import React from 'react'
import {Route as ReactRoute} from 'react-router-dom'

const Route = ({component: Component, layout: Layout = null, ...rest}) => (
    <ReactRoute {...rest} render={matchProps => (
        Layout
            ? <Layout title={rest.title}><Component {...matchProps}/></Layout>
            : <Component {...matchProps}/>
    )}/>
)

export default Route
