import React, { useState, useMemo, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import ChannelListItem from 'Components/Units/channel-list-item/channel-list-item.component';
import { filterArray } from 'utils/functions';

const UnitListItem = ({ unit, selectedChannels, disabledChannels, search, defaultShow, setValues, globalSelectAll, setInitialValues }) => {
    const { nameLocalized, channels } = unit;
    const [show, setShow] = useState(defaultShow);
    const [selectAll, setSelectAll] = useState(false);

    const filteredChannels = useMemo(() => {
        if (search && search !== '')
            return filterArray(channels, search, 'nameLocalized');
        return channels;
    }, [channels, search]);
    

    const selectedChannelCount = useMemo(() => {
        return selectedChannels ? selectedChannels.filter(ch => channels.some(channel => channel.id === ch.id)).length : 0;
    }, [selectedChannels, channels]);

    useEffect(() => {
        setSelectAll(globalSelectAll);
    }, [globalSelectAll]);

    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        const updatedChannels = filteredChannels.filter(channel => 
            !selectedChannels.some(selectedChannel => selectedChannel.id === channel.id)
        ).map(channel => ({
            ...channel,
            unit: channel.unit
        }));

        setValues(prevValues => {
            const updatedValues = checked 
                ? [...prevValues, ...updatedChannels] 
                : prevValues.filter(channel => !filteredChannels.some(fc => fc.id === channel.id));
            return updatedValues;
        });
        setInitialValues(prevValues => {
            const updatedValues = checked 
                ? [...prevValues, ...updatedChannels] 
                : prevValues.filter(channel => !filteredChannels.some(fc => fc.id === channel.id));
            return updatedValues;
        });
    };

    return (
        <div className='unit-list-item'>
            <div className='title' onClick={() => setShow(show => !show)}>
                <div className={`arrow ${show ? '' : 'arrow-open'}`} />
                {nameLocalized}
            </div>
            <div className='unit-list-item-channels' style={{ display: show ? 'block' : 'none', marginLeft: '10px' }}>
                <div style={{display: 'inline-block'}}>
                <FormControlLabel 
                    control={
                        <Checkbox
                            color='default'
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                    }
                    label={`Select all  (${selectedChannelCount}/${channels.length})`}
                />
                </div>
                <div className=''>
                    {filteredChannels.map(channel => (
                        <ChannelListItem 
                            key={channel.id}
                            channel={channel}
                            isChecked={selectAll || (selectedChannels && selectedChannels.some(ch => ch.id === channel.id))}
                            unit={(selectedChannels && selectedChannels.some(ch => ch.id === channel.id)) 
                                ? selectedChannels.find(ch => ch.id === channel.id).unit 
                                : channel.unit}
                            isDisabled={disabledChannels && disabledChannels.includes(channel.id)}
                            setValues={setValues}
                            selectAll={selectAll}
                            setInitialValues={setInitialValues}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UnitListItem;
