import React from 'react'
import { Checkbox } from '@material-ui/core';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <Checkbox
                ref={resolvedRef}
                {...rest}
                color="default"
                size="small"
            />
            // <>
            //     <input type="checkbox" ref={resolvedRef} {...rest} />
            // </>
        )
    }
)

export default IndeterminateCheckbox
