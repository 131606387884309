import { apiMmsBaseUrl } from '../../config'
import { useQuery } from 'react-query'
import Api from '../../Services/Api.service'

export function useEmployeesByDepartmentId (departmentId) {
  const url = departmentId
    ? `${apiMmsBaseUrl}/employees/splitted?departmentId=${departmentId}`
    : `${apiMmsBaseUrl}/employees/splitted`

  return useQuery(
    ['employees-options', departmentId],
    () => Api.get(url),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!departmentId,
    },
  )
}
