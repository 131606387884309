import { darkConfig, lightConfig } from 'Components/Info/Charts/ChartsConfiguration';
import _ from 'lodash';
import { formatPointTime } from 'utils/functions';

const _timeBaseChartConfig = (timeType, timeFormat, timeDiff, timeZone) => {
    return {
        time: {
            timezoneOffset: -120,
        },
        tooltip: {
            shared: true,
            split: false,
            crosshairs: true,
            formatter: function () {
                const pointTime = formatPointTime(this.x, timeType, timeFormat, timeDiff, timeZone);

                if (this.points) {
                    return [`<b>${pointTime}</b><br/>`].concat(this.points.map(point => `${point.series.name}: <b>${point.y}</b><br/>`));

                } else {
                    // prop split is false
                    const pointName = this.series.name;
                    const pointValue = `<b>${this.y}</b>`;
                    return `${pointName} ${pointValue} <br/> ${pointTime}`;
                }
            },
        },
        xAxis: {
            type: 'datetime',
            reversed: false,
        },
        yAxis: {
            labels: {
                format: `{value}`,
            },
        },
    };
};

export const timeBaseChartConfig = (theme, timeType, timeFormat, timeDiff, timeZone) => {
    // return _timeBaseChartConfig(timeType, timeFormat, timeDiff, timeZone)
    return _.merge(
        {},
        theme === 'Dark' ? darkConfig : lightConfig,
        _timeBaseChartConfig(timeType, timeFormat, timeDiff, timeZone),
    );
};

export const dataBaseChartConfig = theme => {
    return theme === 'Dark' ? darkConfig : lightConfig;
};
