import React, { useReducer } from 'react';
import ConfirmModal from '../Components/modals/ConfirmModal';

const ConfirmContext = React.createContext(undefined);

export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';

export const initialState = {
    show: false,
    text: '',
    errors: null,
};
let resolveCallback;
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CONFIRM:
            return {
                show: true,
                text: action.payload.text,
                errors: action.payload?.errors,
            };
        case HIDE_CONFIRM:
            return initialState;
        default:
            return initialState;
    }
};

const ConfirmProvider = ({ children }) => {

    const [confirmState, dispatch] = useReducer(reducer, initialState);

    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };

    const confirm = (text, errors) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text,
                errors
            },
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const closeConfirm = () => {
        dispatch({
            type: HIDE_CONFIRM,
        });
    };


    return (
        <ConfirmContext.Provider value={{ confirm }}>
            {children}
            <ConfirmModal
                open={confirmState?.show}
                handleConfirm={onConfirm}
                handleClose={onCancel}
                message={confirmState?.text}
                errors={confirmState?.errors}
            />
        </ConfirmContext.Provider>
    );
};



const useConfirm = () => {
    const context = React.useContext(ConfirmContext);
    if (context === undefined) {
        throw new Error('useConfirm must be used within a ConfirmProvider');
    }
    return context;
};

export {
    ConfirmProvider,
    useConfirm,
};
