import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import useTranslation from "Hooks/useTranslation";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { Cached } from "@material-ui/icons";
import { apiBaseUrl } from "config";
import axios from "axios";
import UnitBoxDigitalViewHeader from "./Box/Units/UnitBoxDigitalViewHeader";
import BoxHeaderContextMenuComponent from "./Box/BoxHeaderContextMenu.component";

const DragIcon = () => <svg className="dragIcon" />;

const MapComponent = (props) => {
  const {
    currentUnitModel,
    section,
    boxData,
    onRemove,
    handleEdit,
    dragHandleProps,
    onNewWindowOpen,
    onExportToCsv,
  } = props;
  
  


  const translation = useTranslation();
  const [lat, setLat] = useState(currentUnitModel?.latitude);
  const [lng, setLng] = useState(currentUnitModel?.longitude);
  const uId = currentUnitModel?.uId;

  const getMapCoordinates = async () => {
    const urlMap = {
      units: `${apiBaseUrl}/units/refresh/${uId}`,
      locations: `${apiBaseUrl}/locations/refresh/${uId}`,
    };
    const url = urlMap[section] || urlMap.units;

    try {
      const { data } = await axios.get(url);
      setLat(data.latitude);
      setLng(data.longitude);
      return data;
    } catch (error) {
      console.error("Error fetching map coordinates:", error);
      throw error;
    }
  };

    // useEffect(() => {
    //   if(isOpen) {
    //     getMapCoordinates({ UId: uId, section });
    //   }
    // })

  const refreshMap = () => {
    getMapCoordinates({ UId: uId, section });
  };

  const Map = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat, lng }}
        defaultOptions={{
          disableDefaultUI: true,
          keyboardShortcuts: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          styles: [
            {
              featureType: "all",
              elementType: "all",
              // stylers: [
              //   { saturation: -100 },
              //   { lightness: 1 },
              // ],
            },
          ],
        }}
      >
        <Marker position={{ lat, lng }} />{" "}
      </GoogleMap>
    ))
  );

  return (
    <div>
      {/* <div className="modalTitleHolder mb16">
        <h1 className="modalTitle text-center">UNIT CURRENT LOCATION</h1>
      </div> */}
      
      <div  className="boxHeader">
      <div className="contextMenu">
        <IconButton {...dragHandleProps}>
          <DragIcon class="contextMenuIcon" />
        </IconButton>
      </div>

      <div className="contextMenu">
        <BoxHeaderContextMenuComponent
          onNewWindowOpen={onNewWindowOpen}
          onManageChannel={handleEdit}
          onRemove={onRemove}
          onExportToCsv={onExportToCsv}
          type="map"
        />
      </div>
      </div>

      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAQYDQR2ROO-y-cJJVK2NGK5dJno6W7ASM&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `400px` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `400px` }} />}
      />

      <div className="btnHolder end mt16 fixedWidthBtnWithoutMargin">
        <Button
          className="refreshBtn"
          startIcon={<Cached />}
          onClick={refreshMap}
        >
          <span className="refreshButtonLabel">
            {translation.refreshButtonLabel}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default MapComponent;
