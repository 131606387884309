import React, { Fragment, useState, useEffect, memo } from 'react';
import { useTable, useSortBy, useRowSelect, useGlobalFilter, usePagination, useFilters } from 'react-table';
import SearchInput from './SearchInput';
import TableEmptyListMessage from './TableEmptyListMessage';
import { Button, TablePagination } from '@material-ui/core';
import './ListTable.css';
import { DefaultColumnFilter } from '../filters';
import useTranslation from '../Hooks/useTranslation';
import { IconButton } from '@material-ui/core';
// import { Tooltip } from '@material-ui/core';
// import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import IndeterminateCheckbox from './IndeterminateCheckbox';

const ListTable = props => {

    const {
        columns,
        data,
        onRowClick,
        request,
        tableClass,
        wrapperClass,
        initSort,
        enableSearch = true,
        enableColumnFilters,
        isSelectionColumn,
        onSelectedRows
    } = props;

    const [showColumnsFilter, setShowColumnsFilters] = useState(false);

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    const tableInstance = useTable({
            columns,
            data,
            defaultColumn,
            initialState: {
                pageIndex: 0,
                sortBy: initSort ? initSort : [],
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push((columns) => {
                return isSelectionColumn ? [
                    {
                        id: 'selection',
                        className: 'checkBoxCell',
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="notificationsTblCheckBoxAllRows"/>
                        ),
                        Cell: ({row}) => (
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id="notificationsTblCheckBoxReport"/>
                        ),
                    },
                    ...columns
                ] : columns
            })
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        gotoPage,
        setPageSize,
        page,
        selectedFlatRows,
        state: {globalFilter, pageIndex, pageSize, filters},
        setAllFilters,
    } = tableInstance;

    const translation = useTranslation();

    useEffect(() => {
        if (onSelectedRows)
            onSelectedRows(selectedFlatRows.map(row => row.original.id))
    }, [selectedFlatRows, onSelectedRows])

    const renderSearchBlock = () => {
        if (!enableSearch) return null;
        return (
            <div className="pageBtnSection pt0 mt5">
                <div className="searchHolder">
                <div className="inputHolder" style={{display: 'flex', verticalAlign: 'center'}}>
                    <SearchInput
                        search={globalFilter}
                        onChange={setGlobalFilter}
                    />

                    {
                        enableColumnFilters && (
                            <Fragment>
                                <Button
                                    // variant="contained"
                                    // color="secondary"
                                    onClick={() => setShowColumnsFilters((v) => {
                                        if (v) setAllFilters([]);
                                        return !v;
                                    })}
                                    startIcon={<FilterListIcon />}
                                    className="ml15"
                                >
                                    {
                                        showColumnsFilter ? <span className='hideTitleRefreshBtn'>{translation.hideFiltersLabel}</span> : <span className='hideTitleRefreshBtn'>{translation.showFiltersLabel}</span>
                                    }
                                </Button>
                                {
                                    showColumnsFilter && (
                                        <span className='hideTitleRefreshBtn'>
                                            <IconButton
                                            size="small"
                                            disabled={!filters.length}
                                            onClick={() => setAllFilters([])}
                                        >
                                            {/* <Tooltip title={translation.clearAllFiltersLabel}>
                                                <ClearIcon />
                                            </Tooltip> */}
                                        </IconButton>
                                        </span>
                                    )
                                }
                            </Fragment>


                            // <Button
                            //     disabled={!filters.length}
                            //     onClick={() => setAllFilters([])}>
                            //     {translation.clearAllFiltersLabel}
                            // </Button>
                        )
                    }
                </div>
                <div className="inputHolder">

                </div>
            </div>
            </div>
        );
    };

    const renderEmptyList = () => {
        if (request && !request.isSuccess) return null;
        return !rows.length && <TableEmptyListMessage>{translation.activitiesPagePartsEmptyListPlaceholderLabel}</TableEmptyListMessage>;
    };

    const renderPagination = () => {

        if (!data) return null;

        return (
            <TablePagination
                classes={{
                    root: 'tableControlsHolder',
                }}
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={data.length}
                page={pageIndex}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'}
                }}
                onPageChange={(event, page) => {
                    gotoPage(page);
                }}
                rowsPerPage={pageSize}
                onRowsPerPageChange={event => {
                    setPageSize(parseInt(event.target.value));
                }}
            />

        );
    };

    return (
        <Fragment>
            {renderSearchBlock()}
            <div className={wrapperClass || 'tableHolder'}>
                <table {...getTableProps()} className={tableClass || ''}>
                    <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="headingRow">
                                {
                                    headerGroup.headers.map(column => {
                                        const sortClass = column.isSorted ? (column.isSortedDesc ? 'hasArrow' : 'hasArrow up') : '';
                                        const columnClass = `${column.className} ${sortClass}`;
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={columnClass}
                                            >
                                                {column.render('Header')}
                                                {/*<div>{column.canFilter ? column.render('Filter') : null}</div>*/}
                                            </th>
                                        );
                                    })
                                }
                            </tr>

                        ))
                    }
                    {
                        (enableColumnFilters && showColumnsFilter) && headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="headingRow">
                                {
                                    headerGroup.headers.map(column => {
                                        return (
                                            <th {...column.getHeaderProps()}>
                                                {column.canFilter ? column.render('Filter') : null}
                                            </th>
                                        );
                                    })
                                }
                            </tr>

                        ))
                    }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {renderEmptyList()}
                    {
                        page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps({
                                                    className: cell.column.className,
                                                })} onClick={() => {
                                                    if (cell.value !== undefined && onRowClick) {
                                                        onRowClick(cell.row.original);
                                                    }
                                                }}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
            {renderPagination()}
        </Fragment>

    );
};

export default memo(ListTable);
