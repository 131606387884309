import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Checkbox, MenuItem, TextField, Tooltip } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormLabel from "@material-ui/core/FormLabel";
import GetAppIcon from "@material-ui/icons/GetApp";
import MmsService from "Services/MMS/mms-api.service";
import {
  setComponents,
  setComponentsCatalog,
  setParts,
  setPartsCatalog,
} from "../../../../Store/modules/mms/actions";
import DeleteItemModal from "Components/modals/delete-item-modal/delete-item-modal.component";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { normalizeErrorResponse } from "utils/form";
import { connect } from "react-redux";
import AlertToastr from "utils/alert";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import useTranslation from "Hooks/useTranslation";
import enGB from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import { useAppSettings } from "Contexts/AppSettingsProvider";
import { useQueryClient } from "react-query";
import { Autocomplete } from "@material-ui/lab";

const AmIcon6 = () => <div className="amPlIcon" />;

const LegendLabel = ({ children }) => (
  <FormLabel component="legend" style={{ lineHeight: "24px" }}>
    {children}
  </FormLabel>
);

const BlankActivityData = {
  name: "",
  type: "",
  occurence: "",
  priority: "",

  addAsCompleted: false,
  date: "",
  completeWorkhours: "",
  completeDate: "",
  сompletedById: "",

  isCheckedTime: false,
  actionTriggerTimeAmount: "",
  actionTriggerDateRange: "",
  expectedCompleteDate: null,
  isCheckedWorkhours: false,
  actionTriggerWorkhoursRange: "",
  expectedWorkhours: "",
  isCheckedOnDemand: false,

  notificationsWorkhoursEnabled: false,
  notificationsWorkhoursOffset: "",
  notificationsNextWorkhours: "",
  notificationsDateTimeEnabled: false,
  notificationsDateTimePeriod: "",
  notificationsDateRange: "",
  notificationsNextDateTime: null,

  tasks: [],
  timeAmountDateRange: "",
  timeAmount: "",
  skills: [],
  tools: [],
  comments: "",
  fileIds: [],
  files: [],
  employeeId: "",
};

const emptyTaskOption = { id: "empty", name: "Select task" };
const enterTaskNameOption = { id: "enter", name: "Enter new task name" };
const emptyPartOption = { id: "empty", name: "Select part" };

const defaultPriority = "moderate";

const SaveActivityModal = ({
  isOpen,
  isOpenManager,
  isCatalog,
  activity,
  employees,
  currentDepartment,
  componentId,
  parts,
  partsCatalog,
  setParts,
  setPartsCatalog,
  setComponents,
  setComponentsCatalog,
  components,
  componentsCatalog,
  typeOptions,
  componentWorkHours,
  isReadOnly,
  onSuccessSave,
  hideCompleted,
  disableCompleted,
  handleActivityUpdate,
  setExpandedIds,
  openedFromActivities = false,
  componentsOptions,
  setSelectedComponentDetail,
  isUnassignedComponent
}) => {
  const { appLanguage } = useAppSettings();
  const [occurenceOptions, setoccurenceOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [actionTimeTypeOptions, setActionTimeTypeOptions] = useState([]);
  const [notificationTimeRangeOptions, setNotificationTimeRangeOptions] =
    useState([]);
  const [workTimeTypeOptions, setWorkTimeTypeOptions] = useState([]);
  const [showEstimatedTimeBlock] = useState(false);
  const [showSkillsAndToolsBlock] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);
  const [toolOptions, setToolOptions] = useState([]);
  const [activityInfo, setActivityInfo] = useState(BlankActivityData);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  // const [disableButtons, setDisabledButtons] = useState(false);
  const [partList, setPartList] = useState(null);
  const inputFile = useRef(null);
  const [taskList, setTaskList] = useState([]);
  const [initialTaskList, setInitialTaskList] = useState([]);
  const [errors, setErrors] = useState({});
  const translation = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const queryClient = useQueryClient();
  const [activitiesOptions, setActivitiesOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState('createNew');
  const [isVisibleTemplate, setIsVisibleTemplate] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);

    if (e.target.value === 'selectTemplate') {
      setIsVisibleTemplate(true);
    } else {
      setIsVisibleTemplate(false);
    }
  };

  const emptyGuidAssigneeOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.filterActivitiesModalSelectAssigneeLabel,
    };
  }, [translation]);
  const departmentEmployees = useMemo(() => {
    if (employees && currentDepartment)
      return employees.filter((e) => e.departmentId === currentDepartment.id);
    else return null;
  }, [employees, currentDepartment]);

  const emptyComponentOption = useMemo(() => {
    return {
      id: "empty",
      fullName: translation.saveActivityModalSelectComponentOption,
    };
  }, [translation]);

  const emptyActivityOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.saveActivityModalSelectActivityOption,
    };
  }, [translation]);

  const [selectedAutocompleteComponent, setSelectedAutocompleteComponent] =
    useState(emptyComponentOption);

  const [selectedAutocompleteActivities, setSelectedAutocompleteActivities] =
    useState(emptyActivityOption);

  useEffect(() => {
    const fetchDropdownsOptions = async () => {
      let occurenceResponse = await MmsService.getOccurenceOptions();
      setoccurenceOptions(occurenceResponse.data);
      let priorityResponse = await MmsService.getPriorityOptions();
      setPriorityOptions(priorityResponse.data);
      let actionTriggerDateRangeResponse =
        await MmsService.getActionTimeTypeOptions();
      setActionTimeTypeOptions(actionTriggerDateRangeResponse.data);
      let notificationTimeRangesResponse =
        await MmsService.getNotificationTimeRangesOptions();
      setNotificationTimeRangeOptions(notificationTimeRangesResponse.data);

      let timeAmountDateRange = await MmsService.getWorkTimeTypeOptions();
      setWorkTimeTypeOptions(timeAmountDateRange.data);
      if (!activity) {
        setActivityInfo((activityInfo) => {
          return {
            ...activityInfo,
            occurence: occurenceResponse.data[0].occurence,
            priority: priorityResponse.data.find(
              (x) => x.priority === defaultPriority
            )
              ? priorityResponse.data.find(
                  (x) => x.priority === defaultPriority
                ).priority
              : priorityResponse.data[0].priority,
            actionTriggerDateRange:
              actionTriggerDateRangeResponse.data[0].dateRange,
            notificationsDateRange:
              notificationTimeRangesResponse.data[0].dateRange,

            timeAmountDateRange: timeAmountDateRange.data[0].dateRange,
          };
        });
      }
    };
    const fetchSkillOptions = async () => {
      let response = await MmsService.getSkillOptions();
      setSkillOptions(response.data);
    };
    const fetchToolOptions = async () => {
      let response = await MmsService.getToolOptions();
      setToolOptions(response.data);
    };
    const fetchTasksByDepartmentId = async () => {
      let response;
      if (isCatalog) {
        response = await MmsService.getCatalogTasks();
      } else {
        response = await MmsService.getTasksByDepartmentId(
          currentDepartment.id
        );
      }

      setTaskList([emptyTaskOption, enterTaskNameOption].concat(response.data));
      setInitialTaskList(
        [emptyTaskOption, enterTaskNameOption].concat(response.data)
      );
    };
    if (!isOpen || !currentDepartment) return;
    fetchDropdownsOptions();
    fetchSkillOptions();
    fetchToolOptions();
    fetchTasksByDepartmentId();
  }, [isOpen, activity, currentDepartment, isCatalog]);

  // useEffect(() => {
  //     setActivityInfo(activityInfo => {
  //         return {
  //             ...activityInfo,
  //             expectedCompleteDate: activityInfo.isCheckedTime ? moment().add(activityInfo.actionTriggerTimeAmount, activityInfo.actionTriggerDateRange).format().split('+')[0] : null,
  //         };
  //     });
  // }, [activityInfo.actionTriggerDateRange, activityInfo.actionTriggerTimeAmount]);
  useEffect(() => {
    if (!isOpen || !activity) return;
    setActivityInfo({
      ...activity,
      employeeId: activity.assigneeId ? activity.assigneeId : "empty",
      date: moment().format().split("+")[0],
    });
  }, [activity, isOpen]);

  useEffect(() => {
    const fetchParts = async () => {
      try {
        const response = await MmsService.getPartsList();
        setPartList(response.data);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      }
    };
    const fetchPartsCatalog = async () => {
      try {
        const response = await MmsService.getPartsCatalog();
        setPartList(response.data);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      }
    };
    if (!isOpen) return;
    if (isCatalog) {
      if (partsCatalog) setPartList(partsCatalog);
      else fetchPartsCatalog();
    } else {
      if (parts) setPartList(parts);
      else fetchParts();
    }
  }, [
    setParts,
    parts,
    setPartsCatalog,
    partsCatalog,
    currentDepartment,
    isOpen,
    isCatalog,
  ]);

  useEffect(() => {
    if (!isOpen) return;
    let locale;
    if (navigator.languages !== undefined) locale = navigator.languages[0];
    else locale = navigator.language;
    moment.locale(locale);
    if (activity) {
      if (!activity.tasks) activity.tasks = [];
      if (!activity.fileIds) activity.fileIds = [];
      if (!activity.files) activity.files = [];
      if (!activity.skills) activity.skills = [];
      if (!activity.tools) activity.tools = [];
      setActivityInfo(
        JSON.parse(
          JSON.stringify({
            ...activity,
            tasks: activity.tasks.map((x) => {
              if (!x.taskId) {
                x.showTextField = false;
                const currentTask = initialTaskList.find(
                  (t) => t.name === x.taskName
                );
                if (currentTask) x.taskId = currentTask.id;
              }
              return x;
            }),
          })
        )
      );
    } else {
      if (typeOptions)
        setActivityInfo({
          ...BlankActivityData,
          tasks: [],
          fileIds: [],
          files: [],
          skills: [],
          tools: [],
          comments: "",
          type: typeOptions[0].activityType,
        });
      else
        setActivityInfo({
          ...BlankActivityData,
          tasks: [],
          fileIds: [],
          files: [],
          skills: [],
          tools: [],
          comments: "",
        });
    }
  }, [activity, isOpen, typeOptions, initialTaskList]);

  useEffect(() => {
    registerLocale("English", enGB);
    registerLocale("Russian", ru);

    function handleResize() {
      setIsMobile(window.innerWidth <= 576);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setSelectedOption('createNew')
    setIsVisibleTemplate(false);
    isOpenManager(false);
    setErrors({});
    setSelectedAutocompleteComponent(emptyComponentOption);
    setSelectedAutocompleteActivities(emptyActivityOption);
    setActivityInfo({
        ...BlankActivityData,
        tasks: [],
        fileIds: [],
        files: [],
        skills: [],
        tools: [],
        comments: "",
      });
      setSelectedComponentDetail(null)
  };

  const onSave = () => {
    // setDisabledButtons(true);
    const departmentId = isCatalog ? null : currentDepartment.id;

    const formatedActivityInfo = { ...activityInfo };
    formatedActivityInfo.tasks = [...formatedActivityInfo.tasks].map((x) => {
      const res = { ...x };
      if (res.taskId === emptyTaskOption.id) res.taskId = null;
      if (x.taskId === emptyTaskOption.id && !x.showTextField)
        res.taskName = "";
      if (res.partId === emptyPartOption.id) res.partId = null;
      if (res.alternativePartId === emptyPartOption.id)
        res.alternativePartId = null;
      if (res.quantity === "") res.quantity = null;
      delete res.showTextField;
      return { ...res };
    });
    if (!formatedActivityInfo.isCheckedTime) {
      formatedActivityInfo.actionTriggerDateRange = null;
      formatedActivityInfo.actionTriggerTimeAmount = null;
    }
    if (!formatedActivityInfo.isCheckedWorkhours) {
      formatedActivityInfo.actionTriggerWorkhoursRange = null;
      formatedActivityInfo.expectedWorkhours = null;
    } else {
      // formatedActivityInfo.expectedWorkhoursDate =
      //     moment().add(formatedActivityInfo.expectedWorkhours / 10 - 1, 'day').format().split('+')[0];
    }
    if (formatedActivityInfo.actionTriggerTimeAmount === "") {
      formatedActivityInfo.actionTriggerTimeAmount = null;
    }
    if (formatedActivityInfo.actionTriggerWorkhoursRange === "") {
      formatedActivityInfo.actionTriggerWorkhoursRange = null;
    }
    if (formatedActivityInfo.expectedWorkhours === "") {
      formatedActivityInfo.expectedWorkhours = null;
    }
    if (formatedActivityInfo.timeAmount === "") {
      formatedActivityInfo.timeAmount = null;
      formatedActivityInfo.timeAmountDateRange = null;
    }

    if (activity) {
      if (!openedFromActivities) {
        MmsService.updateActivity(formatedActivityInfo)
          .then((response) => {
            if (response.data.isSuccessful) {
              AlertToastr.showAlert(translation.notificationsActivityUpdated);
              //   setDisabledButtons(false);
              handleClose();
              onSuccessSave();
              setExpandedIds(response.data.expandedIds);
              if (formatedActivityInfo.addAsCompleted) return;
              if (isCatalog) {
                const componentIndex = componentsCatalog.findIndex(
                  (c) => c.id === componentId
                );
                const newActivities = [
                  ...componentsCatalog[componentIndex].activities,
                ];
                const activityindex = newActivities.findIndex(
                  (a) => a.id === formatedActivityInfo.id
                );
                newActivities.splice(activityindex, 1, {
                  ...formatedActivityInfo,
                });
                componentsCatalog.splice(componentIndex, 1, {
                  ...componentsCatalog[componentIndex],
                  activities: [...newActivities],
                });
                setComponentsCatalog([...componentsCatalog]);
              } else {
                const componentIndex = components.findIndex(
                  (c) => c.id === componentId
                );
                const newActivities = [
                  ...components[componentIndex].activities,
                ];
                const activityindex = newActivities.findIndex(
                  (a) => a.id === formatedActivityInfo.id
                );
                newActivities.splice(activityindex, 1, {
                  ...formatedActivityInfo,
                });
                components.splice(componentIndex, 1, {
                  ...components[componentIndex],
                  activities: [...newActivities],
                });
                setComponents([...components]);
              }
              if (onSuccessSave) {
                onSuccessSave();
              }
            }
          })
          .catch((error) => {
            if (!error || !error.response || !error.response.data) return;
            const resErrors = normalizeErrorResponse(error.response.data);
            if (resErrors.errors) setErrors(resErrors.errors);
            // setDisabledButtons(false);
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message);
            if (error.response.data.errors)
              error.response.data.errors
                .filter((x) => x.PropertyName === "")
                .map((x) => {
                  AlertToastr.showErrorAlert(x.ErrorMessage);
                  return x;
                });
          });
      } else {
        MmsService.patchActivity(formatedActivityInfo)
          .then((response) => {
            if (response.data.isSuccessful) {
              AlertToastr.showAlert(translation.notificationsActivityUpdated);
              //   setDisabledButtons(false);
              handleClose();
              handleActivityUpdate(activity);
              queryClient.invalidateQueries(["components"]).then();
              setExpandedIds(response.data.expandedIds);
              setSelectedAutocompleteComponent(emptyComponentOption);
              setSelectedAutocompleteActivities(emptyActivityOption);
              if (isCatalog) {
                const componentIndex = componentsCatalog.findIndex(
                  (c) => c.id === componentId
                );
                const newActivities = [
                  ...componentsCatalog[componentIndex].activities,
                ];
                const activityindex = newActivities.findIndex(
                  (a) => a.id === formatedActivityInfo.id
                );
                newActivities.splice(activityindex, 1, {
                  ...formatedActivityInfo,
                });
                componentsCatalog.splice(componentIndex, 1, {
                  ...componentsCatalog[componentIndex],
                  activities: [...newActivities],
                });
                setComponentsCatalog([...componentsCatalog]);
              } else {
                const componentIndex = components.findIndex(
                  (c) => c.id === componentId
                );
                const newActivities = [
                  ...components[componentIndex].activities,
                ];
                const activityindex = newActivities.findIndex(
                  (a) => a.id === formatedActivityInfo.id
                );
                newActivities.splice(activityindex, 1, {
                  ...formatedActivityInfo,
                });
                components.splice(componentIndex, 1, {
                  ...components[componentIndex],
                  activities: [...newActivities],
                });
                setComponents([...components]);
              }
              if (onSuccessSave) {
                onSuccessSave();
              }
            }
          })
          .catch((error) => {
            if (!error || !error.response || !error.response.data) return;
            const resErrors = normalizeErrorResponse(error.response.data);
            if (resErrors.errors) setErrors(resErrors.errors);
            // setDisabledButtons(false);
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message);
            if (error.response.data.errors)
              error.response.data.errors
                .filter((x) => x.PropertyName === "")
                .map((x) => {
                  AlertToastr.showErrorAlert(x.ErrorMessage);
                  return x;
                });
          });
      }
    } else {
      MmsService.createActivity(formatedActivityInfo, departmentId, componentId)
        .then((response) => {
          if (response.data.isSuccessful) {
            // setDisabledButtons(false);
            if (formatedActivityInfo.addAsCompleted) {
              AlertToastr.showAlert(
                translation.notificationsActivityCompletedAdded
              );
            } else {
              AlertToastr.showAlert(translation.notificationsActivityAdded);
            }
            queryClient.invalidateQueries("unassigned").then();
            handleClose();
            onSuccessSave();
            setExpandedIds(response.data.expandedIds);

            if (formatedActivityInfo.addAsCompleted) return;
            if (isCatalog) {
              const componentIndex = componentsCatalog.findIndex(
                (c) => c.id === componentId
              );
              const newActivities = [
                ...componentsCatalog[componentIndex].activities,
                {
                  ...formatedActivityInfo,
                  id: response.data.id,
                  status: "opened",
                },
              ];
              onSuccessSave();
              setExpandedIds(response.data.expandedIds);
              componentsCatalog.splice(componentIndex, 1, {
                ...componentsCatalog[componentIndex],
                activities: [...newActivities],
              });
              setComponentsCatalog([...componentsCatalog]);
              onSuccessSave();
              setExpandedIds(response.data.expandedIds);
            } else {
              const componentIndex = components.findIndex(
                (c) => c.id === componentId
              );
              const newActivities = [
                ...components[componentIndex].activities,
                {
                  ...formatedActivityInfo,
                  id: response.data.id,
                  status: "opened",
                },
              ];
              components.splice(componentIndex, 1, {
                ...components[componentIndex],
                activities: [...newActivities],
              });
              setComponents([...components]);
              onSuccessSave();
              setExpandedIds(response.data.expandedIds);
            }
            if (onSuccessSave) {
              onSuccessSave();
            }
          }
        })
        .catch((error) => {
          if (!error || !error.response || !error.response.data) return;
          const resErrors = normalizeErrorResponse(error.response.data);
          if (resErrors.errors) setErrors(resErrors.errors);
          //   setDisabledButtons(false);
          if (resErrors && resErrors.message)
            AlertToastr.showErrorAlert(`${resErrors.message}`);
          if (error.response.data.errors)
            error.response.data.errors
              .filter((x) => x.PropertyName === "")
              .map((x) => {
                AlertToastr.showErrorAlert(x.ErrorMessage);
                return x;
              });
        });
    }
  };

  const onDeleteTask = () => {
    MmsService.deleteTask(selectedTaskId)
      .then((res) => {
        const newTasks = taskList.filter((d) => d.id !== selectedTaskId);
        setTaskList(newTasks);
        AlertToastr.showAlert(translation.notificationsTaskDeleted);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      });
  };

  const onFileSelected = (file) => {
    const departmentId = isCatalog ? null : currentDepartment.id;
    MmsService.uploadFileToDepartment(departmentId, file)
      .then((response) => {
        if (response.data.isSuccessful) {
          setActivityInfo({
            ...activityInfo,
            fileIds: [...activityInfo.fileIds, response.data.item.id],
            files: [...activityInfo.files, { ...response.data.item }],
          });
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      });
  };

  // const downloadFile = (fileId) => {
  //     MmsService.downloadFile(fileId).then(response => {
  //         const filename = activityInfo.files.find(p => p.id === fileId).name;
  //         const element = document.createElement('a');
  //         const file = new Blob(['\ufeff' + response.data]);
  //         element.href = URL.createObjectURL(file);
  //         element.download = filename;
  //         element.click();
  //     }).catch(error => {
  //         if (error && error.response && error.response.data && error.response.data.message)
  //             AlertToastr.showErrorAlert(error.response.data.message);
  //     });
  // };

  const skillsPart = (skillOption) => (
    <div className="formCheckBoxHolder mb0 mt0" key={skillOption.item}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isReadOnly}
            checked={activityInfo.skills.includes(skillOption.item)}
            onChange={(e) => {
              if (activityInfo.skills.includes(skillOption.item)) {
                const newSkills = activityInfo.skills.filter(
                  (s) => s !== skillOption.item
                );
                setActivityInfo({ ...activityInfo, skills: newSkills });
              } else {
                activityInfo.skills.push(skillOption.item);
                setActivityInfo({
                  ...activityInfo,
                  skills: activityInfo.skills,
                });
              }
            }}
            name="checkedB"
            size="small"
            color="default"
          />
        }
        label={skillOption.name}
      />
    </div>
  );

  const toolsPart = (toolOption) => (
    <div className="formCheckBoxHolder mb0 mt0" key={toolOption.item}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isReadOnly}
            checked={activityInfo.tools.includes(toolOption.item)}
            onChange={(e) => {
              if (activityInfo.tools.includes(toolOption.item)) {
                const newTools = activityInfo.tools.filter(
                  (s) => s !== toolOption.item
                );
                setActivityInfo({ ...activityInfo, tools: newTools });
              } else {
                activityInfo.tools.push(toolOption.item);
                setActivityInfo({ ...activityInfo, tools: activityInfo.tools });
              }
            }}
            name="checkedB"
            size="small"
            color="default"
          />
        }
        label={toolOption.name}
      />
    </div>
  );

  const selectedComponentChanged = (selectedComponent) => {
    setSelectedAutocompleteComponent(selectedComponent);
    setActivitiesOptions(selectedComponent?.activities);
    if(selectedComponent.id === 'empty' || selectedComponent.activities?.length === 0) {
      setActivityInfo({...BlankActivityData, name: "", type: "maintenance",
      occurence: "single",
      priority: "moderate"})
      setActivitiesOptions([]);
      setSelectedAutocompleteActivities(emptyActivityOption)
    }
  };

  const selectedActivityChanged = (selectedActivity) => {
    setSelectedAutocompleteActivities(selectedActivity);
    const selectedActivityWithoutId = { ...selectedActivity };
    delete selectedActivityWithoutId.id;
    setActivityInfo(selectedActivityWithoutId);
  };

  const renderComponentsSelector = () => {
    if(isUnassignedComponent) return null;
    return (
      <div className="formContentBlock modalFieldLabelSettings">
        {/* <FormLabel component="legend" className="mb5 modalFieldLabel">
            {translation.saveActivityModalSelectComponentOptionLabel}
          </FormLabel> */}
        <Autocomplete
          className="autocompleteActivityModal"
          options={
            componentsOptions
              ? [emptyComponentOption, ...componentsOptions]
              : []
          }
          getOptionLabel={(option) => {
            if (!option) return "";
            const fullName = option?.fullName || "";
            const uId = option?.uId || "";
            return uId ? `${fullName} | ${uId}` : fullName;
          }}
          onChange={(event, value) => selectedComponentChanged(value)}
          value={selectedAutocompleteComponent}
          id="assetsTblMenuActivityChooseComponent"
          renderInput={(params) => (
            <TextField
              {...params}
              // label="Select unassigned component"
              variant="outlined"
              margin="normal"
              fullWidth
              className="mb10"
            />
          )}
        />
          <Autocomplete
            className="autocompleteActivityModal"
            options={
              activitiesOptions
                ? [emptyActivityOption, ...activitiesOptions]
                : []
            }
            getOptionLabel={(option) => `${option.name}`}
            onChange={(event, value) => selectedActivityChanged(value)}
            id="assetsTblMenuActivityChooseActivity"
            value={selectedAutocompleteActivities}
            disabled={selectedAutocompleteComponent === emptyComponentOption || selectedAutocompleteComponent.activities?.length === 0}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                fullWidth
                className="mb10"
              />
            )}
          />
      </div>
    );
  };

  const renderNameField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={errors && errors.name ? errors.name.join("\n") : ""}
    >
      <TextField
        disabled={isReadOnly}
        className="activityDescriptionLabel"
        value={activityInfo.name}
        onChange={(e) => {
          if (errors.name) errors.name = null;
          setActivityInfo({
            ...activityInfo,
            name: e.target.value.trimStart(),
          });
        }}
        error={errors && errors.name && errors.name.length > 0}
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalNameLabel}
        id="assetsTblMenuActivityDetailsModalDescription"
        fullWidth
      />
    </Tooltip>
  );

  const renderTypeField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={errors && errors.type ? errors.type.join("\n") : ""}
    >
      <TextField
        disabled={isReadOnly}
        value={activityInfo.type}
        onChange={(e) => {
          if (errors.type) errors.type = null;
          setActivityInfo({ ...activityInfo, type: e.target.value });
        }}
        error={errors && errors.type && errors.type.length > 0}
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalTypeLabel}
        id="assetsTblMenuActivityDetailsModalTypeChange"
        fullWidth
        select
      >
        {typeOptions &&
          typeOptions.map((typeOption) => (
            <MenuItem
              key={typeOption.activityType}
              value={typeOption.activityType}
              id="assetsTblMenuActivityDetailsModalTypeChoose"
              className="filterMenuItem"
            >
              {typeOption.name}
            </MenuItem>
          ))}
      </TextField>
    </Tooltip>
  );

  const renderOccurrenceField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={errors && errors.occurence ? errors.occurence.join("\n") : ""}
    >
      <TextField
        disabled={isReadOnly}
        value={activityInfo.occurence}
        onChange={(e) => {
          const newValue = e.target.value;

          const valueIsSingle = e.target.value === "single";

          if (errors.occurence) errors.occurence = null;

          setActivityInfo((prev) => ({
            ...prev,
            occurence: newValue,

            // reset completed fields
            addAsCompleted: !valueIsSingle ? false : prev.addAsCompleted,
            date: !valueIsSingle ? "" : prev.date,
            completeWorkhours: !valueIsSingle ? "" : prev.completeWorkhours,
            employeeId: !valueIsSingle ? "" : prev.employeeId,
          }));
        }}
        error={errors && errors.occurence && errors.occurence.length > 0}
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalOccurrenceLabel}
        id="assetsTblMenuActivityDetailsModalOcurrenceChange"
        fullWidth
        select
      >
        {occurenceOptions &&
          occurenceOptions.map((occurenceOption) => (
            <MenuItem
              key={occurenceOption.occurence}
              value={occurenceOption.occurence}
              id="assetsTblMenuActivityDetailsModalOcurrenceChoose"
              className="filterMenuItem"
            >
              {occurenceOption.name}
            </MenuItem>
          ))}
      </TextField>
    </Tooltip>
  );

  const renderPriorityField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={errors && errors.priority ? errors.priority.join("\n") : ""}
    >
      <TextField
        disabled={isReadOnly}
        value={activityInfo.priority}
        onChange={(e) => {
          if (errors.priority) errors.priority = null;
          setActivityInfo({ ...activityInfo, priority: e.target.value });
        }}
        error={errors && errors.priority && errors.priority.length > 0}
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalPriorityLabel}
        id="assetsTblMenuActivityDetailsModalPriorityChange"
        fullWidth
        select
      >
        {priorityOptions &&
          priorityOptions.map((priorityOption) => (
            <MenuItem
              key={priorityOption.priority}
              value={priorityOption.priority}
              id="assetsTblMenuActivityDetailsModalPriorityChoose"
              className="filterMenuItem"
            >
              {priorityOption.name}
            </MenuItem>
          ))}
      </TextField>
    </Tooltip>
  );

  const renderAddedAsCompletedCheckbox = (required) => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.addAsCompleted ? errors.addAsCompleted.join("\n") : ""
      }
    >
      <div
        className={`formCheckBoxHolder ${
          errors && errors.addAsCompleted && errors.addAsCompleted.length > 0
            ? "hasError"
            : ""
        }`}
        style={{ display: "flex" }}
      >
        <FormControlLabel
          style={{ marginRight: "0" }}
          control={
            <Checkbox
              disabled={required}
              checked={activityInfo.addAsCompleted}
              id="assetsTblMenuActivityDetailsModalCompletedCheckbox"
              onChange={(e) => {
                setActivityInfo((prev) => {
                  const numericComponentWorkHours = componentWorkHours
                    ? componentWorkHours
                    : 0;
                  const newValue = !activityInfo.addAsCompleted;
                  const newOccurence = newValue
                    ? "single"
                    : activityInfo.occurence;
                  const newAssigneeId = !newValue
                    ? ""
                    : activityInfo.employeeId;
                  const newCompletedById = !newValue
                    ? ""
                    : activityInfo.employeeId;
                  const newDate = !newValue
                    ? null
                    : moment().format().split("+")[0];
                  const newCompleteDate = !newValue
                    ? null
                    : moment().format().split("+")[0];
                  const newCompleteWorkhours = !newValue
                    ? ""
                    : numericComponentWorkHours;

                  return {
                    ...prev,
                    occurence: newOccurence,
                    addAsCompleted: newValue,
                    employeeId: newAssigneeId,
                    сompletedById: newCompletedById,
                    date: newDate,
                    completeDate: newCompleteDate,
                    completeWorkhours: newCompleteWorkhours,

                    // isCheckedTime: newValue ? false : prev.isCheckedTime,
                    // notificationsDateTimeEnabled: newValue ? false : prev.notificationsDateTimeEnabled,
                    // notificationsDateTimePeriod: newValue ? '' : prev.notificationsDateTimePeriod,
                    // notificationsDateRange: newValue ? 'days' : prev.notificationsDateRange,
                    // notificationsNextDateTime: newValue ? null : prev.notificationsNextDateTime,
                    // expectedCompleteDate: newValue ? null : prev.expectedCompleteDate,
                    // // actionTriggerTimeAmount: prev.isCheckedTime ? '' : activityInfo.actionTriggerTimeAmount,
                    // // actionTriggerDateRange: prev.isCheckedTime ? actionTimeTypeOptions[0].dateRange : prev.actionTriggerDateRange,
                    //
                    // isCheckedWorkhours: newValue ? false : prev.isCheckedWorkhours,
                    // notificationsWorkhoursEnabled: newValue ? false : prev.notificationsWorkhoursEnabled,
                    // notificationsWorkhoursOffset: newValue ? '' : prev.notificationsWorkhoursOffset,
                    // expectedWorkhours: newValue ? '' : prev.expectedWorkhours,
                    // actionTriggerWorkhoursRange: newValue ? '' : prev.actionTriggerWorkhoursRange,
                  };
                });
              }}
              name="checkedB"
              size="small"
              color="default"
              className={
                activityInfo.addAsCompleted ? "checkedCheckboxLabel" : ""
              }
            />
          }
          label={""}
        />
        <span
          className={
            activityInfo.status === "opened" ||
            activityInfo.status === "completed"
              ? "completedCheckboxLabel"
              : "checkedCheckboxLabel"
          }
        >
          {translation.saveActivityModalAddAsCompleted}
        </span>
      </div>
    </Tooltip>

    // <div
    //     className={`formCheckBoxHolder`}>
    //     <FormControlLabel
    //         control={
    //             <Checkbox
    //                 disabled={isReadOnly}
    //                 checked={activityInfo.addAsCompleted}
    //                 onChange={(e) => {
    //                     const newValue = !activityInfo.addAsCompleted;
    //                     const newOccurence = newValue ? 'single' : activityInfo.occurence;
    //                     const newAssigneeId = !newValue ? '' : activityInfo.employeeId;
    //                     setActivityInfo({
    //                         ...activityInfo,
    //                         occurence: newOccurence,
    //                         addAsCompleted: newValue,
    //                         employeeId: newAssigneeId,
    //                     });
    //                 }}
    //                 name="AddedAsCompleted"
    //                 size="small"
    //                 color="default"
    //             />
    //         }
    //         label={translation.saveActivityModalAddAsCompleted}
    //     />
    // </div>
  );

  const renderTimeCheckbox = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.isCheckedTime ? errors.isCheckedTime.join("\n") : ""
      }
    >
      <div
        className={`formCheckBoxHolder ${
          errors && errors.isCheckedTime && errors.isCheckedTime.length > 0
            ? "hasError"
            : ""
        }`}
        style={{ display: "flex" }}
      >
        <FormControlLabel
          style={{ marginRight: "0" }}
          control={
            <Checkbox
              disabled={isReadOnly || activityInfo.addAsCompleted}
              checked={activityInfo.isCheckedTime}
              onChange={(e) =>
                setActivityInfo((prev) => {
                  if (errors.isCheckedTime) errors.isCheckedTime = null;

                  const newValue = !prev.isCheckedTime;
                  return {
                    ...prev,
                    isCheckedTime: newValue,
                    notificationsDateTimeEnabled: !newValue
                      ? false
                      : prev.notificationsDateTimeEnabled,
                    notificationsDateTimePeriod: !newValue
                      ? ""
                      : prev.notificationsDateTimePeriod,
                    notificationsDateRange: !newValue
                      ? "days"
                      : prev.notificationsDateRange,
                    notificationsNextDateTime: !newValue
                      ? null
                      : prev.notificationsNextDateTime,
                    expectedCompleteDate: prev.isCheckedTime
                      ? null
                      : moment().format().split("+")[0],
                    // actionTriggerTimeAmount: prev.isCheckedTime ? '' : activityInfo.actionTriggerTimeAmount,
                    // actionTriggerDateRange: prev.isCheckedTime ? actionTimeTypeOptions[0].dateRange : prev.actionTriggerDateRange,

                    // reset completed fields
                    addAsCompleted: newValue ? false : prev.addAsCompleted,
                    date: newValue ? "" : prev.date,
                    completeWorkhours: newValue ? "" : prev.completeWorkhours,
                    employeeId: newValue ? "" : prev.employeeId,
                  };
                })
              }
              id="assetsTblMenuActivityDetailsModalTimeCheckbox"
              name="Time"
              size="small"
              color="default"
              className={
                activityInfo.isCheckedTime ? "checkedCheckboxLabel" : ""
              }
            />
          }
          label={""}
        />
        <span
          className={
            activityInfo.addAsCompleted ||
            (activityInfo.status === "opened" &&
              !activityInfo.addAsCompleted &&
              isReadOnly) ||
            (!activityInfo.isCheckedTime && activityInfo.status === "opened")
              ? "completedCheckboxLabel"
              : activityInfo.isCheckedTime
              ? "checkedCheckboxLabel"
              : "сheckboxLabel"
          }
        >
          {translation.saveActivityModalTimeLabel}
        </span>
      </div>
    </Tooltip>
  );

  const renderWorkhoursCheckbox = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.isCheckedTime ? errors.isCheckedTime.join("\n") : ""
      }
    >
      <div
        className={`formCheckBoxHolder ${
          errors && errors.isCheckedTime && errors.isCheckedTime.length > 0
            ? "hasError"
            : ""
        }`}
        style={{ display: "flex" }}
      >
        <FormControlLabel
          style={{ marginRight: "0" }}
          control={
            <Checkbox
              disabled={isReadOnly || activityInfo.addAsCompleted}
              checked={activityInfo.isCheckedWorkhours}
              id="assetsTblMenuActivityDetailsModalWorkhoursCheckbox"
              onChange={(e) => {
                if (errors.isCheckedWorkhours) errors.isCheckedWorkhours = null;
                const numericComponentWorkHours = componentWorkHours
                  ? componentWorkHours
                  : 0;
                setActivityInfo((prev) => {
                  const newValue = !prev.isCheckedWorkhours;

                  return {
                    ...prev,
                    notificationsWorkhoursEnabled: !newValue
                      ? false
                      : prev.notificationsWorkhoursEnabled,
                    notificationsWorkhoursOffset: !newValue
                      ? ""
                      : prev.notificationsWorkhoursOffset,
                    isCheckedWorkhours: newValue,
                    expectedWorkhours: activityInfo.isCheckedWorkhours
                      ? ""
                      : numericComponentWorkHours,
                    actionTriggerWorkhoursRange: activityInfo.isCheckedWorkhours
                      ? ""
                      : activityInfo.actionTriggerWorkhoursRange,

                    // reset completed fields
                    addAsCompleted: newValue ? false : prev.addAsCompleted,
                    date: newValue ? "" : prev.date,
                    completeWorkhours: newValue ? "" : prev.completeWorkhours,
                    employeeId: newValue ? "" : prev.employeeId,
                  };
                });
              }}
              name="checkedB"
              size="small"
              color="default"
              className={
                activityInfo.isCheckedWorkhours ? "checkedCheckboxLabel" : ""
              }
            />
          }
          label={""}
        />
        <span
          className={
            activityInfo.addAsCompleted ||
            (activityInfo.status === "opened" &&
              !activityInfo.addAsCompleted &&
              isReadOnly) ||
            (!activityInfo.isCheckedWorkhours &&
              activityInfo.status === "opened")
              ? "completedCheckboxLabel"
              : activityInfo.isCheckedWorkhours
              ? "checkedCheckboxLabel"
              : "сheckboxLabel"
          }
        >
          {translation.saveActivityModalWorkhoursLabel}
        </span>
      </div>
    </Tooltip>
  );

  const renderOnDemandCheckbox = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.isCheckedOnDemand
          ? errors.isCheckedOnDemand.join("\n")
          : ""
      }
    >
      <div
        className={`formCheckBoxHolder ${
          errors &&
          errors.isCheckedOnDemand &&
          errors.isCheckedOnDemand.length > 0
            ? "hasError"
            : ""
        }`}
        style={{ display: "flex" }}
      >
        <FormControlLabel
          style={{ marginRight: "0" }}
          control={
            <Checkbox
              disabled={isReadOnly || activityInfo.addAsCompleted}
              checked={activityInfo.isCheckedOnDemand}
              onChange={(e) =>
                setActivityInfo((prev) => {
                  if (errors.isCheckedOnDemand) errors.isCheckedOnDemand = null;

                  const newValue = !prev.isCheckedOnDemand;
                  return {
                    ...prev,
                    isCheckedOnDemand: newValue,
                    // actionTriggerTimeAmount: prev.isCheckedTime ? '' : activityInfo.actionTriggerTimeAmount,
                    // actionTriggerDateRange: prev.isCheckedTime ? actionTimeTypeOptions[0].dateRange : prev.actionTriggerDateRange,

                    // reset completed fields
                    addAsCompleted: newValue ? false : prev.addAsCompleted,
                    date: newValue ? "" : prev.date,
                    completeWorkhours: newValue ? "" : prev.completeWorkhours,
                    employeeId: newValue ? "" : prev.employeeId,
                  };
                })
              }
              id="assetsTblMenuActivityDetailsModalOnDemandCheckbox"
              name="onDemand"
              size="small"
              color="default"
              className={
                activityInfo.isCheckedOnDemand ? "checkedCheckboxLabel" : ""
              }
            />
          }
          label={""}
        />
        <span
          className={
            activityInfo.addAsCompleted ||
            (activityInfo.status === "opened" &&
              !activityInfo.addAsCompleted &&
              isReadOnly) ||
            (!activityInfo.isCheckedOnDemand &&
              activityInfo.status === "opened")
              ? "completedCheckboxLabel"
              : activityInfo.isCheckedOnDemand
              ? "checkedCheckboxLabel"
              : "сheckboxLabel"
          }
        >
          {translation.saveActivityModalOnDemand}
        </span>
      </div>
    </Tooltip>
  );

  const renderActionTriggerTimeAmountField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.actionTriggerTimeAmount
          ? errors.actionTriggerTimeAmount.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedTime ||
          activityInfo.occurence === "single" ||
          isReadOnly
        }
        type="number"
        value={activityInfo.actionTriggerTimeAmount}
        onChange={(e) => {
          if (errors.actionTriggerTimeAmount)
            errors.actionTriggerTimeAmount = null;
          setActivityInfo({
            ...activityInfo,
            actionTriggerTimeAmount: e.target.value,
          });
        }}
        error={
          errors &&
          errors.actionTriggerTimeAmount &&
          errors.actionTriggerTimeAmount.length > 0
        }
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalDateIntervalLabel}
        id="assetsTblMenuActivityDetailsModalIntervalField"
        // fullWidth
      />
    </Tooltip>
  );

  const renderActionTriggerDateRangeField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.actionTriggerDateRange
          ? errors.actionTriggerDateRange.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedTime ||
          activityInfo.occurence === "single" ||
          isReadOnly
        }
        value={activityInfo.actionTriggerDateRange}
        onChange={(e) => {
          if (errors.actionTriggerDateRange)
            errors.actionTriggerDateRange = null;
          setActivityInfo({
            ...activityInfo,
            actionTriggerDateRange: e.target.value,
          });
        }}
        error={
          errors &&
          errors.actionTriggerDateRange &&
          errors.actionTriggerDateRange.length > 0
        }
        variant="outlined"
        margin="normal"
        label=""
        id="assetsTblMenuActivityDetailsModalIntervalTypeChange"
        // label={translation.notificationsDateRange}
        // fullWidth
        select
        className="last"
      >
        {actionTimeTypeOptions &&
          actionTimeTypeOptions.map((actionTimeTypeOption) => (
            <MenuItem
              key={actionTimeTypeOption.dateRange}
              value={actionTimeTypeOption.dateRange}
              id="assetsTblMenuActivityDetailsModalIntervalTypeChoose"
              className="filterMenuItem"
            >
              {actionTimeTypeOption.name}
            </MenuItem>
          ))}
      </TextField>
    </Tooltip>
  );

  const renderExpectedCompleteDateField = () => {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={
          errors && errors.expectedCompleteDate
            ? errors.expectedCompleteDate.join("\n")
            : ""
        }
      >
        <div>
          <div
            className={
              activityInfo.isCheckedTime &&
              !isReadOnly &&
              !activityInfo.addAsCompleted
                ? "labelLike"
                : "labelLike disabled"
            }
          >
            {translation.saveActivityModalDateLabel}
          </div>
          <DatePicker
            disabled={
              !activityInfo.isCheckedTime ||
              isReadOnly ||
              activityInfo.addAsCompleted
            }
            onChange={(e) => {
              if (errors.expectedCompleteDate) {
                errors.expectedCompleteDate = null;
              }
              setActivityInfo({
                ...activityInfo,
                expectedCompleteDate: e ? moment(e).format().split("+")[0] : "",
              });
            }}
            id="assetsTblMenuActivityDetailsModalDateLabel"
            minDate={activityInfo.addAsCompleted ? null : Date.now()}
            maxDate={new Date("12/31/2999")}
            className="formControlDatePicker"
            error={
              errors &&
              errors.expectedCompleteDate &&
              errors.expectedCompleteDate.length > 0
            }
            selected={
              activityInfo.expectedCompleteDate
                ? new Date(activityInfo.expectedCompleteDate)
                : null
            }
            withPortal={isMobile}
            locale={appLanguage}
          />
        </div>
      </Tooltip>
    );
  };

  const renderActionTriggerWorkhoursRangeField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.actionTriggerWorkhoursRange
          ? errors.actionTriggerWorkhoursRange.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedWorkhours ||
          activityInfo.occurence === "single" ||
          isReadOnly
        }
        type="number"
        value={activityInfo.actionTriggerWorkhoursRange}
        onChange={(e) => {
          if (errors.actionTriggerWorkhoursRange)
            errors.actionTriggerWorkhoursRange = null;
          setActivityInfo({
            ...activityInfo,
            actionTriggerWorkhoursRange: e.target.value,
          });
        }}
        error={
          errors &&
          errors.actionTriggerWorkhoursRange &&
          errors.actionTriggerWorkhoursRange.length > 0
        }
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalWorkhoursIntervalLabel}
        id="assetsTblMenuActivityDetailsModalWorkhoursInterval"
        fullWidth
      />
    </Tooltip>
  );

  const renderCompleteDateField = (disabled) => {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={errors && errors.date ? errors.date.join("\n") : ""}
      >
        <div>
          <div className="labelLike">
            {translation.saveActivityModalDateLabel}
          </div>
          <DatePicker
            disabled={!activityInfo.addAsCompleted}
            id="assetsTblMenuActivityDetailsModalCompletedDateLabel"
            onChange={(e) => {
              if (errors.date) errors.date = null;
              setActivityInfo({
                ...activityInfo,
                date: e ? moment(e).format().split("+")[0] : "",
                completeDate: e ? moment(e).format().split("+")[0] : "",
              });
            }}
            minDate={activityInfo.addAsCompleted ? null : Date.now()}
            maxDate={new Date("12/31/2999")}
            // value={activityInfo.date ? moment(activityInfo.date).format('L') : ''}
            className="formControlDatePicker"
            error={errors && errors.date && errors.date.length > 0}
            selected={
              activityInfo.date
                ? new Date(activityInfo.date)
                : undefined || activityInfo.completeDate
                ? new Date(activityInfo.completeDate)
                : undefined
            }
            withPortal={isMobile}
            locale={appLanguage}
          />
        </div>
      </Tooltip>
    );
  };

  const renderCompleteWorkhoursField = (disabled) => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.completeWorkhours
          ? errors.completeWorkhours.join("\n")
          : ""
      }
    >
      <div>
        <TextField
          disabled={!activityInfo.addAsCompleted}
          type="number"
          value={activityInfo.completeWorkhours}
          onChange={(e) => {
            if (errors.completeWorkhours) errors.completeWorkhours = null;
            setActivityInfo({
              ...activityInfo,
              completeWorkhours: e.target.value,
            });
          }}
          error={
            errors &&
            errors.completeWorkhours &&
            errors.completeWorkhours.length > 0
          }
          variant="outlined"
          margin="normal"
          label={translation.saveActivityModalCompleteWorkhoursLabel}
          id="assetsTblMenuActivityDetailsModalCompletedWorkhoursLabel"
          fullWidth
        />
      </div>
    </Tooltip>
  );

  const renderExpectedWorkhoursField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.expectedWorkhours
          ? errors.expectedWorkhours.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedWorkhours ||
          isReadOnly ||
          activityInfo.addAsCompleted
        }
        value={
          activityInfo.expectedWorkhours ? activityInfo.expectedWorkhours : ""
        }
        onChange={(e) => {
          if (errors.expectedWorkhours) errors.expectedWorkhours = null;
          setActivityInfo({
            ...activityInfo,
            expectedWorkhours: e.target.value,
          });
        }}
        error={
          errors &&
          errors.expectedWorkhours &&
          errors.expectedWorkhours.length > 0
        }
        variant="outlined"
        margin="normal"
        label={translation.saveActivityModalWorkhoursLabel}
        id="assetsTblMenuActivityDetailsModalWorkhoursLabel"
        fullWidth
      ></TextField>
    </Tooltip>
  );

  const renderEstimatedTimeBlock = () => {
    if (!showEstimatedTimeBlock) return null;

    return (
      <div className="formContentBlock">
        <div className="halfBlockContainer">
          <div className="halfBlock">
            <FormLabel component="legend" className="mb5">
              {translation.saveActivityModalEstimatedWorkTimeLabel}
            </FormLabel>
            <div className="textFieldGroup hasMargin">
              <div className="tfgItem">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    errors && errors.timeAmount
                      ? errors.timeAmount.join("\n")
                      : ""
                  }
                >
                  <div>
                    <TextField
                      disabled={isReadOnly}
                      type="number"
                      value={activityInfo.timeAmount}
                      onChange={(e) => {
                        if (errors.timeAmount) errors.timeAmount = null;
                        setActivityInfo({
                          ...activityInfo,
                          timeAmount: e.target.value,
                        });
                      }}
                      error={
                        errors &&
                        errors.timeAmount &&
                        errors.timeAmount.length > 0
                      }
                      variant="outlined"
                      margin="none"
                      label={translation.saveActivityModalAmountLabel}
                      fullWidth
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="tfgItem">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    errors && errors.timeAmountDateRange
                      ? errors.timeAmountDateRange.join("\n")
                      : ""
                  }
                >
                  <div>
                    <TextField
                      disabled={isReadOnly}
                      value={activityInfo.timeAmountDateRange}
                      onChange={(e) => {
                        if (errors.timeAmountDateRange)
                          errors.timeAmountDateRange = null;
                        setActivityInfo({
                          ...activityInfo,
                          timeAmountDateRange: e.target.value,
                        });
                      }}
                      error={
                        errors &&
                        errors.timeAmountDateRange &&
                        errors.timeAmountDateRange.length > 0
                      }
                      variant="outlined"
                      margin="none"
                      label=""
                      fullWidth
                      select
                    >
                      {workTimeTypeOptions &&
                        workTimeTypeOptions.map((workTimeTypeOption) => (
                          <MenuItem
                            key={workTimeTypeOption.dateRange}
                            className="filterMenuItem"
                            value={workTimeTypeOption.dateRange}
                          >
                            {workTimeTypeOption.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className="halfBlock"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div className="ftgItem">
              {activityInfo.addAsCompleted && (
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    errors && errors.employeeId
                      ? errors.employeeId.join("\n")
                      : ""
                  }
                >
                  <div style={{ margin: "8px 0 4px" }}>
                    <TextField
                      value={
                        activityInfo.employeeId
                          ? activityInfo.employeeId
                          : "empty"
                      }
                      onChange={(e) => {
                        if (errors.employeeId) errors.employeeId = null;
                        setActivityInfo({
                          ...activityInfo,
                          employeeId: e.target.value,
                        });
                      }}
                      margin="none"
                      label=""
                      variant="outlined"
                      fullWidth
                      select
                    >
                      <MenuItem
                        key={emptyGuidAssigneeOption.id}
                        className="filterMenuItem"
                        value={emptyGuidAssigneeOption.id}
                      >
                        {emptyGuidAssigneeOption.name}
                      </MenuItem>
                      {departmentEmployees &&
                        departmentEmployees.map((employee) => (
                          <MenuItem
                            key={employee.id}
                            className="filterMenuItem"
                            value={employee.id}
                          >
                            {employee.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSkillsAndToolsBlock = () => {
    if (!showSkillsAndToolsBlock) return null;

    return (
      <div className="formContentBlock">
        <div className="halfBlockContainer">
          <div className="halfBlock">
            {/* <FormLabel component="legend"
                                   className="mb5">{translation.saveActivityModalSkillsLabel}</FormLabel> */}
            {skillOptions &&
              skillOptions.map((skillOption) => {
                return skillsPart(skillOption);
              })}
          </div>
          <div className="halfBlock">
            <FormLabel component="legend" className="mb5">
              {translation.saveActivityModalToolsLabel}
            </FormLabel>
            {toolOptions &&
              toolOptions.map((toolOption) => {
                return toolsPart(toolOption);
              })}
          </div>
        </div>
      </div>
    );
  };

  const renderEmployeeField = (disabled) => {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={errors && errors.employeeId ? errors.employeeId.join("\n") : ""}
      >
        <div>
          <TextField
            value={activityInfo.employeeId}
            onChange={(e) => {
              if (errors.employeeId) errors.employeeId = null;
              setActivityInfo({
                ...activityInfo,
                employeeId: e.target.value,
                сompletedById: e.target.value,
              });
            }}
            disabled={!activityInfo.addAsCompleted}
            margin="normal"
            label="Select assignee"
            variant="outlined"
            fullWidth
            select
            id="assetsTblMenuActivityDetailsModalCompletedChangeAssignee"
          >
            <MenuItem
              key={emptyGuidAssigneeOption.id}
              className="filterMenuItem"
              value={emptyGuidAssigneeOption.id}
            >
              {emptyGuidAssigneeOption.name}
            </MenuItem>
            {employees &&
              employees.map((employee) => (
                <MenuItem
                  key={employee.id}
                  className="filterMenuItem"
                  value={employee.id}
                  id="assetsTblMenuActivityDetailsModalCompletedChooseAssignee"
                >
                  {employee.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </Tooltip>
    );
  };

  const renderOccurrenceTab = () => {
    return (
      <>
        <div className="activityFormGridSize">
          <div className="activityFormLeftSide">
            <div style={{ marginBottom: "4px", display: "none" }}>
              <LegendLabel>
                {translation.saveActivityModalTimeLabel}
              </LegendLabel>
            </div>
            <div style={{ marginTop: "30px" }}>{renderTimeCheckbox()}</div>
          </div>

          <div>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1, position: "relative" }}>
                <div style={{ marginBottom: "8px" }}>
                  <LegendLabel>
                    {translation.saveActivityModalNextOccurenceLabel}
                  </LegendLabel>
                </div>
                <div
                  className={
                    errors &&
                    errors.expectedCompleteDate &&
                    errors.expectedCompleteDate.length > 0
                      ? "datePickerWithLabel hasError"
                      : "datePickerWithLabel"
                  }
                >
                  {renderExpectedCompleteDateField()}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "4px" }}>
              <LegendLabel>
                {translation.saveActivityModalOccurrenceIntervalLabel}
              </LegendLabel>
            </div>

            <div
              className="doubleInput hasSelect"
              style={{ display: "flex", alignItems: "center" }}
            >
              {renderActionTriggerTimeAmountField()}
              {renderActionTriggerDateRangeField()}
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "4px" }}>
              <LegendLabel>
                {translation.saveActivityModalNotificationsLabel}
              </LegendLabel>
            </div>
            <div
              className="doubleInput hasSelect"
              style={{ display: "flex", alignItems: "center" }}
            >
              {renderNotificationActionTriggerTimeAmountField()}
              {renderNotificationActionTriggerDateRangeField()}
            </div>
          </div>
        </div>

        {/* <LegendLabel>{translation.saveActivityModalWorkhoursLabel}</LegendLabel> */}
        <div className="activityFormGridSize">
          <div className="activityFormLeftSide">
            {renderWorkhoursCheckbox()}
          </div>
          {renderExpectedWorkhoursField()}
          {renderActionTriggerWorkhoursRangeField()}
          {renderNotificationExpectedWorkhoursField()}
        </div>

        <div className="onDemandCheckbox">
          <div className="onDemandCheckboxLeftSide" style={{ width: "108px" }}>
            {renderOnDemandCheckbox()}
          </div>
        </div>
      </>
    );
  };

  const renderNotificationActionTriggerTimeAmountField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.notificationsDateTimePeriod
          ? errors.notificationsDateTimePeriod.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedTime ||
          isReadOnly ||
          activityInfo.addAsCompleted
        }
        type="number"
        value={activityInfo.notificationsDateTimePeriod}
        onChange={(e) => {
          if (errors.notificationsDateTimePeriod)
            errors.notificationsDateTimePeriod = null;
          setActivityInfo({
            ...activityInfo,
            notificationsDateTimePeriod: e.target.value,
          });
        }}
        error={
          errors &&
          errors.notificationsDateTimePeriod &&
          errors.notificationsDateTimePeriod.length > 0
        }
        variant="outlined"
        margin="normal"
        label={translation.notificationsDateTimePeriod}
        id="assetsTblMenuActivityDetailsModalNotificatNum"
      />
    </Tooltip>
  );

  const renderNotificationActionTriggerDateRangeField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.notificationsDateRange
          ? errors.notificationsDateRange.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedTime ||
          isReadOnly ||
          activityInfo.addAsCompleted
        }
        value={activityInfo.notificationsDateRange}
        onChange={(e) => {
          if (errors.notificationsDateRange)
            errors.notificationsDateRange = null;
          setActivityInfo({
            ...activityInfo,
            notificationsDateRange: e.target.value,
          });
        }}
        error={
          errors &&
          errors.notificationsDateRange &&
          errors.notificationsDateRange.length > 0
        }
        variant="outlined"
        margin="normal"
        // label={translation.notificationsDateRange}
        label=""
        id="assetsTblMenuActivityDetailsModalNotificatNumTypeChange"
        // fullWidth
        select
        className="last"
      >
        {notificationTimeRangeOptions &&
          notificationTimeRangeOptions.map((option) => (
            <MenuItem
              key={option.dateRange}
              value={option.dateRange}
              id="assetsTblMenuActivityDetailsModalNotificatNumTypeChoose"
              className="filterMenuItem"
            >
              {option.name}
            </MenuItem>
          ))}
      </TextField>
    </Tooltip>
  );

  const renderNotificationExpectedWorkhoursField = () => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        errors && errors.notificationsWorkhoursOffset
          ? errors.notificationsWorkhoursOffset.join("\n")
          : ""
      }
    >
      <TextField
        disabled={
          !activityInfo.isCheckedWorkhours ||
          isReadOnly ||
          activityInfo.addAsCompleted
        }
        value={
          activityInfo.notificationsWorkhoursOffset
            ? activityInfo.notificationsWorkhoursOffset
            : ""
        }
        onChange={(e) => {
          if (errors.notificationsWorkhoursOffset)
            errors.notificationsWorkhoursOffset = null;
          setActivityInfo({
            ...activityInfo,
            notificationsWorkhoursOffset: e.target.value,
          });
        }}
        error={
          errors &&
          errors.notificationsWorkhoursOffset &&
          errors.notificationsWorkhoursOffset.length > 0
        }
        variant="outlined"
        margin="normal"
        label={translation.notificationsWorkhoursOffset}
        id="assetsTblMenuActivityDetailsModalWorkhoursNumber"
        fullWidth
      ></TextField>
    </Tooltip>
  );

  const renderCompletedFields = () => {
    return (
      <>
        {/* <LegendLabel>{translation.saveActivityModalAddAsCompleted}</LegendLabel> */}
        <div className="activityFormGridSize">
          <div className="activityFormLeftSide">
            {renderAddedAsCompletedCheckbox(
              isReadOnly ||
                activityInfo.occurence !== "single" ||
                disableCompleted
            )}
          </div>
          <div
            className={
              errors &&
              errors.expectedCompleteDate &&
              errors.expectedCompleteDate.length > 0
                ? "datePickerWithLabel hasError"
                : "datePickerWithLabel"
            }
          >
            {renderCompleteDateField(
              !activityInfo.addAsCompleted || disableCompleted
            )}
          </div>
          {renderCompleteWorkhoursField(
            !activityInfo.addAsCompleted || disableCompleted
          )}
          {renderEmployeeField(
            !activityInfo.addAsCompleted || disableCompleted
          )}
        </div>
      </>
    );
  };

  const renderRadioButtons = (selectedOption, handleOptionChange, showUnassignedComponents) => {
    return (
      <div className="radioButtonGroup">
        <label className="radioButtonLabel">
          <input
            type="radio"
            value="createNew"
            checked={selectedOption === 'createNew'}
            onChange={handleOptionChange}
            id="createNewActivityRadioBtn"
          />
          Create New
        </label>
        <label className="radioButtonLabel">
          <input
            type="radio"
            value={showUnassignedComponents ? 'selectUnassigned' : 'selectTemplate'}
            checked={selectedOption === (showUnassignedComponents ? 'selectUnassigned' : 'selectTemplate')}
            onChange={handleOptionChange}
            id="chooseFromExistingActivityRadioBtn"
          />
          Copy From Existing Activities
        </label>
      </div>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onKeyDown={(e) => {
        if (e.key === "Enter" && activityInfo.comments === "") {
          e.preventDefault();
          onSave();
        }
      }}
      className="respModal scrollContentModal autoHeight"
    >
      <div className="formContentHolder">
        <div className="modalTitleHolder">
          <h1 className="modalTitle text-center">
            {activity
              ? translation.saveActivityModalEditActivityLabel
              : translation.saveActivityModalAddActivityLabel}
          </h1>
        </div>
        <div className="formContent activityForm">
        {renderRadioButtons(selectedOption, handleOptionChange)}
     
          <div className={`slide ${isVisibleTemplate ? 'showActivity' : 'hide'}`}>
              {renderComponentsSelector()}
            </div>

          <div className="formContentBlock">
            <div className="halfBlockContainer">
              <div className="halfBlock">{renderNameField()}</div>
              <div className="halfBlock">{renderOccurrenceField()}</div>
            </div>
            <div className="halfBlockContainer">
              <div className="halfBlock">{renderTypeField()}</div>
              <div className="halfBlock">{renderPriorityField()}</div>
            </div>
          </div>
          <div className="formContentBlock">
            <div className="overflowContent">{renderOccurrenceTab()}</div>
          </div>
          <div className="formContentBlock">
            <div className="overflowContent">
              {!hideCompleted && renderCompletedFields()}
            </div>
          </div>

          <div className="formContentBlock">
            <div className="wrapper content-justified align-center">
              <FormLabel
                className="сheckboxLabel"
                disabled={activityInfo.status === "removed"}
              >
                {translation.saveActivityModalTasksLabel}
              </FormLabel>
              <Button
                disabled={activityInfo.status === "removed"}
                startIcon={<AmIcon6 />}
                id="assetsTblMenuActivityDetailsModalAddTaskBtn"
                onClick={(e) => {
                  activityInfo.tasks.push({
                    taskName: emptyTaskOption.name,
                    taskId: emptyTaskOption.id,
                    quantity: "",
                    partId: emptyPartOption.id,
                    alternativePartId: emptyPartOption.id,
                  });
                  setActivityInfo({
                    ...activityInfo,
                    tasks: [...activityInfo.tasks],
                  });
                }}
              >
                <span disabled={isReadOnly}>
                  {translation.saveActivityModalAddButtonLabel}
                </span>
              </Button>
            </div>
            <div className="textFieldTableHolder">
              <table className="textFieldTable">
                <tbody>
                  {activityInfo.tasks || activityInfo.tasks?.length === 0
                    ? activityInfo.tasks.map((task, index) => (
                        <tr key={index}>
                          <td className="nameCell">
                            <div className="tfgItem">
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                title={
                                  errors && errors[`tasks[{index}]`]
                                    ? errors[`tasks[${index}]`].join("\n")
                                    : ""
                                }
                              >
                                <div>
                                  {task.showTextField && (
                                    <TextField
                                      disabled={
                                        activityInfo.status === "removed"
                                      }
                                      value={task.taskName}
                                      onChange={(e) => {
                                        if (errors[`tasks[${index}]`])
                                          errors[`tasks[${index}]`] = null;
                                        if (e.target.value === "") {
                                          task.showTextField = false;
                                          task.taskName = emptyTaskOption.name;
                                          task.taskId = emptyTaskOption.id;
                                        } else task.taskName = e.target.value;
                                        setActivityInfo({
                                          ...activityInfo,
                                          tasks: [...activityInfo.tasks],
                                        });
                                      }}
                                      error={
                                        errors &&
                                        errors[`tasks[${index}]`] &&
                                        errors[`tasks[${index}]`].length > 0
                                      }
                                      variant="outlined"
                                      margin="none"
                                      label={
                                        translation.saveActivityModalTaskLabel
                                      }
                                      fullWidth
                                    />
                                  )}
                                </div>
                              </Tooltip>
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                title={
                                  errors && errors[`tasks[${index}]`]
                                    ? errors[`tasks[${index}]`].join("\n")
                                    : ""
                                }
                              >
                                <div>
                                  {!task.showTextField && (
                                    <TextField
                                      disabled={
                                        activityInfo.status === "removed"
                                      }
                                      value={task.taskId}
                                      SelectProps={{
                                        renderValue: (taskId) => {
                                          return task.taskName;
                                        },
                                      }}
                                      onChange={(e) => {
                                        if (isOpenDeleteModal) return;
                                        if (errors[`tasks[${index}]`])
                                          errors[`tasks[${index}]`] = null;
                                        if (
                                          taskList.find(
                                            (x) => x.id === e.target.value
                                          ).noBaseParts
                                        ) {
                                          task.noBaseParts = true;
                                          task.partId = emptyPartOption.id;
                                          task.alternativePartId =
                                            emptyPartOption.id;
                                        } else {
                                          task.noBaseParts = false;
                                        }
                                        if (
                                          e.target.value ===
                                          enterTaskNameOption.id
                                        ) {
                                          task.showTextField = true;
                                          task.taskName = "";
                                        } else {
                                          task.taskName = taskList.find(
                                            (x) => x.id === e.target.value
                                          ).name;
                                          task.taskId = e.target.value;
                                        }
                                        setActivityInfo({
                                          ...activityInfo,
                                          tasks: [...activityInfo.tasks],
                                        });
                                      }}
                                      error={
                                        errors &&
                                        errors[`tasks[${index}]`] &&
                                        errors[`tasks[${index}]`].length > 0
                                      }
                                      variant="outlined"
                                      margin="none"
                                      label={
                                        translation.saveActivityModalTaskLabel
                                      }
                                      fullWidth
                                      select
                                      className="hasDeleteOnPlaceHolder"
                                      id="assetsTblMenuActivityDetailsModalTaskChange"
                                    >
                                      {taskList &&
                                        taskList.map((t) => (
                                          <MenuItem
                                            key={t.id}
                                            value={t.id}
                                            id="assetsTblMenuActivityDetailsModalTaskChoose"
                                            className="filterMenuItem hasDeleteBtn"
                                          >
                                            {t.name}
                                            {t.id !== emptyTaskOption.id &&
                                              t.id !== enterTaskNameOption.id &&
                                              !t.noBaseParts && (
                                                <IconButton
                                                  onClick={(e) => {
                                                    setSelectedTaskId(t.id);
                                                    setIsOpenDeleteModal(true);

                                                    setTimeout(() => {
                                                      activityInfo.tasks[
                                                        index
                                                      ].taskId =
                                                        emptyTaskOption.id;
                                                      setActivityInfo({
                                                        ...activityInfo,
                                                        tasks: [
                                                          ...activityInfo.tasks,
                                                        ],
                                                      });
                                                    }, 100);
                                                  }}
                                                  id="assetsTblMenuActivityDetailsModalTaskDelete"
                                                  className="iconBtnSm"
                                                >
                                                  <HighlightOffIcon />
                                                </IconButton>
                                              )}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  )}
                                </div>
                              </Tooltip>
                            </div>
                          </td>
                          <td style={{ maxWidth: "350px" }}>
                            <div className="tfgItem">
                              {task.partId !== emptyPartOption.id &&
                              task.partId !== null ? (
                                <Tooltip
                                  title={
                                    partList.find(
                                      (part) => part.id === task.partId
                                    )?.fullName
                                  }
                                >
                                  <Autocomplete
                                    disabled={
                                      // task.noBaseParts ||
                                      activityInfo.status === "removed"
                                    }
                                    value={partList.find(
                                      (part) => part.id === task.partId
                                    )}
                                    onChange={(event, newValue) => {
                                      task.partId = newValue
                                        ? newValue.id
                                        : null;
                                      setActivityInfo({
                                        ...activityInfo,
                                        tasks: [...activityInfo.tasks],
                                      });
                                    }}
                                    getOptionLabel={(option) => option.fullName}
                                    options={partList}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="autocompletePartActivityChoose"
                                        variant="outlined"
                                        margin="none"
                                        label={
                                          activityInfo.status !== "completed"
                                            ? translation.saveActivityModalPartToReplaceLabel
                                            : translation.saveActivityModalPartReplacedLabel
                                        }
                                        fullWidth
                                      />
                                    )}
                                    fullWidth
                                    className="autocompletePartActivity"
                                    id="autocompletePartActivityChange"
                                  />
                                </Tooltip>
                              ) : (
                                <Autocomplete
                                  disabled={
                                    // task.noBaseParts ||
                                    activityInfo.status === "removed"
                                  }
                                  value={partList.find(
                                    (part) => part.id === task.partId
                                  )}
                                  onChange={(event, newValue) => {
                                    task.partId = newValue ? newValue.id : null;
                                    setActivityInfo({
                                      ...activityInfo,
                                      tasks: [...activityInfo.tasks],
                                    });
                                  }}
                                  getOptionLabel={(option) => option.fullName}
                                  options={partList}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      id="autocompletePartActivityChoose"
                                      variant="outlined"
                                      margin="none"
                                      label={
                                        activityInfo.status !== "completed"
                                          ? translation.saveActivityModalPartToReplaceLabel
                                          : translation.saveActivityModalPartReplacedLabel
                                      }
                                      fullWidth
                                    />
                                  )}
                                  fullWidth
                                  className="autocompletePartActivity"
                                  id="autocompletePartActivityChange"
                                />
                              )}
                            </div>
                          </td>

                          <td className="qntCell">
                            <div className="tfgItem">
                              <TextField
                                disabled={activityInfo.status === "removed"}
                                type="number"
                                value={
                                  activityInfo.status === "completed"
                                    ? task.partReplacedQuantity
                                    : task.quantity
                                }
                                onChange={(e) => {
                                  task.quantity = e.target.value;
                                  setActivityInfo({
                                    ...activityInfo,
                                    tasks: [...activityInfo.tasks],
                                  });
                                }}
                                variant="outlined"
                                margin="none"
                                label={
                                  translation.saveActivityModalQuantityLabel
                                }
                                id="assetsTblMenuActivityDetailsModalTaskQtyLabel"
                                fullWidth
                              />
                            </div>
                          </td>
                          <td className="btnCell">
                            <div className="tfgItem btnItem">
                              <IconButton
                                disabled={activityInfo.status === "removed"}
                                id="assetsTblMenuActivityDetailsModalDeleteTaskBtn"
                                onClick={(e) => {
                                  activityInfo.tasks.splice(index, 1);
                                  setActivityInfo({
                                    ...activityInfo,
                                    tasks: [...activityInfo.tasks],
                                  });
                                }}
                                className="iconBtnSm"
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          {renderEstimatedTimeBlock()}
          {renderSkillsAndToolsBlock()}
          <div className="formContentBlock">
            <div>
              <div className="wrapper content-justified align-center mb5">
                <FormLabel component="legend" className="сheckboxLabel">
                  {translation.saveActivityModalFilesLabel}
                </FormLabel>
                <Button
                  disabled={isReadOnly && !openedFromActivities}
                  onClick={(e) => inputFile.current.click()}
                  startIcon={<AmIcon6 />}
                  id="assetsTblMenuActivityDetailsModalUploadFileBtn"
                >
                  <span className="сheckboxLabel">
                    {translation.saveActivityModalUploadButtonLabel}
                  </span>
                </Button>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (!e.target.files[0]) return;
                    onFileSelected(e.target.files[0]);
                  }}
                />
              </div>
              <ul className="partsListGroup" style={{ height: "99px" }}>
                {activityInfo.fileIds &&
                  activityInfo.fileIds.map((fileId) => {
                    const file = activityInfo.files.find(
                      (p) => p.id === fileId
                    );
                    return (
                      <li key={fileId}>
                        <div className="name">{file.name}</div>
                        <div className="btnPart">
                          <IconButton
                            className="download"
                            size="small"
                            style={{ color: "#b1b3b5" }}
                            id="assetsTblMenuActivityDetailsModalDownloadFileBtn"
                            onClick={(e) => {
                              fetch(file.url)
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const link = document.createElement("a");
                                  link.href = URL.createObjectURL(blob);
                                  link.download = file.name;
                                  link.click();
                                });
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                          <IconButton
                            className="delete"
                            disabled={isReadOnly && !openedFromActivities}
                            size="small"
                            style={{ color: "#b1b3b5" }}
                            id="assetsTblMenuActivityDetailsModalDeleteFileBtn"
                            onClick={(e) => {
                              setActivityInfo({
                                ...activityInfo,
                                fileIds: activityInfo.fileIds.filter(
                                  (p) => p !== fileId
                                ),
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div>
              <div className="wrapper content-justified align-center mb5">
                <FormLabel
                  component="legend"
                  style={{ lineHeight: "38px" }}
                  className="сheckboxLabel"
                >
                  {translation.saveActivityModalCommentsLabel}
                </FormLabel>
              </div>
              <TextField
                disabled={isReadOnly && !openedFromActivities}
                value={activityInfo.comments}
                onChange={(e) => {
                  setActivityInfo({
                    ...activityInfo,
                    comments: e.target.value,
                  });
                }}
                type="text"
                id="assetsTblMenuActivityDetailsModalCommentsLabel"
                variant="outlined"
                margin="none"
                fullWidth
                multiline={true}
                minRows={4}
                className="formControlTextarea fixedHeight"
              />
            </div>
          </div>
        </div>
        <div className="btnHolder end fixedWidthBtn">
          <Button
            // disabled={disableButtons}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleClose}
            id="assetsTblMenuActivityDetailsModalCancelBtn"
          >
            {translation.cancelButtonLabel}
          </Button>
          <Button
            // disabled={disableButtons || isReadOnly}
            variant="contained"
            color="primary"
            id="assetsTblMenuActivityDetailsModalSaveBtn"
            onClick={(e) => onSave()}
            fullWidth
          >
            {activity
              ? translation.saveButtonLabel
              : translation.addButtonLabel}
          </Button>
        </div>
      </div>
      <DeleteItemModal
        isOpen={isOpenDeleteModal}
        isOpenManager={setIsOpenDeleteModal}
        message={translation.removeTaskModalMessageLabel}
        onDeleteModalClose={(e) => {
          onDeleteTask();
        }}
      />
    </Dialog>
  );
};

const mapStateToProps = ({ mms }) => ({
  employees: mms.employees,
  currentDepartment: mms.currentDepartment,
  components: mms.components,
  componentsCatalog: mms.componentsCatalog,
  parts: mms.parts,
  partsCatalog: mms.partsCatalog,
  typeOptions: mms.typeOptions,
});

const mapDispatchToProps = {
  setComponents,
  setComponentsCatalog,
  setParts,
  setPartsCatalog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveActivityModal);