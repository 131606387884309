import React, {useMemo} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import useTranslation from '../../Hooks/useTranslation';

const ConfirmModal = (props) => {

    const {
        open,
        handleConfirm,
        handleClose,
        loading = false,
        message = '',
        errors
    } = props;

    const translation = useTranslation();

    const title = useMemo(() => {
        if (errors?.type === 'error') return 'Error';
        if (errors?.type === 'warning') return 'Warning'
        return translation.removeComponentModalPermissionLabel;
    }, [errors, translation]);

    const iconClass = useMemo(() => {
        if (errors?.type === 'error') return 'modalTitle hasIcon error';
        if (errors?.type === 'warning') return 'modalTitle hasIcon warning';
        return 'modalTitle';
    }, [errors]);


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="modalSm"
        >
            <div className="modalTitleHolder mb16">
                <h1 className={`${iconClass}`}>{title}</h1>
            </div>
            {
                errors?.messages && (
                    <ul className="errorList mb15">
                        {
                            errors.messages.map((errorMessage, index) => (
                                <li key={index}>{errorMessage}</li>))
                        }
                    </ul>
                )
            }
            <p>{message}</p>
            <div className="btnHolder end mt10 fixedWidthBtn">
                <Button
                    onClick={handleClose}
                    variant="outlined"
                >
                    {translation.nowLabel}
                </Button>
                <Button
                    disabled={loading}
                    onClick={handleConfirm}
                    variant="contained"
                    color="primary"
                >
                    {translation.yesLabel}
                </Button>
            </div>
        </Dialog>
    );
};

export default ConfirmModal;
