import { useEffect, useMemo } from 'react';
import { apiBaseUrl } from '../../config';
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { useClientMethod, useHub, useHubMethod } from 'react-use-signalr';

const ExportDownloadSignalR = props => {
    const {
        token,
        companyId,
        onFinish,
        tabType,
    } = props;

    const signalRConnection = useMemo(
        () => {
            const buildHubUrl = companyId
                ? `${apiBaseUrl}/ws/export?companyid=${companyId}`
                : `${apiBaseUrl}/ws/export`;

            const protocol = new JsonHubProtocol();
            const transport = HttpTransportType.WebSockets;

            const options = {
                skipNegotiation: true,
                transport,
                accessTokenFactory: () => token,
                headers: { authorization: token ? `Token ${token}` : `` },
            };

            return new HubConnectionBuilder()
                .withUrl(buildHubUrl, options)
                .withAutomaticReconnect()
                .withHubProtocol(protocol)
                .configureLogging(LogLevel.Information)
                .build();
        }, [token, companyId],
    );

    const {
        hubConnectionState,
        // error
    } = useHub(signalRConnection);

    const {
        invoke,
        // loading,
        // error: errorInvoke
    } = useHubMethod(signalRConnection, 'SubscribeToCsvExportUpdates');

    useClientMethod(signalRConnection, 'CsvExportCompleted', onFinish);

    useEffect(() => {
        if (hubConnectionState === 'Connected') {
            invoke(tabType).then(() => {
            });
        }
    }, [hubConnectionState, invoke, tabType]);

    return null;
};

export default ExportDownloadSignalR;
