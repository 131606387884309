import React, { Fragment, useState, useEffect } from 'react'
// import AlertToastr from 'utils/alert'
import { Button, Dialog, TextField } from '@material-ui/core'
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import { ThemeProvider } from "styled-components";
import { START_DATE, END_DATE } from "@datepicker-react/styled";
import DateRangePicker from 'Components/Box/DateRangePicker'
import moment from 'moment'
import { useAppSettings } from 'Contexts/AppSettingsProvider'
import ManageDateFormat from 'Components/modals/ManageDateFormatModal/ManageDateFormat'
import useTranslation from 'Hooks/useTranslation'

const defaultTime = "00:00"
const RadioLabel = ({ label }) => (
    <div className="jobLabel">
        <div className="labelName">{label}</div>
    </div>
)
const showDateRangeSection = false;

const ManageDateFormatModal = ({
    isOpen,
    setOpen,
    onSave,
    timeType,
    timeFormat,
    type,
    chartType,
    dateFrom,
    dateTo,
    // setDateFrom,
    // setDateTo
}) => {

    const { appTheme: theme } = useAppSettings()
    const [timeFrom, setTimeFrom] = useState(defaultTime)
    const [timeTo, setTimeTo] = useState(defaultTime)
    const [selectedTimeFormat, setSelectedTimeFormat] = useState('')
    const [selectedTimeType, setSelectedTimeType] = useState('')
    const [selectedDateFrom, setSelectedDateFrom] = useState(null)
    const [selectedDateTo, setSelectedDateTo] = useState(null)
    const [showDatepickers, setShowDatepickers] = useState(false)
    const translation = useTranslation();

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE,
    })

    useEffect(() => {
        if (!dateFrom && !dateTo) {
            setTimeFrom(defaultTime)
            setTimeTo(defaultTime)
            setShowDatepickers(false)
        } else {
            setSelectedDateFrom(dateFrom)
            setSelectedDateTo(dateTo)
            setShowDatepickers(true)
        }
    }, [dateFrom, dateTo])

    useEffect(() => {
        if (!selectedDateFrom || !selectedDateTo) {
            setTimeFrom(defaultTime)
            setTimeTo(defaultTime)
            setShowDatepickers(false)
        } else {
            setTimeTo(moment(new Date(selectedDateTo)).format("HH:mm"))
            setTimeFrom(moment(new Date(selectedDateFrom)).format("HH:mm"))
            setShowDatepickers(true)
            setDateRange({ startDate: new Date(selectedDateFrom), endDate: new Date(selectedDateTo), focusedInput: END_DATE })
        }
    }, [selectedDateFrom, selectedDateTo])

    useEffect(() => {
        setSelectedTimeFormat(timeFormat)
        setSelectedTimeType(timeType)
    }, [timeType, timeFormat])

    // const formatDate = (date, time) => {
    //     const formattedDate = new Date(new Date(date).setHours(time.slice(0, 2), time.slice(3, 5))).toISOString().replace('Z', '')
    //     return formattedDate;
    // }

    const handleDatesChange = (data) => {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE })
        } else {
            setDateRange(data)
        }
    }

    const handleCancel = () => {
        if (dateFrom && dateTo) {
            setSelectedDateFrom(dateFrom)
            setSelectedDateTo(dateTo)
            setShowDatepickers(true)
        } else {
            setShowDatepickers(false)
        }
        setSelectedTimeType(timeType)
        setSelectedTimeFormat(timeFormat)
        setOpen(false)
    }

    const resetDateRange = () => {
        setTimeFrom(defaultTime)
        setTimeTo(defaultTime)
        setSelectedDateFrom(null)
        setSelectedDateTo(null)
        setDateRange({ focusedInput: START_DATE, startDate: null, endDate: null })
    }

    const handleSave = () => {
        // if (dateRange.startDate && dateRange.endDate) {
        //     let dateFrom = formatDate(dateRange.startDate, timeFrom)
        //     let dateTo = formatDate(dateRange.endDate, timeTo)
        //     if (dateFrom >= dateTo) {
        //         AlertToastr.showErrorAlert(translation.notificationsInvavidDates)
        //         return;
        //     }
        //     setDateFrom(dateFrom)
        //     setDateTo(dateTo)
        // } else {
        //     setDateFrom(null)
        //     setDateTo(null)
        // }
        onSave(selectedTimeType, selectedTimeFormat)
        setOpen(false)
    }

    const dialogContent = (
        <Fragment>
            <div className="formContentHolder">
                <div className="modalTitleHolder hasBackLink">
                    <h1 className='modalTitle text-center'>{translation.manageDateSettingsModalManageDateSettingsLabel}</h1>
                </div>
                <div className="formContent">
                    <ManageDateFormat
                        selectedTimeFormat={selectedTimeFormat}
                        setSelectedTimeFormat={setSelectedTimeFormat}
                        selectedTimeType={selectedTimeType}
                        setSelectedTimeType={setSelectedTimeType}
                        type={type}
                        chartType={chartType}
                    />
                    {(type === 'dataTable' && showDateRangeSection) && <div className="formContentBlock noBorder">
                        <FormControl component="fieldset">
                            <FormLabel component="label" className={'radioLabel text-bold'}>{translation.manageDateSettingsModalDateRangeLabel}</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="lifetime"
                                onChange={(e) => {
                                    setShowDatepickers(!showDatepickers)
                                }}>
                                <FormControlLabel
                                    onClick={resetDateRange}
                                    value="lifetime"
                                    checked={!showDatepickers}
                                    control={<Radio color="default" />}
                                    label={<RadioLabel label={translation.manageDateSettingsModalAllLabel} />}
                                />
                                <FormControlLabel
                                    value="setTime"
                                    checked={showDatepickers}
                                    control={<Radio color="default" />}
                                    label={<RadioLabel label={translation.manageDateSettingsModalSelectRangeLabel} />}
                                />
                            </RadioGroup>
                        </FormControl>
                        {showDatepickers &&
                            <div className="collapseBodyHolder">
                                <div className="calendarHolder">
                                    <ThemeProvider
                                        theme={{
                                            reactDatepicker: {
                                                daySize: [36, 40],
                                                fontFamily: '"Open Sans", sans-serif',
                                                colors: {
                                                    accessibility: 'transparent',
                                                    selectedDay: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                                                    selectedDayHover: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                                                    primaryColor: theme === 'Light' ? 'rgba(68, 68, 68, 0.12)!important' : 'rgba(255, 255, 255, 0.12)!important',
                                                },
                                            },
                                        }}
                                    >
                                        <DateRangePicker
                                            dateFrom={selectedDateFrom}
                                            dateTo={selectedDateTo}
                                            setTimeFrom={setTimeFrom}
                                            setTimeTo={setTimeTo}
                                            dateRange={dateRange}
                                            handleDatesChange={handleDatesChange}
                                        />
                                    </ThemeProvider>
                                </div>
                                <div className="timeHolder">
                                    <div className="inputHolder">
                                        <TextField
                                            id="startTime"
                                            label={translation.manageDateSettingsModalStartTimeLabel}
                                            type="time"
                                            variant="outlined"
                                            fullWidth
                                            value={timeFrom}
                                            onChange={e => setTimeFrom(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        />
                                    </div>
                                    <div className="inputHolder">
                                        <TextField
                                            id="endtime"
                                            label={translation.manageDateSettingsModalStopTimeLabel}
                                            type="time"
                                            variant="outlined"
                                            fullWidth
                                            value={timeTo}
                                            onChange={e => setTimeTo(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>}
                    </div>}
                    <div className="btnHolder end fixedWidthBtn">
                        <Button variant="outlined" onClick={handleCancel}>{translation.cancelButtonLabel}</Button>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            color="primary">
                            {translation.saveButtonLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    return <Dialog open={isOpen} className='respModal scrollContentModal autoHeight'>{dialogContent}</Dialog>

}

export default ManageDateFormatModal
