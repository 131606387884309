import React from 'react';
// import Button from '@material-ui/core/Button';
import { useAuth } from '../Contexts/AuthProvider';
import ExportDownloadSignalR from './SignalrSubscribes/ExportDownloadSignalR';
import { Button } from '@material-ui/core';
import useTranslation from '../Hooks/useTranslation';
import useDeleteDataExport from '../Hooks/useDeleteDataExport';
import { downloadAs } from '../helpers';

const DownloadFileButton = ({ tabType, getExportRequest }) => {

    const { token, currentCompany } = useAuth();
    const deleteExportMutation = useDeleteDataExport(tabType);
    const translation = useTranslation();

    const { isCompleted, url, fileName } = getExportRequest.data;

    const handleFinish = () => {
        getExportRequest.refetch().then();
    };

    const onDownloadFile = async (e) => {
        e.preventDefault();
        if (e.currentTarget.href && e.currentTarget.download) {
            const { href, download } = e.currentTarget;
            await downloadAs(href, download);
        }
        await deleteExportMutation.mutateAsync();
        await getExportRequest.refetch();
    };

    if (isCompleted === undefined) return null;

    return (
        <>
            <Button
                onClick={onDownloadFile}
                disabled={!isCompleted}
                variant="outlined"
                color="primary"
                href={isCompleted ? url : '#'}
                download={fileName || ''}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <span style={{ textAlign: 'center', fontSize: '.9em' }}>
                        {
                            isCompleted
                                ? translation.downloadBtnLabel
                                : translation.buildingCsvLabel
                        }
                    </span>
                    {fileName && (
                        <span style={{ textAlign: 'center', fontSize: '.5em' }}>{fileName}</span>
                    )}
                </div>

            </Button>

            {
                (isCompleted === false) && (
                    <ExportDownloadSignalR
                        tabType={tabType}
                        onFinish={handleFinish}
                        token={token}
                        companyId={currentCompany?.id}
                    />
                )
            }

        </>
    );
};

export default DownloadFileButton;
