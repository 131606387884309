import React  from 'react';
import { Button, Dialog } from '@material-ui/core';

const ErrorTemplateModal = ({ errors, onOk, isOpen }) => {
    const title = errors?.type === 'error' ? 'Error' : 'Warning';
    const iconClass = errors?.type === 'error'
        ? 'modalTitle hasIcon error'
        : 'modalTitle hasIcon warning';

    if (!errors) return null;
    return (
        <Dialog
            open={isOpen}
        >
            <div className="modalTitleHolder mb16">
                <h2 className={iconClass}>{title}</h2>
            </div>
            <ul className="errorList">
                {
                    errors.messages && errors.messages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>))
                }
            </ul>
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button color={'primary'}
                        onClick={onOk}
                        variant={'contained'}>
                    Ok
                </Button>
            </div>
        </Dialog>
    );
};

export default ErrorTemplateModal;
