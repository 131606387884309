import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dialog, TextField, MenuItem } from '@material-ui/core';
import UnitList from 'Components/Units/unit-list/unit-list.component';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { jobChannels } from 'utils/functions';
import useTranslation from 'Hooks/useTranslation';
import DatatableFilters from '../../DatatableFilters';

const ManageChannelsModal = props => {
    const {
        jobUnits,
        type,
        isOpen,
        showBackButton,
        onCancel,
        onSave,
        onBack,
        editedModel,
        enableFilterWidget = false,
        setSelectedCsvChannels,
        showManageChannelsFirst,
        selectedCsvChannels
    } = props;

    const [values, setValues] = useState([]);
    const [mainXYChannel, setMainXYChannel] = useState('');
    const [chartType, setChartType] = useState('timeBased');
    const [search, setSearch] = useState('');
    const [initialValues, setInitialValues] = useState([]);
    const translation = useTranslation();
    const [datatableFilters, setDatatableFilters] = useState({
        'filterType': 'none',
        'value': 0,
    });

    //init
    useEffect(() => {
        if (editedModel) {
            const newValues = editedModel.channels
                ? editedModel.channels.map(ch => {
                    return { ...ch };
                })
                : [];
            setValues(newValues);
            setInitialValues(newValues);
            setChartType(editedModel.chartType || 'timeBased');
            setMainXYChannel(editedModel.mainXYChannel || '');
        } else if (showManageChannelsFirst) {
            setInitialValues(selectedCsvChannels);
            setValues(selectedCsvChannels);
        }
         else {
            setValues([]);
            setInitialValues([]);
        }
    }, [editedModel, selectedCsvChannels, showManageChannelsFirst]);
 

    const onMainXYChannelChanged = (value) => {
        setMainXYChannel(value);
        if (values.find(x => x.id === value)) {
            setValues(values => {
                let newValues = values.filter(x => x.id !== value);
                return newValues;
            });
        }
    };

    const canSave = () => {
        if (selectedCsvChannels?.length > 0) return true;
        if (chartType === 'timeBased' && values.length > 0)
            return true;

        if (chartType === 'digitalDisplay' && values.length > 0)
            return true;

        return chartType === 'dataBased' && mainXYChannel && values.length > 0;
    };

    const reset = () => {
        setSearch('');
        setValues([]);
        setMainXYChannel('');
        setChartType('timeBased');
        setInitialValues([])
    };

    const handleCancel = () => {
        reset();
        onCancel();
    };

    const handleBack = () => {
        // reset();
        onBack();
    };

    const save = () => handleSave();
    const saveInNewDashboard = () => handleSave(true);

    const handleSave = (openInNewDashboard = false) => {
        if (canSave()) {
            setSearch('');
            let savedData = {};

            const sortedChannels = [...values].sort((a, b) => {
                const aUnitId = jobUnits.find(unit => unit.id === a.unitId).dmsId;
                const bUnitId = jobUnits.find(unit => unit.id === b.unitId).dmsId;
                if (aUnitId > bUnitId)
                    return 1;
                if (aUnitId < bUnitId)
                    return -1;
                return a.dmsId > b.dmsId ? 1 : -1;
            });
            
            if(showManageChannelsFirst) {
                setSelectedCsvChannels(sortedChannels)
            }

            if (type === 'chartView') {
                savedData = {
                    name: translation.dashboardChartViewLabel,
                    type: type,
                    channels: sortedChannels,
                    chartType: chartType,
                    mainXYChannel,
                };
                if (editedModel) {
                    savedData.yAxisSettings = editedModel.yAxisSettings;
                    savedData.xAxisSettings = editedModel.xAxisSettings;
                    savedData.timeType = editedModel.timeType;
                    savedData.timeFormat = editedModel.timeFormat;
                }

            }

            if (type === 'digitalView') {
                savedData = {
                    name: translation.dashboardDigitalViewLabel,
                    type: type,
                    channels: sortedChannels,
                };
            }

            if (type === 'dataTable') {
                savedData = {
                    name: translation.dashboardDataTableLabel,
                    type: type,
                    channels: sortedChannels,
                    filters: datatableFilters,
                };

                if (editedModel) {
                    if (editedModel.filters) {
                        savedData.filters = {...editedModel.filters}
                    }
                }
            }
            onSave(savedData, openInNewDashboard);
            reset();
        }
    };

    const modalTitle = () => {
        if (editedModel)
            return translation.dashboardManageChannelsLabel;
        else {
            return translation.dashboardManageChannelsLabel;
            // if (type === 'chartView')
            //     return translation.dashboardAddNewChartLabel;
            // if (type === 'digitalView')
            //     return translation.dashboardAddNewDigitalDisplayLabel;
            // if (type === 'dataTable')
            //     return translation.dashboardAddNewDataTableLabel;
        }
        
    };

    const databasedChartChannelsOptions = () => {
        const availableChannels = jobChannels(jobUnits);
        return availableChannels.filter(ch => !values.includes(ch.id));
    };

    const renderFilters = () => {
        if (!enableFilterWidget) return null;
        if (type === 'dataTable') return (
            <DatatableFilters
                initData={editedModel?.filters}
                onChange={setDatatableFilters}
            />
        );
        return null;
    };

    const dialogContent = (
        <Fragment>
            <div className="modalTitleHolder mb16 hasBackLink">
                <div className="backLinkHolder">
                    {
                        showBackButton && (
                            <IconButton aria-label="back" size="small" onClick={handleBack}>
                                <ArrowBackIcon fontSize="inherit" />
                            </IconButton>
                        )
                    }
                </div>
                <h1 className="modalTitle text-center">{modalTitle()}</h1>
            </div>

            {renderFilters()}

            {
                type === 'chartView' && (
                    <Fragment>
                        {/* {
                            !editedModel && (<div className={chartType === 'dataBased' ? 'optionsSwitch in' : 'optionsSwitch'}>
                                <div className='option'
                                    onClick={() => setChartType('timeBased')}>
                                    Time Based
                            </div>
                                <div className='option'
                                    onClick={() => setChartType('dataBased')}>
                                    Data Based
                            </div>
                            </div>)
                        } */}
                        {
                            chartType === 'dataBased' &&
                            <TextField
                                variant="outlined"
                                margin="normal"
                                select
                                fullWidth
                                label="Channel"
                                name="channel"
                                onChange={e => onMainXYChannelChanged(e.target.value)}
                                value={mainXYChannel}>
                                {
                                    databasedChartChannelsOptions().map((item, index) =>
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nameLocalized}
                                        </MenuItem>)
                                }
                            </TextField>
                        }
                    </Fragment>
                )
            }

            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="search"
                label={translation.dashboardSearchChannelLabel}
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            {
                jobUnits && (
                    <UnitList units={jobUnits}
                              search={search}
                              selectedChannels={initialValues}
                              selectedCsvChannels={selectedCsvChannels}
                              disabledChannels={[mainXYChannel]}
                              setInitialValues={setInitialValues}
                              setValues={setValues} />
                )
            }

            <div className="btnHolder mt22 fixedWidthBtn center">
                <Button onClick={handleCancel} variant="outlined">{translation.cancelButtonLabel}</Button>
                {!showManageChannelsFirst && !selectedCsvChannels?.length > 0 && 
                    <Button onClick={saveInNewDashboard}
                            disabled={!canSave()}
                            variant="outlined">
                        {translation.openInNewWindowButtonLabel}
                    </Button>
                }
                <Button onClick={save}
                        disabled={!canSave()}
                        variant="contained"
                        color="primary">
                    {showManageChannelsFirst || selectedCsvChannels?.length  ? translation.nextButtonLabel : translation.openButtonLabel}
                </Button>
            </div>
        </Fragment>
    );

    return (
        <Dialog open={isOpen} className="respModal">
            {dialogContent}
        </Dialog>
    );
};

export default ManageChannelsModal;
