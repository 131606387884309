import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import UnitBoxLoader from './UnitBoxLoader'
import useUnitInitChannelsDataUnit from '../../../Hooks/useUnitInitChannelsData'
import UnitTimeBasedChart from '../../Charts/UnitTimeBasedChart'
import UnitBoxChartSignalR from '../../SignalrSubscribes/UnitBoxChartSignalR'
import { useAuth } from '../../../Contexts/AuthProvider'
import UnitBoxChartHeader from './UnitBoxChartHeader'
import ManageAxesModal from '../../modals/ManageAxisModal/ManageAxesModal'
import ManageDateFormatModal from '../../modals/ManageDateFormatModal/ManageDateFormatModal'
import UnitBoxDataError from './UnitBoxDataError'
import { Resizable } from 'react-resizable'
import EventEmitter from '../../../utils/EventEmitter'
import { useBoxes } from '../../../Contexts/BoxContext';

const defaultChartHeight = 450

const UnitBoxChart = props => {

  const {
    boxData,
    onRemove,
    handleEdit,
    dragHandleProps,
    onNewWindowOpen,
    onExportToCsv,
    section = 'unit',
    onUpdateSettings
  } = props

  const {
    unitUid,
    channels,
    yAxisSettings,
    xAxisSettings,
    timeType,
    timeFormat,
    filters,
  } = boxData

  const { token, currentCompany } = useAuth()

  const [showManageAxesModal, setShowManageAxesModal] = useState(false)
  const [showManageDateFormatModal, setManageDateFormatModal] = useState(false)
  const [chartHeight, setChartHeight] = useState(null)
  const [data, setData] = useState(null)
  const [hasDataError, setHasDataError] = useState(false)
  const [currentTimestamp, setCurrentTimestamp] = useState(null)
  const [dateTimeFormat, setDateTimeFormat] = useState('')
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const { startDate, endDate } = useBoxes();
  const signalrRequestBody = useMemo(() => ({
    uId: unitUid,
    channels, ...filters,
    jobType: section,
    dateFrom: startDate,
    dateTo: endDate
  }), [unitUid, channels, filters, section, startDate, endDate])

  const [exportImage, setExportImage] = useState(false);

  const handleExportToImage = () => {
    setExportImage(true);
    setTimeout(() => setExportImage(false), 1000); 
};

  

  const channelsDataUnitRequest = useUnitInitChannelsDataUnit({ unitUid, channels, filters, section, startDate,  endDate  })

  useEffect(() => {
    setChartHeight(defaultChartHeight)
  }, [])

  useEffect(() => {
    // console.log(data, 'data');
  }, [data])

  const chartResizeConfig = useMemo(() => (
    {
      onResize: (e, dir, reftoEl) => {
        setChartHeight(reftoEl.offsetHeight)
      },
      enable: {
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
      minHeight: 200,
    }
  ), [])

  useEffect(() => {
    if (channelsDataUnitRequest.isSuccess) {
      const {
        channelDataResponses,
        lastProcessedTimestamp,
        dateTimeFormat,
        jobId,
      } = channelsDataUnitRequest.data

      if (jobId) {
        EventEmitter.emit('InitUnitBoxChartWithJobId', jobId)
      }

      const serverDataMap = {}

      if (!channelDataResponses) {
        setHasDataError(true)
        return
      }

      channelDataResponses.forEach(channelData => {
        const { channel, data } = channelData
        serverDataMap[channel.code] = data
      })
      setData(serverDataMap)
      setCurrentTimestamp(lastProcessedTimestamp)
      setDateTimeFormat(dateTimeFormat)

    }
  }, [channelsDataUnitRequest.data, channelsDataUnitRequest.isSuccess])

  const handleLiveData = useCallback(incomingData => {
    if (incomingData && incomingData.length) {
      setData(prevData => {
        const newData = prevData ? { ...prevData } : {}
        incomingData.forEach((channelData) => {
          const { channel, data } = channelData
          const newChannelData = newData[channel.code] || []
          newData[channel.code] = [...newChannelData, ...data]
        })
        return newData
      })
    }

  }, [setData])

  const openManageAxesModal = () => {
    setShowManageAxesModal(true)
  }

  const saveAxesSettings = (yAxisSettings, xAxisSettings, timeType, timeFormat) => {
    boxData.yAxisSettings = yAxisSettings
    boxData.xAxisSettings = xAxisSettings
    boxData.timeType = timeType
    boxData.timeFormat = timeFormat
    if (onUpdateSettings) onUpdateSettings()
  }

  const saveDateFormat = (timeType, timeFormat) => {
    boxData.timeType = timeType
    boxData.timeFormat = timeFormat
    if (onUpdateSettings) onUpdateSettings()
  }

  const content = hasDataError
    ? <UnitBoxDataError/>
    : (
      <Resizable {...chartResizeConfig}>
        <UnitTimeBasedChart
          id={boxData.id}
          startTime={currentTimestamp}
          channels={channels}
          channelsData={data}
          chartHeight={chartHeight}
          timeType={timeType}
          timeFormat={timeFormat}
          yAxisSettings={yAxisSettings}
          xAxisSettings={xAxisSettings}
          dateTimeFormat={dateTimeFormat}
          onExportImage={exportImage} 
        />
      </Resizable>
    )

  return (
    <Fragment>
      <UnitBoxLoader
        loading={channelsDataUnitRequest.isLoading || channelsDataUnitRequest.isFetching}
      />

      <UnitBoxChartHeader
        box={boxData}
        onRemove={onRemove}
        handleEdit={handleEdit}
        dragHandleProps={dragHandleProps}
        onManageAxesSettings={openManageAxesModal}
        onNewWindowOpen={onNewWindowOpen}
        onExportToCsv={onExportToCsv}
        onExportToImage={handleExportToImage} 
      />

      <div className="pageCard">
        {channelsDataUnitRequest?.data?.noData ? (
          <div style={{
            paddingTop: '30px',
            color: 'rgb(144, 144, 144)',
            marginBottom: '20px', textAlign: 'center'
          }}>No data found.</div>
        ) : (
          <div>{content}</div>
        )}
      </div>

      <ManageAxesModal
        isOpen={showManageAxesModal}
        setOpen={setShowManageAxesModal}
        editedModel={boxData}
        reverseChart={false}
        onSave={saveAxesSettings}
        timeType={timeType}
        timeFormat={timeFormat}
      />

      <ManageDateFormatModal
        isOpen={showManageDateFormatModal}
        setOpen={setManageDateFormatModal}
        onSave={saveDateFormat}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        timeType={timeType}
        timeFormat={timeFormat}
        type="chartView"
        chartType="timeBased"
      />

      {
        (currentTimestamp && !channelsDataUnitRequest.isFetching) && (
          <UnitBoxChartSignalR
            token={token}
            companyId={currentCompany?.id}
            requestBody={signalrRequestBody}
            onIncomingData={handleLiveData}
            lastProcessedTimestamp={currentTimestamp}
            section={section}
          />
        )
      }

    </Fragment>
  )
}

export default UnitBoxChart
