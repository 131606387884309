import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'
import Types from './types'

const INITIAL_STATE = {
    isAuthenticated: false,
    user: null,
    signinRequest: {loading: false, error: null},
    signoutRequest: {loading: false, error: null},
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case Types.RESET_STATE:
            return INITIAL_STATE

        case Types.SIGNIN_REQUEST:
            return {
                ...state,
                signinRequest: {loading: true, error: null}
            }
        case Types.SIGNIN_SUCCESS:
            return {
                ...state,
                signinRequest: {loading: false, error: null},
                isAuthenticated: true
            }
        case Types.SIGNIN_FAILURE:
            return {
                ...state,
                signinRequest: {loading: false, error: action.payload}
            }
        case Types.SIGNIN_RESET:
            return {
                ...state,
                signinRequest: {loading: false, error: null}
            }
        case Types.SIGNOUT_REQUEST:
            return {
                ...state,
                signoutRequest: {loading: true, error: null}
            }
        case Types.SIGNOUT_SUCCESS:
            return INITIAL_STATE

        case Types.SIGNOUT_FAILURE:
            return {
                ...state,
                signoutRequest: {loading: false, error: action.payload}
            }
        case Types.SET_AUTH_USER:
            return {
                ...state,
                user: action.payload
            }

        default:
            return state
    }
}

const persistConfig = {
    key: 'auth',
    storage,
    whitelist: ['isAuthenticated', 'user']
}

export default persistReducer(persistConfig, reducer)
