import React, { Fragment, useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import UnitList from "./UnitList";
import ExportCsvForm from "./ExportCsvForm";
import useTranslation from "../../../Hooks/useTranslation";
import UnitChannelList from "./UnitChannelList";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useBoxes } from "Contexts/BoxContext";

const ExportCsvUnitModal = (props) => {
  const {
    isOpen,
    onCancel,
    onExportData,
    setCurrentUnit,
    currentUnitTitle,
    unitsRequest,
    dontShowChangeUnitModal,
    currentUnitModel,
    setSelectedCsvChannels,
    selectedCsvChannels
  } = props;

  const { unitBoxes, modalStep } = useBoxes();

  const translation = useTranslation();

  const [step, setStep] = useState("selectUnit");

  const { data: units } = unitsRequest;

  useEffect(() => {
    if (!isOpen) return;
    if (unitBoxes[0]?.type === "chartView" || unitBoxes[0]?.type === "digitalView" || unitBoxes[0]?.type === "dataTable" || currentUnitModel) {
      setStep("selectChannels");
    } else if (currentUnitTitle && !dontShowChangeUnitModal) {
      setStep("selectChannels");
    }
    if(modalStep === "exportCsv") {
      setStep("exportCsv")
    }
  }, [isOpen, currentUnitTitle, dontShowChangeUnitModal, modalStep, unitBoxes, currentUnitModel]);

  const handleSaveUnit = (unitId) => {
    setStep("selectChannels");
    setCurrentUnit(unitId);
  };

  const handleSaveChannels = () => {
    setStep("exportCsv");
  };

  const onModalCsvCancel = () => {
    onCancel();
    setStep('');
  };

  const onExportCsvData = (data) => {
    onCancel();
    setStep('');
    onExportData(data);
  };

  const renderBackToUnitButton = () => {
    if (step === "selectUnit" || step === '') return null;
    return (
      <IconButton
        aria-label="back"
        size="small"
        onClick={() => {
          if (step === "selectChannels") setStep("selectUnit");
          if (step === "exportCsv") setStep("selectChannels");
        }}
      >
        <ArrowBackIcon fontSize="inherit" />
      </IconButton>
    );
  };

  const renderTitle = () => {
    switch (step) {
      case "selectUnit":
        return "Units";
      case "selectChannels":
        return translation.dashboardManageChannelsLabel;
      case "exportCsv":
        return translation.exportToCsvModalExportToCsvLabel;
      default:
        return "";
    }
  };

  const renderUnitList = () => {
    if (step !== "selectUnit") return null;

    return (
      <UnitList items={units} onCancel={onCancel} onSave={handleSaveUnit} />
    );
  };

  const renderChannelsList = () => {
    if (step !== "selectChannels") return null;

    return (
      <UnitChannelList
        unit={currentUnitModel}
        onCancel={onCancel}
        onSave={handleSaveChannels}
        unitsRequest={unitsRequest}
        setSelectedCsvChannels={setSelectedCsvChannels}
        isNotVisible = {step === "selectChannels"}
        selectedCsvChannels={selectedCsvChannels}
      />
    );
  };

  const renderCsvForm = () => {
    if (step !== "exportCsv") return null;

    return (
      <ExportCsvForm
        unit={currentUnitModel}
        onExportData={onExportCsvData}
        unitName={currentUnitTitle}
        onCancel={onModalCsvCancel}
        setStep={setStep}
      />
    );
  };

  const modalClass =
    step === "selectUnit" || step === "selectChannels"
      ? "respModal"
      : "respModal autoHeight scrollContentModal";

  const renderDialogContent = () => {
    const modalTitleClass =
      step === "selectUnit" || step === "selectChannels"
        ? "modalTitle text-center mb10"
        : "modalTitle text-center";

    const modalBtnClass =
      step === "selectUnit" || step === "selectChannels"
        ? "backLinkHolder"
        : "backLink";    

    return (
      <Fragment>
        <div className="modalTitleHolder hasBackLink">
          <div className={modalBtnClass}>{renderBackToUnitButton()}</div>
          <h1 className={modalTitleClass}>{renderTitle()}</h1>
        </div>

        {renderUnitList()}
        {renderChannelsList()}
        {renderCsvForm()}
      </Fragment>
    );
  };

  return (
    <Dialog open={isOpen} className={modalClass}>
      {renderDialogContent()}
    </Dialog>
  );
};

export default ExportCsvUnitModal;
