import Types from './types'

export const signinRequest = data => ({type: Types.SIGNIN_REQUEST, payload: data})
export const signinSuccess = data => ({type: Types.SIGNIN_SUCCESS, payload: data})
export const signinFailure = error => ({type: Types.SIGNIN_FAILURE, payload: error})
export const signinReset = () => ({type: Types.SIGNIN_RESET})

export const signoutRequest = () => ({type: Types.SIGNOUT_REQUEST})
export const signoutSuccess = () => ({type: Types.SIGNOUT_SUCCESS})
export const signoutFailure = error => ({type: Types.SIGNOUT_FAILURE, payload: error})

export const setAuthUser = data => ({type: Types.SET_AUTH_USER, payload: data})
