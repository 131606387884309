import {all, call} from 'redux-saga/effects'
import authSagas from './modules/auth/sagas'
import dashboardSagas from './modules/dashboard/sagas'

export default function* () {
    yield all([
        call(authSagas),
        call(dashboardSagas)
    ])
}
