import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, CircularProgress, Link, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { blue, green } from '@material-ui/core/colors'
import useTranslation from 'Hooks/useTranslation'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        color: green[900],
        backgroundColor: green['A200'],
        marginBottom: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(3, 0, 2),
        position: 'relative',
    },
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}))

const SignInLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} to="/signin" {...props} />)

const PasswordResetCodeRequestForm = ({ handleSubmit, register, watch, onSubmit, request }) => {
    const translation = useTranslation();

    const classes = useStyles()

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>


            <div className="authForm hasHeight xl">
                <p className="mb16">{translation.forgotPasswordPageEnterEmailLabel}</p>
                <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={translation.forgotPasswordPageEmailAddressLabel}
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <div className='btnHolder fullWidthMobile'>
                    <div className="part">
                        <Link component={SignInLink} className='linkPrimary'>
                            {translation.forgotPasswordPageSignInLabel}
                        </Link>
                    </div>
                    <div className="part">
                        <Button
                            disabled={request.loading || !watch('email')}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {translation.sendButtonLabel}
                        </Button>
                        {request.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>
            </div>
        </form>
    )
}

PasswordResetCodeRequestForm.defaultProps = {
    values: {},
    errors: {}
}

export default PasswordResetCodeRequestForm
