import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useDepartmentComponents,
    useNotificationsCUD,
    useNotificationsListRequest,
} from '../../../Hooks/useNotificationReportsHooks';
import EventEmitter from '../../../utils/EventEmitter';
import ListTable from '../../../Components/ListTable';
import useTranslation from '../../../Hooks/useTranslation';
import NotificationListItemActions from './NotificationListItemActions';
import SaveNotificationModal from './Modals/SaveNotificationModal';
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component';
import { setPageTitle } from '../../../Store/modules/mms/actions';

const NotificationsPage = () => {

    const currentCompany = useSelector(state => state?.dashboard?.currentCompany);
    const currentDepartment = useSelector(state => state?.mms?.currentDepartment);
    const [editableModel, setEditableModel] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const translation = useTranslation();
    const { deleteMutation, invalidateList } = useNotificationsCUD();
    const [ids, setIds] = useState([]);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle(translation.notificationsPageLabel))
    }, [dispatch, translation]);

    const notificationsListRequest = useNotificationsListRequest({
        companyId: currentCompany?.id,
        departmentId: currentDepartment?.id,
    });

    const componentsListRequest = useDepartmentComponents({
        companyId: currentCompany?.id,
        departmentId: currentDepartment?.id,
        short: true
    });

    const tableColumns = useMemo(() => {
        return [
            {
                Header: translation.tableColumnName,
                accessor: 'name',
                id: "notificationsTblHeaderName",
                Cell: ({ value }) => <span className="text-semi-bold">{value}</span>,
            },
            {
                Header: translation.scheduledReportsPageRecipientsHeader,
                accessor: 'recipients',
                id: "notificationsTblHeaderInterval",
                Cell: ({ value }) => {
                    return value.join(', ').length > 30 ?
                        `${value.join(', ').substring(0, 30)}...` :
                        value.join(', ');
                },
            },
            {
                Header: translation.notificationsComponentsQTYHeader,
                accessor: 'componentIds',
                className: 'text-center',
                id: "notificationsTblHeaderRecipients",
                Cell: ({ value }) => value.length,
            },
            {
                Header: translation.scheduledReportsPageStatusHeader,
                accessor: 'isActive',
                className: 'btnCell',
                id: "notificationsTblHeaderStatus",
                Cell: ({ value }) => {
                    return (
                        <div style={{ textAlign: 'right' }}>
                            {
                                value
                                    ? <span className="statusItem on">{translation.scheduledReportsPageStatusLabelActive}</span>
                                    : <span className="statusItem off">{translation.scheduledReportsPageStatusLabelInActive}</span>
                            }
                        </div>
                    );
                },
            },
            {
                Header: '',
                id: 'actions',
                className: 'btnCell',
                Cell: ({ value, row }) => {
                    return (
                        <div>
                            <NotificationListItemActions
                                item={row.original}
                                onAction={(action, item, payload) => {
                                    if (action === 'edit') {
                                        setEditableModel(item);
                                        setShowSaveModal(true);
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
        ];
    }, [translation]);

    const data = useMemo(() => {
        if (!notificationsListRequest.data) return [];
        if (!Array.isArray(notificationsListRequest.data.data)) return [];
        return notificationsListRequest.data.data;
    }, [notificationsListRequest.data]);

    const components = useMemo(() => {
        if (!componentsListRequest.data) return [];
        if (!Array.isArray(componentsListRequest.data.data)) return [];
        return componentsListRequest.data.data.filter(item => !item.parentComponentId).map(item => ({ id: item.id, name: item.fullName }));
    }, [componentsListRequest.data]);

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true);

        const listener = EventEmitter.addListener('CreateNotification', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        const onEvent = () => {
            setShowDeleteConfirm(true);
        }

        const listener = EventEmitter.addListener('RemoveBatchNotifications', onEvent);
        return () => {
            listener.remove();
        };
    }, );

    const deleteItems = () => {
        deleteMutation.mutateAsync(ids).then(() => invalidateList().then());
        setShowDeleteConfirm(false);
    }

    const handleIdsChange = useCallback(
        (ids) => {
            setIds(ids);
            EventEmitter.emit('NotificationsSelected', !!ids.length)
        },
        [],
    );


    if (!currentCompany && !currentDepartment) return null;

    return (
        <div className="pmPageHolder">
            <div className="pmPageBlock">
                <div className="pmPageBlockTitle">{translation.notificationsPageLabel}</div>
                <ListTable
                    isSelectionColumn
                    tableClass="pmTable"
                    wrapperClass="pmTableHolder"
                    enableSearch={false}
                    request={notificationsListRequest}
                    columns={tableColumns}
                    data={data}
                    onSelectedRows={handleIdsChange}
                />
                <SaveNotificationModal
                    isOpen={showSaveModal}
                    onClose={() => {
                        setShowSaveModal(false);
                        setEditableModel(null);
                    }}
                    components={components}
                    departmentId={currentDepartment?.id}
                    model={editableModel}
                />
                <DeleteItemModal isOpen={showDeleteConfirm}
                                 loading={deleteMutation.isLoading}
                                 isOpenManager={() => {
                                 }}
                                 onCancel={() => {
                                     setShowDeleteConfirm(false);
                                 }}
                                 message={translation.formatString(translation.removeNotificationsModalMessageLabel)}
                                 onDeleteModalClose={deleteItems}
                />
            </div>
        </div>
    );
};

export default NotificationsPage;
