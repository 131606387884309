import React, { useMemo } from 'react';
import BoxHeaderContextMenuComponent from 'Components/Box/BoxHeaderContextMenu.component';
import TimeTypeMenu from 'Components/Box/TimeTypeMenu.component';
import TimeFormatMenu from 'Components/Box/TimeFormatMenu.component';
import IconButton from '@material-ui/core/IconButton';
import { useAuth } from 'Contexts/AuthProvider';

const DragIcon = () => <svg className="dragIcon" />;

const UnitBoxChartHeader = props => {
    const {
        handleEdit,
        onManageAxesSettings,
        onManageDateFormat,
        onExportToCsv,
        onNewWindowOpen,
        onRemove,
        timeType,
        onChangeTimeType,
        timeFormat,
        onChangeTimeFormat,
        reverse,
        tabId,
        itemId,
        dragHandleProps,
        onExportToImage
    } = props;

    const { currentUser } = useAuth();
    const allowExport = useMemo(() => currentUser.canExportCsv(), [currentUser]);

    return (
        <div className="boxHeader formHolder">
            <div className="chartHeadingOptions">
                <div className="timeTypeButtons">
                    <TimeTypeMenu
                        currentType={timeType}
                        onChangeTimeType={onChangeTimeType}
                    />
                    <TimeFormatMenu
                        currentFormat={timeFormat}
                        onChangeTimeFormat={onChangeTimeFormat}
                        reverse={reverse}
                    />
                </div>
            </div>
            <div className="contextMenu">
                <IconButton {...dragHandleProps} >
                    <DragIcon class="contextMenuIcon" />
                </IconButton>
            </div>
            <div className="contextMenu">
                <BoxHeaderContextMenuComponent
                    onNewWindowOpen={onNewWindowOpen}
                    onManageChannel={handleEdit}
                    onRemove={onRemove}
                    onManageAxesSettings={onManageAxesSettings}
                    onManageDateFormat={onManageDateFormat}
                    onExportToCsv={onExportToCsv}
                    allowExport={allowExport}
                    type='chartView'
                    tabId={tabId}
                    itemId={itemId}
                    onExportToImage={onExportToImage}
                />
            </div>
        </div>
    );
};

export default UnitBoxChartHeader;
