import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, TextField } from '@material-ui/core';
import { useAuth } from 'Contexts/AuthProvider';
import useTranslation from 'Hooks/useTranslation';
import useSaveTemplate from '../../../Hooks/useSaveTemplate';
import AlertToastr from '../../../utils/alert';

const SaveUnitTemplateModal = props => {

    const {
        isOpen,
        onCancel,
        onSuccessSave,
        loadedTemplate,
        currentUnitModel,
        type
    } = props

    const [templateName, setTemplateName] = useState('');
    const translation = useTranslation();
    const { win, currentCompany } = useAuth();

    const saveTemplateMutation = useSaveTemplate({
        id: currentUnitModel?.code,
        type
    });

    const handleClose = () => {
        setTemplateName('');
        onCancel()
    };

    useEffect(() => {
        if (loadedTemplate && loadedTemplate.name) {
            setTemplateName(loadedTemplate.name);
        }
    }, [loadedTemplate, setTemplateName]);

    const handleSaveTemplate = (asNew = false) => () => {
        if (win) {
            let templateId = (loadedTemplate && loadedTemplate.id) || null;
            if (asNew)
                templateId = null;

            saveTemplateMutation.mutateAsync({
                tabId: win?.id,
                templateId,
                name: templateName,
                companyId: currentCompany?.id,
            })
                .then(response => {
                    if (response && response.data && response.data.item) {
                        handleClose()
                        onSuccessSave(response.data.item)
                    }
                })
                .catch(error => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        }
    };

    return (
        <Dialog open={isOpen} onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                handleSaveTemplate();
            }
        }}>
            <div className="modalTitleHolder mb16">
                <h1 className="modalTitle">{translation.dashboardSaveTemplateLabel}</h1>
            </div>
            <TextField
                onChange={e => setTemplateName(e.target.value)}
                value={templateName}
                variant="outlined"
                margin="normal"
                fullWidth
                name="Template Name"
                label="Template Name"
                type="text"
            />
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    onClick={handleClose}
                    variant="outlined">
                    {translation.cancelButtonLabel}
                </Button>
                <div className="part">
                    <Button
                        onClick={handleSaveTemplate()}
                        variant="contained"
                        className='mr0'
                        color="primary"
                        disabled={saveTemplateMutation.isLoading}
                    >
                        {translation.saveButtonLabel}
                    </Button>
                    {saveTemplateMutation.isLoading && <CircularProgress size={24} />}
                </div>
            </div>
        </Dialog>
    );
};

export default SaveUnitTemplateModal;
