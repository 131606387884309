import React, { useEffect, useMemo, useState, useCallback } from "react";
import NewIntegrationModal from "Components/modals/new-integration-modal/new-integration-modal.component";
import IntegrationLogsModal from "Components/modals/integration-logs-modal/integration-logs-modal";
import { IconButton, Tooltip } from "@material-ui/core";
import DmsService from "Services/DMS/dms-api.service";
import DeleteItemModal from "Components/modals/delete-item-modal/delete-item-modal.component";
import { makeStyles } from "@material-ui/core/styles";
import AlertToastr from "utils/alert";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Button from "@material-ui/core/Button";
import useTranslation from "Hooks/useTranslation";
import { connect } from "react-redux";
import useUnitDateTypes from "Hooks/useUnitDateTypes";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useAuth } from '../../../Contexts/AuthProvider'

const descConst = "descending";
const ascConst = "ascending";
const arrowDownClassName = "hasArrow";
const arrowUpClassName = "hasArrow up";
const successStatus = 200;

const RemoveIcon = () => <div className="removeIcon" />;
const IcoPlusWhite = () => <div className="plusIcon" />;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const IntegrationsPage = () => {
  const translation = useTranslation();
  const classes = useStyles();
  const [newIntegrationModalIsOpen, onNewIntegrationModalIsOpenChange] =
    useState(false);
  const [removeUserModalIsOpen, onRemoveUserModalIsOpen] = useState(false);
  const [integrationLogsModalIsOpen, onIntegrationLogsModalIsOpenChange] =
    useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [integrationData, setIntegrationData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [integrationTypes, setIntegrationTypes] = useState([]);
  const [integrationDateIntervals, setIntegrationDateIntervals] = useState([]);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const { data: dateTypes, isLoadingUnitDateTypes } = useUnitDateTypes();
  const dateTypesOptions = useMemo(() => {
    return dateTypes.map((item) => ({
      ...item,
      name: item.name || translation.selectDateRangeLabel,
    }));
  }, [dateTypes, translation]);

  const { currentUser, currentCompany } = useAuth()

  const allowIntegration = useMemo(() => currentUser.allowUiIntegrations(), [currentUser])

  const companyId = currentCompany ? currentCompany.id : currentUser?.companyId

  const handleMenuSelect = (itemType) => {
    createUser();
  };

  const fetchIntegrationTypes = async () => {
    try {
      const response = await DmsService.getIntegrationTypes();
      setIntegrationTypes(response.data);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      )
        AlertToastr.showErrorAlert(error.response.data.message);
    }
  };

  const fetchIntegrationDateIntervals = async () => {
    try {
      const response = await DmsService.getIntegrationDateIntervals();
      setIntegrationDateIntervals(response.data);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      )
        AlertToastr.showErrorAlert(error.response.data.message);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);

  const sortedUsers = useMemo(() => {
    let sortableUsers = [...integrations];
    if (sortConfig !== null) {
      if (sortConfig.key === "status") {
        sortableUsers.sort((a, b) => {
          if (a.isActivated < b.isActivated) {
            return sortConfig.direction === ascConst ? -1 : 1;
          }
          if (a.isActivated > b.isActivated) {
            return sortConfig.direction === ascConst ? 1 : -1;
          }
          if (a.isActivated && b.isActivated) {
            if (a.isEnabled < b.isEnabled) {
              return sortConfig.direction === ascConst ? -1 : 1;
            }
            if (a.isEnabled > b.isEnabled) {
              return sortConfig.direction === ascConst ? 1 : -1;
            }
          }
          return 0;
        });
      }
      if (sortConfig.key === "admin") {
        sortableUsers.sort((a, b) => {
          if (a.isAdmin < b.isAdmin) {
            return sortConfig.direction === ascConst ? -1 : 1;
          }
          if (a.isAdmin > b.isAdmin) {
            return sortConfig.direction === ascConst ? 1 : -1;
          }
          if (a.isAdmin && b.isAdmin) {
            if (a.created < b.created) {
              return sortConfig.direction === ascConst ? -1 : 1;
            }
            if (a.created > b.created) {
              return sortConfig.direction === ascConst ? 1 : -1;
            }
          }
          return 0;
        });
      }
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [integrations, sortConfig]);

  const requestSort = (key) => {
    let direction = ascConst;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === ascConst
    ) {
      direction = descConst;
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return;
    }
    if (sortConfig.direction)
      return sortConfig.direction === ascConst
        ? arrowDownClassName
        : arrowUpClassName;
  };

  const fetchIntegrations = useCallback(async () => {
    if(companyId) {
      try {
        let response;
        response = await DmsService.getIntegrations(companyId);
        if (response.status === successStatus) {
          setIntegrations(response.data);
        }
        fetchIntegrationTypes();
        fetchIntegrationDateIntervals();
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Message
        )
          AlertToastr.showErrorAlert(error.response.data.Message);
      }
    }
  }, [companyId]);

  useEffect(() => {
    fetchIntegrations().then();
  }, [fetchIntegrations]);

  const onDeleteIntegration = () => {
    DmsService.deleteIntegration(integrationData.id)
      .then((res) => fetchIntegrations().then())
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Message
        )
          AlertToastr.showErrorAlert(error.response.data.Message);
      });
  };

  const editIntegration = (integrationInfo) => {
    setIntegrationData(integrationInfo);
    onNewIntegrationModalIsOpenChange(true);
  };

  const createUser = (e) => {
    setIntegrationData(null);
    onNewIntegrationModalIsOpenChange(true);
  };

  const convertToLocalTime = (utcTime) => {
    if (!utcTime) return '';

    const localTimezone = moment.tz.guess();
  
    return moment.utc(utcTime)
      .tz(localTimezone)
      .format("DD.MM.YYYY, HH:mm");
  };

  return (
    <main>
      <div className="">
        <div className="pageBlock">
          <>
            <div className="pageBtnSection pt0">
              <div className="part">
                <Button
                  className="addItem"
                  variant="contained"
                  color="primary"
                  startIcon={<IcoPlusWhite />}
                  onClick={handleMenuSelect}
                  disabled={!allowIntegration}
                >
                  {translation.settingsTablesNewIntegrationLabel}
                </Button>
              </div>
            </div>
            {/* {!allowIntegration && (
              <div className="tableHolderErrorIntegration">Access to this section is currently unavailable as it requires an active API or API_Plus subscription.</div>
            )} */}
            <div className="pageCard noPadding">
              <div className="tableHolder">
                <table>
                  <thead>
                    <tr className="headingRow">
                      <th
                        onClick={() => requestSort("type")}
                        className={getClassNamesFor("type")}
                      >
                        Integration Type
                      </th>
                      <th
                        onClick={() => requestSort("email")}
                        className={getClassNamesFor("email")}
                      >
                        User Name
                      </th>
                      <th
                        onClick={() => requestSort("status")}
                        className={`text-center ${getClassNamesFor("status")}`}
                      >
                        Password
                      </th>
                      {/* <th
                        onClick={() => requestSort("admin")}
                        className={`text-center ${getClassNamesFor("admin")}`}
                      >
                        Email
                      </th> */}
                      <th
                        onClick={() => requestSort("root")}
                        className={`text-center ${getClassNamesFor("root")}`}
                      >
                        Base URL
                      </th>
                      <th
                        onClick={() => requestSort("activeSessions")}
                        className={`text-center ${getClassNamesFor(
                          "activeSessions"
                        )}`}
                      >
                        Start Date
                      </th>
                      <th
                        onClick={() => requestSort("created")}
                        className={getClassNamesFor("created")}
                      >
                        Update Frequency
                      </th>
                      <th>
                        Next Sync Date
                      </th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedUsers || sortedUsers.length === 0
                      ? sortedUsers.map((integration) => {
                          return (
                            <tr
                              key={integration.id}
                              style={{pointerEvents: !allowIntegration ? 'none' : '', opacity: !allowIntegration ? 0.5 : 1}}
                            >
                              <td onClick={() => editIntegration(integration)}>
                                <div className="integrationInfo">
                                  <span className="userName">
                                    {integration.type}
                                  </span>
                                </div>
                              </td>
                              <td onClick={() => editIntegration(integration)}>{integration.username}</td>
                              <td onClick={() => editIntegration(integration)}>
                                {integration.password
                                  ? "•".repeat(integration.password.length)
                                  : ""}
                              </td>

                              {/* <td>{integration.email}</td> */}
                              <td onClick={() => editIntegration(integration)}>
                                <Tooltip title={integration.url}>
                                  <span>
                                    {integration.url.substring(0, 90)}
                                  </span>
                                </Tooltip>
                              </td>
                              <td onClick={() => editIntegration(integration)}>
                              {convertToLocalTime(integration.startFrom)}
                              </td>
                              <td onClick={() => editIntegration(integration)}>{integration.value} {integration.interval}</td>
                              <td onClick={() => editIntegration(integration)}>{convertToLocalTime(integration.nextSyncDate)}</td>
                              <td onClick={() => editIntegration(integration)} className={`status-item ${integration.status}`} style={{marginTop: '8px'}}>{integration.status}</td>
                              <td className={"actionTableCell"}>
                              <Button
                                  size={"small"}
                                  className={"viewLogsBtn"}
                                  onClick={(e) => {
                                    onIntegrationLogsModalIsOpenChange(true);
                                    setSelectedIntegrationId(integration.id);
                                  }}
                                  style={{pointerEvents: !allowIntegration ? 'none' : ''}}
                                  disabled={!allowIntegration}
                                >
                                  VIEW LOGS
                                </Button>
                                <IconButton
                                  size={"small"}
                                  className={"deleteTableItemBtn"}
                                  onClick={(e) => {
                                    setIntegrationData(integration);
                                    onRemoveUserModalIsOpen(true);
                                  }}
                                  style={{pointerEvents: !allowIntegration ? 'none' : '', opacity: !allowIntegration ? 0.5 : 1}}
                                >
                                  <RemoveIcon disabled={!allowIntegration}/>
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                <div className={classes.root}>
                  <Fade in={isLoading} unmountOnExit>
                    <CircularProgress />
                  </Fade>
                </div>
              </div>
            </div>
          </>
        </div>
        <NewIntegrationModal
          isOpen={newIntegrationModalIsOpen}
          isOpenChangeHandler={onNewIntegrationModalIsOpenChange}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          integrationTypes={integrationTypes}
          integrationDateIntervals={integrationDateIntervals}
          fetchIntegrations={fetchIntegrations}
        />
        <IntegrationLogsModal
          isOpen={integrationLogsModalIsOpen}
          isOpenChangeHandler={onIntegrationLogsModalIsOpenChange}
          integrationId={selectedIntegrationId}
          setSelectedIntegrationId={setSelectedIntegrationId}
          dateTypesOptions={dateTypesOptions}
          isLoadingUnitDateTypes={isLoadingUnitDateTypes}
        />
        <DeleteItemModal
          isOpen={removeUserModalIsOpen}
          isOpenManager={onRemoveUserModalIsOpen}
          message={translation.removeUserModalMessageLabel}
          onDeleteModalClose={onDeleteIntegration}
        />
      </div>
    </main>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  // currentCompany: dashboard.currentCompany,
});

export default connect(mapStateToProps)(IntegrationsPage);
