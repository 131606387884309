import { useEffect } from 'react'
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr'
import { apiBaseUrl } from 'config'
import { useAuth } from 'Contexts/AuthProvider'

const buildHubUrl = (company) => company ? `${apiBaseUrl}/ws/data?companyid=${company.id}` : `${apiBaseUrl}/ws/data`

const protocol = new JsonHubProtocol()
const transport = HttpTransportType.WebSockets


const LiveJobSignalrDataBased = props => {

    const {
        mainXYChannel,
        channels,
        lastProcessedTimestamp,
        onIncomingData,
    } = props

    const { token, currentCompany } = useAuth()

    useEffect(() => {

        if (!token) return

        const options = {
            skipNegotiation: true,
            transport,
            accessTokenFactory: () => {
                return token
            },
            headers: {
                authorization: token ? `Token ${token}` : ``
            }
        }

        const connection = new HubConnectionBuilder()
            .withUrl(buildHubUrl(currentCompany), options)
            .withAutomaticReconnect()
            .withHubProtocol(protocol)
            .configureLogging(LogLevel.Information)
            .build()

        const dataHandler = data => {
            onIncomingData(data)
        }

        connection.on('CustomAxisChannelDataResponse', dataHandler)

        connection.start().then(() => {
            if (mainXYChannel && lastProcessedTimestamp) {
                const databaseChannelsData = {
                    'channelX': {
                        'channelId': mainXYChannel,
                        'unit': 'ms'
                    },
                    'channelsY': channels.map(ch => ({ channelId: ch.id, unit: ch.unit }))
                }

                connection.invoke('GetDataBasedChannelData', databaseChannelsData, lastProcessedTimestamp).then()
            }

        })

        return () => {
            if (connection.state === 'Connected')
                connection.stop()
        }
    }, [mainXYChannel, channels, onIncomingData, lastProcessedTimestamp, token, currentCompany])

    return null
}

export default LiveJobSignalrDataBased

