import React from 'react'
import {toast} from 'react-toastify'

const AlertBody = ({messages}) => <ul>{messages.map((message, idx) => <li key={idx}>{message}</li>)}</ul>

const AlertToastr = {
    showSuccessResponseAlert(response) {
        if (response && response.message) {
            this.showAlert(response.message)
        }
    },

    getErrorResponseMessage(error) {
        if (error && error.response && error.response.data) {
            const errorData = error.response.data
            let alertTitle = errorData.message || ''
            let alertMessage = errorData.errors || ''
            if (!alertMessage) {
                alertMessage = alertTitle
                alertTitle = null
            }
            let output = ''
            if (typeof alertMessage === 'string') {
                output = alertMessage
            }

            if (typeof alertMessage === 'object') {
                Object.keys(alertMessage).forEach(key => {
                    output = alertMessage[key][0]
                })
            }
            return output
        }
        return ''
    },

    showErrorResponseAlert(error) {
        if (error) {
            let alertTitle = error.message || ''
            let alertMessage = error.errors || ''
            if (!alertMessage) {
                alertMessage = alertTitle
                alertTitle = null
            }
            this.showErrorAlert(alertMessage, alertTitle)
        }
    },

    showErrorAlert(message, title = '') {
        this.showAlert(message, title, 'error')
    },

    showWarnAlert(message, title = '') {
        this.showAlert(message, title, 'warn')
    },

    showAlert(message, title = '', type = 'success') {
        let output = ''
        if (typeof message === 'string') {
            output = message
        }

        if (typeof message === 'object') {
            let messagesArray = []
            Object.keys(message).forEach(key => {
                messagesArray.push(message[key][0])
            })
            output = <AlertBody messages={messagesArray}/>
        }

        toast[type](output, title)
    }
}

export default AlertToastr
