import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

export const getUnitById = async ({id, companyId, section}) => {
    const urlMap = {
        'units': `${apiBaseUrl}/units/${id}`,
        'locations': `${apiBaseUrl}/locations/${id}`,
    }
    const url = urlMap[section] || urlMap['units'];

    const { data } = await axios.get(url, {
        headers: { 'CompanyId': companyId },
    });
    return data;
};

export function useUnit(unitId, section = 'units') {
    const { userLoaded, currentCompany } = useAuth();
    return useQuery(
        ['unit-item', section, unitId],
        () => getUnitById({
            id: unitId,
            companyId: currentCompany?.id,
            section
        }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!unitId && userLoaded,
        },
    );
}
