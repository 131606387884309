import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useBoxes } from '../../../Contexts/BoxContext';
import { boxHelper } from '../../../helpers';

const DigitalView = ({
    channelsData,
    infoColors,
    boundChannelList }) => {

    const {codeColors} = useBoxes();


    const coloredChannelsData = useMemo(() => {
        if (!channelsData)
            return []
        let isColoredSuccess = true
        const coloredChannelsData = channelsData.map((channel) => {
            channel.color = boxHelper.colors(channel.id, codeColors)
            return channel
        })
        return isColoredSuccess ? coloredChannelsData : []
    }, [channelsData, codeColors])


    if (!channelsData)
        return null


    return (
        <div className='digitalDisplayList'>
            {
                coloredChannelsData.map((channel) => {
                    return (
                        <li className="digitalDisplayListItem" key={channel.id}>
                            <h3 className='name'>{channel.nameLocalized}</h3>
                            <div className="subtitle">{`${channel.unitName} (${channel.unitCode})`}</div>
                            <div className="displayContent">
                                <div className='data'>
                                    <span className="qnt">{channel.value === 0 ? 0 : channel.value || '-'}</span> {channel.unit}
                                </div>
                                <div className='statusbar' style={{ backgroundColor: channel.color }} />
                            </div>
                        </li>
                    )
                })
            }
        </div>
    )
}

const mapStateToProps = ({ dashboard }) => ({
    currentJob: dashboard.currentJob,
    infoColors: dashboard.infoColors
})

export default connect(mapStateToProps)(DigitalView)
