import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
    addBox,
    addBoxes,
    editBox,
    editBoxAxes,
    removeBox,
    setBoxes,
    setCurrentJob,
} from 'Store/modules/dashboard/actions';
import { getBoxes, getCurrentJob, getJobList } from 'Store/modules/dashboard/selectors';
import DashboardGroup from 'Components/DashboardGroup/DashboardGroup.component';
import DmsService from 'Services/DMS/dms-api.service';
import { jobChannel } from 'utils/functions';
import { useAuth } from 'Contexts/AuthProvider';
import { dashboardHelper, uniqueValues } from 'helpers';
import useTranslation from 'Hooks/useTranslation';
import AlertToastr from 'utils/alert';
import { useParams } from 'react-router-dom';
import { useBoxes } from 'Contexts/BoxContext';

const uuid = require('uuid/v1');

const DashboardInfoPage = props => {
    const { dashboardId } = useParams();
    const translation = useTranslation();
    const [dashboard] = useState(null);
    const { win, initDashboardWindow } = useAuth();
    const [boxChanged, setBoxChanged] = useState(false);
    const [loadedTemplate, setLoadedTemplate] = useState(null);
    const [boundChannelList, setBoundChannelsList] = useState([]);
    const { currentUser } = useAuth();
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser]);
    const {
        changeJobsGlobalFilters, jobsBoxesFilters
    } = useBoxes();

    useEffect(() => {
        if (dashboardId) {
            initDashboardWindow(dashboardId);
        }
    }, [dashboardId, initDashboardWindow]);

    const {
        jobs,             //needs for checking statuses: loading, error
        currentJob,       //current selected job
        setCurrentJob,    //set current job action
        boxes,
        setBoxes,          //list of available boxes,
        addBox,           //add box action
        addBoxes,           //add multiple boxes action
        editBox,
        editBoxAxes,
        removeBox,
    } = props;

    const { currentCompany } = useAuth();

    const syncTabs = useCallback((winId, boxes, colors) => {

        if (isSuperUser) {
            if (currentCompany) {
                DmsService.syncTabWithServer(winId, boxes, colors, currentCompany.id)
                    .then(response => {
                        // console.log(response)
                    })
                    .catch(error => {
                        // console.log(error)
                    });
            }
        } else {
            DmsService.syncTabWithServer(winId, boxes, colors)
                .then(response => {
                    // console.log(response)
                })
                .catch(error => {
                    // console.log(error)
                });
        }
    }, [currentCompany, isSuperUser]);

    useEffect(() => {
        if (win && win.id) {
            //prevent send first request with initial empty boxes
            if (!(!boxChanged && boxes.length === 0)) {
                // syncTabs(win.id, boxes, boundChannelList);
            }
            if (!boxChanged)
                setBoxChanged(true);
        }
    }, [boxes, win, setBoxChanged, boxChanged, boundChannelList, syncTabs]);

    const handleAddBox = (data, openInNewWindow, currentJobModel = null) => {
        if (openInNewWindow) {
            const parentId = win.parentId || win.id;
            dashboardHelper.add({
                id: null,
                parent: parentId,
                box: data,
                job: currentJobModel || currentJob,
                unit: null,
                company: currentCompany,
                filters: jobsBoxesFilters
            });
        } else {
            addBox(data);
        }
    };

    const handleAddBoxes = (data) => {
        const boxes = [];
        data.forEach((boxData, index) => {
            if (boxData.itemPosition.position === 'Left') {
                if (!data[index + 1] || data[index + 1].itemPosition.position !== 'Right') {
                    boxData.itemPosition.position = 'Stretch';
                }
            }
            if (boxData.itemPosition.position === 'Right') {
                if (!data[index - 1] || data[index - 1].itemPosition.position !== 'Left') {
                    boxData.itemPosition.position = 'Stretch';
                }
            }
            boxes.push(boxData);
        });
        addBoxes(boxes);
    };

    const handleEditBox = (data, openInNewWindow) => {
        if (openInNewWindow) {
            const parentId = win.parentId || win.id;
            dashboardHelper.add({
                id: null,
                parent: parentId,
                box: data,
                job: currentJob,
                unit: null,
                company: currentCompany,
            });
            removeBox(data.id);
        } else {
            editBox(data);
        }

    };

    const handleOpenInNewWindow = (boxId) => {
        const movedBox = boxes.find(box => box.id === boxId);
        if (movedBox) {
            const parentId = win.parentId || win.id;
            dashboardHelper.add({
                id: null,
                parent: parentId,
                box: movedBox,
                job: currentJob,
                unit: null,
                company: currentCompany,
                filters: jobsBoxesFilters
            });
            changeJobsGlobalFilters(jobsBoxesFilters);
            removeBox(boxId);
        }
    };

    const handleManageAxesSettings = () => {
        editBoxAxes();
    };

    const handleTemplateLoad = (templateId, jobId, tabs, template) => {
        let isSuccessTemplateLoad = false;
        const jobModel = setCurrentJobById(jobId);
        tabs.forEach(tab => {
            const boxes = tab.items;

            const boxesForNewWindow = [];
            const boxesForParentWindow = [];

            boxes.forEach(templateBox => {
                const boxDataChannels = [];
                if (templateBox.channels)

                    templateBox.channels.forEach(channel => {
                        let jobChannelObject = jobChannel(jobModel, channel.id);
                        if (jobChannelObject) {
                            jobChannelObject = { ...jobChannelObject, unit: channel.unit };
                            boxDataChannels.push(jobChannelObject);
                        }
                    });

                let templateBoxMainXYChannel = '';
                if (templateBox.mainXYChannel) {
                    if (templateBox.mainXYChannel.id) {
                        templateBoxMainXYChannel = templateBox.mainXYChannel.id;
                    } else {
                        templateBoxMainXYChannel = templateBox.mainXYChannel;
                    }
                }
                if (boxDataChannels.length > 0 || templateBox.type === 'eventLogger' || templateBox.type === 'jobProperties') {
                    isSuccessTemplateLoad = true;
                    const boxData = {
                        id: uuid(),
                        name: templateBox.name || '',
                        type: templateBox.type,
                        chartType: templateBox.subType || '',
                        mainXYChannel: templateBoxMainXYChannel,
                        channels: boxDataChannels,
                        itemPosition: templateBox.itemPosition,
                        timeType: templateBox.timeType || 'local',
                        timeFormat: templateBox.timeFormat || 'time',
                        filters: templateBox.filters || {},
                    };
                    if (templateBox.filters) {
                        boxData.filters = { ...boxData.filters, ...templateBox.filters };
                    }
                    addChartSettings(boxData, templateBox);
                    if (tab.isParent || tab.isParentTab) {
                        // open box in current window
                        boxesForParentWindow.push(boxData);

                    } else {
                        // open box in new window
                        boxesForNewWindow.push(boxData);
                    }
                }

            });

            if (boxesForNewWindow.length) {
                handleAddBox(boxesForNewWindow, true, jobModel);
            }

            if (boxesForParentWindow.length) {
                handleAddBoxes(boxesForParentWindow);
            }
        });
        if (template) {
            if (isSuccessTemplateLoad) {
                setLoadedTemplate(template);
            } else {
                setLoadedTemplate(null);
                AlertToastr.showErrorAlert(translation.notificationsLoadTemolateError);
            }
        }
    };

    const addChartSettings = (boxData, templateBox = null) => {
        if (boxData.channels && boxData.type === 'chartView') {
            const units = uniqueValues(boxData.channels.map(channel => channel.unit));
            if (templateBox && templateBox.yAxisSettings) {
                boxData.yAxisSettings = templateBox.yAxisSettings;
            } else {
                boxData.yAxisSettings = uniqueValues(units.map(unit => {
                    return {
                        unit: unit,
                        autoScaling: true,
                        min: undefined,
                        max: undefined,
                    };
                }));
            }

            if (templateBox && templateBox.xAxisSettings) {
                boxData.xAxisSettings = templateBox.xAxisSettings;
            } else {
                if (boxData.chartType === 'timeBased') {
                    boxData.xAxisSettings = {
                        count: undefined,
                        autoScaling: true,
                        unit: 'sec',
                    };
                } else {
                    const mainChannel = jobChannel(currentJob, boxData.mainXYChannel);
                    boxData.xAxisSettings = {
                        unit: mainChannel.unit,
                        autoScaling: true,
                        min: undefined,
                        max: undefined,
                    };
                }
            }
        }
    };

    const setCurrentJobById = jobId => {
        const jobModel = jobs.find(job => job.id === jobId);
        if (jobModel)
            setCurrentJob(jobModel);
        return jobModel;
    };

    return (
        <div className="mainContainer">
            {
                win.id && (
                    <DashboardGroup
                        dashboard={dashboard}
                        key={win.id}
                        jobs={jobs}
                        currentJob={currentJob}
                        setCurrentJob={setCurrentJob}
                        boxes={boxes}
                        loadedTemplate={loadedTemplate}
                        boundChannelList={boundChannelList}
                        setBoundChannelColors={setBoundChannelsList}
                        syncTabs={syncTabs}
                        onTemplateLoaded={handleTemplateLoad}
                        onSuccessTemplateSave={setLoadedTemplate}
                        onManageChannels={() => setLoadedTemplate(template => template ? {
                            ...template,
                            modified: true,
                        } : null)}
                        setBoxes={setBoxes}
                        addBox={handleAddBox}
                        editBox={handleEditBox}
                        removeBox={removeBox}
                        onNewWindowOpen={handleOpenInNewWindow}
                        onManageAxesSettings={handleManageAxesSettings}
                        addChartSettings={addChartSettings}
                        onChange={changeJobsGlobalFilters}
                    />
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    jobs: getJobList(state),
    currentJob: getCurrentJob(state),
    boxes: getBoxes(state),
});

const mapDispatchToProps = {
    setCurrentJob,
    setBoxes,
    addBox,
    addBoxes,
    editBox,
    editBoxAxes,
    removeBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInfoPage);
