import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import UnitList from './UnitList';
import UnitChannelList from './UnitChannelList';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const ManageUnitChannelsModal = (props) => {

    const {
        isOpen,
        onCancel,
        onSave,
        editedModel,
        enableFilters,
        title,
        currentUnit,
        currentUnitModel,
        setCurrentUnit,
        unitsRequest,
        unitBoxes,
        section,
        newlyCreatedBoxType,
        onSaveEventLogger
    } = props;

    const [isSelectUnitStep, setIsSelectUnitStep] = useState(true);

    const { data: units } = unitsRequest;

    useEffect(() => {
        if (isOpen) {
            if (currentUnitModel) {
                setIsSelectUnitStep(false)
            }
        } else {
            setIsSelectUnitStep(true)
        }
    }, [isOpen, currentUnitModel])


    const handleBack = () => setIsSelectUnitStep(true);

    const handleSaveUnit = (unitId) => {
        setIsSelectUnitStep(false);
        setCurrentUnit(unitId);
    };

    const renderBackToUnitButton = () => {
        if (unitBoxes.length) return null;
        if (isSelectUnitStep) return null;

        return (
            <IconButton aria-label="back" size="small" onClick={handleBack}>
                <ArrowBackIcon fontSize="inherit" />
            </IconButton>
        );
    };

    const renderUnitList = () => {
        if (!isSelectUnitStep) return;

        return (
            <UnitList
                newlyCreatedBoxType={newlyCreatedBoxType}
                unit={currentUnitModel}
                items={units}
                onCancel={onCancel}
                onSave={handleSaveUnit}
                onSaveEventLogger={onSaveEventLogger}
                onSaveUnit={onSave}
            />
        );
    };

    const renderChannelsList = () => {
        if (isSelectUnitStep) return;

        return (
            <UnitChannelList
                enableFilters={enableFilters}
                editedModel={editedModel}
                unit={currentUnitModel}
                onCancel={() => {
                    setIsSelectUnitStep(true);
                    onCancel();
                }}
                onSave={onSave}
                unitsRequest={unitsRequest}
                section={section}
            />
        );
    };

    return (
        <Dialog
            open={isOpen}
            className="respModal"
        >
            <div className="modalTitleHolder mb16 hasBackLink">
                <div className="backLinkHolder">{renderBackToUnitButton()}</div>
                {
                    !isSelectUnitStep && (
                        <h1 className="modalTitle text-center">{title}</h1>
                    )
                }

            </div>

            {renderUnitList()}
            {renderChannelsList()}
        </Dialog>
    );
};

export default ManageUnitChannelsModal;
