import { useEffect } from "react";
import {
  HttpTransportType,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { apiBaseUrl } from "config";
import { useAuth } from "Contexts/AuthProvider";
import EventEmitter from "utils/EventEmitter";

const buildHubUrl = (company) =>
  company
    ? `${apiBaseUrl}/ws/data?companyid=${company.id}`
    : `${apiBaseUrl}/ws/data`;

const protocol = new JsonHubProtocol();
const transport = HttpTransportType.WebSockets;

const LiveUnitsSignalrEventLogger = (props) => {
  const {
    jobId,
    onIncomingData,
    onIncomingDeleteData,
    requestBody,
    lastModificationDateTime,
  } = props;

  const { token, currentCompany } = useAuth();

  useEffect(() => {
    const options = {
      skipNegotiation: true,
      transport,
      accessTokenFactory: () => {
        return token;
      },
      headers: {
        authorization: token ? `Token ${token}` : ``,
      },
    };

    let connection = new HubConnectionBuilder()
      .withUrl(buildHubUrl(currentCompany), options)
      .withAutomaticReconnect()
      .withHubProtocol(protocol)
      .configureLogging(LogLevel.Information)
      .build();

    const dataHandler = (data) => {
      onIncomingData(data);
    };

    const deleteDataHandler = (data) => {
      onIncomingDeleteData(data);
    };

    connection.on("EventsAdded", dataHandler);
    connection.on("EventsUpdated", dataHandler);
    connection.on("EventsDeleted", deleteDataHandler);

    const startConnection = () => {
      connection.start().then(() => {
        connection
          .invoke("GetEventsForUnits", requestBody, lastModificationDateTime)
          .then(() => {});
      });
    };

    if(lastModificationDateTime) {
      startConnection();
    }

    const listener = EventEmitter.addListener('refreshBtnEventLogger', () => {
      if (connection.state === "Connected") {
        connection.stop().then(startConnection);
      }
    });

    return () => {
      listener.remove();
      if (connection.state === "Connected") connection.stop();
    };
    
  }, [onIncomingData, onIncomingDeleteData, requestBody, jobId, lastModificationDateTime, token, currentCompany]);

  return null;
};

export default LiveUnitsSignalrEventLogger;
