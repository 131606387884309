import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import UnitBoxLoader from './UnitBoxLoader';
import { useAuth } from '../../../Contexts/AuthProvider';
import UnitBoxDataError from './UnitBoxDataError';
import useUnitLastChannelsData from '../../../Hooks/useUnitLastChannelsData';
import UnitBoxDigitalViewHeader from './UnitBoxDigitalViewHeader';
import UnitDigitalViewCard from './UnitDigitalViewCard';
import { useBoxes } from '../../../Contexts/BoxContext';
import { chartHelper } from '../../../helpers';
import UnitBoxDigitalViewSignalR from '../../SignalrSubscribes/UnitBoxDigitalViewSignalR';

const UnitBoxDigitalView = props => {

    const {
        boxData,
        onRemove,
        handleEdit,
        dragHandleProps,
        onNewWindowOpen,
        onExportToCsv,
        section = 'unit',
    } = props;

    const {
        unitUid,
        channels,
        filters,
    } = boxData;

    const { token, currentCompany } = useAuth();
    const { currentUnitModel, unitCodeColors, startDate, endDate } = useBoxes();
    const [data, setData] = useState([]);
    const [hasDataError, setHasDataError] = useState(false);
    const signalrRequestBody = useMemo(() => ({ uId: unitUid, channels, ...filters, jobType: section }), [unitUid, channels, filters, section]);

    const channelsDataUnitRequest = useUnitLastChannelsData({ unitUid, channels, filters, section, startDate,  endDate});

    useEffect(() => {
        if (channelsDataUnitRequest.isSuccess) {
            setHasDataError(false);
            try {
                //**sync channels with colors**
                const channelColorMap = {};
                let hasChannelWithoutColor = false;
                channels.forEach((channel) => {
                    const color = chartHelper.colors(channel.code, unitCodeColors);
                    if (color) {
                        channelColorMap[channel.code] = color;
                    } else {
                        hasChannelWithoutColor = true;
                    }
                });

                if (hasChannelWithoutColor) return;
                //**sync channels with colors - end**

                const { channelDataResponses } = channelsDataUnitRequest.data;

                if (channelDataResponses) {
                    const serverData = channels.map(ch => {
                        const foundCh = channelDataResponses.find(channel => channel.channel.code === ch.code);
                        const channelValue = foundCh?.data[0]?.value;
                        return {
                            ...ch,
                            value: channelValue,
                            color: channelColorMap[ch.code],
                            unitName: currentUnitModel?.nameLocalized,
                            unitCode: currentUnitModel?.code,
                        };
                    });
                    setData(serverData);
                } else {
                    setData([])
                    setHasDataError(true);
                }
            } catch (e) {
                console.log(e)
                setHasDataError(true);
            }

        }
    }, [
        channelsDataUnitRequest.data,
        channelsDataUnitRequest.isSuccess,
        channels,
        unitCodeColors,
        currentUnitModel,
    ]);

    const handleLiveData = useCallback(incomingData => {
        if (incomingData && incomingData.length) {
            setData(() => {
                const newData = [];
                const channelColorMap = {};
                let hasChannelWithoutColor = false;
                if (hasChannelWithoutColor) return;
                incomingData.forEach(channelData => {
                    const { channelId, value, nameLocalized, name } = channelData;
                    const color = chartHelper.colors(name, unitCodeColors);
                    if (color) {
                        channelColorMap[name] = color;
                    } else {
                        hasChannelWithoutColor = true;
                    }
                    
                    if (newData.every(item => item.id !== channelId)) {
                        newData.push({ 
                            id: channelId, 
                            value: value || 0, 
                            unitName: currentUnitModel?.nameLocalized,
                            unitCode: currentUnitModel?.code,
                            nameLocalized: nameLocalized,
                            color: channelColorMap[name],
                        });
                    }
                    const channelIndexFromExistingData = newData.findIndex(item => item.id === channelId);
                    if (channelIndexFromExistingData !== -1) {
                        newData[channelIndexFromExistingData].value = value || 0;
                    }
                });
                return newData;
            });
        } else {
            setData([]); 
        }
    }, [currentUnitModel?.code, currentUnitModel?.nameLocalized, unitCodeColors]);

    // const handleLiveData = useCallback(incomingData => {
    //     console.log('incoming', incomingData);
    //     if (incomingData && incomingData.length) {
    //         setData(data => {
    //             const newData = incomingData ? [...incomingData] : [];
    //             // console.log('pre', newData);
    //             incomingData.forEach(channelData => {
    //                 const { channelId, value } = channelData;
                       
    //                 // console.log(channelData);
    //                 const channelIndexFromExistingData = newData.findIndex(item => item.id === channelId);

    //                 if (channelIndexFromExistingData !== -1) {
    //                     newData[channelIndexFromExistingData].value = value || 0;
    //                 }
    //             });
    //             console.log('new data', newData);
    //             return newData;
    //         });
    //     }

    // }, [setData]);

    // const handleLiveData = useCallback(incomingData => {
    //     if (incomingData && incomingData.length) {
    //         setData(prevData => {
    //             const updatedData = [...prevData];
    //             incomingData.forEach(liveDataItem => {
    //                 const index = updatedData.findIndex(item => item.id === liveDataItem.channelId);
    //                 if (index !== -1) {
    //                     updatedData[index] = {
    //                         ...updatedData[index],
    //                         value: liveDataItem.value,
    //                         unitName: currentUnitModel?.nameLocalized,
    //                         unitCode: currentUnitModel?.code,
    //                     };
    //                 }
    //             });
    //             return updatedData;
    //         });
    //     }
    // }, [setData, currentUnitModel]);
    

    return (
        <Fragment>
            <UnitBoxLoader
                loading={channelsDataUnitRequest.isLoading || channelsDataUnitRequest.isFetching}
            />

            <UnitBoxDigitalViewHeader
                box={boxData}
                onRemove={onRemove}
                handleEdit={handleEdit}
                dragHandleProps={dragHandleProps}
                onManageAxesSettings={() => {
                }}
                onNewWindowOpen={onNewWindowOpen}
                onExportToCsv={onExportToCsv}
            />

            <div className="pageCard">
                {
                    hasDataError
                        ? <UnitBoxDataError />
                        : <UnitDigitalViewCard data={data} />
                }
            </div>

            <UnitBoxDigitalViewSignalR
                token={token}
                companyId={currentCompany?.id}
                requestBody={signalrRequestBody}
                onIncomingData={handleLiveData}
            />
        </Fragment>
    );
};

export default UnitBoxDigitalView;
