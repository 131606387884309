import React, {useState} from 'react'
import {JobContext} from 'Contexts/Job/JobContext'
import {getCurrentJob} from 'Store/modules/dashboard/selectors'
import {connect} from 'react-redux'
import JobDataUpdatesSignalr from 'Components/SignalrSubscribes/JobDataUpdatesSignalr.component'

const JobState = ({currentJob, children}) => {

    const [jobUpdateCounter, setJobUpdateCounter] = useState(1)


    return (
        <JobContext.Provider value={{currentJob, jobUpdateCounter}}>
            {children}
            {
                currentJob && <JobDataUpdatesSignalr jobId={currentJob.id}
                                                     onUpdated={() => {
                                                         setJobUpdateCounter(c => c + 1)
                                                     }} />
            }
        </JobContext.Provider>
    )
}

const mapStateToProps = state => ({
    currentJob: getCurrentJob(state),
})

export default connect(mapStateToProps)(JobState)
