const ACTIVE_DEPARTMENT_LOCALSTORAGE_KEY = process.env.REACT_APP_ACTIVE_DEPARTMENT_LOCALSTORAGE_KEY || '_department_id_';

export const getStoredDepartment = () => window.localStorage.getItem(ACTIVE_DEPARTMENT_LOCALSTORAGE_KEY);

export const setStoredDepartment = (id) => {
    window.localStorage.setItem(ACTIVE_DEPARTMENT_LOCALSTORAGE_KEY, id);
};

export const clearStoredDepartment = () => {
    window.localStorage.removeItem(ACTIVE_DEPARTMENT_LOCALSTORAGE_KEY);
};
