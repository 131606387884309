import axios from 'axios';

class ApiService {
    /**
     * @param appLanguage
     */
    init(appLanguage) {
        axios.defaults.headers.common['Accept'] = 'application/json';
        let locale = 'en-EN';
        switch (appLanguage) {
            case 'Russian':
                locale = 'ru-RU';
                break;
            default:
        }
        axios.defaults.headers.common['Accept-Language'] = locale;
    }

    setLang(appLanguage) {
        let locale = 'en-EN';
        switch (appLanguage) {
            case 'Russian':
                locale = 'ru-RU';
                break;
            default:
        }
        axios.defaults.headers.common['Accept-Language'] = locale;
    }

    /**
     * @param token
     */
    setAuthHeader = token => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    removeAuthHeader = () => {
        delete axios.defaults.headers.common['Authorization'];
    };

    /**
     *
     * @param url
     * @param config
     * @returns {Promise<AxiosResponse<any>>}
     */
    get = (url, config = {}) => axios.get(url, config);

    /**
     * @param url
     * @param data
     * @param config
     * @returns {Promise<AxiosResponse<T>>}
     */
    post = (url, data, config) => axios.post(url, data, config);

    patch = (url, data, config) => axios.patch(url, data, config);

    postBlob = (url, data) => axios.post(url, data, { responseType: 'blob' });

    /**
     * @param url
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    put = (url, data) => axios.put(url, data);

    /**
     * @param url
     * @returns {Promise<AxiosResponse<T>>}
     */
    delete = (url, config) => axios.delete(url, config);

    deleteWithBody = (url, data) => axios.delete(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
    });

    /**
     * Performs a custom axios request
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     *
     * @param data
     * @returns {AxiosPromise}
     */
    request = data => axios(data);

    apiRequest = (route, data = null) => {
        if (route && route.url && route.method) {
            return this.request({
                url: route.url,
                method: route.method,
                data,
            });
        }
    };

    mount401Interceptor = () => {
        this._401interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.request.status === 401) {
                }
                throw error;
            },
        );
    };

    unmount401Interceptor = () => {
        axios.interceptors.response.eject(this._401interceptor);
    };

    mount403Interceptor = () => {
        this._403interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.request.status === 403) {

                }
                throw error;
            },
        );
    };

    unmount403Interceptor = () => {
        axios.interceptors.response.eject(this._403interceptor);
    };

}

export default new ApiService();
