import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'
import Types from './types'

const INITIAL_STATE = {
    theme: 'Light',
    lang: 'English'
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case Types.SET_THEME:
            return {
                ...state,
                theme: action.payload
            }

        case Types.SET_LANG:
            return {
                ...state,
                lang: action.payload
            }

        default:
            return state
    }
}

const persistConfig = {
    key: 'settings',
    storage
}

export default persistReducer(persistConfig, reducer)
