import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { normalizeErrorResponse } from 'utils/form'
import { useAuth } from 'Contexts/AuthProvider'

const useApiCall = url => {
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ignoreAuthToken, setIgnoreAuthToken] = useState(false)
    const [error, setError] = useState(null)
    const [options, setOptions] = useState({})
    const { token } = useAuth()

    const doCall = useCallback((options = {}) => {
        if (options.ignoreAutToken) {
            setIgnoreAuthToken(true)
        }
        setOptions(options)
        setLoading(true)
    }, [])

    useEffect(() => {
        let cancel

        const fetchData = async () => {
            try {
                let requestOptions
                if (ignoreAuthToken) {
                    requestOptions = {
                        ...options,
                        cancelToken: new axios.CancelToken(c => cancel = c)
                    }
                } else {
                    requestOptions = {
                        ...options,
                        ...{
                            headers: {
                                authorization: token ? `Token ${token}` : ``
                            }
                        },
                        cancelToken: new axios.CancelToken(c => cancel = c)
                    }
                }

                const requestUrl = url || requestOptions.url

                const response = await axios(`${requestUrl}`, requestOptions)
                setResponse(response.data)
                setLoading(false)
            } catch (e) {
                if (axios.isCancel(e))
                    return
                if (e && e.response && e.response.data) {
                    setError(normalizeErrorResponse(e.response.data))
                }

                setLoading(false)
            }
        }

        if (!loading)
            return

        fetchData().then()

        return () => { cancel() }
    }, [
        url,
        loading,
        options,
        token
    ])

    return [{ response, loading, error }, doCall]
}

export default useApiCall
