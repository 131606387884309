import { useAuth } from '../Contexts/AuthProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { apiMmsBaseUrl } from '../config';

export const useNotificationsListRequest = ({ departmentId, companyId }) => {
    const { userLoaded } = useAuth();

    return useQuery(
        ['mms', 'notifications', companyId, departmentId],
        () => {
            const config = {
                headers: { 'CompanyId': companyId },
            };
            return axios.get(`${apiMmsBaseUrl}/NotificationReports/by-departmentid/${departmentId}`, config);
        },
        {
            keepPreviousData: true,
            enabled: userLoaded && !!departmentId,
        },
    );
};

export const useDepartmentComponents = ({ departmentId, companyId, short = false }) => {
    const { userLoaded } = useAuth();

    return useQuery(
        ['mms', 'components', companyId, departmentId],
        () => {
            const config = {
                headers: { 'CompanyId': companyId },
            };
            const endpoint = short
                ? `${apiMmsBaseUrl}/components/by-departmentid/${departmentId}/short`
                : `${apiMmsBaseUrl}/components/by-departmentid/${departmentId}`

            return axios.get(endpoint, config);
        },
        {
            keepPreviousData: true,
            enabled: userLoaded && !!departmentId,
        },
    );
};

export const useNotificationsCUD = () => {
    const queryClient = useQueryClient();

    const createMutation = useMutation((data) => axios.post(`${apiMmsBaseUrl}/NotificationReports`, data));
    const updateMutation = useMutation((data) => axios.put(`${apiMmsBaseUrl}/NotificationReports`, data));
    const activateMutation = useMutation((id) => axios.put(`${apiMmsBaseUrl}/NotificationReports/${id}/activate`));
    const deactivateMutation = useMutation((id) => axios.put(`${apiMmsBaseUrl}/NotificationReports/${id}/deactivate`));
    const deleteMutation = useMutation((id) => {
        const ids = Array.isArray(id) ? id : [id];
        return axios.delete(`${apiMmsBaseUrl}/NotificationReports`, { data: { ids } })
    });

    const invalidateList = () => queryClient.invalidateQueries(['mms', 'notifications']);

    return {
        invalidateList,
        createMutation,
        deleteMutation,
        updateMutation,
        activateMutation,
        deactivateMutation
    };
};

