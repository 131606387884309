import React, { useState, useEffect, useMemo } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import MmsService from 'Services/MMS/mms-api.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Popover from "@material-ui/core/Popover";
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import { Checkbox } from "@material-ui/core";
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import SavePartModal from '../Equipment/Modals/SavePartModal'
import { useAuth } from 'Contexts/AuthProvider'
import { setPartsCatalog, setCurrentPage } from '../../../Store/modules/mms/actions'
import CloneToEquipmentModal from './Modals/CloneToEquipmentModal'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const ArrowLeft = () => <div className='arrowLeftIcon' />
const ArrowRight = () => <div className='arrowRightIcon' />

const PartsCatalogComponent = ({ partsCatalog, setPartsCatalog, setCurrentPage, currentDepartment }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isOpenSavePartModal, setIsOpenSavePartModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenCloneToEquipmentModal, setIsOpenCloneToEquipmentModal] = useState(false);
    const [menuAchorEl, setMenuAchorEl] = useState(null)
    const [selectedPart, setSelectedPart] = useState(null)
    const { currentUser } = useAuth()
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser])
    const [firstIndex, setFirstIndex] = useState(0)
    const [lastIndex, setLastIndex] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [rowsOnPage, setRowsOnPage] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)
    const [anchorEl, onAnchorElChange] = useState(null)
    const translation = useTranslation();
    const closeMenu = () => {
        setMenuAchorEl(null)
    }

    useEffect(() => {
        const fetchPartsCatalog = async () => {
            try {
                const response = await MmsService.getPartsCatalog()
                setIsLoading(false)
                setPartsCatalog(response.data)
            }
            catch (error) {
                setIsLoading(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            }
        }
        setCurrentPage('parts')
        fetchPartsCatalog()
    }, [setPartsCatalog, setCurrentPage])

    const onDeletePart = () => {
        MmsService.deletePart(selectedPart.id).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(translation.notificationsPartDeleted)
                const newParts = partsCatalog.filter(p => p.id !== selectedPart.id)
                setPartsCatalog(newParts)
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    const pageData = useMemo(() => {
        if (!partsCatalog)
            return []
        let pageData = [...partsCatalog]
        const totalLength = pageData.length

        if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
            setPageNumber(1)
        }
        setTotalRows(totalLength)
        setFirstIndex(Math.min(((pageNumber - 1) * rowsOnPage + 1), totalLength))
        setLastIndex(Math.min((pageNumber * rowsOnPage), totalLength))
        let tableData = pageData.splice((pageNumber - 1) * rowsOnPage, Math.min((pageNumber * rowsOnPage), totalLength))
        return [...tableData]
    }, [
        partsCatalog,
        rowsOnPage,
        pageNumber
    ])

    const closePaginationMenu = () => {
        onAnchorElChange(null)
    }

    const handleRowCount = (count) => {
        setRowsOnPage(count)
        setPageNumber(1)
        closePaginationMenu()
    }

    const onNextPage = () => {
        setPageNumber(pageNumber + 1)
        window.scrollTo(0, 0)
    }

    const onPrevPage = () => {
        setPageNumber(pageNumber - 1)
        window.scrollTo(0, 0)
    }


    const handleSelectedIdChange = (partId, checked) => {
        partsCatalog.find(p => p.id === partId).selected = checked
        setPartsCatalog([...partsCatalog])
    }

    const handleAllSelectedChange = (checked) => {
        const newParts = partsCatalog.map(p => {
            p.selected = checked
            return p
        })
        setPartsCatalog(newParts)
    }

    return (
        <div className="pmTableHolder">
            <table className="pmTable">
                <thead>
                    <tr>
                        <th className='checkBoxCell'>
                            <Checkbox
                                checked={partsCatalog ? partsCatalog.every(p => p.selected) : false}
                                onChange={(e) => handleAllSelectedChange(e.target.checked)}
                                color='default'
                                size='small'
                            />
                        </th>
                        <th>{translation.equipmentPagePartsPartHeader}</th>
                        <th>{translation.equipmentPagePartsPartIdHeader}</th>
                        <th>{translation.equipmentPagePartsSupplierHeader}</th>
                        <th>{translation.equipmentPagePartsSupplierPartIdHeader}</th>
                        <th>{translation.equipmentPageManufacturerHeader}</th>
                        <th colSpan='2'>{translation.equipmentPageManufacturerTypeHeader}</th>
                    </tr>
                </thead>
                <tbody>
                    {pageData ? pageData.map(part =>
                        <tr key={part.id}>
                            <td className='checkBoxCell'>
                                <Checkbox
                                    checked={part.selected ? part.selected : false}
                                    onChange={(e) => handleSelectedIdChange(part.id, e.target.checked)}
                                    color='default'
                                    size='small'
                                />
                            </td>
                            <td className='text-semi-bold'>{part.name}</td>
                            <td>{part.number}</td>
                            <td>{part.supplierName}</td>
                            <td>{part.supplierPartNumber}</td>
                            <td>{part.manufacturerName}</td>
                            <td>{part.manufacturerType}</td>
                            <td className='btnCell'>
                                <IconButton aria-label="delete" size='small' className='dropDownBtn mobile' onClick={(e) => { setMenuAchorEl(e.target); setSelectedPart(part) }}>
                                    <MoreVertIcon />
                                </IconButton>
                            </td>
                            <Popover
                                anchorEl={menuAchorEl}
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                                disableScrollLock={true}
                                open={Boolean(menuAchorEl)}
                                onClose={closeMenu}
                                elevation={1}
                                className='pmPopover equipmentSubMenu'
                            >
                                {isSuperUser && <MenuItem
                                    key={'edit'}
                                    onClick={() => { setIsOpenSavePartModal(true); setMenuAchorEl(null) }}
                                    className='menuItem edit'
                                >{translation.popoverDetailsLabel}</MenuItem>}
                                {isSuperUser && <MenuItem
                                    key={'delete'}
                                    onClick={() => { setIsOpenDeleteModal(true); setMenuAchorEl(null) }}
                                    className='menuItem delete'
                                >{translation.popoverRemoveLabel}</MenuItem>}
                                {!isSuperUser && <>
                                    <MenuItem
                                        key={'take'}
                                        onClick={() => { setIsOpenCloneToEquipmentModal(true); setMenuAchorEl(null) }}
                                        className='menuItem change'
                                    >{translation.popoverTakeToMyEquipmentLabel}</MenuItem>
                                    <MenuItem
                                        key={'show'}
                                        onClick={() => { setIsOpenSavePartModal(true); setMenuAchorEl(null) }}
                                        className='menuItem edit'
                                    >{translation.popoverShowDetailsLabel}</MenuItem>
                                </>}
                            </Popover>
                        </tr>
                    ) : null}
                </tbody>
            </table>
            {pageData && pageData.length > 0 && <div className="tableControlsHolder">
                <div className="sortHolder">
                    <div className="caption">Rows per page:</div>
                    <Button onClick={(e) => onAnchorElChange(e.target)}
                        endIcon={<ArrowDropDownIcon />}
                        className='dropDownBtn'>
                        {rowsOnPage}
                    </Button>

                    <Popover
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closePaginationMenu}
                        disableScrollLock={true}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}>
                        <MenuItem onClick={() => handleRowCount(20)}>20</MenuItem>
                        <MenuItem onClick={() => handleRowCount(50)}>50</MenuItem>
                        <MenuItem onClick={() => handleRowCount(100)}>100</MenuItem>
                    </Popover>
                </div>
                <div className="paginationHolder mms">
                    <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                    <div className="paginationBtn">
                        <IconButton
                            disabled={firstIndex === 1 || firstIndex === 0}
                            onClick={() => onPrevPage()} >
                            <ArrowLeft />
                        </IconButton>
                        <IconButton
                            disabled={lastIndex === totalRows}
                            onClick={() => onNextPage()}>
                            <ArrowRight />
                        </IconButton>
                    </div>
                </div>
            </div>}
            {isLoading ? <div className='loaderHolder'>
                <Fade
                    in={isLoading}
                    unmountOnExit>
                    <CircularProgress />
                </Fade>
            </div> : null}
            <SavePartModal
                open={isOpenSavePartModal}
                handleClose={(e) => setIsOpenSavePartModal(false)}
                part={selectedPart}
                isCatalog={true}
                isReadOnly={!isSuperUser}
            />
            <CloneToEquipmentModal
                isOpen={isOpenCloneToEquipmentModal}
                isOpenManager={setIsOpenCloneToEquipmentModal}
                ids={[selectedPart?.id]}
                departmentId={currentDepartment?.id}
                instance={'parts'} />
            <DeleteItemModal
                isOpen={isOpenDeleteModal}
                isOpenManager={setIsOpenDeleteModal}
                message={translation.removePartModalMessageLabel}
                onDeleteModalClose={(e) => { onDeletePart() }} />
        </div >
    )
}

const mapStateToProps = ({ mms }) => ({
    partsCatalog: mms.partsCatalog,
    currentDepartment: mms.currentDepartment
})

const mapDispatchToProps = {
    setPartsCatalog,
    setCurrentPage
}

export default connect(mapStateToProps, mapDispatchToProps)(PartsCatalogComponent)
