export const clearServerErrors = (errors, name) => {
    if (errors) {
        const validationErrors = {...errors}
        if (name === undefined) {
            return null
        } else {
            let requestError
            (Array.isArray(name) ? name : [name]).forEach(fieldName => {
                if (validationErrors[fieldName]) {
                    delete validationErrors[fieldName]
                    if (Object.keys(validationErrors).length) {
                        requestError = {...validationErrors}
                    } else {
                        requestError = null
                    }
                }
            })
            return requestError
        }
    }
    // if (request && request.error && request.error.errors) {
    //     const validationErrors = request.error.errors
    //     if (name === undefined) {
    //         return {...request, error: null}
    //     } else {
    //         let requestError;
    //         (Array.isArray(name) ? name : [name]).forEach(fieldName => {
    //             if (validationErrors[fieldName]) {
    //                 delete validationErrors[fieldName]
    //                 if (Object.keys(validationErrors).length) {
    //                     requestError = {...request.error, errors: validationErrors}
    //                 } else {
    //                     requestError = null
    //                 }
    //             }
    //         })
    //         return {...request, error: requestError}
    //     }
    // }
    // return request
}


export const getServerError = (errors, name) => {
    // return request
    //     && request.error
    //     && request.error.errors
    //     && request.error.errors[name]
    //     && request.error.errors[name][0]
    return errors && errors[name] && errors[name][0]
}

export const normalizeErrorResponse = response => {
    const output = {
        message: 'Error',
        errors: {},
    }
    if (response['Message']) {
        output.message = response['Message']
    }
    if (response['message']) {
        output.message = response['message']
    }
    if (response['error_description']) {
        output.message = response['error_description']
    }
    if (response.Errors) {
        const errors = {}
        response.Errors.forEach((error) => {
            const field = error['PropertyName'].replace(/^\w/, (c) => c.toLowerCase());
            const message = error.ErrorMessage;
            if (!errors[field]) errors[field] = [];
                errors[field].push(message);
      });
      output.errors = errors;
    } 
    // else {
    //   const errors = {};
    //   console.log(response);
    //   Object.entries(response.Errors).forEach((error) => {
    //     const [field, errorsArray] = error;
    //     errors[field] = errorsArray;
    //   });
    //   output.errors = errors;
    // }
    if (typeof response === 'string') {
        output.message = response
    }
    return output
  }

// export const normalizeErrorResponse = response => {
//     const output = {
//         message: 'Error',
//         errors: null,
//     }
//     if (response['Message']) {
//         output.message = response['Message']
//     }
//     if (response['message']) {
//         output.message = response['message']
//     }
//     if (response['error_description']) {
//         output.message = response['error_description']
//     }
//     if (response.errors) {
//         const errors = {}
//         if (Array.isArray(response.errors)) {
//             response.errors.forEach(error => {
//                 const field = error['PropertyName'].replace(/^\w/, c => c.toLowerCase())
//                 const message = error['ErrorMessage']
//                 if (!errors[field])
//                     errors[field] = []

//                 errors[field].push(message)
//             })
//         } else {
//             Object.entries(response.errors).forEach(error => {
//                 const [field, errorsArray] = error
//                 errors[field] = errorsArray
//             })
//         }


//         output.errors = errors
//     }
//     if (typeof response === 'string') {
//         output.message = response
//     }
//     return output
// }
