import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { filterArray } from 'utils/functions';
import DmsService from 'Services/DMS/dms-api.service';
import useTranslation from 'Hooks/useTranslation';
import JobLabel from './JobLabel';

const SelectJobModal = ({ jobs, title, isOpen, onCancel, onSave, onSelectionChanged, type, setCurrentJobUnits, loading = false }) => {
    const [value, setValue] = useState(0);
    const [search, setSearch] = useState('');
    const [lastJobsCount, setLastJobsCount] = useState(0);
    const translation = useTranslation();
    const handleChange = event => {
        setValue(+event.target.value);
    };

    const allowedJobs = useMemo(() => {
        if (!jobs) return [];
        return jobs.filter(job => !job.isDeleting);
    }, [jobs]);

    const filteredJobs = useMemo(() => {
        if (search && search !== '')
            return filterArray(allowedJobs, search);
        return allowedJobs;
    }, [allowedJobs, search]);

    const buttonLabel = useMemo(() => {
        switch (type) {
            case 'eventLogger':
                return translation.openButtonLabel;
            case 'oneJobPassword':
                return translation.createButtonLabel;
            default:
                return translation.continueButtonLabel;
        }
    }, [type, translation]);

    useEffect(() => {
        if (!allowedJobs || allowedJobs.length <= lastJobsCount)
            return;
        setValue(allowedJobs[0].id);
        setLastJobsCount(allowedJobs.length);
    }, [allowedJobs, lastJobsCount]);

    /**
     * Flow for selecting first visible job
     */
    useEffect(() => {

        if (filteredJobs.length) {
            if (!filteredJobs.find(job => job.id === value)) {
                setValue(filteredJobs[0].id);
            }
        } else {
            setValue('');
        }
    }, [filteredJobs, search, value, onSelectionChanged, isOpen]);

    useEffect(() => {
        const fetchJobById = async (jobId) => {
            if (jobId && allowedJobs && allowedJobs.length) {
                let response = await DmsService.getJobsById(jobId);
                if (response) {
                    const job = allowedJobs.find(x => x.id === jobId);
                    job.units = response.units;
                    setCurrentJobUnits(response.units);
                }
            }
        };
        if (value && isOpen && type !== 'oneJobPassword') {
            fetchJobById(value).then();
        }

    }, [value, setCurrentJobUnits, allowedJobs, isOpen, type]);

    return (
        <Dialog open={isOpen} className="respModal">
            <div className="modalTitleHolder mb16">
                <h1 className="modalTitle text-center">{title || translation.saveUserModalSelectJobsLabel}</h1>
            </div>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="search"
                label={translation.dashboardSearchJobLabel}
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <div className="checkboxListHolder mt8">
                <div className="labelLike">{translation.dashboardJobsLabel}</div>
                <div className="labelLike dateStart">{translation.dashboardStartTimeLabel}</div>
                <div className="labelLike dateStop">{translation.dashboardStopTimeLabel}</div>
                <div className="checkboxListContainer">
                    <RadioGroup
                        aria-label="jobs"
                        name="jobs"
                        value={value}
                        onChange={handleChange}
                    >
                        {filteredJobs &&
                            filteredJobs.map(job => {
                                return (
                                    <FormControlLabel
                                        disabled={job.isDeleting}
                                        key={job.id}
                                        value={job.id}
                                        label={<JobLabel label={job.name} job={job} />}
                                        control={<Radio color="default" />} />
                                );
                            })
                        }
                    </RadioGroup>
                </div>
            </div>
            <div className="btnHolder mt22 fixedWidthBtn center">
                <Button onClick={onCancel}
                        variant="outlined">
                    {translation.cancelButtonLabel}
                </Button>
                {
                    type === 'eventLogger' &&
                    <Button onClick={() => onSave(value, true)}
                            disabled={!value}
                            variant="outlined">
                        {translation.openInNewWindowButtonLabel}
                    </Button>
                }
                <Button onClick={() => onSave(value, false)}
                        disabled={!value || loading}
                        variant="contained"
                        color="primary">
                    {buttonLabel}
                </Button>
            </div>
        </Dialog>
    );
};
export default SelectJobModal;
