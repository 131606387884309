import React from 'react';
import moment from 'moment';

const JobLabel = ({ label, job }) => {
    return (
        <div className="jobLabel">
            <div className="labelName">{label}</div>
            <div className="infoPart">
                <div className="startDate date">{moment(job.startTime).format('DD-MMM-YY, HH:mm')}</div>
                {job.stopTime ?
                    <div className="stopDate date">{moment(job.stopTime).format('DD-MMM-YY, HH:mm')}</div> :
                    <div className="stopDate date"></div>}
            </div>
        </div>
    );
};

export default JobLabel;
