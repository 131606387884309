import React from 'react'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AlertContainer = () => {
    return (
        <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            autoClose={10000}
            hideProgressBar={false}
            preventDuplicates={true}
        />
    )
}

export default AlertContainer
