import {applyMiddleware, createStore} from 'redux'
import reducer from './reducer'
import saga from './saga'

import {composeWithDevTools} from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
// import {persistStore} from 'redux-persist'

import NetworkService from 'Services/Auth/Network.service'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
    sagaMiddleware,
]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)))
sagaMiddleware.run(saga)

// const persistor = persistStore(store)

NetworkService.setupInterceptors(store)

export {
    store,
    // persistor
}
