export default {
    SIGNIN_RESET: 'SIGNIN_RESET',
    SIGNIN_REQUEST: 'SIGNIN_REQUEST',
    SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
    SIGNIN_FAILURE: 'SIGNIN_FAILURE',

    SIGNOUT_REQUEST: 'SIGNOUT_REQUEST',
    SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
    SIGNOUT_FAILURE: 'SIGNOUT_FAILURE',

    SET_AUTH_USER: 'SET_AUTH_USER'
}
