import React, { useContext } from 'react'
import { WindowContext } from 'Contexts/Window/WindowContext'

const themeList = JSON.parse(localStorage.getItem('themeList')) || []
const languageList = JSON.parse(localStorage.getItem('languageList')) || []

const SettingsPage = () => {

    const { setTheme, currentTheme, currentLanguage, setLanguage } = useContext(WindowContext)

    const setActive = (e, querySelector) => {
        document.querySelectorAll(querySelector).forEach((e) => {
            e.classList.remove('active')
        })

        e.target.classList.add('active')
    }

    const isLightTheme = () => {
        return currentTheme === 'Light'
    }

    const isDarkTheme = () => {
        return currentTheme === 'Dark'
    }

    const blueClasses = () => {
        if (isLightTheme()) return 'blue active'
        return 'blue'
    }

    const darkClasses = () => {
        if (isDarkTheme()) return 'dark active'
        return 'dark'
    }

    return (
        <main>
            {/*<div className='settings-template'>*/}
            {/*    <button disabled>Load Template</button>*/}
            {/*    <button disabled>Save Template</button>*/}
            {/*</div>*/}
            <div className="mainContainer">
                <div className="pageBlock hasPadTop">
                    <h2 className="pageBlockHeading mb24">Themes</h2>
                    <div className="pageCard ">
                        <ul className="themesList">
                            {themeList.map(theme => (
                                <li key={theme} className={theme === 'Dark' ? darkClasses() : blueClasses()} onClick={(e) => {
                                    setTheme(theme)
                                }}>{theme}
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="pageBlock hasPadTop">
                    <h2 className="pageBlockHeading mb24">Languages</h2>
                    <div className="pageCard ">
                        <ul className="langList">
                            {languageList.map(language => (
                                <li key={language} className={currentLanguage === language ? 'active' : ''} onClick={(e) => {
                                    setLanguage(language)
                                    setActive(e, '.langList li')
                                }}>{language}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default SettingsPage
