import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  TextField,
  Popover,
} from "@material-ui/core";
import DmsService from "Services/DMS/dms-api.service";
import useTranslation from "Hooks/useTranslation";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Datepicker, START_DATE, END_DATE } from "@datepicker-react/styled";
import { ThemeProvider } from "styled-components";
import { useAppSettings } from 'Contexts/AppSettingsProvider';
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const IntegrationLogsModal = ({
  isOpen,
  isOpenChangeHandler,
  integrationId,
  setSelectedIntegrationId,
  isLoadingUnitDateTypes,
}) => {
  const translation = useTranslation();
  const classes = useStyles();

  const [dateRangeValue, setDateRangeValue] = useState("");
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [customDateRangeType, setCustomDateRangeType] = useState(null);
  const [resetClicked, setResetClicked] = useState(false);
  const [integrationLogs, setIntegrationLogs] = useState(null);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [expandedLogs, setExpandedLogs] = useState({});
  const { appTheme: theme } = useAppSettings();

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handleOpenDatePicker = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleDateChange = ({ startDate, endDate }) => {

    if (startDate && endDate && startDate > endDate) {
      return;
    }

    setSelectedDateFrom(startDate);
    setSelectedDateTo(endDate);

    if (startDate && endDate) {
      const formattedRange = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      setDateRangeValue(formattedRange);
    } else {
      setDateRangeValue("");
    }
  };

  const handleFocusChange = (focusedInput) => {
    console.log('handleFocusChange:', focusedInput);
    setFocusedInput(focusedInput);
  };

  const handleReset = useCallback(() => {
    if (integrationLogs && integrationLogs.length > 0) {
      const latestLog = integrationLogs.reduce((latest, current) => {
        return new Date(current.timestamp) > new Date(latest.timestamp)
          ? current
          : latest;
      }, integrationLogs[0]);

      const latestDate = new Date(latestLog.timestamp);
      const fromDate = new Date(latestDate);
      fromDate.setDate(fromDate.getDate() - 7);

      if (
        (!selectedDateFrom || selectedDateFrom.getTime() !== fromDate.getTime()) ||
        (!selectedDateTo || selectedDateTo.getTime() !== latestDate.getTime())
      ) {
        setSelectedDateFrom(fromDate);
        setSelectedDateTo(latestDate);

        const formattedRange = `${fromDate.toLocaleDateString()} - ${latestDate.toLocaleDateString()}`;
        setDateRangeValue(formattedRange);
      }
    } else {
      setDateRangeValue("");
      setSelectedDateFrom(null);
      setSelectedDateTo(null);
    }
  }, [integrationLogs, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    if (resetClicked) {
      handleReset();
      setResetClicked(false);
    }
  }, [handleReset, resetClicked]);

  const resetState = () => {
    setDateRangeValue("");
    setSelectedDateFrom(null);
    setSelectedDateTo(null);
    setIntegrationLogs(null);
    setExpandedLogs({});
    setCustomDateRangeType(null);
  };

  const onClose = () => {
    isOpenChangeHandler(false);
    setSelectedIntegrationId(null);
    resetState();
  };

  const fetchIntegrationLogs = async () => {
    if (!integrationId) return;
  
    try {
      setIsLoadingLogs(true);
      const response = await DmsService.getIntegrationLogs(
        integrationId,
        selectedDateFrom,
        selectedDateTo
      );
      const sortedData = response.data.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setIntegrationLogs(sortedData);
    } catch (error) {
      console.error("Error fetching integration logs:", error);
    } finally {
      setIsLoadingLogs(false);
    }
  };
  

  useEffect(() => {
    if (integrationId) {
      fetchIntegrationLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId]); 

  useEffect(() => {
    if (integrationLogs && integrationLogs.length > 0 && !dateRangeValue) {
      handleReset();
    }
  }, [integrationLogs, handleReset, dateRangeValue]);

  const toggleExpandLog = (index) => {
    setExpandedLogs((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleApply = () => {
    handleClosePopover();
    fetchIntegrationLogs(); 
  };

  const renderDateRangeSelect = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <TextField
          disabled={isLoadingUnitDateTypes}
          variant="outlined"
          margin="none"
          fullWidth
          onClick={handleOpenDatePicker}
          value={dateRangeValue}
          className="dateRangePicker"
        />
        <Popover
          open={Boolean(popoverAnchor)}
          anchorEl={popoverAnchor}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="popoverCalendarHolder">
            <div className="calendarHolder">
              <ThemeProvider
                theme={{
                  reactDatepicker: {
                    daySize: [36, 40],
                    fontFamily: '"Open Sans", sans-serif',
                    colors: {
                      accessibility: 'transparent',
                      selectedDay: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                      selectedDayHover: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                      primaryColor: theme === 'Light' ? 'rgba(68, 68, 68, 0.12)!important' : 'rgba(255, 255, 255, 0.12)!important',
                    },
                  },
                }}
              >
                <Datepicker
                  onDatesChange={handleDateChange}
                  onFocusChange={handleFocusChange}
                  startDate={selectedDateFrom}
                  endDate={selectedDateTo}
                  focusedInput={focusedInput}
                  showResetDates={false}
                  showSelectedDates={false}
                  showClose={false}
                />
              </ThemeProvider>
            </div>
            <div className="btnHolder justified fixedWidthBtn mt32">
              <Button variant="outlined" onClick={handleReset}>
                {translation.resetButtonLabel}
              </Button>
              <Button variant="contained" color="primary" onClick={handleApply}>
                {translation.applyButtonLabel}
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} className="respModal autoHeight logsModal">
      <form action="">
        <div className="formContentHolder">
          <div
            className="modalTitleHolder"
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            <h2>{translation.viewIntegrationLogsModalIntegrationLogsLabel}</h2>
          </div>

          <div className="formContent">
            <div
              className="formContentBlock"
              style={{ width: "170px", marginLeft: "auto" }}
            >
              {renderDateRangeSelect()}
            </div>
            <div
              className="formContentBlock logsTable"
              style={{
                width: "100%",
                color: "black",
                padding: "15px",
                marginTop: "20px",
                borderRadius: "5px",
                minHeight: "400px",
                maxHeight: "400px",
                overflowY: "auto",
                fontFamily: "monospace",
              }}
            >
              {isLoadingLogs ? (
                <div className={classes.root}>
                <Fade in={!isLoadingLogs} unmountOnExit>
                  <CircularProgress />
                </Fade>
              </div>
              ) : integrationLogs && integrationLogs.length > 0 ? (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead
                    style={{
                      borderBottom: "1px solid rgb(207 204 204 / 34%)",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "10px 110px 10px 10px",
                        }}
                      >
                        Timestamp
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "10px 50px 10px 10px",
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          padding: "10px 10px 10px 10px",
                        }}
                      >
                        Message
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {integrationLogs.map((log, index) => (
                      <tr key={index} onClick={() => toggleExpandLog(index)}>
                        <td
                          style={{
                            padding: "8px 10px 8px 10px",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                        >
                          {log.timestamp
                            ? new Date(log.timestamp).toLocaleString()
                            : "No Date"}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                          }}
                          className={`status-item ${log.status}`}
                        >
                          {log.status}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            whiteSpace: expandedLogs[index] ? "pre-wrap" : "nowrap",
                            wordBreak: "break-word",
                            cursor: "pointer",
                          }}
                        >
                          {expandedLogs[index]
                            ? log.message
                            : log.message.length > 100
                            ? `${log.message.substring(0, 125)}`
                            : log.message}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div style={{ textAlign: "center", padding: "20px" }}>
                No data
              </div>
              )}
            </div>
          </div>
          <div className="btnHolder mt22 fixedWidthBtn">
            <Button onClick={onClose} variant="outlined">
              {translation.closeButtonLabel}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default IntegrationLogsModal;
