import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MmsService from 'Services/MMS/mms-api.service';
import AlertToastr from 'utils/alert';
import {
    Button,
    MenuItem,
    TextField,
    Tooltip,
    FormControl,
    RadioGroup,
    Radio, FormControlLabel,
} from '@material-ui/core';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import { normalizeErrorResponse } from 'utils/form';
import DatePicker, { registerLocale } from 'react-datepicker';
import { setReports } from '../../../../Store/modules/mms/actions';
import 'react-datepicker/dist/react-datepicker.css';
import useTranslation from 'Hooks/useTranslation';
import enGB from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import { useAppSettings } from 'Contexts/AppSettingsProvider';
import { useQueryClient } from 'react-query';

const BlankReportData = {
    name: '',
    language: 'English',
    recipients: '',
    startDate: new Date(),
    frequency: '',
    componentId: 'empty',
    status: 'empty',
    priority: 'empty',
    filterDateType: 'empty',
    assigneeId: '00000000-0000-0000-0000-000000000000',
};

const SaveScheduledReportsModal = ({
                                       isOpen,
                                       isOpenManager,
                                       components,
                                       employees,
                                       currentDepartment,
                                       statusOptions,
                                       report,
                                   }) => {
    const queryClient = useQueryClient();
    const { appLanguage } = useAppSettings();
    const [priorityOptions, setPriorityOptions] = useState([]);
    const [filterTimeTypeOptions, setFilterTimeTypeOptions] = useState([]);
    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const [reportInfo, setReportInfo] = useState(BlankReportData);
    const [disableButtons, setDisabledButtons] = useState(false);
    const [errors, setErrors] = useState({});
    const translation = useTranslation();
    const [isMobile, setIsMobile] = useState(false);
    const emptyStatusOption = useMemo(() => {
        return { id: 'empty', name: translation.saveReportModalSelectStatusLabel };
    }, [translation]);
    const emptyDateRangeOption = useMemo(() => {
        return { id: 'empty', name: translation.saveReportModalSelectDateRangeLabel };
    }, [translation]);
    const emptyComponentOption = useMemo(() => {
        return { id: 'empty', name: translation.saveReportModalSelectComponentLabel };
    }, [translation]);
    const emptyPriorityOption = useMemo(() => {
        return { id: 'empty', name: translation.saveReportModalSelectPriorityLabel };
    }, [translation]);
    const emptyGuidAssigneeOption = useMemo(() => {
        return {
            id: '00000000-0000-0000-0000-000000000000',
            name: translation.filterActivitiesModalSelectAssigneeLabel,
        };
    }, [translation]);
    const emptyAssigneeOption = useMemo(() => {
        return { id: 'empty', name: translation.saveReportModalNoAssigneeLabel };
    }, [translation]);

    useEffect(() => {
        const fetchDropdownsOptions = async () => {
            let frequencyResponse = await MmsService.getFrequencyOptions();
            setFrequencyOptions(frequencyResponse.data);
            setReportInfo(reportInfo => {
                return {
                    ...reportInfo,
                    frequency: reportInfo.frequency ? reportInfo.frequency : frequencyResponse.data[0]?.item,
                };
            });
            let actionTriggerDateRangeResponse = await MmsService.getFilterTimeTypeOptions();
            setFilterTimeTypeOptions(actionTriggerDateRangeResponse.data);
            let priorityResponse = await MmsService.getPriorityOptions();
            setPriorityOptions(priorityResponse.data);

        };
        if (!isOpen || !currentDepartment)
            return;
        fetchDropdownsOptions();
    }, [isOpen, report, currentDepartment]);

    useEffect(() => {

        function convertUTCDateToLocalDate(date) {
            let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

            let offset = date.getTimezoneOffset() / 60;
            let hours = date.getHours();

            newDate.setHours(hours - offset);

            return newDate;
        }

        if (!isOpen)
            return;
        if (report) {
            if (!report.recipients)
                report.recipients = [];
            if (!report.componentIds)
                report.componentIds = [];
            setReportInfo({
                ...report,
                recipients: report.recipients.join('\n'),
                startDate: convertUTCDateToLocalDate(new Date(report.startDate))
            });
        } else {
            setReportInfo({ ...BlankReportData });
        }
    }, [report, isOpen]);

    useEffect(() => {
        registerLocale('English', enGB);
        registerLocale('Russian', ru);

        function handleResize() {
            setIsMobile(window.innerWidth <= 576);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => {
        isOpenManager(false);
        setErrors({});
    };

    const departmentEmployees = useMemo(() => {
        if (employees && currentDepartment)
            return employees.filter(e => e.departmentId === currentDepartment.id);
        else
            return null;
    }, [employees, currentDepartment]);

    const onSave = () => {
        setDisabledButtons(true);

        const formattedReportInfo = {
            ...reportInfo,
            recipients: reportInfo.recipients.split('\n').filter(str => !!str),
        };
        if (formattedReportInfo.frequency === 'empty') {
            formattedReportInfo.frequency = null;
        }
        if (formattedReportInfo.status === 'empty') {
            formattedReportInfo.status = null;
        }
        if (formattedReportInfo.priority === 'empty') {
            formattedReportInfo.priority = null;
        }
        if (formattedReportInfo.filterDateType === 'empty') {
            formattedReportInfo.filterDateType = null;
        }
        if (formattedReportInfo.assigneeId === 'empty') {
            formattedReportInfo.assigneeId = null;
        }
        if (formattedReportInfo.componentId === 'empty') {
            formattedReportInfo.componentId = null;
        }

        if (report) {
            MmsService.updateReport({ ...formattedReportInfo, startDate: reportInfo.startDate }).then((response) => {
                if (response.data.isSuccessful) {
                    AlertToastr.showAlert(translation.notificationsReportUpdated);
                    setDisabledButtons(false);
                    handleClose();
                }
            })
                .then((response) => {
                    queryClient.invalidateQueries('reports').then();
                })
                .catch((error) => {
                    if (!error || !error.response || !error.response.data)
                        return;
                    const resErrors = normalizeErrorResponse(error.response.data);
                    if (resErrors.errors)
                        setErrors(resErrors.errors);
                    setDisabledButtons(false);
                    if (resErrors && resErrors.message)
                        AlertToastr.showErrorAlert(resErrors.message);
                    if (resErrors.errors && Array.isArray(resErrors.errors))
                        resErrors.errors.filter(x => x.PropertyName === '').map(x => {
                            AlertToastr.showErrorAlert(x.ErrorMessage);
                            return x;
                        });
                });
        } else {
            MmsService.createReport({
                ...formattedReportInfo,
                startDate: reportInfo.startDate,
            }, currentDepartment.id).then((response) => {
                if (response.data.isSuccessful) {
                    setDisabledButtons(false);
                    handleClose();
                    AlertToastr.showAlert(translation.notificationsReportAdded);
                    queryClient.invalidateQueries('reports').then();
                }
            })
                .catch((error) => {
                    if (!error || !error.response || !error.response.data)
                        return;
                    const resErrors = normalizeErrorResponse(error.response.data);
                    if (resErrors.errors)
                        setErrors(resErrors.errors);
                    setDisabledButtons(false);
                    if (resErrors && resErrors.message)
                        AlertToastr.showErrorAlert(`${resErrors.message}`);
                    if (error.response.data.errors)
                        error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
                            AlertToastr.showErrorAlert(x.ErrorMessage);
                            return x;
                        });
                });
        }
    };

    return (
        <Dialog open={isOpen}
                onClose={handleClose}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && reportInfo.recipients === '') {
                        e.preventDefault()
                        onSave();
                    }
                }}
                className="respModal scrollContentModal autoHeight">
            <div className="formContentHolder">
                <div className="modalTitleHolder">
                    <h1 className="modalTitle text-center">{report ? translation.saveReportModalEditReportLabel : translation.saveReportModalAddReportLabel}</h1>
                </div>
                <div className="formContent">
                    <div className="formContentBlock modalFieldLabelSettings">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <FormLabel component="legend"
                                       className="mb5 modalFieldLabel">{translation.saveReportModalReportSpecificationLabel}</FormLabel>
                            <FormControl component="fieldset" className="langRadioBtn">
                                {/*<FormLabel component="legend">Language</FormLabel>*/}
                                <RadioGroup
                                    row
                                    defaultValue="English"
                                    value={reportInfo.language}
                                    onChange={(e) => setReportInfo(prev => ({ ...prev, language: e.target.value }))}
                                >
                                    <FormControlLabel id="activitiesAddScheduledReportSpecificationEngBtn" value="English" control={<Radio color="default" />}
                                                      label="English" />
                                    <FormControlLabel id="activitiesAddScheduledReportSpecificationRusBtn" value="Russian" control={<Radio color="default" />}
                                                      label="Russian" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.name ? errors.name.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.name}
                                    onChange={e => {
                                        if (errors.name)
                                            errors.name = null;
                                        setReportInfo({ ...reportInfo, name: e.target.value });
                                    }}
                                    error={errors && errors.name && errors.name.length > 0}
                                    variant="outlined"
                                    margin="normal"
                                    id="activitiesAddScheduledReportNameField"
                                    label={translation.saveReportModalNameLabel}
                                    fullWidth
                                />
                            </div>
                        </Tooltip>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.frequency ? errors.frequency.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.frequency}
                                    onChange={e => {
                                        if (errors.frequency)
                                            errors.frequency = null;
                                        setReportInfo({ ...reportInfo, frequency: e.target.value });
                                    }}
                                    error={errors && errors.frequency && errors.frequency.length > 0}
                                    variant="outlined"
                                    margin="normal"
                                    label={translation.saveReportModalFrequencyLabel}
                                    fullWidth
                                    select
                                    id="activitiesAddScheduledReportChangeFrequency"
                                >
                                    {frequencyOptions && frequencyOptions.map((frequencyOption) =>
                                        <MenuItem key={frequencyOption.item} className="filterMenuItem"
                                                  id="activitiesAddScheduledReportChooseFrequency"
                                                  value={frequencyOption.item}>
                                            {frequencyOption.name}
                                        </MenuItem>)}
                                </TextField>
                            </div>
                        </Tooltip>
                        <div
                            className={errors && errors.startDate ? 'datePickerWithLabel hasError' : 'datePickerWithLabel'}>
                            <Tooltip disableFocusListener disableTouchListener
                                     title={errors && errors.startDate ? errors.startDate.join('\n') : ''}>
                                <div>
                                    <div className="labelLike"
                                         style={{ left: '-10px' }}>{translation.saveReportModalStartDateLabel}</div>
                                    <DatePicker
                                        onChange={e => {
                                            //TODO: timezone
                                            if (errors.startDate)
                                                errors.startDate = null;

                                            let date = new Date(e);

                                            setReportInfo({ ...reportInfo, startDate: date });
                                        }}
                                        minDate={Date.now()}
                                        value={reportInfo.startDate ? `${moment(reportInfo.startDate).format('L')} ${moment(reportInfo.startDate).format('LT')}` : ''}
                                        selected={reportInfo.startDate}
                                        timeInputLabel={translation.saveReportModalTimeLabel}
                                        showTimeSelect
                                        id="activitiesAddScheduledReportDateField"
                                        timeFormat="p"
                                        timeIntervals={15}
                                        dateFormat="Pp"
                                        className="formControlDatePicker"
                                        minTime={null}
                                        timeCaption={translation.saveReportModalTimeLabel}
                                        withPortal={isMobile}
                                        locale={appLanguage}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.recipients ? errors.recipients.join('\n') : ''}>
                            <TextField
                                value={reportInfo.recipients}
                                onChange={e => {
                                    // if (errors.name)
                                    //     errors.name = null;
                                    setReportInfo({ ...reportInfo, recipients: e.target.value });
                                }}
                                error={!!errors && !!errors.recipients}
                                variant="outlined"
                                margin="normal"
                                label={translation.saveReportModalRecipientsLabel}
                                minRows={4}
                                multiline
                                id="activitiesAddScheduledReportRecipientsField"
                                fullWidth
                            />
                        </Tooltip>
                    </div>
                    <div className="formContentBlock modalFieldLabelSettings">
                        <FormLabel component="legend"
                                   className="mb5 modalFieldLabel">{translation.saveReportModalAppliedFiltersLabel}</FormLabel>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.status ? errors.status.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.status ? reportInfo.status : 'empty'}
                                    onChange={e => {
                                        if (errors.status)
                                            errors.status = null;
                                        setReportInfo({ ...reportInfo, status: e.target.value });
                                    }}
                                    label={translation.saveReportModalStatusLabel}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    margin="normal"
                                    id="secAsideActivitiesSchedRepModalChangeStatus"
                                >
                                    <MenuItem key={emptyStatusOption.id} className="filterMenuItem"
                                              value={emptyStatusOption.id}>
                                        {emptyStatusOption.name}
                                    </MenuItem>
                                    {statusOptions && statusOptions.map((statusOption) =>
                                        <MenuItem key={statusOption.uiStatus} className="filterMenuItem"
                                                  id="secAsideActivitiesSchedRepModalChooseStatus"
                                                  value={statusOption.uiStatus}>
                                            {statusOption.name}
                                        </MenuItem>)}
                                </TextField>
                            </div>
                        </Tooltip>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.filterDateType ? errors.filterDateType.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.filterDateType ? reportInfo.filterDateType : 'empty'}
                                    onChange={e => {
                                        if (errors.filterDateType)
                                            errors.filterDateType = null;
                                        setReportInfo({ ...reportInfo, filterDateType: e.target.value });
                                    }}
                                    label={translation.saveReportModalDateRangeLabel}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    margin="normal"
                                    id="secAsideActivitiesSchedRepModalChangeDateRange"
                                >
                                    <MenuItem key={emptyDateRangeOption.id} className="filterMenuItem"
                                              value={emptyDateRangeOption.id}>
                                        {emptyDateRangeOption.name}
                                    </MenuItem>
                                    {filterTimeTypeOptions && filterTimeTypeOptions.map((filterTimeTypeOption) =>
                                        <MenuItem key={filterTimeTypeOption.filterDateType} className="filterMenuItem"
                                                  id="secAsideActivitiesSchedRepModalChooseDateRange"
                                                  value={filterTimeTypeOption.filterDateType}>
                                            {filterTimeTypeOption.name}
                                        </MenuItem>)}
                                </TextField>
                            </div>
                        </Tooltip>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.componentId ? errors.componentId.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.componentId ? reportInfo.componentId : 'empty'}
                                    onChange={e => {
                                        if (errors.componentId)
                                            errors.componentId = null;
                                        setReportInfo({ ...reportInfo, componentId: e.target.value });
                                    }}
                                    label={translation.saveReportModalComponentLabel}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    margin="normal"
                                    id="secAsideActivitiesSchedRepModalChangeAsset"
                                >
                                    <MenuItem key={emptyComponentOption.id} className="filterMenuItem"
                                              value={emptyComponentOption.id}>
                                        {emptyComponentOption.name}
                                    </MenuItem>
                                    {components && components.filter(c => !c.parentComponentId && c.isActive).map((component) =>
                                        <MenuItem key={component.id} className="filterMenuItem" value={component.id} id="secAsideActivitiesSchedRepModalChooseAsset" >
                                        {component.fullName}
                                        </MenuItem>)}.
                                </TextField>
                            </div>
                        </Tooltip>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.priority ? errors.priority.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.priority ? reportInfo.priority : 'empty'}
                                    onChange={e => {
                                        if (errors.priority)
                                            errors.priority = null;
                                        setReportInfo({ ...reportInfo, priority: e.target.value });
                                    }}
                                    label={translation.saveReportModalPriorityLabel}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    margin="normal"
                                    id="secAsideActivitiesSchedRepModalChangePriority"
                                >
                                    <MenuItem key={emptyPriorityOption.id} className="filterMenuItem"
                                              value={emptyPriorityOption.id}>
                                        {emptyPriorityOption.name}
                                    </MenuItem>
                                    {priorityOptions && priorityOptions.map((priorityOption) =>
                                        <MenuItem key={priorityOption.priority} className="filterMenuItem"
                                                  id="secAsideActivitiesSchedRepModalChoosePriority"
                                                  value={priorityOption.priority}>
                                            {priorityOption.name}
                                        </MenuItem>)}
                                </TextField>
                            </div>
                        </Tooltip>
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.assigneeId ? errors.assigneeId.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={reportInfo.assigneeId ? reportInfo.assigneeId : 'empty'}
                                    onChange={e => {
                                        if (errors.assigneeId)
                                            errors.assigneeId = null;
                                        setReportInfo({ ...reportInfo, assigneeId: e.target.value });
                                    }}
                                    label={translation.saveReportModalAssigneeLabel}
                                    variant="outlined"
                                    fullWidth
                                    select
                                    margin="normal"
                                    id="secAsideActivitiesSchedRepModalChangeAssignee"
                                >
                                    <MenuItem key={emptyGuidAssigneeOption.id} className="filterMenuItem"
                                              value={emptyGuidAssigneeOption.id}>
                                        {emptyGuidAssigneeOption.name}
                                    </MenuItem>
                                    <MenuItem key={emptyAssigneeOption.id} className="filterMenuItem"
                                              value={emptyAssigneeOption.id}>
                                        {emptyAssigneeOption.name}
                                    </MenuItem>
                                    {departmentEmployees && departmentEmployees.map((employee) =>
                                        <MenuItem key={employee.id} className="filterMenuItem"
                                                  id="secAsideActivitiesSchedRepModalChooseAssigneeDep"
                                                  value={employee.id}>
                                            {employee.name}
                                        </MenuItem>)}
                                </TextField>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className="btnHolder end fixedWidthBtn">
                <Button
                    disabled={!!disableButtons}
                    onClick={handleClose}
                    variant="outlined"
                    id="activitiesAddScheduledReportCancelBtn"
                >{translation.cancelButtonLabel}</Button>
                <Button
                    disabled={!!disableButtons}
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    id="activitiesAddScheduledReportSaveBtn"
                >{translation.saveButtonLabel}</Button>
            </div>
        </Dialog>
    );
};

const mapStateToProps = ({ mms }) => ({
    currentDepartment: mms.currentDepartment,
    components: mms.components,
    statusOptions: mms.statusOptions,
    employees: mms.employees,
    reports: mms.reports,
});

const mapDispatchToProps = {
    setReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveScheduledReportsModal);
