import React from 'react'

import Logo from '../../assets/img/logo-full.svg'

const AuthLayout = ({title, children}) => {
    return (
        <div className="loginLayout">
            <div className="loginContent">
                <div className="logoHolder">
                    <img src={Logo} alt='logo'/>
                </div>
                <div className="authFromBlock">
                    {title ? <h2 className='text-center mb32'>{title}</h2> : 'Page Title'}
                    {children}
                </div>
            </div>
        </div>

    )
}

export default AuthLayout
