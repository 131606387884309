import React, { Fragment, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, Popover } from '@material-ui/core';
import useTranslation from 'Hooks/useTranslation';
import { useAuth } from '../Contexts/AuthProvider';
import EventEmitter from 'utils/EventEmitter';
import { useBoxes } from 'Contexts/BoxContext';

const OptionsMenu = props => {

    const {
        onRefresh,
        refreshDisabled = false,
        onChangeJob,
        changeJobDisabled = false,
        onChangeUnit,
        changeUnitDisabled = false,
        onLoadTemplate,
        onSaveTemplate,
        allowManageTemplate,
        disableSaveTemplate,
        allowExportCsv = true,
        exportCsvDisabled = false,
        onExportCsv,
        isCurrentJobSet,
        exportCsvLabel,
    } = props;


    const { currentUser } = useAuth();
    const isOneJobPassword = useMemo(() => (currentUser && currentUser.isOneJobPassword), [currentUser])
    const allowExport = useMemo(() => currentUser.canExportCsv() && allowExportCsv, [currentUser, allowExportCsv]);

    const translation = useTranslation();

    const [addItemMenuAnchorEl, onAddItemMenuAnchorElChange] = useState(null);

    const { setModalStep } = useBoxes();

    const closeAddItemMenu = () => onAddItemMenuAnchorElChange(null);

    const handleRefreshClick = () => {
        if (onRefresh)
            onRefresh();
            EventEmitter.emit('refreshBtnEventLogger')
        closeAddItemMenu();
    };

    const handleChangeJobClick = () => {
        if (onChangeJob)
            onChangeJob();

        closeAddItemMenu();
    }

    const handleChangeUnitClick = () => {
        if (onChangeUnit)
            onChangeUnit();

        closeAddItemMenu();
    }

    const renderRefreshButton = () => {
        if (!onRefresh) return null;
        return (
            <MenuItem
                disabled={refreshDisabled}
                className="filterMenuItem"
                onClick={handleRefreshClick}
            >
                <span>{translation.refreshButtonLabel}</span>
            </MenuItem>
        );
    };

    const renderChangeJobButton = () => {
        if (!onChangeJob) return null;
        if (isOneJobPassword) return null;
        return (
            <MenuItem
                disabled={changeJobDisabled || !isCurrentJobSet}
                className="filterMenuItem"
                onClick={handleChangeJobClick}
            >
                {translation.changeJobLabel}
            </MenuItem>
        );
    };

    const renderChangeUnitButton = () => {
        if (!onChangeUnit) return null;
        return (
            <MenuItem
                disabled={changeUnitDisabled}
                className="filterMenuItem"
                onClick={handleChangeUnitClick}
            >
                {translation.changeUnitLabel}
            </MenuItem>
        );
    };

    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<ExpandMoreIcon />}
                onClick={(e) => onAddItemMenuAnchorElChange(e.target)}>
                {translation.dashboardOptionsLabel}
            </Button>
            <Popover
                anchorEl={addItemMenuAnchorEl}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                disableScrollLock={true}
                open={Boolean(addItemMenuAnchorEl)}
                onClose={closeAddItemMenu}
            >
                <MenuItem
                    className="filterMenuItem"
                    onClick={() => {
                        onLoadTemplate();
                        closeAddItemMenu();
                    }}
                >
                    {translation.dashboardLoadTemplateLabel}
                </MenuItem>
                {
                    allowManageTemplate && (
                        <MenuItem
                            disabled={disableSaveTemplate}
                            className="filterMenuItem"
                            onClick={() => {
                                onSaveTemplate();
                                closeAddItemMenu();
                            }}
                        >
                            {translation.saveTemplateButtonLabel}
                        </MenuItem>
                    )
                }
                {
                    allowExport && (
                        <MenuItem
                            disabled={exportCsvDisabled}
                            className="filterMenuItem"
                            onClick={() => {
                                onExportCsv();
                                closeAddItemMenu();
                                setModalStep('jobOptionsCsv')
                            }}
                        >
                            {exportCsvLabel}
                        </MenuItem>
                    )
                }
                {renderRefreshButton()}
                {renderChangeJobButton()}
                {renderChangeUnitButton()}
            </Popover>
        </Fragment>
    );
};

export default OptionsMenu;
