import React from 'react';

const UnitBoxDebug = ({data}) => {
    return (
        <pre>
            {JSON.stringify(data, null, 2)}
        </pre>
    );
};

export default UnitBoxDebug;
