import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useUniqueUnits from '../Hooks/useUniqueUnits'
import { useAuth } from './AuthProvider'
import { boxHelper } from '../helpers'
import { useUnit } from '../Hooks/useUnit'
import useDateTypes from '../Hooks/useDateTypes'
import useTranslation from '../Hooks/useTranslation'
import { useQueryClient } from 'react-query'
import useDataExport from '../Hooks/useDataExport'
import useShareStatesBetweenTabs from '../Hooks/useShareStatesBetweenTabs'
import { useSelector } from 'react-redux'
import EventEmitter from '../utils/EventEmitter'

const BoxContext = React.createContext(undefined)
const filterChannel = new BroadcastChannel('unit-filter')
const jobsFilterChannel = new BroadcastChannel('jobs-filter')
const refreshBoxesChannel = new BroadcastChannel('refresh-boxes')
const locationsFilterChannel = new BroadcastChannel('location-unit-filter')

const initColorsObj = {
  lastIndex: 0,
  values: {},
}

const defaultBoxFilters = {
  'dateType': 'last1hour',
  'jobId': '',
}

const getUnitTitle = (unitModel) => unitModel?.nameLocalized ? `${unitModel.nameLocalized} (${unitModel?.uId})` : ''

const BoxProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const chartBoxes = useSelector(state => state?.dashboard?.boxes || [])
  const translation = useTranslation()
  const { win } = useAuth()

  /** LOCATIONS **/
  const locationUnitsRequest = useUniqueUnits('locations')
  const { data: locationUnits } = locationUnitsRequest
  const [currentLocationUnit, setCurrentLocationUnit] = useState(null)
  const [currentLocationTemplate, setCurrentLocationTemplate] = useState(null)
  const [locationBoxes, setLocationBoxes] = useState([])
  const [locationBoxesFilters, setLocationBoxesFilters] = useState(defaultBoxFilters)
  const currentLocationUnitModel = useMemo(
    () => {
      return locationUnits ? locationUnits.find(item => item.id === currentLocationUnit) : []
    },
    [currentLocationUnit, locationUnits]
  )
  const currentLocationUnitTitle = useMemo(() => getUnitTitle(currentLocationUnitModel), [currentLocationUnitModel])
  const { data: currentLocationUnitModelFull } = useUnit(currentLocationUnit, 'locations')
  /** LOCATIONS END **/

  /** UNITS **/
  const unitsRequest = useUniqueUnits('units')
  const { data: units } = unitsRequest
  const [currentUnit, setCurrentUnit] = useState(null)
  const [currentUnitModel, setCurrentUnitModel] = useState(null)
  const [currentUnitTemplate, setCurrentUnitTemplate] = useState(null)
  const [boxes, setBoxes] = useState([])
  const [unitBoxes, setUnitBoxes] = useState([])
  const [codeColors, setCodeColors] = useShareStatesBetweenTabs('chart_colors', initColorsObj)
  const [unitCodeColors, setUnitCodeColors] = useShareStatesBetweenTabs('unit_chart_colors', initColorsObj)
  const [unitBoxesFilters, setUnitBoxesFilters] = useState(defaultBoxFilters)
  const [modalStep, setModalStep] = useState('')
  const [boxType, setBoxType] = useState('')
  // const currentUnitModel = useMemo(() => {

  //   return units ? units.find(item => item.uId === currentUnit) : []
  // }, [currentUnit, units])


  const currentUnitTitle = useMemo(() => getUnitTitle(currentUnitModel), [currentUnitModel])
  const { data: currentUnitModelFull } = useUnit(currentUnit, 'units')
  /** UNITS END **/

  const { data: dateTypes, isLoading: dateTypeIsLoading } = useDateTypes()
  const dataExportRequest = useDataExport('jobs')
  const dataExportUnitsRequest = useDataExport('units')
  const dataExportLocationsRequest = useDataExport('locations')
  const { data: dataExport } = dataExportRequest
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [startChartDate, setStartChartDate] = useState(null);
  const [endChartDate, setEndChartDate] = useState(null);

  const [startEventLoggerDate, setStartEventLoggerDate] = useState(null);
  const [endEventLoggerDate, setEndEventLoggerDate] = useState(null);

  const [jobsBoxesFilters, setJobsBoxesFilters] = useShareStatesBetweenTabs('jobs_boxes_filters', defaultBoxFilters);

  const updateChartDateRange = useCallback((from, to) => {
    setStartChartDate(from);
    setEndChartDate(to);
  }, []);

  const updateEventLoggerDateRange = useCallback((from, to) => {
    setStartEventLoggerDate(from);
    setEndEventLoggerDate(to);
  }, []);

  const updateDateRange = useCallback((from, to) => {
    setStartDate(from);
    setEndDate(to);
  }, []);

  const dateTypesOptions = useMemo(() => {
    const noneLabel = translation.selectFilterType
    if (!dateTypes.length) return [
      { filterType: 'none', name: noneLabel },
    ]
    return dateTypes.map(item => ({ ...item, name: item.name || noneLabel }))
  }, [dateTypes, translation])

  const isMainGroup = useMemo(() => win.parentId === null, [win])

  useEffect(() => {
    const onEvent = (company) => {
      if (company) {
        // console.log(company, 'company changed');
        setCurrentUnit(null)
        setUnitBoxes([])
        setCurrentLocationUnit(null)
        setLocationBoxes([])
      }
    }

    const listener = EventEmitter.addListener('CompanyChanged', onEvent)
    return () => {
      listener.remove()
    }
  }, [])

  useEffect(() => {
    const onEvent = (jobId) => {
      if (unitBoxesFilters.jobId) {
        setUnitBoxesFilters({
          ...unitBoxesFilters, jobId: jobId
        })
        setUnitBoxes(prev => (prev.map(b => ({ ...b, filters: {...b.filters, jobId} }))))
      }
    }

    const listener = EventEmitter.addListener('InitUnitBoxChartWithJobId', onEvent)
    return () => {
      listener.remove()
    }
  }, [unitBoxesFilters])

  useEffect(() => {
    const navigationEntries = performance.getEntriesByType("navigation");
    let wasReload = false;
  
    if (navigationEntries.length > 0) {
      wasReload = navigationEntries[0].type === 'reload';
    } else {
      // Альтернативний спосіб перевірки для старих браузерів
      wasReload = performance.navigation.type === performance.navigation.TYPE_RELOAD;
    }
  
    if (wasReload) {
      setJobsBoxesFilters(defaultBoxFilters);
    }
  }, [setJobsBoxesFilters]);
  

  useEffect(() => {

    setCodeColors(prev => {
      const maxColorIndex = 10
      let index = prev.lastIndex || 0
      const colors = { ...prev.values }
      chartBoxes.forEach(b => {
        if (b.channels) {
          b.channels.forEach(ch => {
            if (colors[ch.id] === undefined) {
              colors[ch.id] = index
              index = index === (maxColorIndex - 1) ? 0 : index + 1
            }
          })
        }

      })
      return {
        lastIndex: index,
        values: colors,
      }
    })

  }, [chartBoxes, setCodeColors])

  useEffect(() => {
    setUnitCodeColors(prev => {
      const maxColorIndex = 10
      let index = prev.lastIndex || 0
      const colors = { ...prev.values }
      unitBoxes.forEach(b => {
        if (b.channels) {
          b.channels.forEach(ch => {
            if (colors[ch.code] === undefined) {
              colors[ch.code] = index
              index = index === (maxColorIndex - 1) ? 0 : index + 1
            }
          })
        }
      })
      return {
        lastIndex: index,
        values: colors,
      }
    })
  }, [unitBoxes, setUnitCodeColors])

  useEffect(() => {
    setUnitCodeColors(prev => {
      const maxColorIndex = 10
      let index = prev.lastIndex || 0
      const colors = { ...prev.values }
      locationBoxes.forEach(b => {
        b.channels.forEach(ch => {
          if (colors[ch.code] === undefined) {
            colors[ch.code] = index
            index = index === (maxColorIndex - 1) ? 0 : index + 1
          }
        })
      })
      return {
        lastIndex: index,
        values: colors,
      }
    })
  }, [locationBoxes, setUnitCodeColors])

  // useEffect(() => {
  //     setCodeColors(prev => boxHelper.generateColorsMap(prev, unitBoxes));
  // }, [unitBoxes]);
  //
  // useEffect(() => {
  //     setCodeColors(prev => boxHelper.generateColorsMap(prev, locationBoxes));
  // }, [locationBoxes]);
  //
  // useEffect(() => {
  //     setCodeColors(prev => {
  //         if (boxes.length === 0) {
  //             return initColorsObj;
  //         }
  //
  //         const maxColorIndex = 10;
  //         let index = prev.lastIndex || 0;
  //         const colors = { ...prev.values };
  //         boxes.forEach(b => {
  //             b.channels.forEach(ch => {
  //                 if (colors[ch.id] === undefined) {
  //                     colors[ch.id] = index;
  //                     index = index === (maxColorIndex - 1) ? 0 : index + 1;
  //                 }
  //             });
  //         });
  //         return {
  //             lastIndex: index,
  //             values: colors,
  //         };
  //     });
  // }, [boxes]);

  const addBoxes = useCallback(boxes => setBoxes(boxes), [])
  const addBox = useCallback(boxData => setBoxes(prev => boxHelper.addBox(prev, boxData, jobsBoxesFilters)), [jobsBoxesFilters])
  const editBox = useCallback(boxData => setBoxes(prev => boxHelper.updateBox(prev, boxData)), [])
  const removeBox = useCallback(boxId => setBoxes(prev => boxHelper.removeBox(prev, boxId)), [])
  const updateJobsBoxesFilters = useCallback(filters => setBoxes(prev => boxHelper.updateBoxesKey(prev, 'filters', filters)), [])

  const addUnitBoxes = useCallback(boxes => setUnitBoxes(boxes), [])
  const addUnitBox = useCallback(boxData => setUnitBoxes(prev => boxHelper.addBox(prev, boxData, unitBoxesFilters)), [unitBoxesFilters])
  const editUnitBox = useCallback(boxData => setUnitBoxes(prev => boxHelper.updateBox(prev, boxData)), [])
  const removeUnitBox = useCallback(boxId => setUnitBoxes(prev => boxHelper.removeBox(prev, boxId)), [])
  const updateUnitBoxesFilters = useCallback(filters => setUnitBoxes(prev => boxHelper.updateBoxesKey(prev, 'filters', filters)), [])

  const addLocationBoxes = useCallback(boxes => setLocationBoxes(boxes), [])
  const addLocationBox = useCallback(boxData => setLocationBoxes(prev => boxHelper.addBox(prev, boxData, locationBoxesFilters)), [locationBoxesFilters])
  const editLocationBox = useCallback(boxData => setLocationBoxes(prev => boxHelper.updateBox(prev, boxData)), [])
  const removeLocationBox = useCallback(boxId => setLocationBoxes(prev => boxHelper.removeBox(prev, boxId)), [])
  const updateLocationBoxesFilters = useCallback(filters => setLocationBoxes(prev => boxHelper.updateBoxesKey(prev, 'filters', filters)), [])

  const refresh = useCallback((section) => {
    if (section === 'units') {
      queryClient.clear('unit')
      setUnitBoxes(boxes => [...boxes])
    }
    if (section === 'locations') {
      queryClient.clear('location')
      setLocationBoxes(boxes => [...boxes])
    }
  }, [queryClient])

  useEffect(() => {
    const refreshHandler = message => {
      refresh(message.data)
    }
    refreshBoxesChannel.addEventListener('message', refreshHandler)

    return () => {
      refreshBoxesChannel.removeEventListener('message', refreshHandler)
    }
  }, [refresh])

  const refreshBoxes = useCallback(section => {
    refreshBoxesChannel.postMessage(section)
    refresh(section)
  }, [refresh])

  const resetCurrentUnit = useCallback(() => {
    if (!unitBoxes.length && isMainGroup) {
      setCurrentUnit(null)
    }
  }, [isMainGroup, unitBoxes.length])

  const resetCurrentLocationUnit = useCallback(() => {
    if (!locationBoxes.length && isMainGroup) {
      setCurrentLocationUnit(null)
    }
  }, [isMainGroup, locationBoxes.length])

  useEffect(() => {
    const filterMessageHandler = message => {
      setUnitBoxesFilters(message.data)
      updateUnitBoxesFilters(message.data)
    }

    if (!isMainGroup) {
      filterChannel.addEventListener('message', filterMessageHandler)
    }

    return () => {
      filterChannel.removeEventListener('message', filterMessageHandler)
    }
  }, [isMainGroup, updateUnitBoxesFilters, setUnitBoxesFilters])

  useEffect(() => {
    const filterMessageHandler = message => {
      setLocationBoxesFilters(message.data)
      updateLocationBoxesFilters(message.data)
    }

    if (!isMainGroup) {
      locationsFilterChannel.addEventListener('message', filterMessageHandler)
    }

    return () => {
      locationsFilterChannel.removeEventListener('message', filterMessageHandler)
    }
  }, [isMainGroup, updateLocationBoxesFilters, setLocationBoxesFilters])

  useEffect(() => {
    const filterMessageHandler = message => {
      setJobsBoxesFilters(message.data)
      updateJobsBoxesFilters(message.data)
    }

    if (!isMainGroup) {
      jobsFilterChannel.addEventListener('message', filterMessageHandler)
    }

    return () => {
      jobsFilterChannel.removeEventListener('message', filterMessageHandler)
    }
  }, [isMainGroup, updateJobsBoxesFilters, setJobsBoxesFilters])

  const changeUnitGlobalFilters = useCallback((filters) => {
    setUnitBoxesFilters(filters)
    updateUnitBoxesFilters(filters)
    filterChannel.postMessage(filters)
  }, [setUnitBoxesFilters, updateUnitBoxesFilters])

  const changeLocationGlobalFilters = useCallback((filters) => {
    setLocationBoxesFilters(filters)
    updateLocationBoxesFilters(filters)
    locationsFilterChannel.postMessage(filters)
  }, [setLocationBoxesFilters, updateLocationBoxesFilters])

  const changeJobsGlobalFilters = useCallback((filters) => {
    setJobsBoxesFilters(filters)
    updateJobsBoxesFilters(filters)
    jobsFilterChannel.postMessage(filters)
  }, [setJobsBoxesFilters, updateJobsBoxesFilters])

  useEffect(() => {
    setJobsBoxesFilters(defaultBoxFilters);
  }, [setJobsBoxesFilters, win.id]);

  return (
    <BoxContext.Provider value={{
      boxes,
      addBoxes,
      addBox,
      editBox,
      removeBox,

      codeColors,
      unitCodeColors,
      isMainGroup,
      dateTypesOptions,
      dateTypeIsLoading,
      units,
      unitsRequest,
      currentUnit,
      setCurrentUnit,
      setCurrentUnitModel,
      currentUnitModel,
      currentUnitModelFull,
      currentUnitTitle,
      unitBoxes,
      addUnitBoxes,
      addUnitBox,
      editUnitBox,
      removeUnitBox,
      currentUnitTemplate,
      setCurrentUnitTemplate,
      resetCurrentUnit,
      unitBoxesFilters,
      setUnitBoxesFilters,
      changeUnitGlobalFilters,
      //locations
      locationUnitsRequest,
      currentLocationUnit,
      setCurrentLocationUnit,
      currentLocationUnitModel,
      currentLocationUnitModelFull,
      currentLocationUnitTitle,
      locationBoxes,
      addLocationBoxes,
      addLocationBox,
      editLocationBox,
      removeLocationBox,
      currentLocationTemplate,
      setCurrentLocationTemplate,
      resetCurrentLocationUnit,
      locationBoxesFilters,
      setLocationBoxesFilters,
      changeLocationGlobalFilters,
      refreshBoxes,
      changeJobsGlobalFilters,
      jobsBoxesFilters,
      dataExportRequest,
      dataExportUnitsRequest,
      dataExportLocationsRequest,
      dataExport,
      startDate,
      endDate,
      updateDateRange,
      startChartDate,
      endChartDate,
      updateChartDateRange,
      modalStep,
      setModalStep,
      updateEventLoggerDateRange,
      startEventLoggerDate,
      endEventLoggerDate,
      setBoxType,
      boxType
    }}>
      {children}
    </BoxContext.Provider>
  )
}

const useBoxes = () => {
  const context = React.useContext(BoxContext)
  if (context === undefined) {
    throw new Error('useBoxes must be used within a BoxProvider')
  }
  return context
}

export {
  BoxProvider,
  useBoxes,
}
