import React, { useMemo } from 'react';
import ListTable from '../../ListTable';
import { dataTableFormatter } from '../../../helpers';

const UnitDataTable = props => {

    const {
        channelsData,
        dateFrom,
        dateTo,
        timeType,
        timeFormat,
        timeDiff,
        dateTimeFormat,
        job,
    } = props;

    const timeZone = useMemo(() => job ? job.timeZone : null, [job]);

    const tableColumns = useMemo(() => {
        return dataTableFormatter.tableColumns(channelsData, {
            timeType,
            timeFormat,
            timeDiff,
            timeZone,
            dateTimeFormat,
        });

    }, [
        channelsData,
        timeType,
        timeFormat,
        timeDiff,
        timeZone,
        dateTimeFormat,
    ]);

    const tableData = useMemo(() => {
        return dataTableFormatter.tableData(channelsData, {
            dateFrom,
            dateTo,
        });

    }, [channelsData, dateFrom, dateTo]);

    return (
        <ListTable
            emptyDataText={(
                <span>
                    <span>No data found.</span>
                </span>
            )}
            initSort={[{ 'id': 'time', 'desc': true }]}
            columns={tableColumns}
            enableSearch={false}
            enableColumnFilters={false}
            data={tableData}
            tableClass="bordered"
        />
    );
};

export default UnitDataTable;
