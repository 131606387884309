import React, {useEffect, useState} from 'react'
import SignUpForm from './SignUpForm.component'
import useForm from 'react-hook-form'
import useApiCall from 'Hooks/useApiCall'
import authEndpoints from 'Services/endpoints/auth.endpoints'
import useTranslation from 'Hooks/useTranslation'

const SignUpPage = () => {

    const translation = useTranslation();
    const [request, signin] = useApiCall()
    const [successSignUp, setSuccessSignUp] = useState(false)

    const {...formProps} = useForm({
        defaultValues: {
            email: '',
            licenseId: '',
            password: '',
            confirmPassword: ''
        }
    })

    useEffect(() => {
        if (request.response) {
            setSuccessSignUp(true)
        }
    }, [
        request.response,
        setSuccessSignUp
    ])

    const handleSubmit = data => {
        signin({
            ...authEndpoints.signUp(),
            data
        })
    }


    if (successSignUp)
        return (
            <div className="text-center">
                {translation.signUpPageAccountRegisteredLabel}
            </div>
        )

    return (

        <SignUpForm {...formProps}
                    onSubmit={handleSubmit}
                    request={request}/>
    )
}

export default SignUpPage
