import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

const getUnitInitEventLoggerData = async (signalrRequestBody) => {
    const response = await axios.post(`${apiBaseUrl}/Events/units/initialize`, signalrRequestBody);
    if (response.status === 204) return {}
    return response.data;
};

export default function useUnitInitEventLoggerData({ signalrRequestBody }) {

    const channelsData = {
        uId: signalrRequestBody.uId,
        jobType: 'unit',
        jobId: signalrRequestBody.jobId,
        dateFrom: signalrRequestBody.dateFrom,
        dateTo: signalrRequestBody.dateTo,
        dateType: signalrRequestBody.dateType
    }

    return useQuery(
        ['unit-eventlogger-data', channelsData],
        () => getUnitInitEventLoggerData(channelsData),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: false
        }
    );
}
