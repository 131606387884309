import React, { useMemo } from 'react';
import LetteredAvatar from 'react-lettered-avatar';

const UserAvatar = ({ name }) => {
    const avatarName = useMemo(() => name.replace(/[^a-zа-яё\s]/gi, '').split(' ').slice(0, 2).join(' '), [name]);

    return (
        <LetteredAvatar size={32} name={avatarName} />
    );
};

export default UserAvatar;
