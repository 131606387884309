import { useEffect, useMemo } from 'react';
import { apiBaseUrl } from '../../config';
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { useClientMethod, useHub, useHubMethod } from 'react-use-signalr';

const UnitBoxChartSignalR = props => {

    const {
        token,
        companyId,
        requestBody,
        lastProcessedTimestamp,
        onIncomingData,
    } = props;


    const signalRConnection = useMemo(
        () => {
            const buildHubUrl = companyId
                ? `${apiBaseUrl}/ws/data?companyid=${companyId}`
                : `${apiBaseUrl}/ws/data`;

            const protocol = new JsonHubProtocol();
            const transport = HttpTransportType.WebSockets;

            const options = {
                skipNegotiation: true,
                transport,
                accessTokenFactory: () => token,
                headers: { authorization: token ? `Token ${token}` : `` },
            };

            return new HubConnectionBuilder()
                .withUrl(buildHubUrl, options)
                .withAutomaticReconnect()
                .withHubProtocol(protocol)
                .configureLogging(LogLevel.Information)
                .build();
        }, [token, companyId],
    );

    const {
        hubConnectionState,
        // error
    } = useHub(signalRConnection);
    const {
        invoke,
    } = useHubMethod(signalRConnection, 'InitializeTimeBasedChannelDataForUnits');

    useClientMethod(signalRConnection, 'ChannelDataResponse', onIncomingData);

    useEffect(() => {
        if (requestBody && lastProcessedTimestamp && hubConnectionState === 'Connected') {
            invoke(requestBody, lastProcessedTimestamp).then();
        }
        return () => {
            signalRConnection.off('InitializeTimeBasedChannelDataForUnits')
        }
    }, [
        requestBody,
        lastProcessedTimestamp,
        invoke,
        hubConnectionState,
        signalRConnection
    ]);

    return null;
};

export default UnitBoxChartSignalR;
