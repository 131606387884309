import { useEffect, useState } from 'react'
import { buildJobsHub } from 'Components/SignalrSubscribes/signalRHubs'
import { useAuth } from 'Contexts/AuthProvider'
import { connect } from 'react-redux'
import dmsApiService from 'Services/DMS/dms-api.service'
import { setJobs } from 'Store/modules/dashboard/actions'

const JobsManagerSignalr = ({ currentCompany, isSuperUser, setJobs }) => {

    const [hubConnection, setHubConnection] = useState(null)
    const [, setConnectionState] = useState(null)
    const { token } = useAuth()

    useEffect(() => {
        if (token)
            setHubConnection(buildJobsHub(token))
    }, [token])

    useEffect(() => {
        const updateJobs = () => {
            async function fetchJobs() {
                if (isSuperUser && currentCompany.id) {
                    let jobsRes = await dmsApiService.getCompanyJobsShort(currentCompany.id)
                    setJobs(jobsRes)
                }
                else {
                    let jobsRes = await dmsApiService.getJobsShort()
                    setJobs(jobsRes)
                }
            }
            fetchJobs().then()
        }

        const startHubConnection = async () => {
            try {
                await hubConnection.start()
                console.log('jobs hub started...')
                setConnectionState(hubConnection.state)
                hubConnection.on('JobsUpdated', updateJobs)

                await hubConnection.invoke('SubscribeToJobUpdates')
            } catch (err) {
                console.log('Error while establishing connection: ', err)
            }
        }

        if (isSuperUser && !currentCompany)
            return

        if (!hubConnection || hubConnection.startPromise || hubConnection.state === 'Connecting' || hubConnection.state === 'Reconnecting' || hubConnection.state === 'Disconnecting') {
            return;
        }

        if (hubConnection.state === 'Disconnected') {
            startHubConnection().then()
        }
        else {
            hubConnection.stop().then(() => {
                startHubConnection().then()
            })
        }

        hubConnection.onclose(error => {
            console.log('jobs hub closed...')
            setConnectionState(hubConnection.state)
        })

        return () => {
            if (hubConnection.state === 'Connected')
                hubConnection.stop()
        }

    }, [hubConnection, currentCompany, isSuperUser, setJobs])

    return null
}


const mapStateToProps = ({ dashboard }) => ({
    currentCompany: dashboard.currentCompany
})

const mapDispatchToProps = {
    setJobs
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsManagerSignalr)
