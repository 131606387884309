import React, { useEffect, useMemo, useState, useCallback } from 'react'
import Avatar from 'assets/img/avatar.png'
import NewUserModal from 'Components/modals/new-user-modal/new-user-modal.component'
import SelectJobModal from 'Components/modals/SelectJobModal/SelectJobModal'
import { IconButton, Switch } from "@material-ui/core"
import DmsService from 'Services/DMS/dms-api.service'
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import { makeStyles } from '@material-ui/core/styles'
import AlertToastr from 'utils/alert'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import UserListHub from 'Components/SignalrSubscribes/UserListHub'
import { userHasPermission } from 'helpers'
import { useAuth } from 'Contexts/AuthProvider'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useTranslation from 'Hooks/useTranslation'
import { connect } from 'react-redux'
const descConst = 'descending'
const ascConst = 'ascending'
const arrowDownClassName = 'hasArrow'
const arrowUpClassName = 'hasArrow up'
const noContentStatus = 204
const successStatus = 200

const RemoveIcon = () => <div className='removeIcon' />
const CopyIcon = () => <div className='copyIcon' />
const ResendIcon = () => <div className='resendIcon' />
const IcoPlusWhite = () => <div className='plusIcon' />

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

const UsersPage = ({ currentCompany }) => {
    const translation = useTranslation();
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('users')
    const [newUserModalIsOpen, onNewUserModalIsOpenChange] = useState(false)
    const [newOneJobPasswordModalIsOpen, onNewOneJobPasswordModalIsOpenChange] = useState(false)
    const [removeUserModalIsOpen, onRemoveUserModalIsOpen] = useState(false)
    const [removeOneJobPasswordModalIsOpen, onRemoveOneJobPasswordModalIsOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [jobs, setJobs] = useState([])
    const [oneJobPasswords, setOneJobPasswords] = useState([])
    const [updateOneJobPassword, setUpdateOneJobPassword] = useState(true)
    const [userData, setUserData] = useState()
    const [oneJobPasswordData, setOneJobPasswordData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [companyDetails, setCompanyDetails] = useState(false)
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' })
    const [oneJobPasswordSortConfig, setOneJobPasswordSortConfig] = useState({ key: '', direction: '' })
    const { currentUser } = useAuth()
    const allowManageAdmins = useMemo(() => userHasPermission(currentUser, 'manage_admins'), [currentUser])
    const allowManageMasters = useMemo(() => userHasPermission(currentUser, 'manage_masters'), [currentUser])
    const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser])

    const companyId = currentCompany ? currentCompany.id : currentUser?.companyId

    useEffect(() => {
        try {
            if (!companyId)
                return
            if (isSuperUser) {
                setCompanyDetails(currentCompany)
            } else
                DmsService.getCompany(companyId)
                    .then(response => setCompanyDetails(response.data))
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message)
        }
    }, [companyId, currentCompany, isSuperUser])

    useEffect(() => {
        setIsLoading(true)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        const fetchOneJobPasswords = async () => {
            try {
                let response
                if (isSuperUser) {
                    if (!currentCompany)
                        return
                    response = await DmsService.getOneJobPasswordsByCompanyId(currentCompany.id)
                } else {
                    response = await DmsService.getOneJobPasswords()
                }
                if (response.status === noContentStatus) {
                    setOneJobPasswords([])
                }
                if (response.status === successStatus) {
                    response.data.map((oneJobPassword) => {
                        const passwordJob = jobs.find(job => job.id === oneJobPassword.jobId)
                        if (passwordJob) {
                            oneJobPassword.jobName = passwordJob.name
                            oneJobPassword.jobStartTime = passwordJob.startTime
                        }
                        return oneJobPassword
                    })
                    setOneJobPasswords(response.data)
                }
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            }
        }
        if (updateOneJobPassword && jobs.length) {
            fetchOneJobPasswords().then()
            setUpdateOneJobPassword(false)
        }

    }, [jobs, updateOneJobPassword, currentCompany, isSuperUser])

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                let response
                if (isSuperUser) {
                    if (!currentCompany)
                        return
                    response = await DmsService.getCompanyJobsShort(currentCompany.id)
                } else {
                    response = await DmsService.getJobsShort()
                }
                setJobs(response)
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            }
        }
        fetchJobs().then()
    }, [currentCompany, isSuperUser])

    const sortedUsers = useMemo(() => {
        let sortableUsers = [...users]
        if (sortConfig !== null) {

            if (sortConfig.key === 'status') {
                sortableUsers.sort((a, b) => {
                    if (a.isActivated < b.isActivated) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (a.isActivated > b.isActivated) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (a.isActivated && b.isActivated) {
                        if (a.isEnabled < b.isEnabled) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.isEnabled > b.isEnabled) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }
            if (sortConfig.key === 'admin') {
                sortableUsers.sort((a, b) => {
                    if (a.isAdmin < b.isAdmin) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (a.isAdmin > b.isAdmin) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (a.isAdmin && b.isAdmin) {
                        if (a.created < b.created) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.created > b.created) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }
            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === ascConst ? -1 : 1
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === ascConst ? 1 : -1
                }
                return 0
            })
        }
        return sortableUsers
    }, [users, sortConfig])

    const sortedOneJobPasswords = useMemo(() => {
        let sortedPasswords = [...oneJobPasswords]
        if (oneJobPasswordSortConfig && oneJobPasswordSortConfig.key) {
            if (oneJobPasswordSortConfig.key === 'status') {
                sortedPasswords.sort((a, b) => {
                    if (a.isEnabled < b.isEnabled) {
                        return oneJobPasswordSortConfig.direction === ascConst ? -1 : 1
                    }
                    if (a.isEnabled > b.isEnabled) {
                        return oneJobPasswordSortConfig.direction === ascConst ? 1 : -1
                    }
                    return 0
                })
            }
            sortedPasswords.sort((a, b) => {
                if (a[oneJobPasswordSortConfig.key] < b[oneJobPasswordSortConfig.key]) {
                    return oneJobPasswordSortConfig.direction === ascConst ? -1 : 1
                }
                if (a[oneJobPasswordSortConfig.key] > b[oneJobPasswordSortConfig.key]) {
                    return oneJobPasswordSortConfig.direction === ascConst ? 1 : -1
                }
                return 0
            })
        }
        else {
            sortedPasswords.sort((a, b) => {
                if (a.jobStartTime < b.jobStartTime) {
                    return 1
                }
                if (a.jobStartTime > b.jobStartTime) {
                    return -1
                }
                return 0
            })
        }
        return sortedPasswords
    }, [oneJobPasswords, oneJobPasswordSortConfig])

    const requestSort = (key) => {
        let direction = ascConst
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === ascConst
        ) {
            direction = descConst
        }
        setSortConfig({ key, direction })
    }

    const requestOneJobPasswordSort = (key) => {
        let direction = ascConst
        if (
            oneJobPasswordSortConfig &&
            oneJobPasswordSortConfig.key === key &&
            oneJobPasswordSortConfig.direction === ascConst
        ) {
            direction = descConst
        }
        setOneJobPasswordSortConfig({ key, direction })
    }

    const getClassNamesFor = (name) => {
        if (!sortConfig || sortConfig.key !== name) {
            return
        }
        if (sortConfig.direction)
            return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName
    }

    const getClassNamesForOneJobPassword = (name) => {
        if (!oneJobPasswordSortConfig || oneJobPasswordSortConfig.key !== name) {
            return
        }
        if (oneJobPasswordSortConfig.direction)
            return oneJobPasswordSortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName
    }

    const onAllowOneJobPasswordValueChanged = () => {
        DmsService.updateCompany(companyDetails).then()
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const fetchUsers = useCallback(async () => {
        try {
            let response
            if (isSuperUser)
                response = await DmsService.getUsersByCompanyId(currentCompany.id)
            else
                response = await DmsService.getUsers()
            if (response.status === noContentStatus) {
                setUsers([])
            }
            if (response.status === successStatus) {
                setUsers(response.data)
            }
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message)
        }
    }, [currentCompany, isSuperUser])

    useEffect(() => {
        fetchUsers().then()
    }, [fetchUsers])

    const onCloseModal = (userInfo) => {
        if (!userInfo) {
            return
        }
        if (!userData) {
            DmsService.createUser(userInfo).then(res => {
                fetchUsers().then()
                setActiveTab('users')
            })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        } else {
            DmsService.updateUser(userInfo).then(res =>
                fetchUsers().then())
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        }
    }

    const onCreateOneJobPassword = (jobId) => {
        onNewOneJobPasswordModalIsOpenChange(false)
        DmsService.createOneJobPassword(jobId, companyId).then(res => {
            setUpdateOneJobPassword(true)
            setActiveTab('ojp')
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    const onDeleteUser = () => {
        DmsService.deleteUser(userData.id).then(res =>
            fetchUsers().then())
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const onDeleteOneJobPassword = () => {
        DmsService.deleteOneJobPassword(oneJobPasswordData.id).then(res =>
            setUpdateOneJobPassword(true)
        )
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const resendEmail = (user) => {
        if (allowManageAdmins || !user.isAdmin) {
            let email = users.find(userInfo => userInfo.id === user.id).email
            DmsService.resendUserEmail(email).then(res => {
                AlertToastr.showAlert(translation.notificationsEmailResent)
            })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.Message)
                        AlertToastr.showErrorAlert(error.response.data.Message)
                })
        }
    }

    const editUser = (userInfo) => {
        setUserData(userInfo)
        onNewUserModalIsOpenChange(true)
    }

    const createUser = (e) => {
        setUserData(null)
        onNewUserModalIsOpenChange(true)
    }

    const onEnabledChanged = (userInfo) => {
        if (allowManageAdmins || !userInfo.isAdmin) {
            userInfo.isEnabled = !userInfo.isEnabled
            DmsService.updateUser(userInfo).then(res =>
                fetchUsers().then())
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.Message)
                        AlertToastr.showErrorAlert(error.response.data.Message)
                })
        }
    }

    const onOneJobPasswordEnabledChanged = (oneJobPassword) => {
        oneJobPassword.isEnabled = !oneJobPassword.isEnabled
        DmsService.updateIsEnabledOneJobPassword(oneJobPassword.id, !oneJobPassword.isEnabled).then(res =>
            setUpdateOneJobPassword(true))
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const updateUserStatus = useCallback((userId, isOnline) => {
        setUsers(users => {
            return users.map(user => {
                if (user.id === userId)
                    user.isOnline = isOnline
                return user
            })
        })
    }, [setUsers])

    const updateSessions = useCallback((itemId, count) => {
        setUsers(users => {
            return users.map(user => {
                if (user.id === itemId)
                    user.activeSessions = count
                return user
            })
        })
        setOneJobPasswords(oneJobPasswords => {
            return oneJobPasswords.map(oneJobPassword => {
                if (oneJobPassword.id === itemId)
                    oneJobPassword.activeSessions = count
                return oneJobPassword
            })
        })
    }, [setUsers])

    const copyLinkToClipboard = async (id) => {
        try {
            let response = await DmsService.getOneJobPasswordLink(id)
            navigator.clipboard.writeText(response.data);
            AlertToastr.showAlert(translation.notificationsLinkCopied)
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message)
        }

    }

    const userClicked = (user) => {
        if ((allowManageAdmins || !user.isAdmin) && !user.isRoot)
            editUser(user)
    }

    const getClassNameForUser = (user) => {
        if (!user.isActivated) {
            return 'inActive'
        }
        if ((!allowManageAdmins && user.isAdmin) || user.isRoot) {
            return 'disabled'
        }
        return ''
    }

    return (
        <main>
            <div className="mainContainer">
                <div className="pageBtnSection">
                    <div className="part">
                        <ul className="tabsLike mb0">
                            <li className={`tabsLikeItem ${activeTab === 'users' ? 'active' : ''}`}
                                onClick={(e) => { setActiveTab('users') }}>{`${translation.settingsTablesUsersLabel} (${users.length})`}</li>
                            <li className={`tabsLikeItem ${activeTab === 'ojp' ? 'active' : ''}`}
                                onClick={(e) => { setActiveTab('ojp') }}>{`${translation.settingsTablesOneJobPasswordsLabel} (${oneJobPasswords.length})`}</li>
                        </ul>
                    </div>
                </div>
                <div className="pageBlock">
                    {activeTab === 'users' &&
                        <>
                            <div className="pageBtnSection pt0">
                                <div className="part">
                                    <Button
                                        className="addItem"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<IcoPlusWhite />}
                                        data-toggle="modal"
                                        onClick={createUser}>
                                        {translation.settingsTablesNewUserLabel}
                                    </Button>
                                </div>
                            </div>
                            <div className="pageCard noPadding">
                                <div className="tableHolder">
                                    <table>
                                        <thead>
                                            <tr className='headingRow'>
                                                <th onClick={() => requestSort('name')}
                                                    className={getClassNamesFor('name')}>
                                                    {translation.settingsTablesNameHeader}
                                                </th>
                                                <th onClick={() => requestSort('email')}
                                                    className={getClassNamesFor('email')}>
                                                    {translation.settingsTablesEmailHeader}
                                                </th>
                                                <th onClick={() => requestSort('status')}
                                                    className={`text-center ${getClassNamesFor('status')}`}>
                                                    {translation.settingsTablesStatusHeader}
                                                </th>
                                                {allowManageMasters && <th onClick={() => requestSort('master')}
                                                                    className={`text-center ${getClassNamesFor('master')}`}>
                                                    {translation.settingsTablesMasterHeader}
                                                </th>}
                                                {isSuperUser && <th onClick={() => requestSort('root')}
                                                    className={`text-center ${getClassNamesFor('root')}`}>
                                                    {translation.settingsTablesRootHeader}
                                                </th>}
                                                <th onClick={() => requestSort('admin')}
                                                    className={`text-center ${getClassNamesFor('admin')}`}>
                                                    {translation.settingsTablesAdminHeader}
                                                </th>
                                                <th onClick={() => requestSort('root')}
                                                    className={`text-center ${getClassNamesFor('root')}`}>
                                                    {translation.settingsTablesMmsManagerHeader}
                                                </th>
                                                <th onClick={() => requestSort('activeSessions')}
                                                    className={`text-center ${getClassNamesFor('activeSessions')}`}>
                                                    {translation.settingsTablesSessionsHeader}
                                                </th>
                                                <th onClick={() => requestSort('created')}
                                                    className={getClassNamesFor('created')}>
                                                    {translation.settingsTablesCreatedHeader}
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (sortedUsers || sortedUsers.length === 0) ? sortedUsers.map(user => {
                                                    return (
                                                        <tr key={user.id} className={getClassNameForUser(user)}>
                                                            <td onClick={() => userClicked(user)}>
                                                                <div className="userInfo">
                                                                    {
                                                                        user.isOnline ? (
                                                                            <Badge
                                                                                color="primary"
                                                                                variant="dot"
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }}
                                                                            >
                                                                                <img src={Avatar} className="userImg sm"
                                                                                    alt={user.name} />
                                                                            </Badge>
                                                                        ) : <img src={Avatar} className="userImg sm"
                                                                            alt={user.name} />
                                                                    }

                                                                    <span className="userName">{user.name}</span>
                                                                </div>
                                                            </td>
                                                            <td onClick={() => userClicked(user)}>{user.email}</td>
                                                            {user.isActivated ?
                                                                <td className='text-center switchCell' onClick={() => onEnabledChanged(user)}>
                                                                    <Switch checked={user.isEnabled}
                                                                        color="primary" />
                                                                </td> : <td className='text-center' onClick={() => userClicked(user)}>Pending</td>}
                                                            {isSuperUser && <td className='text-center'>
                                                                {user.isMaster && <CheckCircleOutlineIcon />}
                                                            </td>}
                                                            {isSuperUser && <td className='text-center'>
                                                                {user.isRoot && <CheckCircleOutlineIcon />}
                                                            </td>}
                                                            <td className='text-center'>
                                                                {user.isAdmin && <CheckCircleOutlineIcon />}
                                                            </td>
                                                            <td className='text-center'>
                                                                {user.isMmsManager && <CheckCircleOutlineIcon />}
                                                            </td>
                                                            <td className='text-center'>{+user.activeSessions || '-'}</td>
                                                            <td onClick={() => userClicked(user)}>{moment(user.created).format('DD.MM.YYYY')}</td>
                                                            <td className={'actionTableCell'}>
                                                                {!user.isActivated &&
                                                                    <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                                        value={user.id} onClick={(e) => resendEmail(user)}>
                                                                        <ResendIcon />
                                                                    </IconButton>}
                                                                <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                                    onClick={(e) => {
                                                                        if ((allowManageAdmins || !user.isAdmin) && !user.isRoot) {
                                                                            setUserData(user)
                                                                            onRemoveUserModalIsOpen(true)
                                                                        }
                                                                    }}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                    <div className={classes.root}>
                                        <Fade
                                            in={isLoading}
                                            unmountOnExit>
                                            <CircularProgress />
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {activeTab === 'ojp' &&
                        <>
                            <div className="pageBtnSection pt0">
                                <div className="part">
                                    <Button className="addItem"
                                        variant="contained"
                                        color='primary'
                                        startIcon={<IcoPlusWhite />}
                                        onClick={() => onNewOneJobPasswordModalIsOpenChange(true)}
                                    >
                                        {translation.settingsTablesNewJobPasswordLabel}
                                    </Button>
                                </div>
                                <div className="part end">
                                    <div className='reverseSwitchHolder'>
                                        <Switch checked={companyDetails.allowOneJobPassword}
                                            onChange={(e) => {
                                                setCompanyDetails(company => {
                                                    return { ...company, allowOneJobPassword: !company.allowOneJobPassword }
                                                })
                                                onAllowOneJobPasswordValueChanged()
                                            }}
                                            color="primary"
                                        />
                                        <div>{translation.settingsTablesAllowOneJobPasswordLabel}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pageCard noPadding">
                                <div className="tableHolder">
                                    <table>
                                        <thead>
                                            <tr className='headingRow'>
                                                <th onClick={() => requestOneJobPasswordSort('jobName')}
                                                    className={getClassNamesForOneJobPassword('jobName')}>
                                                    {translation.settingsTablesJobNameHeader}
                                                </th>
                                                <th onClick={() => requestOneJobPasswordSort('password')}
                                                    className={getClassNamesForOneJobPassword('password')}>
                                                    {translation.settingsTablesPasswordHeader}
                                                </th>
                                                <th onClick={() => requestOneJobPasswordSort('status')}
                                                    className={`text-center ${getClassNamesForOneJobPassword('status')}`}>
                                                    {translation.settingsTablesStatusHeader}
                                                </th>
                                                <th onClick={() => requestOneJobPasswordSort('activeSessions')}
                                                    className={`text-center ${getClassNamesForOneJobPassword('activeSessions')}`}>
                                                    {translation.settingsTablesSessionsHeader}
                                                </th>
                                                <th onClick={() => requestOneJobPasswordSort('validTo')}
                                                    className={getClassNamesForOneJobPassword('validTo')}>
                                                    {translation.settingsTablesValidToHeader}
                                                </th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sortedOneJobPasswords.map(oneJobPassword => {
                                                    return (
                                                        <tr key={oneJobPassword.id}>
                                                            <td>
                                                                {oneJobPassword.jobName}
                                                            </td>
                                                            <td>
                                                                {oneJobPassword.password}
                                                            </td>
                                                            <td className='text-center switchCell' onClick={() => onOneJobPasswordEnabledChanged(oneJobPassword)}>
                                                                <Switch checked={oneJobPassword.isEnabled}
                                                                    color="primary" />
                                                            </td>
                                                            <td className='text-center'>{+oneJobPassword.activeSessions || '-'}</td>
                                                            <td >{oneJobPassword.validTo ? moment(oneJobPassword.validTo).format('DD.MM.YYYY HH:mm') : ''}</td>
                                                            <td className={'actionTableCell'}>
                                                                <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                                    onClick={(e) => {
                                                                        copyLinkToClipboard(oneJobPassword.id)
                                                                    }}>
                                                                    <CopyIcon />
                                                                </IconButton>
                                                                <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                                    onClick={(e) => {
                                                                        setOneJobPasswordData(oneJobPassword)
                                                                        onRemoveOneJobPasswordModalIsOpen(true)
                                                                    }}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className={classes.root}>
                                        <Fade
                                            in={isLoading}
                                            unmountOnExit>
                                            <CircularProgress />
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <NewUserModal isOpen={newUserModalIsOpen}
                    isOpenChangeHandler={onNewUserModalIsOpenChange}
                    onRequestClose={onCloseModal}
                    onDeleteUser={onDeleteUser}
                    userData={userData}
                    jobs={jobs}
                    companyId={currentCompany?.id} />
                <SelectJobModal jobs={jobs}
                    title={'Select Job'}
                    isOpen={newOneJobPasswordModalIsOpen}
                    onCancel={() => { onNewOneJobPasswordModalIsOpenChange(false) }}
                    onSave={onCreateOneJobPassword}
                    setCurrentJobUnits={null}
                    type={'oneJobPassword'} />
                <DeleteItemModal isOpen={removeUserModalIsOpen}
                    isOpenManager={onRemoveUserModalIsOpen}
                    message={translation.removeUserModalMessageLabel}
                    onDeleteModalClose={onDeleteUser} />
                <DeleteItemModal isOpen={removeOneJobPasswordModalIsOpen}
                    isOpenManager={onRemoveOneJobPasswordModalIsOpen}
                    message={translation.removeOneJobPasswordModalMessageLabel}
                    onDeleteModalClose={onDeleteOneJobPassword} />
            </div>
            <UserListHub
                onUserChangedStatus={updateUserStatus}
                onUserUpdateSessions={updateSessions}
            />
        </main>
    )
}

const mapStateToProps = ({ dashboard }) => ({
    currentCompany: dashboard.currentCompany
})

export default connect(mapStateToProps)(UsersPage)
