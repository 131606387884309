import React, { useState, useEffect, useMemo, useCallback } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import MmsService from 'Services/MMS/mms-api.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Popover from "@material-ui/core/Popover";
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import { Checkbox } from "@material-ui/core";
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import SavePartModal from './Modals/SavePartModal'
import { useAuth } from 'Contexts/AuthProvider'
import { setParts, setCurrentPage } from '../../../Store/modules/mms/actions'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchInput from '../../../Components/SearchInput'
import { setPageTitle } from '../../../Store/modules/mms/actions';

const ArrowLeft = () => <div className='arrowLeftIcon' />
const ArrowRight = () => <div className='arrowRightIcon' />

const descConst = "descending";
const ascConst = "ascending";
const arrowDownClassName = "hasArrow";
const arrowUpClassName = "hasArrow up";

const PartsComponent = ({ parts, setParts, currentDepartment, currentCompany, setCurrentPage, setPageTitle }) => {

    const [search, setSearch] = useState('');
    const translation = useTranslation();
    const [isLoading, setIsLoading] = useState(true)
    const [isOpenSavePartModal, setIsOpenSavePartModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [menuAchorEl, setMenuAchorEl] = useState(null)
    const [selectedPart, setSelectedPart] = useState(null)
    const { currentUser } = useAuth()
    const [firstIndex, setFirstIndex] = useState(0)
    const [sortConfig, setSortConfig] = useState({ key: "name", direction: ascConst });
    const [lastIndex, setLastIndex] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [rowsOnPage, setRowsOnPage] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)
    const [anchorEl, onAnchorElChange] = useState(null)
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser])
    const closeMenu = () => {
        setMenuAchorEl(null)
    }

    const filteredParts = useMemo(() => {
      if (!search) return parts;
      return parts.filter(p => {
          const values = Object.values(p).join(' ').toLowerCase();
          return values.includes(search.toLocaleLowerCase());
      })
  }, [parts, search])

  const fetchParts = async () => {
    try {
        const response = await MmsService.getPartsList()
        setIsLoading(false)
        setParts(response.data)
    }
    catch (error) {
        setIsLoading(false)
        if (error && error.response && error.response.data && error.response.data.message)
            AlertToastr.showErrorAlert(error.response.data.message)
    }
}

    useEffect(() => {
        setCurrentPage('parts')
        fetchParts()
    }, [setParts, currentDepartment, isSuperUser, currentCompany, setCurrentPage])

    useEffect(() => {
        setPageTitle(translation.equipmentPagePartsLabel);
    }, [setPageTitle, translation]);

    const handleSort = (key) => {
      if (sortConfig && sortConfig.key === key) {
        setSortConfig({
          key,
          direction: sortConfig.direction === ascConst ? descConst : ascConst,
        });
      } else {
        setSortConfig({
          key,
          direction: descConst,
        });
      }
    };

    const sortByKey = useCallback(
      (key) => {
        return (a, b) => {
          if (a[key].toLowerCase() < b[key].toLowerCase()) {
            return sortConfig.direction === ascConst ? -1 : 1;
          }
          if (a[key].toLowerCase() > b[key].toLowerCase()) {
            return sortConfig.direction === ascConst ? 1 : -1;
          }
          return 0;
        };
      },
      [sortConfig]
    );
    const sortedParts = useMemo(() => {
      if (!parts) return [];
      let sortedParts = [...parts];
      if (!sortConfig || !sortConfig.key) return sortedParts;
      if (sortConfig.key === "name") {
        sortedParts.sort((a, b) => {
          if (a[sortConfig.key] && b[sortConfig.key]) {
            return (
              (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()
                ? -1
                : 1) * (sortConfig.direction === ascConst ? 1 : -1)
            );
          }
          return 0;
        });
      } else {
        sortedParts.sort(sortByKey(sortConfig.key));
      }
      return sortedParts;
    }, [parts, sortConfig, sortByKey]);

    const getClassNamesFor = (name) => {
      if (!sortConfig || sortConfig.key !== name) {
        return;
      }
      if (sortConfig.direction)
        return sortConfig.direction === ascConst
          ? arrowDownClassName
          : arrowUpClassName;
    };

    const pageData = useMemo(() => {
      if (!filteredParts)
          return []
      let pageData = search ? [...filteredParts] : [...sortedParts]
      const totalLength = pageData.length
  
      if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
          setPageNumber(1)
      }
      setTotalRows(totalLength)
      setFirstIndex(Math.min(((pageNumber - 1) * rowsOnPage + 1), totalLength))
      setLastIndex(Math.min((pageNumber * rowsOnPage), totalLength))
      let tableData = pageData.splice((pageNumber - 1) * rowsOnPage, Math.min((pageNumber * rowsOnPage), totalLength))
      return [...tableData]
  }, [filteredParts, rowsOnPage, pageNumber, sortedParts, search])

    const closePaginationMenu = () => {
        onAnchorElChange(null)
    }

    const renderEmptyListText = () => {
      if (pageData.length) return null
      return (
        <div className='emptyInfoHolder'><p className="textGray text-center">{translation.activitiesPagePartsEmptyListPlaceholderLabel}</p></div>
      )
    }
    const handleRowCount = (count) => {
        setRowsOnPage(count)
        setPageNumber(1)
        closePaginationMenu()
    }

    const onNextPage = () => {
        setPageNumber(pageNumber + 1)
        window.scrollTo(0, 0)
    }

    const onPrevPage = () => {
        setPageNumber(pageNumber - 1)
        window.scrollTo(0, 0)
    }

    const onDeletePart = () => {
        MmsService.deletePart(selectedPart.id).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(translation.notificationsPartDeleted)
                const newParts = parts.filter(p => p.id !== selectedPart.id)
                setParts(newParts)
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    const handleSelectedIdChange = (partId, checked) => {
        parts.find(p => p.id === partId).selected = checked
        setParts([...parts])
    }

    const handleAllSelectedChange = (checked) => {
        const newParts = parts.map(p => {
            p.selected = checked
            return p
        })
        setParts(newParts)
    }

    return (
      <>
        
          
        
        <div className="pmPageHolder">
          <div className="pmPageBlock">
            <div className="pmPageBlockTitle">
              {translation.equipmentPagePartsLabel}
            </div>
            <div className='searchBar'><SearchInput search={search} onChange={setSearch} id="partsSearchField"/></div>
            <div className="pmTableHolder">
              <table className="pmTable">
                <thead>
                  <tr>
                    <th className="checkBoxCell">
                      <Checkbox
                        checked={parts ? parts.every((p) => p.selected) : false}
                        onChange={(e) =>
                          handleAllSelectedChange(e.target.checked)
                        }
                        id="partsTblCheckBoxAllRows"
                        color="default"
                        size="small"
                      />
                    </th>
                    <th
                    onClick={() => handleSort("name")}
                    className={getClassNamesFor("name")}
                    id="partsTblHeaderPart" 
                    >{translation.equipmentPagePartsPartHeader}</th>
                    <th
                    onClick={() => handleSort("number")}
                    className={getClassNamesFor("number")}
                    id="partsTblHeaderPartNumber"
                    >{translation.equipmentPagePartsPartIdHeader}</th>
                    {/* <th>{translation.equipmentPagePartsUidHeader}</th> */}
                    <th
                    onClick={() => handleSort("manufacturerName")}
                    className={getClassNamesFor("manufacturerName")}
                    id="partsTblHeaderManufacturer"
                    >{translation.equipmentPageManufacturerHeader}</th>
                    {/* <th>{translation.equipmentPageManufacturerTypeHeader}</th> */}
                    <th 
                    onClick={() => handleSort("manufacturerPartNumber")}
                    className={getClassNamesFor("manufacturerPartNumber")}
                    id="partsTblHeaderManufacturerPartNumber"
                    colSpan="2">
                      {translation.equipmentPageManufacturerPartNumberHeader}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pageData
                    ? pageData.map((part) => (
                        <React.Fragment key={part.id || part.tempKey}>
                          <tr>
                            <td className="checkBoxCell">
                              <Checkbox
                                checked={part.selected ? part.selected : false}
                                onChange={(e) =>
                                  handleSelectedIdChange(part.id, e.target.checked)
                                }
                                color="default"
                                size="small"
                                id="partsTblCheckBoxPart"
                              />
                            </td>
                            <td className="text-semi-bold">{part?.name}</td>
                            {part?.options && part?.options[0]?.number ? <td>{part?.options[0]?.number}</td> : <td>-</td>}
                            {part?.options && part?.options[0]?.manufacturerName ? <td>{part?.options[0]?.manufacturerName}</td> : <td>-</td>}
                            {part?.options && part?.options[0]?.manufacturerPartNumber ? <td>{part?.options[0]?.manufacturerPartNumber}</td> : <td>-</td>}
                            <td className="btnCell">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                className="dropDownBtn mobile"
                                onClick={(e) => {
                                  setMenuAchorEl(e.target);
                                  setSelectedPart(part);
                                }}
                                id="partsTblDropDownBtn"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </td>
                            <Popover
                              anchorEl={menuAchorEl}
                              keepMounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              disableScrollLock={true}
                              open={Boolean(menuAchorEl)}
                              onClose={closeMenu}
                              elevation={1}
                              className="pmPopover equipmentSubMenu"
                            >
                              <MenuItem
                                key={"edit"}
                                onClick={() => {
                                  setIsOpenSavePartModal(true);
                                  setMenuAchorEl(null);
                                }}
                                className="menuItem edit"
                                id="partsTblMenuEditBtn"
                              >
                                {translation.popoverDetailsLabel}
                              </MenuItem>
                              <MenuItem
                                key={"delete"}
                                onClick={() => {
                                  setIsOpenDeleteModal(true);
                                  setMenuAchorEl(null);
                                }}
                                className="menuItem delete"
                                id="partsTblMenuDeleteBtn"
                              >
                                {translation.popoverRemoveLabel}
                              </MenuItem>
                            </Popover>
                          </tr>
                          {part.options && part.options.length > 1 && (
                            part.options
                            .filter((option, index) => index !== 0)
                            .map((option, index) => (
                                <tr key={option.id || `${part.id}-option-${index}`} style={{border: 'none'}}>
                                  <td style={{border: 'none'}}></td>
                                  <td className="text-semi-bold" style={{border: 'none'}}>{option.name}</td>
                                  {option.number ? <td>{option.number}</td> : <td>-</td>}
                                  {option.manufacturerName ? <td>{option.manufacturerName}</td> : <td>-</td>}
                                  {option.manufacturerPartNumber ? <td>{option.manufacturerPartNumber}</td> : <td>-</td>}
                                  <td></td>
                                </tr>
                              ))
                          )}
                        </React.Fragment>
                      ))
                    : null}
                </tbody>
              </table>
              {renderEmptyListText()}
              {pageData && pageData.length > 0 && (
                <div className="tableControlsHolder">
                  <div className="sortHolder">
                    <div className="caption">Rows per page:</div>
                    <Button
                      onClick={(e) => onAnchorElChange(e.target)}
                      endIcon={<ArrowDropDownIcon />}
                      className="dropDownBtn"
                      id="partsTableRowsPerPageBtn"
                    >
                      {rowsOnPage}
                    </Button>

                    <Popover
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={closePaginationMenu}
                      disableScrollLock={true}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem id="partsTblRowCount20Btn" onClick={() => handleRowCount(20)}>20</MenuItem>
                      <MenuItem id="partsTblRowCount50Btn" onClick={() => handleRowCount(50)}>50</MenuItem>
                      <MenuItem id="partsTblRowCount100Btn" onClick={() => handleRowCount(100)}>
                        100
                      </MenuItem>
                    </Popover>
                  </div>
                  <div className="paginationHolder mms">
                    <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                    <div className="paginationBtn">
                      <IconButton
                        disabled={firstIndex === 1 || firstIndex === 0}
                        onClick={() => onPrevPage()}
                        id="partsTblPrevPageBtn"
                      >
                        <ArrowLeft />
                      </IconButton>
                      <IconButton
                        disabled={lastIndex === totalRows}
                        onClick={() => onNextPage()}
                        id="partsTblNextPageBtn"
                      >
                        <ArrowRight />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
              {isLoading ? (
                <div className="loaderHolder">
                  <Fade in={isLoading} unmountOnExit>
                    <CircularProgress />
                  </Fade>
                </div>
              ) : null}
              <SavePartModal
                parts={parts}
                open={isOpenSavePartModal}
                handleClose={(e) => setIsOpenSavePartModal(false)}
                part={selectedPart}
                fetchParts={fetchParts}
              />
              <DeleteItemModal
                isOpen={isOpenDeleteModal}
                isOpenManager={setIsOpenDeleteModal}
                message={translation.removePartModalMessageLabel}
                onDeleteModalClose={(e) => {
                  onDeletePart();
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
}

const mapStateToProps = ({ mms, dashboard }) => ({
    parts: mms.parts,
    currentDepartment: mms.currentDepartment,
    currentCompany: dashboard.currentCompany
})

const mapDispatchToProps = {
    setParts,
    setCurrentPage,
    setPageTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(PartsComponent)
