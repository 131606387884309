import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import Types from './types'

const INITIAL_STATE = {
    departments: null,
    employees: null,
    currentDepartment: null,
    components: null,
    parts: null,
    recommendedSpareParts: null,
    partsCatalog: null,
    componentsCatalog: null,
    currentPage: 'equipment',
    runComponentsUpdate: 0,
    typeOptions: null,
    statusOptions: null,
    currentCompanyPage: 'departments',
    editingCompany: null,
    reports: null,
    filterInfo: null,
    activities: [],
    pageTitle: 'Page Title',
    rspSelectedComponentsIds: []
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case Types.SET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload
            }

        case Types.SET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            }

        case Types.SET_CURRENT_DEPARTMENT:
            return {
                ...state,
                currentDepartment: action.payload
            }

        case Types.SET_COMPONENTS:
            return {
                ...state,
                components: action.payload
            }

        case Types.UPDATE_COMPONENT_WORKHOURS:
            const {componentId, workhours} = action.payload;
            if (componentId) {
                const updatedComponents = state.components.map(component => component.id === componentId ? {...component, workhoursTotal: workhours} : {...component})
                return {
                    ...state,
                    components: updatedComponents
                }
            } else {
                return state;
            }
        
            case Types.UPDATE_COMPONENT_NOTE:
                return {
                    ...state,
                    note: action.payload
                }

        case Types.SET_PARTS:
            return {
                ...state,
                parts: action.payload
            }

        case Types.SET_RECOMMENDED_SPARE_PARTS:
            return {
                ...state,
                recommendedSpareParts: action.payload
            }

        case Types.SET_PARTS_CATALOG:
            return {
                ...state,
                partsCatalog: action.payload
            }

        case Types.SET_COMPONENTS_CATALOG:
            return {
                ...state,
                componentsCatalog: action.payload
            }

        case Types.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }

        case Types.SET_CURRENT_COMPANY_PAGE:
            return {
                ...state,
                currentCompanyPage: action.payload
            }

        case Types.SET_RUN_COMPONENTS_UPDATE:
            return {
                ...state,
                runComponentsUpdate: action.payload
            }

        case Types.SET_TYPE_OPTIONS:
            return {
                ...state,
                typeOptions: action.payload
            }

        case Types.SET_STATUS_OPTIONS:
            return {
                ...state,
                statusOptions: action.payload
            }              

        case Types.SET_EDITING_COMPANY:
            return {
                ...state,
                editingCompany: action.payload
            }

        case Types.SET_REPORTS:
            return {
                ...state,
                reports: action.payload
            }
        case Types.SET_UNASSIGNED_COMPONENTS:
            return {
                ...state,
                unassignedComponents: action.payload
            }

        case Types.SET_FILTER_INFO:
            return {
                ...state,
                filterInfo: action.payload
            }

        case Types.SET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload
            }

        case Types.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.payload
            }

        case Types.SET_RSP_SELECTED_COMPONENTS_IDS:
            return {
                ...state,
                rspSelectedComponentsIds: action.payload
            }

        case Types.SET_SELECTED_UNASSIGNED_COMPONENTS_IDS:
            return {
                ...state,
                selectedUnassignedComponentsIds: action.payload
            }    

        default:
            return state
    }
}

const persistConfig = {
    key: 'mms',
    storage
}

export default persistReducer(persistConfig, reducer)
