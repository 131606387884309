import React, { useState, useEffect } from 'react'
import { Menu, MenuItem, Button, Checkbox, FormControlLabel } from '@material-ui/core'

const ChannelListItem = ({ channel, isChecked, isDisabled, setValues, unit, selectAll, setInitialValues }) => {
    const [setUnitMenuAnchorEl, onSetUnitMenuAnchorElChange] = useState(null)
    const [selectedUnit, setSelectedUnit] = useState(unit)
    const [isSelected, setIsSelected] = useState(isChecked)

    useEffect(() => {
        setIsSelected(isChecked);
    }, [isChecked, selectAll]);

    const closeSetUnitMenu = () => {
        onSetUnitMenuAnchorElChange(null)
    }

    const handleUnitSelect = (unit) => {
        setSelectedUnit(unit)
        if (isSelected)
            setValues(values => {
                values.find(value => value.id === channel.id).unit = unit
                return values
            })
        closeSetUnitMenu()
    }

    const checkboxClicked = (e) => {
        e.persist();
        setIsSelected(e.target.checked);
        setValues(values => {
            if (e.target.checked)
                return [...values, { ...channel, unit: selectedUnit }]
            else
                return values.filter(value => value.id !== channel.id)
        })
        setInitialValues(values => {
            if (e.target.checked)
                return [...values, { ...channel, unit: selectedUnit }]
            else
                return values.filter(value => value.id !== channel.id)
        })
    }

    return (
        <div className='channel-list-item ' key={channel.id}>
            <FormControlLabel control={
                <Checkbox
                    disabled={isDisabled}
                    checked={isSelected}
                    onChange={checkboxClicked}
                    color='default'
                />}
                label={channel.nameLocalized}
            />

            <Button className="chooseUnit"
                onClick={e => onSetUnitMenuAnchorElChange(e.target)}>
                {selectedUnit}
            </Button>
            <Menu
                anchorEl={setUnitMenuAnchorEl}
                open={Boolean(setUnitMenuAnchorEl)}
                onClose={closeSetUnitMenu}
            >
                {
                    channel.units.map(unit => (
                        <MenuItem
                            key={unit}
                            onClick={() => handleUnitSelect(unit)}
                        >
                            {unit}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
}

export default ChannelListItem
