import React from 'react'
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import useTranslation from 'Hooks/useTranslation'

const RadioLabel = ({ label }) => {
    return (
        <div className="jobLabel">
            <div className="labelName">{label}</div>
        </div>
    )
}

const ManageDateFormat = ({
    selectedTimeFormat,
    setSelectedTimeFormat,
    selectedTimeType,
    setSelectedTimeType,
    type,
    chartType
}) => {
    const translation = useTranslation();

    const onTimeModeChanged = (e) => {
        setSelectedTimeType(e.target.value)
        const dependencies = {
            'fromDatabase': ['time', 'dateTime'],
            'local': ['time', 'dateTime'],
            'elapsed': ['time', 'seconds']
        }

        if (dependencies[e.target.value] && !dependencies[e.target.value].includes(selectedTimeFormat)) {
            setSelectedTimeFormat(dependencies[e.target.value][0])
        }
    }

    const onTimeFormatChanged = (e) => {
        setSelectedTimeFormat(e.target.value)
        const dependencies = {
            'time': ['local', 'fromDatabase', 'elapsed'],
            'dateTime': ['local', 'fromDatabase'],
            'seconds': ['elapsed']
        }
        if (dependencies[e.target.value] && !dependencies[e.target.value].includes(selectedTimeType)) {
            setSelectedTimeType(dependencies[e.target.value][0])
        }
    }
    if (type === 'chartView' && chartType === 'timeBased')
        return <div>
            <div className="tr innerLikeRow">
                <div className="td text-bold format">
                    <RadioLabel label={translation.manageAxesModalFormatLabel} />
                </div>
                <div className="td main">
                    <RadioGroup row aria-label="timeFormat" name="timeFormat" defaultValue="time" value={selectedTimeFormat} onChange={onTimeFormatChanged}>
                        <FormControlLabel
                            value="time"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={translation.manageAxesModalTimeLabel} />}
                        />
                        <FormControlLabel
                            value="dateTime"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={translation.manageAxesModalDateAndTimeLabel} />}
                        />
                        <FormControlLabel
                            value="seconds"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={translation.manageAxesModalSecondsLabel} />}
                        />
                    </RadioGroup>
                </div>
            </div>
            <div className="tr hasBorderBottom innerLikeRow">
                <div className="td text-bold mode">
                    <RadioLabel label={translation.manageAxesModalModeLabel} />
                </div>
                <div className="td main">
                    <RadioGroup row aria-label="timeType" name="timeType" defaultValue="local" value={selectedTimeType} onChange={onTimeModeChanged}>
                        <FormControlLabel
                            value="local"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={translation.manageAxesModalLocalLabel} />}
                        />
                        <FormControlLabel
                            value="fromDatabase"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={translation.manageAxesModalDatabaseLabel} />}
                        />
                        <FormControlLabel
                            value="elapsed"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={translation.manageAxesModalElapsedLabel} />}
                        />
                    </RadioGroup>
                </div>
            </div>
        </div>
    else
        return <div>
            <div className="formContentBlock">
                <FormControl component="fieldset">
                    <FormLabel component="label" className={'radioLabel text-bold'}>Time Mode</FormLabel>
                    <RadioGroup row aria-label="timeType" name="timeType" defaultValue="local" value={selectedTimeType} onChange={onTimeModeChanged}>
                        <FormControlLabel
                            value="local"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={"Local"} />}
                        />
                        <FormControlLabel
                            value="fromDatabase"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={"Database"} />}
                        />
                        {type !== 'eventLogger' && <FormControlLabel
                            value="elapsed"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={"Elapsed"} />}
                        />}
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="formContentBlock">
                <FormControl component="fieldset">
                    <FormLabel component="label" className={'radioLabel text-bold'}>{'Time Format'}</FormLabel>
                    <RadioGroup row aria-label="timeFormat" name="timeFormat" defaultValue="time" value={selectedTimeFormat} onChange={onTimeFormatChanged}>
                        <FormControlLabel
                            value="time"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={"Time"} />}
                        />
                        <FormControlLabel
                            value="dateTime"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={"Date and Time"} />}
                        />
                        {type !== 'eventLogger' && <FormControlLabel
                            value="seconds"
                            control={<Radio color="default" />}
                            label={<RadioLabel label={"Seconds"} />}
                        />}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
}

export default ManageDateFormat
