import React, { useEffect, useMemo, useState, useCallback } from 'react';
import AddBoxMenu from 'Components/AddBoxMenu/AddBoxMenu.component';
import SaveTemplateModal from 'Components/modals/template-modals/save-template-modal/save-template-modal.component';
import SelectJobModal from 'Components/modals/SelectJobModal/SelectJobModal';
import Box from 'Components/Box/Box.component';
import ManageChannelsModal from 'Components/modals/ManageChannelsModal/ManageChannelsModal';
import DmsService from 'Services/DMS/dms-api.service';
import dmsApiService from 'Services/DMS/dms-api.service';
import { uniqueValues, userHasPermission } from 'helpers';
import ExportToCsvModal from '../modals/export-to-csv-modal/export-to-csv-modal.component';
import { fetchJobsSuccess, setCurrentCompany } from 'Store/modules/dashboard/actions';
import { useAuth } from 'Contexts/AuthProvider';
import AlertToastr from 'utils/alert';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import UnitsManagerSignalr from 'Components/SignalrSubscribes/UnitsManagerSignalr.component';
import { connect } from 'react-redux';
import useTranslation from 'Hooks/useTranslation';
import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from '../../config';
import LoadTemplateModal2 from '../modals/template-modals/load-template-modal/load-template-modal-2.component';
import OptionsMenu from '../OptionsMenu';
import useItemTypes from '../../Hooks/useItemTypes';
import useJobTemplates from '../../Hooks/useJobTemplates';
import ChangeJobModal from '../modals/SelectJobModal/ChangeJobModal';
import useTemplateChange from '../../Hooks/useTemplateChange';
import ErrorTemplateModal from '../modals/ErrorTemplateModal';
import { useConfirm } from '../../Contexts/ConfirmContext';
import useTemplateChangeConfirm from '../../Hooks/useTemplateChangeConfirm';
import DownloadFileButton from '../DownloadFileButton';
import { useBoxes } from '../../Contexts/BoxContext';
import { Button, TextField, Popover, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useUnitDateTypes from '../../Hooks/useUnitDateTypes';
import DateRangePicker from 'Components/Box/DateRangePickerModal';
import { deleteEmptyStringProperties } from '../../helpers';
import useSyncTabs from '../../Hooks/useSyncTabs';

const uuid = require('uuid/v1');

const defaultDateRangeValue = "last1hour";

const DashboardGroup = props => {
    const {
        jobs,
        currentJob,
        setCurrentJob,
        syncTabs,
        boxes,
        setBoxes,
        addBox,
        editBox,
        removeBox,
        onNewWindowOpen,
        onTemplateLoaded,
        loadedTemplate,
        onSuccessTemplateSave,
        onManageChannels,
        addChartSettings,
        boundChannelList,
        setBoundChannelColors,
        fetchJobsSuccess,
        currentCompany,
        setCurrentCompany,
        onChange
    } = props;

    const { data: itemTypes } = useItemTypes('jobs');
    const [currentManageChannelsModalType, setCurrentManageChannelsModalType] = useState(null);
    const [currentEditedBox, setCurrentEditedBox] = useState(null);
    const [afterSelectJobAction, setAfterSelectJobAction] = useState(null);
    const [selectJobModalIsOpen, setSelectJobModalIsOpen] = useState(false);
    const [changeJobModalIsOpen, setChangeJobModalIsOpen] = useState(false);
    const [templateErrors, setTemplateErrors] = useState();
    const [showTemplateErrorsModal, setShowTemplateErrorsModal] = useState(false);
    const [openInNewDashboard, setOpenInNewDashboard] = useState(false);
    const [manageChannelsIsOpen, setManageChannelsIsOpen] = useState(false);
    const [exportToCsvModalIsOpen, setExportToCsvModalIsOpen] = useState(false);
    const [saveTemplateModalIsOpen, onSaveTemplateModalIsOpenChange] = useState(false);
    const [loadTemplateModalIsOpen, onLoadTemplateModalIsOpenChange] = useState(false);
    const translation = useTranslation();
    const { win, currentUser, isSignedFlow } = useAuth();
    const allowManageTemplate = useMemo(() => userHasPermission(currentUser, 'manage_templates'), [currentUser]);
    const [currentJobUnits, setCurrentJobUnits] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [groupBoundChannelColors, setGroupBoundChannelColors] = useState([]);
    const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);
    const { confirm } = useConfirm();
    const { dataExportRequest, startChartDate, endChartDate, modalStep, boxType, jobsBoxesFilters } = useBoxes();

    const [eventTypes, setEventTypes] = useState([]);
    const isMainGroup = () => win.parentId === null;

    const { mutateAsync: syncJobsTabs } = useSyncTabs('jobs');

    const {
        data: templateItems,
        isSuccess: templateIsLoaded,
    } = useJobTemplates(currentJob?.id);

    const changeTemplateMutation = useTemplateChange();
    const changeTemplateConfirmMutation = useTemplateChangeConfirm();

    useQuery(
        'eventTypes',
        () => axios.get(`${apiBaseUrl}/Events/types`),
        {
            onSuccess: (response) => {
                setEventTypes(response.data);
            },
        },
    );

    useEffect(() => {
        if (currentUser && currentUser.isOneJobPassword && currentJob) {
            if (isSignedFlow) {
                if (templateIsLoaded) {
                    if (templateItems.length > 0) {
                        onLoadTemplateModalIsOpenChange(true)
                    }
                }
            }

        }
    }, [
        currentUser,
        currentJob,
        isSignedFlow,
        templateItems,
        templateIsLoaded,
    ]);

    useEffect(() => {
        if (boxes && !boxes.length) {
            document.body.classList.add('dashboard');
        } else {
            document.body.classList.remove('dashboard');
        }
        return () => {
            document.body.classList.remove('dashboard');
        };
    }, [boxes]);

    useEffect(() => {
        async function fetchCompanies() {
            let res = await dmsApiService.getCompanies();
            if (res.length === 0)
                return;
            // let jobsRes = await dmsApiService.getCompanyJobsShort(res[0].id)
            // fetchJobsSuccess(jobsRes)
        }

        async function fetchJobs() {
            let response;
            response = await dmsApiService.getJobsShort();
            fetchJobsSuccess(response);
        }

        if (currentCompany || currentJob || jobs.length !== 0)
            return;
        if (isSuperUser) {
            fetchCompanies().then();
        } else {
            fetchJobs().then();
        }
    }, [jobs, currentJob, fetchJobsSuccess, currentCompany, isSuperUser, setCurrentCompany]);

    useEffect(() => {
        if (currentJob && openInNewDashboard) {
            if (afterSelectJobAction === 'eventLogger') {
                let eventData = {
                    name: translation.dashboardEventLoggerLabel,
                    type: 'eventLogger',
                    id: uuid(),
                    timeType: 'local',
                    timeFormat: 'time',
                    itemPosition: {
                        rowIndex: boxes.length,
                        position: 'Stretch',
                    },
                    filters: jobsBoxesFilters
                };
                addBox(eventData, openInNewDashboard);
            }
            if (afterSelectJobAction === 'jobProperties') {
                let eventData = {
                    name: translation.dashboardJobPropertiesLabel,
                    type: 'jobProperties',
                    id: uuid(),
                    timeType: 'local',
                    timeFormat: 'time',
                    itemPosition: {
                        rowIndex: boxes.length,
                        position: 'Stretch',
                    },
                    filters: jobsBoxesFilters
                };
                addBox(eventData, openInNewDashboard);
            }
        }
    }, [currentJob, openInNewDashboard, addBox, win.id, afterSelectJobAction, boxes.length, translation, jobsBoxesFilters]);

    // channels color binding
    useEffect(() => {
        const setupColors = async () => {
            const parentId = win.parentId ? win.parentId : win.id;
            let response;
            if (isSuperUser) {
                if (!currentCompany)
                    return;
                response = await DmsService.getTabColorsByCompanyId(parentId, currentCompany?.id);
            } else
                response = await DmsService.getTabColors(parentId);
            const newBoundChannelList = response.data ? response.data : [];
            const uniqueChannelList = [];
            boxes.forEach((box) => {
                if (box.chartType === 'timeBased' || box.type === 'digitalView') {
                    box.channels.forEach((channel) => {
                        if (!uniqueChannelList.some(elem => elem === channel.id)) {
                            uniqueChannelList.push(channel.id);
                            if (!newBoundChannelList.some(ch => ch.id === channel.id)) {
                                for (let i = 0; i <= newBoundChannelList.length; i++) {
                                    if (!newBoundChannelList.some(elem => elem.colorIndex === i)) {
                                        newBoundChannelList.push({ id: channel.id, colorIndex: i });
                                        return;
                                    }
                                }
                            }
                        }
                    });
                }
            });
            const oldBoundChannelsList = [...newBoundChannelList];
            oldBoundChannelsList.forEach((channel) => {
                const boundChannelId = uniqueChannelList.find(ch => ch === channel.id);
                if (!boundChannelId) {
                    newBoundChannelList.splice(newBoundChannelList.indexOf(channel), 1);
                }
            });

            boundChannelList.splice(0, boundChannelList.length);
            newBoundChannelList.map(c => {
                boundChannelList.push(c);
                return c;
            });
            setGroupBoundChannelColors(newBoundChannelList);

            syncTabs(win.id, boxes, boundChannelList);
        };
        setupColors().then();
    }, [boxes, boundChannelList, setBoundChannelColors, win, syncTabs, currentCompany, isSuperUser]);

    const setChannelsSelectionDialogVisibility = (boxType, isOpen) => {
        switch (boxType) {
            case 'chartView':
                setCurrentManageChannelsModalType('chartView');
                setManageChannelsIsOpen(isOpen);
                break;
            case 'digitalView':
                setCurrentManageChannelsModalType('digitalView');
                setManageChannelsIsOpen(isOpen);
                break;
            case 'dataTable':
                setCurrentManageChannelsModalType('dataTable');
                setManageChannelsIsOpen(isOpen);
                break;
            case 'eventLogger':
                if (!openInNewDashboard) {
                    addBox({
                        name: translation.dashboardEventLoggerLabel,
                        type: boxType,
                        id: uuid(),
                        group: win.id,
                        timeType: 'local',
                        timeFormat: 'time',
                        itemPosition: {
                            rowIndex: boxes.length,
                            position: 'Stretch',
                        },
                    }, false);
                }
                break;
            case 'jobProperties':
                if (!openInNewDashboard) {
                    addBox({
                        name: translation.dashboardJobPropertiesLabel,
                        type: boxType,
                        id: uuid(),
                        group: win.id,
                        timeType: 'local',
                        timeFormat: 'time',
                        itemPosition: {
                            rowIndex: boxes.length,
                            position: 'Stretch',
                        },
                    }, false);
                }
                break;
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchJob = async () => {
            if (jobs && jobs[0]) {
                let response = await DmsService.getJobsById(jobs[0].id);
                if (response) {
                    jobs[0].units = response.units;
                }
                setCurrentJobUnits(response.units);
                setCurrentJob(jobs[0]);
            }
        };

        if (currentUser && !currentJob && currentUser.isOneJobPassword && win.parentId === null) {
            fetchJob().then();
        }

    }, [currentJob, currentUser, jobs, setCurrentJob, win]);

    const addBoxShowModal = boxType => {
        if (!currentJob && !currentUser.isOneJobPassword) {
            setAfterSelectJobAction(boxType); // needs to open next dialog after close select job modal
            setSelectJobModalIsOpen(true);
        } else {
            setChannelsSelectionDialogVisibility(boxType, true);
        }
    };

    const handleSaveBox = (boxData, openInNewDashboard) => {
        onManageChannels(); // only for modify template
        setManageChannelsIsOpen(false);
        if (showManageChannelsFirst) {
            setCurrentManageChannelsModalType('');
            setCurrentEditedBox(null);
            return;
        }
        if (currentEditedBox) {
            //box edit mode
            let savedData = {
                ...boxData,
                group: currentEditedBox.group,
                itemPosition: currentEditedBox.itemPosition,
                id: currentEditedBox.id,
            };

            if (savedData.type !== 'digitalView') {
                savedData.timeType = currentEditedBox.timeType;
                savedData.timeFormat = currentEditedBox.timeFormat;
            }
            if (!openInNewDashboard)
                savedData.group = win.id;
            if (savedData.yAxisSettings) {
                editChartSettings(savedData);
            } else {
                addChartSettings(savedData);
            }

            editBox(savedData, openInNewDashboard);
            if (openInNewDashboard) {
                const box = boxes.find(x => x.id === savedData.id);
                const boxIndex = boxes.indexOf(box);
                setNearbyBoxPosition(box.itemPosition.position, boxIndex);
            }
        } else {
            //box create mode
            let savedData = {
                ...boxData,
                id: uuid(),
                timeType: 'local',
                timeFormat: 'time',
                itemPosition: {
                    rowIndex: boxes.length,
                    position: 'Stretch',
                },
                filters: jobsBoxesFilters
            };
            if (!openInNewDashboard)
                savedData.group = win.id;
            addChartSettings(savedData);
            addBox(savedData, openInNewDashboard);
            setAfterSelectJobAction(null);
        }
        setCurrentManageChannelsModalType('');
        setCurrentEditedBox(null);
    };

    useEffect(() => {
        if (currentJob && currentJob.units) {
            setCurrentJobUnits(currentJob.units);
        }
    }, [currentJob, currentJobUnits]);

    const editChartSettings = boxData => {
        const units = uniqueValues(boxData.channels.map(channel => channel.unit));
        boxData.yAxisSettings = boxData.yAxisSettings.filter(s => units.includes(s.unit));
        units.map(unit => {
            if (!boxData.yAxisSettings.find(s => s.unit === unit)) {
                boxData.yAxisSettings.push(
                    {
                        unit: unit,
                        autoScaling: true,
                        min: undefined,
                        max: undefined,
                    });
            }
            return unit;
        });
    };

    const handleEditBox = boxId => {
        onManageChannels(); // only for modify template

        const editedBox = boxes.find(box => box.id === boxId);
        if (editedBox) {
            setCurrentEditedBox(editedBox);

            if (editedBox.type === 'chartView')
                setCurrentManageChannelsModalType('chartView');

            if (editedBox.type === 'digitalView')
                setCurrentManageChannelsModalType('digitalView');

            if (editedBox.type === 'dataTable')
                setCurrentManageChannelsModalType('dataTable');

            if (editedBox.type === null) setCurrentManageChannelsModalType(null);

            setManageChannelsIsOpen(true);
        }
    };

    const handleExportToCsv = (boxId) => {
        const editedBox = boxes.find(box => box.id === boxId);
        if (editedBox) {
            setCurrentEditedBox(editedBox);
        }
        setExportToCsvModalIsOpen(true);
    };

    const [showManageChannelsFirst, setShowManageChannelsFirst] = useState(false);
    const [selectedCsvChannels, setSelectedCsvChannels] = useState([]);

    const handleExportJobToCsv = () => {
        if (!currentJob && !currentUser.isOneJobPassword) {
            setAfterSelectJobAction('exportCsv'); // needs to open next dialog after close select job modal
            setShowManageChannelsFirst(true);
            setSelectJobModalIsOpen(true);
        } else {
            setShowManageChannelsFirst(true);
            setManageChannelsIsOpen(true);
        }
    };
    
    useEffect(() => {
        if (showManageChannelsFirst && !selectJobModalIsOpen && !manageChannelsIsOpen) {
            setShowManageChannelsFirst(false);
            setExportToCsvModalIsOpen(true);
        }
    }, [showManageChannelsFirst, selectJobModalIsOpen, manageChannelsIsOpen]);
    
    const handleSaveCurrentJob = (jobId, openInNewDashboard = false) => {
        setSelectJobModalIsOpen(false);
        if (jobId && jobs && jobs.length) {
            const job = jobs.find(x => x.id === jobId);
            setCurrentJob(job);
            setOpenInNewDashboard(openInNewDashboard);
            if (afterSelectJobAction) {
                if (afterSelectJobAction === 'exportCsv') {
                    if (showManageChannelsFirst) {
                        setManageChannelsIsOpen(true);
                    } else {
                        setExportToCsvModalIsOpen(true);
                    }
                } else {
                    setChannelsSelectionDialogVisibility(afterSelectJobAction, true);
                }
            }
        }
    };

    const onExportData = (data) => {
        if (currentEditedBox) {
            data.channels = currentEditedBox.channels.map(ch => ({ channelId: ch.id, unit: ch.unit }));
        } else {
            data.jobId = currentJob.id;
            data.channels = selectedCsvChannels;
        }

        if(boxType === 'eventLogger') {
            DmsService.exportEventLoggerData(data).then(res => {
                setTimeout(() => {
                    dataExportRequest.refetch().then(() => {
                        AlertToastr.showAlert(translation.exportBuildingStartedMessage);
                    }).finally(() => {
                        if (!boxes.length && isMainGroup) {
                            setCurrentJob(null);
                        }
                        setExportToCsvModalIsOpen(false);
                    });
                }, 500);
            }).catch(error => {
                if (error?.response?.data?.Message) {
                    AlertToastr.showErrorAlert(error?.response?.data?.Message);
                }
            });
        } else {
            DmsService.exportData(data).then(res => {
                // const element = document.createElement('a');
                // const file = new Blob(['\ufeff' + res.data]);
                // element.href = URL.createObjectURL(file);
                // element.download = `${data.name}.csv`;
                // element.click();
                // setExportToCsvModalIsOpen(false);
                dataExportRequest.refetch().then(() => {
                    AlertToastr.showAlert(translation.exportBuildingStartedMessage);

                }).finally(() => {
                    if (!boxes.length && isMainGroup) {
                        setCurrentJob(null);
                    }
                    setExportToCsvModalIsOpen(false);
                });
            }).catch(error => {
                if (error?.response?.data?.Message) {
                    AlertToastr.showErrorAlert(error?.response?.data?.Message);
                }
            });
        }

        setCurrentEditedBox(null);
        setSelectedCsvChannels([])
    };

    const handleRemoveBox = boxId => {
        if (boxes.length === 1 && isMainGroup()) {
            setCurrentJob(null);
        }
        removeBox(boxId);
        const box = boxes.find(x => x.id === boxId);
        const boxIndex = boxes.indexOf(box);
        setNearbyBoxPosition(box.itemPosition.position, boxIndex);
    };

    const selectJobItemTitle = useMemo(() => {
        if (currentJob && currentJob.name) {
            return currentJob.name;
        }
    }, [currentJob]);

    const selectJobItem = isMainGroup() ? (
        <p className="jobItemName">
            {selectJobItemTitle}
        </p>
    ) : null;

    const renderOptionsButton = () => {
        if (!isMainGroup()) return null;

        return (
            <OptionsMenu
                refreshDisabled={!boxes.length}
                onRefresh={handleRefresh}
                onChangeJob={() => setChangeJobModalIsOpen(true)}
                isCurrentJobSet={!!currentJob}
                allowManageTemplate={allowManageTemplate}
                disableSaveTemplate={!currentJob && boxes.length === 0}
                onLoadTemplate={() => onLoadTemplateModalIsOpenChange(true)}
                onSaveTemplate={() => onSaveTemplateModalIsOpenChange(true)}
                onExportCsv={handleExportJobToCsv}
                exportCsvDisabled={dataExportRequest.data?.isCompleted === false}
                exportCsvLabel={translation.exportJobToCsvLabel}
            />
        );
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragStart = () => {
        setIsDragging(true);
    };

    const onDragEnd = ({ combine, source, destination }) => {
        setIsDragging(false);
        if (combine) {
            combineBoxes(source.index, combine.draggableId);
            return;
        }
        if (!destination) {
            return;
        }
        setNearbyBoxPosition(boxes[source.index].itemPosition.position, source.index);
        boxes[source.index].itemPosition.position = 'Stretch';

        const reorderedBoxes = reorder(boxes, source.index, destination.index);
        setBoxes(reorderedBoxes);
        syncTabs(win.id, reorderedBoxes, boundChannelList);
        setCurrentEditedBox(null);
    };

    const combineBoxes = (sourceIndex, destinationId) => {
        const sourceBox = boxes[sourceIndex];
        const destinationBox = boxes.find(x => x.id === destinationId);
        if (!sourceBox || !destinationBox) {
            return;
        }
        if (destinationBox.itemPosition.position !== 'Stretch') {
            return;
        }
        const destinationIndex = boxes.indexOf(destinationBox);
        setNearbyBoxPosition(sourceBox.itemPosition.position, sourceIndex);
        destinationBox.itemPosition.position = 'Left';
        sourceBox.itemPosition.position = 'Right';
        let endIndex = destinationIndex;
        if (sourceIndex > destinationIndex)
            endIndex = destinationIndex + 1;
        const reorderedBoxes = reorder(boxes, sourceIndex, endIndex);

        setBoxes(reorderedBoxes);
        syncTabs(win.id, reorderedBoxes, boundChannelList);
        setCurrentEditedBox(null);
    };


    const setNearbyBoxPosition = (sourcePosition, sourceIndex) => {
        switch (sourcePosition) {
            case 'Left':
                boxes[sourceIndex + 1].itemPosition.position = 'Stretch';
                break;
            case 'Right':
                boxes[sourceIndex - 1].itemPosition.position = 'Stretch';
                break;
            default:
                break;
        }
    };

    const syncCurrentTabs = () => {
        syncTabs(win.id, boxes, boundChannelList);
    };

    const updateFilters = (updatedBox) => {
        editBox(updatedBox);
    };

    const showConfirm = async ({ jobId, tabs, errors, code }) => {
        const isConfirmed = await confirm(translation.confirmChangeJobQuestion, errors);

        if (isConfirmed) {

            await changeTemplateConfirmMutation
                .mutateAsync({
                    tabId: win?.id,
                    code,
                });
            onTemplateLoaded(null, jobId, tabs, null);

        }
    };

    const handleChangeJob = (jobId, isRefresh) => {
        changeTemplateMutation
            .mutateAsync({
                type: 'jobs',
                tabId: win?.id,
                jobId,
                isRefresh,
            })
            .then(response => {
                const newTabs = response?.data?.tabs;
                const messages = response?.data?.messages;
                const code = response?.data?.code;
                if (messages && messages.length) {
                    const errors = {
                        type: 'warning',
                        messages,
                    };
                    showConfirm({ jobId, tabs: newTabs, errors, code }).then();
                } else {
                    onTemplateLoaded(null, jobId, newTabs, null);
                }


            })
            .catch(error => {
                const errMsg = error?.response?.data?.message;
                if (errMsg) {
                    setTemplateErrors({
                        type: 'error',
                        messages: [errMsg],
                    });
                    setShowTemplateErrorsModal(true);
                }

            })
            .finally(() => {
                setChangeJobModalIsOpen(false);
            })
        ;
    };

    const handleRefresh = () => {
        if (currentJob?.id) {
            handleChangeJob(currentJob?.id, true);
        }
    };

    const [updatedDateTypesOptions, setUpdatedDateTypesOptions] = useState(null);
    const { data: dateTypes, isLoading: isLoadingUnitDateTypes } = useUnitDateTypes();
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [dateRangeValue, setDateRangeValue] = useState('');
    const [initialDateTypesOptions, setInitialDateTypesOptions] = useState(null);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [customDateRangeType, setCustomDateRangeType] = useState(null);
    const [resetClicked, setResetClicked] = useState(false);

    const dateTypesOptions = useMemo(() => {
      return dateTypes.map(item => ({ ...item, name: item.name || translation.selectDateRangeLabel }));
  }, [dateTypes, translation]);

  const [currentPage] = useState(window.location.pathname);

  const saveToLocalStorage = (key, data) => {
    localStorage.setItem(`${currentPage}_${key}`, JSON.stringify(data));
  };

  const loadFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(`${currentPage}_${key}`);
    return storedData ? JSON.parse(storedData) : null;
  };

    useEffect(() => {
        const storedUpdatedDateTypesOptions = loadFromLocalStorage(
            "updatedDateTypesOptions"
        )
        if (storedUpdatedDateTypesOptions) {
            setUpdatedDateTypesOptions(storedUpdatedDateTypesOptions);
        } else {
            setUpdatedDateTypesOptions(dateTypesOptions);
        }
     
    }, [currentPage, dateTypesOptions]);

    useEffect(() => {
        if (customDateRangeType !== null) {
            const updatedOptions = initialDateTypesOptions.map((item) => {
                if (item.dateType === "customRange") {
                    return { ...item, name: customDateRangeType };
                }
                return item;
            });
            setUpdatedDateTypesOptions(updatedOptions);
            saveToLocalStorage("customDateRangeType", customDateRangeType);
        } else {
            const storedCustomDateRangeType = loadFromLocalStorage(
                "customDateRangeType"
            )
            if (storedCustomDateRangeType) {
                setCustomDateRangeType(storedCustomDateRangeType);
            }
            const updatedOptions = initialDateTypesOptions?.filter(
                (item) => item.dateType !== "customRange"
            )
            setUpdatedDateTypesOptions(updatedOptions);
        }
    }, [initialDateTypesOptions, customDateRangeType]);

    useEffect(() => {
        setInitialDateTypesOptions(dateTypesOptions);
    }, [dateTypesOptions]);

    const handleDateChange = useCallback((newDateRange) => {
        setDateRangeValue(newDateRange.dateType);
        onChange(deleteEmptyStringProperties({
            dateType: newDateRange.dateType,
            dateFrom: newDateRange.dateFrom,
            dateTo: newDateRange.dateTo,
        }));
    }, [onChange]);

    const handleReset = useCallback(() => {
        const updatedOptions = initialDateTypesOptions?.filter(item => item.dateType !== "customRange");
        setUpdatedDateTypesOptions(updatedOptions);
        setDateRangeValue("last1hour");
    }, [initialDateTypesOptions, setUpdatedDateTypesOptions, setDateRangeValue]);

    useEffect(() => {
        if (resetClicked) {
            handleReset();
            setResetClicked(false);
        }
    }, [handleReset, resetClicked, setResetClicked, setDateRangeValue]);

    useEffect(() => {
        if (updatedDateTypesOptions?.length && dateRangeValue === '') {
            setDateRangeValue(defaultDateRangeValue);
        }
    }, [updatedDateTypesOptions, dateRangeValue]);

    useEffect(() => {
        if (jobsBoxesFilters.dateType && jobsBoxesFilters.dateType !== 'none') {
            // setFilterType('date');
            if (dateTypes.length) {
                setDateRangeValue(jobsBoxesFilters.dateType);
            }
        }
    }, [jobsBoxesFilters, dateTypes]);

    const handleOpenPopover = (event) => {
      setPopoverAnchor(event.currentTarget);
    };
  
    const handleClosePopover = () => {
      setPopoverAnchor(null);
    };

    const handleChangeDateRange = (event) => {
      const value = event.target.value;
      setDateRangeValue(value);
      const updatedOptions = initialDateTypesOptions?.filter(item => item.dateType !== "customRange");
      setUpdatedDateTypesOptions(updatedOptions);

      if (value !== 'customRange') {
          onChange({
              dateType: value,
              dateFrom: null,
              dateTo: null,
          });
          const updatedBoxes = boxes.map(box => ({
            ...box,
            filters: {
                ...box.filters,
                dateType: value,
                dateFrom: null,
                dateTo: null,
            }
        }));
          syncJobsTabs({
            // dateType: value,
            // dateFrom: null,
            // dateTo: null,
            tabId: win.id,
            items: updatedBoxes,
        }).then();
      }
  };

    const renderDateRangeSelect = () => {
        if (!isMainGroup()) return null;  

      const selectedDateType = updatedDateTypesOptions?.find(item => item.dateType === dateRangeValue);
      
        const displayDate = selectedDateType?.name.replace(' to Invalid date', '');
  
      return (
          <>
              <div style={{display: 'flex', alignItems: 'center', gap: '10px'
              }}>
                <div className='showOnlyTitle'>show only</div>  
              <TextField
                  disabled={isLoadingUnitDateTypes}
                  variant="outlined"
                  margin="none"
                  fullWidth
                  onClick={handleOpenPopover}
                  value={displayDate || ''}
                  className='dateRangePicker'
                  InputProps={{
                      endAdornment: <ArrowDropDownIcon className='dateRangePickerIcon'/>,
                  }}
              />
              <Popover
                  open={Boolean(popoverAnchor)}
                  anchorEl={popoverAnchor}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                  }}
              >
                  {updatedDateTypesOptions
              ?.filter(item => item.dateType !== 'customRange')
              .map(item => (
                  <MenuItem
                      key={item.dateType}
                      value={item.dateType}
                      className="filterMenuItem"
                      onClick={() => {
                          handleChangeDateRange({ target: { value: item.dateType } });
                          handleClosePopover();
                      }}
                  >
                      {item.name}
                  </MenuItem>
              ))}
                  <DateRangePicker
                      customRange
                      dateFrom={selectedDateFrom}
                      dateTo={selectedDateTo}
                      setDateFrom={setSelectedDateFrom}
                      setDateTo={setSelectedDateTo}
                      setCustomDateRangeType={setCustomDateRangeType}
                      onChange={handleDateChange}
                      onReset={() => setResetClicked(true)}
                      handleClosePopover={handleClosePopover}
                      handleReset={handleReset}
                      jobPicker
                  />
              </Popover>
              </div>
          </>
      );
  };

    if (!win.id) return null;

    return (
      win.id && (
        <>
          <div className="pageBtnSection">
            <div className="part">
              <div className="btnGroup_Jobs">
                <div className="btnGroup_Jobs_item">
                  <AddBoxMenu
                    itemTypes={
                      itemTypes.filter((item) => item.itemType !== "map")
                    }
                    onAddBox={addBoxShowModal}
                  />
                </div>
                <div className="btnGroup_Jobs_item">
                  {renderOptionsButton()}
                </div>
                <div className="btnGroup_Jobs_item">
                  <DownloadFileButton
                    getExportRequest={dataExportRequest}
                    tabType="jobs"
                  />
                </div>
              </div>
            </div>

            <div className="part centered">
              <Button
                onClick={() => {
                  setChangeJobModalIsOpen(true);
                }}
                endIcon={<ArrowDropDownIcon />}
              >
                <span>{currentJob ? currentJob.name : "Select Job"}</span>
              </Button>
            </div>

            <div className="part end">
                <div className='jobsDateRange'>
                    {renderDateRangeSelect()}
                </div>
              </div>
            </div>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable
              droppableId="droppable"
              type="droppableItem"
              isCombineEnabled
            >
              {(provided) => (
                <div
                  className={isDragging ? "dragging" : ""}
                  ref={provided.innerRef}
                >
                  {boxes.map((box, index) => {
                    if (box.itemPosition.position === "Stretch") {
                      return (
                        <Draggable
                          key={box.id}
                          draggableId={box.id}
                          index={index}
                        >
                          {(provided) => (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="pageBlockHolder"
                              >
                                <div
                                  className={`pageBlock ${box.itemPosition.position}`}
                                  key={box.id}
                                >
                                  <Box
                                    boxData={box}
                                    onRemove={() => handleRemoveBox(box.id)}
                                    index={box.id}
                                    handleEdit={handleEditBox}
                                    onExportToCsv={handleExportToCsv}
                                    onNewWindowOpen={onNewWindowOpen}
                                    tabId={win.id}
                                    dashboard={win}
                                    boundChannelList={boundChannelList}
                                    dragHandleProps={provided.dragHandleProps}
                                    syncCurrentTabs={syncCurrentTabs}
                                    groupBoundChannelColors={
                                      groupBoundChannelColors
                                    }
                                    eventTypes={eventTypes}
                                    onFilterChange={updateFilters}
                                  />
                                </div>
                              </div>
                              {provided.placeholder}
                            </>
                          )}
                        </Draggable>
                      );
                    }
                    if (box.itemPosition.position === "Left")
                      return (
                        <div className="combinedPageBlock" key={box.id}>
                          <Draggable
                            key={box.id}
                            draggableId={box.id}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className="pageBlockHolder"
                                >
                                  <div
                                    className={`pageBlock ${box.itemPosition.position}`}
                                    key={box.id}
                                  >
                                    <Box
                                      boxData={box}
                                      onRemove={() => handleRemoveBox(box.id)}
                                      index={box.id}
                                      handleEdit={handleEditBox}
                                      onExportToCsv={handleExportToCsv}
                                      onNewWindowOpen={onNewWindowOpen}
                                      tabId={win.id}
                                      dashboard={win}
                                      boundChannelList={boundChannelList}
                                      dragHandleProps={provided.dragHandleProps}
                                      syncCurrentTabs={syncCurrentTabs}
                                      groupBoundChannelColors={
                                        groupBoundChannelColors
                                      }
                                      eventTypes={eventTypes}
                                      onFilterChange={updateFilters}
                                    />
                                  </div>
                                </div>
                                {provided.placeholder}
                              </>
                            )}
                          </Draggable>
                          {boxes.length > index + 1 && (
                            <Draggable
                              key={boxes[index + 1].id}
                              draggableId={boxes[index + 1].id}
                              index={index + 1}
                            >
                              {(provided) => (
                                <>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="pageBlockHolder"
                                  >
                                    <div
                                      className={`pageBlock ${box.itemPosition.position}`}
                                      key={boxes[index + 1].id}
                                    >
                                      <Box
                                        boxData={boxes[index + 1]}
                                        onRemove={() =>
                                          handleRemoveBox(boxes[index + 1].id)
                                        }
                                        index={boxes[index + 1].id}
                                        handleEdit={handleEditBox}
                                        onExportToCsv={handleExportToCsv}
                                        onNewWindowOpen={onNewWindowOpen}
                                        tabId={win.id}
                                        dashboard={win}
                                        boundChannelList={boundChannelList}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                        syncCurrentTabs={syncCurrentTabs}
                                        groupBoundChannelColors={
                                          groupBoundChannelColors
                                        }
                                        eventTypes={eventTypes}
                                        onFilterChange={updateFilters}
                                      />
                                    </div>
                                  </div>
                                  {provided.placeholder}
                                </>
                              )}
                            </Draggable>
                          )}
                        </div>
                      );
                    return null;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <ManageChannelsModal
            jobUnits={currentJobUnits}
            isDialog={true}
            type={currentManageChannelsModalType}
            isOpen={manageChannelsIsOpen}
            setSelectedCsvChannels={setSelectedCsvChannels}
            selectedCsvChannels={selectedCsvChannels}
            showManageChannelsFirst={showManageChannelsFirst}
            onCancel={() => {
              setCurrentManageChannelsModalType("");
              setManageChannelsIsOpen(false);
              setCurrentEditedBox(null);
              setAfterSelectJobAction(null);
              setExportToCsvModalIsOpen(false);
              setShowManageChannelsFirst(false);
              setSelectedCsvChannels([])
              if (
                !boxes.length &&
                !currentUser.isOneJobPassword &&
                isMainGroup()
              ) {
                setCurrentJob(null);
              }
            }}
            onBack={() => {
              setManageChannelsIsOpen(false);
              setSelectJobModalIsOpen(true);
              setCurrentEditedBox(null);
              if (!boxes.length && isMainGroup()) {
                setCurrentJob(null);
              }
            }}
            editedModel={currentEditedBox}
            onSave={handleSaveBox}
            showBackButton={
              !boxes.length && currentUser && !currentUser.isOneJobPassword && selectedCsvChannels !== 'null'
            }
            tabId={win.id}
          />

          <ExportToCsvModal
            isOpen={exportToCsvModalIsOpen}
            setOpen={setExportToCsvModalIsOpen}
            jobName={currentJob ? currentJob.name : ""}
            onExportData={onExportData}
            onCancel={() => {
              setCurrentEditedBox(null);
              setSelectedCsvChannels([])
            }}
            currentJob={currentJob}
            startChartDate={startChartDate}
            endChartDate={endChartDate}
            onBack={() => {
              setManageChannelsIsOpen(true);
              setSelectJobModalIsOpen(false);
              // setSelectedCsvChannels(null);
              setExportToCsvModalIsOpen(false);
              setShowManageChannelsFirst(true);
            }}
            modalStep={modalStep}
          />

          <SaveTemplateModal
            isOpen={saveTemplateModalIsOpen}
            onSuccessSave={(template) => onSuccessTemplateSave(template)}
            loadedTemplate={loadedTemplate}
            isOpenStateChanger={onSaveTemplateModalIsOpenChange}
          />

          <SelectJobModal
            jobs={jobs}
            isOpen={selectJobModalIsOpen}
            onCancel={() => {
              setSelectJobModalIsOpen(false);
              setAfterSelectJobAction(null);
              if (!boxes.length && isMainGroup()) {
                setCurrentJob(null);
              }
              setExportToCsvModalIsOpen(false);
              setShowManageChannelsFirst(false)
            }}
            onSave={handleSaveCurrentJob}
            setCurrentJobUnits={setCurrentJobUnits}
            type={afterSelectJobAction}
          />

          <ChangeJobModal
            currentJob={currentJob}
            jobs={jobs}
            isOpen={changeJobModalIsOpen}
            onCancel={() => {
              setChangeJobModalIsOpen(false);
              setExportToCsvModalIsOpen(false);
            }}
            onSave={handleChangeJob}
            setCurrentJobUnits={setCurrentJobUnits}
            loading={changeTemplateMutation.isLoading}
          />

          <ErrorTemplateModal
            errors={templateErrors}
            isOpen={showTemplateErrorsModal}
            onOk={() => {
              setShowTemplateErrorsModal(false);
              setTemplateErrors(undefined);
            }}
          />

          <LoadTemplateModal2
            isOpen={loadTemplateModalIsOpen}
            isOpenChangeHandler={onLoadTemplateModalIsOpenChange}
            jobs={jobs}
            currentJob={currentJob}
            loadedTemplate={loadedTemplate}
            onTemplateLoaded={onTemplateLoaded}
            setCurrentJobUnits={setCurrentJobUnits}
          />

          {
            <UnitsManagerSignalr
              currentJobId={currentJob?.id}
              setCurrentJobUnits={setCurrentJobUnits}
              jobs={jobs}
            />
          }
        </>
      )
    );
};


const mapStateToProps = ({ dashboard }) => ({
    currentCompany: dashboard.currentCompany,
});

const mapDispatchToProps = {
    fetchJobsSuccess,
    setCurrentCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGroup);
