export default {
    SET_DEPARTMENTS: 'SET_DEPARTMENTS',

    SET_EMPLOYEES: 'SET_EMPLOYEES',

    SET_CURRENT_DEPARTMENT: 'SET_CURRENT_DEPARTMENT',

    SET_COMPONENTS: 'SET_COMPONENTS',

    UPDATE_COMPONENT_WORKHOURS: 'UPDATE_COMPONENT_WORKHOURS',

    SET_PARTS: 'SET_PARTS',

    SET_RECOMMENDED_SPARE_PARTS: 'SET_RECOMMENDED_SPARE_PARTS',

    SET_PARTS_CATALOG: 'SET_PARTS_CATALOG',

    SET_COMPONENTS_CATALOG: 'SET_COMPONENTS_CATALOG',

    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',

    SET_RUN_COMPONENTS_UPDATE: 'SET_RUN_COMPONENTS_UPDATE',

    SET_TYPE_OPTIONS: 'SET_TYPE_OPTIONS',

    SET_STATUS_OPTIONS: 'SET_STATUS_OPTIONS',

    SET_STATES: 'SET_STATES',

    SET_CURRENT_COMPANY_PAGE: 'SET_CURRENT_COMPANY_PAGE',

    SET_EDITING_COMPANY: 'SET_EDITING_COMPANY',

    SET_REPORTS: 'SET_REPORTS',

    SET_UNASSIGNED_COMPONENTS: 'SET_UNASSIGNED_COMPONENTS',

    SET_FILTER_INFO: 'SET_FILTER_INFO',

    SET_ACTIVITIES: 'SET_ACTIVITIES',

    SET_PAGE_TITLE: 'SET_PAGE_TITLE',

    SET_RSP_SELECTED_COMPONENTS_IDS: 'SET_RSP_SELECTED_COMPONENTS_IDS',

    SET_SELECTED_UNASSIGNED_COMPONENTS_IDS: 'SET_SELECTED_UNASSIGNED_COMPONENTS_IDS',
}
