import React, { useEffect, useMemo, useState } from 'react';
import useUnitJobs from '../../Hooks/useUnitJobs';
import useUnitDateTypes from '../../Hooks/useUnitDateTypes';
import RadioLabel from '../RadioLabel';
import useTranslation from '../../Hooks/useTranslation';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { MenuItem, TextField } from '@material-ui/core';
import { deleteEmptyStringProperties } from '../../helpers';

const defaultDateRangeValue = 'thisWeek';

const UnitJobDateRangeFilters = props => {

    const {
        unitUid,
        onChange,
        initData,
        inline = false,
    } = props

    const translation = useTranslation();
    const { data: unitJobs, isLoading: isLoadingUnitJobs } = useUnitJobs(unitUid);
    const { data: dateTypes, isLoading: isLoadingUnitDateTypes } = useUnitDateTypes();

    const dateTypesOptions = useMemo(() => {
        return dateTypes.map(item => ({ ...item, name: item.name || translation.selectDateRangeLabel }));
    }, [dateTypes, translation]);


    const [filterType, setFilterType] = useState('date');
    const [jobValue, setJobValue] = useState('none');
    const [dateRangeValue, setDateRangeValue] = useState('');

    useEffect(() => {
        if (dateTypesOptions.length && !initData?.dateType) {
            setDateRangeValue(defaultDateRangeValue);
        }
    }, [dateTypesOptions, initData]);

    useEffect(() => {
        if (!initData) return;

        if (initData.jobId) {
            setFilterType('job');
            setJobValue(initData.jobId);
        }
        if (initData.dateType && initData.dateType !== 'none') {
            setFilterType('date');
            setDateRangeValue(initData.dateType);
        }
    }, [initData]);


    useEffect(() => {
        if (inline) return;
        onChange(deleteEmptyStringProperties({
            dateType: dateRangeValue,
            jobId: jobValue !== 'none' ? jobValue : '',
        }));
    }, [jobValue, dateRangeValue, onChange, inline]);

    const handleChangeFilterType = (event) => {
        setDateRangeValue('none');
        setJobValue('none');
        setFilterType(event.target.value);
        if (inline) {
            if (dateRangeValue === 'none' && jobValue === 'none') return;

            onChange(deleteEmptyStringProperties({
                dateType: 'none',
                jobId: '',
            }));
        }
    };

    const handleChangeJob = (event) => {
        const value = event.target.value

        setDateRangeValue('none');
        setJobValue(value);

        if (inline) {
            onChange(deleteEmptyStringProperties({
                dateType: 'none',
                jobId: value,
            }));
        }
    };

    const handleChangeDateRange = (event) => {
        const value = event.target.value

        setJobValue('none');
        setDateRangeValue(value);

        if (inline) {
            onChange(deleteEmptyStringProperties({
                dateType: value,
                jobId: '',
            }));
        }
    };

    const renderJobSelect = () => {
        if (filterType !== 'job') return null;

        return (
            <TextField
                disabled={isLoadingUnitJobs}
                variant="outlined"
                margin="normal"
                fullWidth={!inline}
                select
                value={jobValue}
                onChange={handleChangeJob}
            >
                <MenuItem value="none" className="filterMenuItem">{translation.selectJobLabel}</MenuItem>
                {
                    unitJobs.map(item => (
                        <MenuItem
                            className="filterMenuItem"
                            key={item.id}
                            value={item.id}
                        >
                            {item.name}
                        </MenuItem>
                    ))
                }
            </TextField>
        );
    };

    const renderDateRangeSelect = () => {
        if (filterType !== 'date') return null;

        return (
            <TextField
                disabled={isLoadingUnitDateTypes}
                variant="outlined"
                margin="normal"
                fullWidth={!inline}
                select
                value={dateRangeValue}
                onChange={handleChangeDateRange}
            >
                {
                    dateTypesOptions.map(item => (
                        <MenuItem
                            key={item.dateType}
                            value={item.dateType}
                            className="filterMenuItem"
                        >
                            {item.name}
                        </MenuItem>
                    ))
                }
            </TextField>
        );
    };

    return (
        <div style={inline ? {display: 'flex', alignItems: 'center'} : {}}>
            <div className="modalFilters filterMenuItem" style={{ marginLeft: '11px' }}>
                <RadioGroup
                    row
                    defaultValue="job"
                    value={filterType}
                    onChange={handleChangeFilterType}
                >
                    <FormControlLabel
                        value="date"
                        control={<Radio color="default" size="small" />}
                        label={<RadioLabel label={translation.dateRangeLabel} />}
                    />
                    <FormControlLabel
                        value="job"
                        control={<Radio color="default" size="small" />}
                        label={<RadioLabel label={translation.jobLabel} />}
                    />
                </RadioGroup>
            </div>
            {renderJobSelect()}
            {renderDateRangeSelect()}
        </div>
    );
};

export default UnitJobDateRangeFilters;
