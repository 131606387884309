import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { GuestRoute, PrivateRoute, Route } from '../Routes';
import AlertContainer from '../Layouts/AlertContainer.component';
import { DashboardInfoPage, SignInPage } from '../../Pages';
import { AuthLayout, MainLayout } from '../Layouts';
import SignUpPage from 'Pages/Auth/SignUp/SignUp.page';
import ConfirmEmailPage from 'Pages/Auth/ConfirmEmail/ConfirmEmail.page';
import PasswordResetRequest from 'Pages/Auth/PasswordReset/PasswordResetRequest.page';
import PasswordResetPage from 'Pages/Auth/PasswordReset/PasswordReset.page';
import ChangePasswordPage from 'Pages/Auth/ChangePassword/ChangePassword.page';
import PredictiveMaintenanceLayout from '../Layouts/PredictiveMaintenance.layout';
import CompaniesPage from '../../Pages/PredictiveMaintenance/Companies/CompaniesPage';
// import DashboardMMS from '../../Pages/PredictiveMaintenance/Dashboard/DashboardMMS';
import EquipmentPage from '../../Pages/PredictiveMaintenance/Equipment/EquipmentPage';
import CatalogPage from '../../Pages/PredictiveMaintenance/Catalog/CatalogPage';
import ActivitiesPage from '../../Pages/PredictiveMaintenance/Activities/ActivitiesPage';
import useTranslation from 'Hooks/useTranslation';
import UsersSettingsPage from '../../Pages/Users/UsersSettings.page';
import LocationsPage from '../../Pages/Locations/LocationsPage';
import UnitsPage from '../../Pages/Units/UnitsPage';
// import ScheduledReportsIndexPage from '../../Pages/PredictiveMaintenance/ScheduledReports/SchedeledReportsIndexPage';
import DataUsage from '../../Pages/Users/Blocks/DataUsage';
import ScheduledReportsPage from '../../Pages/PredictiveMaintenance/ScheduledReports/ScheduledReportsPage';
import NotificationsPage from '../../Pages/PredictiveMaintenance/ScheduledReports/NotificationsPage';
import DepartmentsComponent from 'Pages/PredictiveMaintenance/Companies/DepartmentsComponent';
import EmployeesComponent from 'Pages/PredictiveMaintenance/Companies/EmployeesComponent';
import PartsComponent from 'Pages/PredictiveMaintenance/Equipment/PartsComponent';
import CompanySettings from 'Pages/Users/CompanySettings';
import UnassignedComponents from 'Pages/PredictiveMaintenance/UnassignedComponents/UnassignedComponents';
import '../../amplify-config'; 

const App = () => {
    const translation = useTranslation();

    return (
        <Fragment>
            <Switch>
                <PrivateRoute
                    path="/"
                    component={DashboardInfoPage}
                    layout={MainLayout} exact
                />
                <GuestRoute
                    path="/signin"
                    component={SignInPage}
                    layout={AuthLayout}
                    title={translation.forgotPasswordPageSignInLabel}
                />
                <GuestRoute
                    path="/signup"
                    component={SignUpPage}
                    layout={AuthLayout}
                    title={translation.signUpLabel}
                />
                <GuestRoute
                    path="/confirm-email"
                    component={ConfirmEmailPage}
                    layout={AuthLayout}
                    title={translation.emailConfirmationPageLabel}
                />
                <GuestRoute
                    path="/password/reset"
                    component={PasswordResetRequest}
                    layout={AuthLayout}
                    title={translation.forgotPasswordPageLabel}
                />
                <Route
                    path="/reset-password"
                    component={PasswordResetPage}
                    layout={AuthLayout}
                    title={translation.passwordResetPageLabel}
                />
                <Route
                    path="/set-password"
                    component={PasswordResetPage}
                    layout={AuthLayout}
                    title={translation.setPasswordPageLabel}
                />
                <PrivateRoute
                    path="/change-password"
                    component={ChangePasswordPage}
                    layout={AuthLayout}
                    title={translation.changePasswordPageLabel}
                />
                <PrivateRoute
                    path="/settings"
                    component={UsersSettingsPage}
                    layout={MainLayout}
                />
                <PrivateRoute
                    path="/data-usage"
                    component={DataUsage}
                    layout={MainLayout}
                />
                <PrivateRoute
                    path="/company-settings"
                    component={CompanySettings}
                    layout={MainLayout}
                />
                <PrivateRoute
                    path="/units"
                    component={UnitsPage}
                    layout={MainLayout}
                    exact
                />
                <PrivateRoute
                    path="/locations"
                    component={LocationsPage}
                    layout={MainLayout}
                    exact
                />
                <PrivateRoute
                    path="/dashboard/:dashboardId"
                    component={DashboardInfoPage}
                    layout={MainLayout}
                />
                <PrivateRoute
                    path="/units/dashboard/:dashboardId"
                    component={UnitsPage}
                    layout={MainLayout}
                />
                <PrivateRoute
                    path="/locations/dashboard/:dashboardId"
                    component={LocationsPage}
                    layout={MainLayout}
                />
                <PrivateRoute
                    path="/companies"
                    component={CompaniesPage}
                    layout={PredictiveMaintenanceLayout}
                />
                <PrivateRoute
                    path="/departments"
                    component={DepartmentsComponent}
                    layout={PredictiveMaintenanceLayout}
                />
                <PrivateRoute
                    path="/employees"
                    component={EmployeesComponent}
                    layout={PredictiveMaintenanceLayout}
                />
                <PrivateRoute
                    path="/parts"
                    component={PartsComponent}
                    layout={PredictiveMaintenanceLayout}
                />
                {/* <PrivateRoute
                    path="/dashboard"
                    component={DashboardMMS}
                    layout={PredictiveMaintenanceLayout}
                /> */}
                <PrivateRoute
                    path="/equipment"
                    component={EquipmentPage}
                    layout={PredictiveMaintenanceLayout}
                />
                <PrivateRoute
                    path="/catalog"
                    component={CatalogPage}
                    layout={PredictiveMaintenanceLayout}
                    exact
                />
                <PrivateRoute
                    path="/unassigned-components"
                    component={UnassignedComponents}
                    layout={PredictiveMaintenanceLayout}
                    exact
                />
                <PrivateRoute
                    path="/activities"
                    component={ActivitiesPage}
                    layout={PredictiveMaintenanceLayout}
                    exact
                />
                {/*<PrivateRoute*/}
                {/*    path="/reports"*/}
                {/*    component={ScheduledReportsIndexPage}*/}
                {/*    layout={PredictiveMaintenanceLayout}*/}
                {/*/>*/}
                <PrivateRoute
                    path="/reports"
                    component={ScheduledReportsPage}
                    layout={PredictiveMaintenanceLayout}
                />
                <PrivateRoute
                    path="/notifications"
                    component={NotificationsPage}
                    layout={PredictiveMaintenanceLayout}
                />
                <Redirect to="/" />
            </Switch>
            <AlertContainer />
        </Fragment>
    );
};

export default App;
