import React, {useState}  from 'react'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import { useAsyncDebounce } from 'react-table'
import useTranslation from 'Hooks/useTranslation';

const SearchInput = ({search, onChange}) => {

    const [value, setValue] = useState(search)
    const translation = useTranslation();

    const onSearchChange = useAsyncDebounce(value => {
        onChange(value || undefined)
    }, 500)

    return (
        <TextField
            value={value || ''}
            onChange={e => {
                setValue(e.target.value)
                onSearchChange(e.target.value)
            }}
            id="search"
            label={translation.searchAssetsAndPartsLabel}
            type='search'
            variant="outlined"
            className='search'
            // InputProps={{
            //     startAdornment: (
            //         <InputAdornment position="start">
            //             <SearchIcon />
            //         </InputAdornment>
            //     ),
            // }}
        />
    )
}

export default SearchInput
