import React from 'react';
// import { Tab, Tabs } from '@material-ui/core';
// import EquipmentComponent1 from './EquipmentComponent1';
import EquipmentComponent from './EquipmentComponent2';
// import PartsComponent from './PartsComponent';
import useTranslation from 'Hooks/useTranslation';
// import { useRouteMatch } from 'react-router-dom';
// import { useHistory, useLocation } from 'react-router';
// import { PrivateRoute } from '../../../Components/Routes';

const EquipmentPage = () => {
    // const [value, setValue] = useState(0);

    // const { path, url } = useRouteMatch();
    // const history = useHistory();
    // const { pathname } = useLocation();
    const translation = useTranslation();

    // const tabNameToIndex = useMemo(() => {
    //     return {
    //         0: `${url}`,
    //         1: `${url}/parts`,
    //         2: `${url}/assets-old`,
    //     };
    // }, [url]);

    // const indexToTabName = useMemo(() => {
    //     return {
    //         [`${path}`]: 0,
    //         [`${path}/parts`]: 1,
    //         [`${path}/assets-old`]: 2,
    //     };
    // }, [path]);

    // const [value, setValue] = useState(() => {
    //     return indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
    // });

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     history.push(tabNameToIndex[newValue])
    // };

    // useEffect(() => {
    //     const index = indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
    //     setValue(index)
    // }, [pathname, indexToTabName])

    return (
        <div className="pmPageHolder">
            <div className="pmPageBlock">
                <div className="pmPageBlockTitle">{translation.equipmentPageLabel}</div>
                {/* <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    className="pmTabs mb15"
                >
                    <Tab
                        disableRipple
                        label={translation.equipmentPageMyEquipmentLabel} />
                    <Tab disableRipple
                         label={translation.equipmentPagePartsLabel} />
                    <Tab disableRipple
                        label="old" />
                </Tabs>
                <Switch>
                    <PrivateRoute path={`${path}`} component={EquipmentComponent} exact/>
                    <PrivateRoute path={`${path}/parts`}  component={PartsComponent}/>
                    <PrivateRoute path={`${path}/assets-old`} component={EquipmentComponent1} exact/>
                </Switch> */}
                <EquipmentComponent />
            </div>
        </div>
    );
};

export default EquipmentPage;
