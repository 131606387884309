import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import { IconButton, Switch } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import useTranslation from '../../../Hooks/useTranslation'
import { makeStyles } from '@material-ui/core/styles'
import { userHasPermission } from '../../../helpers'
import DmsService from '../../../Services/DMS/dms-api.service'
import AlertToastr from '../../../utils/alert'
import NewUserModal from '../../../Components/modals/new-user-modal/new-user-modal.component'
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component'
import { useUsersSettings } from '../UsersSettingsContext'
import UserAvatar from '../../../Components/UserAvatar';

const RemoveIcon = () => <div className='removeIcon' />
const ResendIcon = () => <div className='resendIcon' />
const IcoPlusWhite = () => <div className='plusIcon' />

const descConst = 'descending'
const ascConst = 'ascending'
const arrowDownClassName = 'hasArrow'
const arrowUpClassName = 'hasArrow up'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

const Users = () => {

    const {
        currentUser,
        currentCompany,
        setCompanyDetails,
        users,
        updateUsers
    } = useUsersSettings()
    const classes = useStyles()
    const [newUserModalIsOpen, onNewUserModalIsOpenChange] = useState(false)
    const [removeUserModalIsOpen, onRemoveUserModalIsOpen] = useState(false)
    const [jobs, setJobs] = useState([])
    const [userData, setUserData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' })
    const allowManageAdmins = useMemo(() => userHasPermission(currentUser, 'manage_admins'), [currentUser])
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser])
    const isSuperUserOrRoot = useMemo(() => (currentUser.isSuperuser() || currentUser.isRoot()), [currentUser])


    const companyId = currentCompany ? currentCompany.id : currentUser?.companyId
    const translation = useTranslation()

    useEffect(() => {
        try {
            if (!companyId)
                return
            if (isSuperUser) {
                setCompanyDetails(currentCompany)
            } else
                DmsService.getCompany(companyId)
                    .then(response => setCompanyDetails(response.data))
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message)
        }
    }, [companyId, currentCompany, isSuperUser, setCompanyDetails])

    useEffect(() => {
        setIsLoading(true)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                let response
                if (isSuperUser) {
                    if (!currentCompany)
                        return
                    response = await DmsService.getCompanyJobsShort(currentCompany.id)
                } else {
                    response = await DmsService.getJobsShort()
                }
                setJobs(response)
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            }
        }
        fetchJobs().then()
    }, [currentCompany, isSuperUser])

    const sortedUsers = useMemo(() => {
        let sortableUsers = [...users]
        if (sortConfig !== null) {

            if (sortConfig.key === 'status') {
                sortableUsers.sort((a, b) => {
                    if (a.isActivated < b.isActivated) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (a.isActivated > b.isActivated) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (a.isActivated && b.isActivated) {
                        if (a.isEnabled < b.isEnabled) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.isEnabled > b.isEnabled) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }
            if (sortConfig.key === 'admin') {
                sortableUsers.sort((a, b) => {
                    const aValue = a.role === 'admin';
                    const bValue = b.role === 'admin'


                    if (aValue < bValue) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (aValue && bValue) {
                        if (a.created < b.created) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.created > b.created) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }

            if (sortConfig.key === 'master') {
                sortableUsers.sort((a, b) => {
                    const aValue = a.role === 'master';
                    const bValue = b.role === 'master'


                    if (aValue < bValue) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (aValue && bValue) {
                        if (a.created < b.created) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.created > b.created) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }

            if (sortConfig.key === 'root') {
                sortableUsers.sort((a, b) => {
                    const aValue = a.role === 'root';
                    const bValue = b.role === 'root'


                    if (aValue < bValue) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (aValue && bValue) {
                        if (a.created < b.created) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.created > b.created) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }

            if (sortConfig.key === 'mmsmanager') {
                sortableUsers.sort((a, b) => {
                    const aValue = a.role === 'mmsmanager';
                    const bValue = b.role === 'mmsmanager'


                    if (aValue < bValue) {
                        return sortConfig.direction === ascConst ? -1 : 1
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === ascConst ? 1 : -1
                    }
                    if (aValue && bValue) {
                        if (a.created < b.created) {
                            return sortConfig.direction === ascConst ? -1 : 1
                        }
                        if (a.created > b.created) {
                            return sortConfig.direction === ascConst ? 1 : -1
                        }
                    }
                    return 0
                })
            }

            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === ascConst ? -1 : 1
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === ascConst ? 1 : -1
                }
                return 0
            })
        }
        return sortableUsers
    }, [users, sortConfig])

    const hasMasterUsers = useMemo(() => {
        if (!users) return false;
        return users.some(user => user.role === 'master')
    }, [users])

    const onCloseModal = (userInfo) => {
        if (!userInfo) {
            return
        }
        if (!userData) {
            DmsService.createUser(userInfo).then(res => {
                updateUsers()
            })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        } else {
            DmsService.updateUser(userInfo).then(res => updateUsers())
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        }
    }

    const onDeleteUser = () => {
        DmsService.deleteUser(userData.id).then(res => updateUsers())
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const requestSort = (key) => {
        let direction = ascConst
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === ascConst
        ) {
            direction = descConst
        }
        setSortConfig({ key, direction })
    }

    const userClicked = (user) => {
        if (user.role === currentUser.role) return;
        if ((allowManageAdmins || !user.isAdmin) && !user.isRoot)
            editUser(user)
    }

    const getClassNamesFor = (name) => {
        if (!sortConfig || sortConfig.key !== name) {
            return
        }
        if (sortConfig.direction)
            return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName
    }

    const getClassNameForUser = (user) => {
        if (!user.isActivated) {
            return 'inActive'
        }
        if ((!allowManageAdmins && user.isAdmin) || user.isRoot || (user.role === currentUser.role)) {
            return 'disabled'
        }
        return ''
    }

    const resendEmail = (user) => {
        if (allowManageAdmins || !user.isAdmin) {
            let email = users.find(userInfo => userInfo.id === user.id).email
            DmsService.resendUserEmail(email).then(res => {
                AlertToastr.showAlert(translation.notificationsEmailResent)
            })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.Message)
                        AlertToastr.showErrorAlert(error.response.data.Message)
                })
        }
    }

    const editUser = (userInfo) => {
        setUserData(userInfo)
        onNewUserModalIsOpenChange(true)
    }

    const createUser = (e) => {
        setUserData(null)
        onNewUserModalIsOpenChange(true)
    }

    const onEnabledChanged = (userInfo) => {
        if (userInfo.role === currentUser.role) return;
        if (allowManageAdmins || !userInfo.isAdmin) {
            userInfo.isEnabled = !userInfo.isEnabled
            DmsService.updateUser(userInfo).then(res => updateUsers())
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.Message)
                        AlertToastr.showErrorAlert(error.response.data.Message)
                })
        }
    }

    return (
        <Fragment>
            <div className="pageBtnSection pt0">
                <div className="part">
                    <Button
                        className="addItem"
                        variant="contained"
                        color="primary"
                        startIcon={<IcoPlusWhite />}
                        data-toggle="modal"
                        onClick={createUser}>
                        {translation.settingsTablesNewUserLabel}
                    </Button>
                </div>
            </div>
            <div className="pageCard noPadding">
                <div className="tableHolder">
                    <table>
                        <thead>
                        <tr className='headingRow'>
                            <th onClick={() => requestSort('name')}
                                className={getClassNamesFor('name')}>
                                {translation.settingsTablesNameHeader}
                            </th>
                            <th onClick={() => requestSort('email')}
                                className={getClassNamesFor('email')}>
                                {translation.settingsTablesEmailHeader}
                            </th>
                            <th onClick={() => requestSort('status')}
                                className={`text-center ${getClassNamesFor('status')}`}>
                                {translation.settingsTablesStatusHeader}
                            </th>
                            {isSuperUser && hasMasterUsers && <th onClick={() => requestSort('master')}
                                                className={`text-center ${getClassNamesFor('master')}`}>
                                {translation.settingsTablesMasterHeader}
                            </th>}
                            {isSuperUserOrRoot && <th onClick={() => requestSort('root')}
                                                className={`text-center ${getClassNamesFor('root')}`}>
                                {translation.settingsTablesRootHeader}
                            </th>}
                            <th onClick={() => requestSort('admin')}
                                className={`text-center ${getClassNamesFor('admin')}`}>
                                {translation.settingsTablesAdminHeader}
                            </th>
                            <th onClick={() => requestSort('mmsmanager')}
                                className={`text-center ${getClassNamesFor('mmsmanager')}`}>
                                {translation.settingsTablesMmsManagerHeader}
                            </th>
                            <th onClick={() => requestSort('activeSessions')}
                                className={`text-center ${getClassNamesFor('activeSessions')}`}>
                                {translation.settingsTablesSessionsHeader}
                            </th>
                            <th onClick={() => requestSort('created')}
                                className={getClassNamesFor('created')}>
                                {translation.settingsTablesCreatedHeader}
                            </th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (sortedUsers || sortedUsers.length === 0) ? sortedUsers.map(user => {
                                return (
                                    <tr key={user.id} className={getClassNameForUser(user)}>
                                        <td onClick={() => userClicked(user)}>
                                            <div className="userInfo">
                                                {
                                                    user.isOnline ? (
                                                        <Badge
                                                            color="primary"
                                                            variant="dot"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right'
                                                            }}
                                                        >
                                                            <UserAvatar name={user.name} />
                                                        </Badge>
                                                    ) :
                                                        <UserAvatar name={user.name} />
                                                }

                                                <span className="userName">{user.name}</span>
                                            </div>
                                        </td>
                                        <td onClick={() => userClicked(user)}>{user.email}</td>
                                        {user.isActivated ?
                                            <td className='text-center switchCell' onClick={() => onEnabledChanged(user)}>
                                                <Switch checked={user.isEnabled}
                                                        disabled={user.role === currentUser.role}
                                                        color="primary" />
                                            </td> : <td className='text-center' onClick={() => userClicked(user)}>Pending</td>}
                                        {isSuperUser && hasMasterUsers && <td className='text-center'>
                                            {user.role === 'master' && <CheckCircleOutlineIcon />}
                                        </td>}
                                        {isSuperUserOrRoot && <td className='text-center'>
                                            {user.role === 'root' && <CheckCircleOutlineIcon />}
                                        </td>}
                                        <td className='text-center'>
                                            {user.role === 'admin' && <CheckCircleOutlineIcon />}
                                        </td>
                                        <td className='text-center'>
                                            {user.role === 'mmsmanager' && <CheckCircleOutlineIcon />}
                                        </td>
                                        <td className='text-center'>{+user.activeSessions || '-'}</td>
                                        <td onClick={() => userClicked(user)}>{moment(user.created).format('DD.MM.YYYY')}</td>
                                        <td className={'actionTableCell'}>
                                            {!user.isActivated &&
                                            <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                        disabled={user.role === currentUser.role}
                                                        value={user.id} onClick={(e) => resendEmail(user)}>
                                                <ResendIcon />
                                            </IconButton>}
                                            <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                        disabled={user.role === currentUser.role}
                                                        onClick={(e) => {
                                                            if ((allowManageAdmins || !(user.role === 'admin')) && !(user.role === 'root')) {
                                                                setUserData(user)
                                                                onRemoveUserModalIsOpen(true)
                                                            }
                                                        }}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </td>
                                    </tr>
                                )
                            }) : null
                        }
                        </tbody>
                    </table>
                    <div className={classes.root}>
                        <Fade
                            in={isLoading}
                            unmountOnExit>
                            <CircularProgress />
                        </Fade>
                    </div>
                </div>
            </div>
            <NewUserModal isOpen={newUserModalIsOpen}
                          isOpenChangeHandler={onNewUserModalIsOpenChange}
                          onRequestClose={onCloseModal}
                          onDeleteUser={onDeleteUser}
                          userData={userData}
                          jobs={jobs}
                          companyId={currentCompany?.id} />
            <DeleteItemModal isOpen={removeUserModalIsOpen}
                             isOpenManager={onRemoveUserModalIsOpen}
                             message={translation.removeUserModalMessageLabel}
                             onDeleteModalClose={onDeleteUser} />
        </Fragment>
    )
}

export default Users
