const DASHBOARD_PREFIX_LOCALSTORAGE_KEY = process.env.REACT_APP_DASHBOARD_PREFIX_LOCALSTORAGE_KEY || 'dashboard';

export const getStoredDashboard = (id) => {
    const data = window.localStorage.getItem(`${DASHBOARD_PREFIX_LOCALSTORAGE_KEY}-${id}`);
    return data ? JSON.parse(data) : null;
};

export const setStoredDashboard = (id, data) => {
    window.localStorage.setItem(`${DASHBOARD_PREFIX_LOCALSTORAGE_KEY}-${id}`, JSON.stringify(data));
};

export const clearStoredDashboard = (id) => {
    window.localStorage.removeItem(`${DASHBOARD_PREFIX_LOCALSTORAGE_KEY}-${id}`);
};
