import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

const getJobTemplatesById = async (id) => {
    const { data } = await axios.get(`${apiBaseUrl}/templates/by-jobid/${id}`);
    return data || [];
};

export default function useJobTemplates(id) {
    return useQuery(
        ['template', id, 'job'],
        () => getJobTemplatesById(id),
        {
            initialData: [],
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!id
        }
    );
}
