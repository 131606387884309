import React, { useState, memo } from 'react';
import useTranslation from '../../../Hooks/useTranslation';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import { MenuItem, Switch } from '@material-ui/core';
import { useNotificationsCUD } from '../../../Hooks/useNotificationReportsHooks';
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component';

const NotificationListItemActions = ({ item, onAction }) => {

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const translation = useTranslation();
    const { deleteMutation, activateMutation, deactivateMutation, invalidateList } = useNotificationsCUD();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const deleteItem = () => {
        deleteMutation.mutateAsync(item.id).then(() => {
            invalidateList().then()
        });
        setMenuAnchorEl(null);
    }

    const handleChangeStatus = (e) => {
        const { checked } = e.nativeEvent.target;
        if (checked) {
             activateMutation.mutateAsync(item.id).then(() => {
                 invalidateList().then()
             })
        } else {
             deactivateMutation.mutateAsync(item.id).then(() => {
                 invalidateList().then()
             })
        }
         //
        // onAction('changeStatus', item, { status: e.target.checked })
    }

    return (
        <>
            <IconButton aria-label="delete" size="small" className="dropDownBtn mobile" id="reportsTblDropDownMenuBtn"
                        onClick={(e) => {
                            setMenuAnchorEl(e.target);
                        }}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                anchorEl={menuAnchorEl}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                disableScrollLock={true}
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
                className="pmPopover equipmentSubMenu"
            >
                <div className="reverseSwitchHolder">
                    <Switch
                        disabled={activateMutation.isLoading || deactivateMutation.isLoading}
                        checked={Boolean(item ? item.isActive : false)}
                        onChange={handleChangeStatus}
                        id="notificationsTblMenuActiveBtn"
                        color="primary" />
                    <div>{translation.popoverActiveLabel}</div>
                </div>
                <MenuItem
                    onClick={() => {
                        onAction('edit', item);
                        setMenuAnchorEl(null);
                    }}
                    id="notificationsTblMenuDetailsBtn"
                    className="menuItem edit"
                >{translation.popoverDetailsLabel}</MenuItem>
                <MenuItem
                    onClick={() => setShowDeleteConfirm(true)}
                    id="notificationsTblMenuRemoveBtn"
                    className="menuItem delete"
                >{translation.popoverRemoveLabel}</MenuItem>
            </Popover>
            <DeleteItemModal isOpen={showDeleteConfirm}
                             loading={deleteMutation.isLoading}
                             isOpenManager={() => {
                             }}
                             onCancel={() => {
                                 setShowDeleteConfirm(false);
                                 setMenuAnchorEl(null);
                             }}
                             message={translation.formatString(translation.removeNotificationModalMessageLabel, { name: item?.name })}
                             onDeleteModalClose={deleteItem}
            />
        </>
    );
};

export default memo(NotificationListItemActions);
