/* eslint-disable array-callback-return */
import React, {
  useState,
  Fragment,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { apiMmsBaseUrl } from "config";
import Api from "../../../Services/Api.service";
import useTranslation from "Hooks/useTranslation";
import MmsService from "Services/MMS/mms-api.service";
import {
  setRunComponentsUpdate,
  setSelectedUnassignedComponentsIds,
  setPageTitle,
  setComponentsCatalog,
} from "../../../Store/modules/mms/actions";
import AlertToastr from "utils/alert";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { Checkbox, MenuItem } from "@material-ui/core";
import SearchInput from "Components/SearchInput";
import { connect } from "react-redux";
import { flattenTree } from "../../../helpers";
import {
  useComponent,
  useUnassignedComponentsWithPaging,
} from "../../../Hooks/mms/useComponents";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EquipmentInlineWorkhoursForm from "../Equipment/Modals/EquipmentInlineWorkhoursForm";
import { useQueryClient } from "react-query";
import TuneIcon from "@material-ui/icons/Tune";
import TablePagination from "../Equipment/TablePagination";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteItemModal from "Components/modals/delete-item-modal/delete-item-modal.component";
import SaveComponentModal from "../Equipment/Modals/SaveComponentModal";
import ChangeComponentStateModal from "../Equipment/Modals/ChangeComponentStateModal";
import EventEmitter from "../../../utils/EventEmitter";
import { useAuth } from "../../../Contexts/AuthProvider";
import ActivitiesTable from "../Equipment/ActivitiesTable";
import SaveActivityModal from "../Activities/Modals/SaveActivityModal";
import { useAppSettings } from "../../../Contexts/AppSettingsProvider";
import moment from "moment";
import AssignPersonButton from "../../../Components/AssignPersonButton";
import {
  setActivities,
  setFilterInfo,
} from "../../../Store/modules/mms/actions";
import { Tab, Tabs, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEmployeesByDepartmentId } from "../../../Hooks/mms/useEmployees";

const descConst = "descending";
const ascConst = "ascending";
const arrowDownClassName = "hasArrow";
const arrowUpClassName = "hasArrow up";

const UnassignedComponents = (props) => {
  const {
    setPageTitle,
    setSelectedUnassignedComponentsIds,
    isCatalog,
    componentsCatalog,
    components,
    employees,
    currentDepartment,
    statusOptions,
    parts,
  } = props;
  const { currentCompany } = useAuth();
  const { currentUser } = useAuth();

  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);

  const translation = useTranslation();

  const queryClient = useQueryClient();

  const [unassignedComponents, setUnassignedComponents] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "Description",
    direction: ascConst,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [isHistoryPopoverOpen, setHistoryPopoverOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterActivitiesAnchorEl, setFilterActivitiesAnchorEl] =
    useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [pagination, setPagination] = useState();
  const [, onAnchorElChange] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenChangeStateModal, setIsOpenChangeStateModal] = useState(false);
  const [isOpenSaveComponentModal, setIsOpenSaveComponentModal] =
    useState(false);
  const [isOpenNewSaveComponentModal, setIsOpenNewSaveComponentModal] =
    useState(false);
  const [selectedComponentDetail, setSelectedComponentDetail] = useState(null);
  const [parentWorkhours, setParentWorkhours] = useState(null);
  const [parentComponentId, setParentComponentId] = useState(null);
  const [openedComponentsIds, setOpenedComponentsIds] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [expandedIds, setExpandedIds] = useState([]);
  const [, setIsDeleting] = useState(false);
  const [activeComponentActivity, setActiveComponentActivity] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [isOpenSaveActivityModal, setIsOpenSaveActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [activityAnchorEl, setActivityAnchorEl] = useState(null);
  const [isOpenDeleteActivityModal, setIsOpenDeleteActivityModal] =
    useState(false);
  const { appLanguage } = useAppSettings();
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsOnPage, setRowsOnPage] = useState(20);
  const [employeesOptions, setEmployeesOptions] = useState({
    fromDepartment: [],
    other: [],
  });
  const [expandedActivity, setExpandedActivity] = useState(null);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);
  const [activities, setActivities] = useState([]);
  const [sortedComponents, setSortedComponents] = useState([]);
  const [filterTimeTypeOptions, setFilterTimeTypeOptions] = useState([]);
  const [activeTab, setActiveTab] = useState("Components"); 
  const [isUnassignedComponent, setIsUnassignedComponent] = useState(false); 

  const saveSelectedFilters = (filters) => {
    localStorage.setItem("selectedFilters", JSON.stringify(filters));
  };

  const saveSelectedActivitiesFilters = (filters) => {
    localStorage.setItem("selectedActivitiesFilters", JSON.stringify(filters));
  };

  const getSelectedFilters = () => {
    const storedFilters = localStorage.getItem("selectedFilters");
    return storedFilters ? JSON.parse(storedFilters) : [];
  };

  const getSelectedActivitiesFilters = () => {
    const storedActivitiesFilters = localStorage.getItem(
      "selectedActivitiesFilters"
    );
    return storedActivitiesFilters ? JSON.parse(storedActivitiesFilters) : [];
  };

  const [selectedFilters, setSelectedFilters] = useState(getSelectedFilters());

  const [selectedActivitiesFilters, setSelectedActivitiesFilters] = useState(
    getSelectedActivitiesFilters()
  );

  useEffect(() => {
    saveSelectedFilters(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    saveSelectedActivitiesFilters(selectedActivitiesFilters);
  }, [selectedActivitiesFilters]);

  const selectedFilterCount =
    Object.values(selectedFilters).filter(Boolean).length;

  const selectedActivitiesFilterCount = Object.values(
    selectedActivitiesFilters
  ).filter(Boolean).length;

  const handleStateListClick = (event, compModel) => {
    setSelectedComponent(compModel);
    setAnchorEl(event.currentTarget);
  };

  const handleCheckboxFilterChange = (componentState) => {
    setSelectedFilters((prevFilters) => {
      const isSelected = prevFilters.includes(componentState);
      if (isSelected) {
        return prevFilters.filter((filter) => filter !== componentState);
      } else {
        return [...prevFilters, componentState];
      }
    });
  };

  const handleStateItemClick = (stateId, stateName) => {
    handleCloseStateList();
    setSelectedStateId(stateId);
    setSelectedStateName(stateName);
    changeComponentState(selectedComponent?.id, stateId);
  };

  const handleCloseStateList = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "state-popover" : undefined;
  const states = selectedFilters;

  const componentsPagedRequest = useUnassignedComponentsWithPaging(
    currentCompany?.id,
    isSuperUser,
    {
      sortType: sortConfig.key,
      isAsc: sortConfig.direction === ascConst,
      pageSize,
      pageNumber,
      searchTerm,
      states,
    }
  );

  useEffect(() => {
    setSearchTerm("");
  }, [activeTab]);
  
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setSearchTerm("");
  };

  useEffect(() => {
    if (componentsPagedRequest.isSuccess && componentsPagedRequest.data) {
      setPagination({
        pageCount: componentsPagedRequest?.data?.data?.pageCount || 0,
        itemsCount: componentsPagedRequest?.data?.data?.itemsCount || 0,
        itemsTotalCount:
          componentsPagedRequest?.data?.data?.itemsTotalCount || 0,
        pageNumber: componentsPagedRequest?.data?.data?.pageNumber || 0,
        pageSize: componentsPagedRequest?.data?.data?.pageSize || 0,
      });

      let sortedComponents = componentsPagedRequest?.data?.data?.items || [];

      if (sortConfig.customSort && sortConfig.key === "State") {
        sortedComponents.sort((a, b) => customSort(a.state, b.state));
        if (sortConfig.direction === descConst) {
          sortedComponents.reverse();
        }
      }
      setUnassignedComponents(sortedComponents);
      setIsLoading(false);
      setOpenedComponentsIds(
        componentsPagedRequest?.data?.data?.expandedIds || []
      );
      if (expandedIds?.length > 0) {
        setOpenedComponentsIds(expandedIds);
      }
    }
  }, [
    componentsPagedRequest.isSuccess,
    componentsPagedRequest.data,
    sortConfig,
    expandedIds,
  ]);

  const componentDetailsRequest = useComponent(selectedComponent?.id);

  useEffect(() => {
    if (componentDetailsRequest.isSuccess && componentDetailsRequest.data) {
      setSelectedComponentDetail(componentDetailsRequest.data.data);
    }
  }, [
    componentDetailsRequest.isSuccess,
    componentDetailsRequest.data,
    setUnassignedComponents,
  ]);

  useEffect(() => {
    setPageTitle(translation.unassignedComponentsPageLabel);
  }, [setPageTitle, translation]);

  useEffect(() => {
    async function fetchComponentStates() {
      if (currentCompany?.id) {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`,
          {
            headers: isSuperUser ? { CompanyId: currentCompany.id } : {},
          }
        );

        if (componentStatesRequest.data) {
          setStatesList(componentStatesRequest.data);
        }
      } else {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`
        );
        if (componentStatesRequest.data) {
          setStatesList(componentStatesRequest?.data);
        }
      }
    }

    fetchComponentStates();
  }, [currentCompany?.id, isSuperUser]);

  const onDeleteComponent = () => {
    setIsDeleting(true);
    MmsService.deleteUnassignedComponent(selectedComponent.id)
      .then((res) => {
        if (res.data.isSuccessful) {
          AlertToastr.showAlert(res.data.message);
          if (res.data.item) {
            AlertToastr.showAlert(translation.notificationsComponentDeleted);
            handleClosePopover(true);
          }
          queryClient.invalidateQueries(["unassigned"]).then();
          setRunComponentsUpdate(Math.random());
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      });
  };

  const changeComponentState = (stateId, stateName) => {
    MmsService.updateUnassignedComponentState(stateId, stateName)
      .then((res) => {
        if (res.data.isSuccessful) {
          queryClient.invalidateQueries("unassigned");
          AlertToastr.showAlert("Component state successfully updated");
        }
      })

      .catch((error) => {
        // if (
        //   error &&
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.message
        // )

        AlertToastr.showErrorAlert(
          "User is not authorized to update state component to catalog!"
        );
      });
  };

  useEffect(() => {
    const onEvent = () => {
      setIsOpenNewSaveComponentModal(true);
    };

    const listener = EventEmitter.addListener(
      "CreateUnassignedComponent",
      onEvent
    );
    return () => {
      listener.remove();
    };
  }, []);

  const closeMenu = () => {
    onAnchorElChange(null);
  };

  const onNextPage = () => {
    setPageNumber((p) => p + 1);
    window.scrollTo(0, 0);
  };

  const onPrevPage = () => {
    setPageNumber((p) => p - 1);
    window.scrollTo(0, 0);
  };

  const handleRowCount = (count) => {
    setPageSize(count);
    setPageNumber(1);
    closeMenu();
  };

  const renderEmptyListText = () => {
    if (unassignedComponents.length) return null;
    return (
      <div className="emptyInfoHolder">
        <p className="textGray text-center">
          {translation.activitiesPagePartsEmptyListPlaceholderLabel}
        </p>
      </div>
    );
  };

  const handleOpenPopover = (event, componentId) => {
    const selectedCompModel = components.find(
      (compModel) => compModel.id === componentId
    );
    setSelectedComponent(selectedCompModel);
    setPopoverAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  // const handleShowHistory = () => {
  //   setPopoverOpen(false);
  //   setHistoryPopoverOpen(true);
  // };

  const listTree = useMemo(() => {
    const list = flattenTree(unassignedComponents, "subComponents");
    if (list.length) list.shift();
    return list;
  }, [unassignedComponents]);

  const expandedList = useMemo(() => {
    return listTree.filter(
      (item) =>
        item.nestingLevel === 1 ||
        openedComponentsIds.includes(item.id) ||
        openedComponentsIds.includes(item.parentComponentId)
    );
  }, [listTree, openedComponentsIds]);

  const selectedUnassignedComponentsIds = useMemo(() => {
    if (expandedList.length)
      return expandedList
        .filter((item) => !item.parentComponentId && item.selected)
        .map((item) => item.id);
    return [];
  }, [expandedList]);

  useEffect(() => {
    setSelectedUnassignedComponentsIds(selectedUnassignedComponentsIds);
  }, [selectedUnassignedComponentsIds, setSelectedUnassignedComponentsIds]);

  const handleRowClicked = (compModel) => {
    setOpenedComponentsIds((prevIds) => {
      const clickedItemIndex = listTree.findIndex(
        (item) => item.id === compModel.id
      );
      if (clickedItemIndex === -1) return prevIds;

      const item = listTree[clickedItemIndex];

      if (item.nestingLevel === 1) {
        return prevIds.includes(item.id) ? [] : [item.id];
      }

      const deeperIds = listTree
        .filter((i) => i.nestingLevel < item.nestingLevel)
        .map((i) => i.id);

      return prevIds.includes(item.id)
        ? prevIds.filter((i) => deeperIds.includes(i))
        : [...prevIds.filter((i) => deeperIds.includes(i)), item.id];
    });
    setSelectedComponent(compModel);
  };

  const componentList = useMemo(() => {
    return unassignedComponents;
  }, [unassignedComponents]);

  const handleAllSelectedChange = (checked) => {
    const newComponents = unassignedComponents.map((c) => {
      if (!c.parentComponentId) c.selected = checked;
      return c;
    });
    setUnassignedComponents([...newComponents]);
  };

  const handleAllSelectedActivitiesChange = (checked) => {
    const newActivities = activities.map(c => {
      c.selected = checked
      return c
    })
    setActivities([...newActivities])
  }

  const handleSelectedIdChange = (componentId, checked) => {
    unassignedComponents.find((c) => c.id === componentId).selected = checked;
    setUnassignedComponents([...unassignedComponents]);
  };

  const handleSelectedActivitiesIdChange = (activityId, checked) => {
    activities.find(c => c.id === activityId).selected = checked
    setActivities([...activities])
  }

  const handleSelectedShowActivityChange = (componentId) => {
    setActiveComponentActivity((prevComponentId) =>
      prevComponentId === componentId ? null : componentId
    );
    // if (isCatalog)
    //   setComponentsCatalog([...componentList])
    // else
    setUnassignedComponents([...componentList]);
  };

  const onDeleteActivity = () => {
    MmsService.deleteActivity(selectedActivity.id).then(res => {
      if (res.data.isSuccessful) {
        fetchActivities().then()
        AlertToastr.showAlert(translation.notificationsActivityDeleted)
      }
    })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.message)
          AlertToastr.showErrorAlert(error.response.data.message)
      })
  }

  useEffect(() => {
    const fetchOptions = async () => {
      let typeResponse = await MmsService.getTypesOptions(appLanguage);
      setTypeOptions(typeResponse?.data);
      let priorityResponse = await MmsService.getPriorityOptions(appLanguage);
      setPriorityOptions(priorityResponse?.data);
    };
    fetchOptions();
  }, [appLanguage]);

  const editComponent = (component) => {
    const parentComponent = componentList.find(
      (c) => c.id === component?.parentComponentId
    );
    setParentWorkhours(parentComponent ? parentComponent.workhoursTotal : 0);
    setPopoverAnchorEl(null);
    setIsOpenSaveComponentModal(true);
  };

  const customSort = (a, b) => {
    const order = [
      "readyToUse",
      "removed",
      "toBeChecked",
      "toBeRepaired",
      "disposed",
    ];
    return order.indexOf(a) - order.indexOf(b);
  };

  const requestSort = (key) => {
    let direction = ascConst;
    if (sortConfig && sortConfig.key === key) {
      direction = sortConfig.direction === ascConst ? descConst : ascConst;
    }
    if (key === "State") {
      setSortConfig({ key, direction, customSort: true });
    } else {
      setSortConfig({ key, direction });
    }
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return;
    }
    if (sortConfig.direction)
      return sortConfig.direction === ascConst
        ? arrowDownClassName
        : arrowUpClassName;
  };

  const getStatusMessage = () => {
    switch (selectedComponent?.state) {
      case "fieldReady":
        return "Field ready";
      case "toRepair":
        return "To repair";
      case "toCheck":
        return "To check";
      case "installed":
        return "Installed";
      case "disposed":
        return "Disposed";
      case "removed":
        return "Removed";
      default:
        return "";
    }
  };

  const updateList = useCallback(() => {
    queryClient.invalidateQueries(["unassigned"]).then();
  }, [queryClient]);

  const handleSuccessSave = (newComponent) => {
    setOpenedComponentsIds((prevIds) => {
      const componentIds = [
        newComponent.id,
        ...newComponent.subComponents.map((sub) => sub.id),
      ];
      return [...prevIds, ...componentIds];
    });
    if (newComponent.parentComponentId) {
      setUnassignedComponents((prevComponents) => {
        const updateComponent = (
          components,
          parentComponentId,
          newComponent
        ) => {
          return components.map((component) => {
            if (component.id === parentComponentId) {
              const subComponents = [...component.subComponents, newComponent];
              return { ...component, subComponents };
            }
            if (component.subComponents.length > 0) {
              const updatedSubComponents = updateComponent(
                component.subComponents,
                parentComponentId,
                newComponent
              );
              return { ...component, subComponents: updatedSubComponents };
            }
            return component;
          });
        };

        return updateComponent(
          prevComponents,
          newComponent.parentComponentId,
          newComponent
        );
      });
    } else {
      setUnassignedComponents((prevComponents) => [
        ...prevComponents,
        newComponent,
      ]);
    }
    setUnassignedComponents(null);
  };

  const emptyStatusOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.filterActivitiesModalSelectStatusLabel,
    };
  }, [translation]);
  const emptyDateRangeOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.filterActivitiesModalSelectDateRangeLabel,
    };
  }, [translation]);
  const emptyComponentOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.filterActivitiesModalSelectComponentLabel,
    };
  }, [translation]);
  const emptyPriorityOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.filterActivitiesModalSelectPriorityLabel,
    };
  }, [translation]);
  const emptyGuidAssigneeOption = useMemo(() => {
    return {
      id: "00000000-0000-0000-0000-000000000000",
      name: translation.filterActivitiesModalSelectAssigneeLabel,
    };
  }, [translation]);

  const BlankFilterData = useMemo(() => {
    return {
      statusId: emptyStatusOption.id,
      dateRangeId: emptyDateRangeOption.id,
      componentId: emptyComponentOption.id,
      priorityId: emptyPriorityOption.id,
      assigneeId: emptyGuidAssigneeOption.id,
    };
  }, [
    emptyStatusOption.id,
    emptyDateRangeOption.id,
    emptyComponentOption.id,
    emptyPriorityOption.id,
    emptyGuidAssigneeOption.id,
  ]);

  const fetchActivities = useCallback(async () => {
    try {
      const selectedActivitiesFilters =
        JSON.parse(
          localStorage.getItem(
            `selectedActivitiesFilters_${currentDepartment.id}`
          )
        ) || BlankFilterData;
      const response = await MmsService.getFiltredUcActivities(
        currentDepartment.id,
        selectedActivitiesFilters
      );
      setIsLoading(false);
      const activityData = response.data.map((activity) => {
        return {
          ...activity,
          tasks: activity.tasks.map((task) => {
            return {
              ...task,
              newPartReplacedQuantity: task.partReplacedQuantity
                ? task.partReplacedQuantity
                : task.quantity,
              partReplacedQuantity: task.partReplacedQuantity
                ? task.partReplacedQuantity
                : task.quantity,
              partReplacedId: task.partReplacedId
                ? task.partReplacedId
                : task.partId
                ? task.partId
                : task.alternativePartId,
            };
          }),
        };
      });
      setActivities(activityData);

      localStorage.setItem(
        `selectedActivitiesFilters_${currentDepartment.id}`,
        JSON.stringify(selectedActivitiesFilters)
      );
    } catch (error) {
      setIsLoading(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      )
        AlertToastr.showErrorAlert(error.response.data.message);
    }
  }, [currentDepartment, setActivities, BlankFilterData]);

  useEffect(() => {
    if (!currentDepartment) return;
    fetchActivities().then();
  }, [currentDepartment, fetchActivities]);

  const getDate = (activity) => {
    return !!(activity && activity.expectedCompleteDate)
      ? moment(activity.expectedCompleteDate).format("L")
      : "";
  };

  const departmentEmployees = useMemo(() => {
    if (employees && currentDepartment)
      return employees.filter((e) => e.departmentId === currentDepartment.id);
    else return null;
  }, [employees, currentDepartment]);

  const getComponentNameById = useCallback(
    (componentId) =>
      components && componentId && components.find((x) => x.id === componentId)
        ? components.find((x) => x.id === componentId).fullName
        : "",
    [components]
  );

  const getEmployeeNameById = useCallback(
    (employeeId) =>
      employeeId &&
      departmentEmployees &&
      departmentEmployees.find((x) => x.id === employeeId)
        ? departmentEmployees.find((x) => x.id === employeeId).name
        : "",
    [departmentEmployees]
  );

  useEffect(() => {
    const currentDep = currentDepartment?.id;

    if (currentDep) {
      const getComponents = async (currentDep) => {
        try {
          const response = await Api.get(
            `${apiMmsBaseUrl}/components/by-departmentid/${currentDep}/short?sortBy=id`
          );
          setSortedComponents(response.data);
        } catch (e) {
          throw e;
        }
      };

      getComponents(currentDep);
    }
  }, [currentDepartment]);

  const sortedActivities = useMemo(() => {
    if (!activities) return [];
    let sortedActivities = [...activities];
    if (!sortConfig || !sortConfig.key) return sortedActivities;

    if (sortConfig.key === "component") {
      sortedActivities.sort((a, b) => {
        if (
          getComponentNameById(a.componentId) <
          getComponentNameById(b.componentId)
        ) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (
          getComponentNameById(a.componentId) >
          getComponentNameById(b.componentId)
        ) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortConfig.key === "priority") {
      sortedActivities.sort((a, b) => {
        if (
          priorityOptions.findIndex((x) => x.priority === a.priority) <
          priorityOptions.findIndex((x) => x.priority === b.priority)
        ) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (
          priorityOptions.findIndex((x) => x.priority === a.priority) >
          priorityOptions.findIndex((x) => x.priority === b.priority)
        ) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortConfig.key === "tasks") {
      sortedActivities.sort((a, b) => {
        if (a.tasks.length < b.tasks.length) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (a.tasks.length > b.tasks.length) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortConfig.key === "assigned") {
      sortedActivities.sort((a, b) => {
        if (
          getEmployeeNameById(a.assigneeId) < getEmployeeNameById(b.assigneeId)
        ) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (
          getEmployeeNameById(a.assigneeId) > getEmployeeNameById(b.assigneeId)
        ) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortConfig.key === "date") {
      sortedActivities.sort((a, b) => {
        if (moment(getDate(a)).isAfter(getDate(b))) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (moment(getDate(b)).isAfter(getDate(a))) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortConfig.key === "workhours") {
      sortedActivities.sort((a, b) => {
        if (
          (a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) <
          (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)
        ) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (
          (a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) >
          (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)
        ) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortConfig.key === "status") {
      sortedActivities.sort((a, b) => {
        if (
          statusOptions.findIndex((x) => x.status === a.status) <
          statusOptions.findIndex((x) => x.status === b.status)
        ) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (
          statusOptions.findIndex((x) => x.status === a.status) >
          statusOptions.findIndex((x) => x.status === b.status)
        ) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    sortedActivities.sort((a, b) => {
      if (a[sortConfig.key]?.toLowerCase() < b[sortConfig.key]?.toLowerCase()) {
        return sortConfig.direction === ascConst ? -1 : 1;
      }
      if (a[sortConfig.key]?.toLowerCase() > b[sortConfig.key]?.toLowerCase()) {
        return sortConfig.direction === ascConst ? 1 : -1;
      }
      return 0;
    });
    return sortedActivities;
  }, [
    activities,
    sortConfig,
    getComponentNameById,
    getEmployeeNameById,
    priorityOptions,
    statusOptions,
  ]);

  const emptyAssigneeOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.filterActivitiesModalNoAssigneeLabel,
    };
  }, [translation]);

  const filteredActivities = useMemo(() => {
    if (!searchTerm) return activities;
    return activities.filter((p) => {
      const name = p.name?.toLowerCase();
      const component = components?.find((x) => x.id === p.componentId);
      const componentName = component ? component.fullName?.toLowerCase() : "";
      return (
        name?.includes(searchTerm.toLowerCase()) ||
        componentName?.includes(searchTerm.toLowerCase())
      );
    });
  }, [activities, components, searchTerm]);

  const pageData = useMemo(() => {
    let pageData = searchTerm ? [...filteredActivities] : [...sortedActivities];
    const totalLength = pageData.length;

    if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
      setPageNumber(1);
    }
    setTotalRows(totalLength);
    setFirstIndex(Math.min((pageNumber - 1) * rowsOnPage + 1, totalLength));
    setLastIndex(Math.min(pageNumber * rowsOnPage, totalLength));
    let tableData = pageData.splice(
      (pageNumber - 1) * rowsOnPage,
      Math.min(pageNumber * rowsOnPage, totalLength)
    );
    return [...tableData];
  }, [
    sortedActivities,
    rowsOnPage,
    pageNumber,
    searchTerm,
    filteredActivities,
  ]);

  const handleSaveAssignee = (employeeId) => {
    selectedActivity.assigneeId = employeeId;
    setActivities([...activities]);
  };

  const handlePopoverClick = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const [filter, setFilter] = useState(BlankFilterData);

  useEffect(() => {
    const storedFilters =
      JSON.parse(
        localStorage.getItem(
          `selectedActivitiesFilters_${currentDepartment?.id}`
        )
      ) || BlankFilterData;
    setFilter(storedFilters);
    setSelectedActivitiesFilters({
      status:
        storedFilters.statusId !== emptyStatusOption.id
          ? storedFilters.statusId
          : null,
      dateRange:
        storedFilters.dateRangeId !== emptyDateRangeOption.id
          ? storedFilters.dateRangeId
          : null,
      component:
        storedFilters.componentId !== emptyComponentOption.id &&
        components !== null
          ? components.find((c) => c.id === storedFilters.componentId) || null
          : null,

      priority:
        storedFilters.priorityId !== emptyPriorityOption.id
          ? storedFilters.priorityId
          : null,
      assignee:
        storedFilters.assigneeId !== emptyGuidAssigneeOption.id
          ? storedFilters.assigneeId
          : null,
    });
  }, [
    currentDepartment,
    BlankFilterData,
    emptyStatusOption.id,
    emptyDateRangeOption.id,
    emptyComponentOption.id,
    emptyPriorityOption.id,
    emptyGuidAssigneeOption.id,
    components,
  ]);

  const employeesByDepartmentIdRequest = useEmployeesByDepartmentId(
    currentDepartment?.id
  );

  useEffect(() => {
    if (employeesByDepartmentIdRequest.isSuccess) {
      setEmployeesOptions(employeesByDepartmentIdRequest.data.data);
    }
  }, [
    employeesByDepartmentIdRequest.isSuccess,
    employeesByDepartmentIdRequest.data,
  ]);

  useEffect(() => {
    const fetchFilterDropdownsOptions = async (lang) => {
      let priorityResponse = await MmsService.getPriorityOptions(lang);
      setPriorityOptions(priorityResponse.data);
      let filterTimeTypeResponse = await MmsService.getFilterTimeTypeOptions(
        lang
      );
      setFilterTimeTypeOptions(filterTimeTypeResponse.data);
    };
    if (!currentDepartment) return;
    fetchFilterDropdownsOptions(appLanguage);
  }, [currentDepartment, appLanguage]);

  const resetFilter = () => {
    setFilter(BlankFilterData);
    setSelectedActivitiesFilters({
      status: null,
      dateRange: null,
      component: null,
      priority: null,
      assignee: null,
    });
  };

  const applyFilter = useCallback(async () => {
    if (!currentDepartment) return;
    localStorage.setItem(
      `selectedActivitiesFilters_${currentDepartment.id}`,
      JSON.stringify(filter)
    );

    setFilterActivitiesAnchorEl(false);
    document.body.classList.remove("view-scroll");
    setIsLoading(true);
    const response = await MmsService.getFiltredUcActivities(
      currentDepartment.id,
      filter
    );
    setIsLoading(false);
    setActivities(response.data);
  }, [filter, currentDepartment, setActivities]);

  return (
    <>
      <div className="pmPageHolder">
        <div className="pmPageBlock">
          <div className="pmPageBlockTitle">
            {translation.unassignedComponentsPageLabel}
          </div>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            className="pmTabs mb15"
          >
            <Tab
              disableRipple
              label="Components"
              onClick={() => handleTabChange("Components")}
              className={activeTab === "Components" ? "activeSubTitle" : undefined}
            />
            <Tab
              disableRipple
              label="Activities"
              onClick={() => handleTabChange("Activities")}
              className={activeTab === "Activities" ? "activeSubTitle" : undefined}
            />
          </Tabs>
          <div className="subTitleWithFilter mb15">
            <SearchInput key={activeTab} search={searchTerm} onChange={setSearchTerm}/>
            <p className="textGray"></p>
            {activeTab === "Components" ? (
              <div className="filter mr15">
                <Button
                  variant={"contained"}
                  color="primary"
                  id="ucFilterBtn"
                  onClick={(e) => {
                    setFilterAnchorEl(e.currentTarget);
                    document.body.classList.add("view-scroll");
                  }}
                  startIcon={
                    selectedFilterCount > 0 ? (
                      <div>
                        <span className="filterCounter">
                          {selectedFilterCount}
                        </span>
                      </div>
                    ) : (
                      <TuneIcon />
                    )
                  }
                >
                  {translation.filterButtonLabel}
                </Button>
                <Popover
                  anchorEl={filterAnchorEl}
                  classes={{
                    paper: "filterPopover",
                  }}
                  open={Boolean(filterAnchorEl)}
                  onClose={() => {
                    setFilterAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {statesList
                    .filter((state) => state.componentState !== "removed")
                    .map((state, index) => (
                      <div className="filterPopoverItem mb16" key={index}>
                        <Checkbox
                          id="ucFilterCheckboxBtn"
                          className="filterCheckbox"
                          checked={selectedFilters?.includes(
                            state.componentState
                          )}
                          onChange={() =>
                            handleCheckboxFilterChange(state.componentState)
                          }
                        />
                        <MenuItem
                          className={`state-item ${state.componentState}`}
                        >
                          {state.name}
                        </MenuItem>
                      </div>
                    ))}
                </Popover>
              </div>
            ) : (
              <div className="filter mr15">
                <Button
                  variant={"contained"}
                  color="primary"
                  id="activitiesFilterBtn"
                  onClick={(e) => {
                    setFilterActivitiesAnchorEl(e.target);
                    document.body.classList.add("view-scroll");
                  }}
                  startIcon={
                    selectedActivitiesFilterCount > 0 ? (
                      <div>
                        <span className="filterCounter">
                          {selectedActivitiesFilterCount}
                        </span>
                      </div>
                    ) : (
                      <TuneIcon />
                    )
                  }
                >
                  {translation.filterButtonLabel}
                </Button>
              </div>
            )}
          </div>
          <div className="pmTableHolder">
            {activeTab === "Components" ? (
              <table className="pmTable">
                <thead>
                  <tr>
                    <th className="checkBoxCell">
                      <Checkbox
                        checked={
                          unassignedComponents
                            ? unassignedComponents
                                .filter((c) => !c.parentComponentId)
                                .every((c) => c.selected)
                            : false
                        }
                        onChange={(e) =>
                          handleAllSelectedChange(e.target.checked)
                        }
                        color="default"
                        id="ucTblCheckBoxAllRows"
                        size="small"
                      />
                    </th>
                    <th>{translation.unassignedComponentsPageNameHeader}</th>
                    <th>{translation.unassignedComponentsPageUidHeader}</th>
                    <th>
                      {translation.unassignedComponentsPageWorkhoursHeader}
                    </th>
                    <th>
                      {translation.unassignedComponentsPageLastInstallHeader}
                    </th>
                    <th
                      // onClick={() => requestSort("Activities")}
                      // className={getClassNamesFor("Activities")}
                      id="assetsTblHeaderActivities"
                    >
                      {translation.equipmentPageMyEquipmentActivitiesHeader}
                    </th>
                    <th
                      onClick={() => requestSort("State")}
                      className={getClassNamesFor("State")}
                    >
                      {translation.unassignedComponentsPageStateHeader}
                    </th>
                    <th id="ucTblHeaderWorkhours">
                      {translation.unassignedComponentsPageActionHeader}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {unassignedComponents.length > 0
                    ? expandedList.map((compModel) => {
                        return (
                          <Fragment key={compModel.id}>
                            <tr
                              className={
                                compModel.parentComponentId ? "innerRow" : ""
                              }
                            >
                              <td className="checkBoxCell p0">
                                {!compModel.parentComponentId && (
                                  <Checkbox
                                    checked={
                                      compModel.selected
                                        ? compModel.selected
                                        : false
                                    }
                                    id="ucTblCheckBoxAsset"
                                    disabled={compModel.isDeleting}
                                    onChange={(e) =>
                                      handleSelectedIdChange(
                                        compModel.id,
                                        e.target.checked
                                      )
                                    }
                                    color="default"
                                    size="small"
                                  />
                                )}
                              </td>
                              <td
                                className={
                                  compModel.isDeleting
                                    ? "deletedDepartment text-semi-bold p0"
                                    : "text-semi-bold p0"
                                }
                                onClick={() => handleRowClicked(compModel)}
                              >
                                {compModel.parentComponentId &&
                                compModel.nestingLevel > 1 ? (
                                  <div
                                    className="hasInnerRowIcon slide-in-from-top"
                                    style={{
                                      paddingLeft: compModel.nestingLevel * 5,
                                      paddingTop: "8px",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    <div className="icoInnerRow" />
                                    <div className="caption">{`${compModel.name}`}</div>
                                  </div>
                                ) : (
                                  <div className="caption">
                                    {compModel.parentComponentId
                                      ? `${compModel.name}`
                                      : `${compModel.name} `}
                                  </div>
                                )}
                              </td>

                              <td
                                onClick={() => handleRowClicked(compModel)}
                                className={
                                  compModel.isDeleting
                                    ? 'deletedDepartment p0"'
                                    : "p0"
                                }
                              >
                                {compModel.uId}
                              </td>
                              <td
                                className={
                                  compModel.isDeleting
                                    ? 'deletedDepartment p0"'
                                    : "p0"
                                }
                              >
                                <EquipmentInlineWorkhoursForm
                                  model={compModel}
                                  disabled={compModel.isDeleting}
                                  isUnassignedComponent
                                  setExpandedIds={setExpandedIds}
                                  onSuccessUpdate={() => {
                                    queryClient
                                      .invalidateQueries(["unassigned"])
                                      .then();
                                  }}
                                />
                              </td>
                              <td
                                className={
                                  compModel.isDeleting
                                    ? 'deletedDepartment p0"'
                                    : "p0"
                                }
                              >
                                {compModel.lastInstalledAssetName}
                              </td>
                              <td
                                className={
                                  compModel.isDeleting
                                    ? "deletedDepartment textBtnCell"
                                    : "textBtnCell"
                                }
                              >
                                <Button
                                  className="activitiesItem"
                                  disabled={compModel.isDeleting}
                                  id="ucTblActivityShowMenuBtn"
                                  onClick={() =>
                                    handleSelectedShowActivityChange(
                                      compModel.id
                                    )
                                  }
                                >{`${
                                  translation.equipmentPageMyEquipmentActivitiesLabel
                                } (${
                                  compModel.activities
                                    ? compModel.activities.length
                                    : 0
                                })`}</Button>
                              </td>
                              {!compModel.parentComponentId ? (
                                <td
                                  className={
                                    compModel.isDeleting
                                      ? 'deletedDepartment p0"'
                                      : "p0"
                                  }
                                >
                                  <Button
                                    onClick={(event) =>
                                      handleStateListClick(event, compModel)
                                    }
                                    aria-describedby={popoverId}
                                    id="ucTblShowStatesBtn"
                                    disabled={
                                      compModel.parentComponentId ||
                                      compModel.isDeleting
                                    }
                                    className={
                                      compModel.parentComponentId
                                        ? `state-item ${compModel.state}`
                                        : `state-item-table ${compModel.state}`
                                    }
                                  >
                                    {statesList.find(
                                      (stateObj) =>
                                        stateObj.componentState ===
                                        compModel.state
                                    )?.name || compModel.state}
                                  </Button>
                                  <Popover
                                    id={popoverId}
                                    classes={{
                                      paper: "statePopover",
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseStateList}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    {statesList.map((state, index) => (
                                      <MenuItem
                                        key={index}
                                        id="ucTblChooseStatesBtn"
                                        onClick={() => {
                                          handleClosePopover();
                                          handleStateItemClick(
                                            state.componentState,
                                            state.name
                                          );
                                        }}
                                        className={`state-item ${state.componentState} mb16`}
                                      >
                                        {state.name}
                                      </MenuItem>
                                    ))}
                                  </Popover>
                                </td>
                              ) : (
                                <td></td>
                              )}
                              <td className="btnCell">
                                <IconButton
                                  aria-label="delete"
                                  disabled={compModel.isDeleting}
                                  size="small"
                                  className="dropDownBtn mobile"
                                  id="ucTblDropDownBtn"
                                  onClick={(event) => {
                                    handleOpenPopover(event, compModel.id);
                                    setSelectedComponent(compModel);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Popover
                                  id="ucTblPopover"
                                  open={isPopoverOpen}
                                  anchorEl={popoverAnchorEl}
                                  onClose={() => {
                                    handleClosePopover();
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  className="pmPopover equipmentSubMenu"
                                >
                                  {/* <MenuItem
                                  key={"showHistory"}
                                  onClick={() => handleShowHistory()}
                                  id="componentsShowHistoryBtn"
                                  className="componentsMenuItem history"
                                >
                                  Show History
                                </MenuItem>
                                <MenuItem
                                  key={"duplicate"}
                                  onClick={() => {
                                    setIsOpenNewSaveComponentModal(true);
                                    handleClosePopover();
                                  }}
                                  id="assetsTblMenuDublicateBtn"
                                  className="componentsMenuItem duplicate"
                                >
                                  {translation.popoverDuplicateLabel}
                                </MenuItem> */}
                                  <MenuItem
                                    key={"edit"}
                                    id="ucTblSideMenuEditBtn"
                                    onClick={() => {
                                      editComponent(selectedComponent);
                                      handleClosePopover();
                                    }}
                                    className="menuItem edit"
                                  >
                                    {translation.popoverDetailsLabel}
                                  </MenuItem>
                                  <MenuItem
                                    key={"delete"}
                                    id="ucTblSideMenuRemoveBtn"
                                    onClick={() => {
                                      setIsOpenDeleteModal(true);
                                      handleClosePopover();
                                    }}
                                    className="menuItem delete"
                                  >
                                    {translation.popoverRemoveLabel}
                                  </MenuItem>
                                </Popover>
                                <Popover
                                  id="ucHistoryPopover"
                                  open={isHistoryPopoverOpen}
                                  onClose={() => setHistoryPopoverOpen(false)}
                                  anchorEl={popoverAnchorEl}
                                  classes={{
                                    paper: "history-popover",
                                  }}
                                >
                                  <div>
                                    <div className="history-popover-title">
                                      {selectedComponent &&
                                        selectedComponent.name}
                                    </div>
                                    <div className="flex mb16">
                                      <div className="flex">
                                        <div
                                          className={`state-item ${selectedComponent?.state} mb0 mr6`}
                                        >
                                          {getStatusMessage()}
                                        </div>
                                        <div className="history-popover-uId">
                                          {selectedComponent?.uId}
                                        </div>
                                      </div>
                                      <Button
                                        variant={"contained"}
                                        color="primary"
                                        id="ucHistoryFilterBtn"
                                        className="history-popover-filter"
                                        startIcon={<TuneIcon />}
                                      >
                                        {translation.filterButtonLabel}
                                      </Button>
                                    </div>
                                    <div className="flex-line"></div>
                                  </div>
                                </Popover>
                              </td>
                            </tr>
                            {compModel.id === activeComponentActivity && (
                              <tr className="activitiesRow">
                                <td
                                  colSpan="100"
                                  className="activitiesRowContent"
                                >
                                  <ActivitiesTable
                                    activities={compModel.activities}
                                    disabled={compModel.isDeleting}
                                    typeOptions={typeOptions}
                                    priorityOptions={priorityOptions}
                                    setExpandedIds={setExpandedIds}
                                    onAddActivity={() => {
                                      if (!isSuperUser && isCatalog) return;
                                      setSelectedComponent(
                                        componentList.find(
                                          (c) => c.id === compModel.id
                                        )
                                      );
                                      setIsOpenSaveActivityModal(true);
                                      setSelectedComponent(compModel);
                                      setSelectedActivity(null);
                                    }}
                                    onEditActivity={(e, activity) => {
                                      setActivityAnchorEl(e.target);
                                      setSelectedActivity(activity);
                                      setSelectedComponent(
                                        componentList.find(
                                          (c) => c.id === compModel.id
                                        )
                                      );
                                    }}
                                    disableAdd={!isSuperUser && isCatalog}
                                    compModel={compModel}
                                    unassignedComponents={true}
                                  />
                                </td>
                              </tr>
                            )}
                          </Fragment>
                        );
                      })
                    : null}
                </tbody>
              </table>
            ) : (
              <table className="pmTable">
                <thead>
                  <tr>
                    <th className="checkBoxCell">
                      <Checkbox
                        checked={
                          activities
                            ? activities.every((c) => c.selected)
                            : false
                        }
                        onChange={(e) =>
                          handleAllSelectedActivitiesChange(e.target.checked)
                        }
                        id="activitiesTblCheckBoxAllRows"
                        color="default"
                        size="small"
                      />
                    </th>
                    <th
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderDescription"
                      className={getClassNamesFor("name")}
                    >
                      {translation.activitiesNameHeader}
                    </th>
                    <th
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderAsset"
                      className={getClassNamesFor("component")}
                    >
                      {translation.activitiesPageComponentHeader}
                    </th>
                    <th
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderAssignedTo"
                      className={getClassNamesFor("assigned")}
                    >
                      {translation.activitiesPageAssignedToHeader}
                    </th>
                    <th
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderDue"
                      className="textFieldCellDue"
                    >
                      {translation.activitiesPageDueHeader}
                    </th>
                    <th
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderRemaining"
                      className="textFieldCellDue"
                    >
                      {translation.activitiesPageRemainingHeader}
                    </th>
                    <th
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderCompleted"
                      className="textFieldCellDue"
                    >
                      {translation.activitiesPageCompletedHeader}
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => requestSort("")}
                      id="activitiesTblHeaderOperationalStatus"
                      className={getClassNamesFor("status")}
                      colSpan="2"
                    >
                      {translation.activitiesPageStatusHeader}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pageData && pageData.length > 0
                    ? pageData.map((activity) => (
                        <Fragment key={activity.id}>
                          <tr>
                            <td className="checkBoxCell">
                              <Checkbox
                                checked={
                                  activity.selected ? activity.selected : false
                                }
                                onChange={(e) =>
                                  handleSelectedActivitiesIdChange(
                                    activity.id,
                                    e.target.checked
                                  )
                                }
                                color="default"
                                size="small"
                              />
                            </td>
                            <td onClick={(e) => handleRowClicked(activity)}>
                              {activity.name}
                            </td>
                            <td onClick={(e) => handleRowClicked(activity)}>
                              {getComponentNameById(activity.componentId)}
                            </td>
                            {/*<td onClick={(e) => handleRowClicked(activity)}>{typeOptions && typeOptions.find(x => x.activityType === activity.type) ? typeOptions.find(x => x.activityType === activity.type).name : ''}</td>*/}
                            {/*<td onClick={(e) => handleRowClicked(activity)}>{priorityOptions.find(x => x.priority === activity.priority) ? priorityOptions.find(x => x.priority === activity.priority).name : activity.priority}</td>*/}
                            {/*<td onClick={(e) => handleRowClicked(activity)}>{activity.tasks.length}</td>*/}
                            <td
                              className="textFieldCell"
                              onClick={(e) => handleRowClicked(activity)}
                            >
                              <div
                                className="selectField"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {activity.status === "opened" ? (
                                  <AssignPersonButton
                                    department={currentDepartment}
                                    employeesOptions={employeesOptions}
                                    employeeId={activity.assigneeId}
                                    activityId={activity.id}
                                    handleSave={handleSaveAssignee}
                                  />
                                ) : (
                                  getEmployeeNameById(activity.assigneeId)
                                )}
                              </div>
                            </td>
                            {/* <td onClick={(e) => handleRowClicked(activity)}>{getDate(activity)}</td>
                  <td onClick={(e) => handleRowClicked(activity)}>{activity.expectedWorkhours}</td> */}
                            <td className="textFieldCellDue">
                              {activity.due[0]}
                              <br />
                              {activity.due[1]}
                            </td>
                            <td className="textFieldCellDue">
                              {activity.remaining[0]}
                              <br />
                              {activity.remaining[1]}
                            </td>
                            <td className="textFieldCellDue">
                              {activity.completed[0]}
                              <br />
                              {activity.completed[1]}
                            </td>
                            <td onClick={(e) => handleRowClicked(activity)}>
                              <div className="statusItem">
                                {activity.status === "completed" && (
                                  <div className="statusItem open">
                                    {
                                      translation.activitiesPageStatusHeaderCompeted
                                    }
                                  </div>
                                )}
                                {activity.status === "removed" && (
                                  <div className="statusItem off">
                                    {
                                      translation.activitiesPageStatusHeaderRemoved
                                    }
                                  </div>
                                )}
                                {activity.status === "opened" &&
                                  activity.isOverdue &&
                                  !activity.isUpcoming && (
                                    <div className="statusItem overdue">
                                      {
                                        translation.activitiesPageStatusHeaderOverdue
                                      }
                                    </div>
                                  )}
                                {activity.status === "opened" &&
                                  !activity.isOverdue &&
                                  activity.isUpcoming && (
                                    <div className="statusItem upcoming">
                                      {
                                        translation.activitiesPageStatusHeaderUpcoming
                                      }
                                    </div>
                                  )}
                                {activity.status === "opened" &&
                                  activity.isOverdue &&
                                  activity.isUpcoming && (
                                    <div className="statusItem overdue">
                                      {
                                        translation.activitiesPageStatusHeaderOverdue
                                      }
                                    </div>
                                  )}
                                {activity.status === "opened" &&
                                  !activity.isOverdue &&
                                  !activity.isUpcoming && (
                                    <div className="statusItem on">
                                      {
                                        translation.activitiesPageStatusHeaderOpened
                                      }
                                    </div>
                                  )}
                                {/* <div
                                                className="caption">{statusOptions.find(x => x.status === activity.status).name.toUpperCase()}</div> */}
                              </div>
                            </td>
                            <td className="btnCell">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                className="dropDownBtn mobile"
                                id="activitiesTblDropDownBtn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setActivityAnchorEl(e.target);
                                  setSelectedActivity(activity);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </td>
                          </tr>
                          {activity.id === expandedActivity && (
                            <tr className="activitiesInnerTableHolder">
                              <td
                                className="activitiesInnerTableContent"
                                colSpan={100}
                              >
                                <table className="activitiesTaskTable pageTable">
                                  <thead>
                                    <tr>
                                      <th>
                                        {
                                          translation.activitiesPageTasksTableTaskLabel
                                        }
                                      </th>
                                      <th>
                                        {
                                          translation.activitiesPageTasksTablePartToReplaceLabel
                                        }
                                      </th>
                                      <th>
                                        {
                                          translation.activitiesPageAlternativePartLabel
                                        }
                                      </th>
                                      <th>
                                        {
                                          translation.activitiesPageTasksTableQuantityLabel
                                        }
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {activity.tasks.map((task) => {
                                      const partData =
                                        parts &&
                                        parts.find((x) => x.id === task.partId);
                                      let alternativePart = "-";
                                      if (
                                        partData &&
                                        partData.alternativePartId
                                      ) {
                                        const alternativePartModel = parts.find(
                                          (x) =>
                                            x.id === partData.alternativePartId
                                        );
                                        alternativePart =
                                          alternativePartModel.fullName;
                                      }

                                      return (
                                        <tr key={task.id}>
                                          <td>
                                            <div
                                              className={`taskNameHolder ${
                                                task.isCompleted
                                                  ? "completed"
                                                  : ""
                                              }`}
                                            >
                                              <div className="name">
                                                {task.taskName}
                                              </div>
                                              <div className="infoHolder">
                                                <div
                                                  className="icoHolder"
                                                  onClick={handlePopoverClick}
                                                />
                                                <div className="popoverHolder">
                                                  <Popover
                                                    open={openPopover}
                                                    anchorEl={anchorElPopover}
                                                    onClose={handlePopoverClose}
                                                    anchorOrigin={{
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                      vertical: "top",
                                                      horizontal: "left",
                                                    }}
                                                    elevation={1}
                                                    className="pmPopover equipmentSubMenu activitiesTaskPopover"
                                                  >
                                                    <div className="title mb5">
                                                      {
                                                        translation.activitiesPageAlternativePartLabel
                                                      }
                                                    </div>
                                                    <div className="">
                                                      {parts &&
                                                      parts.find(
                                                        (x) =>
                                                          x.id ===
                                                          task.alternativePartId
                                                      )
                                                        ? parts.find(
                                                            (x) =>
                                                              x.id ===
                                                              task.alternativePartId
                                                          ).name
                                                        : "-"}
                                                    </div>
                                                  </Popover>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="taskNameHolder">
                                              <div className="name">
                                                {parts &&
                                                parts.find(
                                                  (x) => x.id === task.partId
                                                )
                                                  ? parts.find(
                                                      (x) =>
                                                        x.id === task.partId
                                                    ).fullName
                                                  : "-"}
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="taskNameHolder">
                                              <div className="name">
                                                {alternativePart}
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="taskNameHolder">
                                              <div className="name">
                                                {task.quantity}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ))
                    : null}
                </tbody>
              </table>
            )}
            {renderEmptyListText()}
            {isLoading ? (
              <div className="loaderHolder">
                <Fade in={isLoading} unmountOnExit>
                  <CircularProgress />
                </Fade>
              </div>
            ) : null}
            {unassignedComponents && unassignedComponents.length > 0 && (
              <TablePagination
                pagination={pagination}
                onPrevPage={onPrevPage}
                onNextPage={onNextPage}
                handleRowCount={handleRowCount}
                // loading={componentsPagedRequest.isLoading}
              />
            )}
            <Popover
              anchorEl={activityAnchorEl}
              id="ucTblActivitySideMenuBtn"
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              disableScrollLock={true}
              open={Boolean(activityAnchorEl)}
              onClose={() => setActivityAnchorEl(null)}
              className="pmPopover equipmentSubMenu"
            >
              <MenuItem
                disabled={
                  selectedActivity && selectedActivity.status !== "opened"
                }
                key={"edit"}
                id="ucTblActivitySideMenuDetailsBtn"
                onClick={() => {
                  setActivityAnchorEl(null);
                  setIsOpenSaveActivityModal(true);
                }}
                className="menuItem edit"
              >
                {translation.popoverDetailsLabel}
              </MenuItem>
              <MenuItem
                key={"delete"}
                id="ucTblActivitySideMenuRemoveBtn"
                onClick={() => {
                  setIsOpenDeleteActivityModal(true);
                  setActivityAnchorEl(null);
                }}
                className="menuItem delete"
              >
                {translation.popoverRemoveLabel}
              </MenuItem>
            </Popover>
            <Popover
              anchorEl={filterActivitiesAnchorEl}
              keepMounted
              open={Boolean(filterActivitiesAnchorEl)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              onClose={(e) => {
                setFilterActivitiesAnchorEl(false);
                document.body.classList.remove("view-scroll");
              }}
            >
              <div className="activitiesFilterHolder respModal">
                <div className="filterHeading">
                  <div className="pmPageBlockTitle">
                    {translation.filterActivitiesModalLabel}
                  </div>
                </div>
                {filter && (
                  <div className="formHolder">
                    <TextField
                      value={filter.statusId}
                      onChange={(e) => {
                        setFilter({ ...filter, statusId: e.target.value });
                        if (e.target.value !== emptyStatusOption.id) {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            status: e.target.value,
                          });
                        } else {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            status: null,
                          });
                        }
                      }}
                      label={translation.filterActivitiesModalStatusLabel}
                      variant="outlined"
                      fullWidth
                      select
                      margin="normal"
                      id="activitiesFilterChangeStatus"
                    >
                      <MenuItem
                        key={emptyStatusOption.id}
                        className="filterMenuItem"
                        value={emptyStatusOption.id}
                      >
                        {emptyStatusOption.name}
                      </MenuItem>
                      {statusOptions &&
                        statusOptions.map((statusOption) => (
                          <MenuItem
                            key={statusOption.uiStatus}
                            className="filterMenuItem"
                            id="activitiesFilterChooseStatus"
                            value={statusOption.uiStatus}
                          >
                            {statusOption.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      value={filter.dateRangeId}
                      onChange={(e) => {
                        setFilter({ ...filter, dateRangeId: e.target.value });
                        if (e.target.value !== emptyDateRangeOption.id) {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            dateRangeId: e.target.value,
                          });
                        } else {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            dateRangeId: null,
                          });
                        }
                      }}
                      label={translation.filterActivitiesModalDateRangeLabel}
                      variant="outlined"
                      fullWidth
                      select
                      margin="normal"
                      id="activitiesFilterChangeDateRange"
                    >
                      <MenuItem
                        key={emptyDateRangeOption.id}
                        className="filterMenuItem"
                        value={emptyDateRangeOption.id}
                      >
                        {emptyDateRangeOption.name}
                      </MenuItem>
                      {filterTimeTypeOptions &&
                        filterTimeTypeOptions.map((filterTimeTypeOption) => (
                          <MenuItem
                            key={filterTimeTypeOption.filterDateType}
                            id="activitiesFilterChooseDateRange"
                            className="filterMenuItem"
                            value={filterTimeTypeOption.filterDateType}
                          >
                            {filterTimeTypeOption.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <Autocomplete
                      options={[
                        {
                          label:
                            translation.filterActivitiesModalSelectComponentLabel,
                        },
                        ...(sortedComponents
                          ? sortedComponents.filter(
                              (c) => !c.parentComponentId && c.isActive
                            )
                          : []),
                      ]}
                      getOptionLabel={(option) => {
                        if (!option) return "";
                        return option.label
                          ? option.label
                          : `${option.name} (${option.number})`;
                      }}
                      value={selectedActivitiesFilters.component}
                      className="autocompleteBox"
                      id="activitiesFilterChangeAsset"
                      onChange={(event, option) => {
                        if (
                          option &&
                          option.label ===
                            translation.filterActivitiesModalSelectComponentLabel
                        ) {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            component: null,
                          });
                          setFilter({
                            ...filter,
                            componentId: emptyComponentOption.id,
                          });
                        } else {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            component: option,
                          });
                          setFilter({
                            ...filter,
                            componentId: option
                              ? option.id
                              : emptyComponentOption.id,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            translation.filterActivitiesModalComponentLabel
                          }
                          variant="outlined"
                          fullWidth
                          className="autocomplete"
                          InputProps={{
                            ...params.InputProps,
                            type: "searchTerm",
                          }}
                          InputLabelProps={{ shrink: true }}
                          placeholder={
                            translation.filterActivitiesModalSelectComponentLabel
                          }
                        />
                      )}
                      renderOption={(option) => (
                        <div>
                          {option.label ? (
                            <div>{option.label}</div>
                          ) : (
                            <div id="activitiesFilterChooseAsset">{`${option.name} (${option.number})`}</div>
                          )}
                        </div>
                      )}
                    />
                    <TextField
                      value={filter.priorityId}
                      onChange={(e) => {
                        setFilter({ ...filter, priorityId: e.target.value });
                        if (e.target.value !== emptyPriorityOption.id) {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            priorityId: e.target.value,
                          });
                        } else {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            priorityId: null,
                          });
                        }
                      }}
                      label={translation.filterActivitiesModalPriorityLabel}
                      variant="outlined"
                      fullWidth
                      select
                      margin="normal"
                      id="activitiesFilterChangePriority"
                    >
                      <MenuItem
                        key={emptyPriorityOption.id}
                        className="filterMenuItem"
                        value={emptyPriorityOption.id}
                      >
                        {emptyPriorityOption.name}
                      </MenuItem>
                      {priorityOptions &&
                        priorityOptions.map((priorityOption) => (
                          <MenuItem
                            key={priorityOption.priority}
                            className="filterMenuItem"
                            id="activitiesFilterChoosePriority"
                            value={priorityOption.priority}
                          >
                            {priorityOption.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      value={filter.assigneeId}
                      onChange={(e) => {
                        setFilter({ ...filter, assigneeId: e.target.value });
                        if (e.target.value !== emptyGuidAssigneeOption.id) {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            assigneeId: e.target.value,
                          });
                        } else {
                          setSelectedActivitiesFilters({
                            ...selectedActivitiesFilters,
                            assigneeId: null,
                          });
                        }
                      }}
                      label={translation.filterActivitiesModalAssigneeLabel}
                      variant="outlined"
                      fullWidth
                      select
                      margin="normal"
                      id="activitiesFilterChangeAssignee"
                    >
                      <MenuItem
                        key={emptyGuidAssigneeOption.id}
                        className="filterMenuItem"
                        value={emptyGuidAssigneeOption.id}
                      >
                        {emptyGuidAssigneeOption.name}
                      </MenuItem>
                      <MenuItem
                        key={emptyAssigneeOption.id}
                        className="filterMenuItem"
                        value={emptyAssigneeOption.id}
                      >
                        {emptyAssigneeOption.name}
                      </MenuItem>
                      {employeesOptions ? (
                        <p className="filterMenuItemDisabled">
                          {translation.saveDepartmentModalFromDepartmentLabel}
                        </p>
                      ) : (
                        <p style={{ display: "none" }}></p>
                      )}

                      {employeesOptions.fromDepartment &&
                        employeesOptions.fromDepartment.map((employee) => (
                          <MenuItem
                            key={employee.id}
                            className="filterMenuItem"
                            id="activitiesFilterChooseAssigneeFromDep"
                            value={employee.id}
                          >
                            {employee.name}
                          </MenuItem>
                        ))}

                      {employeesOptions ? (
                        <p className="filterMenuItemDisabled">
                          {translation.saveDepartmentModalOthersLabel}
                        </p>
                      ) : (
                        <p style={{ display: "none" }}></p>
                      )}
                      {employeesOptions.other &&
                        employeesOptions.other.map((employee) => (
                          <MenuItem
                            key={employee.id}
                            className="filterMenuItem"
                            id="activitiesFilterChooseAssigneeOthers"
                            value={employee.id}
                          >
                            {employee.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                )}
                <div
                  className="btnHolder end fixedWidthBtn"
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    variant="outlined"
                    className="respModal"
                    id="activitiesFilterResetBtn"
                    onClick={resetFilter}
                  >
                    {translation.resetButtonLabel}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="respModal"
                    id="activitiesFilterApplyBtn"
                    onClick={applyFilter}
                  >
                    {translation.applyButtonLabel}
                  </Button>
                </div>
              </div>
            </Popover>
            <DeleteItemModal
              isOpen={isOpenDeleteModal}
              isOpenManager={setIsOpenDeleteModal}
              message={translation.removeSubComponentModalMessageLabel}
              onDeleteModalClose={() => {
                onDeleteComponent();
              }}
            />
            <ChangeComponentStateModal
              isOpen={isOpenChangeStateModal}
              isOpenManager={setIsOpenChangeStateModal}
              selectedComponent={selectedComponent}
              selectedStateName={selectedStateName}
              selectedStateId={selectedStateId}
              onClose={() => {
                setIsOpenChangeStateModal(false);
              }}
            />
            <SaveComponentModal
              open={isOpenNewSaveComponentModal}
              handleClose={(e) => {
                setSelectedComponent(null);
                setSelectedComponentDetail(null);
                setIsOpenNewSaveComponentModal(false);
              }}
              isUnassignedComponent={isUnassignedComponent}
              onSuccessSave={(item) => {}}
              parentComponentId={!parentComponentId}
              unussigned={true}
            />
            <SaveComponentModal
              open={isOpenSaveComponentModal}
              handleClose={(e) => {
                setParentComponentId(null);
                setSelectedComponent(null);
                setSelectedComponentDetail(null);
                setIsOpenSaveComponentModal(false);
              }}
              component={selectedComponentDetail}
              parentComponentId={!parentComponentId}
              parentWorkhours={parentWorkhours}
              loading={componentDetailsRequest.isLoading}
              compDetails
              editUnassignedComponents
              isUnassignedComponent={isUnassignedComponent}
              onSuccessSave={handleSuccessSave}
              setExpandedIds={setExpandedIds}
              unussigned={true}
            />
            <SaveActivityModal
              isOpen={isOpenSaveActivityModal}
              isOpenManager={setIsOpenSaveActivityModal}
              isCatalog={isCatalog}
              componentId={selectedComponent?.id}
              activity={selectedActivity}
              componentWorkHours={selectedComponent?.workhoursTotal}
              onSuccessSave={updateList}
              hideCompleted={!!selectedActivity}
              setExpandedIds={setExpandedIds}
              isUnassignedComponent={isUnassignedComponent}
            />
            <DeleteItemModal
              isOpen={isOpenDeleteActivityModal}
              isOpenManager={setIsOpenDeleteActivityModal}
              message={translation.removeActivityModalMessageLabel}
              onDeleteModalClose={() => {
                onDeleteActivity();
                updateList();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ mms, dashboard }) => ({
  currentDepartment: mms.currentDepartment,
  statusOptions: mms.statusOptions,
  components: mms.components,
});

const mapDispatchToProps = {
  setPageTitle,
  setSelectedUnassignedComponentsIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedComponents);
