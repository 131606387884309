import React, { useState, useEffect, Fragment } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog"
import MmsService from 'Services/MMS/mms-api.service'
import { setRunComponentsUpdate } from '../../../../Store/modules/mms/actions'
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import { useQueryClient } from 'react-query'
import { CircularProgress } from '@material-ui/core';

const ChangeDepartmentModal = ({ open, handleClose, department, departments, componentIds, setRunComponentsUpdate, onSuccessChange }) => {
    const translation = useTranslation();
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)
    const [disableButtons, setDisabledButtons] = useState(false)
    const queryClient = useQueryClient()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!open)
            return
        setSelectedDepartmentId(department.id)
    }, [department, open])

    const handleSave = () => {
        setDisabledButtons(true)
        setIsLoading(true);
        if (selectedDepartmentId === department.id) {
            handleClose()
            setDisabledButtons(false)
            return
        }
        MmsService.changeComponentDepartment(componentIds, selectedDepartmentId).then((response) => {
            if (response.data.isSuccessful) {
                setRunComponentsUpdate(Math.random())
                queryClient.invalidateQueries(['components']).then()
                AlertToastr.showAlert(translation.notificationsComponentDepartmentChanged)
                if (response?.data?.message) {
                    AlertToastr.showWarnAlert(response.data.message)
                }
            }

            if (onSuccessChange)
                onSuccessChange()

            handleClose()
            setDisabledButtons(false)
            setIsLoading(false);
        })
            .catch((error) => {
                handleClose()
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
                    setIsLoading(false);
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        handleSave();
                    }
                }}
                className='respModal scrollContentModal autoHeight'
            >
                <div className="formContentHolder">
                    <div className="modalTitleHolder">
                        <h1 className='modalTitle text-center'>{translation.changeDepartmentModalLabel}</h1>
                    </div>
                    <div className="formContent">
                        <div className="formContentBlock modalFieldLabelSettings">
                            <TextField
                                value={selectedDepartmentId}
                                onChange={(e) => {
                                    e.persist()
                                    setSelectedDepartmentId(e.target.value)
                                }}
                                id="departments assetsChangeDepModalChangeDep"
                                variant="outlined"
                                margin='normal'
                                select
                                fullWidth
                            >
                                {departments && departments.map((option) => {
                                    return <MenuItem key={option.id} value={option.id} id="assetsChangeDepModalChooseDep">
                                        {option.name}
                                    </MenuItem>
                                })}
                            </TextField>
                        </div>
                    </div>
                    <div className="btnHolder end fixedWidthBtn">
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                                id="assetsChangeDepModalCancelBtn"
                            >
                                {translation.cancelButtonLabel}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSave}
                                id="assetsChangeDepModalSaveBtn"
                            >
                                {translation.saveButtonLabel}
                            </Button>
                            {isLoading && <CircularProgress size={24} />}
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment >
    )
}
const mapStateToProps = ({ mms }) => ({
    departments: mms.departments
})

const mapDispatchToProps = {
    setRunComponentsUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDepartmentModal)
