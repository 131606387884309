import { createSelector } from 'reselect'

const getDashboard = state => state.dashboard

export const getJobList = createSelector(getDashboard, dashboard => dashboard.jobs)
export const getFetchJobsRequest = createSelector(getDashboard, dashboard => dashboard.fetchJobsRequest)
export const getCurrentJob = createSelector(getDashboard, dashboard => dashboard.currentJob)
export const getBoxes = createSelector(getDashboard, dashboard => dashboard.boxes)
export const getCurrentCompany = createSelector(getDashboard, dashboard => dashboard.currentCompany)

export const getSelectedChannels = state => state.selectedChannels[state.currentIndex] ? state.selectedChannels[state.currentIndex] : []

export const getChannelsData = state => state.channelsData[state.currentIndex] ? state.channelsData[state.currentIndex] : []

export const getCurrentSelectedChannelData = createSelector(
    getSelectedChannels,
    channels => channels[0]
        ? { jobId: channels[0].jobId, unitId: channels[0].unitId }
        : { jobId: null, unitId: null }
)

export const getSelectedChannelsCount = createSelector(
    getSelectedChannels,
    channels => channels.length
)

export const getSelectedChannelsIds = createSelector(
    getSelectedChannels,
    channels => channels.reduce((acc, ch) => [...acc, ch.id], [])
)

export const getChartData = createSelector(
    getChannelsData,

    ({ data, type }) => {
        if (type === 'timeBased') {
            return data.map(set => {
                const transformedData = set ? set.data.reduce((acc, item) => {
                    return [...acc, [new Date(item.timestamp).getTime(), item.value]]
                }, []) : []
                return {
                    data: transformedData
                }
            })
        } else {
            return data
        }
    }
)
