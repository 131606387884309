import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

export default function useSaveTemplate({ id, type = 'jobs' }) {
    const queryClient = useQueryClient();

    return useMutation(
        ({ tabId, templateId, name, companyId }) => axios.post(`${apiBaseUrl}/templates/save`, {
            type,
            tabId,
            templateId,
            name,
        }, {
            headers: { 'CompanyId': companyId },
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['template', id, type]).then()
            },
        },
    );
}
