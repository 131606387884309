import { useQuery, queryClient } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

export const getUnitTemplatesByUid = async (uid) => {
    const { data } = await axios.get(`${apiBaseUrl}/templates/by-unituid/${uid}`);
    return data || [];
};

export function useUnitTemplates(uid) {
    return useQuery(
        ['template', uid, 'units'],
        () => getUnitTemplatesByUid(uid),
        {
            initialData: [],
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!uid
        }
    );
}

export function useUnitTemplatesPrefetch(uid) {
    return queryClient.prefetchQuery(
        ['template', uid, 'units'],
        () => getUnitTemplatesByUid(uid),
    );
}
