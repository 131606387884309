import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'Store';
import App from 'Components/App/App.component';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import 'assets/scss/index.scss';
import themeStyles from './theme';
import themeDarkStyles from './themeDark';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import JobState from 'Contexts/Job/JobState';
import ErrorBoundary from 'Components/ErrorBoundary';
import { AuthProvider } from 'Contexts/AuthProvider';
import { AppSettingsProvider } from 'Contexts/AppSettingsProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BoxProvider } from './Contexts/BoxContext';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ConfirmProvider } from './Contexts/ConfirmContext';

const queryClient = new QueryClient();
const configTheme = window.sessionStorage.getItem('theme') || localStorage.getItem('theme') || 'Light';

const theme = configTheme === 'Light' ? themeStyles : themeDarkStyles;

const Root = () => {
    const [currentTheme, setCurrentTheme] = useState(theme);

    const changeTheme = useCallback((theme, isChildrenWindow) => {
        if (!isChildrenWindow) {
            if (theme === 'Light') {
                document.body.classList.remove('theme-dark');
                setCurrentTheme(themeStyles);
            }

            if (theme === 'Dark') {
                document.body.classList.add('theme-dark');
                setCurrentTheme(themeDarkStyles);
            }
        }
    }, []);


    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Provider store={store}>
                        <AppSettingsProvider onSetTheme={changeTheme}>
                            <AuthProvider>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <ThemeProvider theme={createTheme(currentTheme)}>
                                        <ConfirmProvider>
                                            <JobState>
                                                <BoxProvider>
                                                    <App />
                                                </BoxProvider>
                                            </JobState>
                                        </ConfirmProvider>
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </AuthProvider>
                        </AppSettingsProvider>
                    </Provider>
                </Router>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

ReactDOM.render(<Root />,
    document.getElementById('root'),
);
