import jwtDecode from 'jwt-decode'

class TokenSevice {

    accessTokenKey = 'access_token'
    refreshTokenKey = 'refresh_token'
    expiresInKey = 'expires_in'
    tokenTypeKey = 'token_type'

    saveTokenData = data => {
        if (!data) {
            this.removeTokenData()
            return
        }

        const {access_token, refresh_token, expires_in, token_type} = data

        if (access_token) this.saveAccessToken(access_token)
        if (refresh_token) this.saveRefreshToken(refresh_token)
        if (expires_in) this.saveExpiresIn(expires_in)
        if (token_type) this.saveTokenType(token_type)
    }

    removeTokenData = () => {
        this.removeAccessToken()
        this.removeRefreshToken()
        this.removeExpiresIn()
        this.removeTokenType()
    }

    getAccessToken = () => localStorage.getItem(this.accessTokenKey)
    saveAccessToken = accessToken => localStorage.setItem(this.accessTokenKey, accessToken)
    removeAccessToken = () => localStorage.removeItem(this.accessTokenKey)

    getRefreshToken = () => localStorage.getItem(this.refreshTokenKey)
    saveRefreshToken = refreshToken => localStorage.setItem(this.refreshTokenKey, refreshToken)
    removeRefreshToken = () => localStorage.removeItem(this.refreshTokenKey)

    getExpiresIn = () => localStorage.getItem(this.expiresInKey)
    saveExpiresIn = expiresIn => localStorage.setItem(this.expiresInKey, expiresIn)
    removeExpiresIn = () => localStorage.removeItem(this.expiresInKey)

    getTokenType = () => localStorage.getItem(this.tokenTypeKey)
    saveTokenType = tokenType => localStorage.setItem(this.tokenTypeKey, tokenType)
    removeTokenType = () => localStorage.removeItem(this.tokenTypeKey)

    isTokenWith2FA = () => {
        const token = this.getToken()
        const decoded = jwtDecode(token)
        return decoded ? decoded.scopes.includes('2fa') : false
    }
}

export default new TokenSevice()
