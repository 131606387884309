import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useQueryClient } from 'react-query';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import MmsService from 'Services/MMS/mms-api.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Popover from "@material-ui/core/Popover";
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import SaveDepartmentModal from './Modals/SaveDepartmentModal'
import { setDepartments, setEmployees } from '../../../Store/modules/mms/actions'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { setPageTitle } from '../../../Store/modules/mms/actions';
import DepartmentInlineNotesForm from './DepartmentInlineNotesForm';



const ArrowLeft = () => <div className="arrowLeftIcon" />;
const ArrowRight = () => <div className="arrowRightIcon" />;

const descConst = "descending";
const ascConst = "ascending";
const arrowDownClassName = "hasArrow";
const arrowUpClassName = "hasArrow up";

const DepartmentsComponent = ({
  departments,
  setDepartments,
  setEmployees,
  employees,
  currentCompany,
  setPageTitle,
}) => {
  const translation = useTranslation();
  const [isOpenSaveDepartmentModal, setIsOpenSaveDepartmentModal] =
    useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [menuAchorEl, setMenuAchorEl] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "name", direction: ascConst });
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsOnPage, setRowsOnPage] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, onAnchorElChange] = useState(null);
  const queryClient = useQueryClient();
  const [ , setIsDeleting] = useState(false);

  const closeMenu = () => {
    setMenuAchorEl(null);
  };

  const onDeleteDepartment = () => {
    setIsDeleting(true);
    MmsService.deleteDepartment(selectedDepartment.id, currentCompany?.id)
      .then((res) => {
        if (res.data.isSuccessful) {
          AlertToastr.showAlert(res.data.message);
        }
        setIsDeleting(false);
        queryClient.invalidateQueries(['departments'])
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          AlertToastr.showErrorAlert(error.response.data.message);
        }
        setIsDeleting(false);
      });
  };

  const sortByKey = useCallback((key) => {
    return (a, b) => {
      if (a.employee && a.employee[key] && b.employee && b.employee[key]) {
        return (
          (a.employee[key].toLowerCase() < b.employee[key].toLowerCase()
            ? -1
            : 1) * (sortConfig.direction === ascConst ? 1 : -1)
        );
      }
      if (a.employee && a.employee[key] && (!b.employee || !b.employee[key])) {
        return -1 * (sortConfig.direction === ascConst ? 1 : -1);
      }
      if ((!a.employee || !a.employee[key]) && b.employee && b.employee[key]) {
        return 1 * (sortConfig.direction === ascConst ? 1 : -1);
      }
      return 0;
    };
  }, [sortConfig]);
  
  const sortedDepartments = useMemo(() => {
      if (!departments) return [];
      let sortedDepartments = [...departments];  
      if (!sortConfig || !sortConfig.key) return sortedDepartments;  
      if (sortConfig.key === "name") {
        sortedDepartments.sort((a, b) => {
          if (a[sortConfig.key] && b[sortConfig.key]) {
            return (
              (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()
                ? -1
                : 1) * (sortConfig.direction === ascConst ? 1 : -1)
            );
          }
          return 0;
        });
      } else if (sortConfig.key === "employee.name") {
        sortedDepartments.sort(sortByKey("name"));
      } else if (sortConfig.key === "employee.email") {
        sortedDepartments.sort(sortByKey("email"));
      } else if (sortConfig.key === "employee.phoneNumber") {
        sortedDepartments.sort(sortByKey("phoneNumber"));
      }  
      return sortedDepartments;
    }, [departments, sortConfig, sortByKey]);

  const pageData = useMemo(() => {
    let pageData = [...sortedDepartments];
    const totalLength = pageData.length;

    if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
      setPageNumber(1);
    }
    setTotalRows(totalLength);
    setFirstIndex(Math.min((pageNumber - 1) * rowsOnPage + 1, totalLength));
    setLastIndex(Math.min(pageNumber * rowsOnPage, totalLength));
    let tableData = pageData.splice(
      (pageNumber - 1) * rowsOnPage,
      Math.min(pageNumber * rowsOnPage, totalLength)
    );
    return [...tableData];
  }, [sortedDepartments, pageNumber, rowsOnPage]);

  useEffect(() => {
    setPageTitle(translation.myCompanyDepartmentsLabel);
  }, [setPageTitle, translation]);

  const requestSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === ascConst ? descConst : ascConst,
      });
    } else {
      setSortConfig({
        key,
        direction: descConst,
      });
    }
  };


  const getClassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return;
    }
    if (sortConfig.direction)
      return sortConfig.direction === ascConst
        ? arrowDownClassName
        : arrowUpClassName;
  };

  const closePaginationMenu = () => {
    onAnchorElChange(null);
  };

  const handleRowCount = (count) => {
    setRowsOnPage(count);
    setPageNumber(1);
    closePaginationMenu();
  };

  const onNextPage = () => {
    setPageNumber(pageNumber + 1);
    window.scrollTo(0, 0);
  };

  const onPrevPage = () => {
    setPageNumber(pageNumber - 1);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="pmPageHolder">
        <div className="pmPageBlock">
          <div className="pmPageBlockTitle">
            {translation.myCompanyDepartmentsLabel}
          </div>
          <div className="pmTableHolder" style={{ marginTop: "25px" }}>
            <table className="pmTable">
              <thead>
                <tr>
                  <th
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                    colSpan="1"
                  >
                    {translation.saveDepartmentModalNameLabel}
                  </th>
                  <th
                    onClick={() => requestSort("note")}
                    className={getClassNamesFor("note")}
                    colSpan="1"
                  >
                    {translation.saveDepartmentModalNoteLabel}
                  </th>
                  <th
                    onClick={() => requestSort("employee.name")}
                    className={getClassNamesFor("employee.name")}
                    colSpan="1"
                  >
                    {translation.saveDepartmentModalContactPersonLabel}
                  </th>
                  <th
                    onClick={() => requestSort("employee.email")}
                    className={getClassNamesFor("employee.email")}
                    colSpan="1"
                  >
                    {translation.saveDepartmentModalEmailLabel}
                  </th>
                  <th
                    onClick={() => requestSort("employee.phoneNumber")}
                    className={getClassNamesFor("employee.phoneNumber")}
                    colSpan="2"
                  >
                    {translation.saveDepartmentModalTelephoneLabel}
                  </th>
                </tr>
              </thead>
              <tbody>
                {pageData
                  ? pageData.map((department) => (
                      <tr key={department.id}>
                        <td id='departmentsTblHeaderName' className={department.isDeleting ? 'deletedDepartment' : 'text-semi-bold'}>
                          {department.name}
                        </td>
                        <td className={department.isDeleting ? 'deletedDepartment pr10' : 'pr10'} style={{minWidth: "160px"}}>
                            <DepartmentInlineNotesForm
                              model={department}
                              onSuccessUpdate={() => {
                                  queryClient.invalidateQueries(['departments'])
                                  .then();
                              }}
                            />
                        </td>
                        {department.employee ? (
                          <td id='departmentsTblHeaderEmployee' className={department.isDeleting ? 'deletedDepartment' : ''}>{department.employee.name}</td>
                        ) : (
                          <td className={department.isDeleting ? 'deletedDepartment' : ''}>-</td>
                        )}
                        {department.employee && department.employee.email ? (
                          <td id='departmentsTblHeaderEmail' className={department.isDeleting ? 'deletedDepartment' : ''}>{department.employee.email}</td>
                        ) : (
                          <td className={department.isDeleting ? 'deletedDepartment' : ''}>-</td>
                        )}
                        {department.employee && department.employee.phoneNumber ? (
                          <td id='departmentsTblHeaderPhone' className={department.isDeleting ? 'deletedDepartment' : ''}>{department.employee.phoneNumber}</td>
                        ) : (
                          <td className={department.isDeleting ? 'deletedDepartment' : ''}>-</td>
                        )}
                        <td className="btnCell">
                          <IconButton
                            disabled={department.isDeleting}
                            id='departmentsTblDropDownBtn'
                            aria-label="delete"
                            size="small"
                            className="dropDownBtn mobile"
                            onClick={(e) => {
                              setMenuAchorEl(e.target);
                              setSelectedDepartment(department);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </td>
                        <Popover
                          anchorEl={menuAchorEl}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          disableScrollLock={true}
                          open={Boolean(menuAchorEl)}
                          onClose={closeMenu}
                          elevation={1}
                          className="pmPopover equipmentSubMenu"
                        >
                          <MenuItem
                            id='departmentsTblMenuEditBtn'
                            key={"edit"}
                            onClick={() => {
                              setIsOpenSaveDepartmentModal(true);
                              setMenuAchorEl(null);
                            }}
                            className="menuItem edit"
                          >
                            {translation.popoverEditLabel}
                          </MenuItem>
                          <MenuItem
                            id='departmentsTblMenuDeleteBtn'
                            key={"delete"}
                            onClick={() => {
                              setIsOpenDeleteModal(true);
                              setMenuAchorEl(null);
                            }}
                            className="menuItem delete"
                          >
                            {translation.popoverRemoveLabel}
                          </MenuItem>
                        </Popover>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            {pageData && pageData.length > 0 && (
              <div className="tableControlsHolder">
                <div className="sortHolder">
                  <div className="caption">Rows per page:</div>
                  <Button
                    id='departmentsTableRowsPerPageBtn'
                    onClick={(e) => onAnchorElChange(e.target)}
                    endIcon={<ArrowDropDownIcon />}
                    className="dropDownBtn"
                  >
                    {rowsOnPage}
                  </Button>

                  <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={closePaginationMenu}
                    disableScrollLock={true}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem id='departmentsTblRowCount20Btn' onClick={() => handleRowCount(20)}>20</MenuItem>
                    <MenuItem id='departmentsTblRowCount50Btn' onClick={() => handleRowCount(50)}>50</MenuItem>
                    <MenuItem id='departmentsTblRowCount100Btn' onClick={() => handleRowCount(100)}>100</MenuItem>
                  </Popover>
                </div>
                <div className="paginationHolder mms">
                  <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                  <div className="paginationBtn">
                    <IconButton
                      id='departmentsTblPrevPageBtn'
                      disabled={firstIndex === 1 || firstIndex === 0}
                      onClick={() => onPrevPage()}
                    >
                      <ArrowLeft />
                    </IconButton>
                    <IconButton
                      id='departmentsTblNextPageBtn'
                      disabled={lastIndex === totalRows}
                      onClick={() => onNextPage()}
                    >
                      <ArrowRight />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
            {!departments ? (
              <div className="loaderHolder">
                <Fade in={!departments} unmountOnExit>
                  <CircularProgress />
                </Fade>
              </div>
            ) : null}
            <SaveDepartmentModal
                open={isOpenSaveDepartmentModal}
                onSuccessSave= {() => queryClient.invalidateQueries(['departments'])}
                handleClose={(e) => setIsOpenSaveDepartmentModal(false)}
                department={selectedDepartment}
              />
            
            <DeleteItemModal
              isOpen={isOpenDeleteModal}
              isOpenManager={setIsOpenDeleteModal}
              message={translation.removeDepartmentModalMessageLabel}
              onDeleteModalClose={(e) => {
                onDeleteDepartment();
              }}
              
            />

          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ mms, dashboard }) => ({
  departments: mms.departments,
  employees: mms.employees,
  currentCompany: dashboard.currentCompany,
});

const mapDispatchToProps = {
  setDepartments,
  setEmployees,
  setPageTitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentsComponent);