import React, { createContext, memo, useContext, useEffect, useState } from 'react';
import ApiService from 'Services/Api.service';
import { getStoredLang } from '../storage/lang-storage';

const AppSettingsContext = createContext({});
AppSettingsContext.displayName = 'AppSettingsContext';

const STORAGE_THEME_KEY = '_dms_theme';

export const AppSettingsProvider = memo(({ children, onSetTheme }) => {

    const [appLanguage, setAppLanguage] = useState(() => (getStoredLang() || 'English'));
    const [userBoundChannelColors, setUserBoundChannelColors] = useState([]);
    const [appTheme, setAppTheme] = useState(window.sessionStorage.getItem('theme'));
    const [loading, setLoading] = useState(true);
    const [languageList, setLanguageList] = useState(['English', 'Russian']);
    const [themeList, setThemeList] = useState(['Light', 'Dark']);

    //aside menu buttons
    const [showActivitiesPartsExportPdf, setShowActivitiesPartsExportPdf] = useState(false);

    useEffect(() => {
        ApiService.setLang(appLanguage);
    }, [appLanguage]);

    useEffect(() => {
        const theme = window.sessionStorage.getItem('theme') || localStorage.getItem(STORAGE_THEME_KEY) || 'Light';
        // const language = localStorage.getItem(STORAGE_LANGUAGE_KEY) || 'English';
        setAppTheme(theme);
        // setAppLanguage(language);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (appTheme) {
            window.sessionStorage.setItem('theme', appTheme);
            onSetTheme(appTheme);
        }
    }, [appTheme, onSetTheme]);

    return (
        <AppSettingsContext.Provider value={{
            appLanguage,
            appTheme,
            setAppTheme,
            setAppLanguage,
            languageList,
            setLanguageList,
            themeList,
            setThemeList,
            userBoundChannelColors,
            setUserBoundChannelColors,
            showActivitiesPartsExportPdf,
            setShowActivitiesPartsExportPdf,
            loading
        }}>
            {!loading && children}
        </AppSettingsContext.Provider>
    );
});

export const useAppSettings = () => useContext(AppSettingsContext);
