import React from 'react';

const RadioLabel = ({ label }) => {
    return (
        <div className="jobLabel">
            <div className="labelName">{label}</div>
        </div>
    );
};

export default RadioLabel;
