import Types from './types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import DmsService from 'Services/DMS/dms-api.service';

import {
    fetchChannelsDataFailure,
    fetchChannelsDataSuccess,
    fetchXYChannelsDataFailure,
    fetchXYChannelsDataSuccess,
} from './actions';

function* fetchJobs() {
}

function* watchFetchJobRequest() {
    yield takeLatest(Types.FETCH_JOB_REQUEST, fetchJobs);
}

function* fetchChannelsData({from, to, channelsIds, index}) {
    try {
        const channelsData = yield call(DmsService.getChannelsDataByDaterange, from, to, channelsIds);
        yield put(fetchChannelsDataSuccess(channelsData, index));
    } catch (error) {
        yield put(fetchChannelsDataFailure(error.response));
    }
}

function* watchFetchChannelsDataRequest() {
    yield takeLatest(Types.FETCH_CHANNELS_DATA_REQUEST, fetchChannelsData);
}

function* fetchXYChannelsData({channelsXId, channelsYIds}) {
    try {
        const channelsData = yield call(DmsService.getChannelsDataByCustomAxis, channelsXId, channelsYIds);
        yield put(fetchXYChannelsDataSuccess(channelsData));
    } catch (error) {
        yield put(fetchXYChannelsDataFailure(error.response.data));
    }
}

function* watchFetchXYChannelsDataRequest() {
    yield takeLatest(Types.FETCH_XY_CHANNELS_DATA_REQUEST, fetchXYChannelsData);
}

export default function* () {
    yield all([
        call(watchFetchJobRequest),
        call(watchFetchChannelsDataRequest),
        call(watchFetchXYChannelsDataRequest),
    ]);
}
