import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Dialog, Switch, TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { ThemeProvider } from 'styled-components';
import { Datepicker, END_DATE, START_DATE } from '@datepicker-react/styled';
import DmsService from 'Services/DMS/dms-api.service';
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component';
import AlertToastr from 'utils/alert';
import { string } from 'prop-types';
import { userHasPermission } from 'helpers';
import { useAuth } from 'Contexts/AuthProvider';
import moment from 'moment';
import { useAppSettings } from '../../../Contexts/AppSettingsProvider';
import useTranslation from 'Hooks/useTranslation';
import { useConfirm } from '../../../Contexts/ConfirmContext';

const allConst = 'all';
const customConst = 'custom';
const defaultTime = '00:00';
const invalidDatesErrorMessage = '\'Valid From\' must be earlier than \'Valid To\'';
const RadioLabel = ({ label }) => {
    return (
        <div className="jobLabel">
            <div className="labelName">{label}</div>
        </div>
    );
};

const NewUserModal = ({ isOpen, isOpenChangeHandler, onRequestClose, onDeleteUser, userData, jobs, companyId }) => {

    const translation = useTranslation();
    const { appTheme: theme } = useAppSettings();
    const { confirm } = useConfirm();
    const [removeUserModalIsOpen, onRemoveUserModalIsOpen] = useState(false);
    const [role, setRole] = useState('user');
    const [isEdit, setIsEdit] = useState(false);
    const [showDatepickers, setShowDatepickers] = useState(false);
    const [showLicenseIds, setShowLicenseIds] = useState(false);
    const [showJobs, setShowJobs] = useState(false);
    const [licenses, setLicenses] = useState([]);
    const [canViewAllChannels, setCanViewAllChannels] = useState(false);
    const [canDownloadDataFile, setCanDownloadDataFile] = useState(false);
    const [canManageTemplate, setCanManageTemplate] = useState(false);
    const [timeFrom, setTimeFrom] = useState(defaultTime);
    const [timeTo, setTimeTo] = useState(defaultTime);
    const [user, setUser] = useState({
        username: string,
        email: string,
        selectedLicenses: [],
        selectedJobs: [],
    });
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE,
    });
    const { currentUser } = useAuth();
    const allowManageMasters = useMemo(() => userHasPermission(currentUser, 'manage_masters'), [currentUser]);
    const allowManageRoots = useMemo(() => userHasPermission(currentUser, 'manage_roots'), [currentUser]);
    const allowManageAdmins = useMemo(() => userHasPermission(currentUser, 'manage_admins'), [currentUser]);

    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser]);

    const fetchLicenseIds = async () => {
        try {
            let response;
            if (isSuperUser)
                response = await DmsService.getLicenseIdsByCompanyId(companyId);
            else
                response = await DmsService.getLicenseIds();
            setLicenses(response.data);
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message);
        }
    };

    const handleJobsChange = (item, checked) => {
        let jobList = user.selectedJobs;
        if (checked) {
            jobList.push(item.id);
        } else {
            removeItem(jobList, item.id);
        }
        setUser({ ...user, selectedJobs: jobList });
    };

    const handleLicenseIdsChange = (item, checked) => {
        let licenseList = user.selectedLicenses;
        if (checked) {
            licenseList.push(item.licenseId);
        } else {
            removeItem(licenseList, item.licenseId);
        }
        setUser({ ...user, selectedLicenses: licenseList });
    };

    const handleDatesChange = (data) => {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE });
        } else {
            setDateRange(data);
        }
    };

    const removeItem = (list, itemId) => {
        let index = list.findIndex((id) => id === itemId);
        list.splice(index, 1);
    };

    const setDefaultValues = () => {
        setRole('user');
        setShowDatepickers(false);
        setShowLicenseIds(false);
        setLicenses([]);
        setShowJobs(false);
        setTimeFrom(defaultTime);
        setTimeTo(defaultTime);
        setUser({ ...user, selectedLicenses: [], selectedJobs: [] });
        setDateRange({ focusedInput: START_DATE, startDate: null, endDate: null });
        setCanViewAllChannels(false);
        setCanDownloadDataFile(false);
        setCanManageTemplate(false);
    };

    const onCreate = () => {
        let userInfo = createUserInfo();
        if (userInfo.role === 'admin' || userInfo.role === 'root') {
            onClose(userInfo);
            return;
        }
        if ((userInfo.validity.key === customConst) && new Date(userInfo.validity.value.from) >= new Date(userInfo.validity.value.to)) {
            AlertToastr.showErrorAlert(invalidDatesErrorMessage);
            return;
        }
        onClose(userInfo);
    };

    const formatDate = (date, time) => {
        return new Date(new Date(date).setHours(time.slice(0, 2), time.slice(3, 5))).toISOString();
    };

    const onEnter = (e) => {
        fetchLicenseIds().then();
        if (userData) {
            setIsEdit(true);
            showUserInfo();
            setTimeout(() => {
                if (userData.validity.key === customConst)
                    handleDatesChange({
                        startDate: new Date(userData.validity.value.from),
                        endDate: new Date(userData.validity.value.to),
                        focusedInput: END_DATE,
                    });
            }, 100);
        } else {
            setIsEdit(false);
            setRole('user');
            setDefaultValues();
        }

    };

    const showUserInfo = () => {
        setRole(userData.role);

        setShowDatepickers(userData.validity.key === customConst);
        if (userData.validity.key === customConst) {
            setTimeTo(moment(new Date(userData.validity.value.to)).format('HH:mm'));
            setTimeFrom(moment(new Date(userData.validity.value.from)).format('HH:mm'));
        }
        let jobList = [];
        setShowJobs(userData.allowedJobs.key === customConst);
        if (userData.allowedJobs.key === customConst) {
            jobList = userData.allowedJobs.value;
        }
        let licenseList = [];
        setShowLicenseIds(userData.allowedLicenses.key === customConst);
        if (userData.allowedLicenses.key === customConst) {
            licenseList = userData.allowedLicenses.value;
        }
        setUser({ ...user, selectedJobs: jobList, selectedLicenses: licenseList });
        setCanViewAllChannels(userData.canViewAllChannels);
        setCanDownloadDataFile(userData.canDownloadDataFile);
        setCanManageTemplate(userData.canManageTemplate);
    };

    const createUserInfo = () => {
        let userInfo;
        if (role === 'admin' || role === 'root') {
            userInfo = {
                role,
                companyId: companyId,
            };
        } else {
            userInfo = {
                companyId: companyId,
                validity: {
                    key: showDatepickers ? customConst : allConst,
                    value: showDatepickers ? {
                        from: formatDate(dateRange.startDate, timeFrom),
                        to: formatDate(dateRange.endDate, timeTo),
                    } : {},
                },
                allowedLicenses: {
                    key: showLicenseIds ? customConst : allConst,
                    value: showLicenseIds ? user.selectedLicenses : [],
                },
                allowedJobs: {
                    key: showJobs ? customConst : allConst,
                    value: showJobs ? user.selectedJobs : [],
                },
                canViewAllChannels: canViewAllChannels,
                canDownloadDataFile: canDownloadDataFile,
                canManageTemplate: canManageTemplate,
                role,
            };
        }

        if (!isEdit) {
            userInfo.name = user.username;
            userInfo.email = user.email;
        } else {
            userInfo.id = userData.id;
            userInfo.isEnabled = userData.isEnabled;

        }
        return userInfo;
    };

    const onDeleteModalClose = () => {
        isOpenChangeHandler(false);
        setDefaultValues();
        onDeleteUser();
    };

    const onClose = (userInfo) => {
        isOpenChangeHandler(false);
        onRequestClose(userInfo);
        setDefaultValues();
    };

    const showConfirm = async () => {
        const isConfirmed = await confirm(translation.confirmChangeRootRoleQuestion);
        if (isConfirmed) {
            onCreate();
        }
    };

    const handleChangeMaster =  () => {
        setRole(prevRole => prevRole === 'master' ? 'user' : 'master')
    }

    const handleChangeRoot =  () => {
        setRole(prevRole => prevRole === 'root' ? 'user' : 'root')
    }

    const handleSubmit = async () => {
        if (role === 'root') { await showConfirm()}
        else {onCreate()}
    }

    return (
        <Dialog
            open={isOpen}
            className={role === 'admin' ? 'respModal scrollContentModal calendarModal autoHeight' : 'respModal scrollContentModal calendarModal autoHeight'}
            // onEnter={onEnter}
            TransitionProps={{
                onEnter,
            }}
        >
            <form action="">
                <div className="formContentHolder">
                    {!isEdit ?
                        <div className="modalTitleHolder">
                            <h2>{translation.saveUserModalCreateUserLabel}</h2>
                        </div> :
                        <div className="modalTitleHolder">
                            <h2>{translation.saveUserModalAccountSettingsLabel}</h2>
                        </div>
                    }
                    <div className="formContent">
                        {!isEdit &&
                            <div className="formContentBlock">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="username"
                                    label={translation.saveUserModalUsernameLabel}
                                    name="username"
                                    inputProps={{
                                        'autoComplete': 'off',
                                    }}
                                    onChange={e => setUser({ ...user, username: e.target.value })}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label={translation.saveUserModalEmailLabel}
                                    name="email"
                                    inputProps={{
                                        'autoComplete': 'off',
                                    }}
                                    onChange={e => setUser({ ...user, email: e.target.value })}
                                />
                            </div>}
                        {allowManageMasters &&
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label className="radioLabel text-bold">{translation.saveUserModalMasterLabel}</label>
                                    <Switch
                                        checked={role === 'master'}
                                        onChange={handleChangeMaster}
                                        color="primary"
                                    />
                                </div>
                            </div>}

                        {allowManageRoots &&
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label className="radioLabel text-bold">{translation.saveUserModalRootLabel}</label>
                                    <Switch
                                        checked={role === 'root'}
                                        onChange={handleChangeRoot}
                                        color="primary"
                                    />
                                </div>
                            </div>}

                        {allowManageAdmins &&
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label
                                        className="radioLabel text-bold">{translation.saveUserModalAdminLabel}</label>
                                    <Switch
                                        checked={role === 'admin'}
                                        onChange={e => setRole(prevRole => prevRole === 'admin' ? 'user' : 'admin')}
                                        color="primary"
                                    />
                                </div>
                            </div>}
                        {!(role === 'admin' || role === 'root' || role === 'master') && <div>
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label
                                        className="radioLabel text-bold">{translation.saveUserModalMmsManagerLabel}</label>
                                    <Switch
                                        checked={role === 'mmsmanager'}
                                        onChange={e => setRole(prevRole => prevRole === 'mmsmanager' ? 'user' : 'mmsmanager')}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            <div className="formContentBlock">
                                <FormControl component="fieldset">
                                    <FormLabel component="label"
                                               className={'radioLabel text-bold'}>{translation.saveUserModalValidityTimeLabel}</FormLabel>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="lifetime"
                                                onChange={(e) => {
                                                    setShowDatepickers(!showDatepickers);
                                                }}>
                                        <FormControlLabel
                                            value="lifetime"
                                            checked={!showDatepickers}
                                            control={<Radio color="default" />}
                                            label={<RadioLabel label={translation.saveUserModalLifetimeLabel} />}
                                        />
                                        <FormControlLabel
                                            value="setTime"
                                            checked={showDatepickers}
                                            control={<Radio color="default" />}
                                            label={<RadioLabel label={translation.saveUserModalSetTimeLabel} />}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {showDatepickers &&
                                    <div className="collapseBodyHolder">
                                        <div className="calendarHolder">
                                            <ThemeProvider
                                                theme={{
                                                    reactDatepicker: {
                                                        daySize: [36, 40],
                                                        fontFamily: '"Open Sans", sans-serif',
                                                        colors: {
                                                            accessibility: 'transparent',
                                                            selectedDay: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                                                            selectedDayHover: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                                                            primaryColor: theme === 'Light' ? 'rgba(68, 68, 68, 0.12)!important' : 'rgba(255, 255, 255, 0.12)!important',
                                                        },
                                                    },
                                                }}
                                            >
                                                <Datepicker
                                                    onDatesChange={handleDatesChange}
                                                    startDate={dateRange.startDate} // Date or null
                                                    endDate={dateRange.endDate} // Date or null
                                                    focusedInput={dateRange.focusedInput} // START_DATE, END_DATE or null
                                                    showResetDates={false}
                                                    showSelectedDates={false}
                                                    showClose={false}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <div className="timeHolder">
                                            <div className="inputHolder">
                                                <TextField
                                                    id="startTime"
                                                    label="Valid from"
                                                    type="time"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={timeFrom}
                                                    onChange={e => setTimeFrom(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                    }}
                                                />
                                            </div>
                                            <div className="inputHolder">
                                                <TextField
                                                    id="endtime"
                                                    label="Valid to"
                                                    type="time"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={timeTo}
                                                    onChange={e => setTimeTo(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="formContentBlock">
                                <FormControl component="fieldset">
                                    <FormLabel component="label"
                                               className={'radioLabel text-bold'}>{translation.saveUserModalAccessedLicensesLabel}</FormLabel>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top"
                                                onChange={(e) => {
                                                    setShowLicenseIds(!showLicenseIds);
                                                }}>
                                        <FormControlLabel
                                            checked={!showLicenseIds}
                                            value="all"
                                            control={<Radio color="default" />}
                                            label={<RadioLabel label={translation.saveUserModalAllLabel} />}
                                        />
                                        <FormControlLabel
                                            checked={showLicenseIds}
                                            value="setIds"
                                            control={<Radio color="default" />}
                                            label={<RadioLabel label={translation.saveUserModalSelectLicensesLabel} />}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {showLicenseIds &&
                                    <div className="collapseBodyHolder">
                                        <div className="checkboxListHolder mt8">
                                            <div className="labelLike">Licenses</div>
                                            <div className="checkboxListContainer">
                                                {
                                                    licenses.map(item => (
                                                        <div key={item.licenseId}>
                                                            <label>
                                                                <Checkbox color="default" name={item.name}
                                                                          checked={user.selectedLicenses.includes(item.licenseId)}
                                                                          onChange={(e) => {
                                                                              handleLicenseIdsChange(item, e.target.checked);
                                                                          }} />
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="formContentBlock">
                                <FormControl component="fieldset">
                                    <FormLabel component="label"
                                               className={'radioLabel text-bold'}>{translation.saveUserModalAccessedJobsLabel}</FormLabel>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top"
                                                onChange={(e) => {
                                                    setShowJobs(!showJobs);
                                                }}>
                                        <FormControlLabel
                                            checked={!showJobs}
                                            value="all"
                                            control={<Radio color="default" />}
                                            label={<RadioLabel label={'All'} />}
                                        />
                                        <FormControlLabel
                                            checked={showJobs}
                                            value="setJob"
                                            control={<Radio color="default" />}
                                            label={<RadioLabel label={translation.saveUserModalSelectJobsLabel} />}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {showJobs &&
                                    <div className="collapseBodyHolder">
                                        <div className="checkboxListHolder mt8">
                                            <div className="labelLike">{translation.saveUserModalJobsLabel}</div>
                                            <div className="checkboxListContainer">
                                                {jobs &&
                                                    jobs.map(item => (
                                                        <div key={item.id}>
                                                            <label>
                                                                <Checkbox color="default" name={item.identifier}
                                                                          checked={user.selectedJobs.includes(item.id)}
                                                                          onChange={(e) => handleJobsChange(item, e.target.checked)} />
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label
                                        className="radioLabel text-bold">{translation.saveUserModalSpecialPrivilegesLabel}</label>
                                    <Switch
                                        checked={canViewAllChannels}
                                        onChange={e => setCanViewAllChannels(!canViewAllChannels)}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label
                                        className="radioLabel text-bold">{translation.saveUserModalDownloadDataFileLabel}</label>
                                    <Switch
                                        checked={canDownloadDataFile}
                                        onChange={e => setCanDownloadDataFile(!canDownloadDataFile)}
                                        color="primary"
                                    />
                                </div>
                            </div>
                            <div className="formContentBlock xs">
                                <div className="switchHolder">
                                    <label
                                        className="radioLabel text-bold">{translation.saveUserModalManageTemplatesLabel}</label>
                                    <Switch
                                        checked={canManageTemplate}
                                        onChange={e => setCanManageTemplate(!canManageTemplate)}
                                        color="primary"
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="btnHolder end fixedWidthBtn">
                        {isEdit && <Button variant="outlined"
                                           onClick={(e) => onRemoveUserModalIsOpen(true)}
                        >
                            {translation.deleteAccountButtonLabel}
                        </Button>}
                        <Button onClick={(e) => onClose(null)}
                                variant="outlined">
                            {translation.cancelButtonLabel}
                        </Button>
                        <Button onClick={handleSubmit}
                                variant="contained"
                                color="primary">
                            {isEdit ? translation.saveButtonLabel : translation.createButtonLabel}
                        </Button>
                    </div>
                </div>
                <DeleteItemModal isOpen={removeUserModalIsOpen}
                                 isOpenManager={onRemoveUserModalIsOpen}
                                 message={translation.removeUserModalMessageLabel}
                                 onDeleteModalClose={onDeleteModalClose} />
            </form>

        </Dialog>
    );
};

export default NewUserModal;
