export default {
    continueButtonLabel: 'Далее',
    cancelButtonLabel: 'Отменить',
    saveButtonLabel: 'Сохранить',
    editButtonLabel: 'Редактировать',
    nextButtonLabel: 'Далее',
    nextButtonTooltipLabel: 'Нет доступных шаблонов для выбранной работы',
    loadButtonLabel: 'Далее',
    createButtonLabel: 'Создать',
    openInNewWindowButtonLabel: 'Открыть в новом окне',
    exportButtonLabel: 'Экспортировать',
    filterButtonLabel: 'Фильтр',
    resetButtonLabel: 'Сбросить',
    applyButtonLabel: 'Применить',
    deleteButtonLabel: 'Удалить',
    cloneButtonLabel: 'Клонировать',
    completeButtonLabel: 'Завершить',
    changeButtonLabel: 'Изменить',
    sendButtonLabel: 'Отправить',
    refreshButtonLabel: 'Обновить',
    signUpButtonLabel: 'Зарегистрироваться',
    signInButtonLabel: 'Войти',
    openButtonLabel: 'Открыть',
    deleteAccountButtonLabel: 'Удалить учетную запись',
    addButtonLabel: 'Добавить',
    saveTemplateButtonLabel: 'Сохранить шаблон',
    exportJobToCsvLabel: 'Экспорт работ(-ы) в CSV',
    exportUnitToCsvLabel: 'Экспорт блока(-ов) в CSV',
    exportLocationToCsvLabel: 'Экспорт карты в CSV',
    changeJobLabel: 'Изменить работу',
    changeUnitLabel: 'Изменить блок',

    headerUnitsLabel: 'Блоки',
    headerLocationsLabel: 'Карта',
    headerDmsCloudLabel: 'Работы',
    headerSettings: 'Настройки',
    headerMmsLabel: 'CAMMS',
    headerCompanyLabel: 'Компания',
    headerMenuChangePasswordLabel: 'Изменить пароль',
    headerMenuSignOutLabel: 'Выйти',
    headerMenuSignOutMessageLabel: 'Вы уверены что хотите выйти?',
    headerMenuSignOutBtnLabel: 'Подтвердить',

    saveUserModalCreateUserLabel: 'Создать пользователя',
    saveUserModalAccountSettingsLabel: 'Настройки учетной записи',
    saveUserModalUsernameLabel: 'Имя',
    saveUserModalEmailLabel: 'E-mail',
    saveUserModalAdminLabel: 'Администратор',
    saveUserModalRootLabel: 'Корневой',
    saveUserModalMasterLabel: 'Мастер',
    saveUserModalMmsManagerLabel: 'MMS Менеджер',
    saveUserModalValidityTimeLabel: 'Срок действия',
    saveUserModalLifetimeLabel: 'Всё время',
    saveUserModalSetTimeLabel: 'Установить время',
    saveUserModalAllLabel: 'Все',
    saveUserModalSelectLicensesLabel: 'Выбрать лицензию',
    saveUserModalAccessedLicensesLabel: 'Доступные лицензии',
    saveUserModalAccessedJobsLabel: 'Доступные работы',
    saveUserModalJobsLabel: 'Работы',
    saveUserModalSelectJobsLabel: 'Выбрать работы',
    saveUserModalAccessToAllChannelsLabel: 'Доступ ко всем каналам',
    saveUserModalSpecialPrivilegesLabel: 'Особые привилегии',
    saveUserModalDownloadDataFileLabel: 'Скачать файл',
    saveUserModalManageTemplatesLabel: 'Управление шаблонами',

    settingsTablesUsersLabel: 'Пользователи',
    settingsTablesOneJobPasswordsLabel: 'Пароли одной работы',
    settingsDbManagementLabel: 'Управление БД',
    settingsTemplatesManagementLabel: 'Управление шаблонами',
    settingsDataUsageLabel: 'Использование данных',
    settingsTablesAllowOneJobPasswordLabel: 'Разрешить пароль одной работы',
    settingsTablesNewUserLabel: 'Новый пользователь',
    settingsTablesNewJobPasswordLabel: 'Новый пароль одной работы',
    settingsTablesNameHeader: 'Имя',
    settingsTablesEmailHeader: 'E-mail',
    settingsTablesStatusHeader: 'Статус',
    settingsTablesCsvExportHeader: 'CSV экспорт',
    settingsMasterRootHeader: 'Мастер',
    settingsTablesRootHeader: 'Корневой',
    settingsTablesAdminHeader: 'Администратор',
    settingsTablesMmsManagerHeader: 'MMS Менеджер',
    settingsTablesSessionsHeader: 'Сессии',
    settingsTablesCreatedHeader: 'Дата создания',
    settingsTablesJobNameHeader: 'Наименование работы',
    settingsTablesPasswordHeader: 'Пароль',
    settingsTablesValidToHeader: 'Действует до',

    dashboardJobsLabel: 'Работы',
    dashboardStartTimeLabel: 'Начало работы',
    dashboardStopTimeLabel: 'Конец работы',
    dashboardNewItemLabel: ' Добавить',
    dashboardOptionsLabel: 'Настройки',
    dashboardLoadTemplateLabel: 'Загрузить шаблон',
    dashboardSaveTemplateLabel: 'Сохранить шаблон',
    dashboardSearchJobLabel: 'Найти работу',
    dashboardSearchUnitLabel: 'Найти блок',
    dashboardSearchTemplateLabel: 'Найти шаблон',
    dashboardTemplatesLabel: 'Шаблоны',
    dashboardTemplatesUpdatedAtLabel: 'Последнее изменение',
    dashboardDigitalViewLabel: 'Цифровой дисплей',
    dashboardChartViewLabel: 'Графический вид',
    dashboardEventLoggerLabel: 'Регистратор событий',
    dashboardJobPropertiesLabel: 'Свойства работы',
    dashboardDataTableLabel: 'Табличный вид',
    dashboardAddNewDigitalDisplayLabel: 'Добавить цифровой дисплей',
    dashboardAddNewChartLabel: 'Добавить графический вид',
    dashboardAddNewDataTableLabel: 'Добавить таблицу данных',
    dashboardSearchChannelLabel: 'Найти канал',
    dashboardChannelsLabel: 'Каналы',
    dashboardOpenInNewWindowLabel: 'Открыть в новом окне',
    dashboardRemoveFromDashboardLabel: 'Удалить с панели',
    dashboardManageChannelsLabel: 'Управление каналами',
    dashboardExportToCsvLabel: 'Экспортировать в CSV',

    manageAxesModalManageAxesLabel: 'Управление координатными осями',
    manageAxesModalXAxisLabel: 'Ось Х',
    manageAxesModalRangeLabel: 'Диапазон',
    manageAxesModalAllLabel: 'Весь',
    manageAxesModalLastLabel: 'Последние',
    manageAxesModalFormatLabel: 'Формат',
    manageAxesModalTimeLabel: 'Время',
    manageAxesModalDateAndTimeLabel: 'Дата и Время',
    manageAxesModalSecondsLabel: 'Секунды',
    manageAxesModalModeLabel: 'Режим',
    manageAxesModalLocalLabel: 'Локальный',
    manageAxesModalDatabaseLabel: 'Базы данных',
    manageAxesModalElapsedLabel: 'Истекло',
    manageAxesModalYAxisLabel: 'Ось Y',
    manageAxesModalRangeSwitchLabel: 'Диапазон',
    manageAxesModalValueLabel: 'Значение',

    manageDateSettingsModalManageDateSettingsLabel: 'Управление настройками даты',
    manageDateSettingsModalLocalLabel: 'Локальный',
    manageDateSettingsModalDatabaseLabel: 'Базы данных',
    manageDateSettingsModalElapsedLabel: 'Истекло',
    manageDateSettingsModalTimeFormatLabel: 'Формат времени',
    manageDateSettingsModalTimeLabel: 'Время',
    manageDateSettingsModalDateAndTimeLabel: 'Дата и Время',
    manageDateSettingsModalSecondsLabel: 'Секунды',
    manageDateSettingsModalDateRangeLabel: 'Временной диапазон',
    manageDateSettingsModalAllLabel: 'Всё время',
    manageDateSettingsModalSelectRangeLabel: 'Выбрать диапазон',
    manageDateSettingsModalStartTimeLabel: 'Начало',
    manageDateSettingsModalStopTimeLabel: 'Конец',

    exportToCsvModalExportToCsvLabel: 'Экспортировать в CSV',
    exportToCsvModalFileNameLabel: 'Название файла',
    exportToCsvModalDelimiterSymbolLabel: 'Символ разделителя',
    exportToCsvModaDelimiterSymbolSemicolonLabel: 'Точка с запятой “ ; ”',
    exportToCsvModalDelimiterSymbolCommaLabel: 'Запятая “ , ”',
    exportToCsvModalDelimiterSymbolTabulatorLabel: 'Табулятор',
    exportToCsvModalSpaceLabel: 'Пробел',
    exportToCsvModalDecimalPointSeparatorLabel: 'Десятичный разделитель',
    exportToCsvModalPointSeparatorDotLabel: 'Точка “ . ”',
    exportToCsvModalPointSeparatorCommaLabel: 'Запятая “ , ”',
    exportToCsvModalDateAndTimeExportFormatLabel: 'Формат даты и время экспорта',

    siderMyCompanyLabel: 'Моя Компания',
    siderCompanyLabel: 'Компания',
    siderEquipmentLabel: 'Оборудование',
    siderDashboardLabel: 'Статистика',
    siderCatalogLabel: 'Шаблоны',
    siderActivitiesLabel: 'Деятельность',
    siderScheduledReportsLabel: 'Отчеты',

    popoverActiveLabel: 'Активный',
    popoverAddSubcomponentLabel: 'Добавить подкомпонент',
    popoverEditLabel: 'Редактировать',
    popoverDuplicateLabel: 'Дублировать',
    popoverChangeDepartmentLabel: 'Изменить департамент',
    popoverRemoveLabel: 'Удалить',
    popoverTakeToMyEquipmentLabel: 'Переместить в оборудование',
    popoverShowDetailsLabel: 'Детали',
    popoverAssignPersonLabel: 'Назначить работника',
    popoverMarkAsCompletedLabel: 'Отметить как выполненую',
    popoverDetailsLabel: 'Детали',

    sideMenuEditCompanyLabel: 'Редактировать компанию',
    sideMenuEditLogoLabel: 'Изменить лого',
    sideMenuAddNewDepartmentLabel: 'Добавить департамент',
    sideMenuAddNewEmployeeLabel: 'Добавить работника',
    sideMenuAddNewComponentLabel: 'Добавить компонент',
    sideMenuDuplicateLabel: 'Дублировать',
    sideMenuRemoveLabel: 'Удалить',
    sideMenuChangeDepartmentLabel: 'Изменить департамент',
    sideMenuTakeToMyEquipmentLabel: 'Переместить в оборудование',
    sideMenuAddNewPartLabel: 'Добавить деталь',
    sideMenuScheduledReportsLabel: 'Создать Отчет',
    sideMenuCreateNotificationLabel: 'Создать уведомление',
    sideMenuExportReportsLabel: 'Экспортировать в PDF',
    sideMenuPrintLabel: 'Экспортировать в PDF',
    sideMenuExportActivitiesPartsToPdfLabel: 'Экспортировать детали в PDF',
    sideMenuExportSparePartsLabel: 'Экспортировать запчасти в PDF',
    myCompanyDepartmentsLabel: 'Департаменты',
    myCompanyEmployeesLabel: 'Работники',
    myCompanyEmployeeNameHeader: 'Имя',
    myCompanyEmployeeDepartmentHeader: 'Департамент',
    myCompanyEmployeeRoleHeader: 'Должность',
    myCompanyEmployeePhoneNumberHeader: 'Номер телефона',
    myCompanyEmployeeEmailHeader: 'E-mail',

    equipmentPageLabel: 'Оборудование',
    equipmentPageMyEquipmentLabel: 'Мое Оборудование',
    equipmentPageRecommendedSparePartsLabel: 'Рекомендованные запчасти',
    equipmentPagePartsLabel: 'Детали',
    equipmentPageMyEquipmentNameHeader: 'Название',
    equipmentPageMyEquipmentIdHeader: 'ID',
    equipmentPageMyEquipmentSerialNumberHeader: 'Серийный номер',
    equipmentPageMyEquipmentNoteHeader: 'Заметка',
    equipmentPageMyEquipmentManufacturerHeader: 'Производитель',
    equipmentPageMyEquipmentWorkhoursTotalHeader: 'Рабочие часы',
    equipmentPageMyEquipmentActivitiesHeader: 'Деятельность',
    equipmentPageMyEquipmentActivitiesLabel: 'Деятельность',
    equipmentPageMyEquipmentStatusHeader: 'Статус',
    equipmentPageMyEquipmentStatusLabelActive: 'АКТИВНЫЙ',
    equipmentPageMyEquipmentStatusLabelInActive: 'НЕАКТИВНЫЙ',
    equipmentPageActivitiesTypeHeader: 'Тип',
    equipmentPageActivitiesDescriptionHeader: 'Описание',
    equipmentPageActivitiesPriorityHeader: 'Приоритет',
    equipmentPageActivitiesTasksHeader: 'Задачи',
    equipmentPageActivitiesDateHeader: 'Дата',
    equipmentPageActivitiesWorkhoursHeader: 'Рабочие часы',
    equipmentPageActivitiesAddActivityButton: 'Добавить деятельность',
    equipmentPageRecommendedSparePartsSelectComponentsLabel: 'Выбрать оборудование',
    equipmentPageRecommendedSparePartsSelectedComponentsLabel: 'Выбранное оборудование',
    equipmentPageRecommendedSparePartsSelectAllComponentsLabel: 'Выбрать все оборудование',
    equipmentPageRecommendedSparePartsPartHeader: 'Детали',
    equipmentPageRecommendedSparePartsPartIdHeader: 'ID Детали',
    equipmentPageRecommendedSparePartsSupplierHeader: 'Поставщик',
    equipmentPageRecommendedSparePartsUidHeader: 'Серийный номер',
    equipmentPageRecommendedSparePartsSupplierPartIdHeader: 'ID Детали Поставщика',
    equipmentPageRecommendedSparePartsManufacturerHeader: 'Производитель',
    equipmentPageRecommendedSparePartsManufacturerTypeHeader: 'Тип производителя',
    equipmentPageRecommendedSparePartsManufacturerPartNumberHeader: 'Номер детали производителя',
    equipmentPageRecommendedSparePartsQuantityHeader: 'Количество',
    equipmentPagePartsPartHeader: 'Детали',
    equipmentPagePartsPartIdHeader: 'ID детали',
    equipmentPagePartsUidHeader: 'Серийный номер',
    equipmentPagePartsSupplierHeader: 'Поставщик',
    equipmentPagePartsSupplierPartIdHeader: 'ID Детали поставщика',
    equipmentPageManufacturerHeader: 'Производитель',
    equipmentPageManufacturerTypeHeader: 'Тип производителя',
    equipmentPageManufacturerPartNumberHeader: 'Номер детали производителя',

    catalogPageLabel: 'Шаблоны',
    catalogPageDropdownComponentsOption: 'Компоненты',
    catalogPageDropdownPartsOption: 'Детали',

    searchAssetsAndPartsLabel: 'Поиск...',

    activitiesNameHeader: 'Описание',
    activitiesPageActivitiesLabel: 'Деятельность',
    activitiesPageNoActivitiesFoundLabel: 'Деятельность не найдена.',
    activitiesPageAdjustCriteriaLabel: 'Попробуйте изменить критерии фильтра.',
    activitiesPageComponentsLabel: 'Компоненты',
    activitiesPageComponentHeader: 'Компонент',
    activitiesPageTypeHeader: 'Тип',
    activitiesPagePriorityHeader: 'Приоритет',
    activitiesPageTasksHeader: 'Задачи',
    activitiesPageAssignedToHeader: 'Назначить',
    activitiesPageDateHeader: 'Выполнение',
    activitiesPageWorkhoursHeader: 'Рабочие часы',
    activitiesPageDueHeader: 'К выполнению',
    activitiesPageRemainingHeader: 'Оставшаяся',
    activitiesPageCompletedHeader: 'Завершенная',
    activitiesPageStatusHeader: 'Статус',
    activitiesPageStatusHeaderOpened: 'ОТКРЫТАЯ',
    activitiesPageStatusHeaderCompeted: 'ЗАВЕРШЕННАЯ',
    activitiesPageStatusHeaderRemoved: 'УДАЛЕНА',
    activitiesPageStatusHeaderOverdue: 'ПРОСРОЧЕННАЯ',
    activitiesPageStatusHeaderUpcoming: 'ПРЕДСТОЯЩАЯ',
    activitiesPagePartsTableLabel: 'Выбранный список деталей деятельности',
    activitiesPagePartsEmptyListPlaceholderLabel: 'Детали деятельности не найдены',
    activitiesPagePartsTablePartHeader: 'Детали',
    activitiesPagePartsTablePartNumberHeader: 'Номер Детали',
    activitiesPagePartsTableManufacturerHeader: 'Производитель',
    activitiesPagePartsTableManufacturerTypeHeader: 'Тип Производителя',
    activitiesPagePartsTableAlternativePartHeader: 'Альтернативная деталь',
    activitiesPagePartsTableQuantityHeader: 'Кол-во',
    activitiesPageTasksTableTaskLabel: 'Задача',
    activitiesPageTasksTablePartToReplaceLabel: 'Деталь для замены',
    activitiesPageTasksTableQuantityLabel: 'Кол-во',
    activitiesPageTasksTablePartReplacedLabel: 'Замененная деталь',
    activitiesPageTasksTableDoneLabel: 'Выполнено',
    activitiesPageAlternativePartLabel: 'Альтернативная деталь',

    scheduledReportsPageLabel: 'Oтчеты',
    scheduledReportsTabLabel: 'Плановые отчеты',
    notificationsPageLabel: 'Уведомления',
    scheduledReportsPageNameHeader: 'Название',
    scheduledReportsPageIntervalHeader: 'Периодичность',
    scheduledReportsPageRecipientsHeader: 'Получатели',
    scheduledReportsPageStatusHeader: 'Статус',
    scheduledReportsPageStatusLabelActive: 'АКТИВНЫЙ',
    scheduledReportsPageStatusLabelInActive: 'НЕАКТИВНЫЙ',

    notificationsComponentsQTYHeader: 'К-во компонентов',
    notificationsAssetsHeader: 'Оборудование',
    notificationsComponentsHeader: 'Компоненты',
    saveNotificationAddLabel: 'Добавить уведомление',
    saveNotificationEditLabel: 'Детали уведомления',
    notificationsPageStatusLabelInActive: 'НЕАКТИВНЫЙ',
    notificationsPageStatusLabelActive: 'АКТИВНЫЙ',

    saveCompanyModalEditCompanyLabel: 'Редактировать компанию',
    saveCompanyModalNameLabel: 'Название',
    saveCompanyModalDescriptionLabel: 'Описание',

    saveDepartmentModalAddDepartmentLabel: 'Добавить департамент',
    saveDepartmentModalEditDepartmentLabel: 'Редактировать департамент',
    saveDepartmentModalNameLabel: 'Название',
    saveDepartmentModalAssigneeNameLabel: 'Контактное лицо',
    saveDepartmentModalContactPersonLabel: 'Контактное лицо',
    saveDepartmentModalEmailLabel: 'E-mail',
    saveDepartmentModalTelephoneLabel: 'Tелефон',
    saveDepartmentModalFromDepartmentLabel: 'Из департамента',
    saveDepartmentModalOthersLabel: 'Другие',

    saveEmployeeModalAddEmployeeLabel: 'Добавить работника',
    saveEmployeeModalEditEmployeeLabel: 'Редактировать работника',
    saveEmployeeModalNameLabel: 'Имя',
    saveEmployeeModalRoleLabel: 'Должность',
    saveEmployeeModalPhoneNumberLabel: 'Номер телефона',
    saveEmployeeModalEmailLabel: 'E-mail',
    saveEmployeeModalSelectDepartmentLabel: 'Выберите отдел',

    saveComponentModalAddComponentLabel: 'Добавить компонент',
    duplicateComponentModalAddComponentLabel: 'Дублировать компонент',
    saveComponentModalEditComponentLabel: 'Редактировать компонент',
    saveComponentModalAddChildComponentLabel: 'Добавить компонент',
    duplicateComponentModalAddChildComponentLabel: 'Дублировать компонент',
    saveComponentModalEditChildComponentLabel: 'Редактировать компонент',
    saveComponentModalChooseFromCatalogLabel: 'Выбрать из Шаблонов',
    saveComponentModalSelectFromCatalogOption: 'Выбрать из Шаблонов',
    saveComponentModalCatalogOption: 'Шаблоны',
    saveComponentModalEquipmentOption: 'Оборудование',
    saveComponentModalComponentLabel: 'Компонент',
    saveComponentModalComponentComponentLabel: 'Компонент',
    saveComponentModalComponentNameLabel: 'Название',
    saveComponentModalComponentPartNumberLabel: 'ID/Номер Детали',
    saveComponentModalComponentUidLabel: 'Серийный номер',
    saveComponentModalSupplierLabel: 'Поставщик',
    saveComponentModalSupplierNameLabel: 'Название',
    saveComponentModalSupplierPartNumberLabel: 'Номер детали',
    saveComponentModalSupplierDeliveryTimeLabel: 'Срок доставки [дни]',
    saveComponentModalManufacturerLabel: 'Производитель',
    saveComponentModalManufacturerNameLabel: 'Название',
    saveComponentModalManufacturerPartNumberLabel: 'Номер детали',
    saveComponentModalModelLabel: 'Номер детали',
    saveComponentModalManufacturerTypeLabel: 'Тип/Модель',
    saveComponentModalManufacturerModelLabel: 'Модель',
    saveComponentModalWorkhoursLabel: 'Рабочие часы',
    saveComponentModalWorkhoursTotalLabel: 'Всего рабочих часов',
    saveComponentModalNoteLabel: 'Заметка',
    saveComponentModalSelectUnitLabel: 'Выбрать блок',
    saveComponentModalSelectChannelLabel: 'Выбрать канал',
    saveComponentModalWorkhoursFactorLabel: 'Коэффициент рабочих часов',
    saveComponentModalDimensionsLabel: 'Размеры',
    saveComponentModalLengthLabel: 'Длинна',
    saveComponentModalWidthLabel: 'Ширина',
    saveComponentModalHeightLabel: 'Высота',
    saveComponentModalWeightLabel: 'Вес',
    saveComponentModalWeightPlaceholder: 'Вес',
    saveComponentModalRecommendedSparePartsLabel: 'Рекомендованные запчасти',
    saveComponentModalAddButtonLabel: 'Добавить',
    saveComponentModalUserManualsLabel: 'Руководства пользователя, файлы',
    saveComponentModalUploadButtonLabel: 'Загрузить',
    saveComponentSyncCloudLatestJobLabel: 'Последняя работа: ',
    saveComponentSyncCloudLatestJobLabelStartTime: 'Время старта: ',
    saveComponentSyncCloudLatestJobLabelLicense: 'Лицензия: ',
    recommendedSparePartsEmptyListPlaceholderLabel: 'Рекомендованых запчастей не найдено',

    cloneComponentModalPermissionLabel: 'Доступ',
    cloneComponentModalMessageLabel: 'Вы действительно хотите клонировать компонент?',
    cloneComponentsToEquipmentModalMessageLabel: `Вы действительно хотите клонировать компоненты в своё оборудование?`,
    clonePartsToEquipmentModalMessageLabel: `Вы действительно хотите клонировать детали в своё оборудование?`,

    removeComponentModalPermissionLabel: 'Доступ',
    removeComponentModalMessageLabel: 'Вы действительно хотите удалить компонент?',
    removeComponentsModalMessageLabel: 'Вы действительно хотите удалить компоненты?',
    removePartModalMessageLabel: 'Вы действительно хотите удалить деталь?',
    removePartsModalMessageLabel: 'Вы действительно хотите удалить детали?',
    removeReportModalMessageLabel: 'Вы действительно хотите удалить отчет?',
    removeReportsModalMessageLabel: 'Вы действительно хотите удалить отчеты?',
    removeUserModalMessageLabel: 'Вы действительно хотите удалить пользователя?',
    removeJobModalMessageLabel: 'Вы действительно хотите удалить работу "{name}"?',
    removeSettingsTemplateModalMessageLabel: 'Вы действительно хотите удалить шаблон "{name}"?',
    removeOneJobPasswordModalMessageLabel: 'Вы действительно хотите удалить пароль одной работы?',
    removeTemplateModalMessageLabel: 'Вы действительно хотите удалить шаблон?',
    removeDepartmentModalMessageLabel: 'Вы действительно хотите удалить департамент?',
    removeEmployeeModalMessageLabel: 'Вы действительно хотите удалить работника?',
    removeTaskModalMessageLabel: 'Вы действительно хотите удалить задачу?',
    removeActivityModalMessageLabel: 'Вы действительно хотите удалить деятельность?',

    changeDepartmentModalLabel: 'Изменить департамент',

    savePartModalAddPartLabel: 'Добавить деталь',
    savePartModalEditPartLabel: 'Редактировать деталь',
    savePartModalChooseFromCatalogLabel: 'Выбрать из Шаблонов',
    savePartModalSelectFromCatalogOption: 'Выбрать из Шаблонов',
    savePartModalPartLabel: 'Деталь',
    savePartModalPartNameLabel: 'Название',
    savePartModalPartNumberLabel: 'ID/Номер Детали',
    savePartModalPartUidLabel: 'Серийный номер',
    savePartModalSupplierLabel: 'Поставщик',
    savePartModalSupplierNameLabel: 'Название',
    savePartModalSupplierPartNumberLabel: 'Номер детали',
    savePartModalSupplierDeliveryTimeLabel: 'Срок доставки [дни]',
    savePartModalManufacturerLabel: 'Производитель',
    savePartModalManufacturerNameLabel: 'Название',
    savePartModalManufacturerPartNumberLabel: 'Номер детали',
    savePartModalManufacturerTypeLabel: 'Тип/Модель',
    savePartModalDimensionsLabel: 'Размеры',
    savePartModalLengthLabel: 'Длинна',
    savePartModalWidthLabel: 'Ширина',
    savePartModalHeightLabel: 'Высота',
    savePartModalWeightLabel: 'Вес',
    savePartModalWeightPlaceholder: 'Вес',
    savePartModalUserManualsLabel: 'Руководства пользователя, файлы',
    savePartModalUploadButtonLabel: 'Загрузить',

    takeComponentsModalPermissionLabel: 'Доступ',
    takeComponentsModalMessageLabel: 'Вы действительно хотите добавить компонент в ваше Оборудование?',

    saveActivityModalAddActivityLabel: 'Добавить активность',
    saveActivityModalEditActivityLabel: 'Редактировать активность',
    saveActivityModalActivityLabel: 'Деятельность',
    saveActivityModalNameLabel: 'Описание',
    saveActivityModalTypeLabel: 'Тип',
    saveActivityModalOccurrenceLabel: 'Частота',
    saveActivityModalPriorityLabel: 'Приоритет',
    saveActivityModalActionTriggersLabel: 'Триггеры действия',
    saveActivityModalAddAsCompleted: 'Выполненый',
    saveActivityModalOnDemand: 'По требованию',
    saveActivityModalTimeLabel: 'Дата',
    saveActivityModalDateLabel: 'Дата',
    saveActivityModalOccurrenceIntervalLabel: 'Интервал повторения',
    saveActivityModalNotificationsLabel: 'Уведомления',
    saveActivityModalWorkhoursLabel: 'Рабочие часы',
    saveActivityModalTimeIntervalLabel: 'Период времени',
    saveActivityModalDateIntervalLabel: 'Период',
    saveActivityModalNextOccurrenceLabel: 'Следующее выполнение',
    saveActivityModalWorkhoursIntervalLabel: 'Инт. рабочих часов',
    saveActivityModalCompleteWorkhoursLabel: 'Рабочие часы',
    saveActivityModalNextOccurenceLabel: 'Следующее выполнение',

    saveActivityOccurrenceTab: 'Частота',
    saveActivityNotificationSettingsTab: 'Настройки уведомлений',
    notificationsDateTimeEnabled: 'Время',
    notificationsNextDateTime: 'Следующая дата уведомления',
    notificationsDateTimePeriod: 'Количество',
    notificationsDateRange: 'Интервал',
    notificationsWorkhoursEnabled: 'Рабочие часы',
    notificationsWorkhoursOffset: 'К-во рабочих часов',
    removeNotificationModalMessageLabel: 'Вы правда хотите удалить "{name}" уведомление?',
    removeNotificationsModalMessageLabel: 'Вы правда хотите удалить выбраное уведомление?',

    saveActivityModalTasksLabel: 'Задачи',
    saveActivityModalAddButtonLabel: 'Добавить',
    saveActivityModalTaskLabel: 'Задача',
    saveActivityModalPartToReplaceLabel: 'Деталь для замены',
    saveActivityModalQuantityLabel: 'Кол-во',
    saveActivityModalAlternativePartLabel: 'Альтернативная деталь',
    saveActivityModalSelectAlternativePartLabel: 'Выбрать альтернативную деталь',
    saveActivityModalEstimatedWorkTimeLabel: 'Расчетное время работы',
    saveActivityModalAmountLabel: 'Количество',
    saveActivityModalSkillsLabel: 'Квалификация',
    saveActivityModalToolsLabel: 'Инструменты',
    saveActivityModalCommentsLabel: 'Комментарии',
    saveActivityModalFilesLabel: 'Файлы',
    saveActivityModalUploadButtonLabel: 'Загрузить',

    filterActivitiesModalLabel: 'Деятельность',
    filterActivitiesModalStatusLabel: 'Статус',
    filterActivitiesModalSelectStatusLabel: 'Выбрать статус',
    filterActivitiesModalDateRangeLabel: 'Временной промежуток',
    filterActivitiesModalSelectDateRangeLabel: 'Выбрать временной промежуток',
    filterActivitiesModalComponentLabel: 'Компонент',
    filterActivitiesModalSelectComponentLabel: 'Выбрать компонент',
    filterActivitiesModalPriorityLabel: 'Приоритет',
    filterActivitiesModalSelectPriorityLabel: 'Выбрать приоритет',
    filterActivitiesModalAssigneeLabel: 'Исполнитель',
    filterActivitiesModalSelectAssigneeLabel: 'Исполнитель',
    filterActivitiesModalNoAssigneeLabel: 'Без исполнителя',
    filterActivitiesModalSelectPartLabel: 'Выбрать деталь',

    assignPersonModalLabel: 'Назначить исполнителя',
    assignPersonModalSelectAssigneeLabel: 'Выбрать исполнителя',

    markAsCompletedModalLabel: 'Отметить как выполненую',
    markAsCompletedModalEmployeeLabel: 'Работник',
    markAsCompletedModalSelectAssigneeLabel: 'Выбрать работника',
    markAsCompletedModalSelectPartLabel: 'Выбрать деталь',
    markAsCompletedModalDateLabel: 'Дата',
    markAsCompletedModalAmountLabel: 'Количество',
    markAsCompletedModalIntervalLabel: 'Периодичность',
    markAsCompletedModalTaskHeader: 'Задача',
    markAsCompletedModalNoTasksWereAdded: 'Нет добавленных задач',
    markAsCompletedModalTaskInstructionPopoverTaskInstructionLabel: 'Описание задачи',
    markAsCompletedModalTaskInstructionPopoverPartToReplaceLabel: 'Деталь для замены:',
    markAsCompletedModalTaskInstructionPopoverQtyLabel: 'Кол-во:',
    markAsCompletedModalTaskInstructionPopoverAlternativePart: 'Альтернативная деталь:',
    markAsCompletedModalPartReplacedHeader: 'Замененная Деталь',
    markAsCompletedModalQtyHeader: 'Кол-во',
    markAsCompletedModalDoneHeader: 'Выполнено',
    markAsCompletedModalCommentsLabel: 'Комментарии',
    markAsCompletedModalFilesLabel: 'Файлы',
    markAsCompletedModalUploadButtonLabel: 'Загрузить',

    saveReportModalAddReportLabel: 'Создать отчет',
    saveReportModalEditReportLabel: 'Редактировать отчет',
    saveReportModalReportSpecificationLabel: 'Детали отчета',
    saveReportModalNameLabel: 'Название',
    saveReportModalFrequencyLabel: 'Периодичность',
    saveReportModalSelectFrequencyLabel: 'Выбрать переодичность',
    saveReportModalTimeLabel: 'Время:',
    saveReportModalStartDateLabel: 'Дата начала',
    saveReportModalRecipientsLabel: 'Получатели',
    saveReportModalAddButtonLabel: 'Добавить',
    saveReportModalAppliedFiltersLabel: 'Примененные Фильтры',
    saveReportModalStatusLabel: 'Статус',
    saveReportModalSelectStatusLabel: 'Выбрать статус',
    saveReportModalDateRangeLabel: 'Временной промежуток',
    saveReportModalSelectDateRangeLabel: 'Выбрать временной промежуток',
    saveReportModalComponentLabel: 'Компонент',
    saveReportModalSelectComponentLabel: 'Выбрать компонент',
    saveReportModalPriorityLabel: 'Приоритет',
    saveReportModalSelectPriorityLabel: 'Выбрать приоритет',
    saveReportModalAssigneeLabel: 'Исполнитель',
    saveReportModalSelectAssigneeLabel: 'Выбрать исполнителя',
    saveReportModalNoAssigneeLabel: 'Без исполнителя',

    notificationsInvavidDates: "'Время начала' должно быть меньше чем 'Время окончания'",
    notificationsExportError: 'Во время выгрузки произошла ошибка',
    notificationsComponentsDeleted: 'Компоненты успешно удалены!',
    notificationsComponentDeleted: 'Компонент успешно удален!',
    notificationsPartsDeleted: 'Детали успешно удалены!',
    notificationsPartDeleted: 'Деталь успешно удалена!',
    notificationsPartAdded: 'Деталь успешно добавлена',
    notificationsPartUpdated: 'Деталь успешно обновлена!',
    notificationsPartsCloned: 'Детали успешно клонированы!',
    notificationsPartCloned: 'Деталь успешно клонирована!',
    notificationsReportsDeleted: 'Отчеты успешно удалены!',
    notificationsReportDeleted: 'Отчет успешно удален!',
    notificationsReportAdded: 'Отчеты успешно добавлен!',
    notificationsReportUpdated: 'Отчеты успешно обновлен!',
    notificationsFileNameRequired: 'Требуется имя файла',
    notificationsLoadTemolateError: 'Шаблон не может быть загружен. Пользователь не имеет прав доступа на просмотр каналов шаблона',
    notificationsEmployeeAssigned: 'Работник успешно назначен!',
    notificationsActivityCompleted: 'Деятельность успешно выполнена!',
    notificationsActivityUpdated: 'Деятельность успешно обновлена!',
    notificationsActivityAdded: 'Деятельность успешно добавлена!',
    notificationsActivityCompletedAdded: 'Деятельность успешно добавлена и выполнена!',
    notificationsComponentsCloned: 'Компоненты успешно клонированы!',
    notificationsComponentCloned: 'Компонент успешно клонирован!',
    notificationsAssetAdded: 'Компонент успешно добавлен!',
    notificationsComponentAdded: 'Компонент успешно добавлен!',
    notificationsTemplateAdded: 'Шаблон успешно добавлен!',
    notificationsComponentUpdated: 'Компонент успешно обновлен!',
    notificationsCompanyUpdated: 'Детали Компании успешно обновлены!',
    notificationsDepartmentUpdated: `Департамент успешно обновлен!`,
    notificationsDepartmentAdded: `Департамент успешно добавлен!`,
    notificationsDepartmentDeleted: 'Департамент успешно удален!',
    notificationsEmployeeUpdated: `Работник успешно обновлен!`,
    notificationsEmployeeAdded: `Работник успешно добавлен!`,
    notificationsEmployeeDeleted: `Работник успешно удален!`,
    notificationsTaskDeleted: `Задача успешно удалена!`,
    notificationsActivityDeleted: `Деятельность успешно удалена!`,
    notificationsComponentDepartmentChanged: `Компонент Департамента успешно обновлен!`,
    notificationsEmailResent: 'E-mail отправлен повторно!',
    notificationsLinkCopied: 'Ссылка скопирована!',

    emailConfirmationPageLabel: 'Подтверждение электронной почты',

    passwordResetPageLabel: 'Создать новый пароль',

    setPasswordPageLabel: 'Задать пароль',

    changePasswordPageLabel: 'Изменить пароль',
    changePasswordPageOldPasswordLabel: 'Старый пароль',
    changePasswordPagePasswordLabel: 'Пароль',
    changePasswordPageConfirmPasswordLabel: 'Подтвердить пароль',
    changePasswordPageBackLabel: 'Назад',

    signInPageEmailSwitchLabel: 'E-mail',
    signInPageEmailLabel: 'E-mail',
    signInPageOneJobPasswordLabel: 'Пароль Одной Работы',
    signInPagePasswordLabel: 'Пароль',
    signInPageForgotLabel: 'Забыли пароль?',

    forgotPasswordPageLabel: 'Забыли пароль?',
    forgotPasswordPageEnterEmailLabel: 'Пожалуйста, введите адрес электронной почты',
    forgotPasswordPageEmailAddressLabel: 'Адрес электронной почты',
    forgotPasswordPageSignInLabel: 'Войти',
    forgotPasswordPagePasswordLabel: 'Пароль',
    forgotPasswordPageConfirmPasswordLabel: 'Подтвердить пароль',
    forgotPasswordPageRequestSentLabel: 'На указанный вами электронный адрес отправлено письмо с ссылкой на восстановление пароля. Пройдите по ней для завершения процедуры восстановления пароля',

    signUpLabel: 'Регистрация',
    signUpPageAccountRegisteredLabel: 'Учетная запись была успешно зарегистрирована. На указанный вами электронный адрес было отправлено письмо со ссылкой. Пройдите по ней для завершения процедуры регистрации  ',
    signUpPageLicenseIdLabel: 'ID Лицензии',
    signUpPageEmailLabel: 'E-mail',
    signUpPagePasswordLabel: 'Пароль',
    signUpPageConfirmPasswordLabel: 'Подтвердить пароль',
    signUpPageHaveAnAccountLabel: 'Есть учетная запись?',
    signUpPageLoginLabel: 'Войти',

    dbSizeInfo: '{used} из {limit}Гб',
    tableColumnName: 'Имя',
    tableColumnStartTime: 'Начало',
    tableColumnUpdatedAtTime: 'Последнее изменение',
    tableColumnStopTime: 'Конец',
    tableColumnSize: 'Размер, Мб',
    tableColumnJobType: 'Тип',

    deleteJobMessage: 'Работа успешно удалена',
    deleteTemplateMessage: 'Шаблон успешно удален',

    mmsAccessError: 'Срок действия модуля MMS истек или не найден в файле лицензии. Пожалуйста, обратитесь к системному администратору',

    selectJobLabel: 'Выбрать работу',
    selectDateRangeLabel: 'Выбрать период',
    selectFilterType: 'Выбрать фильтр',
    jobLabel: 'Работа',
    dateRangeLabel: 'Период',

    clearAllFiltersLabel: 'Очистить фильтры',
    showFiltersLabel: 'Показать фильтры',
    hideFiltersLabel: 'Скрыть фильтры',

    emptyDataTitle: 'Данные не найдены.',
    emptyDataSubtitle: 'Попробуйте изменить критерии поиска.',
    valueLabel: 'Значение',
    fromLabel: 'От',
    toLabel: 'До',
    yesLabel: 'Да',
    nowLabel: 'Нет',
    confirmChangeJobQuestion: 'Вы уверены что хотите изменить работу?',
    confirmChangeUnitQuestion: 'Вы уверены что хотите изменить блок?',

    exportBuildingStartedMessage: 'Экспорт успешно начат',
    downloadBtnLabel: 'Загрузить',
    buildingCsvLabel: 'Создание CSV',

    confirmChangeRootRoleQuestion: 'Existing Root user will be overwritten. Are you sure you want to proceed?',

    dataUsageCompanyNameLabel: 'Company Name',
    dataUsageTotalImportsLabel: 'Total Imports',
    dataUsageTotalLocationsLabel: 'Total Locations',
    dataUsageTotalSizeLabel: 'Total Size (MB)',
    dataUsageTotalUnitsLabel: 'Total Units',
    dataUsageTotalJobsLabel: 'Total Jobs',

    selectComponentLabel: 'All components',

    timezoneLabel: 'Таймзона'
};
