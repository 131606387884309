import moment from 'moment';
import { formatPointTime, timeOrNow } from 'utils/functions';
import { ColorHEX, ColorRGBA, SolidFill } from '@arction/lcjs';
import { setStoredDashboard } from './storage/dashboard-storage';
import Axios from 'axios';

const uuid = require('uuid/v1');

export const jobStartTimeTimestamp = job => job ? moment(job.startTime).valueOf() : null;

export const userHasPermission = (user, permission) => {
    return user
        && user.permissions
        && user.permissions.includes(permission);
};

export const allPropsTrue = obj => {
    for (let prop in obj)
        if (!obj[prop]) return false;

    return true;
};

export const lastPointTimestampFromTimeBasedChartResponse = response => {
    if (response && response[0] && response[0].data) {
        const firstChannelData = response[0].data;
        return firstChannelData[firstChannelData.length - 1].timestamp;
    }
    return timeOrNow(false);
};

export const lastPointTimestampFromDataBasedChartResponse = response => {
    if (response
        && response.data
        && response.data.yAxisResponses
        && response.data.yAxisResponses[0]
        && response.data.yAxisResponses[0].dataPoints
    ) {
        const firstChannelData = response.data.yAxisResponses[0].dataPoints;
        return firstChannelData[firstChannelData.length - 1].timestamp;
    }
    return timeOrNow(false);
};

export const toHHMMSS = (secs) => {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return [hours, minutes, seconds]
        .map(v => v < 10 ? '0' + v : v)
        // .filter((v,i) => v !== "00" || i > 0)
        .join(':');
};

export const removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
};

export const uniqueValues = arr => {
    if (Array.isArray(arr)) {
        return Array.from(new Set(arr));
    }
};

export const timeServerToLocal = (inp, zone) => {
    return moment.tz(inp, zone).isValid() ? moment.tz(inp, zone).local().format() : null;
};

export const jobTimeFormat = (value, zone) => {
    return moment.tz(value, zone).isValid() ? moment.tz(value, zone).local().format('YYYY-MM-DD HH:mm:ss') : null;
};

export const appLangCode = (lang) => {
    let defaultCode = 'en-EN';
    const codes = {
        'Russian': 'ru-RU',
        'English': 'en-EN',
    };
    return codes[lang] || defaultCode;
};

export const chartHelper = {
    getLicenceKey() {
        const key = process.env.REACT_APP_ARCTION_CHART_KEY || undefined;
        return key === 'false' ? undefined : key;
    },

    getStartDateFromChannelsData(data) {
        if (!data) return 0;
        return (data[0] && data[0].data[0]) ? data[0].data[0][0] : 0;
    },

    getTimeInMilliseconds(xAxisSettings) {
        if (!xAxisSettings) return 0;
        if (xAxisSettings.autoScaling) return 0;

        const map = {
            'sec': 1000,
            'min': 60000,
            'hr': 3600000,
        };
        const value = map[xAxisSettings.unit];
        return value ? xAxisSettings.count * value : 0;
    },

    getFontColor(theme) {
        const color = theme === 'Dark'
            ? ColorRGBA(255, 255, 255, 224)
            : ColorHEX('#444444');
        return new SolidFill({ color });
    },

    getStrokeColor(theme) {
        const color = theme === 'Dark'
            ? ColorRGBA(255, 255, 255, 120)
            : ColorRGBA(68, 68, 68, 50);
        return new SolidFill({ color });
    },

    getChartBackgroundColor(theme) {
        const color = theme === 'Dark'
            ? ColorHEX('#121212')
            : ColorHEX('#fafafa');
        return new SolidFill({ color });
    },

    appendChartSettings(boxData, templateBox = null) {
        if (boxData.type !== 'chartView') return;
        if (!boxData.channels) return;

        const units = uniqueValues(boxData.channels.map(channel => channel.unit));
        if (templateBox && templateBox.yAxisSettings) {
            boxData.yAxisSettings = templateBox.yAxisSettings;
        } else {
            boxData.yAxisSettings = uniqueValues(units.map(unit => {
                return {
                    unit: unit,
                    autoScaling: true,
                    min: undefined,
                    max: undefined,
                };
            }));
        }

        if (templateBox && templateBox.xAxisSettings) {
            boxData.xAxisSettings = templateBox.xAxisSettings;
        } else {
            if (boxData.chartType === 'timeBased') {
                boxData.xAxisSettings = {
                    count: undefined,
                    autoScaling: true,
                    unit: 'sec',
                };
            }
            // else {
            //     const mainChannel = jobChannel(currentJob, boxData.mainXYChannel);
            //     boxData.xAxisSettings = {
            //         unit: mainChannel.unit,
            //         autoScaling: true,
            //         min: undefined,
            //         max: undefined,
            //     };
            // }
        }
    },

    editChartSettings(boxData) {
        const units = uniqueValues(boxData.channels.map(channel => channel.unit));
        boxData.yAxisSettings = boxData.yAxisSettings.filter(s => units.includes(s.unit));
        units.map(unit => {
            if (!boxData.yAxisSettings.find(s => s.unit === unit)) {
                boxData.yAxisSettings.push(
                    {
                        unit: unit,
                        autoScaling: true,
                        min: undefined,
                        max: undefined,
                    });
            }
            return unit;
        });
    },

    colors(idx, mapping) {
        const values = [
            '#ff3925',
            '#009513',
            '#ffa42b',
            '#9b644c',
            '#bf4588',
            '#e6cb00',
            '#0562c0',
            '#f5845a',
            '#4b8b03',
            '#a356a2',
        ];
        const colorIndex = mapping.values[idx];
        return values[colorIndex];
    },
};

export const sortChannels = (currentJob, data) => {
    return [...data];
};

export const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size),
    );

export const useEffectDebug = name => () => {
    console.log(`${name} 😀`);
    return () => {
        console.log(`${name} ❌`);
    };
};

export const boxHelper = {
    colors(idx, mapping) {
        const values = [
            '#ff3925',
            '#009513',
            '#ffa42b',
            '#9b644c',
            '#bf4588',
            '#e6cb00',
            '#0562c0',
            '#f5845a',
            '#4b8b03',
            '#a356a2',
        ];
        const colorIndex = mapping?.values?.[idx] || 0;
        return values[colorIndex];
    },

    generateColorsMap(prevColors, boxes) {
        if (boxes.length === 0) {
            return {
                lastIndex: 0,
                values: {},
            };
        }

        const maxColorIndex = 10;
        let index = prevColors.lastIndex || 0;
        const colors = { ...prevColors.values };
        boxes.forEach(b => {
            b.channels.forEach(ch => {
                if (colors[ch.code] === undefined) {
                    colors[ch.code] = index;
                    index = index === (maxColorIndex - 1) ? 0 : index + 1;
                }
            });
        });
        return {
            lastIndex: index,
            values: colors,
        };
    },

    addBoxes(prevBoxes, boxes) {
        return [...prevBoxes, ...boxes];
    },
    addBox(prevBoxes, data, filters) {
        return [...prevBoxes, { ...data, filters }];
    },
    updateBox(prevBoxes, data) {
        let boxes = [...prevBoxes];
        let editedBoxIndex = boxes.findIndex(box => box.id === data.id);
        if (editedBoxIndex !== -1) boxes[editedBoxIndex] = {
            ...boxes[editedBoxIndex],
            ...data,
        };
        return boxes;
    },

    updateBoxesKey(prevBoxes, key, value) {
        return prevBoxes.map(item => ({ ...item, [key]: { ...item[key], ...value } }));
    },

    removeBox(prevBoxes, id) {
        return prevBoxes.filter(box => box.id !== id);
    },

    updateDragAndDropPosition(boxes) {
        return boxes.map((boxData, index) => {
            if (boxData.itemPosition.position === 'Left') {
                if (!boxData[index + 1] || boxData[index + 1].itemPosition.position !== 'Right') {
                    boxData.itemPosition.position = 'Stretch';
                }
            }
            if (boxData.itemPosition.position === 'Right') {
                if (!boxData[index - 1] || boxData[index - 1].itemPosition.position !== 'Left') {
                    boxData.itemPosition.position = 'Stretch';
                }
            }
            return boxData;
        });
    },
};

export const dashboardHelper = {
    add(data, dashboardType = 'job') {
        const {
            id,
            parent,
            box,
            job,
            unit,
            company,
            filters,
        } = data;

        const newDashboardId = !!id ? id : uuid();
        let windowId = null;
        let boxWithFilters;

        if (Array.isArray(box)) {
            boxWithFilters = box.map(b => ({ ...b, filters: { ...b.filters, ...filters } }));
        } else {
            boxWithFilters = { ...box, filters: { ...box.filters, ...filters } };
        }

        if (parent) {
            const { protocol, host } = window.location;
            const dashboardData = {
                id: newDashboardId,
                parentDashboardId: parent,
                job,
                company,
                unit,
                box: boxWithFilters,
                windowId,
            };
            setStoredDashboard(newDashboardId, dashboardData);
            let newWindowUrl = `${protocol}//${host}/dashboard/${newDashboardId}`;
            let windowParams = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000';
            if (dashboardType === 'unit') {
                newWindowUrl = `${protocol}//${host}/units/dashboard/${newDashboardId}`;
            }
            if (dashboardType === 'location') {
                newWindowUrl = `${protocol}//${host}/locations/dashboard/${newDashboardId}`;
            }
            window.open(newWindowUrl, '_blank', windowParams);
        }
        return newDashboardId;
    },
};

export const dragNDropHelper = {
    setNearbyBoxPosition(boxes, sourcePosition, sourceIndex) {
        switch (sourcePosition) {
            case 'Left':
                boxes[sourceIndex + 1].itemPosition.position = 'Stretch';
                break;
            case 'Right':
                boxes[sourceIndex - 1].itemPosition.position = 'Stretch';
                break;
            default:
                break;
        }
    },

    reorder(boxes, startIndex, endIndex) {
        const result = Array.from(boxes);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    },

    combineAndUpdateBoxes(boxes, source, combine) {
        const sourceIndex = source.index;
        const destinationId = combine.draggableId;

        const sourceBox = boxes[sourceIndex];
        const destinationBox = boxes.find(x => x.id === destinationId);

        if (!sourceBox || !destinationBox) {
            return;
        }

        if (destinationBox.itemPosition.position !== 'Stretch') {
            return;
        }

        const destinationIndex = boxes.indexOf(destinationBox);

        this.setNearbyBoxPosition(boxes, sourceBox.itemPosition.position, sourceIndex);
        destinationBox.itemPosition.position = 'Left';
        sourceBox.itemPosition.position = 'Right';

        let endIndex = destinationIndex;

        if (sourceIndex > destinationIndex)
            endIndex = destinationIndex + 1;

        const reorderedBoxes = this.reorder(boxes, sourceIndex, endIndex);
        return reorderedBoxes;
    },

    updateBoxes(boxes, source, destination) {
        this.setNearbyBoxPosition(
            boxes,
            boxes[source.index].itemPosition.position,
            source.index,
        );
        boxes[source.index].itemPosition.position = 'Stretch';

        return this.reorder(boxes, source.index, destination.index);
    },
};

export const deleteEmptyStringProperties = (resource) => {
    const obj = { ...resource };
    for (const key in obj) {
        if (
            obj[key] === ''
            // || obj[key] === 'none'
        ) {
            delete obj[key];
        }
    }
    return obj;
};

export const dataTableFormatter = {
    tableColumns(channelsData, {
        timeType,
        timeFormat,
        timeDiff,
        timeZone,
        dateTimeFormat,
    }) {
        const chHeader = channelsData?.map(chData => {
            return {
                Header: `${chData.channel.nameLocalized} (${chData.channel.unit})`,
                accessor: chData.channel.code + '_' + chData.channel.unitId,
                Cell: ({ value }) => {
                    return (value || value === 0) ? value : '-';
                },
            };
        }) || [];

        return [
            {
                Header: 'Time',
                accessor: 'time',
                Cell: ({ value }) => formatPointTime(
                    value,
                    timeType,
                    timeFormat,
                    timeDiff,
                    timeZone,
                    dateTimeFormat,
                ),
            },
            ...chHeader,
        ];
    },

    tableData(channelsData, {
        dateFrom,
        dateTo,
        local = true,
    }) {
        if (!channelsData) return [];

        const timeChMap = {};

        const availableChannelCodes = channelsData.map(chData => chData.channel.code + '_' + chData.channel.unitId);

        let dateStart = dateFrom && new Date(dateFrom).getTime();
        let dateEnd = dateTo && (new Date(dateTo).getTime() + 1000);

        channelsData.forEach(chData => {
            const chCode = chData.channel.code + '_' + chData.channel.unitId;
            chData.data.forEach(([time, value]) => {

                //TODO: apply date range filter

                // console.log(`date range: ${dateFrom} - ${dateTo}`)
                // let dateStart = dateFrom
                // let dateEnd = dateTo

                // console.log(dateStart, dateEnd)
                // if (timeType === 'fromDatabase' && timeZone) {
                //     dateStart = moment(new Date(dateFrom)).tz(timeZone)
                //     dateEnd = moment(new Date(dateTo)).tz(timeZone)
                // }
                // channelsData.forEach(ch => {
                //     let filteredChannel = ch.data.filter(x => (x[0] >= new Date(dateStart).getTime() && x[0] <= new Date(dateEnd).getTime()))
                //     filteredData.push({ channel: ch.channel, data: filteredChannel })
                // })
                if (dateStart && time < dateStart) return;
                if (dateEnd && time > dateEnd) return;

                if (timeChMap[time] === undefined) {
                    timeChMap[time] = {
                        time,
                        [chCode]: value,
                    };
                } else {
                    timeChMap[time][chCode] = value;
                }

                availableChannelCodes.forEach(code => {
                    if (code === chCode) return;

                    if (timeChMap[time][code] === undefined) {
                        timeChMap[time][code] = null;
                    }
                });
            });
        });

        return Object.values(timeChMap).sort((a, b) => {
            if (a.time < b.time) return -1;
            if (a.time > b.time) return 1;
            return 0;
        });
    },
};

export const downloadAs = async (url, name) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.get(url, {
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
                responseType: 'blob',
            });
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(response.data);
            a.download = name;
            a.click();
            resolve();

        } catch (e) {
            reject();
        }
    });

};

export const findAncestors = (item, directory, parentKey = 'parentId') => {
    if (!item[parentKey]) return [];

    const parent = directory.find(i => i.id === item[parentKey]);

    return [
        parent,
        ...findAncestors(parent, directory, parentKey),
    ];
};

export const listToTree = (list, parentKey = 'parentId') => {
    let map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node[parentKey]) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentComponentId]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
};

export function flattenTree(tree, key = 'children', nestingLevel = 0) {

    const root = Array.isArray(tree) ? { [key]: tree } : tree;
    let flatten = [Object.assign({}, { nestingLevel, ...root })];
    delete flatten[0][key];


    if (root[key] && root[key].length > 0) {
        return flatten.concat(root[key]
            .map((child) => flattenTree({ ...child }, key, nestingLevel + 1))
            .reduce((a, b) => {
                return a.concat(b);
            }, []),
        );
    }

    if (Array.isArray(tree)) flatten.shift();

    return flatten;
}
