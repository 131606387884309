import { useQuery } from 'react-query'
import { apiMmsBaseUrl } from 'config'
import Api from '../../Services/Api.service'

export function useComponents (departmentId, parent = false) {
  let url = `${apiMmsBaseUrl}/components/by-departmentid/${departmentId}`
  if (parent) url += '/parent'
  return useQuery(
    ['components', departmentId],
    () => Api.get(url),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!departmentId,
    },
  )
}

export function useComponentsShort (departmentId, parent = false) {
  let url = `${apiMmsBaseUrl}/components/by-departmentid/${departmentId}/short`
  if (parent) url += '/parent'
  return useQuery(
    ['components', 'short', departmentId],
    () => Api.get(url),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!departmentId,
    },
  )
}

export function useComponentsWithPaging (departmentId, params = {}) {
  let url = `${apiMmsBaseUrl}/components/by-departmentid/${departmentId}/paged`
  return useQuery(
    ['components', departmentId, 'paged', params],
    () => Api.get(url, {
      params
    }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!departmentId,
    },
  )
}

export function useComponent (componentId, parent = false) {
  let url = `${apiMmsBaseUrl}/components/${componentId}`
  return useQuery(
    ['component', componentId],
    () => Api.get(url),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: !!componentId,
    },
  )
}

export function useUnassignedComponentsWithPaging(companyId, isSuperUser, params = {}) {
  const { sortType, isAsc, pageSize, pageNumber, searchTerm, states } = params;
  let url = `${apiMmsBaseUrl}/components/unassigned/paged`;
  const statesParams = Array.isArray(states) ? states : [states];
  statesParams.forEach((state, index) => {
    url += `${index === 0 ? '?' : '&'}states=${state}`;
  });

  const headers = isSuperUser && companyId !== undefined ? { 'CompanyId': companyId } : {};

  return useQuery(
    ['unassigned', 'paged', companyId, params],
    () =>
      Api.get(url, {
        params: {
          sortType,
          isAsc,
          pageSize,
          pageNumber,
          searchTerm,
        },
        headers,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
}