import moment from 'moment-timezone'
import { toHHMMSS } from 'helpers'

export const filterArray = (arr, search, field = 'name') => {
    return arr.filter(item => item[field].toLowerCase().includes(search.toLowerCase()))
}

export const groupBoxes = (boxes, groupId) => {
    return boxes.filter(item => item.group === groupId)
}

export const jobChannels = (jobUnits, channelsIds = null) => {
    let channels = []
    if (jobUnits) {
        jobUnits.forEach(unit => {
            if (unit.channels)
                channels = [...channels, ...unit.channels]
        })
    }

    return channelsIds ? channels.filter(ch => channelsIds.includes(ch.id)) : channels
}

export const jobChannel = (job, channelId) => {
    const channels = jobChannels(job.units, [channelId])
    return (channels && channels[0]) || null
}

export const unitChannels = (unit, channelCodes = null) => {
    const channels = unit.channels || [];
    return channelCodes ? channels.filter(ch => channelCodes.includes(ch.code)) : channels
}

export const unitChannel = (unit, channelCode) => {
    const channels = unitChannels(unit, [channelCode])
    return (channels && channels[0]) || null
}

export const timeOrNow = time => time ? time : moment().format()

export const formatPointTime = (timeValue, timeType, timeFormat, timeDiff, timeZone, dateTimeFormat) => {
    // return new Date(timeValue).toString();

    let time = moment(timeValue).local();
    if (timeType === 'fromDatabase' && timeZone) {
        time.tz(timeZone)
    }

    switch (timeFormat) {
        case 'time':
            if (timeType === 'elapsed') {
                const s = time.diff(timeDiff, 'seconds')
                time = toHHMMSS(s)
            } else {
                time = time.format('h:mm:ss A')
            }

            break
        case 'dateTime':
            time = time.format(dateTimeFormat)
            break
        case 'seconds':
            time = time.diff(timeDiff, 'seconds')
            break
        default:
            time = time.format('HH:mm:ss')
    }

    return time.toString()
}

export const usernameFromFullName = (email) => {
    return email.split('@')[0]
        .replace(/[^a-zа-яё\s]/gi, '') // replace . and _ with ' '
        .replace(/\b\w/g, l => l.toUpperCase()) // capitalize all words
}
