import React, { Fragment } from 'react';
import UnitBoxDebug from './UnitBoxDebug';
import UnitBoxChart from './UnitBoxChart';
import UnitBoxChartHeader from './UnitBoxChartHeader';
import UnitBoxDigitalView from './UnitBoxDigitalView';
import UnitBoxDatatable from './UnitBoxDatatable';
import MapComponent from 'Components/MapComponent';
import UnitBoxEventLogger from './UnitBoxEventLogger';

const UnitBox = props => {

    const {
        boxData,
        dragHandleProps,
        onRemove,
        handleEdit,
        openManageAxesModal,
        onNewWindowOpen,
        onExportToCsv,
        currentUnitModel
    } = props;

    if (boxData.type === 'chartView') return <UnitBoxChart {...props} />
    if (boxData.type === 'digitalView') return <UnitBoxDigitalView {...props} />
    if (boxData.type === 'dataTable') return <UnitBoxDatatable {...props} />
    if (boxData.type === 'eventLogger') return <UnitBoxEventLogger {...props} />
    if (boxData[0] === 'm') return <MapComponent {...props} />

    return (
        <Fragment>
            <UnitBoxChartHeader
                box={boxData}
                onRemove={onRemove}
                handleEdit={handleEdit}
                dragHandleProps={dragHandleProps}
                onManageAxesSettings={openManageAxesModal}
                onNewWindowOpen={onNewWindowOpen}
                onExportToCsv={onExportToCsv}
                currentUnitModel={currentUnitModel}
            />

            <div className="pageCard">
                <UnitBoxDebug data={boxData} />
            </div>
        </Fragment>
    );
};

export default UnitBox;
