import ApiService from 'Services/Api.service'
import TokenService from './Token.service'
// import endpoints from './endpoints/auth.endpoints'
import dummyData from '../../dummy-data'
// import jwtDecode from 'jwt-decode'

const dummySignIn = credentials => new Promise((resolve, reject) => {
    const {email, password} = credentials
    const {user} = dummyData

    if (!email && !password) return reject(dummyData.signinRequest.error.emptyEmailAndPassword)
    if (!email) return reject(dummyData.signinRequest.error.emptyEmail)
    if (!password) return reject(dummyData.signinRequest.error.emptyPassword)

    if (user.email === email && user.password === password) {
        return resolve(dummyData.signinRequest.success)
    } else {
        return reject(dummyData.signinRequest.error.invalidCredentials)
    }


})

const AuthService = {
    getTokenUser: token => {
        // const decoded = jwtDecode(token)
        return dummyData.user
    },

    login: async credentials => {
        try {
            const response = await dummySignIn(credentials)
            ApiService.setAuthHeader(response.access_token)
            TokenService.saveTokenData(response)
            return response
        } catch (error) {
            throw error
        }
        /**
         const getTokenRoute = endpoints.getToken()
         const requestData = {
            method: getTokenRoute.method,
            url: getTokenRoute.url,
            data: credentials
        }
         try {
            const response = await ApiService.request(requestData)
            const tokenData = response.data
            ApiService.setAuthHeader(tokenData.access_token)
            TokenService.saveTokenData(tokenData)
            return tokenData
        } catch (error) {
            throw error
        }
         **/
    },

    logout: () => {
        TokenService.removeTokenData()
        ApiService.removeAuthHeader()
    }
}

export default AuthService
