import { useEffect, useState } from 'react'
import { buildUserListHub } from './signalRHubs'
import { useAuth } from 'Contexts/AuthProvider'
import { connect } from 'react-redux'

const UserListHub = ({ currentCompany, onUserChangedStatus, onUserUpdateSessions }) => {

    const [, setHubConnection] = useState(null)
    const [, setConnectionState] = useState(null)
    const { token } = useAuth()

    useEffect(() => {
        const hubConnect = buildUserListHub(token)
        const createHubConnection = async () => {
            try {
                await hubConnect.start()
                console.log('company users hub started...')
                setConnectionState(hubConnect.state)
                hubConnect.on('IsOnline', userId => onUserChangedStatus(userId, true))
                hubConnect.on('IsOffline', userId => onUserChangedStatus(userId, false))
                hubConnect.on('SessionUpdated', (userId, sessionCount) => onUserUpdateSessions(userId, sessionCount))

                await hubConnect.invoke('SubscribeToUsersUpdate')

                hubConnect.onclose(error => {
                    console.log('company users hub closed...')
                    setConnectionState(hubConnect.state)
                })

                hubConnect.onreconnecting(async () => {
                    setConnectionState(hubConnect.state)
                })

                hubConnect.onreconnected(async () => {
                    setConnectionState(hubConnect.state)
                    await hubConnect.invoke('SubscribeToUsersUpdate')
                })

            } catch (err) {
                console.log('Error while establishing connection: ', err)
            }

            setHubConnection(hubConnect)
        }

        createHubConnection().then()

        return () => {
            if (hubConnect.state === 'Connected')
                hubConnect.stop()
        }

    }, [onUserChangedStatus, onUserUpdateSessions, token, currentCompany])

    return null
}


const mapStateToProps = ({ dashboard }) => ({
    currentCompany: dashboard.currentCompany
})

export default connect(mapStateToProps, null)(UserListHub)

