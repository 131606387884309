import React, {useEffect, useState} from 'react'
import { Dialog, TextField, Button, CircularProgress } from '@material-ui/core';
import DmsService from 'Services/DMS/dms-api.service'
import AlertToastr from 'utils/alert'
import {useAuth} from 'Contexts/AuthProvider'
import useTranslation from 'Hooks/useTranslation'

const SaveTemplateModal = ({isOpen, isOpenStateChanger, onSuccessSave, loadedTemplate}) => {

    const [templateName, setTemplateName] = useState('')
    const translation = useTranslation();
    const {win} = useAuth()
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setTemplateName('')
        isOpenStateChanger(false)
    }

    useEffect(() => {
        if (loadedTemplate && loadedTemplate.name) {
            setTemplateName(loadedTemplate.name)
        }
    }, [loadedTemplate, setTemplateName])

    const handleSaveTemplate = (asNew = false) => () => {
        if (win) {
            setLoading(true)
            let temlateId = (loadedTemplate && loadedTemplate.id) || null
            if (asNew)
                temlateId = null
            DmsService.saveTemplate(win.id, templateName, temlateId)
                .then(response => {
                    AlertToastr.showAlert(translation.notificationsTemplateAdded)
                    handleClose()
                    setLoading(false)
                    if (onSuccessSave){
                        if (response && response.data && response.data.item)
                            onSuccessSave(response.data.item)
                    }

                })
                .catch(error => {
                    setLoading(false)
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        }
    }

    return (
        <Dialog open={isOpen} onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                handleSaveTemplate();
            }
        }}>
            <div className="modalTitleHolder mb16">
                <h1 className='modalTitle'>{translation.dashboardSaveTemplateLabel}</h1>
            </div>
            <TextField
                onChange={e => setTemplateName(e.target.value)}
                value={templateName}
                variant="outlined"
                margin="normal"
                fullWidth
                name="Template Name"
                label="Template Name"
                type="text"
            />
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    onClick={handleClose}
                    variant="outlined">
                    {translation.cancelButtonLabel}
                </Button>
                <div className="part">
                    <Button
                        onClick={handleSaveTemplate()}
                        variant="contained"
                        color="primary"
                        className='mr0'
                        disabled={loading}
                    >
                        {translation.saveButtonLabel}
                    </Button>
                    {loading && <CircularProgress size={24} />}
                </div>

                {/* {
                    loadedTemplate && loadedTemplate.id && (
                        <Button
                            onClick={handleSaveTemplate(true)}
                            variant="contained"
                            color="primary">
                            Save New
                        </Button>
                    )
                } */}

            </div>
        </Dialog>
    )
}

export default SaveTemplateModal
