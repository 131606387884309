import { Amplify } from 'aws-amplify';

Amplify.configure({
  ...Amplify.getConfig(),
  Geo: {
    LocationService: {
      maps: {
        items: {
          "arn:aws:geo:eu-central-1:814665783363:map/dms-map": {
            // REQUIRED - Amazon Location Service Map resource name
            style: 'VectorOpenDataStandardLight' // REQUIRED - String representing the style of map resource
          }
        },
        default: 'arn:aws:geo:eu-central-1:814665783363:map/dms-map' // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      geofenceCollections: {
        items: ['XXXXXXXXX', 'XXXXXXXXX'], // REQUIRED - Amazon Location Service Geofence Collection name
        default: 'XXXXXXXXX' // REQUIRED - Amazon Location Service Geofence Collection name to set as default
      },
      region: 'eu-central-1' // REQUIRED - Amazon Location Service Region
    }
  }
});