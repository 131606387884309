import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "@material-ui/core";
import useTranslation from "Hooks/useTranslation";
import MmsService from "Services/MMS/mms-api.service";
import { useQueryClient } from "react-query";
import AlertToastr from "utils/alert";

const ChangeComponentStateModal = ({
  isOpen,
  isOpenManager,
  onClose,
  selectedComponent,
  selectedStateName,
  selectedStateId
}) => {
  const translation = useTranslation();
  const queryClient = useQueryClient();

  const getStatusMessage = () => {
    switch (selectedComponent?.state) {
      case "fieldReady":
        return "Field ready";
      case "toRepair":
        return "To repair";
      case "toCheck":
        return "To check";
      case "installed":
        return "Installed";
      case "disposed":
        return "Disposed";
      case "removed":
        return "Removed";
      default:
        return "";
    }
  };

  const changeComponentState = async () => {
    try {
      await MmsService.updateUnassignedComponentState(
        selectedStateId,
        selectedComponent?.id,
      );
      onClose()
      queryClient.invalidateQueries("unassigned");
      AlertToastr.showAlert("Component state successfully updated");
      isOpenManager(false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        AlertToastr.showErrorAlert(error.response.data.message);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      className="respModal  autoHeight"
      style={{ width: "448px", marginLeft: "auto", marginRight: "auto" }}
    >
      <div className="modalTitleHolder mb16">
        <h1 className="workhoursUpdateModalTitle">
          {translation.changeComponentStateModalLabel}
        </h1>
      </div>
      <div className="deleteItemLabel mb16">
        You are changing the status of a component from <br />
        <span className="boldStatusMessage">{getStatusMessage()}</span> to{" "}
        <span className="boldStatusMessage">{selectedStateName}</span>. Are you
        sure you want to do this?
      </div>

      <div className="btnHolder end fixedWidthBtn">
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          id="modalCancelBtn"
          fullWidth
        >
          {translation.cancelButtonLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          id="modalSaveRemoveBtn"
          onClick={changeComponentState}
        >
          {translation.saveButtonLabel}
        </Button>
      </div>
    </Dialog>
  );
};

export default ChangeComponentStateModal;
