import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

const getUnitJobsByUid = async (tabType, uid) => {
    const { data } = await axios.get(`${apiBaseUrl}/jobs/by-tabtype/${tabType}/by-unituid/${uid}`);
    return data || [];
};

export default function useUnitJobs(tabType, uid) {
    return useQuery(
        ['unit-jobs', tabType, uid],
        () => getUnitJobsByUid(tabType, uid),
        {
            initialData: [],
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!uid
        }
    );
}
