import React, { Fragment, useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import UnitList from './UnitList';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useBoxes } from '../../../Contexts/BoxContext';
import TemplateList from './TemplateList';

const LoadLocationTemplateModal = (props) => {

    const {
        isOpen,
        onCancel,
        onTemplateLoad
    } = props;

    const {
        currentLocationUnitModel: currentUnitModel,
        currentLocationUnitTitle: currentUnitTitle,
        setCurrentLocationUnit: setCurrentUnit,
        locationUnitsRequest: unitsRequest,
        locationBoxes: unitBoxes,
    } = useBoxes();

    const [isSelectUnitStep, setIsSelectUnitStep] = useState(true);
    const [templateLoadedResponse, setTemplateLoadedResponse] = useState({ tabs: null, template: null });
    const [errors, setErrors] = useState({
        type: 'error', //warning
        messages: null,
    });

    const { data: units } = unitsRequest;

    const handleBack = () => setIsSelectUnitStep(true);

    const handleSaveUnit = (unitId) => {
        setIsSelectUnitStep(false);
        setCurrentUnit(unitId);
    };

    const renderTitle = () => isSelectUnitStep
        ? 'Units'
        : currentUnitTitle;

    const renderBackToUnitButton = () => {
        if (unitBoxes.length) return null;
        if (isSelectUnitStep) return null;

        return (
            <IconButton aria-label="back" size="small" onClick={handleBack}>
                <ArrowBackIcon fontSize="inherit" />
            </IconButton>
        );
    };

    const renderUnitList = () => {
        if (!isSelectUnitStep) return;

        return (
            <UnitList
                items={units}
                onCancel={onCancel}
                onSave={handleSaveUnit}
            />
        );
    };

    const handleErrorTemplateLoad = ({ errors, response }) => {
        setErrors(errors);
        setTemplateLoadedResponse(response);
    };

    const renderTemplateList = () => {
        if (isSelectUnitStep) return;

        return (
            <TemplateList
                unit={currentUnitModel}
                onCancel={() => {
                    setIsSelectUnitStep(true);
                    onCancel();
                }}
                onSuccessTemplateLoad={handleSuccessTemplateLoad}
                onErrorTemplateLoad={handleErrorTemplateLoad}
                section="locations"
            />
        );
    };

    const handleSuccessTemplateLoad = (tabs, template) => {
        onTemplateLoad(tabs, template)
        // onTemplateLoaded(selectedTemplate, selectedJob, tabs, template);
        // isOpenChangeHandler(false);
    };

    const handleErrors = () => {
        setErrors({ type: 'error', messages: null });
        if (errors.type === 'warning') {
            const { tabs, template } = templateLoadedResponse;
            if (tabs && template) {
                handleSuccessTemplateLoad(tabs, template);
            }
        }
    };

    const renderError = () => {
        const title = errors.type === 'error' ? 'Error' : 'Warning';
        const iconClass = errors.type === 'error'
            ? 'modalTitle hasIcon error'
            : 'modalTitle hasIcon warning';
        return (
            <Fragment>
                <div className="modalTitleHolder mb16">
                    <h2 className={iconClass}>{title}</h2>
                </div>
                <ul className="errorList">
                    {
                        errors.messages && errors.messages.map((errorMessage, index) => (
                            <li key={index}>{errorMessage}</li>))
                    }
                </ul>
                <div className="btnHolder end mt22 fixedWidthBtn">
                    <Button color={'primary'}
                            onClick={handleErrors}
                            variant={'contained'}>
                        Ok
                    </Button>
                </div>
            </Fragment>
        );
    };

    const modalClass = errors.messages ? '' : 'respModal';

    const renderDialogContent = () => {
        if (errors.messages) {
            return renderError();
        }

        return (
            <Fragment>
                <div className="modalTitleHolder mb16 hasBackLink">
                    <div className="backLinkHolder">{renderBackToUnitButton()}</div>
                    <h1 className="modalTitle text-center">{renderTitle()}</h1>
                </div>

                {renderUnitList()}
                {renderTemplateList()}
            </Fragment>
        );
    };

    return (
        <Dialog
            open={isOpen}
            className={modalClass}
        >
            {renderDialogContent()}
        </Dialog>
    );
};

export default LoadLocationTemplateModal;
