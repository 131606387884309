import { useEffect, useState } from 'react'
import { buildUnitsHub } from 'Components/SignalrSubscribes/signalRHubs'
import { useAuth } from 'Contexts/AuthProvider'
import DmsService from 'Services/DMS/dms-api.service'

const UnitsManagerSignalr = ({ setCurrentJobUnits, currentJobId, jobs }) => {

    const { token } = useAuth()
    const [connection, setConnection] = useState(null)

    useEffect(() => {
        const hubConnection = buildUnitsHub(token)
        setConnection(hubConnection)
    }, [token])

    useEffect(() => {
        const onUnitsUpdated = () => {
            fetchCurrentJob().then()
        }

        const fetchCurrentJob = async () => {
            if (currentJobId && jobs && jobs.length) {
                let response = await DmsService.getJobsById(currentJobId)
                if (response) {
                    const job = jobs.find(x => x.id === currentJobId)
                    job.units = response.units
                    setCurrentJobUnits(response.units)
                }
            }
        }

        const startConnection = () => {
            connection.start().then(() => {
                connection.invoke('SubscribeToUnitUpdates', currentJobId).then(() => {
                    console.log('units hub started...')
                })
            })
        }
        if (!connection || connection.state === 'Connecting' || connection.state === 'Reconnecting' || connection.state === 'Disconnecting') {
            return
        }
        if (!currentJobId) {
            if (connection.state === 'Connected') {
                connection.stop().then(() => {
                    console.log('units hub closed...')
                })
            }
            return
        }

        connection.on('UnitsUpdated', onUnitsUpdated)
        if (connection.state === 'Disconnected') {
            startConnection()
        }

        return () => {
            if (connection.state === 'Connected')
                connection.stop()
        }

    }, [currentJobId, connection, jobs, setCurrentJobUnits])

    return null
}

export default UnitsManagerSignalr
