import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import UnitBoxLoader from './UnitBoxLoader'
import ManageAxesModal from '../../modals/ManageAxisModal/ManageAxesModal'
import ManageDateFormatModal from '../../modals/ManageDateFormatModal/ManageDateFormatModal'
import UnitBoxEventLoggerHeader from './UnitBoxEventLoggerHeader'
import { useBoxes } from '../../../Contexts/BoxContext'
import { apiBaseUrl } from 'config'
import axios from 'axios'
import EventLogger from 'Components/EventLogger/EventLogger'
import LiveUnitsSignalrEventLogger from 'Components/SignalrSubscribes/LiveUnitsSignalrEventLogger'
import EventEmitter from 'utils/EventEmitter'
import useUnitInitEventLoggerDataUnit from '../../../Hooks/useUnitInitEventLoggerData'
import LiveUnitsSignalrEventLoggerNew from '../../SignalrSubscribes/LiveUnitsSignalrEventLoggerNew'
import { useAuth } from '../../../Contexts/AuthProvider'

const UnitBoxEventLogger = (props) => {


  const {
    boxData,
    onRemove,
    handleEdit,
    dragHandleProps,
    onNewWindowOpen,
    onExportToCsv,
    currentJob,
    eventTypes,
    section = 'unit',
    onUpdateSettings,
  } = props

  const { unitUid, timeType, timeFormat, filters, type } = boxData

  //   const translation = useTranslation();
    const { token, currentCompany } = useAuth();

  const [showManageAxesModal, setShowManageAxesModal] = useState(false)
  const [showManageDateFormatModal, setManageDateFormatModal] = useState(false)
  const [data, setData] = useState(null)
  const [dateTimeFormat, setDateTimeFormat] = useState('')
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const { startDate, endDate, unitsRequest } = useBoxes()
  const [lastModificationDateTime, setLastModificationDateTime] = useState(0)

  // const jobId = boxData?.filters?.jobId
  // const dateType = boxData?.filters?.dateType
  // const startDateFrom = filters?.dateFrom
  // const endDateTo = filters?.dateTo

  const signalrRequestBody = useMemo(() => ({
    uId: unitUid,
    jobType: 'unit',
    dateType: boxData.filters.dateType,
    jobId: boxData.filters.jobId === undefined ? '' : boxData.filters.jobId,
    dateFrom: startDate,
    dateTo: endDate,
  }), [unitUid, boxData.filters.dateType, boxData.filters.jobId, startDate, endDate])

  const eventLoggerDataUnitRequest = useUnitInitEventLoggerDataUnit({ signalrRequestBody })

  useEffect(() => {
    if (eventLoggerDataUnitRequest.isSuccess) {
      setData(eventLoggerDataUnitRequest.data.events)
      setDateTimeFormat(eventLoggerDataUnitRequest?.data.dateTimeFormat)
      setLastModificationDateTime(eventLoggerDataUnitRequest.data?.lastModificationDateTime)
    }
  }, [eventLoggerDataUnitRequest.data?.dateTimeFormat, eventLoggerDataUnitRequest.data?.events, eventLoggerDataUnitRequest.data?.lastModificationDateTime, eventLoggerDataUnitRequest.isSuccess])

  const handleLiveData = useCallback(
    (incomingData) => {
      if (incomingData && incomingData.length) {
        setData((prevData) => {
          if (!prevData) return incomingData

          const updatedData = prevData.map((prevItem) => {
            const newItem = incomingData.find((newItem) => newItem.dmsId === prevItem.dmsId)
            return newItem ? newItem : prevItem
          })

          const newItems = incomingData.filter((newItem) => !prevData.some((prevItem) => prevItem.dmsId === newItem.dmsId))

          return updatedData.concat(newItems)
        })
      }
    },
    [setData]
  )

  const openManageAxesModal = () => {
    setShowManageAxesModal(true)
  }

  const openManageDateFormatModal = () => {
    setManageDateFormatModal(true)
  }

  const saveAxesSettings = (
    yAxisSettings,
    xAxisSettings,
    timeType,
    timeFormat
  ) => {
    boxData.yAxisSettings = yAxisSettings
    boxData.xAxisSettings = xAxisSettings
    boxData.timeType = timeType
    boxData.timeFormat = timeFormat
    if (onUpdateSettings) onUpdateSettings()
  }

  const saveDateFormat = (timeType, timeFormat) => {
    boxData.timeType = timeType
    boxData.timeFormat = timeFormat
    if (onUpdateSettings) onUpdateSettings()
  }

  const handleDeleteLiveData = useCallback((incomingData) => {
    setData(data => {
      const newData = data ? [...data] : []
      incomingData.forEach(eventData => {
        const eventIndex = newData.findIndex(item => item.dmsId === eventData.dmsId)

        if (eventIndex > -1) {
          newData.splice(eventIndex, 1)
        }
      })
      return newData
    })
  }, [setData])

  const renderSignalR = () => {
    if (type === 'eventLogger' && lastModificationDateTime !== 0) {
      return (
        <LiveUnitsSignalrEventLogger
          unitsEventLogger={true}
          requestBody={signalrRequestBody}
          lastModificationDateTime={lastModificationDateTime}
          // jobId={jobId}
          // lastLoadedDmsId={lastLoadedDmsId}
          onIncomingData={handleLiveData}
          onIncomingDeleteData={handleDeleteLiveData}
        />
      )
    }

    return null
  }

  const timeDiff = useMemo(() => {
    if (timeType === 'elapsed') {
      if (data && data.length) {
        const firstPointsTimeArray = []
        data.forEach((channelData) => {
          if (
            channelData &&
            channelData.data &&
            channelData.data[0] &&
            channelData.data[0][0]
          ) {
            firstPointsTimeArray.push(
              channelData.data.reduce(
                (min, ch) => (ch[0] < min ? ch[0] : min),
                channelData.data[0][0]
              )
            )
          }
        })
        return Math.min(...firstPointsTimeArray)
      }
    }
    return null
  }, [timeType, data])

  return (
    <Fragment>
      {/* <UnitBoxLoader
        loading={
          channelsDataUnitRequest.isLoading ||
          channelsDataUnitRequest.isFetching
        }
      /> */}

      <UnitBoxEventLoggerHeader
        box={boxData}
        onRemove={onRemove}
        handleEdit={handleEdit}
        dragHandleProps={dragHandleProps}
        onManageAxesSettings={openManageAxesModal}
        onManageDateFormat={openManageDateFormatModal}
        onNewWindowOpen={onNewWindowOpen}
        onExportToCsv={onExportToCsv}
      />

      <div className="pageCard">
        <div className="pageBtnSection pt0">
          <div style={{ display: 'flex' }}></div>
        </div>

        <EventLogger
          data={data}
          job={currentJob}
          timeType={timeType}
          timeFormat={timeFormat}
          timeDiff={timeDiff}
          dateTimeFormat={dateTimeFormat}
          eventTypes={eventTypes}
          showUnitName={true}
          unitsRequest={unitsRequest}
        />
      </div>

      <ManageAxesModal
        isOpen={showManageAxesModal}
        setOpen={setShowManageAxesModal}
        editedModel={boxData}
        reverseChart={false}
        onSave={saveAxesSettings}
        timeType={timeType}
        timeFormat={timeFormat}
      />

      <ManageDateFormatModal
        isOpen={showManageDateFormatModal}
        setOpen={setManageDateFormatModal}
        onSave={saveDateFormat}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        timeType={timeType}
        timeFormat={timeFormat}
        type="eventLogger"
      />


      {/*{renderSignalR()}*/}

      {
        (lastModificationDateTime && !eventLoggerDataUnitRequest.isFetching) ? (
          <LiveUnitsSignalrEventLoggerNew
            token={token}
            companyId={currentCompany?.id}
            unitsEventLogger={true}
            requestBody={signalrRequestBody}
            lastModificationDateTime={lastModificationDateTime}
            onIncomingData={handleLiveData}
            onIncomingDeleteData={handleDeleteLiveData}
          />
        ) : null
      }


    </Fragment>
  )
}

export default UnitBoxEventLogger
