import React  from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {useAppSettings} from 'Contexts/AppSettingsProvider'
import {useAuth} from 'Contexts/AuthProvider'

const BlueThemeIcon = () => <div className='BlueThemeIcon' />

const DarkThemeIcon = () => <div className='DarkThemeIcon' />

const ThemeSelector = () => {

    const { appTheme } = useAppSettings()
    const { setUserTheme } = useAuth()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const {themeList} = useAppSettings()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const isLightTheme = () => {
        return appTheme === 'Light'
    }

    const isDarkTheme = () => {
        return appTheme === 'Dark'
    }

    const blueClasses = () => {
        if (isLightTheme()) return 'themeItem blue active'
        return 'themeItem blue'
    }

    const darkClasses = () => {
        if (isDarkTheme()) return 'themeItem dark active'
        return 'themeItem dark'
    }

    return (
        <div className='langDropdownHolder'>
            <Button aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                startIcon={appTheme === 'Dark' ? <DarkThemeIcon /> : <BlueThemeIcon />}
                className='dropDownBtn'>
                {appTheme}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {themeList.map(theme => (
                    <MenuItem key={theme} onClick={(e) => {
                        handleClose()
                        setUserTheme(theme)
                    }}>
                        <div className={theme === 'Dark' ? darkClasses() : blueClasses()}>{theme}</div>
                    </MenuItem>
                ))
                }
            </Menu>
        </div>
    )
}

export default ThemeSelector
