import React from 'react';
import useTranslation from '../Hooks/useTranslation';

const EmptyDataSpan = () => {
    const translation = useTranslation();

    return (
        <span>
            <span>{translation.emptyDataTitle}</span>
        </span>
    );
};

export default EmptyDataSpan;
