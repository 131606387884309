import React, { Fragment, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import useTranslation from '../Hooks/useTranslation';
import { deleteEmptyStringProperties } from '../helpers';
import { useBoxes } from '../Contexts/BoxContext';

const DatatableFilters = ({ onChange, initData }) => {
    const translation = useTranslation();
    const { dateTypesOptions, dateTypeIsLoading } = useBoxes();
    const [typeValue, setTypeValue] = useState('none');
    const [value, setValue] = useState('');

    const handleChangeFilterType = event => {
        setTypeValue(event.target.value);
        setValue('');
    };

    useEffect(() => {
        if (!initData) return;

        if (initData.filterType) {
            setTypeValue(initData.filterType)
        }

        if (initData.value) {
            setValue(initData.value)
        }
    }, [initData]);

    useEffect(() => {
        onChange(deleteEmptyStringProperties({
            filterType: typeValue,
            value: +value,
        }));
    }, [typeValue, value, onChange]);

    return (
        <Fragment>
            <TextField
                disabled={dateTypeIsLoading}
                variant="outlined"
                margin="normal"
                fullWidth
                select
                value={typeValue}
                onChange={handleChangeFilterType}
            >
                {
                    dateTypesOptions.map(item => (
                        <MenuItem
                            className="filterMenuItem"
                            key={item.filterType}
                            value={item.filterType}
                        >
                            {item.name}
                        </MenuItem>
                    ))
                }
            </TextField>

            {
                typeValue !== 'none' && (
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="value"
                        label={translation.valueLabelDisplay}
                        type="number"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                )
            }
        </Fragment>
    );
};

export default DatatableFilters;
