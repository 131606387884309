import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Popover from '@material-ui/core/Popover'
import { MenuItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

const ArrowLeft = () => <div className="arrowLeftIcon" />;
const ArrowRight = () => <div className="arrowRightIcon" />;

const TablePagination = (props) => {

  const {
    pagination,
    onPrevPage,
    onNextPage,
    handleRowCount,
  } = props;

  const {
    pageSize = 0,
    pageNumber = 0,
    itemsCount = 0,
    itemsTotalCount = 0,
    pageCount = 0,
  } = pagination;

  const [anchorEl, onAnchorElChange] = useState(null);
  const closeMenu = () => onAnchorElChange(null);


  const from = pageSize*pageNumber-pageSize+1;
  const to=from+itemsCount-1
  return (
    <div className="tableControlsHolder">
      <div className="sortHolder">
        <div className="caption">Rows per page:</div>
        <Button onClick={(e) => onAnchorElChange(e.target)}
                id="assetsTableRowsPerPageBtn"
                endIcon={<ArrowDropDownIcon />}
                className="dropDownBtn">
          {pageSize}
        </Button>

        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          disableScrollLock={true}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <MenuItem id="assetsTblRowCount20Btn" onClick={() => {
            closeMenu();
            handleRowCount(15)
          }}>15</MenuItem>
          <MenuItem id="assetsTblRowCount50Btn" onClick={() => {
            closeMenu();
            handleRowCount(50)
          }}>50</MenuItem>
          <MenuItem id="assetsTblRowCount100Btn" onClick={() => {
            closeMenu();
            handleRowCount(100)
          }}>100</MenuItem>
        </Popover>
      </div>
      <div className="paginationHolder mms">
        <div className="caption">{`${from}-${to} of ${itemsTotalCount}`}</div>
        <div className="paginationBtn">
          <IconButton
            disabled={!pageCount || pageNumber === 1}
            onClick={() => onPrevPage()}>
            <ArrowLeft />
          </IconButton>
          <IconButton
            disabled={!pageCount || pageNumber === pageCount}
            onClick={() => onNextPage()}>
            <ArrowRight />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default TablePagination
