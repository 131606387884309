import React, { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, CircularProgress, Link, TextField } from '@material-ui/core'
import { allPropsTrue } from 'helpers'
import { getServerError, clearServerErrors } from 'utils/form'
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'

const SigninLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref}
    to="/signin" {...props} />)
const SignUpForm = props => {

    const {
        request,
        handleSubmit,
        register,
        onSubmit,
        watch
    } = props

    const [errors, setErrors] = useState(null)
    const translation = useTranslation();

    const canSubmit = useMemo(() => allPropsTrue(watch()), [watch])

    useEffect(() => {
        if (request.error) {
            if (request.error.errors) {
                setErrors(request.error.errors)
            } else {
                AlertToastr.showErrorAlert(request.error.message)
            }
        }
    }, [request.error, setErrors])

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>

            <TextField
                inputRef={register}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="licenseId"
                label={translation.signUpPageLicenseIdLabel}
                name="licenseId"
                onFocus={() => setErrors(errors => clearServerErrors(errors, 'licenceId'))}
                error={!!getServerError(errors, 'licenceId')}
                helperText={getServerError(errors, 'licenceId')}
            />
            <TextField
                inputRef={register}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={translation.signUpPageEmailLabel}
                type="email"
                name="email"
                autoComplete="email"
                onFocus={() => setErrors(errors => clearServerErrors(errors, 'email'))}
                error={!!getServerError(errors, 'email')}
                helperText={getServerError(errors, 'email')}
            />
            <TextField
                inputRef={register}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={translation.signUpPagePasswordLabel}
                type="password"
                id="password"
                autoComplete="new-password"
                onFocus={() => setErrors(errors => clearServerErrors(errors, 'password'))}
                error={!!getServerError(errors, 'password')}
                helperText={getServerError(errors, 'password')}
            />
            <TextField
                inputRef={register}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={translation.signUpPageConfirmPasswordLabel}
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                onFocus={() => setErrors(errors => clearServerErrors(errors, 'confirmPassword'))}
                error={!!getServerError(errors, 'confirmPassword')}
                helperText={getServerError(errors, 'confirmPassword')}
            />
            <div className='btnHolder fullWidthMobile mt22'>
                <div className="part">
                    <span>
                        {translation.signUpPageHaveAnAccountLabel} &nbsp;
                        <Link component={SigninLink} className='linkPrimary'>
                            {translation.signUpPageLoginLabel}
                        </Link>
                    </span>
                </div>
                <div className="part">
                    <Button
                        disabled={!canSubmit || request.loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        {translation.signUpButtonLabel}
                    </Button>
                    {request.loading && <CircularProgress size={24} />}
                </div>
            </div>
        </form>
    )
}

export default SignUpForm
