import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import IcoPlusSrc from 'assets/img/round-add-24px.svg'
import { MenuItem, Popover } from '@material-ui/core'
import useTranslation from 'Hooks/useTranslation'
import { useBoxes } from '../../Contexts/BoxContext';
import uuid from 'uuid/v1'

const IcoPlus = () => <img src={IcoPlusSrc} alt="" className="" />

const AddBoxMenu = props => {
    const {
        addUnitBox,
    } = useBoxes();
    const translation = useTranslation();
    const { onAddBox, itemTypes, currentUnitModel, onSave } = props

    const {
        unitBoxes,
      } = useBoxes()

    const [addItemMenuAnchorEl, onAddItemMenuAnchorElChange] = useState(null)

    const closeAddItemMenu = () => onAddItemMenuAnchorElChange(null)

    const handleMenuSelect = menuItem => {
        const savedBox = {
            unitUid: currentUnitModel?.uId,
            channels: currentUnitModel?.channels,
          };

        const  savedData = {
            ...savedBox,
            type: 'eventLogger',
            id: uuid(),
            timeType: 'local',
            timeFormat: 'time',
            itemPosition: {
              rowIndex: unitBoxes.length,
              position: 'Stretch',
            },
          }  
        onAddBox(menuItem)
        closeAddItemMenu()
        if(menuItem === 'map') {
            addUnitBox('m')
        }
        if(currentUnitModel && menuItem === 'eventLogger') {
            onAddBox(savedData)
            // onSave(savedData)
            closeAddItemMenu()
            addUnitBox(savedData)
        }
    }

    return (
        <div>
            <Button className="addItem"
                variant="contained"
                color="primary"
                startIcon={<IcoPlus />}
                onClick={(e) => onAddItemMenuAnchorElChange(e.target)}>
                {translation.dashboardNewItemLabel}
            </Button>
            {
                itemTypes && (
                    <Popover
                        anchorEl={addItemMenuAnchorEl}
                        keepMounted
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                        style={{top: '10px', left: '-10px'}}
                        disableScrollLock={true}
                        open={Boolean(addItemMenuAnchorEl)}
                        onClose={closeAddItemMenu}
                    >
                        {
                            itemTypes.map(item =>
                                <div key={item.itemType}>
                                    <MenuItem
                                    className='addBoxMenuItem'
                                    disabled={!item.isAvailable}
                                    onClick={() => handleMenuSelect(item.itemType)}
                                >{item.name}</MenuItem>
                                </div>
                            )
                        }
                    </Popover>
                )
            }
        </div>
    )
}

export default AddBoxMenu
