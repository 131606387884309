import { useMutation } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

export default function useTemplateChange() {
    const { currentCompany } = useAuth();
    return useMutation(
        ({ tabId, jobId, unitUid, companyId, type, isRefresh = false }) => axios.post(`${apiBaseUrl}/templates/change`, {
            type: type || 'jobs',
            tabId,
            jobId,
            unitUid,
            isRefresh,
        }, {
            headers: { 'CompanyId': currentCompany?.id },
        }),
    );
}
