import React, { Fragment, useState, useMemo } from 'react'
import { MenuItem, Popover } from '@material-ui/core'
import Button from '@material-ui/core/Button'

const AxisIcon = () => <div className='axisIcon' />
const TimeIcon = () => <div className='timeIcon' />

const TimeFormatMenu = ({ currentFormat, onChangeTimeFormat, reverse, boxType, ignoreReverse = false, timeIcon = false }) => {
    const [anchorEl, onAnchorElChange] = useState(null)

    const closeMenu = () => onAnchorElChange(null)

    const handleSelectTimeFormat = type => {
        closeMenu()
        onChangeTimeFormat(type)
    }

    const timeFormatOptions = useMemo(() => {
        let typeList = {
            time: 'Time',
            dateTime: 'Date and Time'
        }
        if (boxType !== 'eventLogger') {
            typeList.seconds = 'Seconds'
        }
        return typeList
    }, [boxType])

    const label = ignoreReverse ? 'Time Format' : (
        reverse ? 'Y-Axis' : 'X-Axis'
    )
    return (
        <Fragment>
            <Button onClick={(e) => onAnchorElChange(e.target)}
                startIcon={timeIcon ? <TimeIcon /> : <AxisIcon />}
                className='dropDownBtn'>
                {label}: {timeFormatOptions[currentFormat] || timeFormatOptions.time}
            </Button>

            <Popover anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                disableScrollLock={true}
                onClose={closeMenu}>
                {
                    Object.keys(timeFormatOptions).map(type => (
                        <MenuItem onClick={() => handleSelectTimeFormat(type)} key={type}>
                            {timeFormatOptions[type]}
                        </MenuItem>
                    ))
                }
            </Popover>
        </Fragment>
    )
}

export default TimeFormatMenu
