export default {
    FETCH_JOB_REQUEST: 'FETCH_JOB_REQUEST',
    FETCH_JOB_SUCCESS: 'FETCH_JOB_SUCCESS',
    FETCH_JOB_FAILURE: 'FETCH_JOB_FAILURE',

    SET_CURRENT_JOB: 'SET_CURRENT_JOB',
    SET_SELECTED_JOB: 'SET_SELECTED_JOB',

    SELECT_CHANNEL: 'SELECT_CHANNEL',
    SELECT_XY_CHANNEL: 'SELECT_XY_CHANNEL',
    DESELECT_CHANNEL: 'DESELECT_CHANNEL',
    DESELECT_XY_CHANNEL: 'DESELECT_XY_CHANNEL',

    SET_SELECTED_CHANNELS: 'SET_SELECTED_CHANNELS',

    FETCH_CHANNELS_DATA_REQUEST: 'FETCH_CHANNELS_DATA_REQUEST',
    FETCH_XY_CHANNELS_DATA_REQUEST: 'FETCH_XY_CHANNELS_DATA_REQUEST',
    FETCH_CHANNELS_DATA_SUCCESS: 'FETCH_CHANNELS_DATA_SUCCESS',
    FETCH_XY_CHANNELS_DATA_SUCCESS: 'FETCH_XY_CHANNELS_DATA_SUCCESS',
    FETCH_CHANNELS_DATA_FAILURE: 'FETCH_CHANNELS_DATA_FAILURE',
    FETCH_XY_CHANNELS_DATA_FAILURE: 'FETCH_XY_CHANNELS_DATA_FAILURE',

    ADD_ITEM_TO_DASHBOARD: 'ADD_ITEM_TO_DASHBOARD',
    CHANGE_JOB_WAS_SET: 'CHANGE_JOB_WAS_SET',
    CANCEL_ADDING_ITEM_TO_DASHBOARD: 'CANCEL_ADDING_ITEM_TO_DASHBOARD',
    SET_CURRENT_INDEX: 'SET_CURRENT_INDEX',
    SET_CURRENT_CHART_TYPE: 'SET_CURRENT_CHART_TYPE',
    BOXES_RELOAD: 'BOXES_RELOAD',
    SET_BOXES: 'SET_BOXES',
    BOX_ADD: 'BOX_ADD',
    BOXES_ADD: 'BOXES_ADD',
    BOX_EDIT: 'BOX_EDIT',
    BOX_EDIT_AXES: 'BOX_EDIT_AXES',
    BOX_REMOVE: 'BOX_REMOVE',
    BOX_TOGGLE_WINDOW: 'BOX_TOGGLE_WINDOW',
    LOAD_TEMPLATE: 'LOAD_TEMPLATE',

    CLEAR: 'CLEAR',

    SET_STATE: 'SET_STATE',
    SET_COMPANIES: 'SET_COMPANIES',
    SET_SELECTED_COMPANY: 'SET_SELECTED_COMPANY',
    SET_JOBS: 'SET_JOBS'
}
