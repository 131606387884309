import React, {useMemo}  from 'react'
import { NavLink } from 'react-router-dom'
import { useUsersSettings } from './UsersSettingsContext'
import { useAuth } from '../../Contexts/AuthProvider'

const UserSettingsTabs = ({path}) => {

    const {tabs} = useUsersSettings()

    const { currentUser } = useAuth()
    const allowIntegration = useMemo(() => currentUser.allowUiIntegrations(), [currentUser])

    const renderTab = (label, url = '', exact = false) => {
        return (
            <NavLink
            className="tabsLikeItem"
                key={label}
                to={`${path}/${url}`}
                exact={exact}
            >
                {label}
            </NavLink>
        )
    }

    return (
        <div className="tabsLike mb0">
            { tabs.map(({label, path, exact}) => renderTab(label, path, exact))}
        </div>
    )
}

export default UserSettingsTabs
