import { useMutation } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

export default function useTemplateChangeConfirm() {
    const { currentCompany } = useAuth();
    return useMutation(
        ({ tabId, code, companyId }) => axios.post(`${apiBaseUrl}/templates/change/confirm`, {
            tabId,
            code,
        }, {
            headers: { 'CompanyId': currentCompany?.id },
        }),
    );
}
