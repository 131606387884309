import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, CircularProgress, Link, TextField } from '@material-ui/core'
import { getServerError, clearServerErrors } from 'utils/form'
import InputAdornment from '@material-ui/core/InputAdornment'
import useTranslation from 'Hooks/useTranslation'
import AlertToastr from 'utils/alert'

const PasswordResetLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref}
    to="/password/reset" {...props} />)

const SignInForm = ({ handleSubmit, register, onSubmit, request, emailMode }) => {
    const translation = useTranslation();

    const [errors, setErrors] = useState(null)

    useEffect(() => {
        if (request.error) {
            if (request.error.errors) {
                setErrors(request.error.errors)
                AlertToastr.showErrorAlert(request.error.message)
            } else {
                AlertToastr.showErrorAlert(request.error.message)
            }
        }
    }, [request.error, setErrors])

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="authForm hasHeight">
                {
                    emailMode && (
                        <TextField
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={translation.signInPageEmailLabel}
                            name="username"
                            autoComplete="email"
                            onFocus={() => setErrors(errors => clearServerErrors(errors, 'usernmame'))}
                            error={!!getServerError(errors, 'username')}
                            helperText={getServerError(errors, 'usernmame')}
                        />
                    )
                }

                <TextField
                    InputProps={{
                        endAdornment: emailMode && <InputAdornment position="start">
                            <Link component={PasswordResetLink} className='linkGray text-size-normal'>
                                {translation.signInPageForgotLabel}
                            </Link>
                        </InputAdornment>,
                    }}
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={translation.signInPagePasswordLabel}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onFocus={() => setErrors(errors => clearServerErrors(errors, 'password'))}
                    error={!!getServerError(errors, 'password')}
                    helperText={getServerError(errors, 'password')}
                />
                <div className='btnHolder fullWidthMobile end'>
                    <div className="part">
                        <Button
                            disabled={request.loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {translation.signInButtonLabel}
                        </Button>
                        {request.loading && <CircularProgress size={24} />}
                    </div>
                </div>
            </div>
        </form>
    )
}

export default SignInForm
