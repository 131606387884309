import { useEffect, useState } from 'react'
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr'
import { apiBaseUrl } from 'config'
import { useAuth } from 'Contexts/AuthProvider'
import { useBoxes } from 'Contexts/BoxContext'

const buildHubUrl = (company) => company ? `${apiBaseUrl}/ws/data?companyid=${company.id}` : `${apiBaseUrl}/ws/data`
const protocol = new JsonHubProtocol()
const transport = HttpTransportType.WebSockets

const LiveJobSignalrTimeBasedNew = props => {

    const {
        channels,
        lastProcessedTimestamp,
        onIncomingData
    } = props

    const { token, currentCompany } = useAuth()
    const [connection, setConnection] = useState(null)
    const { jobsBoxesFilters } = useBoxes();

    useEffect(() => {
        const options = {
            skipNegotiation: true,
            transport,
            accessTokenFactory: () => {
                return token
            },
            headers: {
                authorization: token ? `Token ${token}` : ``
            }
        }
        const connection = new HubConnectionBuilder()
            .withUrl(buildHubUrl(currentCompany), options)
            .withAutomaticReconnect()
            .withHubProtocol(protocol)
            .configureLogging(LogLevel.Information)
            .build()

        if (connection) {
            connection.start().then(() => {
                setConnection(connection)

            })
        }


        return () => {
            if (connection) {
                connection.stop()
            }
            setConnection(undefined)
        }
    }, [token, currentCompany])


    useEffect(() => {
        if (!connection) return;
        if (connection.state !== 'Connected') return;
        if (!lastProcessedTimestamp) return;

        connection.on('ChannelDataResponse', onIncomingData)

        // const channelsData = channels.map((ch) => ({ channelId: ch.id, unit: ch.unit }))
        const payload = {
            channels: channels.map(ch => ({
              channelId: ch.id,
              unit: ch.unit
            })),
            dateType: jobsBoxesFilters.dateType,
            dateFrom: jobsBoxesFilters.dateFrom,
            dateTo: jobsBoxesFilters.dateTo
          };
        connection.invoke('InitializeTimeBasedChannelDataWithDateRange', payload, lastProcessedTimestamp).then()


        return () => {
            connection.off('ChannelDataResponse')
        }
    }, [connection, channels, lastProcessedTimestamp, onIncomingData])

    return null
}

export default LiveJobSignalrTimeBasedNew

