import React, { useEffect, useState } from 'react'
import { useLocation, Redirect } from 'react-router'
import SignInForm from 'Pages/Auth/SignIn/SignInForm.component'
import useForm from 'react-hook-form'
import useApiCall from 'Hooks/useApiCall'
import authEndpoints from 'Services/endpoints/auth.endpoints'
import qs from 'qs'
import { useAuth } from 'Contexts/AuthProvider'
import useTranslation from 'Hooks/useTranslation'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SignInPage = () => {
    const translation = useTranslation();
    const query = useQuery()
    const queryOneJobPassword = query.get('oneJobPassword')
    const queryPassword = query.get('password')
    const queryEmail = query.get('email')
    const [oneJobPass, setOneJobPass] = useState(null)
    const [password, setPassword] = useState(null)
    const [email, setEmail] = useState(null)
    const [emailMode, setEmailMode] = useState(true)
    const [isSuccessfullSubmit, setIsSuccessfulSubmit] = useState(false)
    const [request, signin] = useApiCall()
    const { login, isLoggedIn } = useAuth()

    const { ...formProps } = useForm({
        defaultValues: { username: queryEmail || '', password: queryPassword || queryOneJobPassword || '' }
    })

    const signIn = data => {
        if (data) {
            if (emailMode) {
                data.grant_type = 'password'
                data.scope = 'offline_access openid'
            } else {
                data.grant_type = 'one_job_password'
            }
        }
        signin({
            ...authEndpoints.getToken(),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(data)
        })
    }

    useEffect(() => {
        setOneJobPass(queryOneJobPassword)
    }, [queryOneJobPassword])

    useEffect(() => {
        setPassword(queryPassword)
    }, [queryPassword])

    useEffect(() => {
        setEmail(queryEmail)
    }, [queryEmail])

    useEffect(() => {
        if (oneJobPass) {
            setEmailMode(false)
            formProps.register({ defaultValues: { username: '', password: oneJobPass } })
        }
        if (password && email) {
            formProps.register({ defaultValues: { username: email, password: password } })
        }
        if (!request.response)
            return

        setIsSuccessfulSubmit(true)
        login(request.response.access_token)

    }, [request.response, login, oneJobPass, formProps, email, password])

    const toggleMode = (e) => {
        if (emailMode === false)
            setOneJobPass(null)
        else {
            setPassword(null)
            setEmail(null)
        }
        setEmailMode(m => !m)
        formProps.reset({ defaultValues: { username: queryEmail || '', password: queryPassword || queryOneJobPassword || '' } })
    }

    if (isSuccessfullSubmit || isLoggedIn) {
        return <Redirect to={{pathname: '/', state: {afterLogin: isSuccessfullSubmit}}} />
    }

    return (
        <div className="">
            <div className={emailMode ? 'optionsSwitch mb8' : 'optionsSwitch mb8 in'}>
                <div className="option" onClick={toggleMode}>{translation.signInPageEmailSwitchLabel}</div>
                <div className="option" onClick={toggleMode}>{translation.signInPageOneJobPasswordLabel}</div>
            </div>
            <SignInForm {...formProps}
                onSubmit={signIn}
                request={request}
                emailMode={emailMode} />
        </div>
    )
}

export default SignInPage
