import React from 'react';
import Header from './Header.component';
import CssBaseline from '@material-ui/core/CssBaseline';
import LanguageSelector from '../LanguageSelector/LanguageSelector.component';
import ThemeSelector from '../ThemeSelector/ThemeSelector.component';
import { useParams } from 'react-router-dom';
import { getBoxes } from 'Store/modules/dashboard/selectors';
import { connect } from 'react-redux';
import Copyright from '../Copyright';

const MainLayout = ({ children, boxes }) => {

    const { dashboardId } = useParams();
    const mainClass = (boxes && boxes.length) ? 'mainLayout' : 'mainLayout introImg';

    return (
        <div className={mainClass}>
            <CssBaseline />
            {!dashboardId && <Header />}
            <main className="main">
                {children}
            </main>
            {
                !dashboardId ? (
                    <footer className="mainFooter">
                        <div className="mainContainer">
                            <div className="footerWrapper">
                                <ThemeSelector />
                                <LanguageSelector />
                                <Copyright />
                            </div>
                        </div>
                    </footer>
                ) : <footer className="mainFooter empty" />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    boxes: getBoxes(state),
});

export default connect(mapStateToProps)(MainLayout);
