import axios from 'axios'
import {signoutRequest} from '../../Store/modules/auth/actions'
import {toast} from 'react-toastify'

export default {
    setupInterceptors: store => {
        axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response) {
                    const status = error.response.status || 500
                    const data = error.response.data || {}
                    if (status === 401) {
                        if (data.error === 'access_denied') {
                            store.dispatch(signoutRequest())
                            toast.error('Session expired!')
                        }
                    }

                    if (status === 422) {
                        if (!data.errors && data.message) {
                            error.response.data = {...data, errors: {general: [data.message]}}
                        }
                    }

                }
                throw error
            }
        )
    }
}
