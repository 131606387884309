import React  from 'react';

const UnitDigitalViewCard = ({ data }) => {

    return (
        <div className="digitalDisplayList">
            {
                data && data.map((channel) => {
                    return (
                        <li className="digitalDisplayListItem" key={channel.id}>
                            <h3 className="name">{channel.nameLocalized}</h3>
                            <div className="subtitle">{`${channel.unitName} (${channel.unitCode})`}</div>
                            <div className="displayContent">
                                <div className="data">
                                    <span
                                        className="qnt">{channel.value === 0 ? 0 : channel.value || '-'}
                                    </span>
                                    {channel.unit}
                                </div>
                                <div className="statusbar" style={{ backgroundColor: channel.color || 'red' }} />
                            </div>
                        </li>
                    );
                })
            }
        </div>
    );
};

export default UnitDigitalViewCard;
