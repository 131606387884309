import Types from './types'

export const setDepartments = departments => ({
    type: Types.SET_DEPARTMENTS,
    payload: departments
})

export const setEmployees = employees => ({
    type: Types.SET_EMPLOYEES,
    payload: employees
})

export const setCurrentDepartment = department => ({
    type: Types.SET_CURRENT_DEPARTMENT,
    payload: department
})

export const setComponents = components => ({
    type: Types.SET_COMPONENTS,
    payload: components
})

export const updateComponentWorkhours = data => {
    return {
        type: Types.UPDATE_COMPONENT_WORKHOURS,
        payload: data
    }
}

export const updateComponentNote = data => {
    return {
        type: Types.UPDATE_COMPONENT_NOTE,
        payload: data
    }
}

export const setParts = parts => ({
    type: Types.SET_PARTS,
    payload: parts
})

export const setRecommendedSpareParts = recommendedSpareParts => ({
    type: Types.SET_RECOMMENDED_SPARE_PARTS,
    payload: recommendedSpareParts
})

export const setPartsCatalog = partsCatalog => ({
    type: Types.SET_PARTS_CATALOG,
    payload: partsCatalog
})

export const setComponentsCatalog = componentsCatalog => ({
    type: Types.SET_COMPONENTS_CATALOG,
    payload: componentsCatalog
})

export const setCurrentPage = currentPage => ({
    type: Types.SET_CURRENT_PAGE,
    payload: currentPage
})

export const setRunComponentsUpdate = runComponentsUpdate => ({
    type: Types.SET_RUN_COMPONENTS_UPDATE,
    payload: runComponentsUpdate
})

export const setTypeOptions = options => ({
    type: Types.SET_TYPE_OPTIONS,
    payload: options
})

export const setStatusOptions = options => ({
    type: Types.SET_STATUS_OPTIONS,
    payload: options
})

export const setStates = options => ({
    type: Types.SET_STATES,
    payload: options
})

export const setCurrentCompanyPage = page => ({
    type: Types.SET_CURRENT_COMPANY_PAGE,
    payload: page
})

export const setEditingCompany = company => ({
    type: Types.SET_EDITING_COMPANY,
    payload: company
})

export const setReports = reports => ({
    type: Types.SET_REPORTS,
    payload: reports
})

export const setUnassignedComponents = unassignedComponents => ({
    type: Types.SET_UNASSIGNED_COMPONENTS,
    payload: unassignedComponents
})

export const setFilterInfo = filterInfo => ({
    type: Types.SET_FILTER_INFO,
    payload: filterInfo
})

export const setActivities = activities => ({
    type: Types.SET_ACTIVITIES,
    payload: activities
})

export const setPageTitle = pageTitle => ({
    type: Types.SET_PAGE_TITLE,
    payload: pageTitle
})

export const setRspSelectedComponentsIds = rspSelectedComponentsIds => ({
    type: Types.SET_RSP_SELECTED_COMPONENTS_IDS,
    payload: rspSelectedComponentsIds
})

export const setSelectedUnassignedComponentsIds = selectedUnassignedComponentsIds => ({
    type: Types.SET_SELECTED_UNASSIGNED_COMPONENTS_IDS,
    payload: selectedUnassignedComponentsIds
})
