import React, { useEffect, useState } from "react";
import { Dialog, Button } from "@material-ui/core";
import useTranslation from "Hooks/useTranslation";
import { apiBaseUrl } from "config";
import axios from "axios";
import 'maplibre-gl/dist/maplibre-gl.css';
import "maplibre-gl-js-amplify/dist/public/amplify-map.css";
import maplibregl from 'maplibre-gl';
import { Cached } from "@material-ui/icons";


const MapComponentModal = (props) => {
  const { isOpen, onCancel, currentUnitModel } = props;
  const translation = useTranslation();
  const uId = currentUnitModel?.uId;
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const getMapCoordinates = async () => {
    const urlMap = `${apiBaseUrl}/data/gps/${currentUnitModel?.uId}`;
  
    try {
      const { data } = await axios.get(urlMap);
      setLat(data.latitude);
      setLng(data.longitude);
      return data;
    } catch (error) {
      console.error("Error fetching map coordinates:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (isOpen) {
      getMapCoordinates();
    }
  }, [isOpen, currentUnitModel]);

  const refreshMap = () => {
    getMapCoordinates();
  };

  const handleClose = () => {
    setLat(null);
    setLng(null);
    onCancel();
  };

  useEffect(() => {
    if (lat !== null && lng !== null) {
      initializeMap();
    }
  }, [lat, lng]);
  
  
  async function initializeMap() {
    const apiKey = "v1.public.eyJqdGkiOiI5MzcwNDFkYi02M2UzLTQ1MzgtODNiYi0xOGQwN2ZkMDUzNDcifYfo7-yc6R6yYy_LemT0RbeOdUSpRBH4lCtahgwsWgQ6WPZtZ8l4PgQgcIzZdQ7soyv2Opt3vp9a1Rf5ULwLKg71PcHmUB6ni2zPrtv_v8yOsPMjx5EVng432nPonmOIsKVw-A-7D16ajmG1hgSfulSUtEYDtujUSR0Bdrih2goGUhtLkd7rWrQS3KM71qVvJ6oDTYBQdmmTP6sBTef_lJaM7BCTvu0APvjZJxiZy9ZgTaIPYFp3esRtOQ97Zl5mnjhLfpTwMqE_VK3zryHoGDO6MT_P53recN9iWGwc88ZU7QVsE2NlaNnK2ImjTSlWXN677RPCnWvxT24SHQgn3x0.Y2MzMTcwNWYtZTgwMy00NGZlLWFiNGItNzVkMDFmMDM0Nzcy";
    const mapName = "dms-map";
    const region = "eu-central-1";
    
    const map = new maplibregl.Map({
      container: 'map', 
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [lng, lat], 
      zoom: 11
    });
    map.addControl(new maplibregl.NavigationControl(), "top-left");
    new maplibregl.Marker({ color: 'red' })
    .setLngLat([lng, lat])
    .addTo(map);
  }
  
  return (
    <Dialog open={isOpen} className="mapModal" onClose={handleClose}>
      <div className="modalTitleHolder mb16">
        <h1 className="modalTitle text-center">UNIT CURRENT LOCATION</h1>
      </div>
  
      <div id="map" style={{ height: '500px' }}></div>
  
      <div className="btnHolder end mt16 fixedWidthBtnWithoutMargin">
        <Button className="refreshButton" startIcon={<Cached />} onClick={refreshMap}>
          <span className="refreshButtonLabel">{translation.refreshButtonLabel}</span>
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          {translation.closeButtonLabel}
        </Button>
      </div>
    </Dialog>
  );
}  

export default MapComponentModal;
