import { combineReducers } from 'redux'

import authReducer from './modules/auth/reducer'
import dashboardReducer from './modules/dashboard/reducer'
import settingsReducer from './modules/settings/reducer'
import mssReducer from './modules/mms/reducer'


export default combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    settings: settingsReducer,
    mms: mssReducer
})
