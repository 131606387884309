import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

const getDataExport = async ({ companyId, tabType }) => {
    const { data } = await axios(`${apiBaseUrl}/dataexport/${tabType}`, {
        headers: { 'CompanyId': companyId },
    });
    return data;
};

export default function useDataExport(tabType = 'jobs') {
    const { userLoaded, currentCompany } = useAuth();
    return useQuery(['data-export', tabType], () => getDataExport({tabType, companyId: currentCompany?.id }),
        {
            initialData: {},
            keepPreviousData: true,
            enabled: userLoaded,
        });
}



