import _ from 'lodash'

let initConfig = {
    title: undefined,
    credits: {enabled: false},
    plotOptions: {
        series: {
            marker: {
                radius: 2,
                symbol: 'circle',
                enabledThreshold: 3
            },
            lineWidth: 1,
            states: {
                hover: {
                    lineWidthPlus: 0
                }
            },
            turboThreshold: 10000
        }
    },
    chart: {
        styledMode: false,
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 5,
        spacingRight: 5,
        style: {
            fontFamily: '\'Open Sans\', sans-serif'
        },
        backgroundColor: 'transparent',
        animation: false,
        resetZoomButton: {
            theme: {
                r: 20
            }
        }
    },
    legend: {
        align: 'left',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemStyle: {
            fontSize: '14px',
            fontWeight: '500'
        }
    },
    xAxis: {
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px',
                fontWeight: '500'
            }
        },
        tickWidth: 1,
        margin: 0,
        title: {
            margin: 10,
            offset: 15
        }
    },
    yAxis: {
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px',
                fontWeight: '500'
            }
        },
        lineWidth: 1,
        gridLineWidth: 0,
        tickWidth: 1,
        showEmpty: false,
        tickAmount: 6, // need somehow make more on reverse (based on axis length?)
        margin: 10,
        title: {
            margin: 10,
            offset: 15
        }
    },
    tooltip: {
        split: true,
        style: {
            fontSize: '13px',
            fontWeight: '500'
        }
    },
    showEmpty: false
}

let lightConfig = _.merge(
    {},
    initConfig,
    {
        chart: {
            resetZoomButton: {
                theme: {
                    fill: 'transparent',
                    stroke: 'rgba(68, 68, 68, 0.24)',
                    style: {
                        color: 'rgba(68, 68, 68, 0.88)'
                    },
                    states: {
                        hover: {
                            fill: 'rgba(0, 0, 0, 0.08)'

                        }
                    }
                }
            }
        },
        tooltip: {
            backgroundColor: 'rgba(255,255,255,.88)',
            style: {
                color: 'rgba(68, 68, 68, 0.88)'
            }
        },
        legend: {
            itemStyle: {
                color: 'rgba(68, 68, 68, 0.88)'
            },
            itemHiddenStyle: {
                color: 'rgba(68, 68, 68, 0.4)'
            },
            itemHoverStyle: {
                color: 'rgba(68, 68, 68, 1)'
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: 'rgba(68, 68, 68, 0.88)'
                }
            },
            title: {
                style: {
                    color: 'rgba(68, 68, 68, 0.4)'
                }
            },
            lineColor: 'rgba(68, 68, 68, 0.24)',
            tickColor: 'rgba(68, 68, 68, 0.24)'
        },
        yAxis: {
            labels: {
                style: {
                    color: 'rgba(68, 68, 68, 0.88)'
                }
            },
            title: {
                style: {
                    color: 'rgba(68, 68, 68, 0.4)'
                }
            },
            lineColor: 'rgba(68, 68, 68, 0.24)',
            tickColor: 'rgba(68, 68, 68, 0.24)'
        }
    }
)

let darkConfig = _.merge(
    {},
    initConfig,
    {
        chart: {
            resetZoomButton: {
                theme: {
                    fill: 'transparent',
                    stroke: 'rgba(255, 255, 255, 0.24)',
                    style: {
                        color: 'rgba(255, 255, 255, 0.88)'
                    },
                    states: {
                        hover: {
                            fill: 'rgba(255, 255, 255, 0.08)'

                        }
                    }
                }
            }
        },
        tooltip: {
            backgroundColor: 'rgba(68,68,68,.88)',
            style: {
                color: 'rgba(255, 255, 255, 0.88)'
            }
        },
        legend: {
            itemStyle: {
                color: 'rgba(255, 255, 255, 0.88)'
            },
            itemHiddenStyle: {
                color: 'rgba(255, 255, 255, 0.4)'
            },
            itemHoverStyle: {
                color: 'rgba(255, 255, 255, 1)'
            }
        },
        xAxis: {
            labels: {
                style: {
                    color: 'rgba(255, 255, 255, 0.88)'
                }
            },
            title: {
                style: {
                    color: 'rgba(255, 255, 255, 0.4)'
                }
            },
            lineColor: 'rgba(255, 255, 255, 0.24)',
            tickColor: 'rgba(255, 255, 255, 0.24)'
        },
        yAxis: {
            labels: {
                style: {
                    color: 'rgba(255, 255, 255, 0.88)'
                }
            },
            title: {
                style: {
                    color: 'rgba(255, 255, 255, 0.4)'
                }
            },
            lineColor: 'rgba(255, 255, 255, 0.24)',
            tickColor: 'rgba(255, 255, 255, 0.24)'
        }
    }
)

export {lightConfig, darkConfig}
