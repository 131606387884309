import React from 'react';
import { Dialog } from '@material-ui/core';
import UnitList from './UnitList';
import useTranslation from '../../../Hooks/useTranslation';

const ChangeUnitModal = (props) => {

    const {
        isOpen,
        onCancel,
        onSave,
        unitsRequest,
    } = props;

    const translation = useTranslation();

    const { data: units } = unitsRequest;

    return (
        <Dialog
            open={isOpen}
            className="respModal"
        >
            <div className="modalTitleHolder mb16 hasBackLink">
                <h1 className="modalTitle text-center">{translation.changeUnitLabel}</h1>
            </div>

            <UnitList
                submitButtonTitle={translation.saveButtonLabel}
                items={units}
                onCancel={onCancel}
                onSave={onSave}
            />
        </Dialog>
    );
};

export default ChangeUnitModal;
