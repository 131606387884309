const LANG_LOCALSTORAGE_KEY = process.env.REACT_APP_LANG_LOCALSTORAGE_KEY || '_lang_'

export const getStoredLang = () => window.localStorage.getItem(LANG_LOCALSTORAGE_KEY)

export const setStoredLang = (id) => {
    window.localStorage.setItem(LANG_LOCALSTORAGE_KEY, id)
}

export const clearStoredLang = () => {
    window.localStorage.removeItem(LANG_LOCALSTORAGE_KEY)
}
