import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';

export const DefaultColumnFilter = ({ column }) => {
    const { filterValue, Header, setFilter } = column;

    return (
        <TextField
            placeholder={Header}
            style={{ width: '100%' }}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            type="search"
            size="small"
            variant="outlined"
        />
    );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <Select
            variant="outlined"
            fullWidth
            displayEmpty
            onChange={e => setFilter(e.target.value || undefined)}
            value={filterValue || ''}
            placeholder="All"
        >
            <MenuItem className="filterMenuItem" value={''}>All</MenuItem>
            {options.map((option, i) => (
                    <MenuItem
                        className="filterMenuItem"
                        key={i}
                        value={option}
                    >
                        {option}
                    </MenuItem>
                ),
            )}
        </Select>
    );
};

export const MultiSelectColumnFilter = ({ column }) => {
    // Calculate the options for filtering
    // using the preFilteredRows

    const { filterValue, setFilter, preFilteredRows, id, Header } = column;

    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);


    const handleChange = e => {
        setFilter(e.target.value);
    };

    return (
        <Select
            placeholder="nice"
            variant="outlined"
            type="search"
            fullWidth
            displayEmpty
            multiple
            onChange={handleChange}
            value={filterValue || []}
            renderValue={(selected) => selected.length ? selected.join(', ') :
                <div className="placeholder">{Header}</div>}
        >

            <MenuItem value="" disabled>
                {Header}
            </MenuItem>
            {options.map((option, i) => (
                    <MenuItem
                        dense
                        className="filterMenuItem"
                        key={i}
                        value={option}
                    >
                        <Checkbox
                            color="primary"
                            checked={(filterValue || []).indexOf(option) > -1}
                        />
                        <ListItemText primary={option} />
                        {/*{option}*/}
                    </MenuItem>
                ),
            )}
        </Select>
    );
};
