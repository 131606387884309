import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAppSettings } from '../Contexts/AppSettingsProvider';
import { appLangCode } from '../helpers';

const getUnitDateTypes = async (lang) => {
    const {data} = await axios(`${apiBaseUrl}/units/date-types`, {
        headers: { 'Accept-Language': appLangCode(lang) },
    })
    return data;
}

export default function useUnitDateTypes() {
    const { appLanguage, loading } = useAppSettings();
    return useQuery(['unit-date-types', appLanguage], () => getUnitDateTypes(appLanguage), {
        initialData: [],
        keepPreviousData: true,
        enabled: !loading && !!appLanguage
    })
}
