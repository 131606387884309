import React from 'react';
import EmptyDataSpan from '../../EmptyDataSpan';

const UnitBoxDataError = ({error}) => error || (
    <p className="text-center" style={{paddingTop: '30px', color: 'rgb(144 144 144)', marginBottom: '20px'}}>
        <EmptyDataSpan />
    </p>
);

export default UnitBoxDataError;
