import React from 'react';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateIcon from '@material-ui/icons/Create';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DomainIcon from '@material-ui/icons/Domain';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import BuildIcon from '@material-ui/icons/Build';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import { NotificationImportant } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import StorageIcon from '@material-ui/icons/Storage';
import TuneIcon from '@material-ui/icons/Tune';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import WorkIcon from '@material-ui/icons/Work';
import BallotIcon from '@material-ui/icons/Ballot';

const MyIcons = (props) => {
    return (
        <>
            {props.icon === 'photoCamera' && <PhotoCamera />}
            {props.icon === 'lowPriorityIcon' && <LowPriorityIcon />}
            {props.icon === 'equalizerIcon' && <EqualizerIcon />}
            {props.icon === 'filterNoneIcon' && <FilterNoneIcon />}
            {props.icon === 'getAppIcon' && <GetAppIcon />}
            {props.icon === 'personAddIcon' && <PersonAddIcon />}
            {props.icon === 'deleteOutlineIcon' && <DeleteOutlineIcon />}
            {props.icon === 'addCircleOutlineIcon' && <AddCircleOutlineIcon />}
            {props.icon === 'createIcon' && <CreateIcon />}
            {props.icon === 'peopleAltIcon' && <PeopleAltIcon />}
            {props.icon === 'domainIcon' && <DomainIcon />}
            {props.icon === 'accountTreeIcon' && <AccountTreeIcon />}
            {props.icon === 'apartmentIcon' && <ApartmentIcon />}
            {props.icon === 'assessmentIcon' && <AssessmentIcon />}
            {props.icon === 'playlistAddCheckIcon' && <PlaylistAddCheckIcon />}
            {props.icon === 'buildIcon' && <BuildIcon />}
            {props.icon === 'developerBoardIcon' && <DeveloperBoardIcon />}
            {props.icon === 'notificationImportant' && <NotificationImportant />}
            {props.icon === 'settingsIcon' && <SettingsIcon />}
            {props.icon === 'logOutIcon' && <ExitToAppIcon />}
            {props.icon === 'passwordIcon' && <VpnKeyIcon />}
            {props.icon === 'dataUsageIcon' && <StorageIcon />}
            {props.icon === 'settingIcon' && <TuneIcon />}
            {props.icon === 'locationIcon' && <MyLocationIcon />}
            {props.icon === 'unitsIcon' && <BlurLinearIcon />}
            {props.icon === 'jobsIcon' && <WorkIcon />}
            {props.icon === 'componentsIcon' && <BallotIcon />}
            
        </>
    );
};
export default MyIcons;