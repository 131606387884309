import React, { Fragment, useState, useMemo } from 'react'
import { MenuItem, Popover } from '@material-ui/core'
import Button from '@material-ui/core/Button'

const TimeIcon = () => <div className='timeIconWithArrow' />

const TimeTypeMenu = ({ currentType, onChangeTimeType, boxType }) => {

    const [anchorEl, onAnchorElChange] = useState(null)

    const closeMenu = () => onAnchorElChange(null)

    const handleSelectTimeType = type => {
        closeMenu()
        onChangeTimeType(type)
    }

    const timeTypesOptions = useMemo(() => {
        let typeList = {
            local: 'Local',
            fromDatabase: 'Database'
        }
        if (boxType !== 'eventLogger') {
            typeList.elapsed = 'Elapsed'
        }
        return typeList
    }, [boxType])

    return (
        <Fragment>
            <Button onClick={(e) => onAnchorElChange(e.target)}
                startIcon={<TimeIcon />}
                className='dropDownBtn'>
                Time Mode: {timeTypesOptions[currentType] || timeTypesOptions.fromDatabase}
            </Button>

            <Popover anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                disableScrollLock={true}
                onClose={closeMenu}>
                {
                    Object.keys(timeTypesOptions).map(type => (
                        <MenuItem onClick={() => handleSelectTimeType(type)} key={type}>
                            {timeTypesOptions[type]}
                        </MenuItem>
                    ))
                }
            </Popover>
        </Fragment>
    )
}

export default TimeTypeMenu
