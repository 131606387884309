import { useQuery } from 'react-query';
import axios from 'axios';
import { apiAuthBaseUrl } from 'config';
import { useAppSettings } from '../Contexts/AppSettingsProvider';
import { appLangCode } from '../helpers';

const getItemTypes = async (lang, type = 'jobs') => {
    const {data} = await axios(`${apiAuthBaseUrl}/items/types/${type}`, {
        headers: { 'Accept-Language': appLangCode(lang) },
    })
    return data;
}

export default function useItemTypes(type) {
    const { appLanguage, loading } = useAppSettings();
    return useQuery(['item-types', type, appLanguage], () => getItemTypes(appLanguage, type), {
        initialData: [],
        keepPreviousData: true,
        enabled: !loading && !!appLanguage
    })
}
