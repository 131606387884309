import React, { Fragment, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { MenuItem, Popover } from '@material-ui/core'
import useTranslation from 'Hooks/useTranslation'
import { useBoxes } from 'Contexts/BoxContext'

const DotsIcon = () => <div className="dotsIcon" />

const BoxHeaderContextMenuComponent = props => {
    const {
        onNewWindowOpen,
        onManageChannel,
        onRemove,
        onManageAxesSettings,
        onManageDateFormat,
        onExportToCsv,
        type,
        allowExport,
        onExportToImage
    } = props

    const [anchorEl, onAnchorElChange] = useState(null)
    const translation = useTranslation()
    const { setModalStep, setBoxType } = useBoxes();

    const closeMenu = () => {
        onAnchorElChange(null)
    }

    const handleNewWindowOpen = () => {
        closeMenu()
        onNewWindowOpen()
    }

    const handleRemove = () => {
        closeMenu()
        onRemove()
    }

    const handleManageChannels = () => {
        closeMenu()
        onManageChannel()
    }

    const handleManageAxesSettings = () => {
        closeMenu()
        onManageAxesSettings()
    }

    const handleManageDateFormat = () => {
        closeMenu()
        onManageDateFormat()
    }

    const handleExportToCsv = () => {
        closeMenu()
        onExportToCsv()
        setModalStep('exportCsv')
        setBoxType(type)
    }

    return (
        <Fragment>
            <IconButton onClick={(e) => onAnchorElChange(e.target)}>
                <DotsIcon />
            </IconButton>

            <Popover anchorEl={anchorEl}
                     keepMounted
                     open={Boolean(anchorEl)}
                     transformOrigin={{vertical: 'top', horizontal: 'right'}}
                     anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                     disableScrollLock={true}
                     onClose={closeMenu}>
                <MenuItem onClick={handleNewWindowOpen}
                          className="filterMenuItem">{translation.dashboardOpenInNewWindowLabel}</MenuItem>
                <MenuItem onClick={handleRemove}
                          className="filterMenuItem">{translation.dashboardRemoveFromDashboardLabel}</MenuItem>
                {!['eventLogger', 'jobProperties', 'map'].includes(type) && (
                    <MenuItem onClick={handleManageChannels}
                              className="filterMenuItem">{translation.dashboardManageChannelsLabel}</MenuItem>
                )}
                {type === 'chartView' && (
                    <MenuItem onClick={handleManageAxesSettings}
                              className="filterMenuItem">{translation.manageAxesModalManageAxesLabel}</MenuItem>
                )}
                {!['digitalView', 'chartView', 'map'].includes(type) && (
                    <MenuItem onClick={handleManageDateFormat}
                              className="filterMenuItem">{translation.manageDateSettingsModalManageDateSettingsLabel}</MenuItem>
                )}
                {(['eventLogger', 'dataTable', 'chartView'].includes(type) && allowExport) ?
                    <MenuItem onClick={handleExportToCsv}
                              className="filterMenuItem">{translation.dashboardExportToCsvLabel}</MenuItem> : null
                }
                {(['chartView'].includes(type) && allowExport) ?
                    <MenuItem onClick={() => {onExportToImage(); closeMenu()}}
                              className="filterMenuItem">Export to Image</MenuItem> : null
                }
            </Popover>
        </Fragment>
    )
}

export default BoxHeaderContextMenuComponent
