import React from 'react'
import Route from './Route'
import {Redirect} from 'react-router-dom'
import {useAuth} from 'Contexts/AuthProvider'

const PrivateRoute = ({...rest}) => {
    const {isLoggedIn} = useAuth()
    return isLoggedIn
        ? <Route {...rest} />
        : <Redirect to={{pathname: '/signin', state: {referer: rest.location}}}/>
}

export default PrivateRoute
