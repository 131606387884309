import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, Dialog, IconButton, TextField, Tooltip } from '@material-ui/core';
import TemplateList from 'Components/Templates/template-list/template-list.component';
import DmsService from 'Services/DMS/dms-api.service';
import { useAuth } from 'Contexts/AuthProvider';
import AlertToastr from 'utils/alert';
import { connect } from 'react-redux';
import useTranslation from 'Hooks/useTranslation';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { filterArray } from '../../../../utils/functions';
import moment from 'moment';
import { useBoxes } from 'Contexts/BoxContext';

const JobLabel = ({ label, job }) => {
    return (
        <div className="jobLabel">
            <div className="labelName">{label}</div>
            <div className="infoPart">
                <div className="startDate date">{moment(job.startTime).format('DD-MMM-YY, HH:mm')}</div>
                {job.stopTime ?
                    <div className="stopDate date">{moment(job.stopTime).format('DD-MMM-YY, HH:mm')}</div> :
                    <div className="stopDate date">

                    </div>}
            </div>
        </div>
    );
};

const LoadTemplateModal2 = props => {
    const {
        isOpen,
        isOpenChangeHandler,
        jobs,
        loadedTemplate,
        onTemplateLoaded,
        setCurrentJobUnits,
        currentJob,
    } = props;


    const [isTemplateSelectionStep, setIsTemplateSelectionStep] = useState(false);
    const { changeJobsGlobalFilters } = useBoxes();
    const { win } = useAuth();
    const translation = useTranslation();

    const [searchJob, setSearchJob] = useState('');
    const [errors, setErrors] = useState({
        type: 'error', //warning
        messages: null,
    });
    const [selectedJob, setSelectedJob] = useState('');
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState((loadedTemplate && !loadedTemplate.modified && loadedTemplate.id) || '');
    const [searchTemplate, setSearchTemplate] = useState('');
    const [templateLoadedResponse, setTemplateLoadedResponse] = useState({ tabs: null, template: null });
    const [templateLoading, setTemplateLoading] = useState(false);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [isNextBtnClicked, setIsNextBtnClicked] = useState(true);
    const filteredJobs = useMemo(() => {
        if (searchJob && searchJob !== '')
            return filterArray(jobs, searchJob);
        return jobs;
    }, [jobs, searchJob]);

    const filteredTemplates = useMemo(() => {
        if (!setSearchTemplate)
            return templates;

        return templates?.filter(item => {
            const name = item.name.toLowerCase();
            const search = searchTemplate.toLowerCase();
            return name.includes(search);
        });
    }, [templates, searchTemplate]);

    const fetchTemplates = useCallback(async (jobId) => {
        setIsLoadingTemplates(true);
        try {
            const response = await DmsService.getTemplatesByJobId(jobId);
            setTemplates(response.data || []);
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message);
        } finally {
            setIsLoadingTemplates(false);
        }
    }, []);

    useEffect(() => {
        if (isOpen && !isNextBtnClicked && selectedJob)
            fetchTemplates(selectedJob).then();
    }, [fetchTemplates, isNextBtnClicked, isOpen, selectedJob]);

    useEffect(() => {
        if (loadedTemplate && loadedTemplate.id && !loadedTemplate.modified && isOpen) {
            setSelectedTemplate(loadedTemplate.id);
        }
    }, [loadedTemplate, isOpen]);

    useEffect(() => {
        if (jobs && jobs.length) {
            if (currentJob) {
                const currentJobInList = jobs.find(item => item.id === currentJob.id);
                if (currentJobInList) {
                    setSelectedJob(currentJobInList.id);
                    setIsTemplateSelectionStep(true);
                    setIsNextBtnClicked(false)
                }
            } else {
                setSelectedJob(jobs[0].id);
            }
        }
    }, [jobs, currentJob]);

    const updateTemplateList = () => {
        fetchTemplates(selectedJob).then();
    };

    const handleSuccessTemplateLoad = (tabs, template) => {
        onTemplateLoaded(selectedTemplate, selectedJob, tabs, template);
        isOpenChangeHandler(false);
    };

    useEffect(() => {
        const fetchJobById = async (jobId) => {
            if (jobId && jobs && jobs.length) {
                let response = await DmsService.getJobsById(jobId);
                if (response) {
                    const job = jobs.find(x => x.id === jobId);
                    job.units = response.units;
                    setCurrentJobUnits(response.units);
                }
            }
        };
        if (selectedJob && isOpen) {
            fetchJobById(selectedJob).then();
        }

    }, [selectedJob, setCurrentJobUnits, jobs, isOpen]);

    const handleLoadTemplate = () => {
        if (selectedJob && selectedTemplate) {
            setTemplateLoading(true);

            DmsService.loadTemplate(selectedTemplate, selectedJob, win.id)
                .then(response => {
                    const tabs = response && response.data && response.data.tabs;
                    const messages = response && response.data && response.data.messages;
                    const template = templates.find(template => template.id === selectedTemplate);
                    const dateType = response?.data?.tabs[0]?.items[0]?.filters.dateType;
                    if (dateType) {
                        changeJobsGlobalFilters({ dateType });
                    }
                    if (messages && messages.length) {
                        setErrors({ type: 'warning', messages });
                        setTemplateLoadedResponse({
                            tabs,
                            template,
                        });
                    } else {
                        handleSuccessTemplateLoad(tabs, template);
                    }

                    setTemplateLoading(false);
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        setErrors({
                            type: 'error',
                            messages: [error.response.data.message],
                        });
                    }
                    setTemplateLoading(false);
                });
        }
    };

    const handleErrors = () => {
        setErrors({ type: 'error', messages: null });
        if (errors.type === 'warning') {
            const { tabs, template } = templateLoadedResponse;
            if (tabs && template) {
                handleSuccessTemplateLoad(tabs, template);
            }
        }
    };

    const renderError = () => {
        const title = errors.type === 'error' ? 'Error' : 'Warning';
        const iconClass = errors.type === 'error'
            ? 'modalTitle hasIcon error'
            : 'modalTitle hasIcon warning';
        return (
            <Fragment>
                <div className="modalTitleHolder mb16">
                    <h2 className={iconClass}>{title}</h2>
                </div>
                <ul className="errorList">
                    {
                        errors.messages && errors.messages.map((errorMessage, index) => (
                            <li key={index}>{errorMessage}</li>))
                    }
                </ul>
                <div className="btnHolder end mt22 fixedWidthBtn">
                    <Button color={'primary'}
                            onClick={handleErrors}
                            variant={'contained'}>
                        Ok
                    </Button>
                </div>
            </Fragment>
        );
    };

    const renderBackButton = () => {
        if (!isTemplateSelectionStep) return null;

        return (
            <Tooltip disableFocusListener disableTouchListener
                     title={translation.selectJobLabel}>
                <IconButton
                    aria-label="delete"
                    size="small"
                    style={{ position: 'absolute', left: 0 }}
                    onClick={() => {
                        setIsTemplateSelectionStep(false); 
                        setTemplates([])
                    }}
                >
                    <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
        );
    };

    const renderJobSelectStep = () => {
        return (
            <Fragment>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="search"
                    label={translation.dashboardSearchJobLabel}
                    type="text"
                    value={searchJob}
                    onChange={e => setSearchJob(e.target.value)}
                />
                <div className="checkboxListHolder mt8">
                    <div className="labelLike">{translation.dashboardJobsLabel}</div>
                    <div className="labelLike dateStart">{translation.dashboardStartTimeLabel}</div>
                    <div className="labelLike dateStop">{translation.dashboardStopTimeLabel}</div>
                    <div className="checkboxListContainer">
                        <RadioGroup aria-label="jobs" name="jobs" value={selectedJob}
                                    onChange={(event) => setSelectedJob(+event.target.value)}>
                            {filteredJobs &&
                                filteredJobs.map(job => (
                                    <FormControlLabel key={job.id}
                                                      value={job.id}
                                                      label={<JobLabel label={job.name} job={job} />}
                                                      control={<Radio color="default" />} />
                                ))
                            }
                        </RadioGroup>
                    </div>
                </div>

                <div className="btnHolder end mt22 fixedWidthBtn">
                    <Button onClick={() => {
                        isOpenChangeHandler(false);
                        setSelectedTemplate('');
                    }} variant="outlined">{translation.cancelButtonLabel}</Button>
                    <Tooltip 
                    // title={!selectedJob || templates.length === 0 ? translation.nextButtonTooltipLabel : ''}
                    title={''}
                    >
                      <span>
                        <Button 
                          onClick={() => {
                            setIsTemplateSelectionStep(true);
                            setIsNextBtnClicked(true); 
                            fetchTemplates(selectedJob)}
                        }
                          variant="contained"
                          color="primary"
                        //   disabled={!(selectedJob && templates.length > 0)}
                        >
                          {translation.nextButtonLabel}
                        </Button>
                      </span>
                    </Tooltip>
                </div>
            </Fragment>
        );
    };

    const renderTemplateSelectStep = () => {
        return (
            <Fragment>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="search"
                    label={translation.dashboardSearchTemplateLabel}
                    type="text"
                    value={searchTemplate}
                    onChange={e => setSearchTemplate(e.target.value)}
                />
                <TemplateList templates={filteredTemplates}
                              setSelectedTemplate={setSelectedTemplate}
                              canSetTemplate={isOpen}
                              selectedTemplate={selectedTemplate}
                              updateTemplateList={updateTemplateList} 
                              isLoadingTemplates={isLoadingTemplates}
                              />

                <div className="btnHolder end mt22 fixedWidthBtn">
                    <Button
                        onClick={() => {
                            isOpenChangeHandler(false);
                            setSelectedTemplate('');
                        }}
                        variant="outlined"
                    >
                        {translation.cancelButtonLabel}
                    </Button>
                        <Button
                            onClick={handleLoadTemplate}
                            variant="contained"
                            color="primary"
                            disabled={!(selectedTemplate && selectedJob) || templateLoading}
                        >
                            {translation.loadButtonLabel}
                        </Button>
                        {templateLoading && <CircularProgress size={24} />}

                </div>
            </Fragment>
        );
    };

    const renderForm = () => {
        return (
            <Fragment>
                <div className="modalTitleHolder mb16">
                    <h1 className="modalTitle text-center" style={{ position: 'relative' }}>
                        {renderBackButton()}
                        {translation.dashboardLoadTemplateLabel}
                    </h1>
                </div>
                {
                    !isTemplateSelectionStep
                        ? renderJobSelectStep()
                        : renderTemplateSelectStep()
                }
            </Fragment>
        );
    };

    const modalClass = errors.messages ? '' : 'respModal';

    return (
        <Dialog open={isOpen} className={modalClass}>
            {errors.messages ? renderError() : renderForm()}
        </Dialog>
    );
};

const mapStateToProps = ({ dashboard }) => ({
    currentCompany: dashboard.currentCompany,
});

export default connect(mapStateToProps)(LoadTemplateModal2);
