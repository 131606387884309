import { useEffect, useMemo } from 'react'
import {
  HttpTransportType,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { apiBaseUrl } from "config";
import { useClientMethod, useHub, useHubMethod } from 'react-use-signalr'

const LiveUnitsSignalrEventLoggerNew = (props) => {
  const {
    token,
    companyId,
    onIncomingData,
    onIncomingDeleteData,
    requestBody,
    lastModificationDateTime,
  } = props;

  const signalRConnection = useMemo(
    () => {
      const buildHubUrl = companyId
        ? `${apiBaseUrl}/ws/data?companyid=${companyId}`
        : `${apiBaseUrl}/ws/data`;

      const protocol = new JsonHubProtocol();
      const transport = HttpTransportType.WebSockets;

      const options = {
        skipNegotiation: true,
        transport,
        accessTokenFactory: () => token,
        headers: { authorization: token ? `Token ${token}` : `` },
      };

      return new HubConnectionBuilder()
        .withUrl(buildHubUrl, options)
        .withAutomaticReconnect()
        .withHubProtocol(protocol)
        .configureLogging(LogLevel.Information)
        .build();
    }, [token, companyId],
  );

  const {
    hubConnectionState,
    // error
  } = useHub(signalRConnection);
  const {
    invoke,
  } = useHubMethod(signalRConnection, 'GetEventsForUnits');

  useClientMethod(signalRConnection, 'EventsAdded', onIncomingData);
  useClientMethod(signalRConnection, 'EventsUpdated', onIncomingData);
  useClientMethod(signalRConnection, 'EventsDeleted', onIncomingDeleteData);

  useEffect(() => {
    if (requestBody && lastModificationDateTime && hubConnectionState === 'Connected') {
      invoke(requestBody, lastModificationDateTime).then();
    }
    return () => {
      signalRConnection.off('GetEventsForUnits')
    }
  }, [
    requestBody,
    lastModificationDateTime,
    invoke,
    hubConnectionState,
    signalRConnection
  ]);

  return null;

};

export default LiveUnitsSignalrEventLoggerNew;
