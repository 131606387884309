import React, { useMemo } from 'react'
import TemplateListItem from 'Components/Templates/template-list-item/template-list-item.component'
import { userHasPermission } from 'helpers'
import { useAuth } from 'Contexts/AuthProvider'
import RadioGroup from '@material-ui/core/RadioGroup'
import useTranslation from 'Hooks/useTranslation'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

const TemplateList = props => {

    const {
        templates,
        setSelectedTemplate,
        selectedTemplate,
        updateTemplateList,
        isLoadingTemplates
    } = props

    const { currentUser } = useAuth()
    const allowDeleteTemplate = useMemo(() => userHasPermission(currentUser, 'manage_templates'), [currentUser])
    const translation = useTranslation()

    return (
        <div className="checkboxListHolder mt8">
            <div className="labelLike">{translation.dashboardTemplatesLabel}</div>
            <div className="updatedAtLabel">{translation.dashboardTemplatesUpdatedAtLabel}</div>
            <div className="checkboxListContainer">
                    <RadioGroup aria-label="jobs" name="jobs" defaultValue={'template1'}>
                        {templates.map(template => (
                            <TemplateListItem
                                key={template.id}
                                value={template.id}
                                label={template.name}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                allowDeleteTemplate={allowDeleteTemplate}
                                onDeleteItem={updateTemplateList}
                                updatedAt={template.updatedAt}
                            />
                        ))}
                    </RadioGroup>
                    {isLoadingTemplates && (
                    <div className='loaderHolderTemplates'>
                        <Fade in={true} unmountOnExit>
                            <CircularProgress className='loaderHolderTemplates'/>
                        </Fade>
                    </div>
                )} 
            </div>
        </div>
    )
}

export default TemplateList
