import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useUnit } from '../../../Hooks/useUnit';
import { Button, TextField } from '@material-ui/core';
import useTranslation from '../../../Hooks/useTranslation';
import { filterArray } from '../../../utils/functions';
import UnitChannelListItem from './UnitChannelListItem';
import UnitJobDateRangeFilters from '../../filters/UnitJobDateRangeFilters';
import { Checkbox, FormControlLabel } from '@material-ui/core'

const UnitChannelList = (props) => {
    const {
        unit,
        onCancel,
        onSave,
        editedModel,
        enableFilters = false,
        section,
        setSelectedCsvChannels,
        isNotVisible,
        selectedCsvChannels
    } = props;
    const translation = useTranslation();
    const [initialValues, setInitialValues] = useState([]);
    const [values, setValues] = useState([]);
    const { data: unitData } = useUnit(unit?.id, section);
    const [, setFilters] = useState({
        'dateType': '',
        'jobId': '',
    });

    const [selectAll, setSelectAll] = useState(false)

    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        const updatedChannels = items.map(channel => ({
            ...channel,
            unit: channel.unit
        }));
        setValues(checked ? updatedChannels : []);
        setInitialValues(checked ? updatedChannels : []);
    };

    const [search, setSearch] = useState('');

    const items = useMemo(() => {
        return unitData?.channels || [];
    }, [unitData]);

    const canSave = useMemo(() => values.length, [values]);

    const filteredItems = useMemo(() => {
        if (search && search !== '')
            return filterArray(items, search, 'nameLocalized');
        return items;
    }, [items, search]);

    //init
    useEffect(() => {
        if (editedModel) {
            const newValues = editedModel.channels
                ? editedModel.channels.map(ch => {
                    return { ...ch };
                })
                : [];
            setValues(newValues);
            setInitialValues(newValues);
        } else if (selectedCsvChannels) {
            setInitialValues(selectedCsvChannels);
            setValues(selectedCsvChannels);
        } else {
            setValues([]);
            setInitialValues([]);
        }
    }, [editedModel, selectedCsvChannels]);

    const handleSave = (openInNewDashboard = false) => {
        let savedData = {
            unitUid: unit.uId,
            channels: values,
        };
        onSave(savedData, openInNewDashboard);
        if(isNotVisible) {
            setSelectedCsvChannels(values)
        }
    };

    const handleCancel = () => {
        setInitialValues([]);
        onCancel();
    };

    return (
        <Fragment>
            { enableFilters && (
                <UnitJobDateRangeFilters
                    initData={editedModel?.filters}
                    onChange={setFilters}
                    unitUid={unit?.uId}
                />
            )}

            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="search"
                label={translation.dashboardSearchChannelLabel}
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <div className="checkboxListHolder mt8">
                <div className="labelLike">{translation.dashboardChannelsLabel}</div>
                <div className="checkboxListContainer">
                    <div className="unit-list-item">
                    <div style={{display: 'inline-block'}}>
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    color='default'
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                />
                            }
                            label={`Select all  (${initialValues.length}/${filteredItems.length})`}
                        />
                    </div>
                        <div className="unit-list-item-channels" style={{ display: 'block', marginLeft: '' }}>
                            <div className="channel-list" style={{ paddingLeft: 0 }}>
                                {
                                    filteredItems.map(channel => (
                                        <UnitChannelListItem
                                            key={channel.id}
                                            channel={channel}
                                            setValues={setValues}
                                            unit={(initialValues && initialValues.some(ch => ch.code === channel.code)) ?
                                                initialValues.find(ch => ch.code === channel.code).unit : channel.unit}
                                            isChecked={selectAll || (initialValues && initialValues.some(ch => ch.code === channel.code))}
                                            selectAll={selectAll}
                                            setInitialValues={setInitialValues}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    onClick={handleCancel}
                    variant="outlined"
                >
                    {translation.cancelButtonLabel}
                </Button>
                {!isNotVisible &&
                    <Button
                        onClick={() => handleSave(true)}
                        disabled={!canSave}
                        variant="outlined"
                    >
                        {translation.openInNewWindowButtonLabel}
                    </Button>
                }
                <Button
                    onClick={() => handleSave()}
                    disabled={!canSave && !selectAll}
                    variant="contained"
                    color="primary"
                >
                    {!isNotVisible ? translation.openButtonLabel : translation.nextButtonLabel}
                </Button>
            </div>
        </Fragment>
    );
};

export default UnitChannelList;
