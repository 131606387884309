import {apiAuthBaseUrl} from 'config'

export default {
    signUp: () => ({url: `${apiAuthBaseUrl}/users/root`, method: 'post'}),
    getToken: () => ({url: `${apiAuthBaseUrl}/tokens`, method: 'post'}),
    emailConfirm: () => ({url: `${apiAuthBaseUrl}/users/email/confirm`, method: 'post'}),
    passwordResetRequest: () => ({url: `${apiAuthBaseUrl}/users/password/forgot`, method: 'post'}),
    passwordUpdate: () => ({url: `${apiAuthBaseUrl}/users/password/update`, method: 'post'}),
    changePassword: () => ({url: `${apiAuthBaseUrl}/Users/password/change`, method: 'post', ignoreAutToken : true}),
}
//api/tabs/{tabId}/close/child
