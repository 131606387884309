import React, { useMemo, useState } from 'react'
import moment from 'moment/moment'
import useTranslation from '../../../Hooks/useTranslation'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const descConst = 'descending';
const ascConst = 'ascending';
const arrowDownClassName = 'hasArrow';
const arrowUpClassName = 'hasArrow up';

const getDate = (activity) => {
  if (!activity)
    return '';
  if (activity.expectedWorkhoursDate && !activity.expectedCompleteDate)
    return moment(activity.expectedWorkhoursDate).format('L');
  if (!activity.expectedWorkhoursDate && activity.expectedCompleteDate)
    return moment(activity.expectedCompleteDate).format('L');
  if (!activity.expectedWorkhoursDate && !activity.expectedCompleteDate)
    return '';
  if (moment(activity.expectedWorkhoursDate).isAfter(activity.expectedCompleteDate))
    return moment(activity.expectedCompleteDate).format('L');
  else
    return moment(activity.expectedWorkhoursDate).format('L');
};

const ActivitiesTable = (props) => {
  const {
    activities,
    typeOptions,
    priorityOptions,
    onAddActivity,
    onEditActivity,
    disableAdd,
    compModel,
    unassignedComponents
  } = props;

  const [sortField, setSortField] = useState(null);
  const [direction, setDirection] = useState(ascConst);

  const outputActivities = useMemo(() => {
    if (!activities)
      return [];
    let sortedActivities = [...activities];
    if (!sortField || !direction)
      return sortedActivities;

    if (sortField === 'priority') {
      sortedActivities.sort((a, b) => {
        if ((priorityOptions.findIndex(x => x.priority === a.priority)) < (priorityOptions.findIndex(x => x.priority === b.priority))) {
          return direction === ascConst ? -1 : 1;
        }
        if ((priorityOptions.findIndex(x => x.priority === a.priority)) > (priorityOptions.findIndex(x => x.priority === b.priority))) {
          return direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortField === 'tasks') {
      sortedActivities.sort((a, b) => {
        if (a.tasks.length < b.tasks.length) {
          return direction === ascConst ? -1 : 1;
        }
        if (a.tasks.length > b.tasks.length) {
          return direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortField === 'date') {
      sortedActivities.sort((a, b) => {
        if (moment(getDate(a)).isAfter(getDate(b))) {
          return direction === ascConst ? -1 : 1;
        }
        if (moment(getDate(b)).isAfter(getDate(a))) {
          return direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    if (sortField=== 'workhours') {
      sortedActivities.sort((a, b) => {
        if ((a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) < (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)) {
          return direction === ascConst ? -1 : 1;
        }
        if ((a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) > (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)) {
          return direction === ascConst ? 1 : -1;
        }
        return 0;
      });
      return sortedActivities;
    }
    sortedActivities.sort((a, b) => {
      if (a[sortField].toLowerCase() < b[sortField].toLowerCase()) {
        return direction === ascConst ? -1 : 1;
      }
      if (a[sortField].toLowerCase() > b[sortField].toLowerCase()) {
        return direction === ascConst ? 1 : -1;
      }
      return 0;
    });
    return sortedActivities;
  }, [
    priorityOptions,
    activities,
    sortField,
    direction
  ]);

  const translation = useTranslation();

  const changeSortField = (name) => {
    if (name === sortField) {
      setDirection(prev => {
        if (prev === ascConst) return descConst;
        if (prev === descConst) return null;
        return ascConst
      })
    } else {
      setSortField(name)
      setDirection(ascConst)
    }
  }

  const getClassNamesForActivities = (name) => {
    if (sortField !== name) return;
    if (!direction) return;

    return direction === ascConst ? arrowDownClassName : arrowUpClassName;
  }

  const baseWidth = unassignedComponents ? 340 : 600;
  const decrementAmount = 30;

  const calculateWidth = (nestingLevel) => {
    return `${baseWidth - (nestingLevel - 1) * decrementAmount}px`;
  };

  return (
    <table className="activitiesTable">
      <thead>
      <tr>
      <th></th>
        <th onClick={() => changeSortField('name')}
            id="assetsTblActivityTblHeaderNameBtn"
            className={getClassNamesForActivities('name')} 
            style={{ width: calculateWidth(compModel.nestingLevel), paddingLeft: '10px' }}
            >{translation.equipmentPageActivitiesDescriptionHeader}</th>
        <th onClick={() => changeSortField('type')} style={{width: unassignedComponents ? '230px' :'180px'}}
           id="assetsTblActivityTblHeaderTypeBtn"
           className={getClassNamesForActivities('type')}>{translation.equipmentPageActivitiesTypeHeader}</th>    
        <th onClick={() => changeSortField('priority')} style={{width: unassignedComponents ? '150px' :'25px'}}
            id="assetsTblActivityTblHeaderPriorityBtn"
            className={getClassNamesForActivities('priority')}>{translation.equipmentPageActivitiesPriorityHeader}</th>
        {/* <th onClick={() => changeSortField('tasks')}
            id="assetsTblActivityTblHeaderTasksBtn"
            className={getClassNamesForActivities('tasks')}>{translation.equipmentPageActivitiesTasksHeader}</th>
        <th onClick={() => changeSortField('date')}
            id="assetsTblActivityTblHeaderDateBtn"
            className={getClassNamesForActivities('date')}>{translation.equipmentPageActivitiesDateHeader}</th>
        <th onClick={() => changeSortField('workhours')}
            id="assetsTblActivityTblHeaderWorkhoursBtn"
            className={getClassNamesForActivities('workhours')}>{translation.equipmentPageActivitiesWorkhoursHeader}</th> */}
        <th style={{display: 'flex', justifyContent: 'left', paddingLeft: '45px'}}>
          <div className="addActivityBtn"
              id="assetsTblActivityAddActivityBtn"
               onClick={onAddActivity}>
            <div className="amPlIcon" />
            {translation.equipmentPageActivitiesAddActivityButton}
          </div>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {outputActivities.map(activity =>
        <tr key={activity.id}>
          <td style={{background: 'none'}}></td>
        <td style={{paddingLeft: '10px'}}>{activity.name}</td>
          <td>{typeOptions.find(x => x.activityType === activity.type) ? typeOptions.find(x => x.activityType === activity.type).name : activity.type}</td>
          <td>{priorityOptions.find(x => x.priority === activity.priority) ? priorityOptions.find(x => x.priority === activity.priority).name : activity.priority}</td>
          {/* <td>{activity.tasks.length}</td>
          <td>{getDate(activity)}</td>
          <td>{activity.expectedWorkhours}</td> */}
          <td></td>
          <td className="btnCell">
            <IconButton aria-label="delete" size="small"
                        className="dropDownBtn mobile"
                        disabled={disableAdd}
                        id="assetsTblActivitySideMenuBtn"
                        onClick={(e, ) => {
                          e.preventDefault();
                          onEditActivity(e, activity)
                        }}>
              <MoreVertIcon />
            </IconButton>
          </td>
        </tr>)}
      </tbody>
    </table>
  )
}

export default ActivitiesTable;
