import React, { Fragment } from "react";

import { Button, Dialog, MenuItem, TextField } from '@material-ui/core'
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import { useState, useEffect } from 'react';
import AlertToastr from 'utils/alert'
import DmsService from 'Services/DMS/dms-api.service'
import useTranslation from 'Hooks/useTranslation'
import moment from 'moment'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import DatePicker from "react-datepicker";
import { useBoxes } from "Contexts/BoxContext";

const RadioLabel = ({ label }) => {
    return (
        <div className="jobLabel">
            <div className="labelName">{label}</div>
        </div>
    )
}

const ExportToCsvModal = ({
  isOpen,
  setOpen,
  jobName,
  onExportData,
  onCancel,
  currentJob,
  onBack,
  endChartDate,
  startChartDate,
  modalStep
}) => {
  const translation = useTranslation();
  const [filename, setFilename] = useState("");
  const [delimiterOption, setDelimiterOption] = useState("semicolon");
  const [decimalSeparator, setDecimalSeparator] = useState("dot");
  const [dateTimeFormat, setDateTimeFormat] = useState("");
  const [dateTimeTypes, setDateTimeTypes] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateChanged, setDateChanged] = useState(false);



  const getLocalTimeFromUtcForJobs  = (utcDate, timezone) => {
    const localMoment = moment.tz(utcDate, timezone);
    const utcMoment = localMoment.utc();
    return utcMoment.tz(timezone).toDate();
  };

  const getLocalTimeFromUtc = (utcDate, timezone) => {
    const utcMoment = moment.utc(utcDate);
    return utcMoment.tz(timezone).toDate();
  };

  const { startEventLoggerDate, endEventLoggerDate, boxType } = useBoxes();


  useEffect(() => {
    const timezone = currentJob?.timeZone || moment.tz.guess();

    if (!dateChanged && modalStep === 'jobOptionsCsv') {
        setDateFrom(getLocalTimeFromUtcForJobs(currentJob?.startTime, timezone));
        setDateTo(getLocalTimeFromUtcForJobs(currentJob?.stopTime, timezone));
    } else if (!dateChanged && boxType !== 'eventLogger') {
        setDateFrom(getLocalTimeFromUtc(startChartDate, timezone));
        setDateTo(getLocalTimeFromUtc(endChartDate, timezone));
    } 
    else if (!dateChanged && modalStep !== 'jobOptionsCsv' && boxType === 'eventLogger') {
      setDateFrom(getLocalTimeFromUtc(startEventLoggerDate, timezone));
      setDateTo(getLocalTimeFromUtc(endEventLoggerDate, timezone));
  }
}, [currentJob, endChartDate, modalStep, startChartDate, dateChanged, startEventLoggerDate, endEventLoggerDate, boxType]);


  const onClose = () => {
    onCancel();
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) fetchExportDateTypes().then();
  }, [isOpen]);

  useEffect(() => {
    setFilename(jobName);
  }, [jobName, setFilename]);

  const fetchExportDateTypes = async () => {
    try {
      let response = await DmsService.getExportDateTypes();
      setDateTimeTypes(response.data);
      if (response.data[0]) setDateTimeFormat(response.data[0].typeValue);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.Message
      )
        AlertToastr.showErrorAlert(error.response.data.Message);
    }
  };

  const handleDelimiterChange = (event) => {
    setDelimiterOption(event.target.value);
  };

  const handleDecimalSeparatorChange = (event) => {
    setDecimalSeparator(event.target.value);
  };

  // const getFormattedUtcOffset = () => {
  //   let offset = moment().format("Z");
  //   if (offset.charAt(0) === "+") {
  //     offset = offset.substring(1);
  //   }
  //   return `${offset}:00`;
  // };

  const getFormattedUtcOffset = () => {
    const currentTime = moment();
    const utcTime = moment().utc();
    const hoursDifference = currentTime.hours() - utcTime.hours();
    return `${hoursDifference}:00:00`;
  };

  const handleBack = () => {
    onBack();
  };

  const exportData = () => {
    if (!filename) {
      AlertToastr.showErrorAlert(translation.notificationsFileNameRequired);
      return;
    }
    let delimiter;
    switch (delimiterOption) {
      case "tabulator":
        delimiter = "\t";
        break;
      case "space":
        delimiter = " ";
        break;
      case "semicolon":
        delimiter = ";";
        break;
      case "comma":
        delimiter = ",";
        break;
      default:
        break;
    }
    let decimalDelimiter;
    switch (decimalSeparator) {
      case "dot":
        decimalDelimiter = ".";
        break;
      case "comma":
        decimalDelimiter = ",";
        break;
      default:
        break;
    }
    let locale;
    if (navigator.languages !== undefined) locale = navigator.languages[0];
    else locale = navigator.language;
    onExportData({
      name: filename,
      delimiter: delimiter,
      decimalDelimiter: decimalDelimiter,
      dateTimeFormat: dateTimeFormat,
      cultureInfoName: locale,
      timeShift: getFormattedUtcOffset(),
      // dateFrom: dateFrom.utc().format("YYYY-MM-DDTHH:mm:ss"),
      // dateTo: dateTo.utc().format("YYYY-MM-DDTHH:mm:ss")
      dateFrom: dateFrom.valueOf(),
      dateTo: dateTo.valueOf(),
    });
  };

  const dialogContent = (
    <Fragment>
      <div className="formContentHolder">
        <div className="modalTitleHolder hasBackLink">
          <div className="backLinkHolder" style={{ left: "20px" }}>
            {
              <IconButton aria-label="back" size="small" onClick={handleBack}>
                <ArrowBackIcon fontSize="inherit" />
              </IconButton>
            }
          </div>
          <h1 className="modalTitle text-center">
            {translation.exportToCsvModalExportToCsvLabel}
          </h1>
        </div>
        <div className="formContent">
          <div className="formContentBlock">
            <TextField
              value={filename}
              variant="outlined"
              margin="none"
              fullWidth
              id="filename"
              label={translation.exportToCsvModalFileNameLabel}
              name="filename"
              onChange={(e) => setFilename(e.target.value)}
              inputProps={{
                autoComplete: "off",
              }}
            />
          </div>
          <div className="formContentBlock">
            <FormControl component="fieldset">
              <FormLabel component="label" className={"radioLabel text-bold"}>
                {translation.exportToCsvModalDelimiterSymbolLabel}
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={delimiterOption}
                onChange={handleDelimiterChange}
              >
                <FormControlLabel
                  checked={delimiterOption === "semicolon"}
                  value="semicolon"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModaDelimiterSymbolSemicolonLabel
                      }
                    />
                  }
                />
                <FormControlLabel
                  disabled={decimalSeparator === "comma"}
                  checked={delimiterOption === "comma"}
                  value="comma"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModalDelimiterSymbolCommaLabel
                      }
                    />
                  }
                />
                <FormControlLabel
                  checked={delimiterOption === "tabulator"}
                  value="tabulator"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModalDelimiterSymbolTabulatorLabel
                      }
                    />
                  }
                />
                <FormControlLabel
                  checked={delimiterOption === "space"}
                  value="space"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={translation.exportToCsvModalSpaceLabel}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="formContentBlock">
            <FormControl component="fieldset">
              <FormLabel component="label" className={"radioLabel text-bold"}>
                {translation.exportToCsvModalDecimalPointSeparatorLabel}
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                value={decimalSeparator}
                onChange={handleDecimalSeparatorChange}
              >
                <FormControlLabel
                  checked={decimalSeparator === "dot"}
                  value="dot"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={translation.exportToCsvModalPointSeparatorDotLabel}
                    />
                  }
                />
                <FormControlLabel
                  disabled={delimiterOption === "comma"}
                  checked={decimalSeparator === "comma"}
                  value="comma"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModalPointSeparatorCommaLabel
                      }
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
   
          <div className="formContentBlock" style={{ display: "flex" }}>
            <div>
              <FormLabel component="label" className={"radioLabel text-bold"}>
                {translation.fromLabel}
              </FormLabel>
              <div
                className="DateTimePickerFrom"
                style={{ paddingBottom: "0" }}
              >
                <DatePicker
                  selected={dateFrom}
                  onChange={(date) => {
                    setDateFrom(date);
                    setDateChanged(true);
                  }}
                  showTimeSelect
                  dateFormat="Pp"
                  className="formControlDatePicker"
                  timeFormat="hh:mm aa"
                />
              </div>
              </div>
              <div>
                <FormLabel component="label" className={"radioLabel text-bold"}>
                  {translation.toLabel}
                </FormLabel>
                <div
                  className="DateTimePickerTo"
                  style={{ paddingBottom: "0" }}
                >
                  <DatePicker
                    selected={dateTo}
                    onChange={(date) => {
                      setDateTo(date);
                      setDateChanged(true);
                    }}
                    showTimeSelect
                    dateFormat="Pp"
                    className="formControlDatePicker"
                    timeFormat="hh:mm aa"
                  />
                </div>
              </div>
          </div>

          <div className="formContentBlock">
            <FormLabel component="label" className={"radioLabel text-bold"}>
              {translation.exportToCsvModalDateAndTimeExportFormatLabel}
            </FormLabel>
            <TextField
              variant="outlined"
              margin="normal"
              select
              fullWidth
              label={translation.manageAxesModalFormatLabel}
              value={dateTimeFormat}
              onChange={(e) => setDateTimeFormat(e.target.value)}
            >
              {dateTimeTypes.map((type) => (
                <MenuItem
                  key={type.typeValue}
                  value={type.typeValue}
                  className="filterMenuItem"
                >
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className="btnHolder end fixedWidthBtn">
          <Button variant="outlined" onClick={() => onClose()}>
            {translation.cancelButtonLabel}
          </Button>
          <Button onClick={exportData} variant="contained" color="primary">
            {translation.exportButtonLabel}
          </Button>
        </div>
      </div>
    </Fragment>
  );

  return (
    <Dialog open={isOpen} className="respModal scrollContentModal autoHeight">
      {dialogContent}
    </Dialog>
  );
};

export default ExportToCsvModal;
