import { useEffect } from 'react'
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@aspnet/signalr'
import { apiBaseUrl } from 'config'
import { useAuth } from 'Contexts/AuthProvider'

const buildHubUrl = (company) => company ? `${apiBaseUrl}/ws/data?companyid=${company.id}` : `${apiBaseUrl}/ws/data`

const JobDataUpdatesSignalr = ({ jobId, onUpdated }) => {

    const { token } = useAuth()
    const {currentCompany} = useAuth()

    useEffect(() => {

        const dmsHubUrl = buildHubUrl(currentCompany)
        const protocol = new JsonHubProtocol()
        const transport = HttpTransportType.WebSockets
        const options = {
            skipNegotiation: true,
            transport,
            accessTokenFactory: () => {
                return token
            },
            headers: {
                authorization: token ? `Token ${token}` : ``
            }
        }

        const connection = new HubConnectionBuilder()
            .withUrl(dmsHubUrl, options)
            .withHubProtocol(protocol)
            .configureLogging(LogLevel.Information)
            .build()


        connection.on('RefreshItems', onUpdated)

        connection.start().then(() => {
            connection.invoke('SubscribeToJobDataUpdates', jobId).then()
        })

        return () => {
            if (connection.state === 'Connected')
                connection.stop()
        }

    }, [jobId, onUpdated, token, currentCompany])

    return null
}

export default JobDataUpdatesSignalr
