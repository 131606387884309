import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

const getUnitLastChannelsData = async ({ channelsData, companyId }) => {
    const response = await axios.post(`${apiBaseUrl}/data/unit/by-custom-channels/last`, channelsData, {
        headers: { 'CompanyId': companyId },
    });
    if (response.status === 204) return {}
    return response.data;
};

export default function useUnitLastChannelsData({ unitUid, channels, filters, section = 'unit', startDate, endDate }) {

    const { userLoaded, currentCompany } = useAuth();

    const channelsData = {
        uId: unitUid,
        channels:  channels.map(ch => ({ code: ch.code, unit: ch.unit })),
        jobType: section,
        ...filters,
        dateFrom: startDate,
        dateTo: endDate
    }

    return useQuery(
        [section, 'unit-item-last', channelsData],
        () => getUnitLastChannelsData({
            channelsData,
            companyId: currentCompany?.id
        }),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: userLoaded,
        }
    );
}
