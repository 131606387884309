import React, { Fragment, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { blue, orange } from '@material-ui/core/colors'
import { Link as RouterLink } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { clearServerErrors, getServerError } from 'utils/form'
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        backgroundColor: orange['A200'],
        marginBottom: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(3, 0, 2),
        position: 'relative',
    },
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}))

const SignInLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} to="/signin" {...props} />)

const PasswordResetForm = ({ handleSubmit, register, watch, onSubmit, request, isRegistration }) => {
    const translation = useTranslation();
    const classes = useStyles()

    const [errors, setErrors] = useState(null)

    useEffect(() => {
        if (request.error) {
            if (request.error.errors) {
                setErrors(request.error.errors)
            } else {
                AlertToastr.showErrorAlert(request.error.message)
            }
        }
    }, [request.error, setErrors])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
            <Fragment>
                <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={translation.forgotPasswordPagePasswordLabel}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onFocus={() => setErrors(errors => clearServerErrors(errors, 'password'))}
                    error={!!getServerError(errors, 'password')}
                    helperText={getServerError(errors, 'password')}
                />
                <TextField
                    inputRef={register}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={translation.forgotPasswordPageConfirmPasswordLabel}
                    type="password"
                    id="confirmPassword"
                    onFocus={() => setErrors(errors => clearServerErrors(errors, 'confirmPassword'))}
                    error={!!getServerError(errors, 'confirmPassword')}
                    helperText={getServerError(errors, 'confirmPassword')}
                />
                <div className='btnHolder fullWidthMobile mt22'>
                    <div className="part">
                        {
                            !isRegistration && (
                                <Link component={SignInLink} className='linkPrimary'>
                                    {translation.forgotPasswordPageSignInLabel}
                                </Link>
                            )
                        }
                    </div>
                    <div className="part">
                        <Button
                            disabled={request.loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {translation.createButtonLabel}
                        </Button>
                        {request.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>
            </Fragment>
        </form>
    )
}

PasswordResetForm.defaultProps = {
    values: {},
    errors: {},
}

export default PasswordResetForm
