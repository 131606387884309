import React, { useEffect } from 'react';
// import DepartmentsComponent from './DepartmentsComponent';
// import EmployeesComponent from './EmployeesComponent';
import { useAuth } from 'Contexts/AuthProvider';
// import IconButton from '@material-ui/core/IconButton';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
// import { Tab, Tabs } from '@material-ui/core';
import { connect } from 'react-redux';
import DmsService from 'Services/DMS/dms-api.service';
import { setEditingCompany, setPageTitle } from 'Store/modules/mms/actions';
// import AlertToastr from 'utils/alert';
import DefaultImage from '../../../assets/img/pred-main/defaultimg.svg';
import useTranslation from 'Hooks/useTranslation';
// import { useHistory, useLocation } from 'react-router';
// import { useRouteMatch } from 'react-router-dom';


const CompaniesPage = ({ currentCompanyPage, currentCompany, editingCompany, setEditingCompany, setPageTitle }) => {
    // const inputFile = useRef(null);
    const { currentUser } = useAuth();
    // const { path, url } = useRouteMatch();
    // const history = useHistory();
    // const { pathname } = useLocation();
    const translation = useTranslation();



    // const tabNameToIndex = useMemo(() => {
    //     return {
    //         0: `${url}`,
    //         1: `${url}/employees`,
    //     };
    // }, [url]);

    // const indexToTabName = useMemo(() => {
    //     return {
    //         [`${path}`]: 0,
    //         [`${path}/employees`]: 1,
    //     };
    // }, [path]);

    // const [value, setValue] = useState(() => {
    //     return indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0;
    // });

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     history.push(tabNameToIndex[newValue]);
    // };

    // useEffect(() => {
    //     const index = indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0;
    //     setValue(index);
    // }, [pathname, indexToTabName]);

    useEffect(() => {
        const fetchCompanyImage = async (id) => {
            let response = await DmsService.getCompanyImage(id);
            setEditingCompany(response.data);
        };
        if (currentCompany)
            fetchCompanyImage(currentCompany.id).then();
        if (currentUser && currentUser.companyId)
            fetchCompanyImage(currentUser.companyId).then();
    }, [currentCompany, currentUser, setEditingCompany]);

    // useEffect(() => {
    //     setActiveTab(currentCompanyPage);
    //     setValue(currentCompanyPage === 'departments' ? 0 : 1);
    // }, [currentCompanyPage]);

    // const onFileSelected = (file) => {
    //     DmsService.uploadCompanyImage(editingCompany.id, file).then(async (response) => {
    //         if (response.data.isSuccessful) {
    //             let response = await DmsService.getCompanyImage(editingCompany.id);
    //             setEditingCompany(response.data);
    //         }
    //     }).catch(error => {
    //         if (error && error.response && error.response.data && error.response.data.message)
    //             AlertToastr.showErrorAlert(error.response.data.message);
    //     });
    // };

    useEffect(() => {
        setPageTitle('');
    }, [setPageTitle, translation]);

    return (
        <div className="pmPageHolder">
            <div className="pmPageBlock">
                <div className="companyInfoItemHolder">
                    <div className="imgHolder">
                        <div className="img">
                            {editingCompany && editingCompany.image &&
                                <img src={`data:image/jpeg;base64,${editingCompany.image}`} alt=""
                                     className="companyMainImg" />}
                            {editingCompany && !editingCompany.image &&
                                <img src={DefaultImage} alt="" className="companyMainImg" />
                            }
                        </div>
                        {/* <div className="companyBtnHolder">
                            <IconButton variant="contained"
                                        onClick={e => inputFile.current.click()}>
                                <PhotoCamera />
                            </IconButton>
                            <input type="file" id="file"
                                   ref={inputFile} style={{ display: 'none' }}
                                   onChange={(e) => {
                                       if (!e.target.files[0])
                                           return;
                                       onFileSelected(e.target.files[0]);
                                   }} />
                        </div> */}
                    </div>
                    {editingCompany && <div className="captionHolder">
                        <div className="title">{editingCompany.name}</div>
                        {editingCompany.description &&
                            <div className="subTitle">{editingCompany.description.length > 200 ?
                                `${editingCompany.description.substring(0, 200)}...` :
                                editingCompany.description} </div>}
                        {editingCompany.timezone &&
                            <div className="subTitle">{translation.timezoneLabel}: {editingCompany.timezone}</div>}
                    </div>}
                </div>
            </div>
            {/* <div className="pmPageBlock" style={{ paddingTop: '0' }}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    className="pmTabs mb15"
                    onChange={handleChange}
                >
                    <Tab
                        disableRipple
                        label={translation.myCompanyDepartmentsLabel}
                    />
                    <Tab
                        disableRipple
                        label={translation.myCompanyEmployeesLabel}
                    />
                </Tabs>
                <Switch>
                    <PrivateRoute path={`${path}/departments`} component={DepartmentsComponent} exact/>
                    <PrivateRoute path={`${path}/employees`}  component={EmployeesComponent}/>
                </Switch>
            </div> */}
        </div>
    );
};

const mapDispatchToProps = {
    setEditingCompany,
    setPageTitle,
};

const mapStateToProps = ({ mms, dashboard }) => ({
    currentCompanyPage: mms.currentCompanyPage,
    currentCompany: dashboard.currentCompany,
    editingCompany: mms.editingCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPage);
