import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { clearDashboard, fetchJobsSuccess, setCompanies, setCurrentCompany } from 'Store/modules/dashboard/actions';
import { connect } from 'react-redux';
import { setDepartments, setEmployees } from 'Store/modules/mms/actions';
import useTranslation from 'Hooks/useTranslation';
import 'moment/min/locales';
import 'react-circular-progressbar/dist/styles.css';
import { setStoredCompany } from '../storage/company-storage';

const Header = ({
                    clearDashboard,
                    companies,
                    currentCompany,
                    setCurrentCompany,
                    setDepartments,
                    setEmployees,
                }) => {

    const translation = useTranslation();

    return (
        <div style={{ width: '240px' }}>
            {currentCompany && <TextField
                variant="outlined"
                margin="none"
                select
                fullWidth
                label={translation.headerCompanyLabel}
                name="company"
                onChange={e => {
                    const company = e.target.value;
                    setStoredCompany(company.id);
                    setCurrentCompany(company);
                    clearDashboard();
                    setDepartments(null);
                    setEmployees(null);
                    window.location.reload()
                }}
                value={currentCompany}
            >
                {companies && companies.map((company) => <MenuItem key={company.id}
                                                                   value={company}
                                                                   className="filterMenuItem">{company.name}</MenuItem>)}
            </TextField>
            }
        </div>
    );
};

const mapDispatchToProps = {
    clearDashboard,
    setCurrentCompany,
    fetchJobsSuccess,
    setCompanies,
    setDepartments,
    setEmployees,
};

const mapStateToProps = ({ dashboard }) => ({
    companies: dashboard.companies,
    currentCompany: dashboard.currentCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
