import React, { useEffect, useState } from 'react'
import ChangePasswordForm from './ChangePassword.form'
import useForm from 'react-hook-form'
import AlertToastr from 'utils/alert'
import { Redirect } from 'react-router'
import DmsService from 'Services/DMS/dms-api.service'
import useApiCall from 'Hooks/useApiCall'
import authEndpoints from 'Services/endpoints/auth.endpoints'

const ChangePasswordPage = () => {

    const [request, changePassword] = useApiCall()
    const [isSuccessfullSubmit, setIsSuccessfulSubmit] = useState(false)

    const { ...formProps } = useForm({
        defaultValues: {
            oldPassword: '',
            password: '',
            confirmPassword: '',
        }
    })

    useEffect(() => {
        if (request.response)
            setIsSuccessfulSubmit(true)
    }, [
        request.response,
        setIsSuccessfulSubmit
    ])

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message)
        }
    }, [request.error])

    const handleSubmit = data => {
        if (data) {
            DmsService.getPasswordChangeToken().then(res => {
                data.passwordResetToken = res.data.item
                changePassword({
                    ...authEndpoints.changePassword(),
                    data
                })
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
        }
    }

    if (isSuccessfullSubmit)
        return <Redirect to='/' />

    return (
        <ChangePasswordForm {...formProps}
            onSubmit={handleSubmit}
            request={request} />
    )
}

export default ChangePasswordPage
