import { useMutation } from 'react-query';
import axios from 'axios';
import { apiAuthBaseUrl } from 'config';

export default function useSyncTabs(type = 'jobs') {
    return useMutation(
        ({ tabId, items, colors, companyId, dateFrom, dateTo }) => axios.post(`${apiAuthBaseUrl}/tabs/items`, {
            type,
            tabId,
            items,
            time: new Date(),
            dateFrom,
            dateTo,
            colors,
            companyId,
        }, {
            headers: { 'CompanyId': companyId },
        }),
        {
            onSuccess: () => {
                console.log('tabs sync...');
            },
        },
    );
}
