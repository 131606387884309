import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

const getUniqueUnits = async ({ companyId, section = 'units' }) => {

    const urlMap = {
        'units': `${apiBaseUrl}/units/unique`,
        'locations': `${apiBaseUrl}/locations/unique`,
    }

    const url = urlMap[section] || urlMap['units'];

    const response = await axios.get(url, {
        headers: { 'CompanyId': companyId },
    });
    if (response.status === 204) return []
    return response.data;
};

export default function useUniqueUnits(section = 'units') {
    const { userLoaded, currentCompany } = useAuth();

    return useQuery(
        ['unique-units', section, currentCompany],
        () => getUniqueUnits({ companyId: currentCompany?.id, section }),
        {
            initialData: [],
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: userLoaded,
        });
}
