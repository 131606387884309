import Types from './types'

import {all, call, put, takeLatest} from 'redux-saga/effects'

import {setAuthUser, signinFailure, signinSuccess, signoutFailure, signoutSuccess,} from './actions'

import AuthService from '../../../Services/Auth/Auth.service'

function* signin(action) {
    try {
        const tokenData = yield call(AuthService.login, action.payload)
        const user = AuthService.getTokenUser(tokenData.access_token)
        yield put(signinSuccess(tokenData))
        yield put(setAuthUser(user))
    } catch (error) {
        yield put(signinFailure(error))
    }
}

function* watchSigninRequest() {
    yield takeLatest(Types.SIGNIN_REQUEST, signin)
}

function* signout(action) {
    try {
        yield call(AuthService.logout, action.payload)
        yield put(signoutSuccess())
    } catch (error) {
        yield put(signoutFailure(error.response.data))
    }
}

function* watchSignoutRequest() {
    yield takeLatest(Types.SIGNOUT_REQUEST, signout)
}

export default function* () {
    yield all([
        call(watchSigninRequest),
        call(watchSignoutRequest)
    ])
}
