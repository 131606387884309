import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button, Checkbox, FormControlLabel, TextField, Tooltip } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import useTranslation from 'Hooks/useTranslation';
import { useNotificationsCUD } from '../../../../Hooks/useNotificationReportsHooks';
import AlertToastr from '../../../../utils/alert';
import { normalizeErrorResponse } from 'utils/form';

const BlankReportData = {
    name: '',
    departmentId: '',
    recipients: '',
    componentIds: [],
};

const SaveNotificationModal = ({
                                   isOpen,
                                   onClose,
                                   components,
                                   departmentId,
                                   model,
                               }) => {


    const [data, setData] = useState(BlankReportData);
    const [errors, setErrors] = useState({});
    const translation = useTranslation();
    const { createMutation, updateMutation, invalidateList } = useNotificationsCUD();


    useEffect(() => {
        if (!model && components) {
            setData(prev => ({
                ...prev,
                componentIds: components.map(c => c.id)
            }))
        }
    }, [components, model]);


    useEffect(() => {
        if (model) {
            setData({
                ...model,
                recipients: model.recipients.join('\n'),
            });
        }
    }, [model]);

    useEffect(() => {
        setData(prev => ({ ...prev, departmentId: departmentId }));
    }, [
        departmentId,
    ]);

    const handleClose = () => {
        // setRecipientList([])
        onClose();
        setData({
            ...BlankReportData,
            departmentId,
            componentIds: components.map(c => c.id)
        });
        setErrors({});
    };

    const onSave = async () => {
        const formattedData = {
            ...data,
            recipients: data.recipients.split('\n').filter(str => !!str),
        };
        let errors = {};
        if (formattedData.componentIds.length === 0) {
          errors = {
            ...errors,
            componentIds: "Необхідно вибрати хоча б один елемент"
          };
          const container = document.querySelector('.checkboxListContainer');
          container.style.borderColor = 'red';
        }
        try {
          if (model) {
            await updateMutation.mutateAsync(formattedData);
          } else {
            await createMutation.mutateAsync(formattedData);
          }
          invalidateList().then();
          handleClose();
        } catch (error) {
          if (!error || !error.response || !error.response.data) return;
          const resErrors = normalizeErrorResponse(error.response.data);
          if (resErrors.errors) errors = {...errors, ...resErrors.errors};
          if (resErrors && resErrors.message)
            AlertToastr.showErrorAlert(`${resErrors.message}`);
          if (error.response.data.errors)
            error.response.data.errors
              .filter((x) => x.PropertyName === "")
              .map((x) => {
                AlertToastr.showErrorAlert(x.ErrorMessage);
                return x;
              });
        }
        setErrors(errors);
      };      

    const checkboxClicked = (e) => {
        e.persist();
        setData(prev => {
            let ids = [...prev.componentIds];
            const { checked, value } = e.target;

            if (checked) {
                if (ids.findIndex(v => v === value) === -1) {
                    ids.push(value);
                }
            } else {
                ids = ids.filter(v => v !== value);
            }

            return {
                ...prev,
                componentIds: ids,
            };
        });
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                    onSave();
                }
            }}
            className="respModal scrollContentModal autoHeight"
        >
            <div className="formContentHolder">
                <div className="modalTitleHolder">
                    <h1 className="modalTitle text-center">{!model ? translation.saveNotificationAddLabel : translation.saveNotificationEditLabel}</h1>
                    {/*<h1 className="modalTitle text-center">{'Save'}</h1>*/}
                </div>
                <div className="formContent">
                    <div className="formContentBlock modalFieldLabelSettings">
                        <Tooltip disableFocusListener disableTouchListener
                                 title={errors && errors.name ? errors.name.join('\n') : ''}>
                            <div>
                                <TextField
                                    value={data.name}
                                    onChange={e => {
                                        if (errors.name)
                                            errors.name = null;
                                        setData({ ...data, name: e.target.value });
                                    }}
                                    error={errors && errors.name && errors.name.length > 0}
                                    variant="outlined"
                                    margin="normal"
                                    id="notificationsTblMenuModalName"
                                    label={translation.saveReportModalNameLabel}
                                    fullWidth
                                />
                            </div>
                        </Tooltip>
                        <TextField
                            value={data.recipients}
                            onChange={e => {
                                if (errors.name)
                                    errors.name = null;
                                setData({ ...data, recipients: e.target.value });
                            }}
                            error={errors && errors.recipients && errors.recipients.length > 0}
                            variant="outlined"
                            margin="normal"
                            id="notificationsTblMenuModalRecipients"
                            label={translation.saveReportModalRecipientsLabel}
                            minRows={4}
                            multiline
                            fullWidth
                        />
                        <div className="checkboxListHolder mt8">
                            <div className="labelLike">{translation.notificationsAssetsHeader}</div>
                            <div className="checkboxListContainer">
                                {
                                    components.map(item => {
                                        return (
                                            <FormControlLabel key={item.id} control={
                                                <Checkbox
                                                    value={item.id}
                                                    id="notificationsTblMenuModalAssetsCheckbox"
                                                    checked={data.componentIds.findIndex(i => i === item.id) !== -1}
                                                    onChange={checkboxClicked}
                                                    color="default"
                                                />} label={item.name}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btnHolder end fixedWidthBtn">
                <Button
                    onClick={handleClose}
                    id="notificationsTblMenuModalCancelBtn"
                    variant="outlined"
                >{translation.cancelButtonLabel}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    id="notificationsTblMenuModalSaveBtn"
                    onClick={onSave}
                >{translation.saveButtonLabel}</Button>
            </div>
        </Dialog>
    );
};

export default SaveNotificationModal;
