import React, { useState, useEffect, useMemo, useCallback } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from "@material-ui/core/IconButton";
import MmsService from 'Services/MMS/mms-api.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Popover from "@material-ui/core/Popover";
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import SaveEmployeeModal from './Modals/SaveEmployeeModal'
import { useAuth } from 'Contexts/AuthProvider'
import { setEmployees } from '../../../Store/modules/mms/actions'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { setPageTitle } from '../../../Store/modules/mms/actions';

const ArrowLeft = () => <div className='arrowLeftIcon' />
const ArrowRight = () => <div className='arrowRightIcon' />

const descConst = 'descending'
const ascConst = 'ascending'
const arrowDownClassName = 'hasArrow'
const arrowUpClassName = 'hasArrow up'

const EmployeesComponent = ({
  departments,
  employees,
  setEmployees,
  currentCompany,
  setPageTitle,
}) => {
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenSaveEmployeeModal, setIsOpenSaveEmployeeModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [menuAchorEl, setMenuAchorEl] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { currentUser } = useAuth();
  const [sortConfig, setSortConfig] = useState({ key: "name", direction: ascConst });
  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsOnPage, setRowsOnPage] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, onAnchorElChange] = useState(null);
  const closeMenu = () => {
    setMenuAchorEl(null);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let response;
        if (isSuperUser) {
          if (!currentCompany) return;
          response = await MmsService.getEmployeesByCompanyId(
            currentCompany.id
          );
        } else response = await MmsService.getEmployees();
        setIsLoading(false);
        setEmployees(response.data);
      } catch (error) {
        setIsLoading(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      }
    };
    fetchEmployees();
  }, [setEmployees, currentCompany, isSuperUser]);

  useEffect(() => {
    setPageTitle(translation.myCompanyEmployeesLabel);
  }, [setPageTitle, translation]);

  const getDepartmentNameById = useCallback(
    (departmentId) =>
      departments &&
      departmentId &&
      departments.find((x) => x.id === departmentId)
        ? departments.find((x) => x.id === departmentId).name
        : "-",
    [departments]
  );

  
  const sortByKey = useCallback(
    (key) => {
      return (a, b) => {
        if (a[key].toLowerCase() < b[key].toLowerCase()) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (a[key].toLowerCase() > b[key].toLowerCase()) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      };
    },
    [sortConfig]
  );

  const sortedEmployees = useMemo(() => {
    if (!employees) return [];
    let sortedEmployees = [...employees];
    if (!sortConfig || !sortConfig.key) return sortedEmployees;

    if (sortConfig.key === "department") {
      sortedEmployees.sort((a, b) => {
        if (
          getDepartmentNameById(a.departmentId) <
          getDepartmentNameById(b.departmentId)
        ) {
          return sortConfig.direction === ascConst ? -1 : 1;
        }
        if (
          getDepartmentNameById(a.departmentId) >
          getDepartmentNameById(b.departmentId)
        ) {
          return sortConfig.direction === ascConst ? 1 : -1;
        }
        return 0;
      });
    } else {
      sortedEmployees.sort(sortByKey(sortConfig.key));
    }
    return sortedEmployees;
  }, [employees, sortConfig, getDepartmentNameById, sortByKey]);

  const pageData = useMemo(() => {
    let pageData = [...sortedEmployees];
    const totalLength = pageData.length;

    if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
      setPageNumber(1);
    }
    setTotalRows(totalLength);
    setFirstIndex(Math.min((pageNumber - 1) * rowsOnPage + 1, totalLength));
    setLastIndex(Math.min(pageNumber * rowsOnPage, totalLength));
    let tableData = pageData.splice(
      (pageNumber - 1) * rowsOnPage,
      Math.min(pageNumber * rowsOnPage, totalLength)
    );
    return [...tableData];
  }, [sortedEmployees, rowsOnPage, pageNumber]);

  const requestSort = (key) => {
    let direction = ascConst;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === ascConst
    ) {
      direction = descConst;
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return;
    }
    if (sortConfig.direction)
      return sortConfig.direction === ascConst
        ? arrowDownClassName
        : arrowUpClassName;
  };

  const onDeleteEmployee = () => {
    MmsService.deleteEmployee(selectedEmployee.id, currentCompany?.id)
      .then((res) => {
        AlertToastr.showAlert(translation.notificationsEmployeeDeleted);
        const newEmployees = employees.filter(
          (d) => d.id !== selectedEmployee.id
        );
        setEmployees(newEmployees);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      });
  };

  const closePaginationMenu = () => {
    onAnchorElChange(null);
  };

  const handleRowCount = (count) => {
    setRowsOnPage(count);
    setPageNumber(1);
    closePaginationMenu();
  };

  const onNextPage = () => {
    setPageNumber(pageNumber + 1);
    window.scrollTo(0, 0);
  };

  const onPrevPage = () => {
    setPageNumber(pageNumber - 1);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="pmPageHolder">
        <div className="pmPageBlock">
          <div className="pmPageBlockTitle">
            {translation.myCompanyEmployeesLabel}
          </div>
          <div className="pmTableHolder" style={{ marginTop: "25px" }}>
            <table className="pmTable">
              <thead>
                <tr>
                  <th
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                    id='employeesTblHeaderName'
                  >
                    {translation.myCompanyEmployeeNameHeader}
                  </th>
                  <th
                    onClick={() => requestSort("department")}
                    className={getClassNamesFor("department")}
                    id="employeesTblHeaderDepartment"
                  >
                    {translation.myCompanyEmployeeDepartmentHeader}
                  </th>
                  <th
                    onClick={() => requestSort("role")}
                    className={getClassNamesFor("role")}
                    id="employeesTblHeaderRole"
                  >
                    {translation.myCompanyEmployeeRoleHeader}
                  </th>
                  <th
                    onClick={() => requestSort("phoneNumber")}
                    className={getClassNamesFor("phoneNumber")}
                    id="employeesTblHeaderPhone"
                  >
                    {translation.myCompanyEmployeePhoneNumberHeader}
                  </th>
                  <th
                    onClick={() => requestSort("email")}
                    className={getClassNamesFor("email")}
                    id="employeesTblHeaderEmail"
                    colSpan="2"
                  >
                    {translation.myCompanyEmployeeEmailHeader}
                  </th>
                </tr>
              </thead>
              <tbody>
                {pageData
                  ? pageData.map((employee) => (
                      <tr key={employee.id}>
                        <td className="text-semi-bold">{employee.name}</td>
                        <td>
                          {employee.departmentId
                            ? getDepartmentNameById(employee.departmentId)
                            : "-"}
                        </td>
                        {employee.role ? <td>{employee.role}</td> : <td>-</td>}
                        {employee.phoneNumber ? (
                          <td>{employee.phoneNumber}</td>
                        ) : (
                          <td>-</td>
                        )}
                        {employee.email ? (
                          <td>{employee.email}</td>
                        ) : (
                          <td>-</td>
                        )}
                        <td className="btnCell">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            className="dropDownBtn mobile"
                            onClick={(e) => {
                              setMenuAchorEl(e.target);
                              setSelectedEmployee(employee);
                            }}
                            id="employeesTblDropDownBtn"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </td>
                        <Popover
                          anchorEl={menuAchorEl}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          disableScrollLock={true}
                          open={Boolean(menuAchorEl)}
                          onClose={closeMenu}
                          elevation={1}
                          className="pmPopover equipmentSubMenu"
                        >
                          <MenuItem
                            key={"edit"}
                            onClick={() => {
                              setIsOpenSaveEmployeeModal(true);
                              setMenuAchorEl(null);
                            }}
                            className="menuItem edit"
                            id="employeesTblMenuEditBtn"
                          >
                            {translation.popoverEditLabel}
                          </MenuItem>
                          <MenuItem
                            key={"delete"}
                            onClick={() => {
                              setIsOpenDeleteModal(true);
                              setMenuAchorEl(null);
                            }}
                            className="menuItem delete"
                            id="employeesTblMenuDeleteBtn"
                          >
                            {translation.popoverRemoveLabel}
                          </MenuItem>
                        </Popover>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            {pageData && pageData.length > 0 && (
              <div className="tableControlsHolder">
                <div className="sortHolder">
                  <div className="caption">Rows per page:</div>
                  <Button
                    onClick={(e) => onAnchorElChange(e.target)}
                    endIcon={<ArrowDropDownIcon />}
                    className="dropDownBtn"
                    id="employeesTableRowsPerPageBtn"
                  >
                    {rowsOnPage}
                  </Button>

                  <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={closePaginationMenu}
                    disableScrollLock={true}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem id="employeesTblRowCount20Btn" onClick={() => handleRowCount(20)}>20</MenuItem>
                    <MenuItem id="employeesTblRowCount50Btn" onClick={() => handleRowCount(50)}>50</MenuItem>
                    <MenuItem id="employeesTblRowCount100Btn" onClick={() => handleRowCount(100)}>100</MenuItem>
                  </Popover>
                </div>
                <div className="paginationHolder mms">
                  <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                  <div className="paginationBtn">
                    <IconButton
                      disabled={firstIndex === 1 || firstIndex === 0}
                      onClick={() => onPrevPage()}
                      id="employeesTblPrevPageBtn"
                    >
                      <ArrowLeft />
                    </IconButton>
                    <IconButton
                      disabled={lastIndex === totalRows}
                      onClick={() => onNextPage()}
                      id="employeesTblNextPageBtn"
                    >
                      <ArrowRight />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
            {isLoading ? (
              <div className="loaderHolder">
                <Fade in={isLoading} unmountOnExit>
                  <CircularProgress />
                </Fade>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <SaveEmployeeModal
        open={isOpenSaveEmployeeModal}
        handleClose={(e) => setIsOpenSaveEmployeeModal(false)}
        employee={selectedEmployee}
      />
      <DeleteItemModal
        isOpen={isOpenDeleteModal}
        isOpenManager={setIsOpenDeleteModal}
        message={translation.removeEmployeeModalMessageLabel}
        onDeleteModalClose={(e) => {
          onDeleteEmployee();
        }}
      />
    </>
  );
};
const mapStateToProps = ({ mms, dashboard }) => ({
  departments: mms.departments,
  employees: mms.employees,
  currentCompany: dashboard.currentCompany,
});

const mapDispatchToProps = {
  setEmployees,
  setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesComponent);
