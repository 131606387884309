const ACTIVE_COMPANY_LOCALSTORAGE_KEY = process.env.REACT_APP_ACTIVE_COMPANY_LOCALSTORAGE_KEY || '_company_id_'

export const getStoredCompany = () => window.localStorage.getItem(ACTIVE_COMPANY_LOCALSTORAGE_KEY)

export const setStoredCompany = (id) => {
    window.localStorage.setItem(ACTIVE_COMPANY_LOCALSTORAGE_KEY, id)
}

export const clearStoredCompany = () => {
    window.localStorage.removeItem(ACTIVE_COMPANY_LOCALSTORAGE_KEY)
}
