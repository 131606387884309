import { useEffect, useMemo } from 'react'
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr'
import { apiBaseUrl } from 'config'
import { useAuth } from 'Contexts/AuthProvider'

const buildHubUrl = (company) => company ? `${apiBaseUrl}/ws/data?companyid=${company.id}` : `${apiBaseUrl}/ws/data`
const protocol = new JsonHubProtocol()
const transport = HttpTransportType.WebSockets

const LiveJobSignalrEventLogger = props => {

    const {
        jobId,
        lastLoadedDmsId,
        onIncomingData,
        onIncomingDeleteData,
    } = props

    const { token, currentCompany } = useAuth()


    const lastId = useMemo(() => {
        return lastLoadedDmsId ? lastLoadedDmsId : 0
    }, [lastLoadedDmsId])

    useEffect(() => {
        const options = {
            skipNegotiation: true,
            transport,
            accessTokenFactory: () => {
                return token
            },
            headers: {
                authorization: token ? `Token ${token}` : ``
            }
        }
        const connection = new HubConnectionBuilder()
            .withUrl(buildHubUrl(currentCompany), options)
            .withAutomaticReconnect()
            .withHubProtocol(protocol)
            .configureLogging(LogLevel.Information)
            .build()

        const dataHandler = data => {
            onIncomingData(data)
        }

        const deleteDataHandler = data => {
            onIncomingDeleteData(data)
        }

        connection.on('EventsAdded', dataHandler)
        connection.on('EventsUpdated', dataHandler)
        connection.on('EventsDeleted', deleteDataHandler)

        connection.start().then(() => {
            connection.invoke('GetEvents', jobId, lastId).then(() => {
            })
        })

        return () => {
            if (connection.state === 'Connected')
                connection.stop()
        }
    }, [jobId, onIncomingData, onIncomingDeleteData, lastId, token, currentCompany])

    return null
}

export default LiveJobSignalrEventLogger
