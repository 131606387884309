import React, { useCallback, useEffect } from 'react';
import UnitGroup from './UnitGroup';
import useSyncTabs from '../../Hooks/useSyncTabs';
import { useAuth } from '../../Contexts/AuthProvider';
import { useBoxes } from '../../Contexts/BoxContext';
import { unitChannel } from '../../utils/functions';
import uuid from 'uuid/v1';
import { boxHelper, dashboardHelper, uniqueValues } from '../../helpers';
import AlertToastr from '../../utils/alert';
import useTranslation from '../../Hooks/useTranslation';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentCompany } from '../../Store/modules/dashboard/actions';
import { getUnitById } from '../../Hooks/useUnit'

const UnitsPage = () => {

    const { dashboardId } = useParams();
    const translation = useTranslation();
    const dispatch = useDispatch();
    const {
        userLoaded,
        win,
        setWin,
        initDashboardWindow,
        currentCompany,
    } = useAuth();
    const {
        unitBoxes,
        currentUnit,
        setCurrentUnit,
        currentUnitModelFull,
        addUnitBox,
        editUnitBox,
        removeUnitBox,
        addUnitBoxes,
        unitBoxesFilters,
        setCurrentUnitTemplate,
        setUnitBoxesFilters
    } = useBoxes();

    const { mutateAsync: syncTabs } = useSyncTabs('units');

    useEffect(() => {
        if (!dashboardId) return;

        initDashboardWindow();

        const newWinStorageKey = `dashboard-${dashboardId}`;
        const localStorageData = localStorage.getItem(newWinStorageKey);
        if (!localStorageData) {
            console.log('No any dashboard data in storage');
            return;
        }

        const dashboardInfo = JSON.parse(localStorageData);
        // localStorage.removeItem(newWinStorageKey);
        const { box, parentDashboardId, company, unit } = dashboardInfo;
        if (company) dispatch(setCurrentCompany(company));
        if (unit) {
            setCurrentUnit(unit);
        }

        if (box) {
            const unitBoxes = Array.isArray(box)
                ? box
                : [box];
            addUnitBoxes(unitBoxes);
        }
        setWin({
            id: dashboardId,
            parentId: parentDashboardId ? parentDashboardId : null,
        });

    }, [
        dashboardId,
        initDashboardWindow,
        setWin,
        setCurrentUnit,
        addUnitBoxes,
        dispatch
    ]);

    useEffect(() => {
        if (!win?.id) return;
        syncTabs({
            tabId: win.id,
            items: unitBoxes,
            companyId: currentCompany?.id,
        }).then();

    }, [win, unitBoxes, currentCompany, syncTabs]);

    const handleAddBox = (data, openInNewWindow = null) => {
        if (openInNewWindow) {
            const parentId = win.parentId || win.id;
            dashboardHelper.add({
                id: null,
                parent: parentId,
                box: data,
                job: null,
                unit: currentUnit,
                company: currentCompany,
                filters: unitBoxesFilters,
            }, 'unit');
        } else {
            addUnitBox(data);
        }
    };

    const handleEditBox = (data, openInNewWindow) => {
        if (openInNewWindow) {
            const parentId = win.parentId || win.id;
            dashboardHelper.add({
                id: null,
                parent: parentId,
                box: data,
                job: null,
                unit: currentUnit,
                company: currentCompany,
            }, 'unit');
            removeUnitBox(data.id);
        } else {
            editUnitBox(data);
        }
    };

    const handleRemoveBox = boxId => {
        removeUnitBox(boxId);
    };

    const handleAddBoxes = (boxes) => {
        const boxesNew = boxHelper.updateDragAndDropPosition(boxes);
        addUnitBoxes(boxesNew);
    };

    const handleTemplateLoad = async (tabs, template, unitModel) => {
        let isSuccessTemplateLoad = false;

        let globalFilterInit = null;

        let unitModelFull;
        if (unitModel) {
             unitModelFull = await getUnitById({
                id: unitModel.id,
                companyId: currentCompany?.id,
                section: 'units'
            })

        }
        const unitFull = unitModelFull || currentUnitModelFull;

        tabs?.forEach(tab => {
            const boxes = tab.items;

            const boxesForNewWindow = [];
            const boxesForParentWindow = [];

            boxes.forEach(templateBox => {

                const boxDataChannels = [];
                if (templateBox.channels)
                    templateBox.channels.forEach(channel => {
                        let chObj = unitChannel(unitFull, channel.code);
                        if (chObj) {
                            chObj = { ...chObj, unit: channel.unit };
                            boxDataChannels.push(chObj);
                        }
                    });

                let templateBoxMainXYChannel = '';
                if (templateBox.mainXYChannel) {
                    if (templateBox.mainXYChannel.id) {
                        templateBoxMainXYChannel = templateBox.mainXYChannel.id;
                    } else {
                        templateBoxMainXYChannel = templateBox.mainXYChannel;
                    }
                }

                if (
                    boxDataChannels.length > 0
                    || templateBox.type === 'eventLogger'
                    || templateBox.type === 'jobProperties'
                ) {
                    isSuccessTemplateLoad = true;

                    const templateBoxFilters = templateBox.filters || {};

                    if (templateBoxFilters && !globalFilterInit) {
                        globalFilterInit = {
                            dateType: templateBoxFilters.dateType || 'thisWeek',
                            jobId: templateBoxFilters.jobId || '',
                        }
                    }

                    const boxData = {
                        id: uuid(),
                        filters: templateBoxFilters,
                        name: templateBox.name || '',
                        type: templateBox.type,
                        chartType: templateBox.subType || '',
                        mainXYChannel: templateBoxMainXYChannel,
                        unitUid: unitFull.uId,
                        channels: boxDataChannels,
                        itemPosition: templateBox.itemPosition,
                        timeType: templateBox.timeType || 'local',
                        timeFormat: templateBox.timeFormat || 'time',
                    };
                    addChartSettings(boxData, templateBox);
                    if (tab.isParent) {
                        // open box in current window
                        boxesForParentWindow.push(boxData);

                    } else {
                        // open box in new window
                        boxesForNewWindow.push(boxData);
                    }
                }
            });

            if (globalFilterInit) {
                setUnitBoxesFilters(globalFilterInit)
            }

            if (boxesForNewWindow.length) {
                handleAddBox(boxesForNewWindow, true);
            }

            if (boxesForParentWindow.length) {
                handleAddBoxes(boxesForParentWindow);
            }

        });

        if (template) {
            if (isSuccessTemplateLoad) {
                setCurrentUnitTemplate(template);
            } else {
                setCurrentUnitTemplate(null);
                AlertToastr.showErrorAlert(translation.notificationsLoadTemolateError);
            }
        }
    };

    const addChartSettings = (boxData, templateBox = null) => {
        if (boxData.channels && boxData.type === 'chartView') {
            const units = uniqueValues(boxData.channels.map(channel => channel.unit));
            if (templateBox && templateBox.yAxisSettings) {
                boxData.yAxisSettings = templateBox.yAxisSettings;
            } else {
                boxData.yAxisSettings = uniqueValues(units.map(unit => {
                    return {
                        unit: unit,
                        autoScaling: true,
                        min: undefined,
                        max: undefined,
                    };
                }));
            }

            if (templateBox && templateBox.xAxisSettings) {
                boxData.xAxisSettings = templateBox.xAxisSettings;
            } else {
                if (boxData.chartType === 'timeBased') {
                    boxData.xAxisSettings = {
                        count: undefined,
                        autoScaling: true,
                        unit: 'sec',
                    };
                } else {
                    const mainChannel = unitChannel(currentUnitModelFull, boxData.mainXYChannel);
                    boxData.xAxisSettings = {
                        unit: mainChannel.unit,
                        autoScaling: true,
                        min: undefined,
                        max: undefined,
                    };
                }
            }
        }
    };

    const handleOpenInNewWindow = (boxId) => {
        const movedBox = unitBoxes.find(box => box.id === boxId);
        if (movedBox) {
            const parentId = win.parentId || win.id;
            dashboardHelper.add({
                id: null,
                parent: parentId,
                box: movedBox,
                job: null,
                unit: currentUnit,
                company: currentCompany,
                filters: unitBoxesFilters
            }, 'unit');
            removeUnitBox(boxId);
        }
    };

    const handleUpdateSettings = useCallback(() => {
        if (!win?.id) return;
        syncTabs({
            tabId: win.id,
            items: unitBoxes,
            companyId: currentCompany?.id,
        }).then();
    }, [currentCompany, syncTabs, unitBoxes, win])

    if (dashboardId && !userLoaded) return null

    return (
        <div className="mainContainer">
            <UnitGroup
                onTemplateLoad={handleTemplateLoad}
                onAddBox={handleAddBox}
                onEditBox={handleEditBox}
                onRemoveBox={handleRemoveBox}
                onNewWindowOpen={handleOpenInNewWindow}
                syncTabs={() => console.log('syncing tabs.....')}
                onUpdateSettings={handleUpdateSettings}
            />
        </div>
    );
};

export default UnitsPage;
