import React from 'react'

const TableEmptyListMessage = ({children}) => {
    return (
        <tr>
            <td colSpan="100%"  style={{border: 'none', backgroundColor: '#fafafa', paddingTop: '15px'}} className='TableEmptyListMessage'>
                <p className="text-center" style={{color: 'rgb(144 red 144)'}}>{ children || 'No items found'}</p>
            </td>
        </tr>
    )
}

export default TableEmptyListMessage
