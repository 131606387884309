import LocalizedStrings from "react-localization";
import localization from "Localization";
import { useAppSettings } from 'Contexts/AppSettingsProvider'
import { useMemo } from 'react'

export default function useTranslation() {
    const { appLanguage } = useAppSettings();
    const translation = useMemo(() => {
        let translation = new LocalizedStrings(localization);
        translation.setLanguage(appLanguage);
        return translation
    }, [appLanguage])

    return translation;
}