import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Button from '@material-ui/core/Button'
import { IconButton, Switch } from '@material-ui/core'
import moment from 'moment'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'
import useTranslation from '../../../Hooks/useTranslation'
import { makeStyles } from '@material-ui/core/styles'
import DmsService from '../../../Services/DMS/dms-api.service'
import AlertToastr from '../../../utils/alert'
import SelectJobModal from '../../../Components/modals/SelectJobModal/SelectJobModal'
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component'
import { useUsersSettings } from '../UsersSettingsContext'
import { useQueryClient } from 'react-query'

const descConst = 'descending'
const ascConst = 'ascending'
const arrowDownClassName = 'hasArrow'
const arrowUpClassName = 'hasArrow up'

const RemoveIcon = () => <div className='removeIcon' />
const CopyIcon = () => <div className='copyIcon' />
const IcoPlusWhite = () => <div className='plusIcon' />

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))

const OneJobPasswords = () => {

    const {
        currentUser,
        currentCompany,
        companyDetails,
        setCompanyDetails,
        oneJobPasswords
    } = useUsersSettings()
    const queryClient = useQueryClient()
    const translation = useTranslation();
    const classes = useStyles()
    const [newOneJobPasswordModalIsOpen, onNewOneJobPasswordModalIsOpenChange] = useState(false)
    const [removeOneJobPasswordModalIsOpen, onRemoveOneJobPasswordModalIsOpen] = useState(false)
    const [jobs, setJobs] = useState([])
    // const [oneJobPasswords, setOneJobPasswords] = useState([])
    const [oneJobPasswordData, setOneJobPasswordData] = useState()
    const [isLoading] = useState(false)
    const [oneJobPasswordSortConfig, setOneJobPasswordSortConfig] = useState({ key: '', direction: '' })
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser])

    const companyId = currentCompany ? currentCompany.id : currentUser?.companyId

    // useEffect(() => {
    //     const fetchOneJobPasswords = async () => {
    //         try {
    //             let response
    //             if (isSuperUser) {
    //                 if (!currentCompany)
    //                     return
    //                 response = await DmsService.getOneJobPasswordsByCompanyId(currentCompany.id)
    //             } else {
    //                 response = await DmsService.getOneJobPasswords()
    //             }
    //             if (response.status === noContentStatus) {
    //                 setOneJobPasswords([])
    //             }
    //             if (response.status === successStatus) {
    //                 response.data.map((oneJobPassword) => {
    //                     const passwordJob = jobs.find(job => job.id === oneJobPassword.jobId)
    //                     if (passwordJob) {
    //                         oneJobPassword.jobName = passwordJob.name
    //                         oneJobPassword.jobStartTime = passwordJob.startTime
    //                     }
    //                     return oneJobPassword
    //                 })
    //                 setOneJobPasswords(response.data)
    //             }
    //         } catch (error) {
    //             if (error && error.response && error.response.data && error.response.data.Message)
    //                 AlertToastr.showErrorAlert(error.response.data.Message)
    //         }
    //     }
    //     if (updateOneJobPassword && jobs.length) {
    //         fetchOneJobPasswords().then()
    //         setUpdateOneJobPassword(false)
    //     }
    //
    // }, [jobs, updateOneJobPassword, currentCompany, isSuperUser])

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                let response
                if (isSuperUser) {
                    if (!currentCompany)
                        return
                    response = await DmsService.getCompanyJobsShort(currentCompany.id)
                } else {
                    response = await DmsService.getJobsShort()
                }
                setJobs(response)
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            }
        }
        fetchJobs().then()
    }, [currentCompany, isSuperUser])

    const sortedOneJobPasswords = useMemo(() => {

        let sortedPasswords = Array.isArray(jobs) && jobs.length ? oneJobPasswords.map((oneJobPassword) => {
            const passwordJob = jobs.find(job => job.id === oneJobPassword.jobId)
            if (passwordJob) {
                oneJobPassword.jobName = passwordJob.name
                oneJobPassword.jobStartTime = passwordJob.startTime
            }
            return oneJobPassword
        }) : [...oneJobPasswords]

        if (oneJobPasswordSortConfig && oneJobPasswordSortConfig.key) {
            if (oneJobPasswordSortConfig.key === 'status') {
                sortedPasswords.sort((a, b) => {
                    if (a.isEnabled < b.isEnabled) {
                        return oneJobPasswordSortConfig.direction === ascConst ? -1 : 1
                    }
                    if (a.isEnabled > b.isEnabled) {
                        return oneJobPasswordSortConfig.direction === ascConst ? 1 : -1
                    }
                    return 0
                })
            }
            sortedPasswords.sort((a, b) => {
                if (a[oneJobPasswordSortConfig.key] < b[oneJobPasswordSortConfig.key]) {
                    return oneJobPasswordSortConfig.direction === ascConst ? -1 : 1
                }
                if (a[oneJobPasswordSortConfig.key] > b[oneJobPasswordSortConfig.key]) {
                    return oneJobPasswordSortConfig.direction === ascConst ? 1 : -1
                }
                return 0
            })
        }
        else {
            sortedPasswords.sort((a, b) => {
                if (a.jobStartTime < b.jobStartTime) {
                    return 1
                }
                if (a.jobStartTime > b.jobStartTime) {
                    return -1
                }
                return 0
            })
        }
        return sortedPasswords
    }, [oneJobPasswords, oneJobPasswordSortConfig, jobs])

    const requestOneJobPasswordSort = (key) => {
        let direction = ascConst
        if (
            oneJobPasswordSortConfig &&
            oneJobPasswordSortConfig.key === key &&
            oneJobPasswordSortConfig.direction === ascConst
        ) {
            direction = descConst
        }
        setOneJobPasswordSortConfig({ key, direction })
    }

    const getClassNamesForOneJobPassword = (name) => {
        if (!oneJobPasswordSortConfig || oneJobPasswordSortConfig.key !== name) {
            return
        }
        if (oneJobPasswordSortConfig.direction)
            return oneJobPasswordSortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName
    }

    const onAllowOneJobPasswordValueChanged = (updatedCompany) => {
        DmsService.updateCompany(updatedCompany).then()
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const onOneJobPasswordEnabledChanged = (oneJobPassword) => {
        oneJobPassword.isEnabled = !oneJobPassword.isEnabled
        DmsService.updateIsEnabledOneJobPassword(oneJobPassword.id, !oneJobPassword.isEnabled).then(res => {
            queryClient.invalidateQueries('settingsOneJobPasswords').then()
            // setUpdateOneJobPassword(true)
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const onOneJobPasswordCsvExportChanged = oneJobPassword => {
        DmsService.updateOneJobPassword({
            ...oneJobPassword,
            canDownloadDataFile: !oneJobPassword.canDownloadDataFile
        }).then(res => {
            queryClient.invalidateQueries('settingsOneJobPasswords').then()
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    const copyLinkToClipboard = async (id) => {
        try {
            let response = await DmsService.getOneJobPasswordLink(id)
            navigator.clipboard.writeText(response.data);
            AlertToastr.showAlert(translation.notificationsLinkCopied)
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message)
        }

    }

    const onCreateOneJobPassword = (jobId) => {
        onNewOneJobPasswordModalIsOpenChange(false)
        DmsService.createOneJobPassword(jobId, companyId).then(res => {
            queryClient.invalidateQueries('settingsOneJobPasswords').then()
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    const onDeleteOneJobPassword = () => {
        DmsService.deleteOneJobPassword(oneJobPasswordData.id).then(res => {
                queryClient.invalidateQueries('settingsOneJobPasswords').then()
            }

        )
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    return (
        <Fragment>
            <div className="pageBtnSection pt0">
                <div className="part">
                    <Button className="addItem"
                            variant="contained"
                            color='primary'
                            startIcon={<IcoPlusWhite />}
                            onClick={() => onNewOneJobPasswordModalIsOpenChange(true)}
                    >
                        {translation.settingsTablesNewJobPasswordLabel}
                    </Button>
                </div>
                <div className="part end">
                    <div className='reverseSwitchHolder'>
                        {
                            companyDetails && (
                                <Switch checked={companyDetails?.allowOneJobPassword}
                                        onChange={(e) => {
                                            setCompanyDetails(company => {
                                                const updatedCompany = { ...company, allowOneJobPassword: !company.allowOneJobPassword }
                                                onAllowOneJobPasswordValueChanged(updatedCompany)
                                                return updatedCompany
                                            })

                                        }}
                                        color="primary"
                                />
                            )
                        }
                        <div className='reverseSwitcherLabel'>{translation.settingsTablesAllowOneJobPasswordLabel}</div>
                    </div>
                </div>
            </div>
            <div className="pageCard noPadding">
                <div className="tableHolder">
                    <table>
                        <thead>
                        <tr className='headingRow'>
                            <th onClick={() => requestOneJobPasswordSort('jobName')}
                                className={getClassNamesForOneJobPassword('jobName')}>
                                {translation.settingsTablesJobNameHeader}
                            </th>
                            <th onClick={() => requestOneJobPasswordSort('password')}
                                className={getClassNamesForOneJobPassword('password')}>
                                {translation.settingsTablesPasswordHeader}
                            </th>
                            <th onClick={() => requestOneJobPasswordSort('status')}
                                className={`text-center ${getClassNamesForOneJobPassword('status')}`}>
                                {translation.settingsTablesStatusHeader}
                            </th>
                            <th onClick={() => requestOneJobPasswordSort('csv_export')}
                                className={`text-center ${getClassNamesForOneJobPassword('csv_export')}`}>
                                {translation.settingsTablesCsvExportHeader}
                            </th>
                            <th onClick={() => requestOneJobPasswordSort('activeSessions')}
                                className={`text-center ${getClassNamesForOneJobPassword('activeSessions')}`}>
                                {translation.settingsTablesSessionsHeader}
                            </th>
                            <th onClick={() => requestOneJobPasswordSort('validTo')}
                                className={getClassNamesForOneJobPassword('validTo')}>
                                {translation.settingsTablesValidToHeader}
                            </th>
                            <th />
                        </tr>
                        </thead>
                        <tbody>
                        {
                            sortedOneJobPasswords && Array.isArray(sortedOneJobPasswords) && sortedOneJobPasswords.map(oneJobPassword => {
                                return (
                                    <tr key={oneJobPassword.id}>
                                        <td>
                                            {oneJobPassword.jobName}
                                        </td>
                                        <td>
                                            {oneJobPassword.password}
                                        </td>
                                        <td className='text-center switchCell' onClick={() => onOneJobPasswordEnabledChanged(oneJobPassword)}>
                                            <Switch checked={oneJobPassword.isEnabled}
                                                    color="primary" />
                                        </td>
                                        <td className='text-center switchCell' onClick={() => onOneJobPasswordCsvExportChanged(oneJobPassword)}>
                                            <Switch checked={oneJobPassword.canDownloadDataFile}
                                                    color="primary" />
                                        </td>
                                        <td className='text-center'>{+oneJobPassword.activeSessions || '-'}</td>
                                        <td >{oneJobPassword.validTo ? moment.utc(oneJobPassword.validTo).local().format('DD.MM.YYYY HH:mm') : ''}</td>
                                        <td className={'actionTableCell'}>
                                            <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                        onClick={(e) => {
                                                            copyLinkToClipboard(oneJobPassword.id)
                                                        }}>
                                                <CopyIcon />
                                            </IconButton>
                                            <IconButton size={'small'} className={'deleteTableItemBtn'}
                                                        onClick={(e) => {
                                                            setOneJobPasswordData(oneJobPassword)
                                                            onRemoveOneJobPasswordModalIsOpen(true)
                                                        }}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                    <div className={classes.root}>
                        <Fade
                            in={isLoading}
                            unmountOnExit>
                            <CircularProgress />
                        </Fade>
                    </div>
                </div>
            </div>
            <SelectJobModal jobs={jobs}
                            title={'Select Job'}
                            isOpen={newOneJobPasswordModalIsOpen}
                            onCancel={() => { onNewOneJobPasswordModalIsOpenChange(false) }}
                            onSave={onCreateOneJobPassword}
                            setCurrentJobUnits={null}
                            type={'oneJobPassword'} />
            <DeleteItemModal isOpen={removeOneJobPasswordModalIsOpen}
                             isOpenManager={onRemoveOneJobPasswordModalIsOpen}
                             message={translation.removeOneJobPasswordModalMessageLabel}
                             onDeleteModalClose={onDeleteOneJobPassword} />
        </Fragment>
    )
}

export default OneJobPasswords
