import { useMutation } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';
import { useAuth } from '../Contexts/AuthProvider';

export default function useDeleteDataExport(tabType = 'jobs') {
    const { currentCompany } = useAuth();
    return useMutation(
        () => axios.delete(`${apiBaseUrl}/dataexport/${tabType}`, {
            headers: { 'CompanyId': currentCompany?.id },
        }),
    );
}
