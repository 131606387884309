import React, { useState, useEffect, useRef } from 'react';
import MmsService from '../../../../Services/MMS/mms-api.service';
import { TextField, Popover } from '@material-ui/core';
import AlertToastr from '../../../../utils/alert';

const EquipmentInlineNotesForm = ({ model, onSuccessUpdate }) => {
  const [value, setValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (model?.note === undefined) return;
    setValue(model?.note);
  }, [model.note]);

  const isDeleting = model.isDeleting;

  const handleSubmit = () => {
    if (!value && !model.note) {
      setValue(model.note);
    }

    if (model.note !== value) {
      MmsService.updateComponentNote(model.id, value)
        .then((response) => {
          if (response?.data?.isSuccessful) {
            if (onSuccessUpdate) {
              onSuccessUpdate();
            }
          }
        })
        .catch((error) => {
          setValue(model.note);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            AlertToastr.showErrorAlert(error.response.data.message);
        });
    }
  };

  const onChangeHandler = (e) => {
    if (isDeleting) {
      return;
    }
    setValue(e.target.value);
  };

  const onBlurHandler = () => {
    if (isDeleting) {
      return;
    }
    handleSubmit();
  };

  const handleClick = (event) => {
    if (isDeleting) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      inputRef.current.setSelectionRange(value.length, value.length);
    }, 0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <TextField
        type="text"
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        variant="outlined"
        size="small"
        onClick={handleClick}
        id="assetsTblNoteField"
        disabled={isDeleting} 
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="notePopoverBlock"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ overflow: 'auto', boxSizing: 'border-box' }}>
          <TextField
            type="text"
            value={value}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            variant="outlined"
            size="small"
            multiline
            maxRows={6}
            className="activityNotePopover"
            autoFocus
            inputRef={inputRef}
            disabled={isDeleting} 
          />
        </div>
      </Popover>
    </div>
  );
};

export default EquipmentInlineNotesForm;
