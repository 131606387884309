import React, {useEffect, useState} from 'react'
import {useLocation, Redirect} from 'react-router'
import authEndpoints from 'Services/endpoints/auth.endpoints'
import useApiCall from 'Hooks/useApiCall'
import MuiAlert from '@material-ui/lab/Alert';
import {CircularProgress} from '@material-ui/core'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConfirmEmailPage = () => {

    const query = useQuery()
    const token = query.get('email_token')
    const passwordToken = query.get('password_token')
    const [emailConfirmRequest, emailConfirm] = useApiCall()
    const [successConfirm, setSuccessConfirm] = useState(false)

    useEffect(() => {
        if (token) {
            emailConfirm({
                ...authEndpoints.emailConfirm(),
                data: {
                    emailConfirmationToken: token
                }
            })
        }
    }, [token, emailConfirm])

    useEffect(() => {
        if (emailConfirmRequest.response) {
                setSuccessConfirm(true)
        }
    }, [
        emailConfirmRequest.response,
        setSuccessConfirm,
        passwordToken,
    ])

    const renderError = () => {
        return emailConfirmRequest.error && emailConfirmRequest.error.message && (
            <Alert severity="error">{emailConfirmRequest.error.message}</Alert>
        )
    }

    if (successConfirm) {
        if (passwordToken) {
            const encodeToken = encodeURIComponent(passwordToken)
            return <Redirect to={`/set-password?token=${encodeToken}&type=registration`} />
        } else {
            return <Redirect to='/signin'/>
        }
    }

    return (
        <div className='text-center'>
            {emailConfirmRequest.loading && <CircularProgress />}

            {renderError()}
        </div>
    )
}

export default ConfirmEmailPage

