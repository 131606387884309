import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { usernameFromFullName } from 'utils/functions';
import ApiService from 'Services/Api.service';
import { useDispatch, useSelector } from 'react-redux';
import { addBox, setCurrentCompany, setCurrentJob } from 'Store/modules/dashboard/actions';
import { v1 as uuid } from 'uuid';
import JobsManagerSignalr from 'Components/SignalrSubscribes/JobsManagerSignalr.component';
import { buildUserHub } from 'Components/SignalrSubscribes/signalRHubs';
import DmsApiService from 'Services/DMS/dms-api.service';
import { useAppSettings } from 'Contexts/AppSettingsProvider';
import axios from 'axios';
import { useQuery } from 'react-query';
import { apiBaseUrl } from '../config';
import User from '../Models/User';
import { clearStoredCompany } from '../storage/company-storage';
import { clearStoredDepartment } from '../storage/department-storage';
import { setStoredLang } from '../storage/lang-storage';
import {apiAuthBaseUrl} from 'config'

// color update
const AuthContext = createContext({});
AuthContext.displayName = 'AuthContext';

const STORAGE_ACCESS_TOKEN_KEY = '_t';

export const AuthProvider = ({ children }) => {

    const currentCompany = useSelector(state => state.dashboard.currentCompany);
    const [user, setUser] = useState(null);
    const [isSignedFlow, setIsSignedFlow] = useState(false);
    const [userLoading, setUserLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [isDashboardWindow, setIsDashboardWindow] = useState(false);
    const [id, setId] = useState();
    const [win, setWin] = useState({});
    const [dbSizeInfo, setDbSizeInfo] = useState(null);



    const dispatch = useDispatch();
    const [hubConnection, setHubConnection] = useState(null);
    const [, setConnectionState] = useState(null);
    const { setAppTheme, appLanguage, setAppLanguage, setUserBoundChannelColors } = useAppSettings();

    const userLoaded = useMemo(() => {
        if (!user) return false;
        if (!user.isSuperuser()) return true;
        return user.isSuperuser() && !!currentCompany;
    }, [user, currentCompany]);

    const isChildWindow = useMemo(() => win.id && !!win.parentId, [win.id, win.parentId]);

    const dbSystemRequest = useQuery(
        ['dbSystem', currentCompany, user],
        async () => {
            const options = !user.isSuperuser() ? {} : {
                headers: {
                    'CompanyId': currentCompany?.id,
                },
            };
            return await axios.get(`${apiBaseUrl}/system/database`, options);
        },
        { keepPreviousData: true, enabled: !!userLoaded },
    );

    useEffect(() => {
        if (dbSystemRequest.isSuccess) {
            setDbSizeInfo(dbSystemRequest.data.data);
        }
    }, [dbSystemRequest.isSuccess, dbSystemRequest.data]);

    useEffect(() => {
        if (user && user.isSuperuser()) {
            if (currentCompany) {
                axios.defaults.headers.common['CompanyId'] = currentCompany.id;
            }
        }
    }, [currentCompany, user]);

    useEffect(() => {
        const theme = window.sessionStorage.getItem('theme');
        setAppTheme(theme);
        localStorage.setItem('theme', theme);
        ApiService.init(appLanguage);
    }, [setAppTheme, appLanguage]);

    const createHubConnection = useCallback(async () => {

        if (!user) return;
        if (user && user.isSuperuser() && !currentCompany) return;

        const hubConnect = buildUserHub(accessToken, currentCompany?.id);
        try {
            await hubConnect.start();
            console.log('win hub started...');
            setConnectionState(hubConnect.state);
            hubConnect.on('Drop', data => {
                localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
                localStorage.removeItem('filterInfo');
                setIsAuthenticated(false);
                setAccessToken('');
                setUser(null);
                setWin({});
                ApiService.removeAuthHeader();
                if (hubConnect && hubConnect.state === 'Connected') {
                    hubConnect.stop();
                }
            });
            hubConnect.on('ParentTabClosed', () => {
                window.close();
            });
            hubConnect.on('ThemeChanged', (theme) => {
                if (isChildWindow) {
                    setAppTheme(theme === 1 ? 'Dark' : 'Light');
                }
            });

            const windowData = {
                TabId: win.id,
                ParentTabId: win.parentId === win.id ? null : win.parentId,
            };
            await hubConnect.invoke('Subscribe', windowData).then();

            hubConnect.onclose(error => {
                console.log('win hub closed...');
                setConnectionState(hubConnect.state);
            });

            hubConnect.onreconnecting(async () => {
                console.log('users reconnecting...');
                setConnectionState(hubConnect.state);
            });

            hubConnect.onreconnected(async () => {
                console.log('users reconnected');
                setConnectionState(hubConnect.state);
            });

        } catch (err) {
            console.log('Error while establishing connection: ', err);
        }

        setHubConnection(hubConnect);

    }, [accessToken, win.id, win.parentId, isChildWindow, setAppTheme, currentCompany, user]);

    const versionEndpoint = () => {
        return { url: `${apiAuthBaseUrl}/home/version`, method: 'get' };
      };
      
      const fetchVersion = () => {
        return axios(versionEndpoint())
          .then((response) => {
            const storedVersion = localStorage.getItem('version');
            const pageReloaded = localStorage.getItem('pageReloaded');
      
            if (!storedVersion) {
              localStorage.setItem('version', response.data.version);
            } else if (storedVersion !== response.data.version && !pageReloaded) {
              localStorage.setItem('version', response.data.version);
              localStorage.setItem('pageReloaded', true);
              window.location.reload(true);
              localStorage.removeItem('_t');
            } else {
              localStorage.removeItem('pageReloaded');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };
      
      useEffect(() => {
        if (isAuthenticated) {
          fetchVersion();
        }
      }, [isAuthenticated]);

    const setAuthData = useCallback(async token => {
        if (!token) {
            localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
            localStorage.removeItem('filterInfo');
            setIsAuthenticated(false);
            setAccessToken('');
            setUser(null);
            ApiService.removeAuthHeader();
            if (hubConnection && hubConnection.state === 'Connected') {
                hubConnection.stop();
            }
            return;
        }

        try {
            if (accessToken !== token) {
                const decodedToken = jwtDecode(token);
                setAccessToken(token);
                setIsAuthenticated(true);
                setUser(new User({
                    name: decodedToken.full_name ? usernameFromFullName(decodedToken.full_name) : decodedToken.name,
                    role: decodedToken.role,
                    permissions: Array.isArray(decodedToken.ui_permissions) ? [...decodedToken.ui_permissions] : [decodedToken.ui_permissions],
                    serverPermissions: Array.isArray(decodedToken.permissions) ? [...decodedToken.permissions] : [decodedToken.permissions],
                    companyId: decodedToken.company_id,
                    defaultCompanyId: decodedToken?.default_company_id,
                    isOneJobPassword: decodedToken.type === 'ojp',
                }));
                ApiService.setAuthHeader(token);
            }

            if ((!hubConnection || hubConnection.state === 'Disconnected') && win.id) {
                createHubConnection().then();
                return;
            }
        } catch (e) {
            localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY);
            throw new Error('Token parsing error');
        }

    }, [createHubConnection, hubConnection, win.id, accessToken]);


    const loginHandler = token => {
        setAuthData(token);
        setIsSignedFlow(true);
        setWin({ id: uuid(), parentId: null });
        localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, token);
    };

    const logoutHandler = useCallback(() => {
        setAuthData();
        setWin({});
        dispatch(setCurrentCompany(null))
        clearStoredCompany();
        clearStoredDepartment();
    }, [setAuthData, dispatch]);

    const setUserLanguage = useCallback(language => {
        setAppLanguage(language);
        if (!isChildWindow) {
            DmsApiService.setLanguage(win.id, language).then();
            setStoredLang(language);
        }
    }, [setAppLanguage, isChildWindow, win.id]);

    const setUserTheme = useCallback((theme) => {
        setAppTheme(theme);
        if (!isChildWindow) {
            DmsApiService.setTheme(win.id, theme).then();
        }

    }, [setAppTheme, win.id, isChildWindow]);

    const setBoundChannelColors = useCallback((boundChannelColors) => {
        setUserBoundChannelColors(boundChannelColors);

    }, [setUserBoundChannelColors]);

    // fetch user settings (lang, theme)
    useEffect(() => {
        const fetchUserSettings = () => {
            DmsApiService.getSettings().then(response => {
                if (response && response.data) {
                    const { language, theme } = response.data;
                    window.sessionStorage.setItem('theme', theme);
                    setAppTheme(theme);
                    setAppLanguage(language);
                }
            });
        };

        if (isAuthenticated) {
            fetchUserSettings();
        }
    }, [isAuthenticated, setAppLanguage, setAppTheme]);

    // init auth status
    useEffect(() => {
        const at = localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY);
        if (at) {
            setAuthData(at);
        }
        setUserLoading(false);
    }, [setAuthData]);

    // init window
    useEffect(() => {
        if (!isAuthenticated)
            return;
        if (id) {
            //this is child window
            const newWinStorageKey = `dashboard-${id}`;
            const localStorageData = localStorage.getItem(newWinStorageKey);
            if (!localStorageData) {
                // window.close()
                console.log('No any dashboard data in storage');

                return;
            }

            const dashboardInfo = JSON.parse(localStorageData);
            localStorage.removeItem(newWinStorageKey);
            const { job, box, parentDashboardId, company } = dashboardInfo;
            if (job) dispatch(setCurrentJob(job));
            if (company) dispatch(setCurrentCompany(company));
            if (box) {
                if (Array.isArray(box)) {
                    // array comes from load template flow
                    box.forEach(b => dispatch(addBox(b)));
                } else {
                    dispatch(addBox(box));
                }

            }
            setWin({
                id,
                parentId: parentDashboardId ? parentDashboardId : null,
            });
        }
    }, [id, dispatch, isAuthenticated]);

    // api errors handler
    useEffect(() => {
        const authInterceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response) {
                    const status = error.response.status || 500;

                    if (status === 401) {
                        logoutHandler();
                        return;
                    }

                    if (status === 403) {
                        logoutHandler();
                        return;
                    }

                }
                // console.log(error)
                throw error;
            });
        return () => {
            axios.interceptors.request.eject(authInterceptor);
        };
    }, [logoutHandler]);

    useEffect(() => {
        if (isDashboardWindow) {
            return;
        }
        setWin({ id: uuid(), parentId: null });
    }, [isDashboardWindow])

    const initDashboardWindow = useCallback((dashboardId) => {
        setIsDashboardWindow(true);
        if (dashboardId)
            setId(dashboardId);
    }, [],);

    return (
        <AuthContext.Provider value={{
            isLoggedIn: isAuthenticated,
            currentUser: user,
            token: accessToken,
            login: loginHandler,
            logout: logoutHandler,
            setUserLanguage,
            setUserTheme,
            setBoundChannelColors,
            initDashboardWindow,
            win,
            setWin,
            dbSizeInfo,
            userLoaded,
            currentCompany,
            isSignedFlow,
        }}>
            {!userLoading && children}
            {<JobsManagerSignalr isSuperUser={user && user.role === 'superuser'} />}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
