import { useQuery } from 'react-query';
import { apiMmsBaseUrl } from 'config';
import Api from '../../Services/Api.service';

export default function useTimezones() {
    let url = `${apiMmsBaseUrl}/options/timezones`
    return useQuery(
        ['timezones'],
        () => Api.get(url),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );
}
