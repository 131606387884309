import React, { Fragment, useState } from 'react';
import useTranslation from '../../Hooks/useTranslation';
import { useBoxes } from '../../Contexts/BoxContext';
import { deleteEmptyStringProperties } from '../../helpers';
import { MenuItem, TextField } from '@material-ui/core';
// import { Check } from '@material-ui/icons';
// import Button from '@material-ui/core/Button';
import { useAsyncDebounce } from 'react-table';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const DataTableCustomDateBoxFilter = (props) => {

    const {
        onChange,
        initData,
        dateFrom,
        dateTo,
        onDateFromChange,
        onDateToChange,
        showStepFilter = true,
        saveInLocalTime
    } = props;

    const translation = useTranslation();
    const { dateTypesOptions, dateTypeIsLoading } = useBoxes();
    const [typeValue, setTypeValue] = useState(initData?.filterType || 'none');
    const [value, setValue] = useState(initData?.value || '');


    const handleChangeFilterType = event => {
        const selectValue = event.target.value;

        if (selectValue === 'none') {
            onChange(deleteEmptyStringProperties({
                filterType: selectValue,
                value: '',
            }));
        }

        setTypeValue(selectValue);
        setValue('');
    };

    const onValueChange = useAsyncDebounce((filterType, value) => {
        onChange(deleteEmptyStringProperties({
            filterType: value ? filterType : 'none',
            value: value === '' ? 0 : value,
        }));
    }, 700);

    const handleChangeValue = (e) => {
        const { value } = e.target;
        setValue(value);
        onValueChange(typeValue, value);
    };

    const handleChangeDateFromValue = useAsyncDebounce(datetime => {

        const dateToUtc = dateTo ? moment(dateTo).utc().format('YYYY-MM-DDTHH:mm:ss') : null;
        const dateToOutput = saveInLocalTime ? dateTo : dateToUtc


        if (!datetime) {
            onDateFromChange(null);
            onChange(deleteEmptyStringProperties({
                filterType: typeValue,
                value,
                dateFrom: null,
                dateTo: dateToOutput
            }));
            return;
        }
        if (datetime.isValid()) {
            const datetimeUtc = datetime.clone();
            onChange(deleteEmptyStringProperties({
                filterType: typeValue,
                value,
                dateFrom: saveInLocalTime ? datetime : datetimeUtc.utc().format('YYYY-MM-DDTHH:mm:ss'),
                dateTo: dateToOutput
            }));
            return onDateFromChange(datetime.format('YYYY-MM-DDTHH:mm:ss'));
        }
    }, 700);

    const handleChangeDateToValue = useAsyncDebounce(datetime => {
        const dateFromUtc = dateFrom ? moment(dateFrom).utc().format('YYYY-MM-DDTHH:mm:ss') : null;
        const dateFromOutput = saveInLocalTime ? dateFrom : dateFromUtc;

        if (!datetime) {
            onDateToChange(null);

            onChange(deleteEmptyStringProperties({
                filterType: typeValue,
                value,
                dateFrom: dateFromOutput,
                dateTo: null
            }));
        }
        if (datetime.isValid()) {
            const datetimeUtc = datetime.clone();
            onChange(deleteEmptyStringProperties({
                filterType: typeValue,
                value,
                dateFrom: dateFromOutput ,
                dateTo: saveInLocalTime ? datetime : datetimeUtc.utc().format('YYYY-MM-DDTHH:mm:ss')
            }));
            return onDateToChange(datetime.format('YYYY-MM-DDTHH:mm:ss'));
        }
    }, 700);

    return (
        <div className="box-filters">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="box-filters-container">
                <div className='DateTimePickerFrom'>
                    <KeyboardDateTimePicker
                        inputVariant="outlined"
                        label={translation.fromLabel}
                        placeholder="dd.mm.yyyy hh:mm:ss"
                        value={dateFrom || null}
                        onChange={handleChangeDateFromValue}
                        format="DD.MM.yyyy, HH:mm:ss"
                        variant="inline"
                        ampm={false}
                        KeyboardButtonProps={{ size: 'small' }}
                    />
                </div>
                <div className='DateTimePickerTo'>
                    <KeyboardDateTimePicker
                        inputVariant="outlined"
                        label={translation.toLabel}
                        placeholder="dd.mm.yyyy hh:mm:ss"
                        value={dateTo || null}
                        onChange={handleChangeDateToValue}
                        format="DD.MM.yyyy, HH:mm:ss"
                        variant="inline"
                        ampm={false}
                        KeyboardButtonProps={{ size: 'small' }}
                    />
                </div>
                </div>
            </MuiPickersUtilsProvider>

            {
                showStepFilter && (
                    <Fragment>
                        <div className="box-filters-container">
                        <div className='stepSizeChange'>
                            <TextField
                                disabled={dateTypeIsLoading}
                                variant="outlined"
                                margin="none"
                                select
                                fullWidth
                                value={typeValue}
                                onChange={handleChangeFilterType}
                            >
                                {
                                    dateTypesOptions.map(item => (
                                        <MenuItem
                                            className="filterMenuItem"
                                            key={item.filterType}
                                            value={item.filterType}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </div>
                        {
                            typeValue !== 'none' && (
                                <div className='stepSizeValue'>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        margin="none"
                                        name="value"
                                        label={translation.valueLabel}
                                        type="number"
                                        value={value}
                                        onChange={handleChangeValue}
                                        // onBlur={handleSubmit}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            )
                        }
                        </div>
                    </Fragment>
                )
            }
        </div>
    );
};

export default DataTableCustomDateBoxFilter;
