import React, { useMemo, useEffect } from "react";
import { formatPointTime } from "utils/functions";
import ListTable from "../ListTable";
import { MultiSelectColumnFilter } from "../../filters";
import { useBoxes } from "Contexts/BoxContext";
import moment from 'moment'

const EventLogger = (props) => {
  const {
    timeType,
    timeFormat,
    timeDiff,
    dateTimeFormat,
    job,
    data,
    jobsAdditionalHeader,
    unitsRequest
  } = props;

  const timeZone = useMemo(() => (job ? job.timeZone : null), [job]);

  const { updateEventLoggerDateRange } = useBoxes();

  const getLocalTimeFromUtcForJobs  = (utcDate, timezone) => {
    const localMoment = moment.tz(utcDate, timezone);
    const utcMoment = localMoment.utc();
    return utcMoment.tz(timezone).toDate();
  };

  useEffect(() => {
    if (data?.length) {
      const timezone = moment.tz.guess();
    
        const sortedData = [...data].sort((a, b) => a.timestamp - b.timestamp);
        const firstTimestamp = sortedData[0].timestamp;
        const lastTimestamp = sortedData[sortedData.length - 1].timestamp;
      
        updateEventLoggerDateRange(getLocalTimeFromUtcForJobs(firstTimestamp, timezone), getLocalTimeFromUtcForJobs(lastTimestamp, timezone))
    }
}, [data, updateEventLoggerDateRange]);


  const tableColumns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Time",
        accessor: "timestamp",
        Cell: ({ value }) => {
          return formatPointTime(
            value,
            timeType,
            timeFormat,
            timeDiff,
            timeZone,
            dateTimeFormat
          );
        },
      },
      {
        Header: "Event Type",
        accessor: "type",
        canFilter: false,
        Filter: MultiSelectColumnFilter,
        filter: "includesSome",
      },
      {
        Header: "Message",
        accessor: "text",
        canFilter: false,
        Cell: ({ value }) => {
          return value.split("\r\n").map((row, index) => {
            return (
              <div style={{ whiteSpace: "normal" }} key={index}>
                {row}
              </div>
            );
          });
        },
        className: "message-column",
      },
      {
        Header: "Sender",
        accessor: "senderName",
        canFilter: false,
      },
      {
        Header: "Message ID",
        accessor: "senderMessageId",
        canFilter: false,
      },
      {
        Header: "Event State",
        accessor: "eventState",
        canFilter: false,
      },
    ];

    if (jobsAdditionalHeader) {
      baseColumns.splice(-2, 0, {
        Header: "Unit Name",
        accessor: "name",
        canFilter: false,
        Cell: ({ row }) => {
          const matchingUnit = unitsRequest.data.find(unit => unit.uId === row.original.senderUId);

          return matchingUnit ? matchingUnit.nameLocalized : row.original.name || null;
        },
        className: "message-column",
      });
    }

    return baseColumns;
  }, [jobsAdditionalHeader, timeType, timeFormat, timeDiff, timeZone, dateTimeFormat, unitsRequest.data]);

  const tableData = useMemo(() => data || [], [data]);

  return (
    <div>
      <ListTable
        emptyDataText={
          <span>
            <span>No events found.</span>
          </span>
        }
        initSort={[{ id: "timestamp", desc: true }]}
        columns={tableColumns}
        enableColumnFilters={true}
        data={tableData}
        tableClass="bordered"
      />
    </div>
  );
};

export default EventLogger;
