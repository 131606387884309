import React, { useState, useEffect, Fragment, useMemo, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import MmsService from 'Services/MMS/mms-api.service'
import { setDepartments, setCurrentCompanyPage } from '../../../../Store/modules/mms/actions'
import { normalizeErrorResponse } from 'utils/form'
import AlertToastr from 'utils/alert'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import { MenuItem } from '@material-ui/core'

const SaveDepartmentModal = ({
                               open,
                               handleClose,
                               department,
                               departments,
                               setDepartments,
                               currentCompany,
                               setCurrentCompanyPage,
                               onSuccessSave
                             }) => {
  const translation = useTranslation()
  const [name, setName] = useState('')
  const [note, setNote] = useState('')
  const [employeeID, setEmployeeID] = useState('')
  const [disableButtons, setDisabledButtons] = useState(false)
  const [errors, setErrors] = useState({})
  const emptyAssagneeOption = useMemo(() => {
    return { id: 'empty', name: translation.saveDepartmentModalContactPersonLabel }
  }, [translation])

  const fetchEmployeesByDepartmentIdSplitted = useCallback(async (id) => {
    try {
      let response = await MmsService.getEmployeesByDepartmentIdSplitted(id)
      setEmployeesByID(response.data)

    } catch (error) {
      setErrorByID(error)
    }
  }, [])

  useEffect(() => {
    if (!open)
      return
    if (department) {
      fetchEmployeesByDepartmentIdSplitted(department.id)
      setName(department.name)
      setEmployeeID(department?.employee?.id || '')
      setNote(department.note)
    } else {
      fetchEmployeesByDepartmentIdSplitted()
      setName('')
      setNote('')
      setEmployeeID('')
    }
  }, [department, open, fetchEmployeesByDepartmentIdSplitted])

  const [employeesByID, setEmployeesByID] = useState({ fromDepartment: [], other: [] })
  const [, setErrorByID] = useState(null)

  const handleSave = () => {
    setDisabledButtons(true)
    if (department) {
      MmsService.updateDepartment(department.id, { name, note, employeeId: employeeID !== 'empty' ? employeeID : null }).then((response) => {
        if (response.data.isSuccessful) {
          AlertToastr.showAlert(translation.notificationsDepartmentUpdated)
          onSuccessSave()
        }
        handleClose()
        setErrors({})
        setDisabledButtons(false)
      })
        .catch((error) => {
          if (error.response.data) {
            const resErrors = normalizeErrorResponse(error.response.data)
            if (resErrors.errors)
              setErrors(resErrors.errors)
            setDisabledButtons(false)
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message)
          }
          if (error.response.data.errors)
            error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
              AlertToastr.showErrorAlert(x.ErrorMessage)
              return x
            })
        })
    } else
      MmsService.createDepartment({ name, note, employeeId: employeeID }, currentCompany?.id).then((response) => {
        AlertToastr.showAlert(translation.notificationsDepartmentAdded)
        if (response.data.isSuccessful) {
          onSuccessSave()
          setCurrentCompanyPage('departments')
        }
        handleClose()
        setErrors({})
        setDisabledButtons(false)
      })
        .catch((error) => {
          if (error.response.data) {
            const resErrors = normalizeErrorResponse(error.response.data)
            if (resErrors.errors)
              setErrors(resErrors.errors)
            setDisabledButtons(false)
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message)
          }
          if (error.response.data.errors)
            error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
              AlertToastr.showErrorAlert(x.ErrorMessage)
              return x
            })
        })
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={(e) => {
          handleClose()
          setErrors({})
        }}
        
        onKeyDown={(e) => {
          if (e.key === 'Enter'  && department.note === "") {
              e.preventDefault()
              handleSave();
          }
      }}
        className="respModal scrollContentModal autoHeight"
      >
        <div className="formContentHolder">
          <div className="modalTitleHolder">
            <h1 className="modalTitle text-center">
              {department
                ? translation.saveDepartmentModalEditDepartmentLabel
                : translation.saveDepartmentModalAddDepartmentLabel}
            </h1>
          </div>
          <div className="formContent">
            <div className="formContentBlock modalFieldLabelSettings">
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={errors && errors.name ? errors.name.join('\n') : ''}
              >
                <div style={{ marginBottom: '15px' }}>
                  <TextField
                    value={name}
                    onChange={(e) => {
                      if (errors.name) errors.name = null
                      setName(e.target.value)
                    }}
                    error={errors && errors.name && errors.name.length > 0}
                    id="source departmentsAddDepModalName"
                    label={translation.saveDepartmentModalNameLabel}
                    variant="outlined"
                    margin="none"
                    fullWidth
                  />
                </div>
              </Tooltip>
              <div style={{ marginBottom: '15px' }}>
                <TextField
                    value={note}
                    onChange={(e) => {
                      if (errors.name) errors.name = null
                      setNote(e.target.value)
                    }}
                    error={errors && errors.name && errors.name.length > 0}
                    id="source departmentsAddDepModalNote"
                    label={translation.saveDepartmentModalNoteLabel}
                    type="text"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    multiline={true}
                    minRows={4}
                    className="formControlTextarea fixedHeight"
                  />
              </div>
              <TextField
                value={employeeID ? employeeID : 'empty'}
                onChange={(e) => {
                  if (errors.employeeId) errors.employeeId = null
                  setEmployeeID(e.target.value)
                }}
                variant="outlined"
                margin="normal"
                label={translation.saveDepartmentModalAssigneeNameLabel}
                fullWidth
                select
                id="departmentsAddDepModalChangeContactPerson"
              >
                <MenuItem
                  key={emptyAssagneeOption.id}
                  className="filterMenuItem"
                  value={emptyAssagneeOption.id}
                >
                  {emptyAssagneeOption.name}
                </MenuItem>

                {department
                  ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalFromDepartmentLabel}</p>
                  : <p style={{ display: 'none' }}></p>}

                {employeesByID.fromDepartment.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    className="filterMenuItem"
                    value={employee.id}
                    id="departmentsAddDepModalChooseContactPersonFromDep"
                  >
                    {employee.name}
                  </MenuItem>
                ))}

                {department
                  ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalOthersLabel}</p>
                  : <p style={{ display: 'none' }}></p>}

                {employeesByID.other.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    className="filterMenuItem"
                    value={employee.id}
                    id="departmentsAddDepModalChooseContactPersonOthers"
                  >
                    {employee.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="btnHolder end fixedWidthBtn">
            <Button
              disabled={!!disableButtons}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={(e) => {
                handleClose()
                setErrors({})
              }}
              id="departmentsAddDepModalCancelBtn"
            >
              {translation.cancelButtonLabel}
            </Button>
            <Button
              disabled={!!disableButtons}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSave}
              id="departmentsAddDepModalSaveBtn"
            >
              {department
                ? translation.saveButtonLabel
                : translation.addButtonLabel}
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}
const mapStateToProps = ({ mms, dashboard }) => ({
  departments: mms.departments,
  currentCompany: dashboard.currentCompany
})

const mapDispatchToProps = {
  setDepartments,
  setCurrentCompanyPage
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveDepartmentModal)
