import React, { Fragment, useMemo, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import useTranslation from '../../../Hooks/useTranslation';
import { filterArray } from '../../../utils/functions';
import RadioGroup from '@material-ui/core/RadioGroup';
import TemplateListItem from '../../Templates/template-list-item/template-list-item.component';
import { useAuth } from '../../../Contexts/AuthProvider';
import { userHasPermission } from '../../../helpers';
import useTemplateLoad from '../../../Hooks/useTemplateLoad';
import useTemplates from '../../../Hooks/useTemplates';
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

const TemplateList = (props) => {
    const {
        unit,
        onCancel,
        onSuccessTemplateLoad,
        onErrorTemplateLoad,
        section
        // onSave,
        // editedModel
    } = props;

    const { currentUser, win, currentCompany } = useAuth()
    const allowDeleteTemplate = useMemo(() => userHasPermission(currentUser, 'manage_templates'), [currentUser])
    const translation = useTranslation();
    const [value, setValue] = useState([]);
    const templateRequest = useTemplates(unit?.uId, section);
    const [search, setSearch] = useState('');

    const { data: items } = templateRequest;

    const loadTemplateMutation = useTemplateLoad();

    // const canSave = useMemo(() => values.length, [values]);

    const filteredItems = useMemo(() => {
        if (search && search !== '')
            return filterArray(items, search);
        return items;
    }, [items, search]);

    const handleLoadTemplate = () => {
        loadTemplateMutation
            .mutateAsync({
                templateId : value,
                type: section,
                tabId: win?.id,
                unitUid: unit?.uId,
                companyId: currentCompany?.id
            })
            .then(response => {
                const tabs = response && response.data && response.data.tabs;
                const messages = response && response.data && response.data.messages;
                const template = items.find(template => template.id === value);
                if (messages && messages.length) {
                    onErrorTemplateLoad({
                        errors: { type: 'warning', messages },
                        response: {
                            tabs,
                            template,
                        }
                    })
                } else {
                    onSuccessTemplateLoad(tabs, template);
                }

            })
            .catch(error => console.log(error))
    }

    return (
        <Fragment>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="search"
                label={translation.dashboardSearchTemplateLabel}
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <div className="checkboxListHolder mt8">
                <div className="labelLike">{translation.dashboardTemplatesLabel}</div>
                <div className="checkboxListContainer">
                    <RadioGroup aria-label="jobs" name="jobs" defaultValue={'template1'}>
                        {
                            filteredItems.map(template => (
                                <TemplateListItem
                                    key={template.id}
                                    value={template.id}
                                    label={template.name}
                                    selectedTemplate={value}
                                    updatedAt={template.updatedAt}
                                    setSelectedTemplate={setValue}
                                    allowDeleteTemplate={allowDeleteTemplate}
                                    onDeleteItem={templateRequest.refetch}
                                />
                            ))
                        }
                    </RadioGroup>
                    {templateRequest.isFetching && (
                      <div className="loaderHolderTemplates">
                        <Fade in={true} unmountOnExit>
                          <CircularProgress className="loaderHolderTemplates" />
                        </Fade>
                      </div>
                    )}
                </div>
            </div>
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    onClick={onCancel}
                    variant="outlined"
                >
                    {translation.cancelButtonLabel}
                </Button>
                <Button
                    onClick={handleLoadTemplate}
                    variant="contained"
                    color="primary"
                >
                    {translation.openButtonLabel}
                </Button>
            </div>
        </Fragment>
    );
};

export default TemplateList;
