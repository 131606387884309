import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import UnitListItem from '../unit-list-item/unit-list-item.component';
import useTranslation from 'Hooks/useTranslation';

const UnitList = ({ units, setValues, selectedChannels, disabledChannels, search, selectedCsvChannels, setInitialValues }) => {
    const translation = useTranslation();
    const [globalSelectAll, setGlobalSelectAll] = useState(false);
    const [selectedChannelCount, setSelectedChannelCount] = useState(0);
    const [totalChannelsCount, setTotalChannelsCount] = useState(0);

    useEffect(() => {
        const total = units.reduce((acc, unit) => acc + unit.channels.length, 0);
        setTotalChannelsCount(total);
    }, [units]);

    useEffect(() => {
        setSelectedChannelCount(selectedChannels.length);
    }, [selectedChannels]);

    const handleGlobalSelectAllChange = (e) => {
        const checked = e.target.checked;
        setGlobalSelectAll(checked);
        
        const updatedChannels = units.flatMap(unit => 
            unit.channels.filter(channel => 
                !selectedChannels.some(selectedChannel => selectedChannel.id === channel.id)
            ).map(channel => ({
                ...channel
            }))
        );
        
        setInitialValues(prevValues => checked ? [...prevValues, ...updatedChannels] : []);
        setValues(prevValues => checked ? [...prevValues, ...updatedChannels] : []);
    };

    return (
        <div className="checkboxListHolder mt8">
            <div className="labelLike">{translation.dashboardChannelsLabel}</div>
            <div className="checkboxListContainer">
                <div className="labelLike">
                    <FormControlLabel 
                        control={
                            <Checkbox
                                color='default'
                                checked={globalSelectAll}
                                onChange={handleGlobalSelectAllChange}
                            />
                        }
                        label={`Select all (${selectedChannelCount}/${totalChannelsCount})`}
                    />
                </div>
                {units !== null && units.map((unit, index) => (
                    <div key={unit.id + 'select'}>
                        <UnitListItem
                            key={unit.id}
                            unit={unit}
                            index={index}
                            search={search}
                            selectedChannels={selectedChannels}
                            selectedCsvChannels={selectedCsvChannels}
                            disabledChannels={disabledChannels}
                            defaultShow={units.length === 1}
                            setValues={setValues}
                            globalSelectAll={globalSelectAll}
                            setInitialValues={setInitialValues}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UnitList;
