import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox } from '@material-ui/core';
import MmsService from '../../../Services/MMS/mms-api.service';
import AlertToastr from '../../../utils/alert';
import Popover from '@material-ui/core/Popover';
import { MenuItem, Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component';
import SaveScheduledReportsModal from './Modals/SaveScheduledReportsModal';
import { setReports, setPageTitle } from '../../../Store/modules/mms/actions';
import useTranslation from 'Hooks/useTranslation';
import { connect, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useQuery } from 'react-query';
import { useAuth } from '../../../Contexts/AuthProvider';
import axios from 'axios';
import { apiMmsBaseUrl } from '../../../config';

const ArrowLeft = () => <div className="arrowLeftIcon" />;
const ArrowRight = () => <div className="arrowRightIcon" />;

const descConst = 'descending';
const ascConst = 'ascending';
const arrowDownClassName = 'hasArrow';
const arrowUpClassName = 'hasArrow up';

const ScheduledReportsPage = ({
                                  currentDepartment,
                                  reports,
                                  setReports,
                                  statusOptions,
                                  setPageTitle,
                              }) => {

    const { userLoaded, currentUser } = useAuth();
    const currentCompany = useSelector(state => state.dashboard.currentCompany);
    const translation = useTranslation();
    const [isOpenSaveReportModal, setIsOpenSaveReportModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [firstIndex, setFirstIndex] = useState(0);
    const [lastIndex, setLastIndex] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsOnPage, setRowsOnPage] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [anchorEl, onAnchorElChange] = useState(null);

    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    useEffect(() => {
        if (!currentDepartment) {
            setReports([]);
        }
    }, [setReports, currentDepartment]);

    const reportsRequest = useQuery(
        ['reports', currentDepartment, currentCompany],
        async () => {
            const config = !currentUser.isSuperuser() ? {} : {
                headers: {
                    'CompanyId': currentCompany?.id,
                },
            };
            return await axios.get(`${apiMmsBaseUrl}/reports/by-departmentid/${currentDepartment?.id}`, config);
        },
        { keepPreviousData: true, enabled: userLoaded && !!currentDepartment },
    );

    useEffect(() => {
        if (reportsRequest.isSuccess) {
            const { data, status } = reportsRequest.data;
            setReports(status === 200 ? data : []);
        }
    }, [reportsRequest.isSuccess, reportsRequest.data, setReports]);

    // useEffect(() => {
    //     const fetchReports = async () => {
    //         try {
    //             let response = await MmsService.getReports(currentDepartment.id)
    //             setIsLoading(false)
    //             setReports(response.data)
    //         } catch (error) {
    //             setIsLoading(false)
    //             if (error && error.response && error.response.data && error.response.data.message)
    //                 AlertToastr.showErrorAlert(error.response.data.message)
    //         }
    //     }
    //     if (!currentDepartment)
    //         return
    //     fetchReports()
    // }, [currentDepartment, setReports])

    useEffect(() => {
        const fetchDropdownsOptions = async () => {
            let frequencyResponse = await MmsService.getFrequencyOptions();
            setFrequencyOptions(frequencyResponse.data);
        };
        fetchDropdownsOptions();
    }, []);

    const sortedReports = useMemo(() => {
        if (!reports)
            return [];
        let sortedReports = [...reports];
        if (!sortConfig || !sortConfig.key)
            return sortedReports;
        if (sortConfig.key === 'status') {
            sortedReports.sort((a, b) => {
                if ((statusOptions.findIndex(x => x.status === a.status)) < (statusOptions.findIndex(x => x.status === b.status))) {
                    return sortConfig.direction === ascConst ? -1 : 1;
                }
                if ((statusOptions.findIndex(x => x.status === a.status)) > (statusOptions.findIndex(x => x.status === b.status))) {
                    return sortConfig.direction === ascConst ? 1 : -1;
                }
                return 0;
            });
            return sortedReports;
        }
        sortedReports.sort((a, b) => {
            if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === ascConst ? -1 : 1;
            }
            if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === ascConst ? 1 : -1;
            }
            return 0;
        });
        return sortedReports;
    }, [reports, sortConfig, statusOptions]);

    const pageData = useMemo(() => {
        let pageData = [...sortedReports];
        const totalLength = pageData.length;

        if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
            setPageNumber(1);
        }
        setTotalRows(totalLength);
        setFirstIndex(Math.min(((pageNumber - 1) * rowsOnPage + 1), totalLength));
        setLastIndex(Math.min((pageNumber * rowsOnPage), totalLength));
        let tableData = pageData.splice((pageNumber - 1) * rowsOnPage, Math.min((pageNumber * rowsOnPage), totalLength));
        return [...tableData];
    }, [
        sortedReports,
        rowsOnPage,
        pageNumber,
    ]);

    const requestSort = (key) => {
        let direction = ascConst;
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === ascConst
        ) {
            direction = descConst;
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (name) => {
        if (!sortConfig || sortConfig.key !== name) {
            return;
        }
        if (sortConfig.direction)
            return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName;
    };

    const onDeleteReport = () => {
        MmsService.deleteReports([selectedReport.id]).then(res => {
            AlertToastr.showAlert(translation.notificationsReportDeleted);
            const newReports = reports.filter(d => d.id !== selectedReport.id);
            setReports([...newReports]);
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const handleSelectedIdChange = (reportId, checked) => {
        reports.find(p => p.id === reportId).selected = checked;
        setReports([...reports]);
    };

    const handleAllSelectedChange = (checked) => {
        const newReports = reports.map(p => {
            p.selected = checked;
            return p;
        });
        setReports(newReports);
    };

    const closePaginationMenu = () => {
        onAnchorElChange(null);
    };

    const handleRowCount = (count) => {
        setRowsOnPage(count);
        setPageNumber(1);
        closePaginationMenu();
    };

    const onNextPage = () => {
        setPageNumber(pageNumber + 1);
        window.scrollTo(0, 0);
    };

    const onPrevPage = () => {
        setPageNumber(pageNumber - 1);
        window.scrollTo(0, 0);
    };

    const handleIsActiveChanged = (checked) => {
        MmsService.setReportActivity(selectedReport.id, checked).then((response) => {
            if (response.data.isSuccessful) {
                selectedReport.isActive = checked;
                reports.find(c => c.id === selectedReport.id).isActive = checked;
                setReports([...reports]);
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    useEffect(() => {
        setPageTitle(translation.scheduledReportsPageLabel);
    }, [setPageTitle, translation]);

    const renderEmptyListText = () => {
        if (pageData && pageData.length) return null
        return (
            <p className="textGray text-center">{translation.recommendedSparePartsEmptyListPlaceholderLabel}</p>
        )
    }

    return (
        <div className="pmPageHolder">
            <div className="pmPageBlock">
                <div className="pmPageBlockTitle">{translation.scheduledReportsTabLabel}</div>
                <div className="pmTableHolder">
                    <table className="pmTable">
                        <thead>
                        <tr>
                            <th className="checkBoxCell">
                                <Checkbox
                                    checked={reports ? reports.every(p => p.selected) : false}
                                    onChange={(e) => handleAllSelectedChange(e.target.checked)}
                                    color="default"
                                    size="small"
                                    id="reportsTblCheckBoxAllRows"
                                />
                            </th>
                            <th onClick={() => requestSort('name')}
                                id="reportsTblHeaderName"
                                className={getClassNamesFor('name')}>
                                {translation.scheduledReportsPageNameHeader}</th>
                            <th onClick={() => requestSort('frequency')}
                                id="reportsTblHeaderInterval"
                                className={getClassNamesFor('frequency')}>
                                {translation.scheduledReportsPageIntervalHeader}</th>
                            <th id="reportsTblHeaderRecipients">{translation.scheduledReportsPageRecipientsHeader}</th>
                            <th onClick={() => requestSort('status')}
                                id="reportsTblHeaderStatus"
                                className={getClassNamesFor('status')} colSpan="1" style={{textAlign: 'right'}}>
                                {translation.scheduledReportsPageStatusHeader}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pageData ? pageData.map(report =>
                            <tr key={report.id}>
                                <td className="checkBoxCell">
                                    <Checkbox
                                        checked={report.selected ? report.selected : false}
                                        onChange={(e) => handleSelectedIdChange(report.id, e.target.checked)}
                                        color="default"
                                        size="small"
                                        id="reportsTblCheckBoxReport"
                                    />
                                </td>
                                <td className="text-semi-bold">
                                    {report.name}
                                </td>
                                <td>
                                    {frequencyOptions && frequencyOptions.find(x => x.item === report.frequency) ?
                                        frequencyOptions.find(x => x.item === report.frequency).name : ''}
                                </td>
                                <td>
                                    {report.recipients.join(', ').length > 30 ?
                                        `${report.recipients.join(', ').substring(0, 30)}...` :
                                        report.recipients.join(', ')}
                                </td>
                                <td>
                                    {report.isActive && <div className="statusItem on">{translation.scheduledReportsPageStatusLabelActive}</div>}
                                    {!report.isActive && <div className="statusItem off">{translation.scheduledReportsPageStatusLabelInActive}</div>}
                                </td>
                                <td className="btnCell">
                                    <IconButton aria-label="delete" size="small" className="dropDownBtn mobile"
                                                id="reportsTblDropDownMenuBtn"
                                                onClick={(e) => {
                                                    setMenuAnchorEl(e.target);
                                                    setSelectedReport(report);
                                                }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </td>
                                <Popover
                                    anchorEl={menuAnchorEl}
                                    keepMounted
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    disableScrollLock={true}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={closeMenu}
                                    className="pmPopover equipmentSubMenu"
                                >
                                    <div className="reverseSwitchHolder">
                                        <Switch
                                            checked={Boolean(selectedReport ? selectedReport.isActive : false)}
                                            onChange={e => handleIsActiveChanged(e.target.checked)}
                                            id="reportsTblMenuActiveBtn"
                                            color="primary" />
                                        <div>{translation.popoverActiveLabel}</div>
                                    </div>
                                    <MenuItem
                                        key={'edit'}
                                        id="reportsTblMenuDetailsBtn"
                                        onClick={() => {
                                            setIsOpenSaveReportModal(true);
                                            setMenuAnchorEl(null);
                                        }}
                                        className="menuItem edit"
                                    >{translation.popoverDetailsLabel}</MenuItem>
                                    <MenuItem
                                        key={'delete'}
                                        id="reportsTblMenuRemoveBtn"
                                        onClick={() => {
                                            setIsOpenDeleteModal(true);
                                            setMenuAnchorEl(null);
                                        }}
                                        className="menuItem delete"
                                    >{translation.popoverRemoveLabel}</MenuItem>
                                </Popover>
                            </tr>,
                        ) : null}
                        </tbody>
                    </table>
                    {pageData && pageData.length > 0 && <div className="tableControlsHolder">
                        <div className="sortHolder">
                            <div className="caption">Rows per page:</div>
                            <Button onClick={(e) => onAnchorElChange(e.target)}
                                    endIcon={<ArrowDropDownIcon />}
                                    id="reportsTableRowsPerPageBtn"
                                    className="dropDownBtn">
                                {rowsOnPage}
                            </Button>

                            <Popover
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={closePaginationMenu}
                                disableScrollLock={true}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                <MenuItem id="reportsTblRowCount20Btn" onClick={() => handleRowCount(20)}>20</MenuItem>
                                <MenuItem id="reportsTblRowCount50Btn" onClick={() => handleRowCount(50)}>50</MenuItem>
                                <MenuItem id="reportsTblRowCount100Btn" onClick={() => handleRowCount(100)}>100</MenuItem>
                            </Popover>
                        </div>
                        <div className="paginationHolder mms">
                            <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                            <div className="paginationBtn">
                                <IconButton
                                    disabled={firstIndex === 1 || firstIndex === 0}
                                    id="reportsTblPrevPageBtn"
                                    onClick={() => onPrevPage()}>
                                    <ArrowLeft />
                                </IconButton>
                                <IconButton
                                    disabled={lastIndex === totalRows}
                                    id="reportsTblNextPageBtn"
                                    onClick={() => onNextPage()}>
                                    <ArrowRight />
                                </IconButton>
                            </div>
                        </div>
                    </div>}
                    {reportsRequest.isLoading ? <div className="loaderHolder">
                        <Fade
                            in={reportsRequest.isLoading}
                            unmountOnExit>
                            <CircularProgress />
                        </Fade>
                    </div> : null}
                </div>
                <SaveScheduledReportsModal
                    isOpen={isOpenSaveReportModal}
                    isOpenManager={setIsOpenSaveReportModal}
                    report={selectedReport}
                />
                <DeleteItemModal isOpen={isOpenDeleteModal} isOpenManager={setIsOpenDeleteModal}
                                 message={translation.removeReportModalMessageLabel}
                                 onDeleteModalClose={(e) => {
                                     onDeleteReport();
                                 }} />
            </div>
            {renderEmptyListText()}
        </div>
    );
};

const mapStateToProps = ({ mms }) => ({
    currentDepartment: mms.currentDepartment,
    reports: mms.reports,
    statusOptions: mms.statusOptions,
});

const mapDispatchToProps = {
    setReports,
    setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReportsPage);
