export default {
    palette: {
        primary: {
            main: '#0094c2'
        }
    },
    typography: {
        fontFamily: '"Open Sans", sans-serif'
    },
    overrides: {
        // Style sheet name ⚛️
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'none'
                },
                '&.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.26)'
                }
            },
            icon: {
                color: 'rgba(255, 255, 255, 0.88)',
                '&.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.26)'
                }
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: '#383838',
                color: 'rgba(255, 255, 255, 0.88)'
            },
            elevation8: {
                boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.24)'
            }
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                borderRadius: '20px',
                lineHeight: '1',
                padding: '12px 16px',
                fontSize: '13px',
                fontWeight: '600',
                height: '36px',
                color: 'rgba(255, 255, 255, 0.88)',
                textTransform: 'uppercase',
                '&.Mui-disabled': {
                    color: 'rgba(255,255,255,.33)',
                    border: '1px solid rgba(255,255,255,.33)',
                    backgroundColor: 'transparent'
                }
            },
            text: {
                color: 'rgba(255, 255, 255, 0.88)',
                fontSize: '13px',
                padding: '8px 16px',
                borderRadius: '32px',
                textTransform: 'none',
                fontWeight: '600'
            },
            outlined: {
                boxShadow: 'none',
                borderRadius: '20px',
                lineHeight: '1',
                padding: '12px 16px',
                fontSize: '13px',
                fontWeight: '600',
                height: '36px',
                textTransform: 'uppercase',
                border: 'solid 1px rgba(255, 255, 255, 0.24)',
                color: 'rgba(255, 255, 255, 0.88)',
                '&.Mui-disabled': {
                    borderColor: 'rgba(255,255,255,.33)'
                }
            },
            root: {
                '&.Mui-disabled': {
                    color: 'rgba(255,255,255,.33)',
                }
            }
        },
        MuiTypography: {
            colorPrimary: {
                '&.linkPrimary': {
                    color: '#0094c2'
                },
                '&.linkPrimary:hover': {
                    color: '#0094c2',
                    textDecoration: 'underline'
                }
            }
        },
        MuiInputBase: {
            root: {
                color: 'rgba(255,255,255,.88)',
                '&.Mui-disabled': {
                    color: 'rgba(255,255,255,.38)',
                }
            },
            input: {
                padding: '8px',
                // height: '36px',
                boxSizing: 'border-box',
                fontSize: '13px',
                borderRadius: '6px'
            }
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px',
                height: '36px',
                color: 'rgba(255, 255, 255, 0.88)',
                '&.MuiSelect-selectMenu': {
                    height: '36px'
                },
            },
            notchedOutline: {
                borderRadius: '6px',
                border: 'solid 1px',
                borderColor: 'rgba(255, 255, 255, 0.16)!important'
            },
            root: {
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.26)'
                    }
                },
                // '&.Mui-focused': {
                //     '& .MuiOutlinedInput-notchedOutline': {
                //         borderWidth: '1px',
                //         borderColor: '#006ae7!important'
                //     }
                // }
                '&.Mui-disabled': {
                    '& > .MuiOutlinedInput-input': {
                        color: 'rgba(255, 255, 255, 0.26)!important'
                    },
                    '& > .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.06)!important',
                    }
                }
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: '4px',
                marginBottom: '8px'
            }
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 12px) scale(1)'
            },
            formControl: {}
        },
        MuiRadio: {
            root: {
                padding: '4px',
                '& .MuiSvgIcon-root': {
                    width: '20px',
                    height: '20px'
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: 'rgba(255, 255, 255, 0.56)',
                fontSize: '13px',
                // '&.Mui-focused': {
                //     color: 'rgba(255, 255, 255, 0.56)'
                // }
                '&.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.28)!important',
                }
            }
        },
        MuiFormControlLabel: {
            label: {
                '&.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.28)!important'
                }
            }
        },
        MuiDialog: {
            paperWidthSm: {
                width: '100%',
                maxWidth: '712px',
                borderRadius: '6px',
                boxShadow: '0 4px 16px 0 rgba(52, 52, 52, 0.24)',
                padding: '32px'
            }
        },
        MuiSvgIcon: {
            root: {
                color: 'rgba(255,255,255,.8)'
            }
        },

        MuiSwitch: {
            track: {
                backgroundColor: 'rgba(255,255,255,.56)'
            }
        },
        MuiPopover: {
            paper: {
                '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '5px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(255, 255, 255, 0.16)',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '3.5px',
                    boxShadow: 'none'
                },
                '&::-webkit-scrollbar-track': {
                    background: 'none'
                },
                '&::-webkit-scrollbar-button': {
                    backgroundImage: 'none',
                    backgroundRepeat: 'no-repeat',
                    width: '0',
                    height: '0'
                }
            }
        }
    }
}
