import React, { Fragment, useEffect, useMemo, useReducer, useState } from 'react';
import { Redirect } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import MmsService from 'Services/MMS/mms-api.service';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAuth } from 'Contexts/AuthProvider';
import { Checkbox, ClickAwayListener, MenuItem, Switch, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Popover from '@material-ui/core/Popover';
import AlertToastr from 'utils/alert';
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component';
import SaveComponentModal from './Modals/SaveComponentModal';
import ChangeDepartmentModal from './Modals/ChangeDepartmentModal';
import DuplicateComponentModal from './Modals/DuplicateComponentModal';
import SaveActivityModal from '../Activities/Modals/SaveActivityModal';
import CloneToEquipmentModal from '../Catalog/Modals/CloneToEquipmentModal';
import {
    setComponents,
    setComponentsCatalog,
    setCurrentPage,
    setPageTitle,
    setRspSelectedComponentsIds,
    setRunComponentsUpdate,
    updateComponentWorkhours,
} from '../../../Store/modules/mms/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import useTranslation from 'Hooks/useTranslation';
import RecommendedSparePartsComponentBlock from './RecommendedSparePartsComponentBlock';
import CompleteActivityModal from '../Activities/Modals/CompleteActivityModal';
import { useAppSettings } from '../../../Contexts/AppSettingsProvider';
import MmsAssetsSignalR from '../../../Components/SignalrSubscribes/MmsAssetsSignalR';
import { useEmployeesByDepartmentId } from '../../../Hooks/mms/useEmployees';
// import { listToTree } from '../../../helpers';

const descConst = 'descending';
const ascConst = 'ascending';
const arrowDownClassName = 'hasArrow';
const arrowUpClassName = 'hasArrow up';
const ArrowLeft = () => <div className="arrowLeftIcon" />;
const ArrowRight = () => <div className="arrowRightIcon" />;

const EquipmentComponent = props => {
    const {
        isCatalog,
        currentCompany,
        currentDepartment,
        employees,
        setComponents,
        updateComponentWorkhours,
        components,
        setCurrentPage,
        runComponentsUpdate,
        setRunComponentsUpdate,
        setComponentsCatalog,
        componentsCatalog,
        setPageTitle,
        setRspSelectedComponentsIds,
    } = props;

    const { token } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenSaveComponentModal, setIsOpenSaveComponentModal] = useState(false);
    const [isOpenCloneToEquipmentModal, setIsOpenCloneToEquipmentModal] = useState(false);
    const [parentComponentId, setParentComponentId] = useState(null);
    const [parentWorkhours, setParentWorkhours] = useState(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenDeleteActivityModal, setIsOpenDeleteActivityModal] = useState(false);
    const [isOpenChangeDepartmentModal, setIsOpenChangeDepartmentModal] = useState(false);
    const [isOpenDuplicateComponentModal, setIsOpenDuplicateComponentModal] = useState(false);
    const [isOpenSaveActivityModal, setIsOpenSaveActivityModal] = useState(false);
    const [isOpenCompleteActivityModal, setIsOpenCompleteActivityModal] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [anchorEl, onAnchorElChange] = useState(null);
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [priorityOptions, setPriorityOptions] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const { currentUser } = useAuth();
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser]);
    const [openedComponentsIds, setOpenedComponentsIds] = useState([]);
    const [firstIndex, setFirstIndex] = useState(0);
    const [lastIndex, setLastIndex] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsOnPage, setRowsOnPage] = useState(15);
    const [pageNumber, setPageNumber] = useState(1);
    const translation = useTranslation();
    const { appLanguage } = useAppSettings();
    const [employeesOptions, setEmployeesOptions] = useState({ fromDepartment: [], other: [] })

    const departmentEmployees = useMemo(() => {
        if (employees && currentDepartment)
            return employees.filter(e => e.departmentId === currentDepartment.id);
        else
            return null;
    }, [employees, currentDepartment]);

    const closePopover = () => {
        setMenuAnchorEl(null);
    };

    const componentList = useMemo(() => {
        const sortedComponents = isCatalog ? componentsCatalog : components;
        if (!sortedComponents)
            return [];
        if (!sortConfig || !sortConfig.key)
            return sortedComponents;

        if (sortConfig.key === 'activities') {
            sortedComponents.sort((a, b) => {
                if (a.activities.length < b.activities.length) {
                    return sortConfig.direction === ascConst ? -1 : 1;
                }
                if (a.activities.length > b.activities.length) {
                    return sortConfig.direction === ascConst ? 1 : -1;
                }
                return 0;
            });
            return [...sortedComponents];
        }
        sortedComponents.sort((a, b) => {
            const aVal = typeof a[sortConfig.key] === 'string' ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
            const bVal = typeof b[sortConfig.key] === 'string' ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];


            if (aVal < bVal) {
                return sortConfig.direction === ascConst ? -1 : 1;
            }
            if (aVal > bVal) {
                return sortConfig.direction === ascConst ? 1 : -1;
            }
            return 0;
        });
        return [...sortedComponents];
    }, [isCatalog, componentsCatalog, components, sortConfig]);

    // const componentsTree = useMemo(() => {
    //     return listToTree(componentList);
    // }, [componentList]);

    const pageComponentList = useMemo(() => {
        let pageComponentList = [...componentList];
        const parentComponents = pageComponentList.filter(c => !c.parentComponentId);
        const totalComponentsLength = parentComponents.length;

        if ((pageNumber - 1) * rowsOnPage + 1 > totalComponentsLength) {
            setPageNumber(1);
        }
        setTotalRows(totalComponentsLength);
        setFirstIndex(Math.min(((pageNumber - 1) * rowsOnPage + 1), totalComponentsLength));
        setLastIndex(Math.min((pageNumber * rowsOnPage), totalComponentsLength));
        let tableComponents = pageComponentList.filter(x =>
            x.parentComponentId ||
            (parentComponents.indexOf(x) >= (pageNumber - 1) * rowsOnPage &&
                parentComponents.indexOf(x) < Math.min((pageNumber * rowsOnPage), totalComponentsLength)),
        );
        return [...tableComponents];
    }, [
        componentList,
        rowsOnPage,
        pageNumber,
    ]);

    //TODO: not working
    useEffect(() => {
        if (currentDepartment) {
            if (isCatalog)
                setComponentsCatalog([]);
            else
                setComponents([]);
        }
    }, [currentDepartment, isCatalog, setComponents, setComponentsCatalog]);

    useEffect(() => {
        isCatalog ? setPageTitle(translation.catalogPageLabel) : setPageTitle(translation.equipmentPageLabel);
    }, [setPageTitle, translation, isCatalog]);

    const addSubComponents = (x, updatedComponents, state) => {
        if (openedComponentsIds.includes(x.id)) {
            const subComponents = pageComponentList.filter(c => c.parentComponentId === x.id);
            if (subComponents.length === 0)
                return updatedComponents;
            else {
                const modelSubComponents = state.filter(c => c.parentComponentId === x.id);
                const index = updatedComponents.findIndex(c => c.id === x.id);
                if (modelSubComponents.length === 0) {
                    subComponents.forEach((sc, i) => {
                        const newComponent = {
                            ...sc,
                            newWorkhoursTotal: sc.workhoursTotal,
                            nestingLevel: x.nestingLevel + 1,
                        };
                        updatedComponents.splice(index + i + 1, 0, newComponent);
                        addSubComponents(newComponent, updatedComponents, state);

                    });
                } else {
                    const nextIndex = updatedComponents.findIndex((c, i) => c.nestingLevel <= x.nestingLevel && i > index);
                    const lastIndex = nextIndex === -1 ? updatedComponents.length : nextIndex;
                    if (lastIndex)
                        updatedComponents.splice(index + 1, lastIndex - index - 1);
                }
            }
        }
    };

    const openComponents = (state) => {
        let updatedComponents = [...state];
        const oldComponents = [...state];
        oldComponents.forEach(x => {
            addSubComponents(x, updatedComponents, state);
        });
        return [...updatedComponents];
    };

    const getDate = (activity) => {
        if (!activity)
            return '';
        if (activity.expectedWorkhoursDate && !activity.expectedCompleteDate)
            return moment(activity.expectedWorkhoursDate).format('L');
        if (!activity.expectedWorkhoursDate && activity.expectedCompleteDate)
            return moment(activity.expectedCompleteDate).format('L');
        if (!activity.expectedWorkhoursDate && !activity.expectedCompleteDate)
            return '';
        if (moment(activity.expectedWorkhoursDate).isAfter(activity.expectedCompleteDate))
            return moment(activity.expectedCompleteDate).format('L');
        else
            return moment(activity.expectedWorkhoursDate).format('L');
    };

    const sortActivities = (activities, sortConfig) => {
        if (!activities)
            return [];
        let sortedActivities = [...activities];
        if (!sortConfig || !sortConfig.key)
            return sortedActivities;
        if (sortConfig.key === 'priority') {
            sortedActivities.sort((a, b) => {
                if ((priorityOptions.findIndex(x => x.priority === a.priority)) < (priorityOptions.findIndex(x => x.priority === b.priority))) {
                    return sortConfig.direction === ascConst ? -1 : 1;
                }
                if ((priorityOptions.findIndex(x => x.priority === a.priority)) > (priorityOptions.findIndex(x => x.priority === b.priority))) {
                    return sortConfig.direction === ascConst ? 1 : -1;
                }
                return 0;
            });
            return sortedActivities;
        }
        if (sortConfig.key === 'tasks') {
            sortedActivities.sort((a, b) => {
                if (a.tasks.length < b.tasks.length) {
                    return sortConfig.direction === ascConst ? -1 : 1;
                }
                if (a.tasks.length > b.tasks.length) {
                    return sortConfig.direction === ascConst ? 1 : -1;
                }
                return 0;
            });
            return sortedActivities;
        }
        if (sortConfig.key === 'date') {
            sortedActivities.sort((a, b) => {
                if (moment(getDate(a)).isAfter(getDate(b))) {
                    return sortConfig.direction === ascConst ? -1 : 1;
                }
                if (moment(getDate(b)).isAfter(getDate(a))) {
                    return sortConfig.direction === ascConst ? 1 : -1;
                }
                return 0;
            });
            return sortedActivities;
        }
        if (sortConfig.key === 'workhours') {
            sortedActivities.sort((a, b) => {
                if ((a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) < (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)) {
                    return sortConfig.direction === ascConst ? -1 : 1;
                }
                if ((a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) > (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)) {
                    return sortConfig.direction === ascConst ? 1 : -1;
                }
                return 0;
            });
            return sortedActivities;
        }
        sortedActivities.sort((a, b) => {
            if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === ascConst ? -1 : 1;
            }
            if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === ascConst ? 1 : -1;
            }
            return 0;
        });
        return sortedActivities;
    };

    const requestSortActivities = (key, compModel) => {
        if (!key)
            return;
        dispatchComponentsModel({ type: 'sortChanged', compModel: compModel, key: key });
    };

    const getClassNamesForActivities = (name, sortConfig) => {
        if (!sortConfig || sortConfig.key !== name) {
            return;
        }
        if (sortConfig.direction)
            return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName;
    };

    const closeMenu = () => {
        onAnchorElChange(null);
    };

    const handleRowCount = (count) => {
        setRowsOnPage(count);
        setPageNumber(1);
        closeMenu();
    };

    const onNextPage = () => {
        setPageNumber(pageNumber + 1);
        window.scrollTo(0, 0);
    };

    const onPrevPage = () => {
        setPageNumber(pageNumber - 1);
        window.scrollTo(0, 0);
    };

    const componentsModelReducer = (state, action) => {
        switch (action.type) {
            case 'updated':
                if (!pageComponentList || pageComponentList.length === 0)
                    return [];
                let updatedState = [];
                const filteredComponentList = pageComponentList.map(c => {
                    if (!c.parentComponentId && !updatedState.some(comp => c.id === comp.id)) {
                        updatedState.push({
                            ...c,
                            newWorkhoursTotal: c.workhoursTotal,
                            nestingLevel: 0,
                        });
                    } else if (c.parentComponentId && updatedState.some(comp => comp.parentComponentId === c.parentComponentId) && updatedState.every(comp => comp.id !== c.id)) {
                        let subcomponents = updatedState.filter(comp => c.parentComponentId === comp.parentComponentId);
                        const index = updatedState.indexOf(subcomponents[subcomponents.length - 1]);
                        const componentWorkhours = updatedState.find(comp => c.id === comp.id) ? updatedState.find(comp => c.id === comp.id).workhoursTotal : 0;
                        updatedState.splice(index, 0, {
                            ...c,
                            newWorkhoursTotal: componentWorkhours,
                            nestingLevel: c.nestingLevel + 1,
                        });
                    }
                    return { ...c, nestingLevel: 0, newWorkhoursTotal: c.workhoursTotal };
                });
                return openComponents(filteredComponentList.filter(c => !c.parentComponentId));
            case 'clicked':
                let updatedComponents = [...state];
                const subComponents = pageComponentList.filter(c => c.parentComponentId === action.compModel.id);

                // clicked item has no child items: do nothing
                if (subComponents.length === 0) return state;

                const modelSubComponents = state.filter(c => c.parentComponentId === action.compModel.id);
                const index = updatedComponents.findIndex(x => x.id === action.compModel.id);

                setOpenedComponentsIds(prevIds => prevIds.includes(action.compModel.id) ? [] : [action.compModel.id]);

                if (modelSubComponents.length === 0) {
                    subComponents.forEach((sc, i) => {
                        updatedComponents.splice(index + i + 1, 0, {
                            ...sc,
                            newWorkhoursTotal: sc.workhoursTotal,
                            nestingLevel: action.compModel.nestingLevel + 1,
                        });
                    });
                    // setOpenedComponentsIds(openedComponentsIds => {
                    //     if (!openedComponentsIds.includes(action.compModel.id)) {
                    //         return [...openedComponentsIds, action.compModel.id];
                    //     }
                    //     return openedComponentsIds;
                    // });
                } else {
                    const nextIndex = updatedComponents.findIndex((c, i) => c.nestingLevel <= action.compModel.nestingLevel && i > index);
                    const lastIndex = nextIndex === -1 ? updatedComponents.length : nextIndex;
                    if (lastIndex) {
                        updatedComponents.splice(index + 1, lastIndex - index - 1);
                        // setOpenedComponentsIds(openedComponentsIds.filter((x, i) => i > index && i < lastIndex));
                    }
                }

                return updatedComponents;
            case 'updatedComponentModel':
                const newComponents = [...state];
                const editedComponent = newComponents.find(c => c.id === action.compModel.id);
                editedComponent.newWorkhoursTotal = action.compModel.newWorkhoursTotal;
                editedComponent.workhoursTotal = action.compModel.workhoursTotal;
                return newComponents;
            case 'sortChanged':
                const sortedComponents = [...state];
                const currentComponent = sortedComponents.find(c => c.id === action.compModel.id);
                let direction = ascConst;
                if (
                    currentComponent.sortConfig &&
                    currentComponent.sortConfig.key === action.key &&
                    currentComponent.sortConfig.direction === ascConst
                ) {
                    direction = descConst;
                }
                currentComponent.sortConfig = { key: action.key, direction: direction };
                return sortedComponents;
            default:
                return [];
        }
    };

    const [componentsModel, dispatchComponentsModel] = useReducer(componentsModelReducer, []);


    const selectedComponentsIds = useMemo(() => {

        if (componentsModel.length)
            return componentsModel.filter(item => !item.parentComponentId && item.selected).map(item => item.id);
        return [];
    }, [componentsModel]);


    useEffect(() => {
        setRspSelectedComponentsIds(selectedComponentsIds);
    }, [
        selectedComponentsIds,
        setRspSelectedComponentsIds,
    ]);

    useEffect(() => {
        dispatchComponentsModel({ type: 'updated' });
    }, [pageComponentList]);

    const handleRowClicked = (compModel) => {
        dispatchComponentsModel({ type: 'clicked', compModel: compModel });
    };

    useEffect(() => {
        const fetchComponents = async () => {
            try {
                const response = await MmsService.getComponents(currentDepartment.id);
                setIsLoading(false);
                setComponents(response.data);
            } catch (error) {
                setIsLoading(false);
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            }
        };

        const fetchComponentsCatalog = async () => {
            try {
                const response = await MmsService.getComponentsCatalog();
                setComponentsCatalog(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            }
        };
        if (isCatalog) {
            setCurrentPage('components');
            fetchComponentsCatalog();
        } else {
            setCurrentPage('equipment');
            if (!currentDepartment)
                return;
            fetchComponents();
        }
    }, [setComponents, currentCompany, isSuperUser, currentDepartment, setCurrentPage, runComponentsUpdate, isCatalog, setComponentsCatalog]);

    useEffect(() => {
        const fetchOptions = async () => {
            let typeResponse = await MmsService.getTypesOptions(appLanguage);
            setTypeOptions(typeResponse.data);
            let priorityResponse = await MmsService.getPriorityOptions(appLanguage);
            setPriorityOptions(priorityResponse.data);
        };
        fetchOptions();
    }, [appLanguage]);

    const onDeleteComponent = () => {
        MmsService.deleteComponent(selectedComponent.id).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(translation.notificationsComponentDeleted);
                setRunComponentsUpdate(Math.random());
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const requestSort = (key) => {
        let direction = ascConst;
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === ascConst
        ) {
            direction = descConst;
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (name) => {
        if (!sortConfig || sortConfig.key !== name) {
            return;
        }
        if (sortConfig.direction)
            return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName;
    };

    const onDeleteActivity = () => {
        MmsService.deleteActivity(selectedActivity.id).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(translation.notificationsActivityDeleted);
                if (isCatalog) {
                    const componentIndex = componentsCatalog.findIndex(c => c.id === selectedComponent.id);
                    const newActivities = [...componentsCatalog[componentIndex].activities];
                    const activityindex = newActivities.findIndex(a => a.id === selectedActivity.id);
                    newActivities.splice(activityindex, 1);
                    componentsCatalog.splice(componentIndex, 1, {
                        ...componentsCatalog[componentIndex],
                        activities: [...newActivities],
                    });
                    setComponentsCatalog([...componentsCatalog]);
                } else {
                    const componentIndex = components.findIndex(c => c.id === selectedComponent.id);
                    const newActivities = [...components[componentIndex].activities];
                    const activityindex = newActivities.findIndex(a => a.id === selectedActivity.id);
                    newActivities.splice(activityindex, 1);
                    components.splice(componentIndex, 1, {
                        ...components[componentIndex],
                        activities: [...newActivities],
                    });
                    setComponents([...components]);
                }
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const addSubcomponent = (component) => {
        setMenuAnchorEl(null);
        setParentComponentId(component.id);
        setParentWorkhours(component.workhoursTotal);
        setSelectedComponent(null);
        setIsOpenSaveComponentModal(true);
    };

    const editComponent = (component) => {
        const parentComponent = componentList.find(c => c.id === component.parentComponentId);
        setParentWorkhours(parentComponent ? parentComponent.workhoursTotal : 0);
        setMenuAnchorEl(null);
        setIsOpenSaveComponentModal(true);
    };

    const handleSelectedIdChange = (componentId, checked) => {
        componentList.find(c => c.id === componentId).selected = checked;
        if (isCatalog)
            setComponentsCatalog([...componentList]);
        else
            setComponents([...componentList]);
    };

    const handleAllSelectedChange = (checked) => {
        const newComponents = componentList.map(c => {
            if (!c.parentComponentId)
                c.selected = checked;
            return c;
        });
        if (isCatalog)
            setComponentsCatalog([...newComponents]);
        else
            setComponents([...newComponents]);
    };

    const handleSelectedShowActivityChange = (componentId) => {
        const selectedComponent = componentList.find(c => c.id === componentId);
        selectedComponent.isShownActivity = selectedComponent.isShownActivity ? false : true;
        if (isCatalog)
            setComponentsCatalog([...componentList]);
        else
            setComponents([...componentList]);
    };

    const handleIsActiveChanged = (checked) => {
        selectedComponent.isActive = checked;
        MmsService.setComponentActivity(selectedComponent.id, checked).then((response) => {
            if (response.data.isSuccessful) {
                componentList.find(c => c.id === selectedComponent.id).isActive = checked;
                if (isCatalog)
                    setComponentsCatalog([...componentList]);
                else
                    setComponents([...componentList]);
            }
        })
            .catch((error) => {
                selectedComponent.isActive = !checked;
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const handleLiveData = (data) => {
        updateComponentWorkhours(data);
    };

    const employeesByDepartmentIdRequest = useEmployeesByDepartmentId(currentDepartment?.id)

    useEffect(() => {
      if (employeesByDepartmentIdRequest.isSuccess) {
        setEmployeesOptions(employeesByDepartmentIdRequest.data.data)
      }
    }, [
      employeesByDepartmentIdRequest.isSuccess, employeesByDepartmentIdRequest.data
    ])

    if (!isSuperUser && isCatalog)
        return <Redirect to="/companies" />;


    return (
        <>
            <div className="pmTableHolder">
                <table className="pmTable">
                    <thead>
                    <tr>
                        <th className="checkBoxCell">
                            <Checkbox
                                checked={componentList ? componentList.filter(c => !c.parentComponentId).every(c => c.selected) : false}
                                onChange={(e) => handleAllSelectedChange(e.target.checked)}
                                color="default"
                                size="small"
                                id="templatesTblCheckBoxAllRows"
                            />
                        </th>
                        <th onClick={() => requestSort('name')}
                            id="templatesTblHeaderDescription"
                            className={getClassNamesFor('name')}>
                            {translation.equipmentPageMyEquipmentNameHeader}
                        </th>
                        <th onClick={() => requestSort('id')}
                            id="templatesTblHeaderID"
                            className={getClassNamesFor('id')}>
                            {translation.equipmentPageMyEquipmentIdHeader}
                        </th>
                        {isCatalog && <th onClick={() => requestSort('manufacturerName')}
                                          className={getClassNamesFor('manufacturerName')}>
                            {translation.equipmentPageMyEquipmentManufacturerHeader}
                        </th>}
                        {!isCatalog && <th onClick={() => requestSort('workhoursTotal')}
                                           id="templatesTblHeaderWorkhours"
                                           className={getClassNamesFor('workhoursTotal')}>
                            {translation.equipmentPageMyEquipmentWorkhoursTotalHeader}
                        </th>}
                        <th onClick={() => requestSort('activities')}
                            id="templatesTblHeaderActivities"
                            className={getClassNamesFor('activities')}>
                            {translation.equipmentPageMyEquipmentActivitiesHeader}
                        </th>
                        <th colSpan="2" style={{textAlign: 'right'}} onClick={() => requestSort('isActive')}
                            id="templatesTblHeaderOperationalStatus"
                            className={getClassNamesFor('isActive')}>
                            {translation.equipmentPageMyEquipmentStatusHeader}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {componentsModel.length > 0 ? componentsModel.map(compModel => {
                            return (
                                <Fragment key={compModel.id}>
                                    <tr className={compModel.parentComponentId ? 'innerRow' : ''}>
                                        <td className="checkBoxCell">
                                            {!compModel.parentComponentId && <Checkbox
                                                checked={compModel.selected ? compModel.selected : false}
                                                onChange={(e) => handleSelectedIdChange(compModel.id, e.target.checked)}
                                                color="default"
                                                size="small"
                                                id="templatesTblCheckBoxTemplate"
                                            />}
                                        </td>
                                        <td className="text-semi-bold" onClick={(e) => handleRowClicked(compModel)}>
                                            {compModel.parentComponentId && <div className="hasInnerRowIcon"
                                                                                 style={{ paddingLeft: compModel.nestingLevel * 15 }}>
                                                <div className="icoInnerRow" />
                                                <div className="caption">
                                                    {`${compModel.name}`}
                                                </div>
                                            </div>}
                                            {!compModel.parentComponentId && `${compModel.name}`}
                                        </td>
                                        <td onClick={(e) => handleRowClicked(compModel)}>{compModel.number}</td>
                                        {!isCatalog && <td className="textFieldCell">
                                            <ClickAwayListener onClickAway={(e) => {
                                                if (compModel.newWorkhoursTotal === compModel.workhoursTotal || selectedComponent?.id !== compModel.id)
                                                    return;
                                                MmsService.updateComponentWorkHours(compModel.id, compModel.newWorkhoursTotal).then(response => {
                                                    if (response.data.isSuccessful) {
                                                        setRunComponentsUpdate(Math.random());
                                                    }
                                                })
                                                    .catch((error) => {
                                                        dispatchComponentsModel({
                                                            type: 'updatedComponentModel',
                                                            compModel: {
                                                                ...compModel,
                                                                newWorkhoursTotal: compModel.workhoursTotal,
                                                            },
                                                        });
                                                        if (error && error.response && error.response.data && error.response.data.message)
                                                            AlertToastr.showErrorAlert(error.response.data.message);
                                                    });
                                            }}>
                                                <TextField
                                                    disabled={compModel.workhoursUpdateType !== 'manual'}
                                                    type="number"
                                                    inputProps={{ min: 0, max: 10000000000000 }}
                                                    value={compModel.newWorkhoursTotal}
                                                    onChange={(e) => {
                                                        setSelectedComponent(componentList.find(c => c.id === compModel.id));
                                                        if (Number(e.target.value) >= e.target.min && Number(e.target.value) <= e.target.max)
                                                            dispatchComponentsModel({
                                                                type: 'updatedComponentModel',
                                                                compModel: {
                                                                    ...compModel,
                                                                    newWorkhoursTotal: e.target.value,
                                                                },
                                                            });
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </ClickAwayListener>
                                        </td>}
                                        {isCatalog &&
                                            <td onClick={(e) => handleRowClicked(compModel)}>{compModel.manufacturerName}</td>}
                                        <td className="textBtnCell">
                                            <Button className="activitiesItem" id="templatesTblActivityShowMenuBtn"
                                                    onClick={(e) => handleSelectedShowActivityChange(compModel.id)}>{`${translation.equipmentPageMyEquipmentActivitiesLabel} (${compModel.activities ? compModel.activities.length : 0})`}</Button>
                                        </td>
                                        {!compModel.parentComponentId && <td onClick={(e) => handleRowClicked(compModel)}>
                                        {compModel.isActive && <div className="statusItem on">{translation.equipmentPageMyEquipmentStatusLabelActive}</div>}
                                            {!compModel.isActive && <div className="statusItem off">{translation.equipmentPageMyEquipmentStatusLabelInActive}</div>}
                                        </td>}
                                        {compModel.parentComponentId && <td>
                                        </td>}
                                        <td className="btnCell">
                                            <IconButton aria-label="delete" size="small" className="dropDownBtn mobile" id="templatesTblDropDownBtn"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setMenuAnchorEl(e.target);
                                                            setSelectedComponent(componentList.find(c => c.id === compModel.id));
                                                        }}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </td>
                                    </tr>
                                    {compModel.isShownActivity && <tr className="activitiesRow">
                                        <td colSpan="100" className="activitiesRowContent">
                                            <table className="activitiesTable">
                                                <thead>
                                                <tr>
                                                    <th onClick={() => requestSortActivities('type', compModel)}
                                                        id="templatesTblActivityTblHeaderTypeBtn"
                                                        className={getClassNamesForActivities('type', compModel.sortConfig)}>{translation.equipmentPageActivitiesTypeHeader}</th>
                                                    <th onClick={() => requestSortActivities('name', compModel)}
                                                        id="templatesTblActivityTblHeaderNameBtn"
                                                        className={getClassNamesForActivities('name', compModel.sortConfig)}>{translation.equipmentPageActivitiesDescriptionHeader}</th>
                                                    <th onClick={() => requestSortActivities('priority', compModel)}
                                                        id="templatesTblActivityTblHeaderPriorityBtn"
                                                        className={getClassNamesForActivities('priority', compModel.sortConfig)}>{translation.equipmentPageActivitiesPriorityHeader}</th>
                                                    <th onClick={() => requestSortActivities('tasks', compModel)}
                                                        id="templatesTblActivityTblHeaderTasksBtn"
                                                        className={getClassNamesForActivities('tasks', compModel.sortConfig)}>{translation.equipmentPageActivitiesTasksHeader}</th>
                                                    <th onClick={() => requestSortActivities('date', compModel)}
                                                        id="templatesTblActivityTblHeaderDateBtn"
                                                        className={getClassNamesForActivities('date', compModel.sortConfig)}>{translation.equipmentPageActivitiesDateHeader}</th>
                                                    <th onClick={() => requestSortActivities('workhours', compModel)}
                                                        id="templatesTblActivityTblHeaderWorkhoursBtn"
                                                        className={getClassNamesForActivities('workhours', compModel.sortConfig)}>{translation.equipmentPageActivitiesWorkhoursHeader}</th>
                                                    <th>
                                                        <div className="addActivityBtn"
                                                            id="templatesTblActivityAddActivityBtn"
                                                             onClick={(e) => {
                                                                 if (!isSuperUser && isCatalog)
                                                                     return;
                                                                 setSelectedComponent(componentList.find(c => c.id === compModel.id));
                                                                 setIsOpenSaveActivityModal(true);
                                                                 setSelectedActivity(null);
                                                             }}>
                                                            <div className="amPlIcon" />
                                                            {translation.equipmentPageActivitiesAddActivityButton}
                                                        </div>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {compModel.activities && sortActivities(compModel.activities, compModel.sortConfig).map(activity =>
                                                    <tr key={activity.id}>
                                                        <td>{typeOptions.find(x => x.activityType === activity.type) ? typeOptions.find(x => x.activityType === activity.type).name : activity.type}</td>
                                                        <td>{activity.name}</td>
                                                        <td>{priorityOptions.find(x => x.priority === activity.priority) ? priorityOptions.find(x => x.priority === activity.priority).name : activity.priority}</td>
                                                        <td>{activity.tasks.length}</td>
                                                        <td>{getDate(activity)}</td>
                                                        <td>{activity.expectedWorkhours}</td>
                                                        <td></td>
                                                        <td className="btnCell">
                                                            <IconButton aria-label="delete" size="small"
                                                                        className="dropDownBtn mobile"
                                                                        disabled={!isSuperUser && isCatalog}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setActivityAnchorEl(e.target);
                                                                            setSelectedActivity(activity);
                                                                            setSelectedComponent(componentList.find(c => c.id === compModel.id));
                                                                        }}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>}
                                </Fragment>
                            );
                        },
                    ) : null}
                    </tbody>
                </table>
                {isLoading ? <div className="loaderHolder">
                    <Fade
                        in={isLoading}
                        unmountOnExit>
                        <CircularProgress />
                    </Fade>
                </div> : null}
            </div>
            {componentsModel && componentsModel.length > 0 && <div className="tableControlsHolder">
                <div className="sortHolder">
                    <div className="caption">Rows per page:</div>
                    <Button onClick={(e) => onAnchorElChange(e.target)}
                            endIcon={<ArrowDropDownIcon />}
                            id="templatesTableRowsPerPageBtn"
                            className="dropDownBtn">
                        {rowsOnPage}
                    </Button>

                    <Popover
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                        disableScrollLock={true}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <MenuItem id="templatesTblRowCount20Btn" onClick={() => handleRowCount(15)}>15</MenuItem>
                        <MenuItem id="templatesTblRowCount50Btn" onClick={() => handleRowCount(50)}>50</MenuItem>
                        <MenuItem id="templatesTblRowCount100Btn" onClick={() => handleRowCount(100)}>100</MenuItem>
                    </Popover>
                </div>
                <div className="paginationHolder mms">
                    <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                    <div className="paginationBtn">
                        <IconButton
                            disabled={firstIndex === 1 || firstIndex === 0}
                            id="templatesTblPrevPageBtn"
                            onClick={() => onPrevPage()}>
                            <ArrowLeft />
                        </IconButton>
                        <IconButton
                            disabled={lastIndex === totalRows}
                            id="templatesTblNextPageBtn"
                            onClick={() => onNextPage()}>
                            <ArrowRight />
                        </IconButton>
                    </div>
                </div>
            </div>}
            <Popover
                anchorEl={menuAnchorEl}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                disableScrollLock={true}
                open={Boolean(menuAnchorEl)}
                onClose={closePopover}
                className="pmPopover equipmentSubMenu"
            >
                {(isSuperUser || !isCatalog) && selectedComponent && !selectedComponent?.parentComponentId &&
                    <div className="reverseSwitchHolder">
                        <Switch
                            checked={Boolean(selectedComponent ? selectedComponent.isActive : false)}
                            id="templatesTblMenuActiveBtn"
                            onChange={e => handleIsActiveChanged(e.target.checked)}
                            color="primary" />
                        <div>{translation.popoverActiveLabel}</div>
                    </div>}
                {(isSuperUser || !isCatalog) && <MenuItem
                    key={'add'}
                    id="templatesTblMenuAddComponentBtn"
                    onClick={() => addSubcomponent(selectedComponent)}
                    className="menuItem add"
                >{translation.popoverAddSubcomponentLabel}</MenuItem>}
                {(isSuperUser || !isCatalog) && <MenuItem
                    key={'edit'}
                    onClick={() => editComponent(selectedComponent)}
                    id="templatesTblMenuDetailsBtn"
                    className="menuItem edit"
                >{translation.popoverDetailsLabel}</MenuItem>}
                {(isSuperUser || !isCatalog) && selectedComponent && !selectedComponent?.parentComponentId &&
                    <MenuItem
                        key={'duplicate'}
                        id="templatesTblMenuDuplicateBtn"
                        onClick={() => {
                            setIsOpenDuplicateComponentModal(true);
                            setMenuAnchorEl(null);
                        }}
                        className="menuItem duplicate"
                    >{translation.popoverDuplicateLabel}</MenuItem>}
                {!isCatalog && selectedComponent && !selectedComponent?.parentComponentId && <MenuItem
                    key={'change'}
                    onClick={() => {
                        setIsOpenChangeDepartmentModal(true);
                        setMenuAnchorEl(null);
                    }}
                    className="menuItem change"
                >{translation.popoverChangeDepartmentLabel}</MenuItem>}
                {(isSuperUser || !isCatalog) && <MenuItem
                    key={'delete'}
                    id="templatesTblMenuRemoveBtn"
                    onClick={() => {
                        setIsOpenDeleteModal(true);
                        setMenuAnchorEl(null);
                    }}
                    className="menuItem delete"
                >{translation.popoverRemoveLabel}</MenuItem>}
                {!isSuperUser && isCatalog && <>
                    <MenuItem
                        key={'take'}
                        onClick={() => {
                            setIsOpenCloneToEquipmentModal(true);
                            setMenuAnchorEl(null);
                        }}
                        className="menuItem change"
                    >{translation.popoverTakeToMyEquipmentLabel}</MenuItem>
                    <MenuItem
                        key={'show'}
                        onClick={() => editComponent(selectedComponent)}
                        className="menuItem edit"
                    >{translation.popoverShowDetailsLabel}</MenuItem>
                </>}
            </Popover>
            <Popover
                anchorEl={activityAnchorEl}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                disableScrollLock={true}
                open={Boolean(activityAnchorEl)}
                onClose={e => setActivityAnchorEl(null)}
                className="pmPopover equipmentSubMenu"
            >
                <MenuItem
                    disabled={selectedActivity && selectedActivity.status !== 'opened'}
                    key={'edit'}
                    onClick={() => {
                        setActivityAnchorEl(null);
                        setIsOpenSaveActivityModal(true);
                    }}
                    className="menuItem edit"
                >{translation.popoverDetailsLabel}</MenuItem>
                <MenuItem
                    key={'delete'}
                    onClick={() => {
                        setIsOpenDeleteActivityModal(true);
                        setActivityAnchorEl(null);
                    }}
                    className="menuItem delete"
                >{translation.popoverRemoveLabel}</MenuItem>
            </Popover>
            <SaveComponentModal
                open={isOpenSaveComponentModal}
                handleClose={(e) => setIsOpenSaveComponentModal(false)}
                component={selectedComponent}
                parentComponentId={parentComponentId}
                openSubcomponents={(id) => {
                    if (componentsModel.filter(c => c.parentComponentId === id).length === 0 && componentsModel.find(x => x.id === id))
                        dispatchComponentsModel({ type: 'clicked', compModel: componentsModel.find(x => x.id === id) });
                }}
                parentWorkhours={parentWorkhours}
                isCatalog={isCatalog}
                isReadOnly={!isSuperUser && isCatalog}
            />
            <ChangeDepartmentModal
                open={isOpenChangeDepartmentModal}
                handleClose={(e) => setIsOpenChangeDepartmentModal(false)}
                department={currentDepartment}
                componentIds={[selectedComponent?.id]}
            />
            <DuplicateComponentModal
                isOpen={isOpenDuplicateComponentModal}
                isOpenManager={setIsOpenDuplicateComponentModal}
                componentIds={[selectedComponent?.id]}
                departmentId={currentDepartment?.id}
                isCatalog={isCatalog}
            />
            <CloneToEquipmentModal
                isOpen={isOpenCloneToEquipmentModal}
                isOpenManager={setIsOpenCloneToEquipmentModal}
                ids={[selectedComponent?.id]}
                departmentId={currentDepartment?.id}
                instance={'components'} />
            <DeleteItemModal
                isOpen={isOpenDeleteModal}
                isOpenManager={setIsOpenDeleteModal}
                message={translation.removeComponentModalMessageLabel}
                onDeleteModalClose={(e) => {
                    onDeleteComponent();
                }} />
            <SaveActivityModal
                isOpen={isOpenSaveActivityModal}
                isOpenManager={setIsOpenSaveActivityModal}
                isCatalog={isCatalog}
                componentId={selectedComponent?.id}
                activity={selectedActivity}
                componentWorkHours={selectedComponent?.workhoursTotal}
            />
            <CompleteActivityModal
                employeesOptions={employeesOptions}
                isOpen={isOpenCompleteActivityModal}
                isOpenManager={setIsOpenCompleteActivityModal}
                activity={selectedActivity}
                employees={departmentEmployees}
                handleCompleteUpdate={async () => {
                    try {
                        const response = await MmsService.getComponents(currentDepartment.id);
                        setIsLoading(false);
                        setComponents(response.data);
                    } catch (error) {
                        setIsLoading(false);
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message);
                    }
                }} />
            <DeleteItemModal
                isOpen={isOpenDeleteActivityModal}
                isOpenManager={setIsOpenDeleteActivityModal}
                message={translation.removeActivityModalMessageLabel}
                onDeleteModalClose={(e) => {
                    onDeleteActivity();
                }} />

            {
                !isCatalog && <RecommendedSparePartsComponentBlock rspSelectedComponentsIds={selectedComponentsIds} />
            }
            {
                currentDepartment && <MmsAssetsSignalR
                    departmentId={currentDepartment?.id}
                    onIncomingData={handleLiveData}
                    token={token}
                    companyId={currentCompany?.id}
                />
            }

        </>
    );
};
const mapStateToProps = ({ mms, dashboard }) => ({
    components: mms.components,
    currentCompany: dashboard.currentCompany,
    currentDepartment: mms.currentDepartment,
    runComponentsUpdate: mms.runComponentsUpdate,
    componentsCatalog: mms.componentsCatalog,
    employees: mms.employees,
});

const mapDispatchToProps = {
    setComponents,
    updateComponentWorkhours,
    setCurrentPage,
    setRunComponentsUpdate,
    setComponentsCatalog,
    setPageTitle,
    setRspSelectedComponentsIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentComponent);
