export default {
    user: {
        id: '1',
        name: 'John Doe',
        email: 'john@mail.com',
        password: '123',
    },

    signinRequest: {
        success: {
            access_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjE5MDg4MTQ5MmJhY2FkMTBkMjZmNTE2YzM1OTMwOTNjZTM0NmMzYzU5ZGQzOTIxZmQ0YTRjZDI2YjZjYzZhMzZmNDMyNjgyODg1NDUzMTYxIn0.eyJhdWQiOiIyIiwianRpIjoiMTkwODgxNDkyYmFjYWQxMGQyNmY1MTZjMzU5MzA5M2NlMzQ2YzNjNTlkZDM5MjFmZDRhNGNkMjZiNmNjNmEzNmY0MzI2ODI4ODU0NTMxNjEiLCJpYXQiOjE1NzEyNDM0ODQsIm5iZiI6MTU3MTI0MzQ4NCwiZXhwIjoxNjAyODY1ODg0LCJzdWIiOiI1Iiwic2NvcGVzIjpbImludmVudG9yeSJdfQ.NNXWfxhVBzv3lBHdRG_aCKsYJFTeNNqvcEAbqTRNaVJrBcE3LrY3EnuyCADXxB1KvK2PebNzaAgoXuyz4JK2PlFhPxVj3YFQ1qDnNQnTwMkRiKp3KeCPPCDa1ObFFxyMxYBzvXgdS3ArUH7iE-Li306txvbbh_esWt7NQIFi9W3vCoEJwAuWfmzFlh6uPZi2C8gffBnk0SbqViYQZK6aPP5ehAEZBV1ztRzVMBvZeCIgKZtzrxHc-3uqEK89CSkvr5l502mllAb7ML1uHj-i-TcaDKN5N1RJaNs9HH2pzM2vS2jci3s__Df_bVckXq-DvVrx9ol8MoE4M2yWILCaObPHZwCi7zMtuJvgfXEDeLLGCzFx-cCICdo2sJCu0xYj4Ha_VM31jrH7mNxNJF6SD-he_VLGMntYMBjzqUywhISZUbu4K-y1d1QqujZYmNJEtpi0ogbhGmTaMKInwmT3xOnhBb4gkCWmfH2pzluytc1p6ms5rZSgpHg54NVZv04UD6F5tuNoZya7L-5iJGzdngLHbERYSWgIJzwWoXcUlTov4rv4iGrD5aJm1tkNlDmqwkDNwaJSCo1RfBEkYPc_2AdvT8bWyyXEsvst5spKznz03Sa0DCyMHbXZQYmj3ND-Vg0F6qgUJlBVZUev1Z3cRh0Ylh5dIbE9R-ZGorrF8pY',
            expires_in: 31622400,
            refresh_token: 'def502003a9dd3bf57eac8cd293c130e11e419d0a674eed69b016c7f2ea8f8e9adcf9ea29f098284f6efb5fcc2b2d10cc7c29a1effe7c04b3b36589330781b53462c93f0c45163ae8cac386d14f5fdd269da44a550ef3cd00d83fc9fd408c1f47e7ac050540c3bd89b8e009a237c11a7a83bdfb75009c3c2dc4e8b41f0bf2659eb375eef3e377ec8aa64886b0030e7249fad91de75ffba3ce47f7a5f6ce8f14ab84241e1607707d95ea1a8a219e5226134d13d5317d375bc46d053c4727316293bb27edfd9fee2aa3c068b26a717ee224b5c2f0232cb34be05530a6bb3299aface815dd5b9cb4d51f3d6982b5c9e58c0adb5ed8e52759712c16db21bb5f864e092f587ab239cd32a47b77c0195b8d5033e127e5a825b7f8e2ef664e298ab26f5b7ebf20828fe8ccf6438a07b6fde1550c8313a9a24a99e747c7e87da84cc4d3c5e4f9b0b93b56f081954cff034acbdf78f61ef10595a1768d14faf5d0912341104a02970e091c23bd9a7e254',
            token_type: 'Bearer'
        },
        error: {
            invalidCredentials: {
                errors: {email: ['The user credentials were incorrect']},
                message: 'The user credentials were incorrect',
                statusCode: 422,
                type: 'validation'
            },
            emptyEmail: {
                errors: {email: ['The email field is required.']},
                message: 'The given data was invalid.',
                statusCode: 422,
                type: 'validation'
            },
            emptyPassword: {
                errors: {password: ['The password field is required.']},
                message: 'The given data was invalid.',
                statusCode: 422,
                type: 'validation'
            },
            emptyEmailAndPassword: {
                errors: {email: ['The email field is required.'], password: ['The password field is required.']},
                message: 'The given data was invalid.',
                statusCode: 422,
                type: 'validation'
            }
        }
    }
}
