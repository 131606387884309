import React, { useState, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import EquipmentComponent from '../Equipment/EquipmentComponent'
import PartsCatalogComponent from './PartsCatalogComponent'
import { MenuItem } from '@material-ui/core'
import useTranslation from 'Hooks/useTranslation'

const CatalogPage = () => {
    const translation = useTranslation();

    const catalogOptions = useMemo(() => {
        return [{
            id: 'components',
            name: translation.catalogPageDropdownComponentsOption
        },
        {
            id: 'parts',
            name: translation.catalogPageDropdownPartsOption
        }]
    }, [translation])
    const [selectedOption, setSelectedOption] = useState(catalogOptions[0].id)

    return (
        <div className="pmPageHolder">
            <div className="pmPageBlock">
                <div className="pmPageBlockTitle">{translation.catalogPageLabel}</div>
                <div className="pageFilter mb15">
                    <TextField
                        value={selectedOption}
                        variant="outlined"
                        margin='none'
                        select
                        fullWidth
                        onChange={e => setSelectedOption(e.target.value)}
                        id="templatesCatalogChangeField"
                    >
                        {catalogOptions.map((option) =>
                            <MenuItem key={option.id} value={option.id} className='filterMenuItem' id="templatesCatalogChooseField">{option.name}</MenuItem>)}
                    </TextField>
                </div>
                {selectedOption === 'components' && <EquipmentComponent isCatalog={true} />}
                {selectedOption === 'parts' && <PartsCatalogComponent />}
            </div>
        </div>
    )
}

export default CatalogPage