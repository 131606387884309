import React, { useEffect, useMemo, useState } from 'react';
import {useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from '../../../config';
import ListTable from '../../../Components/ListTable';
import useTranslation from '../../../Hooks/useTranslation';
import { useAuth } from '../../../Contexts/AuthProvider';

const DataUsage = () => {

    const { userLoaded, currentUser, currentCompany } = useAuth();
    const translation = useTranslation();
    const [data, setData] = useState([]);


    const tableColumns = useMemo(() => {
        return [
            {
                Header: translation.dataUsageCompanyNameLabel,
                accessor: 'companyName'
            },
            {
                Header: translation.dataUsageTotalImportsLabel,
                accessor: 'totalImports'
            },
            {
                Header: translation.dataUsageTotalSizeLabel,
                accessor: 'totalSize'
            },
            {
                Header: translation.dataUsageTotalJobsLabel,
                accessor: 'totalJobs'
            },
            {
                Header: translation.dataUsageTotalUnitsLabel,
                accessor: 'totalUnits'
            },
            {
                Header: translation.dataUsageTotalLocationsLabel,
                accessor: 'totalLocations'
            },
            {
                Header: '',
                id: 'actions',
                className: 'actionTableCell',
                Cell: () => {
                    return <div style={{height: '26px'}} />
                },
            },
        ];
    }, [translation]);

    const listRequest = useQuery(
        ['data-usage', currentCompany],
        async () => {
            const config = !currentUser.isSuperuser() ? {} : {
                headers: {
                    'CompanyId': currentCompany?.id,
                },
            };
            return await axios.get(`${apiBaseUrl}/system/usage`, config);
        },
        { keepPreviousData: true, enabled: userLoaded },
    );

    useEffect(() => {
        if (listRequest.isSuccess) {
            const data = listRequest.data.status === 200 ? listRequest.data.data : [];
            setData(data);
        }
    }, [listRequest.isSuccess, listRequest.data]);


    return (
        <div className="mainContainer mt32">
            <div className="pageCard">
                <ListTable
                    emptyDataText={(
                        <span>
                        <span>No data found.</span>
                    </span>
                    )}
                    request={listRequest}
                    columns={tableColumns}
                    data={data}
                />
            </div>
        </div>

    );
};

export default DataUsage;
