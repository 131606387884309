import Types from './types'

export const setCompanies = (companies) => ({
    type: Types.SET_COMPANIES,
    payload: companies
})

export const fetchJobsRequest = () => ({
    type: Types.FETCH_JOB_REQUEST
})

export const fetchJobsSuccess = jobs => ({
    type: Types.FETCH_JOB_SUCCESS,
    payload: jobs
})

export const fetchJobsFailure = error => ({
    type: Types.FETCH_JOB_FAILURE,
    payload: error
})

export const selectChannel = (index, channel) => ({
    type: Types.SELECT_CHANNEL,
    payload: { index, channel }
})

export const deselectChannel = (index, channel) => ({
    type: Types.DESELECT_CHANNEL,
    payload: { index, channel }
})

export const fetchChannelsDataRequest = (from, to, channelsIds, index) => ({
    type: Types.FETCH_CHANNELS_DATA_REQUEST,
    from,
    to,
    channelsIds,
    index
})

export const fetchXYChannelsDataRequest = (channelXId, channelYId) => ({
    type: Types.FETCH_XY_CHANNELS_DATA_REQUEST,
    channelXId,
    channelYId
})

export const fetchChannelsDataSuccess = (data, index) => ({
    type: Types.FETCH_CHANNELS_DATA_SUCCESS,
    payload: { data, index }
})

export const fetchXYChannelsDataSuccess = (data, index) => ({
    type: Types.FETCH_XY_CHANNELS_DATA_SUCCESS,
    payload: { data, index }
})

export const fetchChannelsDataFailure = error => ({
    type: Types.FETCH_CHANNELS_DATA_FAILURE,
    payload: error
})

export const fetchXYChannelsDataFailure = error => ({
    type: Types.FETCH_XY_CHANNELS_DATA_FAILURE,
    payload: error
})

export const addItemOnDashboard = (item) => ({
    type: Types.ADD_ITEM_TO_DASHBOARD,
    payload: item
})

export const setSelectedJob = job => ({
    type: Types.SET_SELECTED_JOB,
    payload: job
})

export const setCurrentJob = job => ({
    type: Types.SET_CURRENT_JOB,
    payload: job
})

export const changeJobWasSet = (wasSet) => ({
    type: Types.CHANGE_JOB_WAS_SET,
    payload: wasSet
})

export const cancelAddingItemToDashboard = (index) => ({
    type: Types.CANCEL_ADDING_ITEM_TO_DASHBOARD,
    payload: index
})

export const setCurrentIndex = (index) => ({
    type: Types.SET_CURRENT_INDEX,
    payload: index
})

export const setCurrentChartType = (type) => ({
    type: Types.SET_CURRENT_CHART_TYPE,
    payload: type
})

export const setSelectedChannels = (index, channels) => ({
    type: Types.SET_SELECTED_CHANNELS,
    payload: {
        index,
        channels
    }
}
)

export const reloadBoxes = () => ({
    type: Types.BOXES_RELOAD
})

export const setBoxes = data => ({
    type: Types.SET_BOXES,
    payload: data
})

export const addBox = data => ({
    type: Types.BOX_ADD,
    payload: data
})

export const addBoxes = data => ({
    type: Types.BOXES_ADD,
    payload: data
})

export const editBox = data => ({
    type: Types.BOX_EDIT,
    payload: data
})

export const editBoxAxes = data => ({
    type: Types.BOX_EDIT_AXES,
    payload: data
})

export const removeBox = index => ({
    type: Types.BOX_REMOVE,
    payload: index
})

export const windowToggleBox = data => ({
    type: Types.BOX_TOGGLE_WINDOW,
    payload: data
})


export const setState = state => ({
    type: Types.SET_STATE,
    payload: state
})

export const clearDashboard = state => ({
    type: Types.CLEAR,
})

export const setCurrentCompany = company => ({
    type: Types.SET_SELECTED_COMPANY,
    payload: company
})

export const setJobs = jobs => ({
    type: Types.SET_JOBS,
    payload: jobs
})
