import React  from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {useAppSettings} from 'Contexts/AppSettingsProvider'
import {useAuth} from 'Contexts/AuthProvider'

const LangIcon = () => <div className='langIcon' />

const LanguageSelector = () => {
    const { appLanguage } = useAppSettings()
    const { setUserLanguage } = useAuth()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const {languageList} = useAppSettings()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className='langDropdownHolder'>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} startIcon={<LangIcon />} className='dropDownBtn'>
                {appLanguage}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {languageList.map(language => (
                    <MenuItem key={language} className='filterMenuItem' onClick={() => {
                        handleClose()
                        setUserLanguage(language)
                    }}>{language}</MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default LanguageSelector
