import axios from 'axios';
import { apiBaseUrl } from 'config';



const useJobsSettings = (cleanupOldData = true, cleanupValue, cleanupInterval) => {
  const saveJobsSettings = async () => {
    try {
      await axios.put(`${apiBaseUrl}/Settings`, {
        cleanupOldData,
        cleanupValue,
        cleanupInterval,
      });
    } catch (e) {
      throw e;
    }
  };

  return saveJobsSettings;
};

const getJobsDateIntervals = async () => {
  const { data } = await axios.get(`${apiBaseUrl}/settings/date-intervals`);
  return data || [];
};

export { getJobsDateIntervals, useJobsSettings };

