import { useState, useRef, useEffect } from 'react';

/**
 * {String} KEY name
 * {*} value
 */
const useShareStatesBetweenTabs = (nameKey, value) => {
    const [state, setState] = useState(value);
    const isNewSession = useRef(true);

    useEffect(() => {
        if (isNewSession.current) {
            const currentState = localStorage.getItem(nameKey);

            if (currentState) setState(JSON.parse(currentState));
            else setState(value);

            isNewSession.current = false;
            return;
        }
        try {
            localStorage.setItem(nameKey, JSON.stringify(state));
        } catch (error) {
        }
    }, [state, nameKey, value]);

    useEffect(() => {
        const onReceiveMessage = (e) => {
            const { key, newValue } = e;
            if (key === nameKey) {
                setState(JSON.parse(newValue));
            }
        };
        window.addEventListener('storage', onReceiveMessage);
        return () => window.removeEventListener('storage', onReceiveMessage);
    }, [nameKey, setState]);

    return [state, setState];
};

export default useShareStatesBetweenTabs;
