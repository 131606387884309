import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, TextField, Tooltip } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import useTranslation from "../../../Hooks/useTranslation";
import { filterArray } from "../../../utils/functions";
import { useBoxes } from "../../../Contexts/BoxContext";
import { getUnitTemplatesByUid } from "../../../Hooks/useUnitTemplates";

const ListItem = ({ item }) => {
  return (
    <div className="jobLabel">
      <div className="labelName">{item.nameLocalized}</div>
      <div className="infoPart">
        <div className="startDate date" />
        <div className="stopDate date">{item.uId}</div>
      </div>
    </div>
  );
};

const UnitList = ({
  items,
  onCancel,
  onSave,
  submitButtonTitle,
  isTemplate,
  newlyCreatedBoxType,
  onSaveEventLogger,
  onSaveUnit,
  unit
}) => {
  const translation = useTranslation();
  const { currentUnit, setCurrentUnitModel } = useBoxes();
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(() => currentUnit);
  const [allowSubmit, setAllowSubmit] = useState(() => !isTemplate);
  const filteredItems = useMemo(() => {
    if (search && search !== "")
      return filterArray(items, search, "nameLocalized");
    return items || [];
  }, [items, search]);

  useEffect(() => {
    if (!isTemplate) return;
    if (!value) return;
    if (!items) return;
    setAllowSubmit(false);

    const currentUnit = items.find((item) => item.id === value);
    if (!currentUnit) return;

    const uId = currentUnit.uId;
    getUnitTemplatesByUid(uId).then((response) => {
      if (response?.length) setAllowSubmit(true);
    });
  }, [value, items, isTemplate]);

  useEffect(() => {
    if (filteredItems.length) {
      if (!filteredItems.find((job) => job.id === value)) {
        setValue(filteredItems[0].id);
      }
    } else {
      setValue(null);
    }
  }, [filteredItems, search, value]);

  const handleChange = (event) => {
    const value = +event.target.value;
    setValue(value);
  };

  const saveUnit = async () => {
    const unitModel = items.find((u) => u.id === value);
    setCurrentUnitModel(unitModel);
    onSave(value, unitModel);
  };

  const save = (openInNewDashboard = false) => () => {
      let savedData = {};
      const unitModel = items.find((u) => u.id === value);
      savedData = {
        unitUid: unitModel?.uId,
        channels: unitModel?.channels,
      };
      onSaveUnit(savedData, openInNewDashboard)
      onSaveEventLogger(value, unitModel, newlyCreatedBoxType = 'eventLogger');
    };

  return (
    <Fragment>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="search"
        label={translation.dashboardSearchUnitLabel}
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="checkboxListHolder mt8">
        <div className="labelLike">{translation.headerUnitsLabel}</div>
        {/*<div className="labelLike">UID</div>*/}
        <div className="labelLike dateStop">UID</div>
        <div className="checkboxListContainer">
          <RadioGroup
            aria-label="units"
            name="units"
            value={value}
            onChange={handleChange}
          >
            {filteredItems.map((item) => {
              return (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  label={<ListItem item={item} />}
                  control={<Radio color="default" />}
                />
              );
            })}
          </RadioGroup>
        </div>
      </div>
      <div className="btnHolder end mt22 fixedWidthBtn">
        <Button onClick={onCancel} variant="outlined">
          {translation.cancelButtonLabel}
        </Button>
        {newlyCreatedBoxType !== "eventLogger" && (
          <Tooltip
            title={
              !value || !allowSubmit ? translation.nextButtonTooltipLabel : ""
            }
          >
            <span>
              <Button
                onClick={saveUnit}
                disabled={!value || !allowSubmit}
                variant="contained"
                color="primary"
              >
                {submitButtonTitle || translation.continueButtonLabel}
              </Button>
            </span>
          </Tooltip>
        )}

        {newlyCreatedBoxType === "eventLogger" && (
          <>
          <Button
                    onClick={save(true)}
                    variant="outlined"
                >
                    {translation.openInNewWindowButtonLabel}
                </Button>
            <Button
              onClick={save()}
              // disabled={!canSave}
              variant="contained"
              color="primary"
            >
              {translation.openButtonLabel}
            </Button>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default UnitList;
