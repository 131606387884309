import React, { useMemo } from 'react'
import ListTable from '../ListTable'

const JobProperties = ({data}) => {

    const tableColumns = useMemo(() => {
        return [
            {
                Header: 'Job Property Name',
                accessor: 'name',
            },
            {
                Header: 'Value',
                accessor: 'value',
            }
        ]
    }, [])

    const tableData = useMemo(() => {
        if (!data) return []
        return data
    }, [data])

    return (
        <div>
            {/*<CustomSelectFilter />*/}
            <ListTable
                emptyDataText={(
                    <span>
                        <span>No job properties found.</span>
                    </span>
                )}
                // initSort={[{'id': 'timestamp', 'desc': true}]}
                columns={tableColumns}
                enableColumnFilters={true}
                data={tableData}
                tableClass="bordered"
            />
        </div>
    )
}

export default JobProperties
