import { useEffect, useMemo } from 'react';
import { apiMmsBaseUrl } from '../../config';
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { useClientMethod, useHub, useHubMethod } from 'react-use-signalr';

const MmsAssetsSignalR = props => {

    const {
        token,
        companyId,
        departmentId,
        onIncomingData,
        refreshCounter
    } = props;

    const signalRConnection = useMemo(
        () => {
            const buildHubUrl = companyId
                ? `${apiMmsBaseUrl}/ws/components?companyid=${companyId}`
                : `${apiMmsBaseUrl}/ws/components`;

            const protocol = new JsonHubProtocol();
            const transport = HttpTransportType.WebSockets;

            const options = {
                skipNegotiation: true,
                transport,
                accessTokenFactory: () => token,
                headers: { authorization: token ? `Token ${token}` : `` },
            };

            return new HubConnectionBuilder()
                .withUrl(buildHubUrl, options)
                .withAutomaticReconnect()
                .withHubProtocol(protocol)
                .configureLogging(LogLevel.Information)
                .build();
        }, [token, companyId],
    );

    const {
        hubConnectionState,
        // error
    } = useHub(signalRConnection);
    const {
        invoke,
        // loading,
        // error: errorInvoke
    } = useHubMethod(signalRConnection, 'SubscribeToWorkhoursUpdate');

    useClientMethod(signalRConnection, 'WorkhoursUpdated', onIncomingData);

    useEffect(() => {
        if (departmentId && hubConnectionState === 'Connected') {
            invoke(departmentId).then();
        }
    }, [
        departmentId,
        invoke,
        hubConnectionState,
        refreshCounter
    ]);

    return null;
};

export default MmsAssetsSignalR;
