import React, { useMemo } from 'react'
import useTranslation from '../Hooks/useTranslation'
import { useAuth } from '../Contexts/AuthProvider'
import { appName} from '../config'

const Copyright = () => {

    const t = useTranslation()
    const {dbSizeInfo} = useAuth()

    const percentage = useMemo(() => {
        if (!dbSizeInfo) return 0

        const {sizeGb, limitGb} = dbSizeInfo

        const result = sizeGb/limitGb * 100

        return result.toFixed(1)
    }, [dbSizeInfo])

    const label = dbSizeInfo ? t.formatString(t.dbSizeInfo, {
        used: dbSizeInfo?.sizeGb,
        limit: dbSizeInfo?.limitGb
    }) : ''


    const labelStyles = percentage >= 90 ? {
        paddingRight: '10px',
        color: 'red',
        fontWeight: 'bold'
    } : { paddingRight: '10px' }

    return (
        <div className="copyright">
            {
                label && <><span style={labelStyles}>{label}</span>{' @'}</>
            }
            <span>{appName}</span>
        </div>
    )
}
export default Copyright
