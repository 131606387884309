import { useEffect } from 'react'
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr'
import { apiBaseUrl } from 'config'
import { useAuth } from 'Contexts/AuthProvider'

const buildHubUrl = (company) => company ? `${apiBaseUrl}/ws/data?companyid=${company.id}` : `${apiBaseUrl}/ws/data`
const protocol = new JsonHubProtocol()
const transport = HttpTransportType.WebSockets

const LiveJobSignalrDigital = props => {

    const {
        channels,
        onIncomingData,
    } = props

    const { token, currentCompany } = useAuth()

    useEffect(() => {

        const options = {
            skipNegotiation: true,
            transport,
            accessTokenFactory: () => {
                return token
            },
            headers: {
                authorization: token ? `Token ${token}` : ``
            }
        }

        const connection = new HubConnectionBuilder()
            .withUrl(buildHubUrl(currentCompany), options)
            .withAutomaticReconnect()
            .withHubProtocol(protocol)
            .configureLogging(LogLevel.Information)
            .build()

        const dataHandler = data => {
            onIncomingData(data)
        }

        connection.on('DigitalViewDataResponse', dataHandler)

        connection.start().then(() => {

            const channelsData = channels.map(ch => ({ channelId: ch.id, unit: ch.unit }))
            connection.invoke('GetDigitalViewData', channelsData).then()
        })

        return () => {
            if (connection.state === 'Connected')
                connection.stop()
        }
    }, [channels, onIncomingData, token, currentCompany])

    return null
}

export default LiveJobSignalrDigital

