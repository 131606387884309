import React, { useEffect, useState } from 'react'
import { Dialog, TextField, Button, CircularProgress } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'
import { useMutation } from 'react-query'
import axios from 'axios'
import { apiBaseUrl } from '../../config'

const SaveSettingsTemplateModal = ({ isOpen, isOpenStateChanger, onSuccessSave, loadedTemplate }) => {

  const [templateName, setTemplateName] = useState('')
  const translation = useTranslation()
  const [loading, setLoading] = useState(false)

  const saveMutation = useMutation((data) => axios.put(`${apiBaseUrl}/templates`, data))

  const handleClose = () => {
    setTemplateName('')
    isOpenStateChanger(false)
  }

  useEffect(() => {
    if (loadedTemplate && loadedTemplate.name) {
      setTemplateName(loadedTemplate.name)
    }
  }, [loadedTemplate, setTemplateName])

  const handleSaveTemplate = async () => {
    setLoading(true)

    try {
      await saveMutation.mutateAsync({
        id: loadedTemplate?.id,
        name: templateName
      })

      handleClose()
      setLoading(false)
      if (onSuccessSave){
        onSuccessSave()
      }
    } catch (error) {
      setLoading(false)
      if (error && error.response && error.response.data && error.response.data.message)
        AlertToastr.showErrorAlert(error.response.data.message)
    }
  }

  return (
    <Dialog open={isOpen}>
      <div className="modalTitleHolder mb16">
        <h1 className="modalTitle">{translation.dashboardSaveTemplateLabel}</h1>
      </div>
      <TextField
        onChange={e => setTemplateName(e.target.value)}
        value={templateName}
        variant="outlined"
        margin="normal"
        fullWidth
        name="Template Name"
        label="Template Name"
        type="text"
      />
      <div className="btnHolder end mt22 fixedWidthBtn">
        <Button
          onClick={handleClose}
          variant="outlined">
          {translation.cancelButtonLabel}
        </Button>
        <div className="part">
          <Button
            onClick={handleSaveTemplate}
            variant="contained"
            color="primary"
            className='mr0'
            disabled={loading}
          >
            {translation.saveButtonLabel}
          </Button>
          {loading && <CircularProgress size={24}/>}
        </div>
      </div>
    </Dialog>
  )
}

export default SaveSettingsTemplateModal
