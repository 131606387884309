import { useMutation } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

export default function useTemplateLoad() {

    // const queryClient = useQueryClient();

    return useMutation(
        ({ tabId, jobId, templateId, unitUid, companyId, type }) => axios.post(`${apiBaseUrl}/templates/load`, {
            templateId,
            type: type || 'jobs',
            tabId,
            jobId,
            unitUid,
        }, {
            headers: { 'CompanyId': companyId },
        }),
        // {
        //     onSuccess: () => {
        //         queryClient.invalidateQueries(['template', id, type]).then()
        //     },
        // },
    );
}
