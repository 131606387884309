// import storage from 'redux-persist/lib/storage'
// import {persistReducer} from 'redux-persist'
import Types from './types'

const INITIAL_STATE = {
    companies: [],
    jobs: [],
    fetchJobsRequest: {
        loading: false,
        error: false
    },
    dashboards: [],
    selectedChannels: [],
    channelsData: [],
    channelsDataLoading: false,
    xyChannelsDataLoading: false,
    channelsDataError: null,
    xyChannelsDataError: null,
    itemsOnDashboard: [],
    selectedJob: null,
    jobWasSet: false,
    currentIndex: 0,
    currentChartType: 'timeBased',
    currentCompany: null,
    currentJob: null,
    boxes: [],
    infoColors: [ '#ff3925', '#009513', '#ffa42b', '#9b644c', '#bf4588', '#e6cb00', '#0562c0' ,'#f5845a', '#4b8b03', '#a356a2' ]
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.SET_COMPANIES:
            return {
                ...state,
                companies: action.payload
            }
        case Types.FETCH_JOB_REQUEST:
            return {
                ...state,
                fetchJobsRequest: { loading: true, error: false }
            }
        case Types.FETCH_JOB_SUCCESS:
            return {
                ...state,
                jobs: action.payload,
                fetchJobsRequest: { loading: false, error: false }
            }
        case Types.FETCH_JOB_FAILURE:
            return {
                ...state,
                jobs: [],
                fetchJobsRequest: { loading: true, error: action.payload }
            }
        case Types.SELECT_CHANNEL:
            return {
                ...state,
                selectedChannels: selectChannel(state.selectedChannels, action.payload)
            }
        case Types.DESELECT_CHANNEL:
            return {
                ...state,
                selectedChannels: deselectChannel(state.selectedChannels, action.payload),
                channelsData: removeDataForChannel(state.channelsData, action.payload)
            }
        case Types.FETCH_CHANNELS_DATA_REQUEST:
            return {
                ...state,
                channelsDataLoading: true,
                channelsDataError: null
            }
        case Types.FETCH_XY_CHANNELS_DATA_REQUEST:
            return {
                ...state,
                xyChannelsDataLoading: true,
                xyChannelsDataError: null
            }
        case Types.FETCH_CHANNELS_DATA_SUCCESS:
            return {
                ...state,
                channelsData: addChannelsData(state.channelsData, action.payload),
                channelsDataLoading: false,
                channelsDataError: null
            }
        case Types.FETCH_XY_CHANNELS_DATA_SUCCESS:
            return {
                ...state,
                channelsData: addChannelsData(state.channelsData, action.payload),
                xyChannelsDataLoading: false,
                xyChannelsDataError: null
            }
        case Types.FETCH_CHANNELS_DATA_FAILURE:
            return {
                ...state,
                channelsData: [],
                channelsDataLoading: false,
                channelsDataError: action.payload
            }
        case Types.FETCH_XY_CHANNELS_DATA_FAILURE:
            return {
                ...state,
                xyChannelsData: [],
                xyChannelsDataLoading: false,
                xyChannelsDataError: action.payload
            }
        case Types.ADD_ITEM_TO_DASHBOARD:
            return {
                ...state,
                itemsOnDashboard: [...state.itemsOnDashboard, action.payload]
            }
        case Types.SET_SELECTED_JOB:
            return {
                ...state,
                selectedJob: action.payload
            }
        case Types.SET_CURRENT_JOB:
            return {
                ...state,
                currentJob: action.payload,
                boxes: []
            }
        case Types.SET_SELECTED_CHANNELS:
            return {
                ...state,
                selectedChannels: setSelectedChannelsAtIndex(state.selectedChannels, action.payload)
            }
        case Types.CHANGE_JOB_WAS_SET:
            return {
                ...state,
                jobWasSet: action.payload
            }
        case Types.CANCEL_ADDING_ITEM_TO_DASHBOARD:
            return {
                ...state,
                channelsData: removeAtIndex(state.channelsData, action.payload),
                selectedChannels: removeAtIndex(state.selectedChannels, action.payload)
            }
        case Types.SET_CURRENT_INDEX:
            return {
                ...state,
                currentIndex: action.payload
            }
        case Types.SET_CURRENT_CHART_TYPE:
            return {
                ...state,
                currentChartType: action.payload
            }
        case Types.BOXES_RELOAD:
            return {
                ...state,
                boxes: [...state.boxes].map(box => {
                    return {
                        ...box,
                        count: typeof box.count === 'undefined' ? 0 : box.count + 1
                    }
                })
            }
        case Types.SET_BOXES:
            return {
                ...state,
                boxes: action.payload
            }
        case Types.BOX_ADD:
            return {
                ...state,
                boxes: [...state.boxes, action.payload]
            }
        case Types.BOXES_ADD:
            return {
                ...state,
                boxes: [...action.payload]
            }
        case Types.BOX_EDIT:
            return {
                ...state,
                boxes: boxesEdit(state, action.payload)
            }
        case Types.BOX_EDIT_AXES:
            return {
                ...state,
                boxes: boxesEditAxes(state, action.payload)
            }
        case Types.BOX_TOGGLE_WINDOW:
            return {
                ...state,
                boxes: boxesToggleWindow(state, action.payload)
            }
        case Types.BOX_REMOVE:
            return {
                ...state,
                boxes: boxesRemove(state, action.payload)
            }
        case Types.SET_STATE:
            return {
                ...action.payload
            }
        case Types.CLEAR:
            return {
                ...state,
                currentJob: null,
                boxes: []
            }
        case Types.SET_SELECTED_COMPANY:
            return {
                ...state,
                currentCompany: action.payload
            }
        case Types.SET_JOBS:
            return {
                ...state,
                jobs: action.payload
            }
        default:
            return state
    }
}

const boxesEdit = (state, data) => {
    let boxes = [...state.boxes]
    let editedBoxIndex = boxes.findIndex(box => box.id === data.id)
    if (editedBoxIndex !== -1) boxes[editedBoxIndex] = data
    return boxes
}

const boxesEditAxes = (state, data) => {
    let boxes = [...state.boxes]
    /*let editedBoxIndex = boxes.findIndex(box => box.id === data.id)
    if (editedBoxIndex !== -1) boxes[editedBoxIndex] = data*/
    return boxes
}

const boxesToggleWindow = (state, data) => {
    let boxes = [...state.boxes]
    let editedBoxIndex = boxes.findIndex(box => box.id === data.id)
    if (editedBoxIndex !== -1) boxes[editedBoxIndex] = { ...boxes[editedBoxIndex], group: data.group }
    return boxes
}

const boxesRemove = (state, id) => {
    return state.boxes.filter(box => box.id !== id)
}

const selectChannel = (selectedChannels, { index, channel }) => {
    let selChan = selectedChannels;
    while (selChan.length - 1 < index) {
        selChan.push([])
    }

    selChan[index].push(channel)

    return selChan
}

const deselectChannel = (selectedChannels, { index, channel }) => {
    selectedChannels[index] = selectedChannels[index].filter(ch => channel.id !== ch.id)
    return selectedChannels
}

const removeDataForChannel = (channelsData, { index, channel }) => {
    channelsData[index] = channelsData[index].filter(ch => channel.id !== ch.channelID)
    return channelsData
}

const removeAtIndex = (arr, index) => {
    if (arr[index]) {
        arr.splice(index, 1)
        return arr
    } else {
        return arr
    }
}

const addChannelsData = (channelsData, { data, index }) => {
    let chanDat = channelsData;

    if (!chanDat[index].includes(data)) {
        chanDat[index].push(data)
    }

    return chanDat
}

const setSelectedChannelsAtIndex = (selectedChannels, { index, channels }) => {
    let selChan = selectedChannels
    selChan[index] = channels

    return selChan
}

// const persistConfig = {
//     key: 'dashboard',
//     storage,
//     whitelist: ['boxes']
// }

// export default persistReducer(persistConfig, reducer)
export default reducer
