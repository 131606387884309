import React, { useState, useEffect, Fragment, useMemo } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog"
import Tooltip from "@material-ui/core/Tooltip"
import MmsService from 'Services/MMS/mms-api.service'
import { setEmployees, setCurrentCompanyPage } from '../../../../Store/modules/mms/actions'
import { normalizeErrorResponse } from 'utils/form'
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'

const SaveEmployeeModal = ({ open, handleClose, departments, employee, employees, setEmployees, currentCompany, setCurrentCompanyPage }) => {

    const translation = useTranslation();
    const [editedEmployee, setEditedEmployee] = useState(null)
    const [disableButtons, setDisabledButtons] = useState(false)
    const [errors, setErrors] = useState({})
    const emptyDepartmentOption = useMemo(() => {
        return { id: 'empty', name: translation.saveEmployeeModalSelectDepartmentLabel }
      }, [translation])

    useEffect(() => {
        if (!open || !departments)
            return
        if (employee) {
            setEditedEmployee(employee)
        }
        else {
            setEditedEmployee({
                name: '',
                departmentId: emptyDepartmentOption.id,
                role: '',
                phoneNumber: '',
                email: ''
            })
        }
    }, [employee, open, departments, emptyDepartmentOption.id])

    const handleSave = () => {
        setDisabledButtons(true)
        if (editedEmployee.departmentId === emptyDepartmentOption.id) {
            editedEmployee.departmentId = null;
        }
        if (employee) {
            MmsService.updateEmployee({
                id: editedEmployee.id,
                name: editedEmployee.name,
                departmentId: editedEmployee.departmentId,
                role: editedEmployee.role,
                phoneNumber: editedEmployee.phoneNumber,
                email: editedEmployee.email
            }).then((response) => {
                if (response.data.isSuccessful) {
                    AlertToastr.showAlert(translation.notificationsEmployeeUpdated)
                    const currEmployee = employees.find(d => d.id === employee.id)
                    currEmployee.name = editedEmployee.name
                    currEmployee.departmentId = editedEmployee.departmentId
                    currEmployee.role = editedEmployee.role
                    currEmployee.phoneNumber = editedEmployee.phoneNumber
                    currEmployee.email = editedEmployee.email
                    setEmployees([...employees])
                }
                setDisabledButtons(false)
                handleClose()
                setErrors({})
            })
                .catch((error) => {
                    if (error.response.data) {
                        const resErrors = normalizeErrorResponse(error.response.data)
                        if (resErrors.errors)
                            setErrors(resErrors.errors)
                        setDisabledButtons(false)
                        if (resErrors && resErrors.message)
                            AlertToastr.showErrorAlert(resErrors.message)
                    }
                    if (error.response.data.errors)
                        error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
                            AlertToastr.showErrorAlert(x.ErrorMessage)
                            return x
                        })
                })
        } else
            MmsService.createEmployee(
                {
                    name: editedEmployee.name,
                    departmentId: editedEmployee.departmentId,
                    role: editedEmployee.role,
                    phoneNumber: editedEmployee.phoneNumber,
                    email: editedEmployee.email,
                    companyId: currentCompany?.id
                }
            ).then((response) => {
                if (response.data.isSuccessful) {
                    AlertToastr.showAlert(translation.notificationsEmployeeAdded)
                    const newEmployees = [...employees, {
                        id: response.data.id,
                        name: editedEmployee.name,
                        departmentId: editedEmployee.departmentId,
                        role: editedEmployee.role,
                        phoneNumber: editedEmployee.phoneNumber,
                        email: editedEmployee.email
                    }]
                    setEmployees(newEmployees)
                    setCurrentCompanyPage('employees')
                }
                handleClose()
                setErrors({})
                setDisabledButtons(false)
            })
                .catch((error) => {
                    if (!error.response || !error.response.data)
                        return
                    const resErrors = normalizeErrorResponse(error.response.data)
                    if (resErrors.errors)
                        setErrors(resErrors.errors)
                    setDisabledButtons(false)
                    if (resErrors && resErrors.message)
                        AlertToastr.showErrorAlert(resErrors.message)
                    if (error.response.data.errors)
                        error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
                            AlertToastr.showErrorAlert(x.ErrorMessage)
                            return x
                        })
                })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={e => {
                    handleClose()
                    setErrors({})
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        handleSave();
                    }
                }}
                className='respModal scrollContentModal autoHeight'
            >
                <div className="formContentHolder">
                    {editedEmployee && <div className="">
                        <div className="modalTitleHolder">
                            <h1 className='modalTitle text-center'>{employee ? translation.saveEmployeeModalEditEmployeeLabel : translation.saveEmployeeModalAddEmployeeLabel}</h1>
                        </div>
                        <div className="formContent">
                            <div className="formContentBlock modalFieldLabelSettings">
                                <Tooltip disableFocusListener disableTouchListener title={errors && errors.name ? errors.name.join('\n') : ''}>
                                    <div>
                                        <TextField
                                            value={editedEmployee.name}
                                            onChange={e => {
                                                if (errors.name)
                                                    errors.name = null
                                                setEditedEmployee({ ...editedEmployee, name: e.target.value })
                                            }}
                                            error={errors && errors.name && errors.name.length > 0}
                                            id="name employeesAddEmployeeModalName"
                                            label={translation.saveEmployeeModalNameLabel}
                                            variant="outlined"
                                            margin='normal'
                                            fullWidth
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip disableFocusListener disableTouchListener title={errors && errors.departmentId ? errors.departmentId.join('\n') : ''}>
                                    <div>
                                        <TextField
                                            value={editedEmployee.departmentId}
                                            onChange={e => {
                                                if (errors.departmentId)
                                                    errors.departmentId = null
                                                setEditedEmployee({ ...editedEmployee, departmentId: e.target.value })
                                            }}
                                            error={errors && errors.departmentId && errors.departmentId.length > 0}
                                            id="departments"
                                            variant="outlined"
                                            margin='normal'
                                            select
                                            fullWidth
                                            label={emptyDepartmentOption.name}
                                        >
                                            <MenuItem
                                              key={emptyDepartmentOption.id}
                                              className="filterMenuItem"
                                              value={emptyDepartmentOption.id}
                                              id=" employeesAddEmployeeModalChangeDepartment"
                                            >
                                              {emptyDepartmentOption.name}
                                            </MenuItem>
                                            {departments && departments.map((option) => {
                                                return <MenuItem id="employeesAddEmployeeModalChooseDepartment" className="filterMenuItem" key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            })}
                                        </TextField>
                                    </div>
                                </Tooltip>
                                <Tooltip disableFocusListener disableTouchListener title={errors && errors.role ? errors.role.join('\n') : ''}>
                                    <div>
                                        <TextField
                                            value={editedEmployee.role}
                                            onChange={e => {
                                                if (errors.role)
                                                    errors.role = null
                                                setEditedEmployee({ ...editedEmployee, role: e.target.value })
                                            }}
                                            error={errors && errors.role && errors.role.length > 0}
                                            id="role employeesAddEmployeeModalRole"
                                            label={translation.saveEmployeeModalRoleLabel}
                                            variant="outlined"
                                            margin='normal'
                                            fullWidth
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip disableFocusListener disableTouchListener title={errors && errors.phoneNumber ? errors.phoneNumber.join('\n') : ''}>
                                    <div>
                                        <TextField
                                            value={editedEmployee.phoneNumber}
                                            onChange={e => {
                                                if (errors.phoneNumber)
                                                    errors.phoneNumber = null
                                                setEditedEmployee({ ...editedEmployee, phoneNumber: e.target.value })
                                            }}
                                            error={errors && errors.phoneNumber && errors.phoneNumber.length > 0}
                                            id="phoneNumber employeesAddEmployeeModalPhone"
                                            label={translation.saveEmployeeModalPhoneNumberLabel}
                                            variant="outlined"
                                            margin='normal'
                                            fullWidth
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip disableFocusListener disableTouchListener title={errors && errors.email ? errors.email.join('\n') : ''}>
                                    <div>
                                        <TextField
                                            value={editedEmployee.email}
                                            onChange={e => {
                                                if (errors.email)
                                                    errors.email = null
                                                setEditedEmployee({ ...editedEmployee, email: e.target.value })
                                            }}
                                            error={errors && errors.email && errors.email.length > 0}
                                            id="email employeesAddEmployeeModalEmail"
                                            label={translation.saveEmployeeModalEmailLabel}
                                            variant="outlined"
                                            margin='normal'
                                            fullWidth
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="btnHolder end fixedWidthBtn">
                                <Button
                                    disabled={!!disableButtons}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={e => {
                                        handleClose()
                                        setErrors({})
                                    }}
                                    id="employeesAddEmployeeModalCancelBtn"
                                >
                                    {translation.cancelButtonLabel}
                                </Button>
                                <Button
                                    disabled={!!disableButtons}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSave}
                                    id="employeesAddEmployeeModalSaveBtn"
                                >
                                    {employee ? translation.saveButtonLabel : translation.addButtonLabel}
                                </Button>
                        </div>
                    </div>}
                </div>
            </Dialog>
        </Fragment >
    )
}
const mapStateToProps = ({ mms, dashboard }) => ({
    employees: mms.employees,
    departments: mms.departments,
    currentCompany: dashboard.currentCompany
})

const mapDispatchToProps = {
    setEmployees,
    setCurrentCompanyPage
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveEmployeeModal)
