import React, { Fragment, useCallback, useContext, useMemo, useRef, memo } from 'react'
import { connect } from 'react-redux'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Boost from 'highcharts/modules/boost'
import { WindowContext } from 'Contexts/Window/WindowContext'
import { dataBaseChartConfig } from 'chartConfig'
import { jobChannels } from 'utils/functions'
import LiveJobSignalrDataBased from 'Components/SignalrSubscribes/LiveJobSignalrDataBased.component'
import _ from 'lodash'

const DataBasedChart = memo(props => {

    const {
        reverse,
        channelsData,
        infoColors,
        chartHeight,
        job,
        mainXYChannel,
        channels,
        currentTimestamp,
        yAxisSettings,
        xAxisSettings
    } = props

    const chartRef = useRef()
    const { currentTheme: theme } = useContext(WindowContext)
    const chartConfig = useMemo(() => dataBaseChartConfig(theme), [theme])
    const mainXYChannelUnit = useMemo(() => {
        const channelModels = jobChannels(job.units, [mainXYChannel])
        return (channelModels && channelModels[0] && channelModels[0].unit) || ''
    }, [job, mainXYChannel])

    const chartData = useMemo(() => {
        if (!channelsData)
            return null

        return {
            channelX: channelsData.channelX,
            yAxisResponses: channelsData.yAxisResponses.map(item => ({
                channelY: item.channelY,
                data: item.dataPoints
            }))
        }
    }, [channelsData])

    const chartOptions = useMemo(() => {
        const yAxises = []
        const seriesData = []

        if (chartData && chartData.yAxisResponses) {
            chartData.yAxisResponses.forEach((yChannel, index) => {

                const { nameLocalized, unit } = yChannel.channelY

                const yAxisId = `${unit}`
                const axisSettings = yAxisSettings.find(s => s.unit === yAxisId)

                if (!yAxises.some(elem => elem.id === yAxisId)) { // to avoid duplication of axes with the same unit
                    const yAxisConfig = _.merge(
                        {},
                        chartConfig.yAxis,
                        {
                            title: { text: `${yAxisId}` },
                            id: yAxisId,
                            min: !axisSettings || axisSettings.autoScaling ? null : axisSettings.min,
                            max: !axisSettings || axisSettings.autoScaling ? null : axisSettings.max,
                        }
                    )
                    yAxises.push(yAxisConfig)
                }

                seriesData.push({
                    name: `${nameLocalized} (${unit})`,
                    type: 'scatter',
                    marker: {
                        enable: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        radius: 1
                    },
                    lineWidth: 1,
                    data: yChannel.data,
                    yAxis: yAxisId,
                    color: infoColors[index]
                })
            })
        }

        const localConfig = {
            series: seriesData,
            // plotOptions: {
            //     series: {
            //         boostThreshold: 3000
            //     }
            // },
            boost: {
                useGPUTranslations: true,
                usePreAllocated: true
            },
            chart: {
                zoomType: 'xy',
                inverted: reverse,
                height: chartHeight
            },
            xAxis: {
                title: {
                    text: mainXYChannelUnit
                },
                min: !xAxisSettings || xAxisSettings.autoScaling ? null : xAxisSettings.min,
                max: !xAxisSettings || xAxisSettings.autoScaling ? null : xAxisSettings.max,
            },
            yAxis: yAxises.map((axis, idx) => ({ ...axis, opposite: idx % 2 !== 0 })),
            tooltip: {
                formatter: function () {
                    let { series, x, y } = this.point
                    return `<b>${channelsData.channelX.nameLocalized} (${channelsData.channelX.unit})</b>: ${x}<br/> <b>${series.name}: </b>${y}`
                },
                split: false,
                shared: false,
            }
        }

        return _.merge(
            {},
            chartConfig,
            localConfig
        )
    }, [
        chartData,
        chartHeight,
        reverse,
        infoColors,
        chartConfig,
        channelsData,
        mainXYChannelUnit,
        yAxisSettings,
        xAxisSettings
    ])

    const handleLiveData = useCallback(incomingData => {
        if (incomingData && incomingData.channelX) {

            if (chartRef && chartRef.current) {
                incomingData.yAxisResponses.forEach((channelY, index) => {
                    channelY.dataPoints.forEach(point => {
                        chartRef.current.chart.series[index].addPoint(point)
                    })
                })
            }
        }

    }, [])

    if (chartData && chartData.yAxisResponses && chartData.yAxisResponses.length) {
        return (
            <Fragment>
                <HighchartsReact highcharts={Boost(Highcharts)}
                    options={chartOptions}
                    immutable={true}
                    ref={chartRef} />

                {
                    !job.stopTime && (
                        <LiveJobSignalrDataBased mainXYChannel={mainXYChannel}
                            channels={channels}
                            onIncomingData={handleLiveData}
                            lastProcessedTimestamp={currentTimestamp} />
                    )
                }
            </Fragment>
        )
    } else
        return null
})

const mapStateToProps = ({ dashboard }) => ({
    infoColors: dashboard.infoColors
})

export default connect(mapStateToProps)(DataBasedChart)
