import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

const getTemplatesById = async (id, section = 'jobs') => {
    const { data } = await axios.get(`${apiBaseUrl}/templates/by-tabType/${section}/id/${id}`);
    return data || [];
};

export default function useTemplates(id, section) {
    return useQuery(
        ['template', id, section],
        () => getTemplatesById(id, section),
        {
            initialData: [],
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!id
        }
    );
}
