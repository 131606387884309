import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  TextField,
  Tooltip,
  MenuItem,
  FormLabel,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DmsApiService from "Services/DMS/dms-api.service";
import AlertToastr from "utils/alert";
import moment from "moment";
import useTranslation from "Hooks/useTranslation";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";

const NewIntegrationModal = ({
  isOpen,
  isOpenChangeHandler,
  integrationTypes,
  integrationDateIntervals,
  integrationData,
  fetchIntegrations,
  setIntegrationData
}) => {
  const translation = useTranslation();

  const [integration, setIntegration] = useState({
    username: "",
    password: "",
    email: "",
    url: "",
    expectedCompleteDate: new Date(),
    dateType: "Hours",
    integrationType: "",
    value: 1,
  });

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (integrationData) {
      setIntegration({
        username: integrationData.username || "",
        password:  "",
        email: integrationData.email || "",
        url: integrationData.url || "",
        expectedCompleteDate: new Date(integrationData.startFrom) || new Date(),
        dateType: integrationData.interval || "Hours",
        integrationType: integrationData.type || "",
        value: integrationData.value || 1,
      });
    }
  }, [integrationData]);

  useEffect(() => {
    if (integrationData) {
      setIsEdit(true);
    }
  }, [integrationData]);

  const setDefaultValues = () => {
    setIntegration({
      username: "",
      password: "",
      email: "",
      url: "",
      expectedCompleteDate: new Date(),
      dateType: "Hours",
      integrationType: "",
      value: 1,
    });
  };

  const onCreateOrUpdate = async () => {
    const selectedDateInterval = integrationDateIntervals.find(
      (item) => item.dateInterval === integration.dateType
    );

    const interval = selectedDateInterval
      ? selectedDateInterval.dateInterval
      : "";

    const integrationInfo = {
      type: integration.integrationType,
      username: integration.username,
      password: integration.password,
      email: integration.email,
      url: integration.url,
      value: integration.value,
      interval: interval,
      startFrom: integration.expectedCompleteDate.toISOString(),
    };

    if (isEdit && integrationData && integrationData.id) {
      integrationInfo.id = integrationData.id;
    }

    try {
      let response;
      if (isEdit) {
        response = await DmsApiService.editIntegration(integrationInfo);
        if (response.data.isSuccessful) {
          AlertToastr.showAlert(translation.notificationsIntegrationUpdated)
        }
      } else {
        response = await DmsApiService.createIntegration(integrationInfo);
        if (response.data.isSuccessful) {
          AlertToastr.showAlert(translation.notificationsIntegrationCreated)
        }
      }
      onClose();
      if (fetchIntegrations) {
        fetchIntegrations();
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        const errData = error.response.data;
        if (Array.isArray(errData.errors) && errData.errors.length > 0) {
          errData.errors.forEach((e) => {
            AlertToastr.showErrorAlert(e.ErrorMessage);
          });
        } else if (typeof errData.Message === "string") {
          AlertToastr.showErrorAlert(errData.Message);
        } else if (typeof errData.message === "string") {
          AlertToastr.showErrorAlert(errData.message);
        } else {
          AlertToastr.showErrorAlert("An unexpected error occurred.");
        }
      } else {
        AlertToastr.showErrorAlert("An unexpected error occurred.");
      }
    }
  };

  const onTest = async () => {

    const integrationInfo = {
      username: integration.username,
      password: integration.password,
      url: integration.url,
    };
    try {
      let response;
        response = await DmsApiService.testIntegration(integrationInfo);
        if (response.data.isSuccessful) {
          AlertToastr.showAlert(response.data.message)
        }
    } catch (error) {
      if (error && error.response && error.response.data) {
        const errData = error.response.data;
        if (Array.isArray(errData.errors) && errData.errors.length > 0) {
          errData.errors.forEach((e) => {
            AlertToastr.showErrorAlert(e.ErrorMessage);
          });
        } else if (typeof errData.Message === "string") {
          AlertToastr.showErrorAlert(errData.Message);
        } else if (typeof errData.message === "string") {
          AlertToastr.showErrorAlert(errData.message);
        } else {
          AlertToastr.showErrorAlert("An unexpected error occurred.");
        }
      } else {
        AlertToastr.showErrorAlert("An unexpected error occurred.");
      }
    }
  };

  const onClose = () => {
    setDefaultValues();
    setIsEdit(false);
    setIntegrationData(null)
    isOpenChangeHandler(false);
  };

  const handleSubmit = async () => {
    onCreateOrUpdate();
  };

  const handleOpenPopover = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const renderExpectedCompleteDateField = () => {
    return (
      <Tooltip disableFocusListener disableTouchListener title="">
        <div>
          <DatePicker
            selected={integration.expectedCompleteDate}
            onChange={(date) => {
              setIntegration({
                ...integration,
                expectedCompleteDate: date ? moment(date).toDate() : new Date(),
              });
            }}
            maxDate={new Date("2999-12-31")}
            className="formControlDatePicker"
            dateFormat="MM/dd/yyyy h:mm aa"
            placeholderText="MM/DD/YYYY hh:mm AM/PM"
            dropdownMode="select"
            showTimeSelect
            timeFormat="HH:mm"
          />
        </div>
      </Tooltip>
    );
  };

  const renderIntegrationTypeSelect = () => {
    return (
      <TextField
        disabled={isEdit}
        select
        variant="outlined"
        margin="normal"
        fullWidth
        id="integrationType"
        label="Select integration type"
        name="integrationType"
        value={integration.integrationType || ""}
        onChange={(e) =>
          setIntegration({ ...integration, integrationType: e.target.value })
        }
        style={{ marginBottom: "10px" }}
      >
        {integrationTypes.map((item) => (
          <MenuItem
            key={item.type}
            value={item.type}
            className="filterMenuItem"
          >
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderDateRangeSelect = () => {
    const selectedDate = integrationDateIntervals.find(
      (item) => item.dateInterval === integration.dateType
    ) || { name: "" };

    return (
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="value"
            label="Value"
            name="value"
            inputProps={{
              autoComplete: "off",
            }}
            value={integration.value}
            onChange={(e) =>
              setIntegration({ ...integration, value: e.target.value })
            }
          />
          <TextField
            variant="outlined"
            margin="none"
            fullWidth
            value={selectedDate.name}
            onClick={handleOpenPopover}
            className="dateRangePicker"
            InputProps={{
              endAdornment: (
                <ArrowDropDownIcon className="dateRangePickerIcon" />
              ),
            }}
            readOnly
            style={{ marginBottom: "4px" }}
          />
          <Popover
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {integrationDateIntervals.map((item) => (
              <MenuItem
                key={item.dateInterval}
                value={item.dateInterval}
                className="filterMenuItem"
                onClick={() => {
                  const newDateType =
                    item.dateInterval === "none" ? "" : item.dateInterval;
                  setIntegration((prevState) => ({
                    ...prevState,
                    dateType: newDateType,
                  }));
                  handleClosePopover();
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Popover>
        </div>
      </>
    );
  };

  const isFormValid = useMemo(() => {
    return (
      integration.username.trim() !== "" &&
      integration.password.trim() !== "" &&
      integration.url.trim() !== "" &&
      integration.integrationType.trim() !== "" &&
      integration.value !== "" &&
      integration.dateType.trim() !== "" &&
      integration.expectedCompleteDate !== null
    );
  }, [integration]);

  return (
    <Dialog
      open={isOpen}
      className="respModal scrollContentModal calendarModal autoHeight"
    >
      <form action="">
        <div className="formContentHolder">
          <div className="modalTitleHolder">
            {!isEdit ? (
              <h2>{translation.saveIntegrationModalCreateIntegrationLabel}</h2>
            ) : (
              <h2>{translation.editIntegrationModalCreateIntegrationLabel}</h2>
            )}
          </div>

          <div className="formContent">
            <div className="formContentBlock">
              <div>
                <FormLabel component="legend" style={{ lineHeight: "30px" }}>
                  {translation.settingsIntegrationModalIntegrationTypeLabel}
                </FormLabel>
              </div>
              {renderIntegrationTypeSelect()}
              <div>
                <FormLabel component="legend" style={{ lineHeight: "30px" }}>
                  {translation.settingsIntegrationModalUserInfoLabel}
                </FormLabel>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label={translation.saveUserModalUsernameLabel}
                name="username"
                inputProps={{
                  autoComplete: "off",
                }}
                value={integration.username}
                onChange={(e) =>
                  setIntegration({ ...integration, username: e.target.value })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="password"
                label={translation.settingsTablesPasswordHeader}
                name="password"
                type="password"
                inputProps={{
                  autoComplete: "off",
                }}
                value={integration.password}
                onChange={(e) =>
                  setIntegration({ ...integration, password: e.target.value })
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="url"
                label={translation.settingsTablesBaseUrlHeader}
                name="url"
                inputProps={{
                  autoComplete: "off",
                }}
                value={integration.url}
                onChange={(e) =>
                  setIntegration({ ...integration, url: e.target.value })
                }
                style={{ marginBottom: "10px" }}
              />
              <div style={{ marginBottom: "8px" }}>
                <div
                  className="halfBlockContainer"
                  style={{ alignItems: "center" }}
                >
                  <div className="halfBlock">
                  <FormLabel component="legend" style={{ lineHeight: "30px" }}>
                  {translation.settingsIntegrationModalStartFromLabel}
                </FormLabel>
                    {renderExpectedCompleteDateField()}
                  </div>
                  <div className="halfBlock">
                  <FormLabel component="legend" style={{ lineHeight: "30px" }}>
                  {translation.settingsIntegrationModalUpdateFrequencyLabel}
                </FormLabel>
                    {renderDateRangeSelect()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="btnHolder mt22 fixedWidthBtn">
            <Button onClick={() => onClose(null)} variant="outlined">
              {translation.cancelButtonLabel}
            </Button>
            <Button
              onClick={onTest}
              variant="contained"
              style={{ marginRight: "15px" }}
              color="primary"
              disabled={!isFormValid}
            >
              {translation.testConnectionButtonLabel}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={!isFormValid}
            >
              {translation.saveButtonLabel}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default NewIntegrationModal;
