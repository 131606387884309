import React, {useEffect} from "react";
import {
  Button,
  Dialog,
  TextField,
} from "@material-ui/core";
import useTranslation from "Hooks/useTranslation";

const WorkhoursUpdateModal = ({
  open,
  onClose,
  onChange,
  onSave,
  model,
  value,
  // forceUpdate,
  // message,
  // serialNumber,
  // handleSerialNumberChange,
}) => {
  const handleSubmit = () => {
    if (onSave) {
      onSave();
    }
  };

  const translation = useTranslation();

  useEffect(() => {
    if (open) {
      const autoFocusTimeout = setTimeout(() => {
        document.getElementById("workhoursInputModal").focus();
      }, 100);
      return () => clearTimeout(autoFocusTimeout);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleSubmit();
        } 
        if (e.key === "Escape") {
          onClose();
        }
    }}
      className="respModal autoHeight"
      style={{ width: "448px", marginLeft: "auto", marginRight: "auto" }}
    >
      <div className="mb16">
        <h1 className="workhoursUpdateModalTitle">Asset Workhours Update</h1>
      </div>
      <div className="workhoursUpdateJobTitle" htmlFor="workhoursInputModal">
        {model.fullName}
      </div>
      <div className="mb16">
        <TextField
          id="workhoursInputModal"
          type="number"
          variant="outlined"
          margin="normal"
          fullWidth
          value={value}
          onChange={onChange}
          autoFocus={open}
          className="mb16"
          label="Workhours"
        />
        {/* {forceUpdate && (
          <>
            <div className="workhoursWarningBlock animatedBlock mb16">
              <ErrorOutlineIcon
                style={{ color: "#F1C219", marginRight: "10px" }}
              />
              <div>{message}</div>
            </div>
            <div>
              <FormLabel component="legend" className="mb5">
                Select new state for Component {model.fullName}
              </FormLabel>
              <div className="">
                <TextField
                  id="workhoursInput"
                  type="number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  className="mb16"
                  value={selectedState}
                  onChange={handleMoveToComponentChange}
                  label={translation.replaceComponentModalStateFieldLabel}
                >
                  <MenuItem value={emptyComponentStateOption.id}>
                    {emptyComponentStateOption.name}
                  </MenuItem>
                  {states.map((state, index) => (
                    <MenuItem
                      key={index}
                      value={state.componentState}
                      onClick={() => setSelectedState(state.componentState)}
                      className=""
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </>
        )} */}
      </div>
      <div className="btnHolder end fixedWidthBtn">
        <Button
          variant="outlined"
          color="primary"
          id="workhoursModalCancelBtn"
          fullWidth
          onClick={onClose}
        >
          {translation.cancelButtonLabel}
        </Button>

        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          id="workhoursModalSaveRemoveBtn"
          style={{ marginBottom: "0" }}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default WorkhoursUpdateModal;
