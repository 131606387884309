import React, { useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import useApiCall from 'Hooks/useApiCall'
import PasswordResetCodeRequestForm from 'Pages/Auth/PasswordReset/forms/PasswordResetCodeRequest.form'
import authEndpoints from 'Services/endpoints/auth.endpoints'
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'

const PasswordResetRequest = () => {
    const translation = useTranslation();
    const [request, passwordResetRequest] = useApiCall()
    const [isSuccessfullSubmit, setIsSuccessfulSubmit] = useState(false)

    const { ...formProps } = useForm({
        defaultValues: { email: '' }
    })

    useEffect(() => {
        if (request.response)
            setIsSuccessfulSubmit(true)
    }, [
        request.response,
        setIsSuccessfulSubmit
    ])

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message)
        }
    }, [request.error])

    const handleSubmit = data => {
        if (data.email) {
            passwordResetRequest({
                ...authEndpoints.passwordResetRequest(),
                data
            })
        }
    }

    if (isSuccessfullSubmit)
        return (
            <div className="text-center">
                {translation.forgotPasswordPageRequestSentLabel}
            </div>
        )

    return (
        <PasswordResetCodeRequestForm {...formProps}
            onSubmit={handleSubmit}
            request={request} />
    )
}

export default PasswordResetRequest
