import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MmsService from 'Services/MMS/mms-api.service'
import AlertToastr from 'utils/alert'
import { Button } from "@material-ui/core";
import { setRunComponentsUpdate } from '../../../../Store/modules/mms/actions'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import { useQueryClient } from 'react-query'

const DuplicateComponentModal = ({ isOpen, isOpenManager, parentComponentId, componentIds, departmentId, setRunComponentsUpdate, isCatalog, onSuccessDuplicate }) => {
    const translation = useTranslation();
    const [disableButtons, setDisabledButtons] = useState(false);
    const queryClient = useQueryClient();

    const onClone = () => {
        setDisabledButtons(true)
        MmsService.cloneComponents(
          componentIds,
          isCatalog ? null : departmentId,
          parentComponentId || null
        ).then((response) => {
            if (response.data.isSuccessful) {
              queryClient.invalidateQueries(['components']);
                // setRunComponentsUpdate(Math.random())
                AlertToastr.showAlert(componentIds.length === 1 ? translation.notificationsComponentCloned : translation.notificationsComponentsCloned)
            }
            if (onSuccessDuplicate) onSuccessDuplicate()
            isOpenManager(false)
            setDisabledButtons(false)
        })
            .catch((error) => {
                isOpenManager(false)
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    return (
        <Dialog open={isOpen} className=''>
            <div className="modalTitleHolder mb16">
                <h1 className='modalTitle'>{translation.takeComponentsModalPermissionLabel}</h1>
            </div>
            <p>{translation.takeComponentsModalMessageLabel}</p>
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    disabled={!!disableButtons}
                    onClick={() => isOpenManager(false)}
                    variant="outlined"
                    id="templatesTblMenuDuplicateCancelBtn"
                >{translation.cancelButtonLabel}</Button>
                <Button
                    disabled={!!disableButtons}
                    onClick={() => { onClone() }}
                    variant="contained"
                    color="primary"
                    id="templatesTblMenuDuplicateConfirmBtn"
                >{translation.cloneButtonLabel}</Button>
            </div>
        </Dialog>
    )
}

const mapDispatchToProps = {
    setRunComponentsUpdate
}

export default connect(null, mapDispatchToProps)(DuplicateComponentModal)
