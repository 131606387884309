import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setState } from 'Store/modules/dashboard/actions'
import { FormControlLabel, Radio } from '@material-ui/core'
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import DmsService from 'Services/DMS/dms-api.service'
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'

// const RemoveIcon = () => {
//     return (
//         <div className='removeIcon' />
//     )
// }

const TemplateListItem = ({ value, label, selectedTemplate, setSelectedTemplate, allowDeleteTemplate, onDeleteItem, updatedAt }) => {
    const [removeTemplateModalIsOpen, onRemoveTemplateModalIsOpen] = useState(false)
    const translation = useTranslation();

    const onDeleteUser = () => {
        if (value === selectedTemplate)
            setSelectedTemplate(null)
        DmsService.deleteTemplate(value).then(res => {
            onDeleteItem(value)
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.Message)
                    AlertToastr.showErrorAlert(error.response.data.Message)
            })
    }

    return (
        <div>
            <div className="listItemWithRemove">
                <div className="inputHolder">
                    <FormControlLabel value={value}
                        label={label}
                        checked={value === selectedTemplate}
                        control={<Radio color="default" />}
                        onClick={() => setSelectedTemplate(value)}
                    />
                </div>
                <div>
                <p>{new Date(updatedAt).toLocaleString()}</p>
                </div>
                {/* {allowDeleteTemplate &&
                    <IconButton onClick={() => onRemoveTemplateModalIsOpen(true)}>
                        <RemoveIcon />
                    </IconButton>
                } */}
            </div>
            <DeleteItemModal
                isOpen={removeTemplateModalIsOpen}
                isOpenManager={onRemoveTemplateModalIsOpen}
                message={translation.removeTemplateModalMessageLabel}
                onDeleteModalClose={onDeleteUser} />
        </div>
    )
}

const mapStateToProps = ({ dashboard }) => ({
    templates: dashboard.templates,
    state: dashboard
})

const mapDispatchToProps = {
    setState
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateListItem)
