import React, {useEffect, useMemo, useState} from 'react'
import PasswordResetForm from './forms/PasswordReset.form'
import useApiCall from 'Hooks/useApiCall'
import useForm from 'react-hook-form'
import AlertToastr from 'utils/alert'
import authEndpoints from 'Services/endpoints/auth.endpoints'
import {Redirect, useLocation} from 'react-router'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PasswordResetPage = () => {

    const query = useQuery()
    const token = query.get('token')
    const type = query.get('type')

    const [request, passwordUpdate] = useApiCall()
    const [isSuccessfullSubmit, setIsSuccessfulSubmit] = useState(false)
    const isRegistration = useMemo(() => {
        return (type && (type === 'registration'))
    }, [type])

    const {...formProps} = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        }
    })

    useEffect(() => {
        if (request.response)
            setIsSuccessfulSubmit(true)
    }, [
        request.response,
        setIsSuccessfulSubmit
    ])

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message)
        }
    }, [request.error])

    const handleSubmit = data => {
        if (data) {
            data.passwordResetToken = token
            passwordUpdate({
                ...authEndpoints.passwordUpdate(),
                data
            })
        }
    }

    if (isSuccessfullSubmit)
        return <Redirect to='/login' />

    return (
        <PasswordResetForm {...formProps}
                           onSubmit={handleSubmit}
                           isRegistration={isRegistration}
                           request={request} />
    )
}

export default PasswordResetPage
