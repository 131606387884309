import {
    HttpTransportType,
    HubConnectionBuilder,
    JsonHubProtocol,
    LogLevel
} from '@microsoft/signalr'

import { apiAuthBaseUrl, apiBaseUrl } from 'config'

const buildHub = (url, token = null, companyId = null) => {

    if (companyId) {
        url = url + '?companyid=' + companyId
    }

    const options = {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => token || '',
        headers: {
            'companyid': 2222
        }
    }

    const connection = new HubConnectionBuilder()
        .withUrl(url, options)
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LogLevel.None)
        .build()

    connection.onclose(error => { })

    return connection
}

export const buildUserListHub = (token, companyId) => buildHub(`${apiAuthBaseUrl}/ws/companyusers`, token, companyId)
export const buildUserHub = (token, companyId) => buildHub(`${apiAuthBaseUrl}/ws/users`, token, companyId)
export const buildJobsHub = (token, companyId) => buildHub(`${apiBaseUrl}/ws/jobs`, token, companyId)
export const buildUnitsHub = (token, companyId) => buildHub(`${apiBaseUrl}/ws/units`, token, companyId)
