import { useQuery } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from 'config';

const getUnitInitChannelsData = async (channelsData) => {
    const response = await axios.post(`${apiBaseUrl}/data/unit/by-custom-channels/initialize/with-filters`, channelsData);
    if (response.status === 204) return {}
    return response.data;
};

export default function useUnitInitChannelsDataWithFilters({ unitUid, channels, filters, section = 'unit', startDate, endDate }) {

    const channelsData = {
        uId: unitUid,
        channels:  channels.map(ch => ({ code: ch.code, unit: ch.unit })),
        jobType: section,
        ...filters,
        dateFrom: startDate,
        dateTo: endDate
    }

    return useQuery(
        [section, 'unit-ch-data', unitUid, channels, filters],
        () => getUnitInitChannelsData(channelsData),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            keepPreviousData: false
        }
    );
}

