import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { useAppSettings } from '../../../Contexts/AppSettingsProvider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const UnitBoxLoader = ({ loading }) => {

    const classes = useStyles();
    const { appTheme: theme } = useAppSettings();

    return (
        <Fade
            in={loading}
            style={{ transitionDelay: loading ? '800ms' : '0ms' }}
            unmountOnExit
        >
            <div style={{
                backgroundColor: theme === 'Dark' ? '#000' : '#fff',
                zIndex: 100,
                position: 'absolute',
                height: '100%',
                width: '100%',
                opacity: 0.7,
            }}>
                <div style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <div className={classes.root}>
                        <CircularProgress />
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default UnitBoxLoader;
