import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MmsService from 'Services/MMS/mms-api.service'
import AlertToastr from 'utils/alert'
import { setComponents, setParts } from 'Store/modules/mms/actions'
import { Button } from "@material-ui/core";
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'

const CloneToEquipmentModal = ({ isOpen, isOpenManager, ids, departmentId, instance, setComponents, setParts }) => {
    const translation = useTranslation();
    const [disableButtons, setDisabledButtons] = useState(false)

    const onClone = () => {
        setDisabledButtons(true)
        if (instance === 'components')
            MmsService.cloneComponents(ids, departmentId).then(async (response) => {
                if (response.data.isSuccessful) {
                    AlertToastr.showAlert(ids.length === 1 ? translation.notificationsComponentCloned : translation.notificationsComponentsCloned)
                    try {
                        const response = await MmsService.getComponents(departmentId)
                        setComponents(response.data)
                    }
                    catch (error) {
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    }
                }
                isOpenManager(false)
                setDisabledButtons(false)
            })
                .catch((error) => {
                    isOpenManager(false)
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
        if (instance === 'parts')
            MmsService.cloneParts(ids, departmentId).then(async (response) => {
                if (response.data.isSuccessful) {
                    try {
                        const response = await MmsService.getPartsList()
                        setParts(response.data)
                    }
                    catch (error) {
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    }
                }
                isOpenManager(false)
                setDisabledButtons(false)
            })
                .catch((error) => {
                    isOpenManager(false)
                    setDisabledButtons(false)
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
    }

    return (
        <Dialog open={isOpen} className=''>
            <div className="modalTitleHolder mb16">
                <h1 className='modalTitle'>{translation.cloneComponentModalPermissionLabel}</h1>
            </div>
            <p>{instance === 'components' ? translation.cloneComponentsToEquipmentModalMessageLabel :
                translation.clonePartsToEquipmentModalMessageLabel}</p>
            <div className="btnHolder end mt22 fixedWidthBtn">
                <Button
                    disabled={!!disableButtons}
                    onClick={() => isOpenManager(false)}
                    variant="outlined"
                >{translation.cancelButtonLabel}</Button>
                <Button
                    disabled={!!disableButtons}
                    onClick={() => { onClone() }}
                    variant="contained"
                    color="primary"
                >{translation.cloneButtonLabel}</Button>
            </div>
        </Dialog>
    )
}

const mapDispatchToProps = {
    setComponents,
    setParts
}

export default connect(null, mapDispatchToProps)(CloneToEquipmentModal)
