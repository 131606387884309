import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { apiBaseUrl } from '../../../config'
import { useAuth } from '../../../Contexts/AuthProvider'
import { useUsersSettings } from '../UsersSettingsContext'
import DisabledLabel from '../../../Components/DisabledLabel'
import { jobTimeFormat } from '../../../helpers'
import { IconButton } from '@material-ui/core'
import useTranslation from '../../../Hooks/useTranslation'
import ListTable from '../../../Components/ListTable'
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component'
import AlertToastr from '../../../utils/alert'
import SaveSettingsTemplateModal from '../../../Components/modals/SaveSettingsTemplateModal'

const RemoveIcon = () => <div className="removeIcon"/>

const TemplatesManagement = () => {

  const { userLoaded, currentUser } = useAuth()
  const { currentCompany } = useUsersSettings()
  const [data, setData] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [activeTemplate, setActiveTemplate] = useState(null)
  const translation = useTranslation()
  const queryClient = useQueryClient()

  const tableColumns = useMemo(() => {
    return [
      {
        Header: translation.tableColumnName,
        accessor: 'name',
        Cell: ({ value, row }) => {
          const { isDeleting } = row.original
          return isDeleting ? <DisabledLabel>{value}</DisabledLabel> : value
        },
      },
      {
        Header: translation.tableColumnJobType,
        accessor: 'type',
        Cell: ({ value, row }) => {
          const { isDeleting } = row.original
          return isDeleting ? <DisabledLabel>{value}</DisabledLabel> : value
        },
      },
      {
        Header: translation.tableColumnUpdatedAtTime,
        accessor: 'updatedAt',
        Cell: ({ value, row }) => {
          const { isDeleting } = row.original
          const formattedValue = jobTimeFormat(value)
          return isDeleting ? <DisabledLabel>{formattedValue}</DisabledLabel> : <DisabledLabel>{formattedValue}</DisabledLabel>
        },
      },
      {
        Header: '',
        id: 'actions',
        className: 'actionTableCell',
        Cell: ({ value, row }) => {
          const { isDeleting } = row.original
          if (isDeleting) return null
          return (
            <IconButton size={'small'}
                        className="deleteTableItemBtn"
                        onClick={() => {
                          setActiveTemplate(row.original)
                          setShowRemoveModal(true)
                        }}
            >
              <RemoveIcon/>
            </IconButton>
          )
        },
      },
    ]
  }, [translation])

  const listRequest = useQuery(
    ['templates-settings', currentCompany],
    async () => {
      const config = !currentUser.isSuperuser() ? {} : {
        headers: {
          'CompanyId': currentCompany?.id,
        },
      }
      return await axios.get(`${apiBaseUrl}/templates`, config)
    },
    { keepPreviousData: true, enabled: userLoaded },
  )

  const removeMutation = useMutation((id) => axios.delete(`${apiBaseUrl}/templates/${id}`))

  useEffect(() => {
    if (listRequest.isSuccess) {
      const data = listRequest.data.status === 200 ? listRequest.data.data : []
      setData(data)
    }
  }, [listRequest.isSuccess, listRequest.data])

  const deleteTemplate = async () => {
    try {
      if (!activeTemplate.id) return alert('Add template ID to response')
      const response = await removeMutation.mutateAsync(activeTemplate.id)

      const successMessage = response?.data?.message

      AlertToastr.showAlert(successMessage || translation.deleteTemplateMessage)
      await queryClient.invalidateQueries(['templates-settings'])
    } catch (e) {
      const message = e?.response?.data?.message

      if (message) {
        AlertToastr.showErrorAlert(message)
      }
    }

    closeDeleteModal()
  }

  const closeDeleteModal = () => {
    setActiveTemplate(null)
    setShowRemoveModal(false)
  }

  const handleEdit = (model) => {
    setActiveTemplate(model)
    setShowEditModal(true)
  }

  const refreshList = async () => {
    await queryClient.invalidateQueries(['templates-settings'])
  }

  return (
    <div className="pageCard">
      <ListTable
        emptyDataText={(
          <span>
                        <span>No templates found.</span>
                    </span>
        )}
        request={listRequest}
        columns={tableColumns}
        data={data}
        onRowClick={handleEdit}
      />
      <SaveSettingsTemplateModal
        isOpen={showEditModal}
        isOpenStateChanger={setShowEditModal}
        loadedTemplate={activeTemplate}
        onSuccessSave={refreshList}
        />
      <DeleteItemModal
        isOpen={showRemoveModal}
        loading={removeMutation.isLoading}
        isOpenManager={() => {}}
        onCancel={closeDeleteModal}
        message={translation.formatString(translation.removeSettingsTemplateModalMessageLabel, { name: activeTemplate?.name })}
        onDeleteModalClose={deleteTemplate}
      />
    </div>
  )
}

export default TemplatesManagement
